































































import ElDialogMixin from "@/mixins/el-dialog.mixin";

import {
  MATCHING_INITIAL_INTEREST,
  MATCHING_CONNECTED_INTEREST,
  MATCHING_HAS_INTEREST,
} from "@/modules/matching/constants";

export default ElDialogMixin.extend({
  name: "PxMatchingInterestConfirmModal",

  props: {
    interestState: {
      type: Number,
      required: true,
    },
    interestName: {
      type: String,
      required: true,
    },
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      removalTypes: {
        [MATCHING_INITIAL_INTEREST as number]: "interest",
        [MATCHING_HAS_INTEREST as number]: "interest",
        [MATCHING_CONNECTED_INTEREST as number]: "connection",
      },
    };
  },

  computed: {
    removalType(): string {
      return this.removalTypes[this.interestState];
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickRemoveInterestButton() {
      this.$emit("removed-interest");
    },
  },
});
