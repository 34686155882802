
















































































import Vue from "vue";

import { IInvitedUser } from "@/components/share-modal/share-modal.interface";
import { LocaleMessages } from "vue-i18n";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";

export default Vue.extend({
  name: "ShareModalUsers",

  props: {
    users: {
      type: Array as () => IInvitedUser[],
      default: () => [],
    },

    copy: {
      type: String,
      default: "milestonePlanner.shareModal.users",
    },
  },

  computed: {
    usersCopy(): LocaleMessages {
      return this.$t(this.copy) as LocaleMessages;
    },
  },

  methods: {
    unshareClickHandler(userProfile: string) {
      this.$root.$emit("share-modal-action", {
        event: SHARE_MODAL_EVENTS.UNSHARE_FROM_USER,
        payload: { user_profile: userProfile },
      });
    },
  },
});
