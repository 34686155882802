






















import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasPopulatedSlot(): boolean {
      return !!this.$slots.default;
    },
  },
});
