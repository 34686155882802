





















import Vue from "vue";

export default Vue.extend({
  name: "FeaturesShowcaseCTA",

  methods: {
    onClickSignUp() {
      // No data to submit hence why we're sending null.
      this.$root.$emit("next-page", null);
    },
  },
});
