

























import Vue from "vue";

export default Vue.extend({
  name: "ClipboardButton",

  props: {
    clipboard: {
      type: [String, Number],
      required: true,
    },
    buttonLabel: {
      type: String,
      default: "common.copyLink",
    },
    buttonIcon: {
      type: String,
      default: "icon-link--white",
    },
    tooltipCopy: {
      type: String,
      default: "common.copiedToClipboard",
    },
  },

  data() {
    return {
      isTooltipVisible: false,
    };
  },

  methods: {
    flashTooltip() {
      this.isTooltipVisible = true;
      setTimeout(() => {
        this.isTooltipVisible = false;
      }, 1000);
    },

    copyToClipboard() {
      const clipboardElement = this.$refs.clipboard as HTMLInputElement;
      clipboardElement.setAttribute("type", "text");
      clipboardElement.select();
      document.execCommand("copy");
      clipboardElement.setAttribute("type", "hidden");
      (window as any).getSelection().removeAllRanges();
    },

    onClickButtonHandler() {
      // Show tooltip momentarily
      this.flashTooltip();

      // Copy to clipboard:
      // As seen at: https://codepen.io/PJCHENder/pen/jamJpj
      this.copyToClipboard();
    },
  },
});
