





































import Vue from "vue";

import ListShareSettingsLink from "@/modules/company-lists/components/list-share/list-share-settings-link.vue";
import ListShareSettingsPasscode from "@/modules/company-lists/components/list-share/list-share-settings-passcode.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";

export default Vue.extend({
  name: "ListShareSettings",

  components: {
    ListShareSettingsLink,
    ListShareSettingsPasscode,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentPasscode: null as null | string,
      canSaveChanges: false,
      hasPasscodeRestriction: false,
      hasChangedPasscodeSetting: false,
    };
  },

  computed: {
    isSavingChanges(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },
  },

  watch: {
    passcode(newValue: string, previousValue: string) {
      if (newValue !== this.currentPasscode || !previousValue.length) {
        this.currentPasscode = newValue;
      }
    },
    hasChangedPasscodeSetting(hasChanged: boolean) {
      if (!hasChanged) {
        this.canSaveChanges = hasChanged;
      }
    },
  },

  created() {
    this.currentPasscode = this.passcode;
  },

  methods: {
    onClickBackHandler() {
      this.$root.$emit("list-settings", false);
      this.currentPasscode = this.passcode;
      this.hasChangedPasscodeSetting = this.canSaveChanges;
    },

    onClickSaveHandler() {
      const newPasscode = this.hasPasscodeRestriction
        ? this.currentPasscode
        : null;
      this.$emit("save", newPasscode);
      this.canSaveChanges = false;
    },

    changeRestrictionHandler(isRestricted: boolean, newPasscode: string) {
      this.hasPasscodeRestriction = isRestricted;
      this.canSaveChanges =
        (isRestricted && newPasscode !== this.passcode) ||
        (!isRestricted && !!this.passcode);
    },
  },
});
