
























import Vue from "vue";
import GridLegendContainer from "@/modules/milestone-planner/components/grid-legend/grid-legend-container.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "GridLegend",

  components: {
    GridLegendContainer,
  },

  data() {
    return {
      completedStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.completedMilestones",
          ),
          icon: EMilestoneStatusType.COMPLETED,
          iconSize: 30,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.selfAssessed",
          ),
          icon: EMilestoneStatusType.SELF_ASSESSED,
          iconSize: 17,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.gridLegend.evidenceProvided",
          ),
          icon: EMilestoneStatusType.EVIDENCE_PROVIDED,
          iconSize: 20,
        },
      ],
      notCompletedStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
          iconSize: 26,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
          iconSize: 26,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
          iconSize: 26,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.gridLegend.critical"),
          icon: EMilestoneStatusType.CRITICAL,
          iconSize: 22,
        },
      ],
      completedTooltip: this.$t(
        "milestonePlanner.milestoneGrid.gridLegend.completedTooltip",
      ),
      notCompletedTooltip: this.$t(
        "milestonePlanner.milestoneGrid.gridLegend.notCompletedTooltip",
      ),
    };
  },
});
