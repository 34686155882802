










































import { EPxButtonVariant } from "@/components/px-button/px-button.types";
import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default PxMatchingInterestMixin.extend({
  name: "PxMatchingInterestCompact",

  props: {
    variant: {
      type: String,
      default: EPxButtonVariant.COMPACT,
    },
  },
});
