


















import Vue from "vue";

import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import SharePlannerModal from "@/modules/milestone-planner/components/share-planner/share-planner-modal.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import {
  ICompanyListInvitedUser,
  ICompanyListInvitedGuest,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default Vue.extend({
  name: "SharePlanner",

  components: {
    SharePlannerModal,
  },

  props: {
    milestonePlanner: {
      type: Object as () => IMilestonePlanner,
      required: true,
    },
  },

  static: {
    shareButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    shareButtonProps(): any {
      return {
        ...this.$options.static.shareButton,
        label: this.$t("milestonePlanner.share") as string,
      };
    },

    plannerPasscode(): string {
      return this.milestonePlanner.passcode || "";
    },

    allInvitedUsers(): Array<
      ICompanyListInvitedUser | ICompanyListInvitedGuest
    > {
      return [
        ...(this.milestonePlanner?.invited_users || []),
        ...(this.milestonePlanner?.invited_guests || []),
      ].sort((userA, userB) => userA.name.localeCompare(userB.name));
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },
  },
});
