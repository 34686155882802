import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import unionBy from "lodash/unionBy";

import {
  IMultiSelection,
  IMultiSelector,
  IMultiSelectorItem,
  IMultiSelectorParams,
} from "@/components/multi-selector/multi-selector.interface";

export default Vue.extend({
  props: {
    value: {
      type: Array as () => Array<any>,
      required: true,
    },

    values: {
      type: Array as () => Array<IMultiSelectorItem>,
      default: () => [],
    },

    remoteMethod: {
      type: Function as () => any,
      default: () => null,
    },

    hasRemoteSearch: {
      type: Boolean,
      default: true,
    },

    /**
     * When `true` it fetches results when created.
     */
    fetchOnCreated: {
      type: Boolean,
      default: false,
    },

    /**
     * When `true` it prefills again the selected items.
     */
    needsRefresh: {
      type: Boolean,
      default: false,
    },

    /**
     * When `true` it triggers form validation on blur.
     */
    validateEvent: {
      type: Boolean,
      default: false,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "common.components.default.input.label",
    },

    placeholder: {
      type: String,
      default: "common.components.default.input.placeholder",
    },

    tip: {
      type: String,
      default: "common.components.default.input.tip",
    },

    subtextLabelList: {
      type: Array as () => Array<string>,
      default: () => [
        "common.components.default.input.subLabel",
        "common.components.default.input.subLabelPlural",
      ],
    },

    inputIcon: {
      type: String,
      default: "magnifier",
      validator: (value: string) => ["magnifier", "location"].includes(value),
    },
  },

  data() {
    return {
      searchedItems: [] as Array<IMultiSelectorItem>,
      innerSelectedItem: null as null | IMultiSelectorItem | string,
      searchQuery: "",
      isLoadingResults: false,
      dispatchRemoteSearch: true,
      selectRemoteMethod: undefined as any,

      // Used for paginating more results
      page: 1,

      // Tracks how many search results exist
      searchCount: null as null | number,

      // Track loading of more results separately since
      // isLoadingResults triggers the ElSelect loading
      isLoadingMoreResults: false,

      // Will store the list of selected items
      selectedItems: [] as Array<IMultiSelectorItem>,

      // Store latest search query and items to avoid repetitive requests
      latestSearch: null as null | {
        query: string;
        items: Array<IMultiSelectorItem>;
      },
    };
  },

  computed: {
    isSearchRemote(): boolean {
      return this.searchQuery !== "";
    },

    hasMoreItemsToLoad(): boolean {
      return (
        !this.searchedItems.length ||
        (!!this.searchCount && this.searchCount > this.searchedItems.length)
      );
    },

    searchParams(): IMultiSelectorParams {
      const params: IMultiSelectorParams = {};

      if (this.searchQuery) {
        params.filter = this.searchQuery;
      }

      if (this.page > 1) {
        params.page = this.page;
      }

      return params;
    },
  },

  watch: {
    needsRefresh(itsNeeded: boolean) {
      if (itsNeeded) {
        this.prefillItems();
      }
    },

    value: {
      deep: true,
      handler(newSelection: Array<IMultiSelection>) {
        if (!newSelection.length) {
          this.selectedItems = [];
        }
      },
    },

    selectedItems: {
      deep: true,
      handler(newSelectedItems: Array<IMultiSelectorItem>) {
        // Emit new selected values
        const values = this.formatSelection(newSelectedItems);
        if (!isEqual(values, this.value)) {
          this.$emit("input", values);
          this.$emit("change", values);
        }
      },
    },

    searchedItems(newSearchedItems) {
      if (
        newSearchedItems.length &&
        (!this.latestSearch ||
          !isEqual(newSearchedItems, this.latestSearch.items))
      ) {
        this.latestSearch = {
          query: this.searchQuery,
          items: cloneDeep(newSearchedItems),
        };
      }
    },
  },

  created() {
    this.selectRemoteMethod = debounce(this.callRemoteMethod, 400);

    // Fetch all top results first
    if (this.fetchOnCreated) {
      this.selectRemoteMethod();
    }

    // Check if needs to prefill existing value
    this.prefillItems();
  },

  methods: {
    /**
     * Wrap call remote method, to trigger loading and filter
     * already selected items.
     *
     * @param query search text
     */
    async callRemoteMethod(query: string) {
      // First check if it's a new search query
      const searchChanged = this.searchQuery !== query;

      // Do a temporary hack to allow search for all top results.
      this.searchQuery = query || "";

      // If not remote search method, set values directly and exit
      if (!this.hasRemoteSearch) {
        this.searchCount = this.values.length;
        this.searchedItems = this.values;
        return;
      }

      // Exit if:
      // 1 - Filterable is disabled
      // 2 - When the query is less than 2 characters.
      // Except if it's an empty request, which will grab the top results.
      if (
        !this.dispatchRemoteSearch ||
        (this.searchQuery.length < 2 &&
          (!this.fetchOnCreated || this.searchQuery !== ""))
      ) {
        return;
      }

      // Set which loading needs to be applied:
      if (!this.searchedItems.length || searchChanged) {
        this.isLoadingResults = true;
      } else {
        this.isLoadingMoreResults = true;
      }

      // Set pagination before looking for results.
      if (searchChanged) {
        this.page = 1;
      } else if (this.searchedItems.length && this.hasMoreItemsToLoad) {
        this.page += 1;
      }

      try {
        const response: IMultiSelector = await this.remoteMethod(
          this.searchParams,
        );

        // Update the total results of our search.
        this.searchCount = response.count || null;

        if (this.isLoadingMoreResults) {
          // Append results when loading for more.
          this.searchedItems = unionBy(
            this.searchedItems,
            response.results,
            "value",
          );
        } else {
          // Otherwise, just reassign results.
          this.searchedItems = response.results;
        }
      } catch {
        if (this.searchedItems.length) return;

        // Only show error message when there aren't
        // search items to avoid confusing the user.
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } finally {
        this.isLoadingResults = this.isLoadingMoreResults = false;
      }
    },

    prefillItems() {
      if (this.value) {
        if (this.needsRefresh) {
          this.selectedItems = [];
        }

        this.value.forEach((selection) => {
          if (selection.selected_parent) {
            this.selectedItems.push(selection.selected_parent);
          } else if (selection.selected_children) {
            this.selectedItems.push(...selection.selected_children);
          }
        });
      }
    },

    /**
     * Format selected options to emit both
     * parent and children values accordingly.
     */
    formatSelection(
      values: Array<IMultiSelectorItem>,
    ): Array<Partial<IMultiSelection>> {
      return values.map((item) => {
        const formattedSelection: Partial<IMultiSelection> = {};

        // a) Check if this is actually a parent
        if ("children" in item) {
          // Choose selected children
          const selectedChildren = item.children
            ? item.children.filter((child) => child.selected)
            : [];
          formattedSelection.selected_children = selectedChildren;

          // Include parent if was selected, or has multiple children selected
          const hasMultipleChildrenSelected = selectedChildren.length > 1;
          if (item.selected || hasMultipleChildrenSelected) {
            formattedSelection.selected_parent = item;
          }
        } else {
          // b) Otherwise treat item as a child
          formattedSelection.selected_children = [item];
        }

        return formattedSelection;
      });
    },

    /**
     * Overriding method to remove selected item to show on
     * select component input
     */
    persistSearchQuery() {
      this.innerSelectedItem = this.searchQuery;
    },

    /**
     * Update selected items
     */
    updateSelectedItems(items: Array<IMultiSelectorItem>) {
      this.persistSearchQuery();
      this.selectedItems = [...items];
    },

    /**
     * Resets current select values
     */
    resetSearch() {
      this.searchedItems = [];
      this.innerSelectedItem = null;

      // Reset search to all top results
      if (this.dispatchRemoteSearch) {
        this.selectRemoteMethod();
      }
    },

    /**
     * Remove the given entry from the list of selected items.
     *
     * @param value removed item value
     */
    onRemoveItemClick(value: number) {
      const indexToRemove = this.selectedItems.findIndex(
        (option: IMultiSelectorItem) => option.value === value,
      );
      this.selectedItems.splice(indexToRemove, 1);

      // Prevent leftovers
      this.resetSearch();
    },

    /**
     * Edit the given entry from the list of selected items.
     *
     * @param value removed item value
     */
    async onEditItemClick(value: number) {
      const selectedItem = this.selectedItems.find(
        (option: IMultiSelectorItem) => option.value === value,
      );

      // Add item to search items, so that we can edit it
      if (selectedItem) {
        const elSelect = this.$refs.select as any;
        const searchLabel =
          selectedItem.children && selectedItem.children.length === 1
            ? selectedItem.children[0].label
            : selectedItem.label;

        // Call remote method to add all selectable options
        if (!this.latestSearch || this.latestSearch.query !== searchLabel) {
          this.dispatchRemoteSearch = true;
          await this.callRemoteMethod(searchLabel);
        } else if (this.latestSearch.items) {
          this.searchedItems = this.latestSearch.items;
        }

        // Temporarily disable filter to avoid remote search
        this.dispatchRemoteSearch = false;
        this.innerSelectedItem = startCase(searchLabel);
        elSelect.toggleMenu();
      }
    },

    /**
     * Override for select blur handler
     * @param event
     */
    blurHandler(event: any) {
      this.$emit("blur", event);
      this.$emit("change", this.selectedItems);

      // TODO: Find a better way to trigger form validaton on blur
      if (this.validateEvent) {
        (this as any).$refs.select.$parent.$parent.$emit("el.form.blur");
      }
    },

    focusHandler(event: any) {
      if (
        !event.target.value &&
        !this.innerSelectedItem &&
        this.isSearchRemote
      ) {
        this.dispatchRemoteSearch = true;
        this.resetSearch();
      }
    },

    /**
     * Override for select visibility change handler
     */
    handleVisibleChange() {
      const elSelect = this.$refs.select as any;

      if (elSelect.visible) {
        return;
      }

      if (elSelect.$refs.reference.focused) {
        // Force select menu visibility
        elSelect.toggleMenu();
      } else if (this.isSearchRemote) {
        // Prevent leftovers
        this.resetSearch();
      }
    },

    onSelectScrolledBottom() {
      if (
        this.hasMoreItemsToLoad &&
        !this.isLoadingResults &&
        !this.isLoadingMoreResults
      ) {
        this.callRemoteMethod(this.searchQuery);
      }
    },
  },
});
