
























import Vue from "vue";
import { AxiosError } from "axios";

import RegistrationModals from "@/components/registration-modals/registration-modals.vue";
import PasscodeAccessModal from "@/components/passcode-access-modal/passcode-access-modal.vue";

import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import {
  EUserGuestGetters,
  EUserGuestActions,
} from "@/services/store/user-guest/user-guest.types";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { HOME } from "@/services/router/router-names";

const PAGE_ELEMENT_SELECTOR = ".page-full-height";
const PAGE_BLUR_CLASS = "page-full-height--blur";

/**
 * This is used to store the page element to add blur effect while this component is active.
 *
 * TODO: explore the solution to move this into something on the store and if it pays the effort.
 */
let pageElement: HTMLDivElement | null = null;

export default Vue.extend({
  name: "AccessListGuard",

  components: {
    RegistrationModals,
    PasscodeAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      passcode: "",
      invalidPasscode: false,
    };
  },

  computed: {
    /**
     * Get list uid.
     */
    listUid(): string {
      return this.$route.params.uid;
    },

    listError(): AxiosError | null {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    listPermissionDenied(): boolean {
      return (
        this.listError?.response?.status === 401 ||
        this.listError?.response?.status === 403
      );
    },

    isIdentificationRequired(): boolean {
      return !this.isLogged && !this.userGuest;
    },

    isLogged(): boolean {
      return !!this.$store.get("auth/user");
    },

    userGuest(): IUserGuest | null {
      return this.$store.get(EUserGuestGetters.GET);
    },

    selectedCompanyList(): ICompanyList | null {
      return this.$store.get(CompanyListState.Getter.VALUE);
    },

    /**
     * Returns `true` when the requested list is already on store.
     */
    isDataLoaded(): boolean {
      return !!this.selectedCompanyList;
    },

    /**
     * Returns `true` when the data is loading.
     */
    isLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    /**
     * Defines the visibility of this guard.
     */
    isGuardVisible(): boolean {
      return !this.isDataLoaded && !this.isLoading;
    },
  },

  watch: {
    /**
     * Every time the uid changes we request the new page data to the server.
     */
    listUid: {
      immediate: true,
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.fetchList();
      },
    },

    isGuardVisible: {
      immediate: true,
      handler(newVal: boolean) {
        this.updateBlur(newVal);
      },
    },
  },

  mounted() {
    // get page element from dom and add a blur modifier
    pageElement = document.querySelector(PAGE_ELEMENT_SELECTOR);
    this.updateBlur(this.isGuardVisible);
  },

  beforeDestroy() {
    // remove the blur effect for the case of we move away form this page without enter
    this.updateBlur(false);
  },

  methods: {
    /**
     * Update base blur based on the given flag.
     */
    updateBlur(newState: boolean) {
      if (newState) {
        pageElement?.classList.add(PAGE_BLUR_CLASS);
      } else {
        pageElement?.classList.remove(PAGE_BLUR_CLASS);
      }
    },

    async fetchList() {
      const listRequestParams = {
        // If user guest exists in store, we include the guest email in the list request:
        ...(!this.isLogged &&
          this.userGuest && { email: this.userGuest.email }),
      };

      // Request list data
      await this.requestListData(listRequestParams);

      this.checkPermissionDeniedError();
    },

    createGuestUser(guestFormData: IUserGuest): Promise<IUserGuest> {
      const newGuestUser: IUserGuest = {
        name: guestFormData.name,
        email: guestFormData.email,
      };

      return this.$store.dispatch(EUserGuestActions.CREATE, newGuestUser);
    },

    async makeRequestWithPasscode(passcode: string) {
      this.passcode = passcode;

      const listRequestParams = {
        // If user guest exists in store, we include the guest email in the list request:
        ...(this.userGuest && { email: this.userGuest.email }),
      };

      // Request list data
      await this.requestListData(listRequestParams);

      this.checkForAnyPasscodeErrors();
      this.checkPermissionDeniedError();
    },

    async enterAsGuestUser(guestFormData: IUserGuest) {
      // Create guest user
      const userGuest = await this.createGuestUser(guestFormData);

      const listRequestParams: object = {
        email: userGuest.email,
      };

      // Request list data
      await this.requestListData(listRequestParams);
    },

    async enterAsLoggedUser() {
      // Request list data
      await this.requestListData();

      this.checkPermissionDeniedError();
    },

    async requestListData(requestParams?: object) {
      const params = {
        ...requestParams,
        id: this.listUid,

        // If passcode exists, we include it in the list request:
        ...(this.passcode && { passcode: this.passcode }),
      };

      await this.$store.dispatch(CompanyListState.Action.GET_VALUE, params);
    },

    checkForAnyPasscodeErrors() {
      const response = this.listError?.response;

      // When the passcode is wrong.
      this.invalidPasscode =
        response?.status === 403 &&
        (response?.data.errors.code === "invalid_passcode" ||
          response?.data.errors.code === "passcode_updated");
    },

    checkPermissionDeniedError() {
      if (
        this.listError &&
        this.listPermissionDenied &&
        !this.requiresPasscode &&
        this.isLogged
      ) {
        this.$message({
          message: this.$root.$t("companyLists.errors.notInvited") as string,
          type: "error",
          customClass: "is-full",
        });

        this.$router.push({ name: HOME });
      }
    },
  },
});
