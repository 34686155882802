

































































































import Vue, { VueConstructor } from "vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import emitter from "element-ui/src/mixins/emitter";

import { networkProvider } from "@/services/data/network/network.provider";
import { INetwork } from "@/services/data/network/network.interface";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

let requestTimer: any = null;

interface INetworkSelectorTypes {
  listOfNetworks: Array<INetwork>;
  tempNetwork: null | INetwork;
  networkSelectLoading: boolean;
  selectedNetworks: Array<INetwork>;
  searchNetworksRequest: (query?: string) => void;
}

export default (Vue as VueConstructor<Vue & INetworkSelectorTypes>).extend({
  name: "NetworksSelector",

  mixins: [emitter],

  props: {
    value: {
      type: Array as () => Array<INetwork>,
      required: true,
    },

    /**
     * When set to `true` the value will be INetwork instances.
     * Otherwise, it will default to network ids'
     */
    valueAsInstance: {
      type: Boolean,
      default: false,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    noLocation: {
      type: Boolean,
      default: false,
    },

    labelCopy: {
      type: String,
      default: "",
    },

    placeholderCopy: {
      type: String,
      default: "",
    },

    isModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listOfNetworks: [] as Array<INetwork>,
      tempNetwork: null as null | INetwork,
      networkSelectLoading: false,

      // Will stores the list of selected sectors
      selectedNetworks: [] as Array<INetwork>,
      isSelectedVisible: true,
      visibleNetworks: 4,
      networksScrollAtEnd: false,
    };
  },

  computed: {
    networkQueryParams(): Array<string> {
      const queryParams =
        this.$route.query && (this.$route.query.network as string | null);
      return queryParams ? queryParams.split(",") : [];
    },

    selectPopperClass(): string {
      return this.isModal
        ? "networks-selector--modal__popper"
        : "networks-selector__popper";
    },

    isGradientVisible(): boolean {
      return (
        this.value.length > this.visibleNetworks &&
        !this.networksScrollAtEnd &&
        this.$route.name === ROUTE_PROFILE
      );
    },
  },

  watch: {
    selectedNetworks(newVal: Array<INetwork>) {
      let selection: Array<INetwork> | Array<number> = newVal;

      if (!this.valueAsInstance) {
        selection = selection.map((network: any) => network.id);
      }

      this.$emit("input", selection);
      (this as any).dispatch("ElFormItem", "el.form.change", [selection]);
    },

    value: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue) && this.valueAsInstance) {
          this.setActiveNetworks(newValue);
        }
      },
    },
  },

  async created() {
    if (this.networkQueryParams.length) {
      // Populate network selector via query params...
      for (const query of this.networkQueryParams) {
        await this.searchNetworksRequest(query);
      }
    } else if (this.value.length) {
      // ...or from pre-existing values (store)
      this.setActiveNetworks(this.value);
    }
  },

  methods: {
    /**
     * Allow to set the active sectors.
     */
    setActiveNetworks(networks: Array<INetwork>) {
      this.selectedNetworks = networks;
    },

    async searchNetworksRequest(query?: string) {
      const search = query || "";

      if (!search.length) {
        this.listOfNetworks = [];
      }

      const data = (await networkProvider.list({
        search,
      })) as Array<INetwork>;

      // Get current selected networks ids
      const selectedIds = this.selectedNetworks.map(
        (item: INetwork) => item.id,
      );

      if (this.networkSelectLoading) {
        // Remove user selected networks from all networks
        this.listOfNetworks =
          selectedIds.length > 0
            ? data.filter((entry: INetwork) => !selectedIds.includes(entry.id))
            : data;
      } else if (data.length > 0) {
        // If it's not a user selection (e.g. prefill networks)
        // Simply add request results to selected networks
        this.listOfNetworks.push(data[0]);

        if (this.networkQueryParams.length === this.listOfNetworks.length) {
          this.selectedNetworks = cloneDeep(this.listOfNetworks);
        }
      }

      // Remove duplicates
      this.listOfNetworks = this.listOfNetworks.filter(
        (item: INetwork, index: number, self: Array<INetwork>) => {
          return (
            index ===
            self.findIndex(
              (t: INetwork) => t.name === item.name && t.slug === item.slug,
            )
          );
        },
      );

      this.networkSelectLoading = false;
    },

    /**
     * Search for sectors on the API.
     */
    async searchNetworks(query: string) {
      this.networkSelectLoading = true;
      if (requestTimer) {
        clearTimeout(requestTimer);
      }

      requestTimer = setTimeout(() => this.searchNetworksRequest(query), 400);
    },

    /**
     * When a new sector is selected it is pushed into the
     * array of selected sectors and the autocomplete fill
     * cleared.
     */
    onNetworkSelect(selectedValue: INetwork) {
      this.selectedNetworks.push(selectedValue);
      this.tempNetwork = null;
      this.listOfNetworks = this.listOfNetworks.filter(
        (network: INetwork) => network !== selectedValue,
      );

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given network from the list of selected networks.
     */
    onClickRemoveNetwork(networkId: number) {
      const indexToRemove = this.selectedNetworks.findIndex(
        (network: INetwork) => network.id === networkId,
      );
      this.selectedNetworks.splice(indexToRemove, 1);
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      (this as any).dispatch("ElFormItem", "el.form.change", [
        this.selectedNetworks,
      ]);
    },

    focusHandler() {
      this.networkSelectLoading = true;
      this.searchNetworksRequest();
    },

    handleVisibleChange() {
      const elSelect = this.$refs.select as any;

      // TEMPORARY FIX: Show select menu while input is focused
      // When on modal, disable re-toggle
      if (
        !elSelect.visible &&
        elSelect.$refs.reference.focused &&
        !this.isModal
      ) {
        elSelect.toggleMenu();
      }

      // When on modal, hide selected list when select element is visible
      this.isSelectedVisible = !this.isModal || !elSelect.visible;
    },

    checkNetworksScroll($event: any) {
      if (!$event.target) return;

      this.networksScrollAtEnd =
        $event.target.scrollTop ===
        $event.target.scrollHeight - $event.target.offsetHeight;
    },
  },
});
