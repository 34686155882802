var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-presentation-layout__wrapper"},[_c('div',{staticClass:"form-presentation-layout__container",class:{
      'form-presentation-layout__container--bkg-reversed':
        _vm.hasReversedBackgrounds,
    }},[_c('div',{staticClass:"form-presentation-layout__content-wrapper",class:{
        'form-presentation-layout__content-wrapper--with-return':
          _vm.hasReturnButton,
      }},[_c('div',{staticClass:"form-presentation-layout__content-container",class:{
          'form-presentation-layout__content-container--top': _vm.isTopAlignment,
        }},[_c('div',{staticClass:"form-presentation-layout__content-header-wrapper",class:{
            'form-presentation-layout__content-header-wrapper--sticky':
              _vm.showStickyHeader,
          }},[(_vm.hasReturnButton)?_c('ElButton',{staticClass:"form-presentation-layout__return-button",attrs:{"circle":""},on:{"click":_vm.onReturnButtonClick}},[_c('PxIcon',{attrs:{"name":"arrow--left-dark","size":"14"}})],1):_vm._e(),_c('div',{staticClass:"form-presentation-layout__content-header"},[_vm._t("header",function(){return [_c('h1',{staticClass:"form-presentation-layout__content-title"},[_vm._v(" "+_vm._s(_vm.contentTitle)+" ")]),_c('p',{staticClass:"form-presentation-layout__content-description"},[_vm._v(" "+_vm._s(_vm.contentDescription)+" ")])]})],2)],1),_c('div',{staticClass:"form-presentation-layout__content-body"},[_vm._t("content")],2)])]),_c('div',{staticClass:"form-presentation-layout__description-wrapper",class:{
        'form-presentation-layout__description-wrapper--with-background':
          _vm.hasDescriptionBackgroundImage,
      }},[_c('div',{staticClass:"form-presentation-layout__description-container"},[_c('PxResponsiveBitmap',{staticClass:"form-presentation-layout__description-image",attrs:{"alt":_vm.descriptionImageTitle,"link":_vm.descriptionImageLink}}),(_vm.descriptionText)?_c('span',{staticClass:"form-presentation-layout__description-text",domProps:{"innerHTML":_vm._s(_vm.descriptionText)}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }