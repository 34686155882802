






































import Vue from "vue";

import { LocaleMessages } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";

import CollapsibleQuestion from "@/components/collapsible-question/collapsible-question.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

export default Vue.extend({
  name: "TeamMemberDemographicData",

  components: {
    CollapsibleQuestion,
  },

  props: {
    responses: {
      type: Array as () => Array<IMatchingResponse>,
      default: () => [],
    },

    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    shouldFormatResponses: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponses: [] as Array<IMatchingResponse> | null,
      responsesState: [] as any | null,
      responsesAreFormatted: false,
    };
  },

  computed: {
    headerCopy(): LocaleMessages {
      return this.$t(
        "teamManagement.settings.demographicData",
      ) as LocaleMessages;
    },
  },

  watch: {
    responses: {
      immediate: true,
      deep: true,
      handler(newResponses: Array<IMatchingResponse>) {
        this.currentResponses = cloneDeep(newResponses);

        this.responsesState = this.currentResponses.map(
          (response: IMatchingResponse) => ({
            question: response.question,
            is_valid: this.checkValidResponse(response),
            is_formatted: false,
          }),
        );
      },
    },

    shouldFormatResponses: {
      handler(shouldFormat: boolean) {
        if (shouldFormat) {
          this.responsesAreFormatted = false;
        }
      },
    },
  },

  beforeDestroy() {
    this.currentResponses = null;
    this.responsesState = null;
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max && !!response?.value?.min) ||
        !!response.value?.text ||
        !response.answers.length ||
        !response.value
      );
    },

    handleFormattedResponse(questionId: number) {
      // Update responses state
      this.responsesState = this.responsesState.map((response: any) => {
        return response.question === questionId
          ? { ...response, is_formatted: true }
          : response;
      });
    },

    updateResponses(isValid: boolean, newResponse: IMatchingResponse) {
      // Update responses state
      this.responsesState = this.responsesState.map((response: any) => {
        return response.question === newResponse.question
          ? { ...response, is_valid: isValid }
          : response;
      });

      // Update responses
      this.currentResponses =
        this.currentResponses?.map((response: IMatchingResponse) => {
          return response.question === newResponse.question
            ? newResponse
            : response;
        }) || [];

      const hasInvalidResponse = this.responsesState.some(
        (response: any) => !response.is_valid,
      );

      const allResponsesFormatted = this.responsesState.every(
        (response: any) => response.is_formatted,
      );

      if (allResponsesFormatted && !this.responsesAreFormatted) {
        this.$emit("responses-formatted", this.currentResponses);
        this.$emit("update:shouldFormatResponses", false);
        this.responsesAreFormatted = true;
      }

      if (this.responsesAreFormatted) {
        this.$emit("has-new-responses", this.currentResponses);
      }

      this.$emit("form-is-invalid", hasInvalidResponse);
    },
  },
});
