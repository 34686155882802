

















































































import Vue from "vue";
import { IBitmapLink } from "@/components/px-responsive-bitmap/px-responsive-bitmap.interface";
import throttle from "lodash/throttle";

export default Vue.extend({
  name: "FormPresentationLayout",

  props: {
    contentTitle: {
      type: String,
      default: "",
    },
    contentDescription: {
      type: String,
      default: "",
    },
    contentVerticalAlignment: {
      type: String,
      default: "center",
      validator(value: string) {
        return !value || ["center", "top"].includes(value);
      },
    },
    descriptionText: {
      type: String,
      default: "",
    },
    descriptionImageLink: {
      type: Object as () => IBitmapLink,
      required: true,
    },
    descriptionImageTitle: {
      type: String,
      required: true,
    },
    hasReturnButton: {
      type: Boolean,
      default: true,
    },
    hasDescriptionBackgroundImage: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
    hasReversedBackgrounds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isWrapperScrolled: false,
    };
  },

  computed: {
    isTopAlignment(): boolean {
      return this.contentVerticalAlignment === "top";
    },

    showStickyHeader(): boolean {
      return this.hasStickyHeader && this.isWrapperScrolled;
    },
  },

  mounted() {
    if (!this.hasStickyHeader) {
      return;
    }

    document.addEventListener(
      "scroll",
      throttle(() => this.onWrapperScroll(), 100),
      {
        passive: true,
        capture: false,
      },
    );
  },

  beforeDestroy() {
    if (!this.hasStickyHeader) {
      return;
    }

    document.removeEventListener(
      "scroll",
      throttle(() => this.onWrapperScroll, 300),
    );
  },

  methods: {
    onWrapperScroll() {
      this.isWrapperScrolled = !!document.scrollingElement?.scrollTop;
    },

    onReturnButtonClick() {
      this.$emit("previous-page");
    },
  },
});
