































import elDialogMixin from "@/mixins/el-dialog.mixin";
import SubmissionsTableRow from "@/modules/submissions/components/submissions-table/submissions-table-row.vue";
import PxTableHeader from "@/components/px-table-header/px-table-header.vue";

import { TranslateResult } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_OWNED_AFFILIATES_SUBMISSIONS } from "@/modules/profile/services/router/routes-names";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default elDialogMixin.extend({
  name: "SubmissionsModal",

  components: {
    SubmissionsTableRow,
    PxTableHeader,
  },

  props: {
    data: {
      type: Array as () => Array<any>,
      required: true,
    },

    company: {
      type: String,
      required: true,
    },
  },

  computed: {
    actionButtonProps(): object {
      return {
        label: this.$t("common.components.submissionsModal.cta") as string,
        type: EPxButtonType.BLUE,
      };
    },

    tableHeaders(): TranslateResult {
      return this.$t("common.components.submissionsModal.table.headers");
    },

    mappedSubmissionsData(): Array<any> {
      return this.data.map((item: any) => ({
        ...item,
        submission_uid: item.uid,
        company_type: ENTREPRENEUR_USER_TYPE,
      }));
    },
  },

  methods: {
    goToSubmissionsTrackerPage() {
      return this.$router.push({
        name: ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
        params: {
          searchInputValue: encodeURIComponent(this.company),
        },
      });
    },
  },
});
