































































































import Vue from "vue";
import {
  IMatchingQuestionType,
  SINGLE_SELECT,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  IMatchingQuestion,
} from "@/services/data/matching-questionary/matching-question.interface";

import PxQuestionaryCurrency from "@/components/px-questionary/px-questionary-currency.vue";
import PxQuestionaryCurrencyRange from "@/components/px-questionary/px-questionary-currency-range.vue";
import PxQuestionaryCriteria from "@/components/px-questionary/px-questionary-criteria.vue";
import PxQuestionaryNumeric from "@/components/px-questionary/px-questionary-numeric.vue";
import PxQuestionaryRange from "@/components/px-questionary/px-questionary-range.vue";
import PxQuestionaryPlaceholder from "@/components/px-questionary/px-questionary-placeholder.vue";
import {
  IMatchingCriteria,
  IMatchingCriteriaWeight,
} from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default Vue.extend({
  name: "PxQuestionary",

  components: {
    PxQuestionaryCurrency,
    PxQuestionaryCurrencyRange,
    PxQuestionaryCriteria,
    PxQuestionaryNumeric,
    PxQuestionaryRange,
    PxQuestionaryPlaceholder,
  },

  props: {
    question: {
      type: Object as () => IMatchingQuestion,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    finalTitle: {
      type: String,
      required: true,
    },
    finalMessage: {
      type: String,
      required: true,
    },
    criteria: {
      type: Object as () => IMatchingCriteria,
      default: null,
    },
    criteriaWeights: {
      type: Array as () => IMatchingCriteriaWeight[],
      default: () => [],
    },
  },

  data() {
    return {
      /**
       * Contains the selected values.
       */
      selectedOptions: [] as IMatchingResponse["answers"],
      selectedValue: null as null | IMatchingResponse["value"],
      selectedCriteriaWeightId: null as null | IMatchingCriteriaWeight["id"],
      isFieldValid: false,
    };
  },

  computed: {
    /**
     * Check if there is no more questions.
     */
    finishedQuestionary(): boolean {
      return this.question === null;
    },

    questionType(): IMatchingQuestionType | null {
      return this.question ? this.question.question_type : null;
    },

    /**
     * Check if can show criteria.
     */
    hasCriteria(): boolean {
      return this.criteriaWeights.length > 0 && !this.finishedQuestionary;
    },

    /**
     * Check if the question is a single ou multi-select type question.
     */
    isSelectType(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        [SINGLE_SELECT, MULTI_SELECT].includes(this.questionType.type)
      );
    },

    /**
     * Check if the question is a numeric type question.
     */
    isNumeric(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === NUMERIC
      );
    },

    /**
     * Check if the question is a multi-select type question.
     */
    isMultiSelect(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === MULTI_SELECT
      );
    },

    /**
     * Check if the question is a range type question.
     */
    isRange(): boolean {
      return (
        !!this.questionType &&
        !!this.questionType.type &&
        this.questionType.type === RANGE
      );
    },

    isMoneyType(): boolean {
      return (
        (!!this.questionType &&
          !!this.questionType.meta &&
          this.questionType.meta.currency) ||
        false
      );
    },

    needsCurrencyRange(): boolean {
      return [
        this.$user.isEntrepreneur() &&
          !!this.questionType &&
          this.isMoneyType &&
          this.isRange,
        this.$user.isSupporter() &&
          !!this.questionType &&
          this.isMoneyType &&
          this.isNumeric,
      ].some((isValid: boolean) => isValid);
    },

    currentResponse():
      | IMatchingResponse["answers"]
      | IMatchingResponse["value"] {
      return this.selectedOptions.length
        ? this.selectedOptions
        : this.selectedValue;
    },
  },

  watch: {
    selectedOptions(value: number | Array<number>) {
      // TODO: Analyse select component on why it doesn't return an array from the get go, to avoid this watch
      if (typeof value === "number") {
        this.selectedOptions = [value];
      }
    },
    criteria: {
      immediate: true,
      handler(existingCriteria: IMatchingCriteria | null) {
        if (existingCriteria) {
          this.selectedCriteriaWeightId = existingCriteria.criteria_weight_id;
        }
      },
    },
  },

  methods: {
    /**
     * Clear form data between questions
     * TODO: Should we fetch and prefill already answered questions?
     */
    clearAnswers() {
      // Clear values from next question
      this.selectedOptions = [];
      this.selectedValue = null;
    },

    onClickSkipQuestion() {
      this.clearAnswers();
      this.$emit("skip");
    },

    onClickSubmitAnswer() {
      if (this.$user.isEntrepreneur()) {
        this.$emit("submit", this.currentResponse);
      }

      if (this.$user.isSupporter()) {
        this.$emit("submit-criteria", {
          criteria: this.selectedCriteriaWeightId,
          values: this.currentResponse,
        });
      }

      this.clearAnswers();
    },
  },
});
