




















































import Vue from "vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { ILevel } from "@/services/data/level/level.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "AssessmentPanelLevel",

  props: {
    latestAssessment: {
      type: Object as () => IAssessment | null,
      required: true,
    },
    levels: {
      type: Array as () => Array<ILevel>,
      required: true,
    },
    level: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  computed: {
    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.latestAssessment
      );
    },
  },

  methods: {
    onClickUpdateLevel() {
      this.$emit("request-new-assessment");
    },

    onClickMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
