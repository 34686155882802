import {
  Alert,
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Message,
  Option,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  TabPane,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  Carousel,
  CarouselItem,
} from "element-ui";
import Vue, { VueConstructor } from "vue";

// Patch select `readonly` condition
const isIE = !Number.isNaN(Number((document as any).documentMode));

if (isIE) {
  (Select as any).computed.readonly = function () {
    // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
    return !(this.filterable || this.multiple) && !this.visible;
  };
}

// Patch to allow changing the cursor position
const oldToggleMenu = (Select as any).methods.toggleMenu;
(Select as any).methods.toggleMenu = function () {
  const isTouchDevice =
    navigator.maxTouchPoints ||
    (document.documentElement !== null &&
      "ontouchstart" in document.documentElement);
  if (isTouchDevice && this.visible) {
    return;
  }
  oldToggleMenu.call(this);
};

// Install Element components
Vue.use(Autocomplete);
Vue.use(Badge);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Select);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Slider);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Option);
Vue.use(Alert);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(Popover);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Divider);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.use((_Vue: VueConstructor) => {
  _Vue.prototype.$message = Message;
});
