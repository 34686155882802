




















import Vue from "vue";

export interface ITableHeaderItems {
  title: string;
  tooltip?: string;
}

export default Vue.extend({
  name: "PxTableHeader",

  props: {
    items: {
      type: Array as () => Array<ITableHeaderItems>,
      required: true,
    },
  },
});
