var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milestone-carousel-description"},_vm._l((_vm.category.milestones),function(milestone){return _c('div',{key:milestone.level,class:[
      {
        'milestone-carousel-description--above-levels': !milestone.completed,
      } ],attrs:{"data-testid":("milestone-description-" + (milestone.level))}},[_c('p',{staticClass:"milestone-carousel-description__text",class:[
        {
          'milestone-carousel-description__text--long-text':
            milestone.description.length > 72,
        } ]},[_vm._v(" "+_vm._s(milestone.description)+" ")]),(milestone.description.length > 72)?_c('ElTooltip',{attrs:{"placement":"top","popper-class":"milestone-carousel-description__tooltip","content":milestone.description,"data-testid":"milestone-carousel-description-tooltip"}},[_c('p',{staticClass:"milestone-carousel-description__tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("common.more"))+" ")])]):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }