

















































import Vue from "vue";

import PxStickyBar from "@/components/px-sticky-bar/px-sticky-bar.vue";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "VisitorJoinBarMobile",

  components: {
    PxStickyBar,
  },

  data() {
    return {
      menuOpen: false,
    };
  },

  computed: {
    menuIcon(): string {
      return this.menuOpen ? "arrow--down-grey" : "arrow--up-grey";
    },
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
