


































import Vue from "vue";
import MilestonePlanStageStatus from "@/modules/milestone-planner/components/milestone-plan-stage-status/milestone-plan-stage-status.vue";
import MilestoneNavigationTab from "@/modules/milestone-planner/components/milestone-navigation-tab/milestone-navigation-tab.vue";
import MilestonePlanOnboardingDialogContent from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content.vue";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default Vue.extend({
  name: "MilestonePlanOnboardingDialog",

  components: {
    MilestonePlanStageStatus,
    MilestoneNavigationTab,
    MilestonePlanOnboardingDialogContent,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      pageCount: 2,
      activeTab: MILESTONE_DETAILS_TABS.PLAN as TMilestoneDetailsTab,
    };
  },

  computed: {
    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },

  watch: {
    currentPage: function (page: number) {
      this.activeTab = Object.values(MILESTONE_DETAILS_TABS)[page - 1];
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      this.currentPage = Object.values(MILESTONE_DETAILS_TABS).indexOf(tab) + 1;
    },
  },
});
