
























































import Vue from "vue";
import get from "lodash/get";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

interface ISize {
  name: string;
  step: number;
}

export default Vue.extend({
  name: "PxGrid",

  props: {
    /**
     * Grid size
     */
    size: {
      type: String,
      default: "default",
    },
    /**
     * Grid inactive state
     */
    inactive: {
      type: Boolean,
      default: false,
    },
    /**
     * Used for generating random results
     * (e.g. For unauthorized users)
     */
    randomResults: {
      type: Boolean,
      default: false,
    },
    /**
     * Array with all available categories.
     */
    categories: {
      type: Array as () => any[],
      required: true,
    },
    /**
     * Number of levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },
    /**
     * Results
     */
    results: {
      type: Array as () => any[],
      default: () => [],
    },
  },

  data() {
    return {
      sizes: {
        default: 32,
        medium: 19,
      },
    };
  },

  computed: {
    /**
     * Computed the level bar position.
     */
    levelBarPosition(): string {
      const isMobile = this.$screen.smDown;
      const isMediumSize = this.size === "medium";
      const PX_PER_STEP = isMobile ? 16 : this.getStepSize;

      let newPosition = 0;

      if (!this.inactive) {
        if (isMobile) {
          newPosition = -this.currentLevel * PX_PER_STEP - 1;
        } else if (isMediumSize) {
          newPosition =
            -this.currentLevel * PX_PER_STEP - 3 * (this.currentLevel - 1);
        } else {
          newPosition = -this.currentLevel * PX_PER_STEP;
        }
      }

      return `${newPosition}px`;
    },

    /**
     * Computed the step size
     */
    getStepSize(): number {
      return get(this.sizes, this.size, this.sizes.default);
    },

    /**
     * Get the results for the grid.
     */
    gridResults(): any[] {
      if (this.inactive) {
        const min = 2;
        const max = this.levels;

        return this.categories.map((category: ICategory) => ({
          category: category.id,
          // Random number between "min" and "max" (inclusive)
          level: Math.floor(Math.random() * (max - min + 1)) + min,
        }));
      }

      return this.results;
    },
  },

  methods: {
    generateStylesFor(
      category: ICategory,
      level: number,
    ): { [key: string]: string } {
      // Get the selected level or the given category
      const selectedLevel = this.gridResults.find(
        (entry: ICategoryLevelSelection) => entry.category === category.id,
      );

      let backgroundColor;
      const cellHasValue = !selectedLevel || level <= selectedLevel.level;
      const isInactive = this.inactive;
      const hasRandomResults = this.randomResults;

      if (cellHasValue && !isInactive) {
        backgroundColor = `#${category.color}`;
      } else if (cellHasValue && hasRandomResults) {
        backgroundColor = `rgba(139, 143, 161, 0.4)`;
      } else {
        backgroundColor = `rgba(39, 39, 64, 0.05)`;
      }

      return {
        backgroundColor,
      };
    },
  },
});
