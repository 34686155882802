






























































import Vue from "vue";

import QuestionsPanelHeader from "@/modules/capital-explorer/components/questions-panel-header/questions-panel-header.vue";
import QuestionsPanel from "@/modules/capital-explorer/components/questions-panel/questions-panel.vue";
import ResultsPanel from "@/modules/capital-explorer/components/results-panel/results-panel.vue";
import OnboardingModal from "@/modules/capital-explorer/components/onboarding-modal/onboarding-modal.vue";
import WeightsPanel from "@/modules/capital-explorer/components/weights-panel/weights-panel.vue";
import RolePlayingBanner from "@/modules/capital-explorer/components/role-playing-banner/role-playing-banner.vue";

import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import cloneDeep from "lodash/cloneDeep";
import {
  ECapitalExplorerActions,
  ECapitalExplorerMutations,
} from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import {
  ICapitalExplorerData,
  ICapitalTileInfo,
  ICapitalExplorerCriteriaWeights,
  ICapitalExplorerSubmission,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

import isEqual from "lodash/isEqual";
import { calculateResults } from "@/modules/capital-explorer/calculate-results";
import debounce from "lodash/debounce";
import { CAPITAL_EXPLORER_USER_GUIDING_VISIBLE } from "@/modules/capital-explorer/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default Vue.extend({
  name: "CapitalExplorerView",

  components: {
    QuestionsPanelHeader,
    QuestionsPanel,
    ResultsPanel,
    OnboardingModal,
    WeightsPanel,
    RolePlayingBanner,
  },

  data() {
    return {
      isQuestionsPanelCollapsed: false,
      elementsVisible: true,
      questionComponentsInitialized: 0,
      shouldResetResponses: false,
      dataPrefilled: false,
    };
  },

  computed: {
    capitalExplorerData(): ICapitalExplorerData | null {
      return this.$store.get("capitalExplorer/data") || null;
    },

    loading(): boolean {
      return this.$store.get("capitalExplorer.loading");
    },

    questions(): IMatchingQuestion[] {
      return this.capitalExplorerData?.questions || [];
    },

    capitalExplorerTilesResults(): ICapitalTileInfo[] | null {
      return this.capitalExplorerData?.results || null;
    },

    totalOfQuestions(): number {
      return this.questions.length || 0;
    },

    totalOfQuestionsAnswered(): number {
      return this.responses.length || 0;
    },

    termsAccepted(): boolean {
      return this.$store.get("capitalExplorer/termsAccepted");
    },

    capitalExplorerSubmission(): ICapitalExplorerSubmission | null {
      return this.capitalExplorerData?.submission || null;
    },

    responses(): IMatchingResponse[] {
      return this.capitalExplorerSubmission?.responses || [];
    },

    weights(): ICapitalExplorerCriteriaWeights {
      return (
        this.capitalExplorerData?.weights || {
          matched: [0, 1, 10, 50, 250],
          unmatched: [0, 1, 10, 50, 500],
          unanswered: [0, 1, 10, 50, 350],
        }
      );
    },

    renderWeightsPanel(): boolean {
      return (
        this.$features.isEnabled("capitalExplorerWeightsPanel") &&
        Object.prototype.hasOwnProperty.call(this.$route.query, "admin")
      );
    },

    allQuestionsComponentsInitialized(): boolean {
      return this.questionComponentsInitialized === this.totalOfQuestions;
    },

    showMatchingBadge(): boolean {
      return this.totalOfQuestions == this.totalOfQuestionsAnswered;
    },

    capitalExplorerUserGuidingVisible(): boolean {
      return !!this.$store.get(
        "meta/get",
        CAPITAL_EXPLORER_USER_GUIDING_VISIBLE,
      );
    },
  },

  watch: {
    responses: {
      deep: true,
      handler(newValue, oldValue) {
        if (
          !this.allQuestionsComponentsInitialized ||
          isEqual(newValue, oldValue)
        )
          return;

        calculateResults();
      },
    },

    weights: {
      deep: true,
      handler(newWeights, oldWeights) {
        if (isEqual(newWeights, oldWeights)) return;

        calculateResults();
      },
    },

    termsAccepted: {
      handler(termsAccepted) {
        if (!termsAccepted) return;

        this.triggerUserGuiding();
      },
    },
  },

  async created() {
    await this.$store.dispatch(ECapitalExplorerActions.CHECK_CONSENT);
    await this.$store.dispatch(ECapitalExplorerActions.FETCH_DATA);
    await this.$store.dispatch(
      ECapitalExplorerActions.FETCH_AUTH_USER_SUBMISSION,
    );

    this.dataPrefilled = true;

    calculateResults();

    if (!this.termsAccepted) return;

    // If user has accepted terms, we trigger the guiding
    this.triggerUserGuiding();
  },

  methods: {
    triggerUserGuiding() {
      if (
        !!(window as any).userGuiding &&
        this.capitalExplorerUserGuidingVisible
      ) {
        (window as any).userGuiding.previewGuide(93477);

        this.$store.dispatch(EMetaActions.SET, {
          key: CAPITAL_EXPLORER_USER_GUIDING_VISIBLE,
          value: false,
        });
      }
    },

    toggleQuestionsPanel() {
      this.isQuestionsPanelCollapsed = !this.isQuestionsPanelCollapsed;

      if (!this.isQuestionsPanelCollapsed) {
        setTimeout(() => {
          this.elementsVisible = !this.isQuestionsPanelCollapsed;
        }, 200);
      } else {
        this.elementsVisible = false;
      }
    },

    updateResponses(newResponses: IMatchingResponse[]) {
      this.$store.commit(
        `capitalExplorer/${ECapitalExplorerMutations.SET_DATA}`,
        {
          ...this.capitalExplorerData,
          submission: {
            ...this.capitalExplorerSubmission,
            responses: cloneDeep(newResponses),
          },
        },
      );

      if (this.allQuestionsComponentsInitialized) {
        this.saveResponses(newResponses);
      } else {
        this.questionComponentsInitialized++;
      }
    },

    saveResponses: debounce(function (
      this: any,
      responses: IMatchingResponse[],
    ) {
      this.$store.dispatch(ECapitalExplorerActions.PATCH, {
        submissionId: this.capitalExplorerSubmission?.id,
        payload: {
          responses: responses,
        },
      });
    },
    1000),

    clearAnswers() {
      this.updateResponses([]);
      this.shouldResetResponses = true;
    },
  },
});
