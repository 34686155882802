


















import Vue from "vue";

export default Vue.extend({
  name: "MultiSelectorWrapper",

  props: {
    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "common.components.default.input.label",
    },

    tip: {
      type: String,
      default: "common.components.default.input.tip",
    },
  },
});
