











































































































import Vue from "vue";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { IMatchingFilters } from "@/mixins/matching-list.mixin";
import { extractValuesFromFilter } from "@/modules/matching/components/matching-filters/matching-filters.utils";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";

import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";
import {
  MATCHING_SELECTED_FILTERS,
  MATCHING_HAS_SYNCED_FILTERS,
} from "@/modules/matching/constants";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IMatchingQueryFiltersParams } from "@/modules/matching/components/matching-filters/mathing-filters.interface";

export default Vue.extend({
  name: "MatchingFilters",

  components: {
    NetworksSelector,
  },

  props: {
    hasCompaniesSearch: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    editCriteriaButton: {
      icon: "icon-gear--grey",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_GREY,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      filtersOpened: false,
      filters: {
        name: {
          value: "",
          onlyOnDiscover: true,
        },
        network: {
          key: "slug",
          values: [],
        },
      } as IMatchingFilters,
    };
  },

  computed: {
    selectedFilters(): IMatchingFilters | null {
      return (
        this.$store.getters[EMetaGetters.GET](MATCHING_SELECTED_FILTERS) || null
      );
    },

    hasSelectedFilters(): boolean {
      return (
        !!this.selectedFilters &&
        Object.keys(this.selectedFilters)
          .map((k: any) => (this.selectedFilters as IMatchingFilters)[k])
          .some((k: any) => !!k?.values?.length)
      );
    },

    availableQueryParams(): Array<string> {
      return Object.keys(this.filters);
    },

    joinedFiltersParams() {
      const filterParams: { [key: string]: string } = {};

      Object.entries(this.filters).forEach(([key, filter]) => {
        const filterValues = extractValuesFromFilter(filter);
        filterParams[key] = filterValues.join(",");
      });

      return filterParams;
    },

    splittedQueryFilterParams(): IMatchingQueryFiltersParams | null {
      const queryFilters: IMatchingQueryFiltersParams = {};

      this.availableQueryParams.forEach((param: string) => {
        if (param in this.$route.query) {
          const joinedQueryValues = this.$route.query[param] as string;
          queryFilters[param] = joinedQueryValues.split(",");
        }
      });

      return Object.keys(queryFilters).length ? queryFilters : null;
    },

    equalQueryParamsAndFilters(): boolean {
      let areEqual = true;

      for (const filter in this.joinedFiltersParams) {
        if (
          Object.prototype.hasOwnProperty.call(this.joinedFiltersParams, filter)
        ) {
          const filterValues = this.joinedFiltersParams[filter].split(",");

          if (!areEqual) {
            break;
          }

          if (
            this.splittedQueryFilterParams &&
            filter in this.splittedQueryFilterParams
          ) {
            areEqual = isEqual(
              this.splittedQueryFilterParams[filter],
              filterValues,
            );
          }
        }
      }

      return areEqual;
    },

    hasFilters(): boolean {
      return (
        this.filters &&
        Object.keys(this.filters)
          .map((k: any) => this.filters[k])
          .some((k: any) => !!k?.values?.length)
      );
    },

    hasSearchTerm(): boolean {
      return !!this.filters?.name?.value;
    },

    filtersLength(): number {
      return Object.keys(this.filters)
        .map((k: any) => this.filters[k])
        .reduce(
          (total: number, current: any) => total + current?.values?.length || 0,
          0,
        );
    },

    filtersTitle(): string {
      return `${this.$t("matching.matchingFilters.toggleButton")} ${
        this.hasFilters ? `(${this.filtersLength})` : ""
      }`;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: debounce(function (this: any, newValues: IMatchingFilters) {
        const newSelection = newValues ? cloneDeep(newValues) : null;
        this.onFiltersChangeHandler(newSelection);
      }, 600),
    },

    equalQueryParamsAndFilters(areSynced: boolean) {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_HAS_SYNCED_FILTERS,
        value: areSynced,
      });
    },

    hasFilters(hasFilters: boolean) {
      // Open all selected filters on desktop
      if (hasFilters && this.$screen.mdUp) {
        this.filtersOpened = hasFilters;
      }

      // Emit event to inform parent component if either there are filters or search term defined or not
      this.$emit("has-filters-change", hasFilters || this.hasSearchTerm);
    },

    hasSearchTerm(hasSearchTerm: boolean) {
      // Emit event to inform parent component if either there are filters or search term defined or not
      this.$emit("has-filters-change", hasSearchTerm || this.hasFilters);
    },
  },

  created() {
    this.prefillFilters();
  },

  methods: {
    setQuery(query: { [key: string]: any }) {
      const obj = Object.assign({}, this.$route.query);

      Object.keys(query).forEach((key: any) => {
        const value = query[key];
        if (value) {
          obj[key] = value;
        } else {
          delete obj[key];
        }
      });

      if (isEqual(this.$route.query, obj)) {
        return;
      }

      this.$router.replace({
        ...this.$router.currentRoute,
        name: this.$router.currentRoute.name as string,
        query: obj,
      });
    },

    removeQuery(queryNameArray: Array<string>) {
      const obj: { [key: string]: any } = {};
      queryNameArray.forEach((key: any) => {
        obj[key] = null;
      });
      this.setQuery(obj);
    },

    onFiltersChangeHandler(newFilters: IMatchingFilters | null) {
      if (newFilters) {
        this.setQuery(this.joinedFiltersParams);
        this.$store.dispatch(EMetaActions.SET, {
          key: MATCHING_SELECTED_FILTERS,
          value: newFilters,
        });
      } else {
        this.removeQuery(this.availableQueryParams);
        this.$store.dispatch(EMetaActions.REMOVE, MATCHING_SELECTED_FILTERS);
      }
    },

    prefillFilters() {
      if (this.selectedFilters) {
        this.filters = clone(this.selectedFilters);
      }

      // Open all selected filters on desktop
      if (this.hasSelectedFilters && this.$screen.mdUp) {
        this.filtersOpened = true;
      }
    },

    toggleFilters() {
      this.filtersOpened = !this.filtersOpened;
      (this as any).$refs.toggleBtn.$el.blur();
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
