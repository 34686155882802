




























import Vue from "vue";

export default Vue.extend({
  name: "MatchDetailsTableLegend",

  props: {
    legendCards: {
      type: Array,
      required: true,
    },
  },
});
