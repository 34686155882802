















import Vue from "vue";

export default Vue.extend({
  name: "PxNotice",

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "speech-bubble",
    },
    type: {
      type: String,
      default: "default",
      validator: (val: string) => {
        return ["default"].includes(val);
      },
    },
    direction: {
      type: String,
      default: "vertical",
      validator: (val: string) => {
        return ["vertical", "horizontal"].includes(val);
      },
    },
  },
});
