



























import Vue from "vue";

import LevelsDescriptionPlaceholder from "@/modules/affiliates/components/levels-description-placeholder/levels-description-placeholder.vue";

export default Vue.extend({
  name: "DetailsPanelPlaceholder",

  components: {
    LevelsDescriptionPlaceholder,
  },
});
