






































































































import Vue from "vue";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";

import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";

import {
  allFormFieldsValid,
  generateRequiredValidator,
  generateSupporterTypesValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

export default Vue.extend({
  name: "SignUpStepSupporterDetails",

  components: {
    PxInputPlaces,
  },

  props: {
    supporter: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: true,

      hasOtherType: false,
      selectedTypes: [] as Array<number>,

      data: {
        supporter: {
          name: "",
          website: "",
          location: null,
          types: [] as Array<number>,
          otherType: null,
        },
      },
      rules: {
        supporter: {
          name: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.supporterName",
          ),
          location: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.location",
          ),
          website: generateURLValidator(
            this,
            "supporters.signup.form.fields.website",
            true,
          ),
          types: generateSupporterTypesValidator(this, "fields.otherType"),
        },
      },
    };
  },

  computed: {
    signupFormRef(): HTMLElement {
      return this.$refs.signupForm as HTMLElement;
    },

    supporterTypesErrorMessage(): string {
      return this.$t("common.errors.requiredSet", {
        fieldName: this.$t("supporters.signup.form.fields.supporterType"),
      }) as string;
    },

    availableTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    selectedTypes(currentSelectedTypes: Array<number>) {
      // Filter out invalid values
      this.data.supporter.types = currentSelectedTypes.filter(
        (type: number) => !!type,
      );
    },
  },

  methods: {
    otherTypeChangeHandler(checked: boolean) {
      this.hasOtherType = checked;

      if (!checked) {
        this.data.supporter.otherType = null;
      }
    },

    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);

      this.$emit("update:supporter", this.data.supporter);
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
