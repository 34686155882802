





















































































import elDialogMixin from "@/mixins/el-dialog.mixin";

import PxNavigationTab, {
  INavigationTab,
} from "@/components/px-navigation-tab/px-navigation-tab.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import PxTableHeader from "@/components/px-table-header/px-table-header.vue";
import MatchDetailsTableRow from "@/components/match-details-table/match-details-table-row.vue";
import MatchDetailsTableLegend from "@/components/match-details-table/match-details-table-legend.vue";

import {
  ICapitalTile,
  ICapitalMatchProperties,
  ICapitalMatchingDetails,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { TranslateResult } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";

export default elDialogMixin.extend({
  name: "DetailsModal",

  components: {
    PxNavigationTab,
    PxStatusBadge,
    PxTableHeader,
    MatchDetailsTableRow,
    MatchDetailsTableLegend,
  },

  data() {
    return {
      activeTab: 1,
      footerButtons: [
        {
          label: this.$t("capitalExplorer.detailsModal.footerButtons[0].label"),
          icon: "link-grey",
          type: EPxButtonType.DEFAULT,
          href: this.$t(
            "capitalExplorer.detailsModal.footerButtons[0].href",
          ) as string,
        },
        {
          label: this.$t("capitalExplorer.detailsModal.footerButtons[1].label"),
          icon: "icon-briefcase-white",
          type: EPxButtonType.PRIMARY,
          href: this.$t(
            "capitalExplorer.detailsModal.footerButtons[1].href",
          ) as string,
        },
      ],
    };
  },

  props: {
    capitalInfo: {
      type: Object as () => ICapitalTile,
      required: true,
    },

    matchProperties: {
      type: Object as () => ICapitalMatchProperties,
      required: true,
    },

    matchDetails: {
      type: Array as () => ICapitalMatchingDetails[],
      required: true,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    questions(): IMatchingQuestion[] {
      return this.$store.get("capitalExplorer/data").questions;
    },

    matchDetailsOrdered(): ICapitalMatchingDetails[] {
      return cloneDeep(this.matchDetails).sort(
        (a, b) =>
          this.questions.findIndex((q) => q.id === a?.question.id) -
          this.questions.findIndex((q) => q.id === b?.question.id),
      );
    },

    tabs(): INavigationTab[] {
      return [
        {
          id: 1,
          title: this.$t("capitalExplorer.detailsModal.tabs[0]") as string,
          visible: !!this.capitalInfo.about,
        },
        {
          id: 2,
          title: this.$t("capitalExplorer.detailsModal.tabs[1]") as string,
          visible: !!this.capitalInfo.key_characteristics,
        },
        {
          id: 3,
          title: this.$t("capitalExplorer.detailsModal.tabs[2]") as string,
          visible: !!this.capitalInfo.key_implications,
        },
        {
          id: 4,
          title: this.$t("capitalExplorer.detailsModal.tabs[3]") as string,
          visible: !!this.capitalInfo.related_links,
        },
        {
          id: 5,
          title: this.$t("capitalExplorer.detailsModal.tabs[4]") as string,
          icon: "matching--white",
          visible: true,
        },
      ];
    },

    visibleTabs(): INavigationTab[] {
      return this.tabs.filter((tab) => tab.visible);
    },

    tabContent(): string | null {
      return this.activeTab === 1
        ? this.capitalInfo.about
        : this.activeTab === 2
        ? this.capitalInfo.key_characteristics
        : this.activeTab === 3
        ? this.capitalInfo.key_implications
        : this.capitalInfo.related_links;
    },

    matchingBadgeLabel(): string {
      return this.matchProperties.matchingBadge
        ? (this.$t(this.matchProperties.matchingBadge?.title) as string)
        : "";
    },

    matchingBadgeStyles(): { [key: string]: string | number } {
      return {
        boxShadow: this.matchProperties.matchingBadge?.boxShadow || "",
      };
    },

    tableHeaders(): TranslateResult {
      const tableHeaders = cloneDeep(
        this.$t("capitalExplorer.detailsModal.tableHeader"),
      ) as any;

      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      tableHeaders[1].title = tableHeaders[1].title[isVisitorsView ? 1 : 0];

      return tableHeaders;
    },

    legend(): TranslateResult {
      return this.$t(`capitalExplorer.detailsModal.legend`);
    },
  },
});
