import { render, staticRenderFns } from "./assessment-panel--history-inactive.vue?vue&type=template&id=4eda2ad7&scoped=true&"
import script from "./assessment-panel--history-inactive.vue?vue&type=script&lang=ts&"
export * from "./assessment-panel--history-inactive.vue?vue&type=script&lang=ts&"
import style0 from "./assessment-panel--history-inactive.vue?vue&type=style&index=0&id=4eda2ad7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eda2ad7",
  null
  
)

export default component.exports