

































import Vue from "vue";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "OverallLevel",

  static: {
    quickUpdateButton: {
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  props: {
    level: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  computed: {
    quickUpdateButtonProps(): any {
      return {
        ...this.$options.static.quickUpdateButton,
        label: this.$t("milestonePlanner.quickUpdate") as string,
      };
    },
  },

  methods: {
    onQuickUpdateClick() {
      this.$store.dispatch(EViralLevelActions.RESET);

      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },
  },
});
