































































import get from "lodash/get";
import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default PxMatchingInterestMixin.extend({
  name: "PxMatchingInterest",

  props: {
    withDescription: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      needsToHideTooltip: false,
    };
  },

  computed: {
    matchingTip(): string {
      if (this.targetInterest)
        return this.$t(
          `matching.matchingInterest.cta[${this.currentCTAState}].tip`,
          { company: this.name },
        ) as string;

      return "";
    },
    ctaIconSize(): number {
      return get(
        (this as any).iconSizes,
        this.size,
        this.cta.iconSizes.default,
      );
    },
  },

  watch: {
    isLoading() {
      this.needsToHideTooltip = true;
    },
  },

  methods: {
    mouseOverCTAHandler() {
      this.needsToHideTooltip = false;
    },

    setCtaHoverState(state: boolean) {
      this.isCtaHover = state;
    },
  },
});
