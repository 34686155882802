import { Route, RouteConfig } from "vue-router";

import { ROUTE_ONLY_GUESTS } from "@/services/router/router-names";
import routesRegistration from "./routes-registration";
import routesProgram from "./routes-program";

import AuthBase from "@/modules/authentication/views/auth-base.vue";
import AuthSignUp from "@/modules/supporters/views/authentication/auth-signup.vue";
import AuthSignUpSuccess from "@/modules/supporters/views/authentication/auth-signup-success.vue";
import AuthBaseLogin from "@/modules/authentication/views/auth-base-login.vue";
import AuthBasePasswordRecover from "@/modules/authentication/views/auth-base-password-recover.vue";
import AuthBasePasswordReset from "@/modules/authentication/views/auth-base-password-reset.vue";
import AuthVerifyAccount from "@/modules/authentication/views/auth-verify-account.vue";

import {
  SUPPORTERS_AUTHENTICATION_LOGIN,
  SUPPORTERS_AUTHENTICATION_PASSWORD_RECOVER,
  SUPPORTERS_AUTHENTICATION_PASSWORD_RESET,
  SUPPORTERS_AUTHENTICATION_SIGNUP,
  SUPPORTERS_AUTHENTICATION_SIGNUP_SUCCESS,
  SUPPORTERS_AUTHENTICATION_VERIFY_ACCOUNT,
} from "@/modules/supporters/services/router/routes-names";

export const routes: RouteConfig[] = [
  routesRegistration,
  routesProgram,

  {
    path: "/supporters",
    component: AuthBase,
    children: [
      {
        path: "sign-up",
        name: SUPPORTERS_AUTHENTICATION_SIGNUP,
        component: AuthSignUp,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "sign-up-success",
        name: SUPPORTERS_AUTHENTICATION_SIGNUP_SUCCESS,
        component: AuthSignUpSuccess,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "login",
        name: SUPPORTERS_AUTHENTICATION_LOGIN,
        component: AuthBaseLogin,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "forgot-password",
        name: SUPPORTERS_AUTHENTICATION_PASSWORD_RECOVER,
        component: AuthBasePasswordRecover,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "reset-password/:uid/:token",
        name: SUPPORTERS_AUTHENTICATION_PASSWORD_RESET,
        component: AuthBasePasswordReset,
        meta: ROUTE_ONLY_GUESTS,
      },
      {
        path: "verify-account/:hash",
        name: SUPPORTERS_AUTHENTICATION_VERIFY_ACCOUNT,
        component: AuthVerifyAccount,
      },
    ],
  },

  {
    path: "/supporter/*",
    redirect: (to: Route) => {
      return to.path.replace("/supporter", "/supporters");
    },
  },
];
