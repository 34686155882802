























import Vue from "vue";
import RenderlessEntrepreneurAssessment from "@/modules/affiliates/components/renderless-entrepreneur-assessment/renderless-entrepreneur-assessment.vue";
import CategoryLevelsAssessmentMobile from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-mobile.vue";
import CategoryLevelsAssessmentDesktop from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-desktop.vue";

export default Vue.extend({
  name: "CategoryLevelsAssessment",

  components: {
    RenderlessEntrepreneurAssessment,
    CategoryLevelsAssessmentMobile,
    CategoryLevelsAssessmentDesktop,
  },

  props: {
    isSelfAssessment: {
      type: Boolean,
      default: false,
    },
  },
});
