











import Vue from "vue";

import SharePlannerUsersIndex from "@/modules/milestone-planner/components/share-planner/share-planner-users-index.vue";
import SharePlannerActions from "@/modules/milestone-planner/components/share-planner/share-planner-actions.vue";

import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

export default Vue.extend({
  name: "SharePlannerUsers",

  components: {
    SharePlannerUsersIndex,
    SharePlannerActions,
  },

  props: {
    users: {
      type: Array as () => IMilestonePlannerInvitedUser[],
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },
});
