var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"affiliate-review-page-team-members-details"},[_c('div',{staticClass:"affiliate-review-page-team-members-details__top",style:({
      'border-bottom':
        _vm.questions && _vm.questions.length ? '1px solid #f0f1f5' : 'none',
    })},[_c('div',{staticClass:"affiliate-review-page-team-members-details__wrapper"},[_c('div',{staticClass:"affiliate-review-page-team-members-details__headers"},[_c('h3',[_vm._v(_vm._s(_vm.$t("teamManagement.settings.mandatoryData.firstName")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("teamManagement.settings.mandatoryData.lastName")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("teamManagement.settings.mandatoryData.position")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("common.email")))])]),_c('div',{staticClass:"affiliate-review-page-team-members-details__info"},[_c('p',{staticClass:"affiliate-review-page-team-members-details__info-content",class:{
            'affiliate-review-page-team-members-details__missing-info':
              !_vm.teamMember.first_name,
          },attrs:{"title":_vm.firstName}},[_vm._v(" "+_vm._s(_vm.firstName)+" ")]),_c('p',{staticClass:"affiliate-review-page-team-members-details__info-content",class:{
            'affiliate-review-page-team-members-details__missing-info':
              !_vm.teamMember.last_name,
          },attrs:{"title":_vm.lastName}},[_vm._v(" "+_vm._s(_vm.lastName)+" ")]),_c('p',{staticClass:"affiliate-review-page-team-members-details__info-content",class:{
            'affiliate-review-page-team-members-details__missing-info':
              !_vm.teamMember.position,
          },attrs:{"title":_vm.position}},[_vm._v(" "+_vm._s(_vm.position)+" ")]),_c('p',{staticClass:"affiliate-review-page-team-members-details__info-content",class:{
            'affiliate-review-page-team-members-details__missing-info':
              !_vm.teamMember.email,
          },attrs:{"title":_vm.email}},[_vm._v(" "+_vm._s(_vm.email)+" ")])])]),(_vm.label)?_c('PxStatusBadge',{staticClass:"affiliate-review-page-team-members-details__label",attrs:{"label":_vm.label.text,"label-color":_vm.label.textColor,"label-size":13,"background-color":_vm.label.backgroundColor,"icon":_vm.label.icon,"icon-size":_vm.label.iconSize,"uppercase":false,"icon-position":"left"}}):_vm._e()],1),_c('div',{staticClass:"affiliate-review-page-team-members-details__bottom"},[(_vm.questions && _vm.questions.length)?_c('div',{staticClass:"affiliate-review-page-team-members-details__questions-wrapper"},_vm._l((_vm.questions),function(question){return _c('div',{key:question.id,staticClass:"affiliate-review-page-team-members-details__question"},[_c('h3',{attrs:{"title":question.entrepreneur_question}},[_vm._v(" "+_vm._s(question.entrepreneur_question)+" ")]),_c('p',{attrs:{"title":_vm.formattedResponse(question)},domProps:{"innerHTML":_vm._s(_vm.formattedResponse(question))}})])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }