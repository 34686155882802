

















































import Vue from "vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { INetwork } from "@/services/data/network/network.interface";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";
import { EAuthCompanyActions } from "@/modules/authentication/services/store/auth/auth-types";

export default Vue.extend({
  name: "NetworksEditModal",

  components: {
    NetworksSelector,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      originalData: {},

      form: {
        data: {
          networks: [] as Array<number>,
        },
      },
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    networks(): Array<INetwork> | null {
      return this.company ? this.company.networks : null;
    },

    dialogConfirmationOptions(): any {
      return [
        {
          eventName: "confirm-cancel-edit",
          showCondition: () => this.hasFormChanged,
          message: this.$t("common.dialogConfirmation.discardMessage"),
          confirmFn: this.onConfirmCancel,
        },
      ];
    },

    /**
     * Check if the form was changed.
     */
    hasFormChanged(): boolean {
      return !isEqual(this.form.data, this.originalData);
    },
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }

        this.resetFormData();
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onConfirmCancel() {
      this.innerVisibility = false;
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    async resetFormData() {
      // Set the networks
      await this.$nextTick();
      const networkSelector = this.$refs.networkSelector as any;
      if (networkSelector && this.networks) {
        networkSelector.setActiveNetworks(cloneDeep(this.networks));
      }

      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);
    },

    async onClickSave() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;

      try {
        await this.$store.dispatch(EAuthCompanyActions.PATCH, this.form.data);
      } catch (error) {
        this.hasServerErrors = true;
        return;
      } finally {
        this.isLoadingSubmission = false;
      }

      this.$emit("submit");

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t("profile.companyEditModal.successMessage") as string,
        type: "success",
        customClass: "is-full",
      });

      this.innerVisibility = false;
    },
  },
});
