





































import Vue from "vue";

export interface IChartLockedContent {
  image: string;
  title: string;
  description: string;
  note: string;
}

export default Vue.extend({
  name: "ChartLocked",

  props: {
    content: {
      type: Object as () => IChartLockedContent,
      required: true,
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
