





















import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "CriticalMilestoneIndicator",

  props: {
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    criticalTooltipCopy(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.critical.guest",
      );
    },
  },
});
