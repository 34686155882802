
import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { ICategory } from "@/services/data/category/category.interface";
import {
  EViralLevelActions,
  EViralLevelGetters,
  IViralLevelState,
} from "@/services/store/viral-level/viral-level-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { SELF_ASSESSMENT_STARTING_POINT } from "@/modules/self-assessment/services/router/routes-names";
import {
  SELF_ASSESSMENT_BEGAN_STATE,
  SELF_ASSESSMENT_FINISHED_STATE,
  SELF_ASSESSMENT_START_CAT_TIME,
  SELF_ASSESSMENT_START_TIME,
} from "@/modules/self-assessment/constants";
import { gaTrackEvent, gaTrackTime } from "@/services/utils/ga.utils";
import { heapTrack } from "@/services/utils/heap.utils";
import { IPendingUser } from "@/services/store/viral-level/types/pending-user.interface";
import { defaultAffiliateId } from "@/services/configs";

import { IUser } from "@/services/data/user/user.interface";

import { pendingSelfAssessmentProvider } from "@/modules/self-assessment/services/data/pending-self-assessment/pending-self-assessment.provider";
import { pendingProgramAssessmentProvider } from "@/modules/affiliate-program/services/data/pending-program-assessment/pending-program-assessment.provider";
import { programAssessmentProvider } from "@/modules/affiliate-program/services/data/program-assessment/program-assessment.provider";
import {
  ENTREPRENEUR_USER_GROUP_ID,
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
} from "@/modules/common/constants";
import { assessmentProvider } from "@/services/data/assessment/assessment.provider";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default Vue.extend({
  name: "RenderlessEntrepreneurAssessment",

  props: {
    endAction: {
      type: Function as () => any,
      required: true,
    },
    isAffiliateFlow: {
      type: Boolean,
      default: false,
    },
    needsSubmissionConfirmation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    companyToken(): string | null {
      return this.$route.query && (this.$route.query.token as string | null);
    },

    viralLevelState(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    /**
     * Index of the current selected category.
     */
    selectedCategory(): number {
      return this.viralLevelState.selectedCategory;
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.viralLevelState.levels;
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      return this.$store.getters[EViralLevelGetters.SELECTED_STEP_DATA];
    },

    /**
     * Get all categories from the store.
     */
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Get the category for the current step.
     */
    currentCategory(): ICategory | null {
      return this.$store.getters[EViralLevelGetters.CURRENT_CATEGORY];
    },

    isFirstInteraction(): boolean {
      return this.viralLevelState.firstInteraction;
    },

    finalLevel(): any | undefined {
      return this.viralLevelState.finalLevel;
    },

    latestAssessment(): IAssessment | null {
      return this.$store.get("auth/latestAssessment/data");
    },

    affiliateId(): number {
      const affiliate = this.viralLevelState.affiliate;

      return affiliate && affiliate.id ? affiliate.id : defaultAffiliateId();
    },

    isPendingAssessmentActive(): boolean {
      return this.$features.isEnabled("assessmentUserInfoBeforeHand");
    },

    pendingUser(): IPendingUser {
      return this.$store.get("viralLevel/pendingUser");
    },

    authUser(): IUser | null {
      return this.$store.get("auth/user");
    },

    hasUserEntry(): boolean {
      return (!!this.authUser && this.$user.isLogged()) || !!this.pendingUser;
    },

    isPendingAssessment(): boolean {
      const isFirstAssessment = this.pendingUser
        ? this.pendingUser.assessment.state === SELF_ASSESSMENT_BEGAN_STATE
        : false;

      return (
        this.isPendingAssessmentActive &&
        !!this.pendingUser &&
        (isFirstAssessment ||
          !isEqual(this.pendingUser.assessment.levels, this.levels))
      );
    },

    company(): ICompany {
      return this.$store.get("auth/company.data");
    },
  },

  watch: {
    currentCategory() {
      this.gaStart();
    },

    latestAssessment: {
      immediate: true,
      handler(latestAssessment: IAssessment | null) {
        if (this.authUser && latestAssessment) {
          this.loadPreviousAssessment(latestAssessment);
        }
      },
    },
  },

  async created() {
    // TODO: validate if token was already used
    if (this.levels.length === 0 && this.companyToken) {
      await this.onTokenSelfAssessmentLoad();
    }

    // Ensure the user doesn't gets to this route directly, expect if token is present
    if (this.levels.length === 0) {
      this.$router.push({ name: SELF_ASSESSMENT_STARTING_POINT });
    } else if (this.finalLevel && this.selectedCategory) {
      await this.$store.dispatch(
        EViralLevelActions.SET_SELECTED_CATEGORY,
        this.levels.length - 1,
      );
    }

    /**
     * Set state with previous assessment
     */
    if (
      this.latestAssessment &&
      this.authUser &&
      this.isPendingAssessmentActive
    ) {
      this.loadPreviousAssessment(this.latestAssessment);
    }
  },

  methods: {
    gaStart() {
      if (!this.selectedStepData || this.selectedStepData.level !== 0) {
        return;
      }

      // Register the start category time and the category
      // that are being filled.
      localStorage.setItem(
        SELF_ASSESSMENT_START_CAT_TIME,
        new Date().toISOString(),
      );
    },

    gaEnd() {
      // This never happens, but without this TS gives an error
      if (!this.currentCategory || this.selectedStepData === null) {
        return;
      }

      // Registers the time that user take to fill the
      // category
      const stringTime = localStorage.getItem(
        SELF_ASSESSMENT_START_CAT_TIME,
      ) as string;
      const startTime = new Date(stringTime);
      const endTime = new Date();

      // Elapsed time between the start and end of the category level selection
      let duration = (endTime.getTime() - startTime.getTime()) / 1000;
      duration = Math.round(duration);

      gaTrackTime(
        "duration",
        duration,
        "Self-Assessment Category",
        this.currentCategory.name,
      );

      heapTrack("Self-Assessment Category", {
        name: this.currentCategory.name,
        duration,
        level: this.selectedStepData.level,
      });

      // Informs GA the user filled the current category
      gaTrackEvent(
        "category-filled",
        "Self-Assessment",
        this.currentCategory.name,
      );
    },

    /**
     * Fetch categories when self assessment is loaded directly by token
     */
    async onTokenSelfAssessmentLoad() {
      // Fetch categories asynchronously
      await this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });

      // Then dispatch viral level reset
      await this.$store.dispatch(EViralLevelActions.RESET);
    },

    /**
     * Go to previous category.
     */
    goPrevCategoryHandler() {
      this.$store.dispatch(
        EViralLevelActions.SET_SELECTED_CATEGORY,
        this.selectedCategory - 1,
      );
    },

    handleLevelSliderChange(newValue: number) {
      if (!this.currentCategory) {
        throw new Error("Invalid state");
      }

      this.$store.dispatch(EViralLevelActions.SET_LEVEL, {
        category: this.currentCategory.id,
        level: newValue,
      });

      this.$store.dispatch(EViralLevelActions.INTERACTION);
    },

    /**
     * Handle the click on the next button.
     */
    async goNextCategoryHandler() {
      const newLevel = this.selectedCategory + 1;

      // Register GA category completion event
      this.gaEnd();

      // When the user is on the last step must be redirected into the
      // results page and an API request must be sent to compute
      // the VIRAL level.
      if (newLevel >= this.categories.length) {
        await this.$store.dispatch(EViralLevelActions.SET_LOADING, true);

        await this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
          skipLoading: true,
        });

        // Submit assessment
        if (this.$user.isLogged()) {
          if (this.isAffiliateFlow) {
            await this.submitAffiliateAuthAssessment();
          } else if (!this.needsSubmissionConfirmation) {
            await this.submitAuthAssessment();
          }
        } else if (this.isPendingAssessment) {
          if (this.isAffiliateFlow) {
            await this.submitAffiliatePendingUserAssessment();
          } else {
            await this.submitPendingUserAssessment();
          }
        }

        await this.$store.dispatch(EViralLevelActions.SET_LOADING, false);

        this.endAction();

        return;
      }

      await this.$store.dispatch(
        EViralLevelActions.SET_SELECTED_CATEGORY,
        newLevel,
      );
    },

    async submitAffiliateAuthAssessment() {
      try {
        // Create assessment
        await programAssessmentProvider.create({
          affiliate: this.affiliateId,
          levels: cloneDeep(this.levels),
          group: ENTREPRENEUR_USER_GROUP_ID,
        });

        // Update assessment on store:
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      } catch (error) {
        const submissionErrorMessage = this.$root.$t(
          "affiliateProgram.assessment.submissionError",
        ) as string;

        this.$message({
          message: submissionErrorMessage,
          type: "error",
          customClass: "is-full",
        });
      }
    },

    async submitAffiliatePendingUserAssessment() {
      try {
        // Create assessment
        await pendingProgramAssessmentProvider.create({
          levels: cloneDeep(this.levels),
          email: this.pendingUser.email,
          group: ENTREPRENEUR_USER_GROUP_ID,
          affiliate: this.affiliateId,
        });

        // Store assessment levels and state
        await this.$store.dispatch(EViralLevelActions.SET_PENDING_ASSESSMENT, {
          levels: cloneDeep(this.levels),
          state: SELF_ASSESSMENT_FINISHED_STATE,
        });
      } catch (error) {
        const submissionErrorMessage = this.$root.$t(
          "affiliateProgram.assessment.submissionError",
        ) as string;

        this.$message({
          message: submissionErrorMessage,
          type: "error",
          customClass: "is-full",
        });
      }
    },

    async submitAuthAssessment() {
      // When the user is making the first assessment create a
      // metadata to inform the other components that the first
      // assessment was made.
      if (this.latestAssessment === null) {
        await this.$store.dispatch(EMetaActions.SET, {
          key: QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
          value: true,
        });
      }

      try {
        await assessmentProvider.register(ENTREPRENEUR_USER_GROUP_ID, {
          levels: this.levels.map(
            (e: ICategoryLevelSelection) =>
              ({
                ...e,
                level: e.level === 0 ? null : e.level,
              } as IAssessmentLevel),
          ),
        });
        // Update assessment on store:
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      } catch (error) {
        const submissionErrorMessage = this.$root.$t(
          "selfAssessment.assessment.submissionError",
        ) as string;

        this.$message({
          message: submissionErrorMessage,
          type: "error",
          customClass: "is-full",
        });
      }
    },

    async submitPendingUserAssessment() {
      try {
        // Create assessment
        await pendingSelfAssessmentProvider.create({
          levels: cloneDeep(this.levels),
          affiliate: this.affiliateId,
          email: this.pendingUser.email,
          vendor: this.pendingUser.vendor || null,
          group: ENTREPRENEUR_USER_GROUP_ID,
        });

        // Store assessment levels and state
        await this.$store.dispatch(EViralLevelActions.SET_PENDING_ASSESSMENT, {
          levels: cloneDeep(this.levels),
          state: SELF_ASSESSMENT_FINISHED_STATE,
        });
      } catch (error) {
        const submissionErrorMessage = this.$root.$t(
          "selfAssessment.assessment.submissionError",
        ) as string;

        this.$message({
          message: submissionErrorMessage,
          type: "error",
          customClass: "is-full",
        });
      }
    },

    gaRegisterStart() {
      // Register the start time of self assessment flow
      localStorage.setItem(
        SELF_ASSESSMENT_START_TIME,
        new Date().toISOString(),
      );

      // Send an event informing that the user started
      // the self-assessment flow
      gaTrackEvent("start", "Self-Assessment");
    },

    /**
     * Loads the previous assessment chosen levels for each category
     * @param assessment
     */
    loadPreviousAssessment(assessment: IAssessment) {
      assessment.data.map((item: IAssessmentLevel) => {
        this.$store.dispatch(EViralLevelActions.SET_LEVEL, {
          ...item,
          level: item.level || 0,
        });
      });
    },
  },

  render(): any {
    if (this.$scopedSlots.default === undefined) {
      return;
    }

    return this.$scopedSlots.default({
      currentCategory: this.currentCategory,
      selectedStep: this.selectedStepData,
      onLevelChange: this.handleLevelSliderChange,
      selectedCategory: this.selectedCategory,
      levels: this.levels,
      goNextCategoryHandler: this.goNextCategoryHandler,
      goPrevCategoryHandler: this.goPrevCategoryHandler,
      isFirstInteraction: this.isFirstInteraction,
      hasUserEntry: this.hasUserEntry,
    });
  },
});
