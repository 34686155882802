






















































import Vue from "vue";
import throttle from "lodash/throttle";

export interface IModalListGroup {
  name: string;
  items: Array<string>;
}

export default Vue.extend({
  name: "PxListModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    items: {
      type: Array as () => string[] | IModalListGroup[],
      required: true,
      default: () => [],
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerVisibility: false,
      itemsOpened: [] as number[],
      itemsScrolling: [] as number[],
    };
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onClickToggleItems(itemKey: string) {
      const index = +itemKey;
      const itemsElement = this.$refs.item
        ? (this.$refs.item as Array<HTMLElement>)
        : [];

      if (itemsElement.length && itemsElement[index]) {
        if (this.hasItemsOpened(index)) {
          this.itemsOpened = this.itemsOpened.filter(
            (itemIndex) => itemIndex !== index,
          );
        } else {
          this.itemsOpened.push(index);
        }
      }
    },

    hasItemsOpened(itemIndex: string | number) {
      return this.itemsOpened.includes(+itemIndex);
    },

    listGroupScrollHandler(itemKey: string) {
      throttle(() => {
        const index = +itemKey;
        const itemsElement = this.$refs.item
          ? (this.$refs.item as Array<HTMLElement>)
          : [];
        const currentListElement =
          itemsElement.length && itemsElement[index]
            ? itemsElement[index]
            : null;

        if (currentListElement) {
          const scrollingContainer = currentListElement.querySelector(
            ".px-list-modal__group-items",
          );

          if (scrollingContainer && scrollingContainer.scrollTop) {
            this.itemsScrolling.push(index);
          } else {
            this.itemsScrolling = this.itemsScrolling.filter(
              (itemIndex) => itemIndex !== index,
            );
          }
        }
      }, 200)();
    },

    isScrollingList(itemIndex: string | number) {
      return this.itemsScrolling.includes(+itemIndex);
    },
  },
});
