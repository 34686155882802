import { getAppVersion, getAppEnv, getEnv } from "@/services/utils/utils";

// Create Bugsnag instance and install it on Vue
import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { isAllowedCookieGroup } from "@/services/utils/onetrust";

/**
 * Bugsnag in the project
 */
export const bugsnagStart = () => {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY || "",
    appVersion: getAppVersion(),
    releaseStage: getAppEnv(),
    plugins: [new BugsnagPluginVue(Vue)],
    enabledBreadcrumbTypes: ["error", "navigation", "request", "user"],
    onError: (event: any) => {
      // Ignore local development errors
      if (getEnv() !== "production" || !isAllowedCookieGroup("performance")) {
        return false;
      }

      // Make sure FullStory object exists.
      if ((window as any).FS && (window as any).FS.getCurrentSessionURL) {
        event.addMetadata("fullstory", {
          urlAtTime: (window as any).FS.getCurrentSessionURL(true),
        });
      }
    },
  });
};
