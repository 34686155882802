
























import elDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";

export default elDialogMixin.extend({
  name: "ChartsLockedModal",

  props: {
    icon: {
      type: String,
      default: "private",
    },

    title: {
      type: String,
      default: "chartsDashboard.privacyModal.title",
    },

    description: {
      type: String,
      default: "chartsDashboard.privacyModal.description",
    },

    ctaLabel: {
      type: String,
      default: "chartsDashboard.privacyModal.cta",
    },

    width: {
      type: String,
      default: "660px",
    },

    closable: {
      type: Boolean,
      default: true,
    },

    showMask: {
      type: Boolean,
      default: true,
    },

    marginTop: {
      type: String,
      default: "15vh",
    },
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.BLUE,
        label: this.$t(this.ctaLabel),
      };
    },
  },

  methods: {
    onButtonClick() {
      return this.$emit("click");
    },
  },
});
