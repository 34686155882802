
















import Vue from "vue";

export default Vue.extend({
  name: "GridLevels",

  props: {
    levelsCount: {
      type: Number,
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },
  },
});
