













































import Vue from "vue";

import RenderlessAssessmentPanel from "@/modules/profile/components/renderless-assessment-panel/renderless-assessment-panel.vue";
import AssessmentPanelPlaceholder from "@/modules/profile/components/assessment-panel/assessment-panel-placeholder.vue";
import AssessmentPanelLevel from "@/modules/profile/components/assessment-panel/assessment-panel--level.vue";
import AssessmentPanelLevelInactive from "@/modules/profile/components/assessment-panel/assessment-panel--level-inactive.vue";
import AssessmentPanelGrid from "@/modules/profile/components/assessment-panel/assessment-panel--grid.vue";
import AssessmentPanelGridInactive from "@/modules/profile/components/assessment-panel/assessment-panel--grid-inactive.vue";
import AssessmentPanelHistory from "@/modules/profile/components/assessment-panel/assessment-panel--history.vue";
import AssessmentPanelHistoryInactive from "@/modules/profile/components/assessment-panel/assessment-panel--history-inactive.vue";

export default Vue.extend({
  name: "AssessmentPanel",

  components: {
    RenderlessAssessmentPanel,
    AssessmentPanelLevel,
    AssessmentPanelLevelInactive,
    AssessmentPanelGrid,
    AssessmentPanelGridInactive,
    AssessmentPanelPlaceholder,
    AssessmentPanelHistory,
    AssessmentPanelHistoryInactive,
  },
});
