





















































import Vue from "vue";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default Vue.extend({
  name: "MatchingEmptyResults",

  props: {
    icon: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.icon",
    },
    title: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.title",
    },
    subtitle: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.subtitle",
    },
    notice: {
      type: Boolean,
      required: false,
      default: true,
    },
    noticeTitle: {
      type: String,
      required: false,
      default: "matching.matchingList.noMoreState.notice.title",
    },
    noticeButton: {
      type: String,
      required: false,
      default: "matching.matchingList.emptyState.notice.button",
    },
  },

  computed: {
    userId(): string {
      return this.$store.state.auth.profileId;
    },

    userEmail(): string {
      return this.$store.state.auth.user.email;
    },

    targetUser(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return `${this.$t(`common.userTypes[${oppositeUserType}]`)}s` as string;
    },

    emptyStateIcon(): string {
      return this.$user.isEntrepreneur() ? "speech-bubble" : "icon-gear";
    },
  },

  methods: {
    onClickOpenFeedbackPage() {
      this.$user.isSupporter()
        ? window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL)
        : window.open(`https://info302232.typeform.com/to/IoMvns`);
    },

    goToMatches() {
      this.$root.$emit("go-to-matches");
    },

    openQuestionsSidebar() {
      this.$root.$emit("open-questions-sidebar");
    },
  },
});
