

























import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "MilestoneTooltipButtons",

  props: {
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    isVisitor: { type: Boolean, default: false },
  },

  data() {
    return {
      buttonSizes: EPxButtonSize,
    };
  },

  computed: {
    planButtonLabel(): TranslateResult {
      return this.isVisitor
        ? this.$t("milestonePlanner.milestone.actions.viewPlan")
        : this.isMilestonePlanned
        ? this.$t("milestonePlanner.milestone.actions.editPlan")
        : this.isFutureMilestone
        ? this.$t("milestonePlanner.milestone.actions.planAnyway")
        : this.$t("milestonePlanner.milestone.actions.plan");
    },

    planButtonType(): EPxButtonType {
      return (!this.isMilestonePlanned && !this.isMilestoneInProgress) ||
        this.isVisitor
        ? EPxButtonType.BLUE
        : EPxButtonType.GHOST_BLUE;
    },

    completeButtonType(): EPxButtonType {
      return this.isMilestonePlanned || this.isMilestoneInProgress
        ? EPxButtonType.BLUE
        : EPxButtonType.GHOST_BLUE;
    },
  },
});
