









































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import PxNavigationStep from "@/components/px-navigation-step/px-navigation-step.vue";

export default Vue.extend({
  name: "PxStepByStepDialogNavigation",

  components: {
    PxNavigationStep,
  },

  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },

  static: {
    nextButton: {
      type: EPxButtonType.BLUE,
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    previousButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    previousButtonProps(): any {
      return {
        ...this.$options.static.previousButton,
        label: this.$t("common.previous"),
      };
    },

    nextButtonProps(): any {
      return {
        ...this.$options.static.nextButton,
        label: this.nextButtonLabel,
      };
    },

    nextButtonLabel(): TranslateResult {
      return this.isLastPage ? this.$t("common.gotIt") : this.$t("common.next");
    },

    isLastPage(): boolean {
      return this.currentPage === this.pageCount;
    },
  },

  methods: {
    goToPage(page: number) {
      if (page !== this.currentPage && page >= 1 && page <= this.pageCount) {
        this.$emit("update:currentPage", page);
        this.$emit("page-change", page);
      }
    },

    nextButtonClick() {
      this.isLastPage
        ? this.$emit("close-dialog")
        : this.goToPage(this.currentPage + 1);
    },
  },
});
