import { Route } from "vue-router";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { featureManager } from "@/services/feature-manager";

/**
 * Guard to deny access to matching.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export const preventMatchingAccessGuard = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  if (!featureManager.isEnabled("match")) {
    next({
      name: ERROR_ROUTE,
    });
  } else {
    next();
  }
};
