


































import Vue from "vue";

import MatchingListInterestsTargets from "@/modules/matching/components/matching-list-interests/matching-list-interests-targets.vue";
import MatchingListInterestsUser from "@/modules/matching/components/matching-list-interests/matching-list-interests-user.vue";

import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  MATCHING_SELECTED_TAB,
  MATCHING_PENDING_SELECTED_TAB,
} from "@/modules/matching/constants";
import { EMatchingListPendingTabs } from "@/modules/matching/components/matching-list-desktop/matching-tabs.types";

export default Vue.extend({
  name: "MatchingListInterests",

  components: {
    MatchingListInterestsTargets,
    MatchingListInterestsUser,
  },

  data() {
    return {
      EMatchingListPendingTabs,
      interestTabs: {
        [EMatchingListPendingTabs.INTERESTED_IN_ME]: {
          label: this.$t("matching.matchingInterest.tabs.interestedInMe"),
        },

        [EMatchingListPendingTabs.IM_INTERESTED_IN]: {
          label: this.$t("matching.matchingInterest.tabs.imInterestedIn"),
        },
      },
      activeTab: "",
      userInterestsModalVisibility: false,
      needsStickyTabBar: false,
    };
  },

  computed: {
    selectedTab(): string {
      return this.$store.get("meta/get", MATCHING_PENDING_SELECTED_TAB);
    },

    targetUser(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return `${this.$t(`common.userTypes[${oppositeUserType}]`)}s` as string;
    },

    targetScores(): IMatchingScore[] {
      return this.$store.state.matchingInterest.targetMatchScores;
    },

    /**
     * Verify if interest page is loading
     */
    isInterestLoading(): boolean {
      return this.$store.state.matchingInterest.loading;
    },
  },

  watch: {
    selectedTab: {
      immediate: true,
      handler(tab: EMatchingListPendingTabs) {
        if (!tab) return;
        this.activeTab = tab;
      },
    },

    activeTab: {
      immediate: true,
      handler(tab: EMatchingListPendingTabs) {
        this.$emit("on-tab-changed", tab);
      },
    },

    isInterestLoading: {
      immediate: true,
      handler(state: boolean) {
        if (this.selectedTab) return;

        if (state) {
          this.activeTab = EMatchingListPendingTabs.IM_INTERESTED_IN;
          return;
        }

        this.activeTab = this.targetScores.length
          ? EMatchingListPendingTabs.INTERESTED_IN_ME
          : EMatchingListPendingTabs.IM_INTERESTED_IN;
      },
    },
  },

  created() {
    this.$root.$on("go-to-matches", this.goToMatches);
    this.$root.$on("tab-bar-small", this.updateStickyTabBarDisplay);
  },

  methods: {
    goToMatches() {
      this.userInterestsModalVisibility = false;
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_SELECTED_TAB,
        value: "discover",
      });
    },

    clickTabHandler() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_PENDING_SELECTED_TAB,
        value: this.activeTab,
      });
    },

    updateStickyTabBarDisplay(isVisible: boolean) {
      this.needsStickyTabBar = isVisible;
    },
  },
});
