

















































import Vue from "vue";

import DemographicCharts from "@/modules/charts-dashboard/components/demographic-charts/demographic-charts.vue";
import CompanyListsEmpty from "@/modules/company-lists/components/company-lists/company-lists-empty.vue";

import { IReportsViewThemes } from "@/modules/charts-dashboard/views/charts-dashboard.view.vue";
import { IListReportsDemographicData } from "../services/data/lists-reports-data/lists-reports-data.interface";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { ROUTE_COMPANY_LISTS_DIRECTORY } from "@/modules/company-lists/services/router/routes-names";
import { GENDER_MOCK_DATA } from "./supporter-free-chart-mock-data";
import { listsReportsDataProvider } from "../services/data/lists-reports-data/lists-reports-data.provider";

export default Vue.extend({
  name: "ListReportsView",

  components: {
    DemographicCharts,
    CompanyListsEmpty,
  },

  props: {
    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  data() {
    return {
      data: {} as IListReportsDemographicData,
      loading: false,
      selectedListUid: "" as string | null,
    };
  },

  computed: {
    userLists(): Array<{ title: string; uid: string }> {
      return (
        this.$store
          .get(CompanyListState.Getter.VALUES)
          .map((list: ICompanyList) => {
            return {
              title: list.title,
              uid: list.uid,
            };
          }) || []
      );
    },

    questionSlug(): string {
      return this.theme?.questionSlug || "";
    },

    isFreeUser(): boolean {
      return !this.$user.isProUser();
    },

    selectDisabled(): boolean {
      return this.isFreeUser || this.userLists.length <= 1 || this.loading;
    },
  },

  watch: {
    selectedListUid: {
      async handler() {
        await this.fetchDemographicData();
      },
    },

    theme: {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        await this.fetchDemographicData();
      },
    },

    loading(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.$emit("loading", newValue);
    },
  },

  async created() {
    // If it's free user we show mock data
    if (this.isFreeUser) {
      this.data = GENDER_MOCK_DATA;
      return;
    }

    this.loading = true;

    await this.setupViewInitialData();
  },

  methods: {
    async setupViewInitialData() {
      if (!this.userLists.length) {
        await this.fetchLists();
      }

      if (this.userLists.length) {
        this.selectedListUid = this.userLists[0].uid;
        return;
      }

      this.$emit("show-navigation", false);
      this.loading = false;
    },

    async fetchDemographicData() {
      if (!this.selectedListUid) return;

      this.loading = true;

      this.data = await listsReportsDataProvider.fetch({
        list_uid: this.selectedListUid,
        question: this.questionSlug,
      });

      this.loading = false;
    },

    async fetchLists() {
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
    },

    goToListsPage() {
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DIRECTORY,
      });
    },
  },
});
