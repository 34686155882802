

















































































import Vue from "vue";

import { passwordResetProvider } from "@/modules/authentication/services/data/password-reset/password-reset.provider";
import { IPasswordReset } from "@/modules/authentication/services/data/password-reset/password-reset.interface";

import { confirmResetTokenProvider } from "@/modules/authentication/services/data/password-reset/confirm-reset-token.provider";
import { IConfirmResetToken } from "@/modules/authentication/services/data/password-reset/confirm-reset-token.interface";

import {
  allFormFieldsValid,
  generatePasswordCheck,
  generatePasswordValidator,
} from "@/services/errors/validator-generators";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { ElForm } from "element-ui/types/form";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default Vue.extend({
  name: "AuthBasePasswordReset",

  data() {
    return {
      loading: false,
      hasServerError: false,
      submissionDisabled: true,
      formEl: null as null | ElForm,

      fields: {
        password1: "",
        password2: "",
      },

      rules: {
        password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        password2: generatePasswordCheck(this, "fields.password1", {
          minimum: 8,
        }),
      },
    };
  },

  computed: {
    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  created() {
    this.confirmResetToken();
  },

  mounted() {
    (this as any).formEl = this.$refs.resetPasswordForm;
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(this.formEl, this.rules);
    },

    async confirmResetToken() {
      const dataToSend = {
        user: this.$route.params.uid,
        key: this.$route.params.token,
      } as IConfirmResetToken;

      this.loading = true;

      try {
        await confirmResetTokenProvider.create(dataToSend);
      } catch (error) {
        this.$message({
          message: this.$root.$t(
            "authentication.passwordReset.invalidTokenMessage",
          ) as string,
          type: "error",
          customClass: "is-full",
        });

        this.$router.replace({ name: ROUTE_AUTHENTICATION_LOGIN });
      } finally {
        this.loading = false;
      }
    },

    async submitForm() {
      const dataToSend = {
        ...this.fields,
        user: this.$route.params.uid,
        key: this.$route.params.token,
      } as IPasswordReset;

      this.hasServerError = false;
      this.loading = true;

      try {
        await passwordResetProvider.create(dataToSend);
      } catch (error) {
        this.hasServerError = true;
        return;
      } finally {
        this.loading = false;
      }

      this.$message({
        message: this.$t(
          "authentication.passwordReset.successMessage",
        ) as string,
        type: "success",
        customClass: "is-full",
      });

      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
