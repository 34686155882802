
























































import Vue from "vue";
import get from "lodash/get";

export interface IPxStepsItem {
  /**
   * Name to be displayed.
   */
  label: string;

  /**
   * Value that corresponds to the step item.
   */
  value: string;
}

/**
 * This component is a component to represent steps.
 *
 * This can be used to define a complex workflow into
 * a set of smaller steps.
 */
export default Vue.extend({
  name: "PxSteps",

  props: {
    /**
     * List of steps.
     *
     * Example:
     * ```
     * { label: "Company Details", value: "company" }
     * ```
     */
    steps: {
      type: Array as () => IPxStepsItem[],
      default: () => [],
    },

    /**
     * Index of the current active step.
     */
    active: {
      type: Number,
      default: 1,
    },

    theme: {
      type: String,
      default: "blue",
      validator(value: string) {
        return ["blue", "orange"].includes(value);
      },
    },
  },

  data() {
    return {
      gradient: {
        blue: ["#64A9FE", "#1865C4"],
        orange: ["#FFA272", "#FF8141"],
      },
    };
  },

  computed: {
    firstGradientColor(): string {
      return get(this.gradient, [this.theme, "0"], "#64A9FE");
    },
    lastGradientColor(): string {
      return get(this.gradient, [this.theme, "1"], "#1865C4");
    },
  },
});
