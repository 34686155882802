

















































import Vue from "vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";

import ResultsBoardPendingUser from "@/modules/affiliates/components/results-board/results-board-pending-user.vue";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "ResultsBoard",

  components: {
    ResultsBoardPendingUser,
  },

  computed: {
    viralStore(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    affiliates(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    hasError(): boolean {
      return !!this.viralStore.error || !!this.affiliates.error;
    },

    finalLevel(): ILevel {
      return this.viralStore.finalLevel as ILevel;
    },

    affiliate(): IAffiliate {
      return this.affiliates.affiliate as IAffiliate;
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },
  },
});
