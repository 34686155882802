import {
  AUTHENTICATION_GUEST_USER_TYPE,
  AUTHENTICATION_LS_TOKEN_KEY,
  ENTREPRENEUR_USER_TYPE,
  SUPPORTER_USER_TYPE,
} from "./constants";
import Vue, { VueConstructor } from "vue";
// TODO: Remove this, in order to fix api-client circular dependency
import store from "@/services/store";
import { ESubscriptionPlanID } from "@/modules/authentication/services/data/subscription/subscription.interface";

/**
 * Manager with all needs methods to manager the user authentication.
 */
class UserManager {
  /**
   * Get authentication token.
   */
  public getToken(): string | null {
    return localStorage.getItem(AUTHENTICATION_LS_TOKEN_KEY);
  }

  /**
   * Set authentication token on the store.
   *
   * @param token new session token
   */
  public setToken(token: string) {
    localStorage.setItem(AUTHENTICATION_LS_TOKEN_KEY, token);
  }

  /**
   * Remove the authentication token.
   */
  public removeToken() {
    localStorage.removeItem(AUTHENTICATION_LS_TOKEN_KEY);
  }

  /**
   * Get guest user type token.
   */
  public getGuestUserType(): string {
    return (
      localStorage.getItem(AUTHENTICATION_GUEST_USER_TYPE) ||
      ENTREPRENEUR_USER_TYPE
    ).toString();
  }

  /**
   * Stores default type as guest supporter.
   *
   * @param userType
   */
  public setGuestUserType(userType: number) {
    localStorage.setItem(AUTHENTICATION_GUEST_USER_TYPE, userType.toString());
  }

  /**
   * Informs if the user has session.
   */
  public isLogged(): boolean {
    return !!this.getToken();
  }

  /**
   * Informs if the current user owns the profile being displayed.
   */
  public isOwner(): boolean {
    const currentCompany = store.get("profile/company/data");
    const userCompany = store.get("auth/company/data");

    return currentCompany && userCompany
      ? currentCompany.id === userCompany.id
      : false;
  }

  /**
   * Informs if the current user is an admin.
   */
  public isAdmin(): boolean {
    return store.get("auth/isAdmin") || false;
  }

  public getUserAccountType(): number {
    const authProfile = store.get("auth/company/data");

    if (authProfile) {
      return authProfile.type;
    }

    return !this.isLogged() && !!this.getGuestUserType()
      ? Number.parseInt(this.getGuestUserType())
      : ENTREPRENEUR_USER_TYPE;
  }

  public isSupporter(): boolean {
    return this.getUserAccountType() === SUPPORTER_USER_TYPE;
  }

  public isEntrepreneur(): boolean {
    return this.getUserAccountType() === ENTREPRENEUR_USER_TYPE;
  }

  /**
   * Informs if the current user has confirmed its email.
   */
  public hasVerifiedAccount(): boolean {
    return store.get("auth/verifiedAccount") || false;
  }

  public isPendingUser(): boolean {
    return !!store.get("affiliates/pendingUser");
  }

  public isPendingSupporter(): boolean {
    return !!store.get("supporterFlow/value")?.auth_token;
  }

  public getPendingUserAuthToken(): string | null {
    return store.get("affiliates/pendingUser")?.auth_token;
  }

  public getPendingSupporterAuthToken(): string | null {
    return store.get("supporterFlow/value")?.auth_token;
  }

  public isProUser(): boolean {
    if (!store.get("auth/subscription")) return false;

    return store.get("auth/subscription").planId !== ESubscriptionPlanID.FREE;
  }
}

/**
 * Create a singleton for the user manager.
 */
export const userManager = new UserManager();

/**
 * Install user manager on the Vue.
 */
Vue.use((_Vue: VueConstructor) => {
  Object.defineProperty(_Vue.prototype, "$user", {
    get() {
      return userManager;
    },
  });
});

declare module "vue/types/vue" {
  interface Vue {
    $user: UserManager;
  }
}
