






















































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import CriteriaMultiSelector from "@/modules/supporters/components/criteria-multi-selector/criteria-multi-selector.vue";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { TranslateResult } from "vue-i18n";
import { EAdditionalCriteriaGetter } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default SupportersFlowMixin.extend({
  name: "CriteriaAdditionalInterests",

  components: {
    FormPresentationLayout,
    CriteriaMultiSelector,
    NavigationFooter,
  },

  data() {
    return {
      fields: {
        additional_criteria: [] as Array<IMatchingResponse>,
      },
    };
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.criteriaAdditionalInterests.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.criteriaAdditionalInterests");
    },

    additionalCriteriaValues(): Array<IMultiSelectorItem> {
      return this.$store.getters[
        EAdditionalCriteriaGetter.MULTI_SELECTOR_VALUES
      ];
    },

    eligibleAdditionalCriteriaValues(): Array<IMultiSelectorItem> {
      // Questions that haven't been made on the Question Bundle:
      const existingCriteria = this.flowData.criteria.map(
        (criteria) => criteria.question,
      );
      return this.additionalCriteriaValues.map((item) => ({
        ...item,
        children: (item?.children || []).filter(
          (child) => !existingCriteria.includes(Number(child.value)),
        ),
      }));
    },
  },

  mounted() {
    if (this.fields && this.flowData) {
      this.fields.additional_criteria = [...this.flowData.additional_criteria];
    }
  },
});
