






























import Vue from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";

import { LocaleMessages } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";

export default Vue.extend({
  name: "ShareModalSettingsLink",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    copy: {
      type: String,
      default: "common.shareModal.settings.link",
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    settingsCopy(): LocaleMessages {
      return this.$t(this.copy) as LocaleMessages;
    },

    resetButtonProps(): any {
      return {
        ...this.$options.static.resetButton,
        label: this.settingsCopy.resetButton,
      };
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$root.$emit("share-modal-action", {
        event: SHARE_MODAL_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
