





















import Vue from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";

export default Vue.extend({
  name: "RolePlayingBanner",

  data: function () {
    return {
      buttonType: EPxButtonType.LINK_DARK,
    };
  },

  methods: {
    goToDashboardPage() {
      this.$router.push({ name: ROUTE_GETTING_STARTED_DASHBOARD });
    },
  },
});
