










































import Vue from "vue";
import emitter from "element-ui/src/mixins/emitter";

import cloneDeep from "lodash/cloneDeep";

import { IOfferingType } from "@/services/data/offering/offering.interface";

export default Vue.extend({
  name: "OfferingTypesSelect",

  mixins: [emitter],

  props: {
    types: {
      type: Array as () => IOfferingType[],
      required: true,
    },
    value: {
      type: Array as () => IOfferingType[],
      required: true,
    },
  },

  data() {
    return {
      listOfOfferingTypes: [] as Array<IOfferingType>,
      tempOfferingType: null as null | IOfferingType,
      offeringTypesSelectLoading: false,

      // Will stores the list of selected sectors
      selectedOfferingTypes: [] as Array<IOfferingType>,
    };
  },

  watch: {
    types: {
      immediate: true,
      handler(currentTypes) {
        this.listOfOfferingTypes = cloneDeep(currentTypes);
      },
    },
    selectedOfferingTypes(newVal: Array<IOfferingType>) {
      this.$emit("input", newVal);
      (this as any).dispatch("ElFormItem", "el.form.change", [newVal]);
    },
  },

  methods: {
    /**
     * Allow to set the active offering types.
     */
    setActiveOfferingTypes(types: Array<IOfferingType>) {
      this.selectedOfferingTypes = types;
    },

    /**
     * When a new type is selected it is pushed into the
     * array of selected types and the autocomplete fill
     * cleared.
     */
    onOfferingTypeSelect(selectedValue: IOfferingType) {
      this.selectedOfferingTypes.push(selectedValue);
      this.tempOfferingType = null;

      // Remove element from list
      const indexToRemove = this.listOfOfferingTypes.findIndex(
        (type: IOfferingType) => type.id === selectedValue.id,
      );
      this.listOfOfferingTypes.splice(indexToRemove, 1);
      this.orderListOfOfferingTypes();

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given type from the list of selected types.
     */
    onClickRemoveOfferingType(typeId: number) {
      const indexOfType = this.selectedOfferingTypes.findIndex(
        (type: IOfferingType) => type.id === typeId,
      );

      // Add to list of available offers
      this.listOfOfferingTypes.push(this.selectedOfferingTypes[indexOfType]);
      this.orderListOfOfferingTypes();

      // Remove from selected offers
      this.selectedOfferingTypes.splice(indexOfType, 1);
    },

    orderListOfOfferingTypes() {
      // Order list alphabetically by name
      this.listOfOfferingTypes.sort((a, b) => a.name.localeCompare(b.name));
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      (this as any).dispatch("ElFormItem", "el.form.change", [
        this.selectedOfferingTypes,
      ]);
    },
  },
});
