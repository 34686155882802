














































import MultiSelectorMixin from "@/components/multi-selector/multi-selector.mixin";

import MultiSelectorWrapper from "@/components/multi-selector-wrapper/multi-selector-wrapper.vue";
import MultiSelectorOption from "@/components/multi-selector-option/multi-selector-option.vue";
import MultiSelectorEntry from "@/components/multi-selector-entry/multi-selector-entry.vue";

export default MultiSelectorMixin.extend({
  name: "MultiSelector",
  components: { MultiSelectorWrapper, MultiSelectorEntry, MultiSelectorOption },
});
