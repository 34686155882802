




























import Vue from "vue";
import MatchDetailsTableRow from "@/components/match-details-table/match-details-table-row.vue";
import { IMatchDetails } from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import PxTableHeader, {
  ITableHeaderItems,
} from "@/components/px-table-header/px-table-header.vue";

export default Vue.extend({
  name: "MatchDetailsModalTable",

  components: {
    MatchDetailsTableRow,
    PxTableHeader,
  },

  props: {
    userType: {
      type: Number,
      default: 0,
    },

    tableData: {
      type: Array as () => Array<IMatchDetails>,
      required: true,
    },
  },

  data() {
    return {
      visibleRows: 5,
      tableScrollAtEnd: false,
      table: (null as any) || null,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return this.tableData.length > this.visibleRows && !this.tableScrollAtEnd;
    },

    userTypeCopy(): string {
      return this.$t(`common.userTypes[${+!this.userType}]`) as string;
    },

    tableHeaders(): any {
      return this.$t("matching.matchDetails.table.header");
    },

    tableHeaderWithUserType(): any {
      return this.tableHeaders.map((headers: ITableHeaderItems) => {
        return {
          ...headers,
          title: headers.title.replace("{userType}", this.userTypeCopy),
        };
      });
    },
  },

  destroyed() {
    this.table = null;
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.table as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
