import { Route, RouteConfig } from "vue-router";

import AffiliatesBaseView from "@/modules/affiliates/views/affiliates-base.view.vue";
import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import SelfAssessmentController from "@/modules/self-assessment/views/assessment-controller.vue";
import {
  SELF_ASSESSMENT_STARTING_POINT,
  SELF_ASSESSMENT_RESULT_TOKEN,
} from "@/modules/self-assessment/services/router/routes-names";
import {
  changeLocale,
  DEFAULT_LOCALE,
  loadedLocales,
} from "@/services/i18n/locale-utils";
import { i18n } from "@/services/i18n/i18n";
import { userManager } from "@/modules/authentication/user-manager";
import { ROUTE_LOGGED_HOME } from "@/services/router/router-names";

export const routes: RouteConfig[] = [
  {
    path: "/entrepreneurs",
    component: AffiliatesBaseView,
    name: SELF_ASSESSMENT_STARTING_POINT,
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      // Prevent logged users from entering the route
      if (userManager.isLogged()) {
        return next({
          name: ROUTE_LOGGED_HOME,
        });
      }

      // To be able to persist the selected language when refreshing the page
      // we setted a local storage variable with the locale selected by the user.
      // When entering in the route we need to validate if the variable is available or not and change the locale accordingly
      // This is only needed because the application is not prepared to display all the pages translated in spanish
      // This code should be removed when the whole application is prepared to be in spanish
      const locale = localStorage.getItem("userLocale") || DEFAULT_LOCALE;
      await changeLocale(i18n, locale, loadedLocales, document);

      next();
    },
  },
  {
    path: "/entrepreneur/*",
    redirect: (to: Route) => {
      return to.path.replace("/entrepreneur", "/entrepreneurs");
    },
  },
  {
    path: "/assessment",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: ":token",
        name: SELF_ASSESSMENT_RESULT_TOKEN,
        component: SelfAssessmentController,
        meta: {
          showWizard: true,
          showFooter: true,
        },
      },
    ],
  },
];
