














































































import Vue from "vue";
import RenderlessAssessmentPanel from "@/modules/profile/components/renderless-assessment-panel/renderless-assessment-panel.vue";
import AssessmentPanelLevel from "@/modules/profile/components/assessment-panel/assessment-panel--level.vue";
import AssessmentPanelLevelInactive from "@/modules/profile/components/assessment-panel/assessment-panel--level-inactive.vue";
import AssessmentPanelGrid from "@/modules/profile/components/assessment-panel/assessment-panel--grid.vue";
import AssessmentPanelGridInactive from "@/modules/profile/components/assessment-panel/assessment-panel--grid-inactive.vue";
import AssessmentPanelHistory from "@/modules/profile/components/assessment-panel/assessment-panel--history.vue";
import AssessmentPanelHistoryInactive from "@/modules/profile/components/assessment-panel/assessment-panel--history-inactive.vue";

export default Vue.extend({
  components: {
    RenderlessAssessmentPanel,
    AssessmentPanelLevel,
    AssessmentPanelLevelInactive,
    AssessmentPanelGrid,
    AssessmentPanelGridInactive,
    AssessmentPanelHistory,
    AssessmentPanelHistoryInactive,
  },

  computed: {
    isHowItWorksLinkDisabled(): boolean {
      return this.$features.isEnabled("disabledHowItWorksLink");
    },
  },

  methods: {
    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
