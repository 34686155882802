





























































import Vue from "vue";
import axios, { AxiosError } from "axios";
import { LocaleMessages } from "vue-i18n";

import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import ListOptionsDeleteModal from "@/modules/company-lists/components/list-options/list-options-delete-modal.vue";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { baseAPIUrl, activeModules } from "@/services/utils/utils";
import { userManager } from "@/modules/authentication/user-manager";
import { CompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";

export default Vue.extend({
  name: "ListOptions",

  components: {
    ListOptionsDeleteModal,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },

    companies: {
      type: Array as () => Array<IMatchingCard>,
      default: () => [],
    },
  },

  data() {
    return {
      optionsVisible: false,
      deleteModalVisible: false,
    };
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    isOwner(): boolean {
      return this.value.is_owner;
    },

    isSmartList(): boolean {
      return this.value.is_smart_list;
    },

    isListDetailPage(): boolean {
      return this.$route.name === ROUTE_COMPANY_LISTS_DETAIL;
    },

    listSharedErrors(): AxiosError {
      return this.$store.get(CompanySharedListState.Getter.ERROR);
    },

    companiesUids(): Array<string> {
      return this.companies.length
        ? // When user is in the list details page:
          this.companies.map(({ uid }) => uid as string)
        : // When user is in the lists page:
          this.value.companies.map(({ uid }) => uid as string);
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    showAddMembersButton(): boolean {
      return !this.isSmartList && this.isMatchingEnabled;
    },
  },

  methods: {
    onVisibleChange(state: boolean) {
      this.optionsVisible = state;
    },

    exportClickHandler() {
      const url = `${baseAPIUrl}/user/company-lists/${this.value.uid}/companies?format=csv`;

      axios({
        url,
        method: "get",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${userManager.getToken()}`,
        },
      }).then((response) => {
        // Download response:
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.value.title}.csv`;
        link.click();
      });
    },

    addMembersClickHandler() {
      // Set selected list
      this.$store.dispatch(EListManagementActions.SET_LISTS, [this.value]);

      // Clear selected companies
      this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);

      // Move to matching
      this.$router.push({
        name: ROUTE_MATCHING_LIST,
        params: {
          companiesUids: JSON.stringify(this.companiesUids),
          listTitle: this.value.title,
        },
      });
    },

    removeFromSharedClickHandler() {
      this.$store
        .dispatch(CompanySharedListState.Action.REMOVE_VALUE, this.value.uid)
        .then(() => {
          if (!this.listSharedErrors) {
            this.$store.dispatch(CompanySharedListState.Action.GET_VALUES);
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle") as string,
            type: "error",
            customClass: "is-full",
          });
        });
    },

    manageMembersClickHandler() {
      // If not on detail page, navigate to there
      if (!this.isListDetailPage) {
        this.$router.push({
          name: ROUTE_COMPANY_LISTS_DETAIL,
          params: { uid: this.value.uid },
        });
      }

      this.$nextTick(() => {
        // Triggers list management footer
        this.$root.$emit("show-list-management-footer");
      });
    },

    settingsClickHandler() {
      this.$root.$emit("show-list-management-modal", {
        tab: EListManagementModalTab.EDIT,
        list: this.value,
      });
    },

    deleteClickHandler() {
      this.deleteModalVisible = true;
    },

    async deleteConfirmationClickHandler() {
      // Dispatch destroy
      await this.$store.dispatch(
        CompanyListState.Action.REMOVE_VALUE,
        this.value.uid,
      );

      // Hide delete modal
      this.deleteModalVisible = false;

      // Force new fetch
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);

      // If the route is the list detail, redirect to the directory listing
      if (this.$route.name === ROUTE_COMPANY_LISTS_DETAIL) {
        this.$router.replace({ name: ROUTE_COMPANY_LISTS_DIRECTORY });
      }
    },
  },
});
