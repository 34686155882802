








































import Vue from "vue";

import { IAffiliateProgramNavBarStep } from "@/modules/affiliates/views/affiliate-types/affiliate-question-bundle.view.vue";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "AffiliateNavigationBar",

  props: {
    logo: {
      type: String,
      default: null,
    },

    externalLink: {
      type: String,
      default: null,
    },

    steps: {
      type: Array as () => Array<IAffiliateProgramNavBarStep>,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    activeStep: {
      type: Number,
      default: 1,
    },

    showLoginButton: {
      type: Boolean,
      default: false,
    },

    showSteps: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  methods: {
    onStepClick(stepId: number) {
      this.$emit("update:activeStep", stepId);
      this.$emit("click");
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
