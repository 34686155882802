
































import Vue from "vue";

import TeamMembersListMemberEntry from "@/modules/team-management/components/team-members-list/team-members-list-member-entry.vue";
import TeamMembersListFooter from "@/modules/team-management/components/team-members-list/team-members-list-footer.vue";
import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "TeamMembersList",

  components: {
    TeamMembersListMemberEntry,
    TeamMembersListFooter,
    PxListHeader,
  },

  props: {
    membersList: {
      type: Array as () => Array<ITeamMember>,
      default: null,
    },

    selectedMemberIndex: {
      type: Number,
      default: 0,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    scroll: {
      type: String,
      default: "top",
      validator(value: string) {
        return Object.values(["top", "bottom"]).includes(value);
      },
    },

    formInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listScrollAtEnd: false,
      list: (null as any) || null,
      visibleMembers: 6,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return (
        this.membersList.length > this.visibleMembers && !this.listScrollAtEnd
      );
    },

    listHeaderTitle(): TranslateResult {
      return this.$t("teamManagement.membersList.title");
    },
  },

  watch: {
    scroll: {
      immediate: true,
      handler(type: string) {
        if (!type) return;

        const listEl = this.$refs.membersList as HTMLElement;

        if (listEl) {
          listEl.scrollTop = type === "top" ? 0 : listEl.scrollHeight;
        }

        this.$emit("update:scroll", null);
      },
    },
  },

  methods: {
    addMember() {
      this.$emit("add-member");
    },

    removeMember(index: number) {
      this.$emit("remove-member", index);
    },

    updateSelectedMember(index: number) {
      if (this.formInvalid) return;

      this.$emit("change-selected-member", index);
    },

    checkListScroll() {
      this.list = this.$refs.membersList as HTMLElement;

      if (!this.list) return;

      this.listScrollAtEnd =
        this.list.scrollTop === this.list.scrollHeight - this.list.offsetHeight;
    },
  },
});
