









































































































import Vue from "vue";

import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";
import SupporterOfferings from "@/modules/profile/components/supporter-offerings/supporter-offerings.vue";
import OfferingsEditModal from "@/modules/profile/components/offerings-edit-modal/offerings-edit-modal.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IOffering } from "@/services/data/offering/offering.interface";

export default Vue.extend({
  name: "SupporterInfoPanel",

  components: {
    PxPanelSection,
    SupporterOfferings,
    OfferingsEditModal,
    PanelEmptyState,
  },

  data() {
    return {
      isOfferingsEditModalVisible: false,
      selectedTab: "offer",
      tabsMobileItems: [
        {
          value: "offer",
          label: this.$t("profile.supporter.offerings.title"),
        },
        // {
        //   value: "whyus",
        //   label: this.$t("profile.supporter.offerings.whyus"),
        // },
      ],
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    isSupporterLoading(): boolean {
      return this.$store.get("profile/supporter/loading");
    },

    supporterOfferings(): Array<IOffering> {
      return this.supporter ? this.supporter.offerings : [];
    },

    hasSupporterOfferings(): boolean {
      return !!this.supporterOfferings && this.supporterOfferings.length > 0;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },

  methods: {
    onClickEditOfferings() {
      this.isOfferingsEditModalVisible = true;
    },
  },
});
