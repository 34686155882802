import Vue from "vue";
import Router from "vue-router";
import CookiePolicy from "@/views/cookie-policy.vue";
import ErrorPage from "@/modules/common/views/error-page.view.vue";
import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import {
  HOME,
  COOKIE_POLICY,
  ERROR_ROUTE,
} from "@/services/router/router-names";

import { activeModules } from "@/services/utils/utils";
import { SELF_ASSESSMENT_STARTING_POINT } from "@/modules/self-assessment/services/router/routes-names";
import { gaEvent } from "@/services/utils/ga.utils";
import { authenticationGuard } from "./authentication-guard";
import { featureManager } from "../feature-manager";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { userManager } from "@/modules/authentication/user-manager";
import { ROUTE_GETTING_STARTED_DASHBOARD } from "@/modules/getting-started-dashboard/services/router/routes-names";
import { defaultScreenService } from "@/modules/common/plugins/screen.plugin";

// Install Vue-Router into Vue
Vue.use(Router);

// Load all module translation files
let modulesRoutes: any = [];
const listOfModules = activeModules();
listOfModules.forEach((moduleName: string) => {
  try {
    const module =
      require(`@/modules/${moduleName}/services/router/routes`).routes;
    modulesRoutes = [...modulesRoutes, ...module];
  } catch (_) {
    // Do nothing! This means the module doesn't use i18n.
  }
});

// Build list of routes
const routes = [
  ...modulesRoutes,
  {
    path: "/",
    name: HOME,
    // NOTE: temporarily disable home route component
    // component: Home,
    redirect: (to: any) => {
      // If user is logged and doesn't contain query parameters on url,
      // redirect to profile if is an entrepreneur and to the dashboard if is a supporter.
      // Only by passing a query parameter
      // will allow the user to be sent to the self-assessment flow.
      // On visitor users, it will always redirect to self-assessment flow by design.

      const redirectToDashboard =
        userManager.isSupporter() &&
        defaultScreenService.isUp("md") &&
        activeModules().includes("getting-started-dashboard");

      const userHomepage = redirectToDashboard
        ? ROUTE_GETTING_STARTED_DASHBOARD
        : ROUTE_PROFILE;

      const routeName =
        userManager.isLogged() && Object.keys(to.query).length === 0
          ? userHomepage
          : SELF_ASSESSMENT_STARTING_POINT;
      return {
        name: routeName,
        query: to.query,
      };
    },
  },
  {
    path: "/cookie-policy",
    name: COOKIE_POLICY,
    component: CookiePolicy,
  },
  {
    path: "/error",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: "",
        name: ERROR_ROUTE,
        component: ErrorPage,
      },
    ],
  },
  {
    path: "*",
    redirect: {
      name: SELF_ASSESSMENT_STARTING_POINT,
    },
  },
];

const router = new Router({
  routes,
  mode: "history",
});

// Inform GA of page changes.
//
// Ignore when GA isn't available, that can happen when
// the user doesn't consent with the Cookie usage.
router.afterEach(() => {
  gaEvent("config", process.env.VUE_APP_GA_CODE);
});

// Before each transition we check if the targeted route
// needs authentication
if (featureManager.isEnabled("authentication")) {
  router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    authenticationGuard(to, from, next);
  });
}

export default router;
