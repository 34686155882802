

























































































import Vue from "vue";

import ChartsLockedModal from "@/modules/charts-dashboard/components/common/charts-locked-modal/charts-locked-modal.vue";
import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import ListReportsView from "@/modules/charts-dashboard/views/list-reports.view.vue";
import ProcessReportsView from "@/modules/charts-dashboard/views/process-reports.view.vue";
import RequestProcessesModal from "@/modules/charts-dashboard/components/common/request-processes-modal/request-processes-modal.vue";

import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import { EDashboardPages } from "@/modules/charts-dashboard/views/charts-dashboard.interface";
import { TranslateResult } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export interface IReportsViewThemes {
  title: TranslateResult;
  questionSlug: string;
  id: number;
  image_path: string;
}

export default Vue.extend({
  name: "ChartsDashboardView",

  components: {
    ChartsLockedModal,
    PxNavigationTab,
    ListReportsView,
    ProcessReportsView,
    RequestProcessesModal,
  },

  data() {
    return {
      selectedPage: EDashboardPages.LIST_REPORTS,
      selectedNavbarId: 1,
      loading: false,
      showThemeNavigation: true,
      showMockDataCopy: false,
      showProcessModal: false,
      showRequestProcessCta: false,
    };
  },

  computed: {
    isFreeUser(): boolean {
      return !this.$user.isProUser();
    },

    proFeatureModalProps(): any {
      return {
        title: this.$t("chartsDashboard.proFeatureModal.title"),
        description: this.$t("chartsDashboard.proFeatureModal.description"),
        ctaLabel: this.$t("chartsDashboard.proFeatureModal.cta"),
        width: "500px",
        closable: false,
        showMask: false,
        marginTop: "0",
      };
    },

    pages(): TranslateResult {
      return this.$t("chartsDashboard.pages");
    },

    showPagesNavigation(): boolean {
      return this.$features.isEnabled("processReports");
    },

    navBarItems(): Array<IReportsViewThemes> {
      return [
        {
          title: this.$t("chartsDashboard.navbarItems[0]"),
          id: 1,
          questionSlug: "Individual_Gender_Identity",
          image_path: "gender",
        },
        {
          title: this.$t("chartsDashboard.navbarItems[1]"),
          id: 2,
          questionSlug: "Individual_Race_Ethnicity",
          image_path: "ethnicity",
        },
        {
          title: this.$t("chartsDashboard.navbarItems[2]"),
          id: 3,
          questionSlug: "Individual_Sexual_Orientation",
          image_path: "sexual_orientation",
        },
      ];
    },

    selectedTheme(): IReportsViewThemes | null {
      return (
        this.navBarItems.find(
          (item: any) => item.id === this.selectedNavbarId,
        ) || null
      );
    },

    questionSlug(): string {
      return this.selectedTheme?.questionSlug || "";
    },

    showListReportsView(): boolean {
      return this.selectedPage === EDashboardPages.LIST_REPORTS;
    },

    requestProcessButtonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$t("chartsDashboard.requestProcessesModal.cta"),
      };
    },
  },

  watch: {
    selectedPage: {
      handler() {
        this.showRequestProcessCta = false;
      },
    },
  },

  methods: {
    goToSubscriptionPage(): void {
      this.$router.push({
        name: ROUTE_ACCOUNT_SETTINGS,
        query: { tab: EAccountSettingsNavigationTabs.SUBSCRIPTION },
      });
    },

    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },

    setThemeNavigationVisibility(isVisible: boolean) {
      this.showThemeNavigation = isVisible;
    },

    setMockDataCopyVisibility(isVisible: boolean) {
      this.showMockDataCopy = isVisible;

      if (!this.isFreeUser && !this.showListReportsView && !isVisible) {
        this.showRequestProcessCta = true;
      }
    },
  },
});
