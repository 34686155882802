























import Vue from "vue";

import ShareModalUsersSearch from "@/components/share-modal/share-modal-users-search.vue";
import ShareModalActions from "@/components/share-modal/share-modal-actions.vue";
import ShareModalUsers from "@/components/share-modal/share-modal-users.vue";

import { IInvitedUser } from "@/components/share-modal/share-modal.interface";

export default Vue.extend({
  name: "ShareModalContent",

  components: {
    ShareModalActions,
    ShareModalUsers,
    ShareModalUsersSearch,
  },

  props: {
    users: {
      type: Array as () => IInvitedUser[],
      default: () => [],
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    actionsCopy: {
      type: String,
      default: "milestonePlanner.shareModal.footer",
    },

    usersCopy: {
      type: String,
      default: "milestonePlanner.shareModal.users",
    },

    filterBy: {
      type: Number,
      default: 0,
    },

    shareSucceeded: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSendEmail(invitedUsers: Array<string>) {
      this.$emit("send-email", invitedUsers);
    },
  },
});
