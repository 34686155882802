import Vue, { VNode } from "vue";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import {
  MATCHING_LOWEST_LEVEL,
  MATCHING_SELECTED_FILTERS,
} from "@/modules/matching/constants";

import { EMetaGetters } from "@/services/store/meta/meta-types";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { mapMatchingScoresToCards } from "@/utils/matching";
import { extractValuesFromFilter } from "@/modules/matching/components/matching-filters/matching-filters.utils";
import { IMatchingQueryFiltersParams } from "@/modules/matching/components/matching-filters/mathing-filters.interface";

export interface IMatchingFilters {
  [key: string]: {
    // Object key to extract filter value(s)
    key?: string;
    // Single value filter
    value?: string;
    // Multi values filter
    values?: Array<string>;
    // Filter to be only applied on discover tab
    onlyOnDiscover?: boolean;
  };
}

export default Vue.extend({
  data() {
    return {
      hasChangedDiscoverFilters: false,
      hasChangedInterestFilters: false,
      hasAlreadyRetrievedInitialResults: false,
    };
  },

  computed: {
    // Restrict lists to desktop
    showListFeature(): boolean {
      return this.$screen.lgUp;
    },

    /**
     * Get all discover matchings
     */
    matchScores(): IMatchingScore[] {
      return this.$store.state.matching.data;
    },

    matchCards(): IMatchingCard[] {
      // Remove temporary duplicates that can return from optimistic UI
      return mapMatchingScoresToCards(this.matchScores).filter(
        (value, index, innerArray) =>
          innerArray.findIndex((innerValue) => innerValue.uid === value.uid) ===
          index,
      );
    },

    latestAssessment(): IAssessment {
      return this.$store.state.profile.latestAssessment.data;
    },

    latestAssessmentLevel(): number {
      return this.latestAssessment && this.latestAssessment.level
        ? this.latestAssessment.level.value
        : MATCHING_LOWEST_LEVEL;
    },

    /**
     * Matchings type property
     */
    matchingsType(): string {
      return this.$user.isEntrepreneur() ? "supporter" : "company";
    },

    /**
     * Verify if discover page is loading
     */
    isDiscoverLoading(): boolean {
      return (
        this.$store.state.matching.loading ||
        this.$store.state.profile.latestAssessment.loading
      );
    },

    /**
     * Verify if interest page is loading
     */
    isInterestLoading(): boolean {
      return (
        this.$store.state.matchingInterest.loading ||
        this.$store.state.profile.latestAssessment.loading
      );
    },

    /**
     * Get all discover matchings
     */
    discoverMatches(): IMatchingScore[] {
      return this.$store.state.matching.data;
    },

    hasDiscoverCards(): boolean {
      return !!this.discoverMatches.length;
    },

    hasLoadedEmptyMatches(): boolean {
      return !this.hasDiscoverCards && !this.isDiscoverLoading;
    },

    selectedFilters(): IMatchingFilters {
      return this.$store.getters[EMetaGetters.GET](MATCHING_SELECTED_FILTERS);
    },

    hasSelectedFilters(): boolean {
      return (
        !!this.selectedFilters &&
        Object.keys(this.selectedFilters)
          .map((k: any) => (this.selectedFilters as IMatchingFilters)[k])
          .some((k: any) => !!k?.values?.length)
      );
    },

    targetUserType(): number {
      return +!this.$user.getUserAccountType();
    },

    filtersByParam(): IMatchingQueryFiltersParams {
      // When fetching here, the page should be the first one
      const filters: any = {
        page: 1,
      };

      if (!this.selectedFilters) {
        return filters;
      }

      Object.entries(this.selectedFilters).forEach(([key, filter]) => {
        const filterValues = extractValuesFromFilter(filter);

        if (filterValues.length) {
          filters[key] = filterValues;
        }
      });

      return filters;
    },

    discoverFilters(): IMatchingQueryFiltersParams {
      let params = {
        exclude: ["connections"],
      };

      if (this.filtersByParam) {
        params = { ...params, ...this.filtersByParam };
      }

      return params;
    },

    globalFilters(): IMatchingQueryFiltersParams {
      const params: IMatchingQueryFiltersParams = {};

      if (!this.filtersByParam || !this.selectedFilters) {
        return params;
      }

      Object.entries(this.selectedFilters).forEach(([key, filter]) => {
        if (!filter?.onlyOnDiscover && key in this.filtersByParam) {
          params[key] = this.filtersByParam[key];
        }
      });

      return params;
    },
  },

  watch: {
    selectedFilters() {
      this.hasChangedDiscoverFilters = this.hasChangedInterestFilters = true;
    },
    isInterestLoading(loading: boolean) {
      if (!loading) {
        this.hasChangedInterestFilters = false;
      }
    },
    isDiscoverLoading: {
      immediate: true,
      handler(loading: boolean, loaded: boolean) {
        const hasFinishedLoading = loaded && !loading;

        if (!loading) {
          this.hasChangedDiscoverFilters = false;
        }

        if (
          hasFinishedLoading &&
          !this.hasLoadedEmptyMatches &&
          !this.hasAlreadyRetrievedInitialResults
        ) {
          this.hasAlreadyRetrievedInitialResults = true;
        }
      },
    },
    matchCards: {
      immediate: true,
      handler() {
        if (!this.hasAlreadyRetrievedInitialResults && this.hasDiscoverCards) {
          this.hasAlreadyRetrievedInitialResults = true;
        }
      },
    },
  },

  methods: {
    /**
     * Handle matching card click.
     *
     * @param {number} companyId
     */
    clickMatchingCardHandler(companyId: number) {
      const id = companyId.toString();

      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id },
      });
    },

    /**
     * Get content for successful recalculation toast message.
     *
     * @return {VNode}
     */
    getSuccessfulRecalculationMessageContent(): VNode {
      const h = this.$createElement;
      const v = (this as any)._v;

      return h("p", { staticClass: "el-message__content" }, [
        v(this.$t("matching.matchingList.recalculateCriteriaState.loaded")),
        h(
          "el-button",
          {
            props: {
              type: "link",
            },
            on: {
              click: this.refreshPage,
            },
            staticClass: "el-button--link-white",
          },
          this.$t("common.refresh"),
        ),
      ]);
    },

    /**
     * Trigger page reload.
     */
    refreshPage() {
      location.reload();
    },
  },
});
