

























































































import Vue from "vue";

import {
  ROUTE_PROFILE,
  ROUTE_USER_ACCOUNT,
  ROUTE_ACCOUNT_SETTINGS,
} from "@/modules/profile/services/router/routes-names";
import AccountSettingsModal from "@/modules/profile/components/account-settings-modal/account-settings-modal.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";

export default Vue.extend({
  components: {
    AccountSettingsModal,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      isAccountSettingsVisible: false,
      accountSettingsView: "email",

      items: [
        {
          key: "email",
          icon: "email",
          dark: true,
          title: this.$t("profile.account.menu.email"),
          visible:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
        },
        {
          key: "settings",
          icon: "cog--dark",
          dark: true,
          title: this.$t("profile.account.menu.settings"),
          visible:
            !this.$user.isEntrepreneur() &&
            this.$features.isEnabled("settingsPage"),
        },
        {
          divider:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
          inset: true,
        },
        {
          key: "password",
          icon: "locker",
          dark: true,
          title: this.$t("profile.account.menu.password"),
          visible:
            !this.$features.isEnabled("settingsPage") ||
            this.$user.isEntrepreneur(),
        },
        {
          divider: true,
        },
        {
          key: "cookie",
          title: this.$t("profile.account.menu.cookie"),
          visible: true,
        },
        { divider: true, inset: true },
        {
          key: "privacy",
          title: this.$t("profile.account.menu.privacy"),
          visible: true,
        },
        { divider: true, inset: true },
        {
          key: "terms",
          title: this.$t("profile.account.menu.terms"),
          visible: true,
        },
      ],

      menuItems: [
        {
          value: "my_company",
          label: this.$t("common.navigation.menuItems.myCompany"),
          icon: "company",
          to: ROUTE_PROFILE,
        },
        {
          value: "my_supporters",
          label: this.$user.isSupporter()
            ? this.$t("common.navigation.menuItems.myMatches")
            : this.$t("common.navigation.menuItems.mySupporters"),
          icon: "flow-circle",
          to: ROUTE_MATCHING_LIST,
        },
        {
          value: "account",
          label: this.$t("common.navigation.menuItems.account"),
          icon: "gear",
          to: ROUTE_USER_ACCOUNT,
        },
      ],
    };
  },

  computed: {
    isMobile(): boolean {
      return this.$screen.smDown;
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyLogo(): string {
      return this.company ? this.company.logo : "";
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    mobileMenuItemsWithoutMatching(): any {
      return this.menuItems.filter((item) => item.value !== "my_supporters");
    },

    mobileMenuItems(): any {
      return this.isMatchingDisabled
        ? this.mobileMenuItemsWithoutMatching
        : this.menuItems;
    },

    isMatchingDisabled(): boolean {
      return !this.$features.isEnabled("match");
    },
  },

  watch: {
    /**
     * Redirect to the profile if the user is accessing the
     * view on a desktop size screen.
     */
    isMobile: {
      immediate: true,
      handler(isMobile) {
        if (!isMobile) {
          this.$router.replace({ name: ROUTE_PROFILE });
        }
      },
    },
  },

  methods: {
    /**
     * Handle click on an item.
     */
    onItemClick(item: any) {
      switch (item.key) {
        case "cookie":
          if ((window as any).OneTrust) {
            (window as any).OneTrust.ToggleInfoDisplay();
          }
          break;
        case "privacy":
          window.open("https://abaca.app/privacy-policy/");
          break;
        case "terms":
          window.open("https://abaca.app/terms-of-use/");
          break;
        case "email":
        case "password":
          this.accountSettingsView = item.key;
          this.isAccountSettingsVisible = true;
          break;
        case "settings":
          this.$router.push({
            name: ROUTE_ACCOUNT_SETTINGS,
            query: {
              tab: EAccountSettingsNavigationTabs.INFO,
            },
          });
          break;
      }
    },

    onClickLogout() {
      this.$store.dispatch(EAuthActions.LOGOUT);
    },
  },
});
