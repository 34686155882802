










import Vue from "vue";
import { ICategory } from "@/services/data/category/category.interface";
import { EViralLevelGetters } from "@/services/store/viral-level/viral-level-types";

export default Vue.extend({
  name: "CriteriaModal",

  props: {
    /**
     * Sets the modal visibility
     */
    visibility: {
      type: Boolean,
      required: true,
    },

    /**
     * Category level with the info to be shown.
     */
    categoryLevel: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    category(): ICategory {
      return this.$store.getters[EViralLevelGetters.CURRENT_CATEGORY];
    },

    /**
     * Returns the modal content.
     */
    modalContent(): string {
      if (!this.categoryLevel.level) {
        return "";
      }

      return this.$t("selfAssessment.assessment.levelDescriptionTemplate", {
        category: this.category.name,
        level: this.categoryLevel.level.value,
        description: this.categoryLevel.requirements,
      }) as string;
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },
});
