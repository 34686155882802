



























import Vue from "vue";

export default Vue.extend({
  name: "PxTextarea",

  props: {
    /**
     * Textarea content.
     */
    value: {
      type: String,
      default: "",
    },

    /**
     * Text to be presented when there is no text on the textarea.
     */
    placeholder: {
      type: String,
      default: "",
    },

    /**
     * Control the resizability: none, vertical, horizontal, and both.
     */
    resize: {
      type: String,
      default: "vertical",
      validator(val: string) {
        return ["none", "vertical", "horizontal", "both"].includes(val);
      },
    },

    /**
     * Same as `readonly` in native.
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether textarea is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Same as `name` in native.
     */
    name: {
      type: String,
      default: null,
    },

    /**
     * Number of rows that the textarea must have.
     */
    rows: {
      type: Number,
      default: 4,
    },

    /**
     * Whether textarea must have a counter of the
     * remaining characters.
     */
    showCounter: {
      type: Boolean,
      default: false,
    },

    /**
     * Define a max number of the characters.
     */
    maxChars: {
      type: Number,
      default: null,
    },

    /**
     * Whether textarea has an adaptative heigh. Can be an object
     * `{minRows: 2, maxRows: 6}`
     */
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
  },

  data() {
    return {
      innerValue: "",
    };
  },

  computed: {
    /**
     * Get the remaining chars.
     */
    remainingChars(): number {
      return this.value ? this.maxChars - this.value.length : this.maxChars;
    },
  },

  watch: {
    innerValue: {
      handler(newInnerValue: string) {
        if (newInnerValue !== this.value) {
          this.$emit("input", newInnerValue);
          this.$emit("change", newInnerValue);
        }
      },
    },

    value: {
      immediate: true,
      handler(newValue: string) {
        if (newValue !== this.innerValue) {
          this.innerValue = newValue;
        }
      },
    },
  },

  methods: {
    focus() {
      (this.$refs.input as any).focus();
    },
  },
});
