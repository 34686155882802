




















import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default ElDialogMixin.extend({
  name: "ActionModal",

  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
