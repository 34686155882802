
















































































































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

import {
  SUPPORTERS_TERMS_OF_USE_URL,
  SUPPORTERS_PRIVACY_POLICY_URL,
} from "@/modules/supporters/constants";
import { Divider as ElDivider } from "element-ui";
import { TranslateResult } from "vue-i18n";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

export default SupportersFlowMixin.extend({
  name: "StartingPointSimplified",

  components: {
    FormPresentationLayout,
    ElDivider,
    LangSwitcher,
  },

  static: {
    termsOfUseUrl: SUPPORTERS_TERMS_OF_USE_URL,
    privacyPolicyUrl: SUPPORTERS_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      fields: {
        email: "",
        terms: false,
      } as Partial<ISupporterFlow>,
    };
  },

  mounted() {
    if (this.flowData) {
      this.fields.email = this.flowData.email || this.fields.email;
    }

    // Check if should validate
    if (this.fields.email) {
      this.currentForm?.validate();
    }
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.startPage.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.startPage");
    },

    showGoogleSignUp(): boolean {
      return this.$features.isEnabled("signUpGoogleSSO");
    },
  },
});
