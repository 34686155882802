




















































import Vue from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import MilestoneFormFooterCompleteButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-complete-button.vue";
import MilestoneFormFooterSaveAsDraftButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-save-as-draft-button.vue";
import MilestoneFormFooterDeleteButton from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-delete-button.vue";

export default Vue.extend({
  name: "MilestoneFormFooterComplete",

  components: {
    MilestoneFormFooterCompleteButton,
    MilestoneFormFooterSaveAsDraftButton,
    MilestoneFormFooterDeleteButton,
  },

  props: {
    userAddsEvidence: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    isFormPublished: {
      type: Boolean,
      default: false,
    },
    formHasChanges: {
      type: Boolean,
      default: false,
    },
    belowMilestonesNotCompleted: {
      type: Boolean,
      default: false,
    },
    aboveMilestonesCompleted: {
      type: Boolean,
      default: false,
    },
    formIsInvalid: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    discardButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    discardButtonProps(): any {
      return {
        ...this.$options.static.discardButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.discardChanges"),
      };
    },

    deleteButtonLabel(): any {
      return !this.isFormPublished
        ? this.$t("milestonePlanner.milestonePlan.buttons.deleteDraft")
        : this.$t("milestonePlanner.milestonePlan.buttons.deleteMilestone");
    },

    completeButtonLabel(): any {
      return this.isFormPublished
        ? this.$t("milestonePlanner.milestonePlan.buttons.saveMilestone")
        : this.$t("milestonePlanner.milestonePlan.buttons.completeMilestone");
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("auth/milestones.loading");
    },

    saveAsDraftDisabled(): any {
      return (
        this.aboveMilestonesCompleted ||
        (!this.formHasChanges && !this.isFormPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },

    completeDisabled(): boolean {
      return (
        this.belowMilestonesNotCompleted ||
        (!this.formHasChanges && !this.hasCompletionInfo) ||
        (!this.formHasChanges &&
          this.hasCompletionInfo &&
          this.isFormPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },
  },
});
