
















import Vue from "vue";

import { Bar } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
} from "chart.js";
import { externalTooltipHandler } from "@/components/charts/bar-chart/bar-chart-tooltip";

import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

export default Vue.extend({
  name: "BarChart",

  components: {
    Bar,
  },

  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    chartWidth: {
      type: Number,
      default: 800,
    },
    chartHeight: {
      type: Number,
      default: 290,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: null,
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object as () => ChartData,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    compareData: {
      type: null || (Object as () => ChartData),
      default: null,
    },
  },

  data() {
    return {
      chartData: null as null | ChartData,
    };
  },

  computed: {
    chartPlugins(): any {
      // Create a copy of the plugins array to avoid mutating the prop directly
      const plugins = [...this.plugins, ChartJsPluginDataLabels];
      const height = this.chartHeight;
      const width = this.chartWidth;

      plugins.push({
        beforeInit: function (chart: ChartJS) {
          // Plugin to change the canvas height and width to the desired one
          if (chart.canvas) {
            chart.canvas.style.height = `${height}px`;
            chart.canvas.style.width = `${width}px`;
          }
        },
      });

      return plugins;
    },

    chartOptions(): any {
      return this.options ? this.options : this.defaultChartOptions;
    },

    defaultChartOptions(): any {
      return {
        maxBarThickness: 96,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            max: 100,
            stacked: true,
            ticks: {
              padding: 2,
              stepSize: 20,
              callback: function (value: number, index: number) {
                if (!index) return value;

                return value + "%";
              },
              font: {
                weight: 500,
                size: 14,
                family: "HkGrotesk",
              },
              color: "#8b8fa1",
            },
            grid: {
              color: ["#f0f1f5"],
              borderDash: function (context: any) {
                if (!context.index) return [];

                return [5];
              },
              borderColor: "transparent",
            },
            gridLines: {
              drawBorder: false,
            },
          },
          x: {
            stacked: true,
            offset: false,
            grid: {
              display: false,
              borderColor: "transparent",
            },
            ticks: {
              font: {
                weight: 500,
                size: 14,
                family: "HkGrotesk",
              },
              padding: 0,
              color: "#8b8fa1",
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: (context: any) => {
              externalTooltipHandler(context, this.compareData);
            },
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderColor: "#fff",
            borderWidth: 1,
            borderSkipped: false,
          },
        },
        hoverBorderColor: "#fff",
      };
    },
  },

  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData: any, oldData: any) {
        if (isEqual(newData, oldData)) return;

        this.chartData = cloneDeep(newData);

        if (this.chartData) {
          // Workaround to add space to the left and right of the chart
          this.chartData.labels?.push("");
          this.chartData.labels?.unshift("");
          this.chartData.datasets?.forEach((dataset: any) => {
            dataset.data?.push(null);
            dataset.data?.unshift(null);
          });
        }
      },
    },
  },
});
