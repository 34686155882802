var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElDialog',{directives:[{name:"dialog-confirmation",rawName:"v-dialog-confirmation",value:(_vm.dialogConfirmationOptions),expression:"dialogConfirmationOptions"}],ref:"modal",staticClass:"account-settings-modal",attrs:{"visible":_vm.innerVisibility,"title":_vm.$t('profile.accountSettingsModal.title'),"before-close":_vm.beforeCloseHandler},on:{"update:visible":function($event){_vm.innerVisibility=$event}}},[_c('PxModalMessage',{attrs:{"visible":_vm.hasServerError}}),_c('PxModalMessage',{attrs:{"visible":_vm.isPasswordSuccessVisible,"timeout":5000,"title":_vm.$t('profile.accountSettingsModal.changePassword.successMessage'),"type":"success"},on:{"update:visible":function($event){_vm.isPasswordSuccessVisible=$event}}}),_c('PxModalMessage',{attrs:{"visible":_vm.isEmailSuccessVisible,"timeout":5000,"title":_vm.$t('profile.accountSettingsModal.changeEmail.successMessage'),"type":"success"},on:{"update:visible":function($event){_vm.isEmailSuccessVisible=$event}}}),_c('ElTabs',{staticClass:"el-tabs--blue-line",on:{"tab-click":_vm.tabClickHandler},model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}},[_c('ElTabPane',{attrs:{"name":"email","label":_vm.$t('profile.accountSettingsModal.tabs.email')}},[_c('span')]),_c('ElTabPane',{attrs:{"name":"password","label":_vm.$t('profile.accountSettingsModal.tabs.password')}},[_c('span')])],1),_c('PxPanel',{attrs:{"max-height":1090,"no-borders":"","scrollable":""}},[_c('VWindow',{model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}},[_c('VWindowItem',{attrs:{"value":"password"}},[_c('ElForm',{ref:_vm.forms.password,staticClass:"profile-edit-form",attrs:{"model":_vm.fields,"rules":_vm.rules},on:{"validate":_vm.updateSubmitState}},[_c('ElFormItem',{attrs:{"label":_vm.$t(
                'profile.accountSettingsModal.changePassword.fields.oldPassword'
              ),"prop":"old_password"}},[_c('ElInput',{attrs:{"placeholder":_vm.$t(
                  'profile.accountSettingsModal.changePassword.fields.oldPassword'
                ),"type":"password"},model:{value:(_vm.fields.old_password),callback:function ($$v) {_vm.$set(_vm.fields, "old_password", $$v)},expression:"fields.old_password"}})],1),_c('ElFormItem',{attrs:{"label":_vm.$t(
                'profile.accountSettingsModal.changePassword.fields.newPassword'
              ),"prop":"new_password1"}},[_c('span',{staticClass:"el-form-item__top-text el-form-item__requirement",domProps:{"textContent":_vm._s(
                _vm.$t('profile.accountSettingsModal.changePassword.requirement')
              )}}),_c('PxInputPassword',{attrs:{"placeholder":_vm.$t(
                  'profile.accountSettingsModal.changePassword.fields.newPassword'
                ),"show-success-state":true},on:{"input":_vm.validateConfirmationField},model:{value:(_vm.fields.new_password1),callback:function ($$v) {_vm.$set(_vm.fields, "new_password1", $$v)},expression:"fields.new_password1"}}),_c('span',{staticClass:"el-form-item__bottom-text el-form-item__tip",domProps:{"innerHTML":_vm._s(_vm.$t('profile.accountSettingsModal.changePassword.tip'))}})],1),_c('ElFormItem',{attrs:{"label":_vm.$t(
                'profile.accountSettingsModal.changePassword.fields.confirmPassword'
              ),"prop":"new_password2"}},[_c('ElInput',{staticClass:"el-input--password",attrs:{"placeholder":_vm.$t(
                  'profile.accountSettingsModal.changePassword.fields.confirmPassword'
                ),"disabled":_vm.passwordIsInvalid,"type":"password"},model:{value:(_vm.fields.new_password2),callback:function ($$v) {_vm.$set(_vm.fields, "new_password2", $$v)},expression:"fields.new_password2"}})],1)],1)],1),_c('VWindowItem',{attrs:{"value":"email"}},[_c('ElForm',{ref:_vm.forms.email,staticClass:"profile-edit-form",attrs:{"model":_vm.emailFields,"rules":_vm.rules},on:{"validate":_vm.updateSubmitState}},[_c('ElFormItem',{attrs:{"label":_vm.$t('profile.accountSettingsModal.changeEmail.fields.newEmail'),"prop":"email"}},[_c('ElInput',{attrs:{"placeholder":_vm.$t('profile.accountSettingsModal.changeEmail.fields.newEmail'),"type":"email"},model:{value:(_vm.emailFields.email),callback:function ($$v) {_vm.$set(_vm.emailFields, "email", $$v)},expression:"emailFields.email"}}),(_vm.submittedEmailChange)?_c('div',{staticClass:"profile-edit-form__instructions"},[_c('div',{staticClass:"profile-edit-form__notice",domProps:{"innerHTML":_vm._s(
                  _vm.$t('profile.accountSettingsModal.changeEmail.notice', {
                    email: _vm.newEmail,
                  })
                )}}),(!_vm.resentEmailConfirmation)?_c('div',{staticClass:"profile-edit-form__cta"},[_vm._v(" "+_vm._s(_vm.$t("profile.accountSettingsModal.changeEmail.help"))+" "),_c('ElButton',{staticClass:"el-button--link-blue",attrs:{"type":"link"},on:{"click":_vm.resendEmailClickHandler}},[_vm._v(" "+_vm._s(_vm.$t("profile.accountSettingsModal.changeEmail.resendLink"))+" ")])],1):_vm._e()]):_vm._e()],1),_c('ElFormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.emailHasChanged),expression:"emailHasChanged"}],attrs:{"label":_vm.$t('profile.accountSettingsModal.changeEmail.fields.password'),"prop":"password"}},[_c('ElInput',{attrs:{"placeholder":_vm.$t('profile.accountSettingsModal.changeEmail.fields.password'),"type":"password"},model:{value:(_vm.emailFields.password),callback:function ($$v) {_vm.$set(_vm.emailFields, "password", $$v)},expression:"emailFields.password"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ElButton',{staticClass:"el-button--small profile-edit-form__reset-btn",attrs:{"type":"link"},on:{"click":function($event){return _vm.$refs.modal.$emit('confirm-cancel-edit')}}},[_vm._v(" "+_vm._s(_vm.$t("profile.accountSettingsModal.changePassword.resetButton"))+" ")]),_c('ElButton',{staticClass:"profile-edit-form__submit-btn",attrs:{"disabled":_vm.isSubmissionDisabled,"loading":_vm.isLoading,"type":"green"},on:{"click":_vm.onClickUpdate}},[_vm._v(" "+_vm._s(_vm.submitButtons[_vm.selectedView])+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }