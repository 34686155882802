





























































































































































import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { ErrorsManager } from "@/services/errors-manager";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import { pendingUserRegisterProvider } from "@/modules/authentication/services/data/pending-user-register/pending-user-register.provider";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredSetValidator,
  generateRequiredValidator,
  generateURLValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import {
  AFFILIATE_PROGRAM_TERMS_OF_USE_URL,
  AFFILIATE_PROGRAM_PRIVACY_POLICY_URL,
} from "@/modules/affiliates/constants";
import { SELF_ASSESSMENT_BEGAN_STATE } from "@/modules/self-assessment/constants";
import { ILocation } from "@/services/data/location/location.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { gaTrackEvent } from "@/services/utils/ga.utils";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import { pendingUserUpdateProvider } from "@/modules/authentication/services/data/pending-user-update/pending-user-update.provider";
import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "UserDetailsModal",

  components: {
    PxInputPlaces,
    SectorsSelector,
  },

  static: {
    termsOfUseUrl: AFFILIATE_PROGRAM_TERMS_OF_USE_URL,
    privacyPolicyUrl: AFFILIATE_PROGRAM_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      innerVisibility: true,
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      formWrapperScrollAtEnd: false,

      form: {
        email: "",
        company: {
          name: "",
          website: "",
          location: {} as ILocation,
          sectors: [] as Array<number>,
        },
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        company: {
          name: generateRequiredValidator(
            this,
            "affiliateProgram.companyInfoModal.fields.name",
          ),
          location: generateRequiredValidator(
            this,
            "affiliateProgram.companyInfoModal.fields.location",
          ),
          website: generateURLValidator(
            this,
            "authentication.signup.form.fields.website",
            true,
          ),
          sectors: generateRequiredSetValidator(
            this,
            "affiliateProgram.companyInfoModal.errors.sector",
          ),
        },
        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
    };
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliatesData.affiliate;
    },

    affiliateName(): string {
      return this.affiliate && this.affiliate.name ? this.affiliate.name : "";
    },

    affiliateId(): number | null {
      return this.affiliate && this.affiliate.id ? this.affiliate.id : null;
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.affiliatesData.draftSubmission?.data.assessment || [];
    },

    currentForm(): HTMLElement {
      return this.$refs.form as HTMLElement;
    },

    companyToken(): string | null {
      return this.$route.query && (this.$route.query.token as string | null);
    },

    vendor(): string | null {
      return this.$route.query && (this.$route.query.vendor as string | null);
    },
  },

  async created() {
    this.fetchCompanyByToken();

    await this.$nextTick();
    this.checkFormWrapperScroll();
  },

  methods: {
    fetchCompanyByToken() {
      if (this.companyToken || this.vendor) {
        companyProvider
          .get(this.companyToken || this.vendor)
          .then((loadedCompany: any) => {
            this.form.email = loadedCompany.email || "";
            this.form.company = this.setCompanyDataOnForm(loadedCompany);
          });
      }
    },

    setCompanyDataOnForm(loadedCompany: any | object) {
      return {
        ...this.form.company,
        name: loadedCompany.name || "",
        location:
          loadedCompany.locations && loadedCompany.locations.length > 0
            ? loadedCompany.locations[0]
            : ({} as ILocation),
        website: loadedCompany.website || "",
      };
    },

    onConfirmCancel() {
      this.innerVisibility = false;
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    gotoSignInModal() {
      this.$emit("sign-in", true);
    },

    onModalClose() {
      this.$emit("close");
    },

    checkFormWrapperScroll() {
      const wrapper = this.$refs.formWrapper as HTMLElement;

      if (!wrapper) {
        return;
      }

      this.formWrapperScrollAtEnd =
        wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight;
    },

    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },

    async onSubmitClick() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;
      this.errors.clear();

      try {
        const clearedLevels = this.assessmentLevels.map((value: any) => ({
          ...value,
          level: 0,
        }));

        const submitData = {
          ...this.form,
          levels: cloneDeep(clearedLevels),
          affiliate: this.affiliateId,
          group: ENTREPRENEUR_USER_GROUP_ID,
        };

        const response =
          this.companyToken || this.vendor
            ? await pendingUserUpdateProvider.updateCompany({
                ...submitData,
                token: this.companyToken as string,
              })
            : await pendingUserRegisterProvider.create(submitData);

        if (response.email) {
          await this.$store.dispatch(EAffiliatesActions.SET_PENDING_USER, {
            email: response.email,
            auth_token: response.auth_token,
            assessment: {
              levels: cloneDeep(clearedLevels),
              state: SELF_ASSESSMENT_BEGAN_STATE,
            },
          });
        }

        gaTrackEvent("start", "Self-Assessment");
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        } else {
          this.hasServerErrors = true;
        }
        return;
      } finally {
        this.isLoadingSubmission = false;
        this.$emit("submit");
      }
    },
  },
});
