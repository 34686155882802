













































































import Vue from "vue";

import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import {
  allFormFieldsValid,
  generateRequiredSetValidator,
  generateRequiredValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";

export default Vue.extend({
  name: "SignUpStepCompany",

  components: {
    PxInputPlaces,
    SectorsSelector,
  },

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: true,

      data: {
        company: {
          name: "",
          location: null,
          sectors: [] as Array<number>,
        },
      },
      rules: {
        company: {
          name: generateRequiredValidator(
            this,
            "authentication.signup.form.fields.companyName",
          ),
          location: generateRequiredValidator(
            this,
            "authentication.signup.form.fields.location",
          ),
          website: generateURLValidator(
            this,
            "authentication.signup.form.fields.website",
          ),
          sectors: generateRequiredSetValidator(
            this,
            "selfAssessment.companyInfoModal.errors.sector",
          ),
        },
      },
    };
  },

  computed: {
    signupFormRef(): HTMLElement {
      return this.$refs.signupForm as HTMLElement;
    },
  },

  methods: {
    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);
      this.$emit("update:company", this.data.company);
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
