




















import Vue from "vue";

export default Vue.extend({
  name: "PxFormModal",

  props: {
    title: {
      type: String,
      default: () => "",
    },
    visibility: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  watch: {
    visibility(value) {
      this.innerVisibility = value;
    },
  },

  methods: {
    onModalClose() {
      this.innerVisibility = false;
      this.$emit("close");
    },
  },
});
