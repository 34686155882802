





































































import Vue from "vue";

import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { ETeamMembersActions } from "@/modules/team-management/services/store/team-members/team-members.types";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { INITIATIVE_ONBOARDED } from "@/modules/team-management/constants";
import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";

import InitiativeDialog from "@/modules/team-management/components/initiative-modal/initiative-dialog.vue";
import TeamMembersList from "@/modules/team-management/components/team-members-list/team-members-list.vue";
import TeamMemberSettings from "@/modules/team-management/components/team-member-settings/team-member-settings.vue";
import DiscardModal from "@/components/discard-modal/discard-modal.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "TeamManagementView",

  components: {
    TeamMembersList,
    TeamMemberSettings,
    DiscardModal,
    InitiativeDialog,
    NavigationFooter,
  },

  static: {
    linkButton: {
      type: EPxButtonType.LINK,
    },
  },

  props: {
    isAffiliateView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      members: [] as Array<ITeamMember>,
      selectedTeamMemberIndex: 0,
      initiativeDialogVisible: false,
      teamMemberQuestions: [] as Array<IMatchingQuestion> | null,
      scroll: null as null | string,
      indexOfMemberToRemove: null as null | number,
      formIsInvalid: false,
      isDiscardModalVisible: false,
    };
  },

  computed: {
    teamMembers(): Array<ITeamMember> {
      return this.isAffiliateView && !this.isSupporter
        ? this.$store.state.affiliates.draftSubmission?.data.teamMembers
        : this.$store.get("teamMembers/values");
    },

    membersHaveChanges(): boolean {
      const savedTeamMembersWithoutResponses = cloneDeep(this.teamMembers).map(
        (member) => {
          delete member.responses;
          return member;
        },
      );

      const updatedTeamMembersWithoutResponses = cloneDeep(this.members).map(
        (member) => {
          delete member.responses;
          return member;
        },
      );

      return (
        savedTeamMembersWithoutResponses &&
        updatedTeamMembersWithoutResponses &&
        JSON.stringify(savedTeamMembersWithoutResponses) !==
          JSON.stringify(updatedTeamMembersWithoutResponses)
      );
    },

    selectedTeamMember(): ITeamMember | undefined {
      return this.members[this.selectedTeamMemberIndex];
    },

    removeModalProps(): any {
      return {
        title: this.$t("teamManagement.membersList.removeMemberModal.title", {
          member: this.selectedTeamMemberName,
        }),
        actionButtonLabel:
          "teamManagement.membersList.removeMemberModal.action",
      };
    },

    hasNoMember(): boolean {
      return (
        this.members[0] &&
        (!this.members[0].first_name ||
          !this.members[0].last_name ||
          !this.members[0].email ||
          !this.members[0].position)
      );
    },

    selectedTeamMemberName(): string {
      // If is empty member
      if (
        !this.selectedTeamMember?.first_name &&
        !this.selectedTeamMember?.last_name
      ) {
        return this.$t("teamManagement.newMember") as string;
      }

      //If we have both names
      if (
        this.selectedTeamMember?.first_name &&
        this.selectedTeamMember?.last_name
      ) {
        return `${this.selectedTeamMember?.first_name} ${this.selectedTeamMember?.last_name}`;
      }

      return this.selectedTeamMember?.first_name
        ? this.selectedTeamMember?.first_name
        : this.selectedTeamMember?.last_name;
    },

    backToProfileButtonProps(): any {
      return {
        ...this.$options.static.linkButton,
        label: this.$t("teamManagement.profileLink"),
      };
    },

    teamMembersLoading(): boolean {
      return this.$store.get("teamMembers.loading");
    },

    hasOnboardedInitiative(): boolean {
      return (
        !!this.$store.getters[EMetaGetters.GET](INITIATIVE_ONBOARDED) || false
      );
    },

    isSupporter(): boolean {
      return this.$user.isSupporter() || this.$user.isPendingSupporter();
    },
  },

  watch: {
    members: {
      handler() {
        if (this.isAffiliateView) {
          this.debouncedUpdateStoreData();
        }
      },
    },
  },

  async created() {
    await this.$store.dispatch(ETeamMembersActions.GET_VALUES);

    if (this.isAffiliateView) {
      this.prefillQuestionsData();
    }

    this.prefillMembersData();
  },

  mounted() {
    if (!this.hasOnboardedInitiative) {
      this.initiativeDialogVisible = true;
      this.markAsOnboarded();
    }
  },

  beforeDestroy() {
    this.teamMemberQuestions = null;
  },

  methods: {
    prefillMembersData(scrollType = "top") {
      if (this.teamMembers.length) {
        this.members = cloneDeep(this.teamMembers);
      } else {
        this.members = [
          {
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            position: "",
            responses: [],
          },
        ];
      }

      this.scroll = scrollType;
    },

    markAsOnboarded(): void {
      this.$store.dispatch(EMetaActions.SET, {
        key: INITIATIVE_ONBOARDED,
        value: true,
      });
    },

    prefillQuestionsData() {
      this.teamMemberQuestions = this.$user.isEntrepreneur()
        ? this.$store.state.affiliates.teamMembersQuestionBundle
        : this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE].filter(
            (question: IMatchingQuestion) => question.is_team_member_question,
          );
    },

    updateSelectedMember(index: number) {
      this.selectedTeamMemberIndex = index;
    },

    updateMemberInfo(memberInfo: ITeamMember) {
      Vue.set(this.members, this.selectedTeamMemberIndex, { ...memberInfo });
    },

    debouncedUpdateStoreData: debounce(function (this: any) {
      this.$store.dispatch(
        EAffiliatesActions.SET_SUBMISSION_TEAM_MEMBERS,
        this.members,
      );
    }, 1000),

    async saveTeamMembers() {
      const teamMembers = this.members.filter(
        (member) =>
          member.first_name &&
          member.last_name &&
          member.email &&
          member.position,
      );

      await this.$store.dispatch(
        ETeamMembersActions.CREATE_VALUES,
        teamMembers,
      );

      const message = this.$t("teamManagement.saved") as string;

      this.$message({
        message,
        type: "success",
        customClass: "is-full",
      });
    },

    removeTeamMember() {
      // Update team members
      this.members = this.members.filter(
        (member: ITeamMember, index) => index !== this.indexOfMemberToRemove,
      );

      // Navigate to previous member if exists, if not navigate to first member of array
      if (this.selectedTeamMemberIndex > 0) {
        this.selectedTeamMemberIndex--;
      }

      this.indexOfMemberToRemove = null;
    },

    addNewTeamMember() {
      const newMember = {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        position: "",
        responses: [],
      };

      // Add new member to array of members and update selected member id to the new added member
      this.members.push(newMember);
      this.selectedTeamMemberIndex = this.members.length - 1;
      setTimeout(() => {
        this.scroll = "bottom";
      }, 200);
    },

    showRemoveModal(index: number) {
      this.indexOfMemberToRemove = index;
      this.isDiscardModalVisible = true;
    },

    goToProfilePage() {
      this.$router.push({ name: ROUTE_PROFILE });
    },

    saveSupporterAffiliate() {
      const teamMembers = this.members.filter(
        (member) =>
          member.first_name &&
          member.last_name &&
          member.email &&
          member.position,
      );

      this.$emit("next-page", {
        team_members: teamMembers,
      });
    },

    checkFormInvalid(isInvalid: boolean) {
      this.formIsInvalid = isInvalid;
    },
  },
});
