











































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MilestonePlanStateBadge",

  props: {
    lastUpdate: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDraft(): boolean {
      return (
        this.label ===
        this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
      );
    },

    isNotPlanned(): boolean {
      return (
        this.label ===
        this.$t("milestonePlanner.milestonePlan.planForm.badges.notPlanned")
      );
    },

    icon(): { [key: string]: string | number } {
      return {
        name: !this.isDraft ? "milestone-check" : "locker",
        size: !this.isDraft ? 16 : 12,
      };
    },

    draftTooltipCopy(): TranslateResult {
      return !this.isVisitor
        ? this.$t(
            "milestonePlanner.milestonePlan.planForm.draftBadgeTooltip.owner",
          )
        : this.$t(
            "milestonePlanner.milestonePlan.planForm.draftBadgeTooltip.guest",
            {
              company: this.ownerName,
            },
          );
    },

    ownerName(): string {
      return this.$store.get("profile/company.data")?.name || "";
    },
  },
});
