







































import Vue from "vue";
import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import SubmissionsPanel from "@/modules/submissions/components/submissions-panel/submissions-panel.vue";

import { TranslateResult } from "vue-i18n";
import { supporterOwnedAffiliatesSubmissionsProvider } from "@/modules/submissions/services/data/submissions/supporter-owned-affiliates-submissions.provider";
import {
  IAffiliateSubmissions,
  IAffiliateSubmission,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "OwnedAffiliatesSubmissionsView",

  components: {
    PxPageHeader,
    SubmissionsPanel,
  },

  props: {
    searchInputValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      submissions: {} as IAffiliateSubmissions | any,
      dataLoading: false,
      submissionFilter: "",
      filteredDraftSubmissions: [] as Array<IAffiliateSubmission>,
      filteredSubmittedSubmissions: [] as Array<IAffiliateSubmission>,
    };
  },

  computed: {
    headerCopy(): TranslateResult {
      return this.$t("ownedAffiliatesSubmissions.header");
    },

    draftSubmissions(): Array<IAffiliateSubmission> {
      return this.submissions?.drafts || [];
    },

    submittedSubmissions(): Array<IAffiliateSubmission> {
      return this.submissions?.submitted || [];
    },

    noSubmissions(): boolean {
      return (
        !this.submissions?.drafts?.length &&
        !this.submissions?.submitted?.length
      );
    },
  },

  async created() {
    this.dataLoading = true;
    this.submissions = await supporterOwnedAffiliatesSubmissionsProvider.list();
    this.filteredDraftSubmissions = cloneDeep(this.draftSubmissions);
    this.filteredSubmittedSubmissions = cloneDeep(this.submittedSubmissions);
    this.dataLoading = false;

    this.submissionFilter = decodeURIComponent(this.searchInputValue);
    this.filterSubmissions();
  },

  methods: {
    filterSubmissions() {
      const value = this.submissionFilter.trim();

      if (value.length) {
        this.filteredDraftSubmissions = this.draftSubmissions.filter(
          (submission) => {
            return this.checkAffiliateNameAndOwners(value, submission);
          },
        );

        this.filteredSubmittedSubmissions = this.submittedSubmissions.filter(
          (submission) => {
            return this.checkAffiliateNameAndOwners(value, submission);
          },
        );
      } else {
        this.filteredDraftSubmissions = cloneDeep(this.draftSubmissions);
        this.filteredSubmittedSubmissions = cloneDeep(
          this.submittedSubmissions,
        );
      }
    },

    checkAffiliateNameAndOwners(
      value: string,
      submission: IAffiliateSubmission,
    ) {
      return (
        submission.affiliate.name.toLowerCase().includes(value.toLowerCase()) ||
        submission.owners?.some((owner) =>
          owner.name.toLowerCase().includes(value.toLowerCase()),
        )
      );
    },

    goToPreviousPage() {
      return this.$router.back();
    },
  },
});
