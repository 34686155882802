
















import Vue from "vue";

export default Vue.extend({
  name: "PricingSwitch",

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
});
