



















































































import Vue from "vue";
import xor from "lodash/xor";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default Vue.extend({
  name: "LevelRangeSlider",

  props: {
    rangeRestriction: {
      type: Number,
      default: null,
    },
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Array as () => any[],
      default: () => [1, 1],
    },
    color: {
      type: String,
      default: "ff8e54",
    },
    /**
     * Component height.
     */
    width: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: [1, 1],
      currentDraggedLevel: this.value,
      rangeHasOverreachedLimit: false,
      showTooltipOverreached: false,
      showTooltip: false,
      entryTooltip: this.$t("supporters.assessment.entryTooltip"),
      limitOverreachedTooltip: this.$t(
        "supporters.assessment.limitOverreachedTooltip",
      ),
      levelsBeingSelected: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 105 / this.levels;

      const result = [0];
      for (let i = 0; i < stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },

    firstStop(): number {
      return this.innerValue[0];
    },

    lastStop(): number {
      return this.innerValue[1];
    },

    firstBeingSelectedLevel(): number {
      return this.levelsBeingSelected[0];
    },

    lastBeingSelectedLevel(): number {
      return this.levelsBeingSelected[1];
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue(newVal, oldVal) {
      const newRange = this.rangeRestriction
        ? this.applyRangeRestrictions(newVal, oldVal)
        : newVal;
      const hasNewValue = newVal.some((newLevel: number) =>
        oldVal.some((oldLevel: number) => newLevel !== oldLevel),
      );

      if (hasNewValue) {
        this.$emit("input", newRange);
        this.$emit("changed", newRange);
      }
    },

    value: {
      immediate: true,
      handler(newVal: Array<number>) {
        if (xor(newVal, this.innerValue).length) {
          this.innerValue = newVal;
        }
      },
    },

    /**
     * Hide tolltip after 4 seconds
     */
    showTooltip(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.showTooltip = false;
        }, 4000);
      }
    },

    /**
     * Hide tolltip after 5 seconds
     */
    showTooltipOverreached(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.showTooltipOverreached = false;
        }, 5000);
      }
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
    this.showTooltip = true;
  },

  methods: {
    handleRangeSliderInputChange(newVal: Array<number>) {
      this.levelsBeingSelected = newVal;
    },

    rangeTipButtonClickHandler() {
      this.$emit("range-levels-modal", true);
    },
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as Vue;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElements = sliderElement.querySelectorAll(
        SLIDER_BUTTON_SELECTOR,
      ) as NodeListOf<HTMLElement>;

      [progressBar, ...buttonElements].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },

    applyRangeRestrictions(
      newRangeValue: Array<number>,
      oldRangeValue: Array<number>,
    ) {
      if (!oldRangeValue) return newRangeValue;

      const updatedRangeValue: Array<number> = [...newRangeValue];

      const rangeDifference = this.rangeRestriction - 1;
      const rangeGotHigher = newRangeValue[1] > this.currentDraggedLevel[1];
      const rangeGotLower = newRangeValue[0] < this.currentDraggedLevel[0];

      const rangeHasGrown =
        newRangeValue[0] + rangeDifference < newRangeValue[1];
      const rangeHasShrink =
        newRangeValue[1] - newRangeValue[0] <
        oldRangeValue[1] - oldRangeValue[0];
      const rangeReachedLimit = rangeHasGrown || rangeHasShrink;

      if (rangeReachedLimit) {
        this.rangeHasOverreachedLimit = rangeHasGrown;

        if (rangeGotHigher) {
          // Pull first range selector up
          updatedRangeValue[0] = newRangeValue[1] - rangeDifference;
        } else if (rangeGotLower) {
          // Pull last range selector down
          updatedRangeValue[1] = newRangeValue[0] + rangeDifference;
        }
      }

      return updatedRangeValue;
    },

    changeLevelHandler(newLevel: number[]) {
      this.currentDraggedLevel = newLevel;
      this.showTooltip = false;

      if (this.rangeHasOverreachedLimit) {
        this.showTooltipOverreached = true;
        this.rangeHasOverreachedLimit = false;
      }
    },

    isLevelBeingSelected(index: number): boolean {
      return (
        index >= this.firstBeingSelectedLevel &&
        index <= this.lastBeingSelectedLevel
      );
    },

    isLevelSelected(index: number): boolean {
      return index === this.firstStop || index === this.lastStop;
    },
    isInLevelSelectedInterval(index: number): boolean {
      return index > this.firstStop && index < this.lastStop;
    },

    isLevelSelectable(index: number): boolean {
      const rangeDifference = this.rangeRestriction - 1;
      const selectedRangeDifference = this.lastStop - this.firstStop;
      const rangeAvailable = rangeDifference - selectedRangeDifference;

      const canSelectLowerLevel =
        index < this.firstStop && index >= this.firstStop - rangeAvailable;
      const canSelectHigherLevel =
        index > this.lastStop && index <= this.lastStop + rangeAvailable;

      return canSelectLowerLevel || canSelectHigherLevel;
    },

    currentLevelState(index: number) {
      return {
        "is-selected": this.isLevelSelected(index),
        "is-selected-interval": this.isInLevelSelectedInterval(index),
        "is-selectable": this.isLevelSelectable(index),
      };
    },
  },
});
