























































import Vue from "vue";
import { get } from "vuex-pathify";

import CompanyEditModalEntrepreneur from "@/modules/profile/components/company-edit-modal/company-edit-modal--entrepreneur.vue";
import CompanyEditModalSupporter from "@/modules/profile/components/company-edit-modal/company-edit-modal--supporter.vue";

export default Vue.extend({
  name: "CompanyEditModal",

  components: {
    CompanyEditModalEntrepreneur,
    CompanyEditModalSupporter,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedView: "",
      hasFormChanged: false,
      resetFormData: false,
      clickedSaveChanges: false,
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      submissionDisabled: false,
    };
  },

  computed: {
    company: get("profile/company/data"),
    isLoadingCompany: get("profile/company/loading"),

    dialogConfirmationOptions(): any {
      return [
        {
          eventName: "confirm-cancel-edit",
          showCondition: () => this.hasFormChanged,
          message: this.$t("common.dialogConfirmation.discardMessage"),
          confirmFn: this.onConfirmCancel,
        },
      ];
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(newVal) {
        this.innerVisibility = newVal;
        this.resetFormData = newVal;
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  mounted() {
    this.googleOptionsTop();
  },

  methods: {
    googleOptionsTop() {
      const dialogElement = this.$el.firstElementChild as HTMLElement;
      const googlePickerTop = () => {
        const googleList = document.querySelectorAll(".pac-container");
        const inputLocation = document.querySelector(
          "#companyEditLocation",
        ) as HTMLElement;

        /**
         * Find the Google object that is being displayed
         */
        const googleObject = Array.from(googleList).find(
          (item: any) => item.style.display !== "none",
        ) as HTMLElement;
        if (googleObject && inputLocation) {
          googleObject.style.top =
            inputLocation.getBoundingClientRect().top +
            inputLocation.offsetHeight +
            "px";
        }
      };

      if ((dialogElement as any).PointerEvent) {
        // Add Pointer Event Listener
        dialogElement.addEventListener("pointerdown", googlePickerTop, true);
        dialogElement.addEventListener("pointermove", googlePickerTop, true);
      } else {
        // Add Touch Listener
        dialogElement.addEventListener("touchstart", googlePickerTop, true);
        dialogElement.addEventListener("touchmove", googlePickerTop, true);
      }
    },

    focusInputField(field: string) {
      (this.$refs.modalForm as any).$refs[field].focus();
    },

    setCurrentView(view: string) {
      this.selectedView = view;
    },

    onConfirmCancel() {
      this.innerVisibility = false;
      this.resetFormData = true;
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
      (this.$refs.modalForm as any).$emit("confirm-cancel-edit");
    },

    closeHandler() {
      window.scrollTo(0, 0);
    },

    onClickSave() {
      this.clickedSaveChanges = true;
      this.$emit("update:visibility", false);
    },
  },
});
