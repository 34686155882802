




































import elDialogMixin from "@/mixins/el-dialog.mixin";
import PricingCard from "@/modules/profile/components/account-settings/pricing-card.vue";

import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import { SUBSCRIPTIONS_DATA } from "@/modules/profile/pricing-cards-data";

export default elDialogMixin.extend({
  name: "SubscriptionModal",

  components: {
    PricingCard,
  },

  computed: {
    pricingCardsData(): any {
      return SUBSCRIPTIONS_DATA;
    },

    freeButtonProps(): any {
      return {
        type: EPxButtonType.DEFAULT,
        size: EPxButtonSize.LARGE,
        label: this.$t("profile.subscriptionModal.ctas[0]"),
      };
    },

    proButtonProps(): any {
      return {
        type: EPxButtonType.BLUE,
        size: EPxButtonSize.LARGE,
        label: this.$t("profile.subscriptionModal.ctas[1]"),
      };
    },
  },

  methods: {
    continueAsFreeUser() {
      this.$emit("update:visibility", false);
    },

    upgradeToProUser() {
      this.$router.push({
        name: ROUTE_ACCOUNT_SETTINGS,
        query: { tab: EAccountSettingsNavigationTabs.SUBSCRIPTION },
      });
    },
  },
});
