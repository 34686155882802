import Bugsnag from "@bugsnag/js";

import { NotImplementedException } from "@/services/data/exceptions/not-implemented.exception";
import { client } from "@/services/data/api-client";
import {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { NotFoundProviderException } from "./exceptions/not-found-provider.exception";
import { GenericProviderException } from "./exceptions/generic-provider.exception";
import { ErrorsProviderException } from "./exceptions/errors-provider.exception";
import { filterSensitiveDataFromJSON } from "@/services/utils/string.utils";

/**
 * Default data type for data parameters.
 */
export interface IDataType {
  [key: string]: any;
}

/**
 * Default data type for URL options.
 */
export interface IOptionsType {
  [key: string]: any;
}

/**
 * List of features that a Provider can have.
 */
export enum EProviderFeatures {
  LIST,
  CREATE,
  GET,
  UPDATE,
  PATCH,
  DESTROY,
}

/**
 * Generic paginator.
 */
export interface IPaginationResult<S> {
  /**
   * Total number of available results.
   */
  count: number;

  /**
   * Next pagination link.
   *
   * This value is null when there is no next links.
   */
  next: string | null;

  /**
   * Previous pagination link.
   *
   * This value is null when there is no results or we are on the first page.
   */
  previous: string | null;

  /**
   * List with the results.
   */
  results: Array<S>;
}

/**
 * Abstract class that is the base for all Providers.
 *
 * This class uses generics to allow to specify a custom type for
 * the data received by the server and the data to be sent to the
 * server.
 */
export abstract class GenericProvider<T, P = IDataType> {
  /**
   * Axios instance.
   */
  protected httpClient: AxiosInstance = client;

  /**
   * Endpoint to be used to make the API call.
   */
  private readonly endpoint!: string;

  /**
   * List of features that this provider supports.
   */
  private features: Array<EProviderFeatures> = [];

  /**
   * Create a new Provider instance.
   *
   * @param endpoint Endpoint to be used to make the API call.
   * @param features
   * @param features
   */
  constructor(endpoint = "", features: Array<EProviderFeatures> = []) {
    this.endpoint = endpoint;

    if (this.features.length === 0) {
      this.features = [
        EProviderFeatures.LIST,
        EProviderFeatures.CREATE,
        EProviderFeatures.GET,
        EProviderFeatures.UPDATE,
        EProviderFeatures.PATCH,
        EProviderFeatures.DESTROY,
      ];
    } else {
      this.features = features;
    }
  }

  /**
   * Build the endpoint url with the query string parameters.
   */
  protected buildEndPointUrl(
    options: IOptionsType = {},
    preQuery?: string,
  ): string {
    const base = preQuery ? `${this.endpoint}/${preQuery}/` : this.endpoint;
    let queryString = "";

    for (const key in options) {
      if (!Object.prototype.hasOwnProperty.call(options, key)) {
        continue;
      }

      const value = options[key];
      if (queryString.length !== 0) {
        queryString += "&";
      }
      queryString += `${key}=${value}`;
    }

    if (queryString.length > 0) {
      return `${base}?${queryString}`;
    }

    return base;
  }

  /**
   * Fetch a list of resources.
   *
   * @param options Additional options to be passed as query parameters.
   */
  public async list(
    options: IOptionsType = {},
    axiosOptions: AxiosRequestConfig = {},
  ): Promise<Array<T>> {
    if (!this.features.includes(EProviderFeatures.LIST)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options);
    const request = this.httpClient.get(url, axiosOptions);
    const { data } = await this.wrapRequest(request);

    return data as Array<T>;
  }

  /**
   * Create a new resource.
   *
   * @param resourceData Data to the resource that will be created.
   * @param options Additional options to be passed as query parameters.
   */
  public async create(resourceData: P, options: IOptionsType = {}): Promise<T> {
    if (!this.features.includes(EProviderFeatures.CREATE)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options);
    const request = this.httpClient.post(url, resourceData);
    const { data } = await this.wrapRequest(request);

    return data as T;
  }

  /**
   * Get a specific resources by it's identifier.
   *
   * @param id Identifier for the resource to be retrieved.
   * @param options Additional options to be passed as query parameters.
   */
  public async get<IDT = number>(
    id: IDT,
    options: IOptionsType = {},
  ): Promise<T> {
    if (!this.features.includes(EProviderFeatures.GET)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options, String(id));
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data as T;
  }

  /**
   * Update a resource data.
   *
   * @param id Identifier for the resource to be retrieved.
   * @param resourceData New data to be used to update the resource.
   * @param options Additional options to be passed as query parameters.
   */
  public async update<IDT = number>(
    id: IDT,
    resourceData: P,
    options: IOptionsType = {},
  ): Promise<T> {
    if (!this.features.includes(EProviderFeatures.UPDATE)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options, String(id));
    const request = this.httpClient.put(url, resourceData);
    const { data } = await this.wrapRequest(request);

    return data as T;
  }

  /**
   * Apply a partial update to a resource.
   *
   * @param id Identifier of the resources to be patched.
   * @param resourceData Partial data to be updated
   * @param options Additional options to be passed as query params.
   */
  public async patch<IDT = number>(
    id: IDT,
    resourceData: P,
    options: IOptionsType = {},
  ): Promise<T> {
    if (!this.features.includes(EProviderFeatures.PATCH)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options, String(id));
    const request = this.httpClient.patch(url, resourceData);
    const { data } = await this.wrapRequest(request);

    return data as T;
  }

  /**
   * Remove a specific resource by it's identifier.
   *
   * @param id Identifier of the resource to be removed.
   * @param options Additional options to be passed as query parameters.
   */
  public async destroy(
    id: number | string,
    options: IOptionsType = {},
  ): Promise<void> {
    if (!this.features.includes(EProviderFeatures.DESTROY)) {
      throw new NotImplementedException();
    }

    const url = this.buildEndPointUrl(options, String(id));
    const request = this.httpClient.delete(url);
    await this.wrapRequest(request);
  }

  /**
   * Handle the axios error response.
   *
   * This objective of the method is to convert an errors
   * response into an exception with a specific Error type.
   *
   * @param axiosError Error response.
   */
  protected handleAxiosError(axiosError: AxiosResponse): Error {
    if (!!axiosError && axiosError.status === 404) {
      return new NotFoundProviderException(axiosError);
    } else if (
      !!axiosError &&
      axiosError.status === 400 &&
      axiosError.data.errors
    ) {
      return new ErrorsProviderException(axiosError);
    }

    return new GenericProviderException(axiosError);
  }

  /**
   * Notify all request errors to Bugsnag.
   *
   * In efforts to proactively act upon handled or unhandled
   * errors that break the app we're notifying all request
   * errors with its necessary context:
   * -> Vuex store
   * -> Identified user
   * -> Request and API Response
   *
   * @param axiosError Error response.
   */
  protected notifyErrorOnBugsnag(axiosError?: AxiosResponse): void {
    // TODO: Find a proper way to import the store module while avoiding a circular import:
    // https://stackoverflow.com/questions/38841469/how-to-fix-this-es6-module-circular-dependency
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const store = require("@/services/store").default;

    const statusCode = !!axiosError ? axiosError.status.toString() : "503";
    const isServerError = statusCode.startsWith("5");
    const errorType = isServerError ? "API internal" : "Client";

    Bugsnag.notify(
      new Error(
        !!axiosError
          ? `Yikes! ${errorType} error (${statusCode}) occurred while requesting: ${axiosError.config.url}.`
          : `Yikes! ${errorType} error (${statusCode}) when attempting to make a request.`,
      ),
      (event) => {
        // Include store
        event.addMetadata("Store", {
          store: filterSensitiveDataFromJSON(window.localStorage?.vuex || ""),
        });

        // Include auth or pending user
        const authState = store.get("auth") || null;
        const pendingEntrepreneur = store.get("viralLevel/pendingUser") || null;
        const pendingSupporter = store.get("supporterFlow/value") || null;
        const userId = authState?.profileId || "";
        const userName = authState?.company?.data?.name || "";
        const userEmail =
          authState?.user?.email ||
          pendingEntrepreneur?.email ||
          pendingSupporter?.email ||
          "";
        event.setUser(userId, userEmail, userName);

        if (!!axiosError) {
          // Include API request data
          event.addMetadata("API Request", {
            url: axiosError.config.url,
            response: axiosError.data,
            method: axiosError.config.method,
            body: filterSensitiveDataFromJSON(axiosError.config.data),
            withCredentials: axiosError.config.withCredentials,
          });
        }

        // Group error by URL context and its status code
        event.groupingHash = `${event.context || ""} status:${statusCode}`;
      },
    );
  }

  /**
   * Wrap the HTTP request to format the errors and abstract
   * it form each request.
   *
   * @param request Axios request to be made.
   */
  protected async wrapRequest(request: AxiosPromise): Promise<any> {
    try {
      return await request;
    } catch (error) {
      if (error?.cancelled) {
        return;
      }
      this.notifyErrorOnBugsnag(error);
      throw this.handleAxiosError(error);
    }
  }
}

/**
 * Decorator to easily set the provider base URL without
 * the need to declare a constructor.
 *
 * @param uri Base API URI for the resource
 * @param features Features available on the resource
 */
export const Provider =
  (uri: string, features: Array<EProviderFeatures> = []) =>
  (target: { new (...args: any[]): any }) => {
    const originalConstructor = target;

    const newConstructor: any = function (...args: any[]) {
      return new originalConstructor(uri, features, ...args);
    };

    newConstructor.prototype = originalConstructor.prototype;

    return newConstructor;
  };
