















import Vue from "vue";

export default Vue.extend({
  name: "TeamMembersListFooter",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    buttonLabel: {
      type: String,
      default: "teamManagement.membersList.newMemberButton",
    },
  },

  methods: {
    addTeamMember() {
      this.$emit("add-member");
    },
  },
});
