






























import Vue from "vue";

import RenderlessSupporterAssessment from "@/modules/common/components/renderless-supporter-assessment/renderless-supporter-assessment.vue";
import AssessmentDesktop from "@/modules/supporters/components/assessment-desktop/assessment-desktop.vue";
import AssessmentMobile from "@/modules/supporters/components/assessment-mobile/assessment-mobile.vue";

import { SUPPORTERS_AUTHENTICATION_SIGNUP } from "@/modules/supporters/services/router/routes-names";

export default Vue.extend({
  name: "Assessment",
  components: {
    RenderlessSupporterAssessment,
    AssessmentDesktop,
    AssessmentMobile,
  },

  props: {
    /**
     * This prop allows to the the end action for when the
     * user riches the end of the assessment.
     */
    endAction: {
      type: Function as () => any,
      default() {
        this.$router.push({
          name: SUPPORTERS_AUTHENTICATION_SIGNUP,
        });
      },
    },
  },
});
