

















































import elDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { INavigationTab } from "@/components/px-navigation-tab/px-navigation-tab.vue";

import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import { LocaleMessages } from "vue-i18n";

export default elDialogMixin.extend({
  name: "RequestProcessesModal",

  components: {
    PxNavigationTab,
  },

  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },

    showClose: {
      type: Boolean,
      default: false,
    },

    titlePath: {
      type: String,
      default: "chartsDashboard.requestProcessesModal.title[0]",
    },

    top: {
      type: String,
      default: "15px",
    },
  },

  data() {
    return {
      selectedNavbarId: 1,
    };
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$t("chartsDashboard.requestProcessesModal.cta"),
      };
    },

    modalCopy(): any {
      return this.selectedNavbarId === 1
        ? {
            description: this.$t(
              "chartsDashboard.requestProcessesModal.description[0]",
            ) as string,
            note: this.$t(
              "chartsDashboard.requestProcessesModal.note[0]",
            ) as string,
          }
        : {
            description: this.$t(
              "chartsDashboard.requestProcessesModal.description[1]",
            ) as string,
            note: this.$t(
              "chartsDashboard.requestProcessesModal.note[1]",
            ) as string,
          };
    },

    modalExampleCopy(): LocaleMessages {
      return this.$t(
        "chartsDashboard.requestProcessesModal.example2",
      ) as LocaleMessages;
    },

    navBarItems(): Array<INavigationTab> {
      return [
        {
          title: this.$t(
            "chartsDashboard.requestProcessesModal.tabs[0]",
          ) as string,
          id: 1,
        },
        {
          title: this.$t(
            "chartsDashboard.requestProcessesModal.tabs[1]",
          ) as string,
          id: 2,
        },
      ];
    },
  },

  methods: {
    openEmailClient() {
      const email = "support@abaca.app";
      const subject = this.$t(
        "chartsDashboard.requestProcessEmail.subject",
      ) as string;
      const body = this.$t(
        "chartsDashboard.requestProcessEmail.body",
      ) as string;

      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`;

      // Open the email client
      window.location.href = mailtoLink;
    },
  },
});
