import Vue from "vue";
import VueCurrencyFilter from "vue-currency-filter";

// TODO: Consider using parseFloat(number).toLocaleString if suits requirements
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});
