import Vue from "vue";
import MatchingCardThumbnail from "@/components/matching-card/matching-card-thumbnail.vue";
import MatchingCardPercentagePill from "@/components/matching-card/matching-card-percentage-pill.vue";
import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";
import MatchingBadgeList from "@/modules/matching/components/matching-badge-list/matching-badge-list.vue";
import SubmissionsModal from "@/components/submissions-modal/submissions-modal.vue";
import { IOffering } from "@/services/data/offering/offering.interface";
import { ISector } from "@/services/data/sector/sector.interface";
import { ILocation } from "@/services/data/location/location.interface";
import { IMatchingBadge } from "@/modules/matching/services/data/matching-badge/matching-badge.interface";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import {
  EMatchScoreDetailsActions,
  IMatchScoreDetails,
} from "@/modules/matching/services/store/match-score-details/match-score-details.types";

export default Vue.extend({
  components: {
    MatchingCardThumbnail,
    MatchingCardPercentagePill,
    MatchingCardTagList,
    MatchingBadgeList,
    SubmissionsModal,
  },

  props: {
    companyId: {
      type: Number,
      required: true,
    },

    companyUid: {
      type: String,
      required: true,
    },

    matchPercentage: {
      type: Number,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    about: {
      type: String,
      default: "",
    },

    location: {
      type: String,
      required: true,
    },

    level: {
      type: Array as () => number[],
      required: true,
    },

    offerings: {
      type: Array as () => IOffering[],
      default: () => [],
    },

    sectors: {
      type: Array as () => ISector[],
      default: () => [],
    },

    locations: {
      type: Array as () => ILocation[],
      default: () => [],
    },

    thumbnail: {
      type: String,
      default: "",
    },

    badges: {
      type: Array as () => IMatchingBadge[],
      default: () => [],
    },

    showListButton: {
      type: Boolean,
      default: true,
    },

    showConnectButton: {
      type: Boolean,
      default: true,
    },

    showPercentage: {
      type: Boolean,
      default: true,
    },

    showOptions: {
      type: Boolean,
      default: false,
    },

    showPublicView: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    isDirectoryMember: {
      type: Boolean,
      default: false,
    },

    affiliates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isInterestDetailModalVisible: false,
      isInnerSelected: false,
      isDeletePanelVisible: false,
      toggleOptions: false,
      matchScoreDetails: null as IMatchScoreDetails | null,
      submissionsModalVisible: false,
    };
  },

  beforeDestroy() {
    this.matchScoreDetails = null;
  },

  computed: {
    enabledMatchingInterest(): boolean {
      return this.$features.isEnabled("matchingInterest");
    },

    mappedSectors(): string[] {
      return (
        this.sectors &&
        this.sectors.reduce((previousValue, currentValue) => {
          const { name } = currentValue;
          return [...previousValue, name];
        }, [] as string[])
      );
    },

    matchLevel(): Array<number> {
      return this.showPublicView ? [] : this.level;
    },

    showInterestDetailModalVisible(): boolean {
      return !this.showPublicView && this.isInterestDetailModalVisible;
    },

    hasRegistrationListener(): boolean {
      return !!this.$listeners?.register;
    },
  },

  methods: {
    onRegistration() {
      this.$emit("register");
    },

    async onClickShowModal() {
      if (this.showPublicView && this.hasRegistrationListener) {
        this.onRegistration();
        return;
      }

      await this.getMatchScoreDetails();

      this.matchScoreDetails = this.$store.get("matchScoreDetails/data");
      this.isInterestDetailModalVisible = true;
    },

    async getMatchScoreDetails() {
      await this.$store.dispatch(
        EMatchScoreDetailsActions.FETCH,
        this.companyUid,
      );
    },

    onShowListManagementModal() {
      if (this.showPublicView && this.hasRegistrationListener) {
        this.onRegistration();
        return;
      }

      // Select self
      this.$store.dispatch(EListManagementActions.ADD_COMPANY, this.companyUid);

      // Emit root action, should be available on context
      this.$root.$emit("show-list-management-modal");
    },

    openSubmissionsModal() {
      this.submissionsModalVisible = true;
    },
  },
});
