










import Vue from "vue";

export default Vue.extend({
  name: "CompanyRequirements",

  props: {
    /**
     * Current category.
     */
    category: {
      type: Object as () => any,
      required: true,
    },

    /**
     * Current level.
     */
    level: {
      type: Number,
      required: true,
    },
  },

  computed: {
    levelDescription(): string {
      const description = this.category.categoryDetails.find(
        (item: any) => item.level.value === this.level,
      );

      const params = {
        description: description.requirements,
        level: this.level,
        category: this.category.name,
      };

      return this.$t(
        "supporters.assessment.rangeDescriptionTemplate",
        params,
      ) as string;
    },
  },
});
