





















import Vue from "vue";

const DEFAULT_STATE = { min: "", max: "" };

export default Vue.extend({
  props: {
    value: {
      type: [Object, Array],
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: { ...DEFAULT_STATE },
    };
  },

  watch: {
    values: {
      deep: true,
      handler(newVal) {
        const newValues = {
          min: parseFloat(newVal.min.replace(/,/g, "")),
          max: parseFloat(newVal.max.replace(/,/g, "")),
        };

        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("input", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid =
        values.max !== undefined &&
        values.min !== undefined &&
        values.max > values.min;

      this.$emit("update:isValid", isValid);
    },
  },
});
