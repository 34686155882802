













































































































































import Vue from "vue";
import moment from "moment";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IGridCategory } from "../components/milestones-grid/milestones-grid.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import MilestoneOnboardingDialog from "@/modules/milestone-planner/components/milestone-onboarding-dialog/milestone-onboarding-dialog.vue";
import GridLegend from "@/modules/milestone-planner/components/grid-legend/grid-legend.vue";
import OverviewSidebar from "@/modules/milestone-planner/components/overview-sidebar/overview-sidebar.vue";
import MilestoneCarousel from "@/modules/milestone-planner/components/milestone-carousel/milestone-carousel.vue";
import MilestonesGrid from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.vue";
import MilestoneSidebar from "@/modules/milestone-planner/components/milestone-sidebar/milestone-sidebar.vue";
import LastAssessmentDate from "@/modules/milestone-planner/components/last-assessment-date/last-assessment-date.vue";

import { ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS } from "../services/router/routes-names";
import { MILESTONE_PLANNER_VISITOR_ONBOARDED } from "@/modules/milestone-planner/constants";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "VisitorsMilestonePlannerOverview",

  components: {
    OverviewSidebar,
    MilestoneOnboardingDialog,
    GridLegend,
    MilestoneCarousel,
    MilestonesGrid,
    MilestoneSidebar,
    LastAssessmentDate,
  },

  static: {
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
    maximizeButton: {
      icon: "maximize",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      onboardingDialogVisibility: false,
      carouselVisible: false,
      carouselSelectedCategory: {},
    };
  },

  computed: {
    hasOnboarded(): boolean {
      return this.$store.get("milestonePlanner/onboardings/data")[
        MILESTONE_PLANNER_VISITOR_ONBOARDED
      ];
    },

    companyData(): ICompany {
      return this.$store.get("profile/company.data");
    },

    companyId(): number | null {
      return this.companyData ? this.companyData.id : null;
    },

    isLoading(): boolean {
      return [
        this.$store.get("profile/latestAssessment.loading"),
        this.$store.get("milestonePlanner.loading"),
      ].some((stillLoading) => stillLoading);
    },

    categories(): ICategory[] {
      return this.$store.get("categories.data");
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("milestonePlanner.parsedValue") || [];
    },

    latestAssessment(): IAssessment {
      return this.$store.get("profile/latestAssessment.data");
    },

    lastAssessmentDate(): string {
      return moment(this.latestAssessment?.updated_at).format(
        "DD MMMM YYYY, HH:MMA",
      );
    },

    shouldRenderGrid(): boolean {
      return (
        !!this.categories.length &&
        !!this.gridCategories.length &&
        !!this.latestAssessment
      );
    },
  },

  async mounted() {
    setTimeout(() => {
      if (!this.hasOnboarded) {
        this.onboardingDialogVisibility = true;
        this.markAsOnboarded();
      }
    }, 2000);
  },

  methods: {
    openOnboardingDialog() {
      this.onboardingDialogVisibility = true;
    },

    closeOnboardingDialog() {
      this.onboardingDialogVisibility = false;
    },

    hideCarousel() {
      this.carouselVisible = false;
    },

    showCarousel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
      this.carouselVisible = true;
    },

    changeCarouselCategoryLevel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
    },

    showFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS });
    },

    markAsOnboarded(): void {
      this.$store.set("milestonePlanner/onboardings/data", {
        [MILESTONE_PLANNER_VISITOR_ONBOARDED]: true,
      });
    },

    goToOwnerProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id: String(this.companyId) },
      });
    },
  },
});
