









import Vue from "vue";

export default Vue.extend({
  name: "PxNavigationStep",
  props: {
    color: {
      type: String,
      default: "#7bb7ff",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
