














































































import ElDialogMixin from "@/mixins/el-dialog.mixin";

import ShareModalContent from "@/components/share-modal/share-modal-content.vue";
import ShareModalSettings from "@/components/share-modal/share-modal-settings.vue";

import { LocaleMessages, TranslateResult } from "vue-i18n";
import { AxiosError } from "axios";
import { SHARE_MODAL_EVENTS } from "@/components/share-modal/share-modal.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { ICapitalExplorerInvitedUser } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { SUPPORTER_USER_TYPE } from "@/modules/authentication/constants";

export default ElDialogMixin.extend({
  name: "ShareResultsModal",

  components: {
    ShareModalContent,
    ShareModalSettings,
  },

  data() {
    return {
      settingsVisibility: false,
      hasServerError: false,
      linkWasReset: false,
      passcodeWasChanged: false,
      capitalExplorerHasBeenShared: false,
      supporters: SUPPORTER_USER_TYPE,
      usersToInvite: [] as Array<ICapitalExplorerInvitedUser["user_profile"]>,
      userToRemove: null as null | any,
      userHasBeenRemoved: false,
    };
  },

  props: {
    /**
     * Represents the capital explorer unique id
     */
    id: {
      type: String,
      required: true,
    },

    /**
     * Represents the capital explorer unique identifiable
     * slug used for the shareable link.
     */
    uid: {
      type: String,
      required: true,
    },

    /**
     * Optional passcode where empty means
     * having no protection.
     */
    passcode: {
      type: String,
      default: "",
    },

    users: {
      type: Array as () => Array<ICapitalExplorerInvitedUser>,
      default: [],
    },
  },

  watch: {
    innerVisibility(isVisible) {
      // Workaround since mounted and destroyed
      // hooks are not called when modals get toggled.
      if (isVisible) {
        this.listenForGlobalSettingsEvent();
        this.listenForGlobalConfirmationEvents();
      } else {
        this.disableGlobalSettingsEvent();
        this.disableGlobalConfirmationEvents();
      }
    },
  },
  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("capitalExplorer.shareModal") as LocaleMessages;
    },

    shareModalUsersCopy(): LocaleMessages {
      return this.shareModalCopy.users as LocaleMessages;
    },

    shareableLink(): string {
      const linkPath = this.$router.resolve({
        name: CAPITAL_EXPLORER_VISITORS_VIEW,
        params: { uid: this.uid },
      }).href;

      return location.origin + linkPath;
    },

    confirmationDialogs(): any {
      return [
        {
          event: SHARE_MODAL_EVENTS.UNSHARE_FROM_USER,
          key: "unshareFromUser",
          callback: this.onConfirmUnshare,
        },
        {
          event: SHARE_MODAL_EVENTS.RESET_SHAREABLE_LINK,
          key: "resetLink",
          callback: this.onConfirmResetLink,
        },
        {
          event: SHARE_MODAL_EVENTS.RESET_PASSCODE,
          key: "resetPasscode",
          callback: this.onConfirmResetPasscode,
        },
      ];
    },

    dialogConfirmationOptions(): any {
      return this.confirmationDialogs.map((confirmation: any) => ({
        eventName: confirmation.event,
        message: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.title`,
          {
            username: this.userToRemoveName,
          },
        ),
        tip: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.subtitle`,
          {
            username: this.userToRemoveName,
          },
        ),
        confirmButtonText: this.$t(
          `capitalExplorer.shareModal.confirmationDialogs.${confirmation.key}.cta`,
        ),
        cancelButtonText: this.$t("common.cancel"),
        confirmFn: confirmation.callback,
      }));
    },

    capitalExplorerErrors(): AxiosError {
      return this.$store.get("capitalExplorer/error");
    },

    capitalExplorerLoading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    userToInviteName(): string | undefined {
      return this.users.find((user: ICapitalExplorerInvitedUser) =>
        this.usersToInvite.includes(user.user_profile),
      )?.name;
    },

    successShareMessage(): TranslateResult {
      return this.usersToInvite.length > 1
        ? this.$t("capitalExplorer.shareModal.messages.sharedMultipleUsers")
        : this.$t(`capitalExplorer.shareModal.messages.shared`, {
            users: this.userToInviteName,
          });
    },

    successUserRemovalMessage(): string {
      return this.$t(`capitalExplorer.shareModal.messages.accessRemoved`, {
        user: this.userToRemoveName,
      }) as string;
    },

    userToRemoveName(): string {
      return this.userToRemove?.name || "";
    },
  },

  methods: {
    beforeCloseHandler() {
      this.$emit("update:visibility", false);
      this.settingsVisibility = false;
    },

    listenForGlobalSettingsEvent() {
      this.$root.$on("toggle-settings", (visibility: boolean) => {
        this.settingsVisibility = visibility;
      });
    },

    listenForGlobalConfirmationEvents() {
      // Workaround for the dialog confirmation
      // directive to work with global events:
      this.$root.$on(
        "share-modal-action",
        ({
          event,
          payload,
        }: {
          event: string;
          payload: { [key: string]: any };
        }) => {
          (this as any).$refs.modal.$emit(event);
          if (payload) {
            this.userToRemove =
              this.users.find(
                (user: ICapitalExplorerInvitedUser) =>
                  user.user_profile === payload.user_profile,
              ) || null;
          }
        },
      );
    },

    disableGlobalSettingsEvent() {
      this.$root.$off("toggle-settings");
    },

    disableGlobalConfirmationEvents() {
      this.$root.$off("share-modal-action");
    },

    onConfirmResetLink() {
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          queryParams: {
            reset: "link",
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.linkWasReset = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmResetPasscode() {
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          queryParams: {
            reset: "passcode",
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmUnshare() {
      const usersStillInvited = this.users
        .filter(
          (user) =>
            !!user?.user_profile &&
            user.user_profile !== this.userToRemove.user_profile,
        )
        .map((user) => user.user_profile);

      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            invited_users: usersStillInvited,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.userHasBeenRemoved = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    saveSettingsHandler(newPasscode: string | null) {
      // Disable/enable passcode restriction
      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            passcode: newPasscode,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    sendEmail(
      invitedUsers: Array<ICapitalExplorerInvitedUser["user_profile"]>,
    ) {
      this.usersToInvite = invitedUsers;

      const allInvitedUsers = [
        ...this.usersToInvite,
        ...this.users
          .filter((user: any) => !!user?.user_profile)
          .map((user: any) => user.user_profile),
      ];

      this.$store
        .dispatch(ECapitalExplorerActions.PATCH, {
          submissionId: this.id,
          payload: {
            invited_users: allInvitedUsers,
          },
        })
        .then(() => {
          if (!this.capitalExplorerErrors) {
            this.capitalExplorerHasBeenShared = true;
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle") as string,
            type: "error",
            customClass: "is-full",
          });
        });
    },
  },
});
