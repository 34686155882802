

























import Vue from "vue";

export default Vue.extend({
  name: "MilestoneLevelCell",

  props: {
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isMilestoneInProgress: {
      type: Boolean,
      default: false,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    hasEvidence: {
      type: Boolean,
      default: false,
    },
    isMilestoneCritical: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "rgb(220, 221, 226)",
    },
  },

  computed: {
    completeMilestoneType(): any {
      return {
        name: this.hasEvidence ? "evidence-provided" : "self-assessed",
        size: this.hasEvidence ? 18 : 16,
      };
    },

    cellStyles(): { [key: string]: string } {
      const color = this.color;
      const inactiveBackgroundColor = "#f4f4f5";
      const inactiveBorderColor = "#c8cbd1";
      const inactiveInProgressBackgroundColor = "#e7e8eb";

      if (this.isMilestoneComplete) {
        return {
          backgroundColor: color,
          color: "#fff",
        };
      }

      // Styles for the planned in advance and in progress cell
      if (this.isFutureMilestone && this.isMilestoneInProgress) {
        return {
          // The background-image was not tested because of this problem with the linear-gradient property: https://github.com/jsdom/jsdom/issues/2166
          backgroundImage: `linear-gradient(110deg, ${inactiveInProgressBackgroundColor} 50%, ${inactiveBackgroundColor} 50%)`,
          border: `1px solid ${inactiveBorderColor}`,
          color,
        };
      }

      // Styles for the planned in advance cell
      if (this.isFutureMilestone && this.isMilestonePlanned) {
        return {
          backgroundColor: inactiveBackgroundColor,
          border: `1px solid ${inactiveBorderColor}`,
          color,
        };
      }

      if (this.isFutureMilestone) {
        return {
          backgroundColor: inactiveBackgroundColor,
          color,
        };
      }

      if (this.isMilestoneInProgress) {
        const rgbaColor = `rgba(${this.hexToRGB(this.color)}, 0.3)`;

        return {
          backgroundImage: `linear-gradient(110deg, ${rgbaColor} 50%, ${inactiveBackgroundColor} 50%)`,
          border: `1px solid ${color}`,
          color,
        };
      }

      return {
        backgroundColor: inactiveBackgroundColor,
        color,
        border:
          !this.isFutureMilestone &&
          !this.isMilestoneInProgress &&
          !this.isMilestonePlanned &&
          !this.isMilestoneComplete
            ? `1px dashed ${color}`
            : `1px solid ${color}`,
      };
    },
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },
  },
});
