




















import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MilestoneFormFooterCompleteButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
  },

  static: {
    completeButton: {
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    completeTooltipCopy(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.tooltips.completeMilestone",
      );
    },

    completeButtonProps(): any {
      return {
        ...this.$options.static.completeButton,
        type: EPxButtonType.BLUE,
        label: this.buttonLabel,
      };
    },
  },
});
