









































import { LocaleMessages } from "vue-i18n";
import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default ElDialogMixin.extend({
  name: "ListOptionsDeleteModal",

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickDeleteListButton() {
      this.$emit("delete-list");
    },
  },
});
