




















import Vue from "vue";

import PxGrid from "@/components/px-grid/px-grid.vue";

import { ICategory } from "@/services/data/category/category.interface";
import {
  ICategoryState,
  ECategoryActions,
} from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";

export default Vue.extend({
  name: "AssessmentPanelGridInactive",

  components: {
    PxGrid,
  },

  computed: {
    /**
     * Get the categories in a ordered way.
     *
     * This will sort the categories by their order, from the
     * lowest to the higher order.
     */
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    /**
     * Return the number of levels.
     */
    numOfLevels(): number {
      return this.$store.state.levels.data.length;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    /**
     * Return if the current user has permissions to see the assessment results.
     */
    userCannotSeeResults(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.$user.isOwner()
      );
    },
  },

  created() {
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },
});
