




































































































import Vue from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";

import { viralLevelRangeProvider } from "@/modules/supporters/services/data/viral-level-range/viral-level-range.provider";

export default Vue.extend({
  name: "RangeLevelsModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      hasServerError: false,
      innerVisibility: false,
      isSubmissionDisabled: true,
      submissionCanceled: false,
      submissionSuccessful: false,

      fields: {
        viral_level_range: "",
        email: "",
      },

      rules: {
        viral_level_range: generateRequiredValidator(
          this,
          "supporters.assessment.rangeLevelsModal.labels.range",
        ),
        email: generateEmailValidator(
          this,
          "supporters.assessment.rangeLevelsModal.fields.email",
          true,
        ),
      },
    };
  },

  computed: {
    formRef(): HTMLElement {
      return this.$refs.form as HTMLElement;
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(this.formRef, this.rules);
    },

    closeModal() {
      this.$emit("update:visibility", false);
    },

    resetForm() {
      const form: any = this.$refs.form || null;

      if (form) {
        form.resetFields();
      } else if (this.$options.data !== undefined) {
        const initialFieldsData = (this.$options.data as any).call(this).fields;
        // Override directly fields data with initial values when
        // form element isn't immediatly available while the modal it's still opening
        this.fields = {
          ...this.fields,
          ...initialFieldsData,
        };
      }
    },

    cancelClickHandler() {
      this.submissionCanceled = true;
      this.closeModal();
    },

    async sendClickHandler() {
      this.isLoading = true;
      this.hasServerError = false;

      try {
        await viralLevelRangeProvider.create(this.fields);
        this.submissionSuccessful = true;
      } catch (error) {
        this.hasServerError = true;

        // TODO: Use PxModalMessage instead of Element Message
        // Shows message under Navbar, due to message global context,
        // which will be shown behind modal overlay background
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          customClass: "is-full",
        });

        return;
      } finally {
        this.isLoading = false;
      }
    },

    closedModalHandler() {
      if (this.submissionCanceled || this.submissionSuccessful) {
        this.submissionCanceled = false;
        this.submissionSuccessful = false;

        this.resetForm();
      }
    },
  },
});
