




























import Vue from "vue";
import PxCallout from "@/components/px-callout/px-callout.vue";
import { activeModules } from "@/services/utils/utils";
import { CAPITAL_EXPLORER_CALLOUT_VISIBLE } from "@/modules/capital-explorer/constants";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import { EComponentCtaActionType } from "@/modules/affiliates/components/common.interface";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { CAPITAL_EXPLORER } from "@/modules/capital-explorer/services/router/routes-names";

export default Vue.extend({
  name: "Dashboard",

  components: {
    PxCallout,
  },

  data: function () {
    return {
      showCallout: false,
      copy: this.$t("capitalExplorer.supportersCallout"),
      label: {
        icon: "stars--white",
        iconSize: 19,
        textColor: "#fff",
        backgroundColor: "#3b82f6",
        text: this.$t("common.new") as string,
      },
      ctas: [
        {
          type: EPxButtonType.LINK_WHITE,
          size: EPxButtonSize.SMALL,
          action: EComponentCtaActionType.CANCEL,
          label: this.$t(
            `capitalExplorer.supportersCallout.ctas[${0}]`,
          ) as string,
        },
        {
          type: EPxButtonType.BLUE,
          size: EPxButtonSize.SMALL,
          action: EComponentCtaActionType.CONTINUE,
          label: this.$t(
            `capitalExplorer.supportersCallout.ctas[${1}]`,
          ) as string,
        },
      ],
    };
  },

  computed: {
    capitalExplorerCalloutVisible(): boolean {
      return !!this.$store.get("meta/get", CAPITAL_EXPLORER_CALLOUT_VISIBLE);
    },

    showCapitalExplorerCallout(): boolean {
      return (
        this.capitalExplorerCalloutVisible &&
        this.$user.isSupporter() &&
        this.$features.isEnabled("capitalExplorer") &&
        activeModules().includes("capital-explorer")
      );
    },
  },

  mounted() {
    if (this.showCapitalExplorerCallout) {
      setTimeout(() => {
        this.showCallout = true;
      }, 2000);
    }
  },

  methods: {
    handleCalloutClick(actionType: EComponentCtaActionType) {
      this.showCallout = false;

      this.$store.dispatch(EMetaActions.SET, {
        key: CAPITAL_EXPLORER_CALLOUT_VISIBLE,
        value: false,
      });

      if (actionType === EComponentCtaActionType.CONTINUE) {
        return this.$router.push({ name: CAPITAL_EXPLORER });
      }
    },
  },
});
