























import Vue from "vue";

export default Vue.extend({
  name: "MatchingCardPercentagePill",

  props: {
    percentage: {
      type: Number,
      required: true,
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    formattedMatchPercentage(): string {
      const value = this.percentage < 0 ? "?" : this.percentage;
      return `${value}%`;
    },
  },
});
