






















































import Vue from "vue";

import MobileTopNavbar from "@/modules/self-assessment/components/mobile-top-navbar/mobile-top-navbar.vue";
import CategoryWizard from "@/modules/self-assessment/components/category-wizard/category-wizard.vue";
import CategoryWizardMobile from "@/modules/self-assessment/components/category-wizard-mobile/category-wizard-mobile.vue";
import Assessment from "@/modules/self-assessment/views/assessment/assessment.vue";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

import { IStepItem } from "@/modules/self-assessment/types/step-item.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  ENTREPRENEUR_USER_GROUP_ID,
  SUPPORTER_USER_GROUP_ID,
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_FROM,
} from "@/modules/common/constants";
import { EAuthCompanyActions } from "@/modules/authentication/services/store/auth/auth-types";
import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import {
  ROUTE_MILESTONE_PLANNER_OVERVIEW,
  ROUTE_MILESTONE_PLANNER_DETAIL,
} from "@/modules/milestone-planner/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { changeLocale, loadedLocales } from "@/services/i18n/locale-utils";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { navBarItemsByFeaturesToggleOrPermissions } from "@/utils/navbar-items-enabled";

export default Vue.extend({
  name: "UpdateLevel",

  components: {
    MobileTopNavbar,
    CategoryWizardMobile,
    CategoryWizard,
    Assessment,
    LangSwitcher,
  },

  data() {
    return {
      currentStep: 0,
      targetRoutesToRedirect: [
        ROUTE_MILESTONE_PLANNER_OVERVIEW,
        ROUTE_MILESTONE_PLANNER_DETAIL,
        ROUTE_COMPANY_LISTS_DIRECTORY,
        ROUTE_COMPANY_LISTS_DETAIL,
        ROUTE_MATCHING_LIST,
      ],
    };
  },

  computed: {
    selectedCategory(): number {
      return this.$store.get("viralLevel.selectedCategory");
    },

    chosenLevels(): Array<ICategoryLevelSelection> {
      return this.$store.get("viralLevel.levels");
    },

    hasError(): boolean {
      return this.$store.get("categories.error");
    },

    isLoading(): boolean {
      return (
        this.$store.get("categories.loading") &&
        this.$store.get("levels.loading")
      );
    },

    categories(): Array<ICategory> {
      return this.$store.get("categories.data");
    },

    steps(): Array<IStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      }));
    },

    checkedSteps(): Array<number> {
      return this.chosenLevels
        .filter((choice: ICategoryLevelSelection) => choice.level > 0)
        .map((choice: ICategoryLevelSelection) => choice.category);
    },

    currentLevel(): ILevel | null {
      return this.$store.get("auth/latestAssessment.data") !== null
        ? this.$store.get("auth/latestAssessment.data.level")
        : null;
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    companyType(): number | null {
      return this.company !== null ? this.company.type : null;
    },

    userGroupId(): number {
      return this.$user.isEntrepreneur()
        ? ENTREPRENEUR_USER_GROUP_ID
        : SUPPORTER_USER_GROUP_ID;
    },

    navBarItems(): Array<INavBarItem> {
      return this.companyType !== null
        ? this.$profile.getByType("navBarItems", this.companyType)
        : this.$profile.get("navBarItems");
    },

    itemsByEnabledModulesOrFeatures(): Array<INavBarItem> {
      return navBarItemsByFeaturesToggleOrPermissions(this.navBarItems);
    },

    activeNavBarItems(): Array<INavBarItem> {
      return this.$user.isLogged() ? this.itemsByEnabledModulesOrFeatures : [];
    },

    firstAssessment(): boolean {
      return this.currentLevel === null;
    },
  },

  watch: {
    /**
     * Update the wizard current step when the store updates.
     */
    selectedCategory: {
      immediate: true,
      handler(newVal: number) {
        this.currentStep = newVal;
      },
    },
  },

  async created() {
    this.fetchCategories();
    this.fetchLevels();

    // If the user access this page directly the needed profile data
    // to make the check to see if this is the first assessment of
    // the user, isn't available.
    if (!this.currentLevel) {
      await this.$store.dispatch(EAuthCompanyActions.FETCH, this.companyId);
    }

    // Load HelpScout chat widget for Entrepreneurs
    helpScoutBeaconInit({
      userType: ENTREPRENEUR_USER_TYPE,
    });
  },

  async destroyed() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();

    // We're reverting the locale back to English as we're only supposed
    // to provide translations in assessment flow pages for the time being.
    await changeLocale(this.$i18n, "en", loadedLocales, document);

    // Fetch backend translations
    this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
      skipLoading: true,
    });
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
    this.$store.dispatch(ELevelActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },

  methods: {
    fetchCategories() {
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: this.userGroupId,
      });
    },

    fetchLevels() {
      this.$store.dispatch(ELevelActions.FETCH, {
        group: this.userGroupId,
      });
    },

    /**
     * When the uses the wizard update the store with the new
     * category selected index.
     */
    stepClickHandler(newIndex: number) {
      this.$store.dispatch(EViralLevelActions.SET_SELECTED_CATEGORY, newIndex);
    },

    /**
     * Change the default behavior of the self-assessment to redirect
     * to the profile to end the update VIRAL level flow.
     */
    async onEndAction() {
      const toRoute = this.$store.get("meta/get", QUICK_LEVEL_FROM);

      const redirectToPreviousRoute =
        this.targetRoutesToRedirect.includes(toRoute);

      if (!redirectToPreviousRoute) {
        this.$router.push({ name: ROUTE_PROFILE });
      } else {
        this.$router.push({ name: toRoute });
      }

      await this.$store.dispatch(EMetaActions.SET, {
        key: QUICK_LEVEL_UPDATE_META_ON_UPDATE,
        value: true,
      });
    },
  },
});
