import Vue from "vue";
import isEqual from "lodash/isEqual";
import { allFormFieldsValid } from "@/services/errors/validator-generators";

export default Vue.extend({
  name: "CompanyEditModalMixin",

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    modalVisibility: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
    formChanged: {
      type: Boolean,
      default: false,
    },
    loadingSubmission: {
      type: Boolean,
      default: false,
    },
    serverErrors: {
      type: Boolean,
      default: false,
    },
    formSubmit: {
      type: Boolean,
      default: false,
    },
    submissionDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      originalData: {} as any,

      // TODO: add standard entrepreneur and supporter rules here
      // Placeholder to be overridden via mixin extend
      rules: {},
    };
  },

  computed: {
    formRef(): any {
      return this.$refs.form;
    },

    uploadRef(): any {
      return this.$refs.upload;
    },

    typeAvatarBtn(): string {
      return this.$screen.smDown ? "default" : "link";
    },

    classesAvatarBtn(): string {
      return this.$screen.smDown ? "" : "el-button--link-dark";
    },

    /**
     * Generate an URL for the profile photo.
     *
     * This can be the preview image of the new avatar or
     * just the current company logo.
     */
    avatarUrl(): string | null {
      if ((this as any).form.file) {
        return URL.createObjectURL((this as any).form.file);
      }

      if (
        Object.prototype.hasOwnProperty.call((this as any).form.data, "logo")
      ) {
        return (this as any).form.data.logo;
      }

      if (
        Object.prototype.hasOwnProperty.call(
          (this as any).form.data.company,
          "logo",
        )
      ) {
        return (this as any).form.data.company.logo;
      }

      return null;
    },

    /**
     * Check if the form was changed.
     */
    hasFormChanged(): boolean {
      return !isEqual((this as any).form.data, this.originalData);
    },
  },

  watch: {
    resetForm: {
      immediate: true,
      async handler(needsReset: boolean) {
        if (needsReset) {
          await (this as any).resetFormData();
          this.$emit("update:resetForm", needsReset);
        }
      },
    },

    hasFormChanged(state: boolean) {
      this.$emit("update:formChanged", state);
    },

    formSubmit(canSubmit: boolean) {
      if (canSubmit) {
        // Run form submission
        (this as any).submitForm();

        // Then reset form submit property to its initial state
        this.$emit("update:formSubmit", false);
      }
    },
  },

  methods: {
    /**
     * Open the file chooser.
     */
    onClickAvatar() {
      this.uploadRef.$refs["upload-inner"].handleClick();
    },

    /**
     * Set the current selected photo.
     */
    onFileChange(event: any) {
      (this as any).form.file = event.raw as File;
    },

    /**
     * Helper method to focus a field upon opening the modal
     */
    focusInputField(field: string) {
      (this.$refs[field] as HTMLInputElement).focus();
    },

    updateSubmitState() {
      this.$emit(
        "update:submissionDisabled",
        !allFormFieldsValid(this.formRef, this.rules),
      );
    },
  },
});
