












































import Vue from "vue";
import FeaturesShowcaseCTA from "@/modules/supporters/components/features-showcase-cta/features-showcase-cta.vue";

export default Vue.extend({
  name: "FeaturesShowcase",

  components: {
    FeaturesShowcaseCTA,
  },

  computed: {
    featuresList(): any {
      return this.$t("supporters.results.featuresShowcase.list");
    },
  },
});
