


















































import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "MilestoneFormFooterPlan",

  props: {
    hasPlan: {
      type: Boolean,
      default: false,
    },

    isPlanPublished: {
      type: Boolean,
      default: false,
    },

    isFutureMilestone: {
      type: Boolean,
      default: false,
    },

    formHasChanges: {
      type: Boolean,
      default: false,
    },

    formIsInvalid: {
      type: Boolean,
      default: false,
    },

    formIsComplete: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    discardButton: {
      type: EPxButtonType.LINK,
    },
    deleteButton: {
      icon: "trash--red",
      type: EPxButtonType.LINK_DANGER,
    },
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
    submitPlanButton: {
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label: !this.isPlanPublished
          ? this.$t("milestonePlanner.milestonePlan.buttons.deleteDraft")
          : this.$t("milestonePlanner.milestonePlan.buttons.deletePlan"),
      };
    },

    discardButtonProps(): any {
      return {
        ...this.$options.static.discardButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.discardChanges"),
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    submitPlanButtonProps(): any {
      return {
        ...this.$options.static.submitPlanButton,
        type: EPxButtonType.GREEN,
        label: this.isPlanPublished
          ? this.$t("milestonePlanner.milestonePlan.buttons.savePlan")
          : this.$t("milestonePlanner.milestonePlan.buttons.submitPlan"),
      };
    },

    saveAsDraftDisabled(): any {
      return (
        (!this.formHasChanges && !this.isPlanPublished) ||
        (this.formHasChanges && this.formIsInvalid)
      );
    },

    submitPlanDisabled(): any {
      return (
        !this.formIsComplete ||
        (this.formIsComplete && this.isPlanPublished && !this.formHasChanges)
      );
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("auth/milestones.loading");
    },
  },
});
