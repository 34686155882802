
































import Vue from "vue";

export default Vue.extend({
  name: "MobileFooterNav",

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
