

















































































































































































































































































































































































import { TranslateResult } from "vue-i18n";

import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import { ISliderCardStop } from "@/components/px-slider-card/px-slider-card.vue";
import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { IModalListGroup } from "@/components/px-list-modal/px-list-modal.vue";
import {
  IMultiSelection,
  IMultiSelectorItem,
} from "@/components/multi-selector/multi-selector.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { EAdditionalCriteriaGetter } from "@/modules/supporters/services/store/additional-criteria/additional-criteria.interface";
import { MatchingCriteriaWeightState } from "@/modules/matching/services/store/matching-criteria-weight/matching-criteria-weight.module";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import uniq from "lodash/uniq";
import { ISupporterFlowImportances } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { IMatchingQuestionaryCriteria } from "@/services/data/matching-questionary-criteria/matching-questionary-criteria.interface";

interface ICardListItemResponse {
  response: IMatchingResponse;

  // Registered to know, in case of sorting, the original index order
  order: number;

  items: Array<ICardListItem>;
}

interface ICardListItem {
  name: string;
  subitems?: Array<string>;
}

const VISIBLE_CARD_ITEMS_TEXT_TRESHOLD = 48;

export default SupportersFlowMixin.extend({
  name: "CriteriaImportance",

  components: {
    FormPresentationLayout,
    NavigationFooter,
  },

  data() {
    return {
      fields: {
        importances: {
          level_weight: null,
          sectors_weight: null,
          locations_weight: null,
          questions: [],
        } as ISupporterFlowImportances,
      },

      listModal: {
        title: "",
        items: [] as Array<IModalListGroup | string>,
        visibility: false,
      },
    };
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.criteriaImportance.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.criteriaImportance");
    },

    innerNavigation() {
      return this.$route?.meta?.innerNavigation || null;
    },

    criteriaImportances(): Array<ISliderCardStop> {
      return this.criteriaWeights.map((weight) => ({
        label: weight.name,
        value: weight.id,
      }));
    },

    supporterInvestingLevelRange(): Array<number> {
      return this.flowData.supporter.investing_level_range || [1, 1];
    },

    investingLevelRangeInterval(): string {
      if (this.supporterInvestingLevelRange.length > 1) {
        return this.supporterInvestingLevelRange[0] ===
          this.supporterInvestingLevelRange[1]
          ? this.supporterInvestingLevelRange[0].toString()
          : this.supporterInvestingLevelRange.join("-");
      }

      return this.supporterInvestingLevelRange[0].toString();
    },

    investingLevelRangeTitle(): string {
      const uniqRange = uniq(this.supporterInvestingLevelRange);
      const rangeSize = uniqRange[1] ? 1 : 0;
      return this.$t(
        `profile.investors.panel.label[${rangeSize}]`,
        uniqRange,
      ) as string;
    },

    supporterSectorsOfInterest(): Array<ICardListItem> {
      return this.mapMultiSelectorItems(this.flowData.meta?.sectors || []);
    },

    supporterLocationsOfInterest(): Array<ICardListItem> {
      return this.mapMultiSelectorItems(this.flowData.meta?.locations || []);
    },

    criteriaWeights(): Array<IMatchingCriteriaWeight> {
      return this.$store.getters[MatchingCriteriaWeightState.Getter.VALUES];
    },

    additionalCriteriaValues() {
      return this.$store.getters[
        EAdditionalCriteriaGetter.MULTI_SELECTOR_VALUES
      ];
    },

    additionalCriteriaFlattenValues(): Array<IMultiSelectorItem> {
      return this.additionalCriteriaValues.reduce(
        (accumulator: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => [
          ...accumulator,
          ...(item?.children || []),
        ],
        [],
      );
    },

    eligibleCriteria(): IMatchingResponse[] {
      // Criteria that impacts matching results:
      return this.flowData.criteria.filter(
        (criteria) =>
          !!criteria?.answers ||
          (!!criteria?.value &&
            ("value" in criteria.value ||
              ("min" in criteria.value && "max" in criteria.value))),
      );
    },

    eligibleAdditionalCriteria(): IMatchingResponse[] {
      return (this.flowData.additional_criteria || []).filter(
        (criteria) =>
          !!criteria?.answers ||
          (!!criteria?.value &&
            ("value" in criteria.value ||
              ("min" in criteria.value && "max" in criteria.value))),
      );
    },

    supporterAdditionalCriteria(): Array<ICardListItemResponse> {
      return this.eligibleAdditionalCriteria.map(
        (criteria: IMatchingResponse, index: number) => {
          // Fetch selector item that corresponds to selected question in value
          const valueFound = this.additionalCriteriaFlattenValues.find(
            (item: IMultiSelectorItem) => criteria.question === item.value,
          ) as IMultiSelectorItem;

          // Matching question is available on meta data for additional criteria
          const response = {
            ...criteria,
            question: valueFound.meta,
            shortName: valueFound.label,
            category: valueFound.parent?.label || "",
          } as IMatchingResponse;

          return {
            response,
            order: index,
            items: this.getResponseValues(response),
          };
        },
      );
    },

    questions(): Array<IMatchingQuestion> {
      return this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE];
    },

    criteriaResponses(): Array<ICardListItemResponse> {
      // Map questions to responses and filter undefined responses
      return (
        this.eligibleCriteria
          .map((criteria: IMatchingResponse, index: number) => {
            const question =
              this.questions.find(
                (question: IMatchingQuestion) =>
                  question.id === criteria.question,
              ) || ({} as IMatchingQuestion);

            const response = {
              ...criteria,
              question,
            } as IMatchingResponse;

            return {
              response,
              order: index,
              items: this.getResponseValues(response),
            };
          })
          .filter(
            (item: ICardListItemResponse) =>
              !!(item.response.question as IMatchingQuestion)?.id,
          )
          // Move free-response questions to bottom since they, as of now, aren't relevant for matching:
          // https://pixelmatters.atlassian.net/browse/VIR-950
          .sort(
            (itemA: ICardListItemResponse, itemB: ICardListItemResponse) => {
              const typeA = Number(
                (itemA.response.question as IMatchingQuestion)?.question_type
                  .type === FREE_RESPONSE,
              );
              const typeB = Number(
                (itemB.response.question as IMatchingQuestion)?.question_type
                  .type === FREE_RESPONSE,
              );
              return typeA > typeB ? 1 : typeB > typeA ? -1 : 0;
            },
          )
      );
    },
  },

  created() {
    this.setCriteriaImportances();
  },

  methods: {
    setCriteriaImportances() {
      // Level Range, Locations, and Sectors
      this.fields.importances.level_weight =
        this.flowData.importances?.level_weight || null;
      this.fields.importances.locations_weight =
        this.flowData.importances?.locations_weight || null;
      this.fields.importances.sectors_weight =
        this.flowData.importances?.sectors_weight || null;

      // Criteria and additional criteria
      this.fields.importances.questions = [
        ...this.criteriaResponses.map((item: ICardListItemResponse) => ({
          question: (item.response.question as IMatchingQuestion).id,
          criteria_weight:
            this.flowData.importances?.questions.find(
              (criteria: IMatchingQuestionaryCriteria) =>
                criteria.question ===
                (item.response.question as IMatchingQuestion).id,
            )?.criteria_weight || null,
        })),

        ...this.supporterAdditionalCriteria.map(
          (item: ICardListItemResponse) => ({
            question: (item.response.question as IMatchingQuestion).id,
            criteria_weight:
              this.flowData.importances?.questions.find(
                (criteria: IMatchingQuestionaryCriteria) =>
                  criteria.question ===
                  (item.response.question as IMatchingQuestion).id,
              )?.criteria_weight || null,
          }),
        ),
      ];
    },

    onClickGoTo(routeName: string, step = "0") {
      this.$router.push({ name: routeName, params: { step } });
    },

    onClickMoreHandler(modalTitle: string, modalItems: Array<ICardListItem>) {
      this.listModal.title = modalTitle;
      this.listModal.items = modalItems.map((list) => {
        return !list.subitems
          ? list.name
          : {
              name: list.name,
              items: list.subitems,
            };
      });
      this.listModal.visibility = true;
    },

    getTotalItems(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        // Count own item
        count++;

        // Count group children if they exist
        if ("subitems" in item && !!item.subitems && item.subitems.length) {
          count += item.subitems.length;
        }

        return count;
      }, 0);
    },

    getTotalOfSubItems(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        // Count group children if they exist
        if ("subitems" in item && !!item.subitems && item.subitems.length) {
          count += item.subitems.length;
        }

        return count;
      }, 0);
    },

    getTotalItemsTextLength(items: Array<ICardListItem>) {
      return items.reduce((count, item) => {
        count += item.name.length;
        return count;
      }, 0);
    },

    hasMoreItems(items: Array<ICardListItem>) {
      return (
        this.getTotalOfSubItems(items) ||
        this.getTotalItemsTextLength(items) > VISIBLE_CARD_ITEMS_TEXT_TRESHOLD
      );
    },

    hasMoreItemsOrNone(items: Array<ICardListItem>) {
      return !items.length || this.hasMoreItems(items);
    },

    getResponseValues(response: IMatchingResponse): Array<ICardListItem> {
      const question = response.question as IMatchingQuestion;
      const numericCurrency = question.question_type.meta?.currency ? "$" : "";

      switch (question.question_type.type) {
        case RANGE: {
          const rangeValue = (response.value?.value as number).toLocaleString();
          return [{ name: `${numericCurrency}${rangeValue}` }];
        }

        case NUMERIC: {
          const numericMin = (response.value?.min as number).toLocaleString();
          const numericMax = (response.value?.max as number).toLocaleString();
          return [
            {
              name: `${numericCurrency}${numericMin} - ${numericCurrency}${numericMax}`,
            },
          ];
        }
        case FREE_RESPONSE: {
          return [{ name: `${response.value?.text || ""}` }];
        }

        case SINGLE_SELECT:
        case MULTI_SELECT: {
          // Map answer value
          return response.answers.map((answer: number) => {
            const answerText =
              question.answers.find(
                (innerAnswer: IMatchingAnswer) => innerAnswer.id === answer,
              )?.value || "";

            return { name: answerText };
          });
        }

        case DATE: {
          return [{ name: `${response.value?.date || ""}` }];
        }

        default: {
          return [{ name: `${response.value?.value || ""}` }];
        }
      }
    },

    mapMultiSelectorItems(
      selections: Array<IMultiSelection>,
    ): Array<ICardListItem> {
      return selections.map((selection: IMultiSelection) => {
        if (selection.selected_parent) {
          const parent = selection.selected_parent;

          // No children found, at least return parent name
          if (!parent.children || parent.children.length === 0) {
            return { name: parent.label };
          }

          const selectedChildren = (parent.children || []).filter(
            (child: IMultiSelectorItem) => child.selected,
          );

          if (selectedChildren.length > 1) {
            return {
              name: parent.label,
              subitems:
                selectedChildren.map(
                  (child: IMultiSelectorItem) => child.label,
                ) || [],
            };
          }
        }

        // Just one selected child found, return it
        return {
          name: selection.selected_children[0].label,
        };
      });
    },
  },
});
