



































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import ListShareClipboardButton from "@/modules/company-lists/components/list-share/list-share-clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

export default Vue.extend({
  name: "SharePlannerSettingsLink",

  components: {
    ListShareClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list.copyLink") as LocaleMessages;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t(
        "companyLists.list.shareModal.settings.link",
      ) as LocaleMessages;
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$root.$emit("share-planner-modal-confirmation", {
        event: SHARE_PLANNER_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
