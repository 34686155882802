















import Vue from "vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";

export default Vue.extend({
  name: "CategoryColorBar",

  props: {
    /**
     * Allows to mark a step as active.
     */
    activeStep: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    steps(): Array<ICategoryStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      }));
    },

    /**
     * Available levels.
     */
    assessmentLevels(): Array<ICategoryLevelSelection> {
      return (
        this.$store.state.affiliates.draftSubmission?.data.assessment || []
      );
    },

    /**
     * Retrieves an array with all checked categories.
     */
    checkedSteps(): Array<number> {
      return this.assessmentLevels
        .filter((record: ICategoryLevelSelection) => record.level > 0)
        .map((record: ICategoryLevelSelection) => record.category);
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: ICategoryStepItem): boolean {
      return this.checkedSteps.includes(step.value);
    },
  },
});
