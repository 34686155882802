






















import Vue from "vue";
import { LocaleMessage } from "vue-i18n";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default Vue.extend({
  name: "MatchingCardCompactListHeader",

  props: {
    userType: {
      type: Number,
      required: true,
    },

    isSameTypeOfUsers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    componentCopy(): LocaleMessage {
      return this.$t("common.components.matchingListHeader");
    },

    isEntrepreneur(): boolean {
      return this.userType === ENTREPRENEUR_USER_TYPE;
    },

    userTypeCopy(): string {
      return (
        this.isEntrepreneur
          ? this.$t("common.components.matchingListHeader.entrepreneur")
          : this.$t("common.components.matchingListHeader.supporter")
      ) as string;
    },

    sectorsCopy(): string {
      return (
        this.isEntrepreneur
          ? this.$t("common.components.matchingListHeader.sectors")
          : this.$t("common.components.matchingListHeader.sectorsInterest")
      ) as string;
    },

    // Due to size constraints, screens lower than 1440px must hide description
    showDescription(): boolean {
      return this.$screen.xlUp;
    },
  },
});
