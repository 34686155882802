




















import Vue from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

/**
 * Selector used to iterate over the cell state on the milestone grid represented in the onboarding modal
 */
export default Vue.extend({
  name: "MilestoneStageStatusSelector",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    selectedStatus: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      stageStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.completed",
          ),
          icon: EMilestoneStatusType.COMPLETED,
        },
      ],
      activeState: this.selectedStatus,
    };
  },

  watch: {
    selectedStatus(newValue) {
      this.activeState = newValue;
    },
  },

  methods: {
    /**
     * Handle stage status click
     */
    clickStageStatusHandler(status: string, index: number) {
      this.activeState = index;

      // We need to add 3 to the index because of the previous pages in the onboarding dialog
      this.$emit("selected-status", index + 3, status);
    },
  },
});
