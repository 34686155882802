










































































import ElDialogMixin from "@/mixins/el-dialog.mixin";

import { LocaleMessages } from "vue-i18n";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

import SharePlannerUsers from "@/modules/milestone-planner/components/share-planner/share-planner-users.vue";
import SharePlannerSettings from "@/modules/milestone-planner/components/share-planner/share-planner-settings.vue";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "@/modules/milestone-planner/services/router/routes-names";
import { EAuthMilestonePlannersActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestone-planners/auth-milestone-planners.types";
import { AxiosError } from "axios";
import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

export default ElDialogMixin.extend({
  name: "SharePlannerModal",

  components: {
    SharePlannerUsers,
    SharePlannerSettings,
  },

  data() {
    return {
      settingsVisibility: false,
      hasServerError: false,
      linkWasReset: false,
      passcodeWasChanged: false,
      userToRemove: null as null | IMilestonePlannerInvitedUser,
      userHasBeenRemoved: false,
    };
  },

  props: {
    /**
     * Represents the planner unique identifiable
     * slug used for the shareable link.
     */
    uid: {
      type: String,
      required: true,
    },

    /**
     * Optional passcode where empty means
     * having no protection.
     */
    passcode: {
      type: String,
      default: "",
      required: true,
    },

    users: {
      type: Array as () => IMilestonePlannerInvitedUser[],
      required: true,
    },
  },

  watch: {
    innerVisibility(isVisible) {
      // Workaround since mounted and destroyed
      // hooks are not called when modals get toggled.
      if (isVisible) {
        this.listenForGlobalSettingsEvent();
        this.listenForGlobalConfirmationEvents();
      } else {
        this.disableGlobalSettingsEvent();
        this.disableGlobalConfirmationEvents();
      }
    },
  },
  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    shareModalUsersCopy(): LocaleMessages {
      return this.$t("milestonePlanner.shareModal.users") as LocaleMessages;
    },

    shareableLink(): string {
      const linkPath = this.$router.resolve({
        name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        params: { uid: this.uid },
      }).href;

      return location.origin + linkPath;
    },

    confirmationDialogs(): any {
      return [
        {
          event: SHARE_PLANNER_EVENTS.RESET_SHAREABLE_LINK,
          key: "resetLink",
          callback: this.onConfirmResetLink,
        },
        {
          event: SHARE_PLANNER_EVENTS.RESET_PASSCODE,
          key: "resetPasscode",
          callback: this.onConfirmResetPasscode,
        },
        {
          event: SHARE_PLANNER_EVENTS.UNSHARE_FROM_USER,
          key: "unshareFromUser",
          callback: this.onConfirmUnshare,
        },
      ];
    },

    dialogConfirmationOptions(): any {
      return this.confirmationDialogs.map((confirmation: any) => ({
        eventName: confirmation.event,
        message: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.title`,
          {
            username: this.userToRemove?.name,
          },
        ),
        tip: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.subtitle`,
          {
            username: this.userToRemove?.name,
          },
        ),

        confirmButtonText: this.$t(
          `milestonePlanner.shareModal.confirmationDialogs.${confirmation.key}.cta`,
        ),
        cancelButtonText: this.$t("common.cancel"),
        confirmFn: confirmation.callback,
      }));
    },

    milestonePlannersErrors(): AxiosError {
      return this.$store.get("auth/milestonePlanners.error");
    },

    successUserRemovalMessage(): string {
      return this.$t(`milestonePlanner.shareModal.accessRemoved`, {
        user: this.userToRemove?.name,
      }) as string;
    },
  },

  methods: {
    beforeCloseHandler() {
      this.$emit("update:visibility", false);
      this.settingsVisibility = false;
    },

    listenForGlobalSettingsEvent() {
      this.$root.$on("planner-settings", (visibility: boolean) => {
        this.settingsVisibility = visibility;
      });
    },

    listenForGlobalConfirmationEvents() {
      // Workaround for the dialog confirmation
      // directive to work with global events:
      this.$root.$on(
        "share-planner-modal-confirmation",
        ({
          event,
          payload,
        }: {
          event: string;
          payload: { [key: string]: IMilestonePlannerInvitedUser };
        }) => {
          this.userToRemove = payload && payload.user;

          (this as any).$refs.modal.$emit(event);
        },
      );
    },

    disableGlobalSettingsEvent() {
      this.$root.$off("planner-settings");
    },

    disableGlobalConfirmationEvents() {
      this.$root.$off("share-planner-modal-confirmation");
    },

    onConfirmResetLink() {
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          queryParams: {
            reset: "link",
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.linkWasReset = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmResetPasscode() {
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          queryParams: {
            reset: "passcode",
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    onConfirmUnshare() {
      const usersStillInvited = this.users
        .filter(
          (user) =>
            !!user?.user_profile &&
            user.user_profile !== this.userToRemove?.user_profile,
        )
        .map((user) => user.user_profile);

      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          payload: {
            invited_users: usersStillInvited,
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.userHasBeenRemoved = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },

    saveSettingsHandler(newPasscode: string | null) {
      // Disable/enable passcode restriction
      this.$store
        .dispatch(EAuthMilestonePlannersActions.PATCH, {
          milestonePlannerUid: this.uid,
          payload: {
            passcode: newPasscode,
          },
        })
        .then(() => {
          if (!this.milestonePlannersErrors) {
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
  },
});
