
























import Vue from "vue";

import RegistrationModals from "@/components/registration-modals/registration-modals.vue";
import PasscodeAccessModal from "@/components/passcode-access-modal/passcode-access-modal.vue";

import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";
import {
  EUserGuestGetters,
  EUserGuestActions,
} from "@/services/store/user-guest/user-guest.types";
import { AxiosError } from "axios";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { HOME } from "@/services/router/router-names";

export default Vue.extend({
  name: "VisitorsAccessModal",

  components: {
    RegistrationModals,
    PasscodeAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      passcode: "",
      invalidPasscode: false,
    };
  },

  computed: {
    capitalExplorerErrors(): AxiosError | null {
      return this.$store.get("capitalExplorer.error");
    },

    capitalExplorerUid(): string {
      return this.$route.params.uid;
    },

    userGuest(): IUserGuest | null {
      return this.$store.get(EUserGuestGetters.GET);
    },

    isIdentificationRequired(): boolean {
      return !this.isLogged && !this.userGuest;
    },

    isLogged(): boolean {
      return !!this.$store.get("auth/user");
    },

    isLoading(): boolean {
      return this.$store.get("capitalExplorer.loading");
    },

    permissionDenied(): boolean {
      return this.capitalExplorerErrors?.response?.status === 403;
    },
  },

  mounted() {
    this.checkPermissionDeniedError();
  },

  methods: {
    createGuestUser(guestFormData: IUserGuest): Promise<IUserGuest> {
      const newGuestUser: IUserGuest = {
        name: guestFormData.name,
        email: guestFormData.email,
      };

      return this.$store.dispatch(EUserGuestActions.CREATE, newGuestUser);
    },

    async makeRequestWithPasscode(passcode: string) {
      this.passcode = passcode;

      let capitalExplorerRequestParams = {};

      // If user guest exists in store, we make the request with passcode and guest email
      if (this.userGuest) {
        capitalExplorerRequestParams = {
          email: this.userGuest.email,
        };
      }

      // Request capital explorer data
      await this.requestCapitalExplorerData(capitalExplorerRequestParams);

      this.checkForAnyPasscodeErrors();
      this.checkPermissionDeniedError();
    },

    async enterAsGuestUser(guestFormData: IUserGuest) {
      // Create guest user
      const userGuest = await this.createGuestUser(guestFormData);
      const capitalExplorerRequestParams: object = {
        email: userGuest.email,
      };

      // Request capital explorer data
      await this.requestCapitalExplorerData(capitalExplorerRequestParams);
    },

    async enterAsLoggedUser() {
      await this.requestCapitalExplorerData();

      this.checkPermissionDeniedError();

      // An entrepreneur should only be able to access his own Capital Explorer.
      // Therefore we need to reload the page so that we enter in the Capital explorer guard and validate the type of user
      if (this.$user.isEntrepreneur()) {
        location.reload();
      }
    },

    async requestCapitalExplorerData(requestParams?: object) {
      let params: object = {
        ...requestParams,
        uid: this.capitalExplorerUid,
      };

      if (this.passcode) {
        params = {
          ...params,
          passcode: this.passcode,
        };
      }

      await this.$store.dispatch(
        ECapitalExplorerActions.FETCH_USER_SUBMISSION,
        params,
      );
    },

    checkForAnyPasscodeErrors() {
      const response = this.capitalExplorerErrors?.response;

      // When the passcode is wrong.
      this.invalidPasscode =
        response?.status === 403 &&
        (response?.data.errors.code === "invalid_passcode" ||
          response?.data.errors.code === "passcode_updated");
    },

    checkPermissionDeniedError() {
      if (
        this.capitalExplorerErrors &&
        this.permissionDenied &&
        this.isLogged
      ) {
        this.$message({
          message: this.$root.$t("capitalExplorer.errors.notInvited") as string,
          type: "error",
          customClass: "is-full",
        });

        this.$router.push({ name: HOME });
      }
    },
  },
});
