

















import Vue from "vue";
import PxMessageBar from "@/components/px-message-bar/px-message-bar.vue";

import { TranslateResult } from "vue-i18n";
import { ROUTE_USER_SUBMISSIONS } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "SubmissionsNotificationMessage",

  components: {
    PxMessageBar,
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },

    draftSubmissions: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showMessage: false,
    };
  },

  computed: {
    pendingDraftSubmissionsCopy(): TranslateResult {
      const submissionsCopyIndex = this.draftSubmissions > 1 ? 1 : 0;

      return this.$t(
        `profile.submissionsNotification.text[${submissionsCopyIndex}]`,
        {
          number: this.draftSubmissions,
        },
      );
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(visible) {
        if (this.showMessage === visible) {
          return;
        }

        this.showMessage = visible;
      },
    },

    showMessage: {
      handler() {
        this.$emit("update:visibility", false);
      },
    },
  },

  methods: {
    goToSubmissionsPage() {
      return this.$router.push({ name: ROUTE_USER_SUBMISSIONS });
    },
  },
});
