




























































import Vue from "vue";

import DemographicCharts from "@/modules/charts-dashboard/components/demographic-charts/demographic-charts.vue";
import RequestProcessesModal from "@/modules/charts-dashboard/components/common/request-processes-modal/request-processes-modal.vue";

import { IReportsViewThemes } from "./charts-dashboard.view.vue";
import {
  IProcessData,
  IProcessReportsDemographicData,
} from "../services/data/process-reports-data/process-reports-data.interface";
import { PROCESS_REPORTS_MOCK_DATA } from "./supporter-free-chart-mock-data";
import { processReportsDataProvider } from "../services/data/process-reports-data/process-reports-data.provider";
import { EDashboardPages } from "./charts-dashboard.interface";

export default Vue.extend({
  name: "ProcessReportsView",

  components: {
    DemographicCharts,
    RequestProcessesModal,
  },

  props: {
    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  data() {
    return {
      data: [] as IProcessReportsDemographicData[],
      loading: false,
      selectedProcessUid: "" as string | null,
      userProcesses: [] as IProcessData[],
      view: EDashboardPages.PROCESS_REPORTS,
      showEmptyState: false,
    };
  },

  computed: {
    questionSlug(): string {
      return this.theme?.questionSlug || "";
    },

    isFreeUser(): boolean {
      return !this.$user.isProUser();
    },

    tooltipText(): string {
      return this.$t("chartsDashboard.processTooltip") as string;
    },

    showProcessTooltip(): boolean {
      return this.userProcesses?.length === 1;
    },

    selectDisabled(): boolean {
      return this.isFreeUser || this.userProcesses.length <= 1 || this.loading;
    },
  },

  watch: {
    selectedProcessUid: {
      async handler() {
        await this.fetchDemographicData();
      },
    },

    theme: {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        await this.fetchDemographicData();
      },
    },

    loading(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.$emit("loading", newValue);
    },
  },

  async created() {
    // If it's free user we show mock data
    if (this.isFreeUser) {
      this.data = PROCESS_REPORTS_MOCK_DATA;
      return;
    }

    this.loading = true;

    await this.setupViewInitialData();
  },

  destroyed() {
    this.$emit("show-mock-data", false);
  },

  methods: {
    async setupViewInitialData() {
      await this.fetchProcesses();

      if (!this.userProcesses.length) {
        this.data = PROCESS_REPORTS_MOCK_DATA;
        this.loading = false;
        this.showEmptyState = true;
        this.$emit("show-mock-data", true);
        return;
      }

      this.$emit("show-mock-data", false);

      this.selectedProcessUid = this.userProcesses[0].uid;
    },

    async fetchDemographicData() {
      if (!this.selectedProcessUid) return;

      this.loading = true;

      this.data = await processReportsDataProvider.getDemographicStats(
        this.selectedProcessUid,
        this.questionSlug,
      );

      this.loading = false;
    },

    async fetchProcesses() {
      this.userProcesses = await processReportsDataProvider.fetch();
    },
  },
});
