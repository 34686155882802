









































import Vue from "vue";

import SubmissionTabInvestRange from "@/modules/supporters/components/submission/submission-tab-invest-range.vue";
import SubmissionTabQuestions from "@/modules/supporters/components/submission/submission-tab-questions.vue";
import SubmissionTabTeamMembers from "@/modules/supporters/components/submission/submission-tab-team-members.vue";
import SubmissionTabInterests from "@/modules/supporters/components/submission/submission-tab-interests.vue";

import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";

enum ESubmissionPanelTab {
  VENTURE_INVESTMENT_LEVEL = 1,
  QUESTIONS = 2,
  TEAM_MEMBERS = 3,
  INTERESTS = 4,
}

interface ISubmissionPanelTab {
  key: number;
  label: string;
  visible: boolean;
}

export default Vue.extend({
  components: {
    SubmissionTabInvestRange,
    SubmissionTabQuestions,
    SubmissionTabInterests,
    SubmissionTabTeamMembers,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSupporterSubmission,
      required: true,
    },
  },

  data() {
    return {
      selectedTab: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
      ESubmissionPanelTab,
    };
  },

  computed: {
    panelTabs(): Array<ISubmissionPanelTab> {
      return [
        {
          label: this.ventureInvestmentLevelLabel,
          key: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
          visible: true,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.questions",
          ) as string,
          key: ESubmissionPanelTab.QUESTIONS,
          visible: true,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.teamMembers",
          ) as string,
          key: ESubmissionPanelTab.TEAM_MEMBERS,
          visible:
            !!this.submission.team_members &&
            !!this.submission.team_members.length,
        },
        {
          label: this.$t(
            "supporters.results.submissionPanel.tabs.interests",
          ) as string,
          key: ESubmissionPanelTab.INTERESTS,
          visible: true,
        },
      ];
    },

    visiblePanelTabs(): Array<ISubmissionPanelTab> {
      return this.panelTabs.filter((tab: ISubmissionPanelTab) => tab.visible);
    },

    ventureInvestmentLevelLabel(): string {
      return (
        this.$screen.mdUp
          ? this.$t(
              "supporters.results.submissionPanel.tabs.ventureInvestLevel",
            )
          : this.$t(
              "supporters.results.submissionPanel.tabs.ventureInvestLevelMobile",
            )
      ) as string;
    },
  },

  methods: {
    isActiveTab(tab: ESubmissionPanelTab) {
      return this.selectedTab === tab;
    },

    changeTab(selectedTab: ESubmissionPanelTab) {
      this.selectedTab = selectedTab;
    },
  },
});
