import { Route, RouteConfig } from "vue-router";

import AffiliatesBaseView from "@/modules/affiliates/views/affiliates-base.view.vue";

import { AFFILIATES_BASE } from "@/modules/affiliates/services/router/routes-names";

import {
  changeLocale,
  DEFAULT_LOCALE,
  loadedLocales,
} from "@/services/i18n/locale-utils";

import { i18n } from "@/services/i18n/i18n";
import store from "@/services/store";

import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { AFFILIATE_TARGET_ENTREPRENEUR } from "@/services/data/affiliate/affiliate.interface";
import { SUPPORTERS_STARTING_POINT_PROGRAM } from "@/modules/supporters/services/router/routes-names";
import { ERROR_ROUTE } from "@/services/router/router-names";

export const routes: RouteConfig[] = [
  {
    path: "/entrepreneurs/program",
    name: AFFILIATES_BASE,
    component: AffiliatesBaseView,
    // To be able to persist the selected language when refreshing the page
    // we setted a local storage variable with the locale selected by the user.
    // When entering in the route we need to validate if the variable is available or not and change the locale accordingly
    // This is only needed because the application is not prepared to display all the pages translated in spanish
    // This code should be removed when the whole application is prepared to be in spanish
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      const affiliate = await store.dispatch(
        EAffiliatesActions.FETCH_AFFILIATE,
        to.query.a || to.query.affiliate,
      );

      if (affiliate) {
        if (affiliate.flow_target === AFFILIATE_TARGET_ENTREPRENEUR) {
          const locale = localStorage.getItem("userLocale") || DEFAULT_LOCALE;
          await changeLocale(i18n, locale, loadedLocales, document);

          return next();
        }

        return next({
          name: SUPPORTERS_STARTING_POINT_PROGRAM,
          query: to.query,
        });
      } else {
        return next({
          name: ERROR_ROUTE,
        });
      }
    },
  },
];
