




























































import MatchingCard from "@/components/matching-card/matching-card.vue";
import MatchingEmptyResults from "@/modules/matching/components/matching-empty-results/matching-empty-results.vue";
import MatchingFilters from "@/modules/matching/components/matching-filters/matching-filters.vue";
import MatchingListMixin from "@/mixins/matching-list.mixin";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import VirtualGrid, {
  IStatusChanger,
} from "@/modules/matching/components/virtual-grid/virtual-grid.vue";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import MatchingCardPlaceholder from "@/components/matching-card/matching-card-placeholder.vue";

export default MatchingListMixin.extend({
  name: "MatchingListInterestsUser",

  components: {
    MatchingCard,
    MatchingEmptyResults,
    MatchingFilters,
    VirtualGrid,
    MatchingCardPlaceholder,
  },

  static: {
    virtualRowHeight: 276,
    virtualGridGap: 40,
  },

  computed: {
    matchScores(): IMatchingScore[] {
      return this.$store.state.matchingInterest.userMatchScores;
    },

    nextPage(): number | null {
      return this.$store.state.matchingInterest.userMatchScoresPage;
    },

    matchCount(): number {
      return this.$store.state.matchingInterest.userMatchScoresCount;
    },

    hasMatchScores(): boolean {
      return !!this.matchScores.length;
    },

    matchCountTitle(): string {
      let title = "";
      const hasOnlyOneMatch = this.matchCount === 1;

      if (hasOnlyOneMatch) {
        title = `1 ${this.$t(`common.userTypes[${this.targetUserType}]`)}`;
      } else {
        title = `${this.matchCount} ${this.$t(
          `common.userTypesPlural[${this.targetUserType}]`,
        )}`;
      }

      return title;
    },

    virtualGridColumns(): number {
      if (this.$screen.xlUp) {
        return 4;
      }

      if (this.$screen.lgUp) {
        return 3;
      }

      if (this.$screen.mdUp) {
        return 2;
      }

      return 1;
    },
  },

  methods: {
    async fetchMoreInterests($status: IStatusChanger) {
      if (!this.nextPage) {
        $status.complete();
        return;
      }

      await this.$store.dispatch(EMatchingInterestActions.FETCH_USER_SCORES, {
        page: this.nextPage,
        ...this.globalFilters,
      });

      !this.nextPage ? $status.complete() : $status.ready();
    },
  },
});
