





































































import Vue from "vue";
import moment from "moment";

import SubmissionsInfoBadges from "@/modules/submissions/components/submissions-info-badges/submissions-info-badges.vue";
import MatchingCardThumbnail from "@/components/matching-card/matching-card-thumbnail.vue";

import { AFFILIATES_BASE } from "@/modules/affiliates/services/router/routes-names";
import {
  ROUTE_ENTREPRENEUR_SUBMISSION,
  ROUTE_SUPPORTER_SUBMISSION,
} from "@/modules/program/services/router/routes-names";
import {
  ROUTE_PROFILE,
  ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
} from "@/modules/profile/services/router/routes-names";
import {
  EAffiliateSubmissionType,
  IAffiliateSubmission,
  IAffiliateSubmissionsOwners,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { baseUrl } from "@/services/utils/utils";

export default Vue.extend({
  name: "SubmissionsTableRow",

  components: {
    SubmissionsInfoBadges,
    MatchingCardThumbnail,
  },

  static: {
    draftButton: {
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.BLUE,
      label: "userSubmissions.table.ctas.resume",
    },

    submittedButton: {
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DEFAULT,
      label: "userSubmissions.table.ctas.view",
    },
  },

  props: {
    submissionData: {
      type: Object as () => IAffiliateSubmission,
      required: true,
    },

    type: {
      type: String,
      default: EAffiliateSubmissionType.DRAFT,
      validator(value: EAffiliateSubmissionType) {
        return Object.values(EAffiliateSubmissionType).includes(value);
      },
    },

    isUserSubmissionsView: {
      type: Boolean,
      default: false,
    },

    isModalView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    actionButtonProps(): any {
      return this.type === EAffiliateSubmissionType.DRAFT
        ? {
            ...this.$options.static.draftButton,
            label: this.isUserSubmissionsView
              ? (this.$t(`userSubmissions.table.actions[${0}]`) as string)
              : (this.$t(
                  `ownedAffiliatesSubmissions.table.actions[${0}]`,
                ) as string),
          }
        : {
            ...this.$options.static.submittedButton,
            label: this.isUserSubmissionsView
              ? (this.$t(`userSubmissions.table.actions[${1}]`) as string)
              : (this.$t(
                  `ownedAffiliatesSubmissions.table.actions[${1}]`,
                ) as string),
          };
    },

    ownersLength(): number {
      return this.submissionData.owners ? this.submissionData.owners.length : 0;
    },

    lastUpdateDate(): string {
      return this.submissionData.last_update
        ? moment(this.submissionData.last_update).format("YYYY-MM-DD")
        : "";
    },

    isOwnerSubmissionsView(): boolean {
      return this.$route.name === ROUTE_OWNED_AFFILIATES_SUBMISSIONS;
    },
  },

  methods: {
    async onActionButtonClick() {
      // If the submission is a draft
      if (this.type === EAffiliateSubmissionType.DRAFT) {
        // If user is seeing is own submissions, we need to redirect to the affiliates page
        if (this.isUserSubmissionsView) {
          return this.$router.push({
            name: AFFILIATES_BASE,
            query: {
              a: this.submissionData.affiliate.id.toString(),
            },
          });
        } else {
          // If user is seeing other user submissions, we need to send a email to resume the draft
          this.sendEmailToResumeDraft();
        }
      }

      // If the submission is submitted, we need to redirect to the submission page
      if (this.submissionData.submission_uid) {
        // If user is seeing is own submissions, we need to redirect to the submission page
        if (this.isUserSubmissionsView) {
          return this.$router.push({
            name: this.$user.isEntrepreneur()
              ? ROUTE_ENTREPRENEUR_SUBMISSION
              : ROUTE_SUPPORTER_SUBMISSION,
            params: { uid: this.submissionData.submission_uid },
          });
        } else {
          // If user is seeing other user submissions, we need to redirect to the correct submission page
          return this.$router.push({
            name:
              this.submissionData.company_type === ENTREPRENEUR_USER_TYPE
                ? ROUTE_ENTREPRENEUR_SUBMISSION
                : ROUTE_SUPPORTER_SUBMISSION,
            params: { uid: this.submissionData.submission_uid },
          });
        }
      }
    },

    sendEmailToResumeDraft() {
      if (this.submissionData.owners?.length) {
        const company = this.submissionData.owners[0];
        const email = company.email;
        const supporter = this.$store.get("auth/supporter").data;
        const affiliate = this.submissionData.affiliate;
        const submissionLink = `${baseUrl}/?a=${affiliate.id}`;
        const subject = this.$t("submissions.email.subject") as string;
        const body = this.$t("submissions.email.body", {
          supporter: supporter.name,
          company: company.name,
          affiliate: affiliate.name,
          submissionLink,
        }) as string;

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;

        // Open the email client
        window.location.href = mailtoLink;
      }
    },

    goToCompanyProfile(member: IAffiliateSubmissionsOwners) {
      if (!member.id) return;

      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: member.id.toString(),
        },
      });
    },
  },
});
