











import PxMatchingInterestMixin from "@/mixins/px-matching-interest.mixin";

export default PxMatchingInterestMixin.extend({
  name: "PxMatchingInterestTip",

  computed: {
    matchingTip(): string {
      if (this.targetInterest)
        return this.$t(
          `matching.matchingInterest.cta[${this.currentCTAState}].tip`,
          { company: this.name },
        ) as string;

      return "";
    },
  },
});
