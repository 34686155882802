


















import Vue from "vue";
import MilestonePlannerAccessModal from "@/modules/milestone-planner/components/access-modal/access-modal.vue";

import { IAuthState } from "@/modules/authentication/services/store/auth/auth-types";
import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { navBarItemsByFeaturesToggleOrPermissions } from "@/utils/navbar-items-enabled";

/**
 * This is used to store the page element to add blur effect while this component is active.
 */
let pageElement: HTMLDivElement | null = null;
const PAGE_ELEMENT_SELECTOR = ".milestone-planner-for-visitors";
const PAGE_BLUR_CLASS = "page-full-height--blur";

export default Vue.extend({
  name: "AccessPlannerGuard",

  components: {
    MilestonePlannerAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },

    navBarItems(): Array<INavBarItem> {
      const company = this.$store.get("auth/company/data");
      const companyType = company !== null ? company.type : null;
      if (companyType !== null) {
        return this.$profile.getByType("navBarItems", companyType);
      }
      return this.$profile.get("navBarItems");
    },

    itemsByEnabledModulesOrFeatures(): Array<INavBarItem> {
      return navBarItemsByFeaturesToggleOrPermissions(this.navBarItems);
    },

    activeNavBarItems(): Array<INavBarItem> {
      return this.$user.isLogged() || this.authState.company?.data !== null
        ? this.itemsByEnabledModulesOrFeatures
        : [];
    },
  },

  mounted() {
    // get page element from dom and add a blur modifier
    pageElement = document.querySelector(PAGE_ELEMENT_SELECTOR);
    pageElement?.classList.add(PAGE_BLUR_CLASS);
  },

  beforeDestroy() {
    // remove the blur effect for the case of we move away form this page
    pageElement?.classList.remove(PAGE_BLUR_CLASS);
  },
});
