






















































































import Vue from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import {
  ICapitalTile,
  ICapitalMatchProperties,
  ICapitalMatchingDetails,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";
import DetailsModal from "@/modules/capital-explorer/components/details-modal/details-modal.vue";
import { DEFAULT_MATCH } from "@/modules/capital-explorer/capital-structures-matching.types";

export default Vue.extend({
  name: "CapitalTile",

  components: {
    PxStatusBadge,
    MatchingCardTagList,
    DetailsModal,
  },

  props: {
    capitalInfo: {
      type: Object as () => ICapitalTile,
      required: true,
    },

    matchProperties: {
      type: Object as () => ICapitalMatchProperties,
      required: true,
    },

    matchDetails: {
      type: Array as () => ICapitalMatchingDetails[],
      required: true,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      detailsModalVisible: false,
    };
  },

  computed: {
    tileStyles(): { [key: string]: string | number } {
      const matchProperties = this.showMatchingBadge
        ? this.matchProperties
        : DEFAULT_MATCH;
      return {
        backgroundColor: matchProperties.backgroundColor || "",
        backgroundImage: matchProperties.backgroundImage || "",
        border: `1px solid ${matchProperties.borderColor}`,
      };
    },

    footerStyles(): { [key: string]: string | number } {
      const matchProperties = this.showMatchingBadge
        ? this.matchProperties
        : DEFAULT_MATCH;
      return {
        borderTop: `1px solid ${matchProperties.borderColor}`,
      };
    },

    matchingBadgeStyles(): { [key: string]: string | number } {
      return {
        boxShadow: this.matchProperties.matchingBadge?.boxShadow || "",
      };
    },

    matchingBadgeLabel(): string {
      return this.matchProperties.matchingBadge
        ? (this.$t(this.matchProperties.matchingBadge?.title) as string)
        : "";
    },

    badges(): any {
      let fundingTypesIcon = "icon-half-chart";
      let fundingTypesCopy = this.capitalInfo.funding_types
        .map((type) => type.name)
        .join(" & ");

      if (this.capitalInfo.funding_types.length == 1) {
        if (this.capitalInfo.funding_types[0].id === 1) {
          fundingTypesIcon = "icon-pie-chart";
        }
        if (this.capitalInfo.funding_types[0].id === 2) {
          fundingTypesIcon = "icon-circle";
        }

        fundingTypesCopy = this.$t("capitalExplorer.tiles.structureType", {
          type: fundingTypesCopy,
        }) as string;
      }

      return [
        {
          icon: fundingTypesIcon,
          description: fundingTypesCopy,
        },
        {
          icon: "icon-dollar-sign",
          description: this.capitalInfo.funding_stages
            .map((stage) => stage.name)
            .join(" & "),
        },
      ];
    },
  },
});
