
























import Vue from "vue";

export default Vue.extend({
  name: "SubmissionsTableRowPlaceholder",

  props: {
    userView: {
      type: Boolean,
      default: false,
    },
  },
});
