





















import Vue from "vue";

export default Vue.extend({
  name: "PxPanelSection",

  props: {
    /**
     * Section title.
     */
    title: {
      type: String,
      required: true,
    },

    /**
     * Allows to use an icon on the title.
     *
     * The icon is aligned to the left followed by the title,
     * aligned in the same way.
     */
    icon: {
      type: String,
      default: null,
    },

    iconSize: {
      type: Number,
      default: 20,
    },
  },
});
