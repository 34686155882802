













import Vue from "vue";

export default Vue.extend({
  name: "LevelBar",

  props: {
    /**
     * Text to be used for the label
     */
    label: {
      type: String,
      default: () => "",
    },

    /**
     * Contains the user range investing level value and description
     */
    range: {
      type: Array as () => any[],
      required: true,
    },
  },

  computed: {
    rangeBarLabel(): string {
      return this.label === ""
        ? (this.$t("supporters.signup.levelBarRange") as string)
        : this.label;
    },

    investingRange(): string {
      const uniqueValueRange = [...new Set(this.range)];
      return uniqueValueRange.join("-");
    },
  },
});
