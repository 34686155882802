import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { locationFormatter } from "@/utils/location-formatter";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { IMatchingBadge } from "@/modules/matching/services/data/matching-badge/matching-badge.interface";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import {
  MATCHING_BADGE_COMPLETED_QUESTION,
  MATCHING_BADGE_DIRECTORY_LISTING,
  MATCHING_BADGE_MEMBER,
  MATCHING_BADGE_MILESTONE_PLANNER,
} from "@/modules/matching/constants";

/**
 * Limit down the 100% scores to 99%
 *
 * @param score matching percentage
 */
export const limitScore = (score: number) => {
  return Math.round(score === 100 ? 99 : score);
};

/**
 * Build the location string for the given company.
 *
 * @param company company data
 */
export const buildCompanyLocation = (company: ICompany): string => {
  const locationComponent =
    company && company.locations && company.locations[0]
      ? company.locations[0]
      : null;

  return locationFormatter(locationComponent);
};

/**
 * Get badges based on Matching Score values
 *
 * @param score matching data
 */
export const getMatchingBadges = (
  score: IMatchingScore,
): Array<IMatchingBadge> => {
  const badges = [] as IMatchingBadge[];

  const addBadge = (name: string, meta: null | {} = null) =>
    badges.push({ name, meta });

  if (score.supporter) {
    const isDirectoryListing =
      !score.supporter.user_profile ||
      (score.supporter.user_profile && score.supporter.user_profile.is_offline);

    isDirectoryListing
      ? addBadge(MATCHING_BADGE_DIRECTORY_LISTING)
      : addBadge(MATCHING_BADGE_MEMBER);
  } else {
    if (score.affiliates?.length) {
      addBadge(MATCHING_BADGE_COMPLETED_QUESTION, {
        affiliatesCount: score.affiliates.length,
      });
    }

    if (score.milestone_planner) {
      addBadge(MATCHING_BADGE_MILESTONE_PLANNER, {
        uid: score.milestone_planner,
      });
    }
  }

  return badges;
};

/**
 * Get Company data based on Matching Score values
 *
 * @param score matching data
 */
export const getCompany = (score: IMatchingScore): ICompany => {
  return score.supporter
    ? score.supporter.user_profile.company
    : (score.company as ICompany);
};

export interface ISectorsItem {
  name: string;
  id: number;
}

/**
 * Get supporter sectors and grouped sectors
 *
 * @param {ISupporter} supporter
 * @return {*}  {Array<ISectorsItem>}
 */
export const getSupporterSectors = (
  supporter: ISupporter,
): Array<ISectorsItem> => {
  const sectors = [];

  const sectorTags = supporter.sectors.map((sector) => ({
    name: sector.name,
    id: sector.id,
  }));

  sectors.push(...sectorTags);

  if (supporter.grouped_sectors) {
    const sectorGroupedTags = [] as Array<ISectorsItem>;

    supporter.grouped_sectors.forEach((grouped_sector) => {
      if (grouped_sector.sectors.length) {
        const sectorTags = grouped_sector.sectors.map((sector) => ({
          name: sector.name,
          id: sector.id,
        }));
        sectorGroupedTags.push(...sectorTags);
      }
    });

    sectors.push(...sectorGroupedTags);
  }

  return sectors;
};

/**
 * Map matching score to matching card
 */
export const mapMatchingScoreToCard = (
  score: IMatchingScore | null,
): IMatchingCard | null => {
  // If null value, just return null back
  if (!score) {
    return null;
  }

  const company = getCompany(score);

  if (!company) {
    return null;
  }

  const level =
    (score.supporter ? score.supporter.investing_level_range : [score.level]) ||
    [];

  const matchSectors =
    (score.supporter
      ? getSupporterSectors(score.supporter)
      : company.sectors) || [];

  const matchLocations =
    (score.supporter ? score.supporter.locations : company.locations) || [];

  const matchOfferings = score.supporter ? score.supporter.offerings : [];

  const percentage =
    score.score !== undefined && score.score !== null
      ? limitScore(score.score)
      : -1;

  return {
    id: company.id,
    uid: company.uid,
    name: company.name,
    about: company.about,
    location: buildCompanyLocation(company),
    thumbnail: company.logo,
    level,
    percentage,
    offerings: matchOfferings,
    sectors: matchSectors,
    locations: matchLocations,
    badges: getMatchingBadges(score),
    affiliates: score.affiliates,
  } as IMatchingCard;
};

/**
 * Map matching score list to matching cards
 */
export const mapMatchingScoresToCards = (
  scores: Array<IMatchingScore>,
): Array<IMatchingCard> => {
  return scores.reduce(
    (accumulated: IMatchingCard[], score: IMatchingScore) => {
      const card = mapMatchingScoreToCard(score);

      if (!card) {
        return accumulated;
      }

      return [...accumulated, card];
    },
    [] as Array<IMatchingCard>,
  );
};
