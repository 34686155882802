
































import elDialogMixin from "@/mixins/el-dialog.mixin";
import { LocaleMessages } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { INITIATIVE_ONBOARDED } from "@/modules/team-management/constants";

export default elDialogMixin.extend({
  name: "InitiativeDialog",

  static: {
    linkButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    modalCopy(): LocaleMessages {
      return this.$t("teamManagement.initiativeModal") as LocaleMessages;
    },

    buttonProps(): any {
      return {
        ...this.$options.static.linkButton,
        label: this.modalCopy.link,
      };
    },
  },

  methods: {
    goToInitiativePage() {
      this.$store.dispatch(EMetaActions.SET, {
        key: INITIATIVE_ONBOARDED,
        value: true,
      });

      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
