











































































































import Vue from "vue";

import LevelBar from "@/modules/self-assessment/components/level-bar/level-bar.vue";
import LevelRangeBar from "@/modules/supporters/components/level-range-bar/level-range-bar.vue";

import {
  ROUTE_AUTHENTICATION_SIGNUP,
  ROUTE_AUTHENTICATION_PASSWORD_RECOVER,
} from "@/modules/authentication/services/router/routes-names";
import {
  EAuthActions,
  IAuthState,
} from "@/modules/authentication/services/store/auth/auth-types";
import { ElForm } from "element-ui/types/form";
import {
  generateRequiredValidator,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { GenericProviderException } from "@/services/data/exceptions/generic-provider.exception";
import { ErrorsManager } from "@/services/errors-manager";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { ElMessageComponent } from "element-ui/types/message";
import { ILevel } from "@/services/data/level/level.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { EMetaGetters } from "@/services/store/meta/meta-types";
import { SUPPORTERS_INVESTING_RANGE_META } from "@/modules/supporters/constants";
import {
  SUPPORTERS_AUTHENTICATION_LOGIN,
  SUPPORTERS_STARTING_POINT,
} from "@/modules/supporters/services/router/routes-names";

export default Vue.extend({
  name: "AuthBaseLogin",

  components: {
    LevelBar,
    LevelRangeBar,
  },

  data() {
    return {
      errors: new ErrorsManager({
        non_field_errors: {
          invalid: this.$t("authentication.login.errors.invalidCredentials"),
        },
      }),

      isShowLevelBar: false,

      // This field is used to show an empty error when
      // the credentials are invalid
      emailErrorMsg: null as null | string,

      infoMessage: null as null | ElMessageComponent,

      data: {
        email: "",
        password: "",
        remember: false,
        redirectUser: true,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.login.form.fields.email",
          true,
        ),
        password: generateRequiredValidator(
          this,
          "authentication.login.form.fields.password",
        ),
      },
    };
  },

  computed: {
    /**
     * Informs if the user comes from the assessment.
     */
    isFromAssessment(): boolean {
      return !!this.$route.query.fromAssessment;
    },

    /**
     * Get assessment level from the store.
     */
    assessmentLevel(): ILevel | undefined {
      const viralStore = this.$store.state.viralLevel as IViralLevelState;
      return viralStore.finalLevel;
    },

    /**
     * Informs if the user comes from the investing level range.
     */
    isFromInvestingSelection(): boolean {
      return !!this.$route.query.fromInvestingSelection;
    },

    /**
     * Informs if the user is in the supporters login page
     */
    isSupporter(): boolean {
      return this.$route.name === SUPPORTERS_AUTHENTICATION_LOGIN;
    },

    /**
     * Get the investing range level from the store.
     */
    investingRangeLevel(): Array<number> {
      return this.$store.getters[EMetaGetters.GET](
        SUPPORTERS_INVESTING_RANGE_META,
      );
    },

    /**
     * Gets the state of the auth module.
     */
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },

    /**
     * Get the loading state of the auth module.
     */
    loading(): boolean {
      return this.authState.loading;
    },

    /**
     * Retrieve any existing error that can occur during
     * the login process.
     */
    loginError(): GenericProviderException | null {
      return this.authState.error as GenericProviderException | null;
    },

    showEmailAsError(): boolean {
      return this.errors.has("non_field_errors", "invalid");
    },

    /**
     * Disable login button when one or more fields
     * are empty.
     */
    isLoadingBtnDisabled(): boolean {
      return this.data.email.length === 0 || this.data.password.length === 0;
    },
  },

  watch: {
    /**
     * Watch for changes with the login error state.
     *
     * When the newVal is defined that means that an error
     * occurred and it's necessary to set the errors,
     * otherwise clean up the errors manager.
     */
    loginError(newVal) {
      if (newVal && newVal instanceof ErrorsProviderException) {
        this.errors.record(newVal.response.data.errors);
        this.handleEmailValidationError();
      } else if (newVal) {
        this.handleAccountError();
      } else {
        this.errors.clear();
      }
    },

    /**
     * Clean errors when any form field change.
     */
    data: {
      deep: true,
      handler() {
        this.errors.clear();
      },
    },
  },

  created() {
    this.setEmailFromQueryParam();
  },

  methods: {
    async onClickResendEmail() {
      if (this.infoMessage !== null) {
        this.infoMessage.close();
      }

      await resendEmailProvider.create({
        email: this.data.email,
      });

      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-full",
      });
    },

    /**
     * When a email validation error occurs show an message
     * to inform the user to revisit his/her email to  check
     * for the validation email.
     */
    handleEmailValidationError() {
      if (!this.errors.has("non_field_errors", "email_not_verified")) {
        return;
      }

      const message = this.$t(
        "authentication.login.errors.emailNotValidated",
      ) as string;
      const resendLinkText = this.$t(
        "authentication.login.resendLink",
      ) as string;

      const h = this.$createElement;
      const v = (this as any)._v;
      const vNode = h("p", { staticClass: "el-message__content" }, [
        v(`${message} `),
        h(
          "el-button",
          {
            props: {
              type: "link",
            },
            on: {
              click: this.onClickResendEmail,
            },
            staticClass: "el-button--link-white",
          },
          resendLinkText,
        ),
      ]);

      this.infoMessage = this.$message({
        message: vNode,
        type: "info",
        duration: 0,
        customClass: "is-full",
      });
    },

    handleAccountError() {
      this.$message({
        message: this.$t(
          "authentication.login.errors.invalidAccount",
        ) as string,
        type: "error",
        duration: 10000,
        customClass: "is-full",
      });
    },

    /**
     * Handler for the click on the Sign Up button.
     */
    onClickSignUp() {
      this.isSupporter
        ? this.$router.push({ name: SUPPORTERS_STARTING_POINT })
        : this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    /**
     * Handler for the click on the Forgot your password button.
     */
    onClickPasswordRecover() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_PASSWORD_RECOVER });
    },

    /**
     * Handler for the click on the login button.
     */
    async onClickLogin() {
      // Remove the informational message
      if (this.infoMessage) {
        this.infoMessage.close();
      }

      const form = this.$refs.form as ElForm;

      try {
        await form.validate();
      } catch (_) {
        return;
      }

      this.$store.dispatch(EAuthActions.LOGIN, this.data);
    },

    /**
     * Set email input value from query param
     * and validate email field
     */
    async setEmailFromQueryParam() {
      if ("email" in this.$route.query && !!this.$route.query.email) {
        this.data.email = this.$route.query.email as string;
        await this.$nextTick();
        (this.$refs.form as any).validateField("email");
      }
    },
  },
});
