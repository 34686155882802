





































































































import Vue from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";

import { TranslateResult } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";

export default Vue.extend({
  name: "MatchDetailsTableRow",

  components: {
    MatchingCardTagList,
  },

  props: {
    criteria: {
      type: String,
      required: true,
    },

    authUserAnswer: {
      type: [
        String,
        Number,
        Array as () => Array<string>,
        Array as () => Array<number>,
        Object as () => {},
      ],
      default: "",
    },

    profileUserAnswer: {
      type: [
        String,
        Number,
        Array as () => Array<string>,
        Array as () => Array<number>,
        Object as () => {},
      ],
      default: "",
    },

    hasMatch: {
      type: Boolean,
      required: true,
    },

    impact: {
      type: Number,
      default: null,
    },

    iconMatch: {
      type: String,
      default: "match",
    },

    listFormat: {
      type: Boolean,
      default: false,
    },

    showLearnMoreLink: {
      type: Boolean,
      default: false,
    },

    instructions: {
      type: String,
      default: null,
    },
  },

  computed: {
    matchIcon(): string {
      return !this.formattedAuthUserAnswer || !this.formattedProfileUserAnswer
        ? "unanswered"
        : this.hasMatch
        ? this.iconMatch
        : "no-match";
    },

    isSectorCriteria(): boolean {
      return this.criteria == "Sector";
    },

    isLocationCriteria(): boolean {
      return this.criteria == "Location";
    },

    allLocationsOrSectorsText(): TranslateResult {
      return this.isLocationCriteria
        ? this.$t("profile.company.emptyState.locationsOfInterest[0]")
        : this.$t("profile.company.emptyState.sectorsOfInterest[0]");
    },

    formattedAuthUserAnswer(): string | number | object {
      return this.formatAnswers(this.authUserAnswer);
    },

    formattedProfileUserAnswer(): string | number | object {
      return this.formatAnswers(this.profileUserAnswer);
    },

    formattedImpactValue(): string {
      return `${Math.trunc(this.impact)}%`;
    },

    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore") as string,
      };
    },

    answerHeaderCopy(): TranslateResult {
      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      return isVisitorsView
        ? this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${1}]`)
        : this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${0}]`);
    },
  },

  methods: {
    formatAnswers(answer: string | number | object) {
      // When there's no location or sectors answer it means that the user is searching for all sectors or all locations
      if ((this.isLocationCriteria || this.isSectorCriteria) && !answer) {
        return this.allLocationsOrSectorsText;
      }

      if (!answer) return "";

      // When the answer is an array we should join the text
      if (Array.isArray(answer)) return answer.join(" ");

      // When the answer is an object we should format the values
      if (typeof answer === "object") {
        return this.formatObjectValues(answer);
      }

      return answer;
    },

    formatObjectValues(answer: object) {
      let answerValues = answer;

      // This is used when we get one object with min and max key/values
      if (Object.keys(answerValues).length > 1) {
        // We should reverse the array to start in the min value
        const reverseValues = Object.values(answer).reverse();

        const formattedValues = reverseValues.map((value: number) =>
          parseInt(String(value)).toLocaleString(),
        );

        return formattedValues.join(" - ");
      }

      // When we only get one key/value pair
      answerValues = Object.values(answer)[0];

      return parseInt(String(answerValues)).toLocaleString();
    },
  },
});
