



















































































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

import moment from "moment";
import camelCase from "lodash/camelCase";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import pick from "lodash/pick";
import snakeCase from "lodash/snakeCase";

import PxDatePicker from "@/components/px-date-picker/px-date-picker.vue";
import MilestonePlanConfirmationDialog from "@/modules/milestone-planner/components/milestone-plan-confirmation-dialog/milestone-plan-confirmation-dialog.vue";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMilestoneForm } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export interface IFormField {
  fieldValue: any;
  prop: string;
  validateState: string;
  required: boolean;
}

const MAX_FINANCES_NEEDED = 1000000000;

const LOCAL_CURRENCY_SETTINGS = {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 0,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
};

export default Vue.extend({
  name: "MilestoneFormPlan",

  components: {
    PxDatePicker,
    MilestonePlanConfirmationDialog,
  },

  props: {
    categoryLevel: {
      type: Object as () => ICategoryDetail,
      required: true,
    },
    categoryName: {
      type: String,
      default: "",
    },
    milestone: {
      type: Object as () => IMilestone,
      default: null,
    },
    evidenceQuestion: {
      type: Object as () => IMatchingQuestion,
      default: null,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {} as IMilestoneForm["plan"],
      // Will be reused to reset form for future milestones:
      planInitialState: Object.freeze({
        critical: false,
        strategy: "",
        outcomes: "",
        resources: "",
        financesNeeded: "",
        targetDate: null,
      }) as IMilestoneForm["plan"],
      rules: {
        strategy: {
          max: 4000,
        },
        outcomes: {
          max: 4000,
        },
        resources: {
          max: 4000,
        },
        financesNeeded: [
          {
            validator: (_: any, value: string, callback: any) => {
              const myValue: number = parseFloat(value.replace(/,/g, ""));
              const valueCheck =
                myValue > MAX_FINANCES_NEEDED ? new Error() : undefined;
              callback(valueCheck);
            },
            trigger: "change",
            message: this.$t("common.errors.numeric.max", {
              maxValue: (this as any).getFormattedFinancesNeededMaxValue(),
            }),
          },
        ],
      },
      formEl: null as null | Element,
      originalData: {} as IMilestoneForm["plan"],
      isDeleting: false,
      isDiscardingChanges: false,
    };
  },

  computed: {
    criticalTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.critical.owner",
      );
    },

    outcomesTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.outcomes",
        { question: this.outcomesQuestion },
      );
    },

    targetDateTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.planForm.tooltips.targetDate",
      );
    },

    successfullySubmitedMessage(): TranslateResult {
      return this.getSuccessMessage(
        "successfullySubmited",
        !!this.milestone?.plan_published,
      );
    },

    successfullyDeletedMessage(): TranslateResult {
      return this.getSuccessMessage(
        "successfullyDeleted",
        !!this.milestone?.plan_published,
      );
    },

    outcomesQuestion(): string {
      return this.evidenceQuestion?.entrepreneur_question || "";
    },

    formFields(): IFormField[] {
      return (this.formEl as any).fields as IFormField[];
    },

    formIsComplete(): boolean {
      if (this.formEl) {
        const formFields = this.formFields;

        // Check if field is required and has not value or if field has error
        const fieldsAreInvalid = (field: IFormField) => {
          return (
            (Boolean(!field.fieldValue) && field.required !== false) ||
            this.formIsInvalid
          );
        };

        return !formFields?.some(fieldsAreInvalid);
      }
      return false;
    },

    //TODO: we need to add E2E tests to check the invalid state of the form
    formIsInvalid(): boolean {
      if (this.formEl) {
        const formFields = this.formFields;

        // Check if any field has an error state
        const checkFieldError = (field: IFormField) => {
          // Check if the field has only spaces and not content
          if (typeof field.fieldValue === "string" && field.fieldValue.length) {
            return !field.fieldValue.trim();
          }

          return field.validateState === "error";
        };

        let formInvalid = formFields?.some(checkFieldError);

        // * Temporary solution for invalidating the form if the critical field
        // * is checked and everything else is empty.
        // * For the time being, saving the above as draft will result in an
        // * invalid API request, and therefore we will disable the button in
        // * that specific case.
        // * In the future, we shall disregard this and return the line above.

        if (!formInvalid) {
          const criticalField = formFields.find(
            (field) => field.prop === "critical",
          );

          if (criticalField?.fieldValue) {
            const otherFields = formFields.filter(
              (field) => field.prop !== "critical",
            );

            formInvalid = !otherFields.some((field) => field.fieldValue);
          }
        }

        return (
          formInvalid || isEqual((this as any).form, this.planInitialState)
        );
      }
      return false;
    },

    hasFormChanged(): boolean {
      return !isEqual((this as any).form, this.originalData);
    },

    /**
     * Formatted form data to match the expected API schema:
     */
    formattedForm(): any {
      const targetDate = this.form.targetDate
        ? moment(this.form.targetDate).format("YYYY-MM-DD")
        : this.form.targetDate;
      const financesNeeded = this.form.financesNeeded
        ? parseFloat(this.form.financesNeeded.replace(/,/g, ""))
        : null;
      return { ...this.form, targetDate, financesNeeded };
    },

    isExistingMilestone(): boolean {
      return !!this.milestone;
    },

    hasErrorOnMilestones(): boolean {
      return Boolean(this.$store.get("auth/milestones.error"));
    },
  },

  watch: {
    formIsComplete(isComplete: boolean) {
      this.$emit("plan-form-is-complete", isComplete);
    },

    formIsInvalid(isInvalid: boolean) {
      this.$emit("plan-form-is-invalid", isInvalid);
    },

    hasFormChanged(hasChanged: boolean) {
      //TODO: check this emit (is happening twice on initialization because the assignment of the originalData only occurs on mounted)
      this.$emit("plan-form-has-changes", hasChanged);
    },

    categoryLevel() {
      if (!this.isExistingMilestone) {
        this.resetForm();
      }
    },

    milestone: {
      deep: true,
      immediate: true,
      handler(newValue: IMilestone | null) {
        if (newValue) {
          const milestoneToFormValues = this.formatMilestoneValues(newValue);
          const prefillValues = mapKeys(milestoneToFormValues, (v, k) =>
            camelCase(k),
          );
          (this as any).form = pick(
            prefillValues,
            Object.keys(this.planInitialState),
          );
        } else {
          this.form = { ...this.planInitialState };
        }

        this.originalData = cloneDeep(this.form);
      },
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.form;
    this.originalData = cloneDeep(this.form);
    this.$emit("plan-form-is-complete", this.formIsComplete);
  },

  created() {
    this.$root.$on("delete-milestone-plan", this.showDeletePlanDialog);
    this.$root.$on("discard-milestone-plan", this.showDiscardChangesDialog);
    this.$root.$on("submit-milestone-plan", this.submitPlan);
  },

  beforeDestroy() {
    this.$root.$off("delete-milestone-plan", this.showDeletePlanDialog);
    this.$root.$off("discard-milestone-plan", this.showDiscardChangesDialog);
    this.$root.$off("submit-milestone-plan", this.submitPlan);
  },

  methods: {
    convertKeysToSnakeCase(fields: {}) {
      // TODO: Move this functionality into the generic provider
      return mapKeys(fields, (v, k) => snakeCase(k));
    },

    formatMilestoneValues(milestone: IMilestone) {
      const targetDate = milestone.target_date
        ? new Date(milestone.target_date)
        : null;
      const financesNeeded = !isNaN(String(milestone.finances_needed) as any)
        ? String(milestone.finances_needed)
        : "";
      return { ...milestone, targetDate, financesNeeded };
    },

    resetForm() {
      this.form = { ...this.originalData };
    },

    showDeletePlanDialog() {
      this.isDeleting = true;
    },

    showDiscardChangesDialog() {
      this.isDiscardingChanges = true;
    },

    async submitPlan({ toPublish }: { [key: string]: boolean }) {
      const fieldsInSnakeCase = this.convertKeysToSnakeCase(this.formattedForm);

      if (!this.isExistingMilestone) {
        await this.$store.dispatch(EAuthMilestonesActions.CREATE, {
          ...fieldsInSnakeCase,
          category_level: this.categoryLevel.id,
          plan_published: toPublish,
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            ...fieldsInSnakeCase,
            plan_published: toPublish,
          },
        });
      }

      this.showMessage(
        this.hasErrorOnMilestones,
        this.successfullySubmitedMessage,
      );
    },

    async deletePlan() {
      const successMessage = this.successfullyDeletedMessage;

      if (this.hasCompletionInfo) {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            critical: false,
            finances_needed: null,
            outcomes: "",
            resources: "",
            strategy: "",
            target_date: null,
            plan_published: false,
          },
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.DESTROY, {
          milestoneToDelete: this.milestone,
        });
      }

      this.showMessage(this.hasErrorOnMilestones, successMessage);
    },

    showMessage(hasError: boolean, successMessage: TranslateResult) {
      if (hasError) {
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else {
        this.$message({
          message: successMessage as string,
          type: "success",
          duration: 10000,
          customClass: "is-full",
        });
      }
    },

    getSuccessMessage(
      actionKey: string,
      isPlanPublished: boolean,
    ): TranslateResult {
      return isPlanPublished
        ? this.$t(`milestonePlanner.milestonePlan.planForm.${actionKey}.plan`)
        : this.$t(`milestonePlanner.milestonePlan.planForm.${actionKey}.draft`);
    },

    getFormattedFinancesNeededMaxValue(): string {
      return this.$options.filters?.currency(
        MAX_FINANCES_NEEDED,
        LOCAL_CURRENCY_SETTINGS,
      );
    },
  },
});
