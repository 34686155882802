































































































import { orderBy } from "lodash";

import Vue from "vue";
import {
  IGridCategory,
  IGridMilestone,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import MilestonesFullGridCell from "@/modules/milestone-planner/components/milestones-full-grid/milestones-full-grid-cell.vue";
import { ILevel } from "@/services/data/level/level.interface";

export default Vue.extend({
  name: "MilestonesFullGrid",

  components: {
    MilestonesFullGridCell,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },

    levels: {
      type: Array as () => ILevel[],
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },
  },

  computed: {
    currentLevelLinePosition(): number {
      const cellHeightPx = 74;
      const cellUpperMarginPx = 7;
      const totalCellHeight = cellHeightPx + cellUpperMarginPx;

      return this.currentLevel * totalCellHeight - cellUpperMarginPx / 2;
    },

    invertedSortLevels(): ILevel[] {
      return orderBy(this.levels, ["value"], ["desc"]);
    },
  },

  methods: {
    getSortedMilestones(
      unsortedMilestones: IGridMilestone[],
    ): IGridMilestone[] {
      return [...unsortedMilestones].reverse();
    },
  },
});
