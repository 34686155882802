import { render, staticRenderFns } from "./affiliate-review-page-team-members.vue?vue&type=template&id=e40233e4&scoped=true&"
import script from "./affiliate-review-page-team-members.vue?vue&type=script&lang=ts&"
export * from "./affiliate-review-page-team-members.vue?vue&type=script&lang=ts&"
import style0 from "./affiliate-review-page-team-members.vue?vue&type=style&index=0&id=e40233e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e40233e4",
  null
  
)

export default component.exports