












































import ElDialogMixin from "@/mixins/el-dialog.mixin";

export default ElDialogMixin.extend({
  name: "DiscardModal",

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "common.dialogConfirmation.discardMessage",
    },

    subtitle: {
      type: String,
      default: "",
    },

    actionButtonLabel: {
      type: String,
      default: "common.dialogConfirmation.confirm",
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },

    beforeCloseHandler() {
      this.updateClosedState();
    },

    onClickCancelButton() {
      this.$emit("cancel-action");
      this.updateClosedState();
    },

    onClickDiscardButton() {
      this.$emit("discard-action");
      this.updateClosedState();
    },
  },
});
