



































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import ListShareClipboardButton from "@/modules/company-lists/components/list-share/list-share-clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default Vue.extend({
  name: "ListShareSettingsLink",

  components: {
    ListShareClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  static: {
    resetButton: {
      icon: "link--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },
  },

  methods: {
    clickResetLinkHandler() {
      this.$root.$emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.RESET_SHAREABLE_LINK,
      });
    },
  },
});
