


















































import Vue from "vue";

import get from "lodash/get";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default Vue.extend({
  name: "ResultsBoardJoin",

  computed: {
    affiliate(): IAffiliate {
      return this.$store.state.affiliate.value as IAffiliate;
    },

    affiliateShortcode(): string {
      return get(this, "affiliate.shortcode", "");
    },

    /**
     * List of member benefits.
     */
    memberBenefits(): Array<string> {
      return (this as any).$t(
        "supporters.results.confirmationPanel.list",
      ) as Array<string>;
    },
  },

  methods: {
    onClickSignUp() {
      // No data to submit hence why we're sending null.
      this.$root.$emit("next-page", null);
    },
  },
});
