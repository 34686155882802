import { render, staticRenderFns } from "./charts-table-wrapper.vue?vue&type=template&id=c275ed64&scoped=true&"
import script from "./charts-table-wrapper.vue?vue&type=script&lang=ts&"
export * from "./charts-table-wrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c275ed64",
  null
  
)

export default component.exports