import { render, staticRenderFns } from "./panel-empty-state.vue?vue&type=template&id=61e38de8&scoped=true&"
import script from "./panel-empty-state.vue?vue&type=script&lang=ts&"
export * from "./panel-empty-state.vue?vue&type=script&lang=ts&"
import style0 from "./panel-empty-state.vue?vue&type=style&index=0&id=61e38de8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e38de8",
  null
  
)

export default component.exports