

















































import Vue from "vue";

import { colorShade } from "@/utils/color-shade";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";

const COLOR_VARIATION_AMOUNT = -20;
const DESIGN_LEFT_SPACE = 3;

export default Vue.extend({
  name: "CategoryWizard",

  props: {
    steps: {
      type: Array as () => ICategoryStepItem[],
      required: true,
    },

    currentStep: {
      type: Number,
      default: 0,
    },

    active: {
      type: Number,
      default: null,
    },

    checked: {
      type: Array as () => any[],
      default: () => [],
    },

    /**
     * Disable the user interaction with the wizard.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxIndexRetched: 0,
    };
  },

  computed: {
    /**
     * Computes the higher checked index.
     */
    higherCheckedIndex() {
      const checkedSteps: Array<number> = this.checked;
      if (checkedSteps.length === 0) {
        return -1;
      }

      let higherIndex = -1;
      checkedSteps.forEach((item: number) => {
        const index = this.steps.findIndex(
          (step: ICategoryStepItem) => step.value === item,
        );
        higherIndex = Math.max(index, higherIndex);
      });

      return higherIndex;
    },

    /**
     * Return the progress bar width based on the higher checked step.
     */
    progressStyle() {
      const perStep = 100 / this.steps.length + 1;
      const target = Math.max(this.higherCheckedIndex, this.currentStep);
      const currentProgress = perStep * target + DESIGN_LEFT_SPACE;

      return {
        width: `${currentProgress}%`,
      };
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: ICategoryStepItem): boolean {
      return this.checked.includes(step.value);
    },

    /**
     * Generates the circle colors for the given step.
     */
    stepCircleStyle(step: ICategoryStepItem): { [key: string]: string } {
      const stepColor = `#${step.color}`;
      const newBorderColor = colorShade(stepColor, COLOR_VARIATION_AMOUNT);

      return {
        "--lightColor": stepColor,
        "--darkColor": newBorderColor,
      };
    },

    stepClickHandler(index: number) {
      this.$emit("update:currentStep", index);
      this.$emit("changed", index);
    },
  },
});
