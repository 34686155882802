















import Vue from "vue";

import { Doughnut } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default Vue.extend({
  name: "DoughnutChart",

  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    chartWidth: {
      type: Number,
      default: 400,
    },
    chartHeight: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: null,
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  computed: {
    chartTotalNumber(): number {
      return (this.data as any).datasets[0].data.reduce(
        (a: number, b: number) => a + b,
      );
    },

    chartPlugins() {
      // Create a copy of the plugins array to avoid mutating the prop directly
      const plugins = [...this.plugins, ChartJsPluginDataLabels];
      const height = this.chartHeight;
      const width = this.chartWidth;
      const totalNumber = this.chartTotalNumber.toString();

      plugins.push({
        // Plugin to change the canvas height and width to the desired one
        beforeInit: function (chart: ChartJS) {
          if (chart.canvas) {
            chart.canvas.style.height = `${height}px`;
            chart.canvas.style.width = `${width}px`;
          }
        },
        beforeDraw: function (chart: ChartJS) {
          const ctx = chart.ctx;

          // Style for "Total" text
          ctx.font = "14px HkGrotesk";
          ctx.fillStyle = "#8b8fa1";
          ctx.textAlign = "center";

          // Calculate the center coordinates of the doughnut chart
          const centerX = chart.width / 2 - 1;
          const centerY = chart.height / 2 - 14;

          // Draw the "Total" text
          ctx.fillText("Total", centerX, centerY);

          // Style for the number
          ctx.font = "bold 30px HkGrotesk";
          ctx.fillStyle = "#272740";

          // Calculate the center coordinates for the number
          const numberY = chart.height / 2 + 21;

          // Draw the total number text text
          ctx.fillText(totalNumber, centerX, numberY);
        },
      });

      return plugins;
    },

    chartOptions(): any {
      return this.options ? this.options : this.defaultChartOptions;
    },

    defaultChartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            caretSize: 0,
            padding: 4,
            cornerRadius: 4,
            backgroundColor: "rgba(0, 0, 0, 0.30)",
            bodyFont: {
              weight: 500,
              size: 15,
              family: "HkGrotesk",
            },
            displayColors: false,
            callbacks: {
              label: function (context: any) {
                const index = context.dataIndex;
                return context.dataset.percentage[index] + "%";
              },
            },
          },
        },
        cutout: "60%",
        elements: {
          arc: {
            offset: 5,
          },
        },
      };
    },
  },
});
