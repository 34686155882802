

























import Vue from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

const MAX_DESCRIPTION_LENGTH = 65;

export default Vue.extend({
  name: "LevelsDescription",

  props: {
    /**
     * List of details for the current category.
     */
    categoryDetails: {
      type: Array as () => ICategoryDetail[],
      required: true,
    },

    /**
     * Current level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },

    /**
     * Levels wrapper total height to help vertically distributing the levels.
     */
    height: {
      type: String,
      required: true,
    },
  },

  computed: {
    sortedDetails(): ICategoryDetail[] {
      return [...this.categoryDetails].sort(
        (a, b) => b.level.value - a.level.value,
      );
    },
  },

  methods: {
    getItemClasses(item: ICategoryDetail): { [key: string]: boolean } {
      return {
        "levels-description__item--visible": this.isItemVisible(item),
        "levels-description__item--checked": this.isItemChecked(item),
        "levels-description__item--selected": this.isItemSelected(item),
        "levels-description__item--truncated": this.itemHasLongText(item),
      };
    },

    isItemVisible(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel + 1;
    },

    isItemChecked(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel;
    },

    isItemSelected(item: ICategoryDetail): boolean {
      return item.level.value === this.currentLevel;
    },

    itemHasLongText(item: ICategoryDetail): boolean {
      return item.achievements.length > MAX_DESCRIPTION_LENGTH;
    },

    getItemText(item: ICategoryDetail): string {
      return this.itemHasLongText(item)
        ? `${item.achievements.substring(0, MAX_DESCRIPTION_LENGTH - 1)}…`
        : item.achievements;
    },
  },
});
