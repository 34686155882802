












































import Vue from "vue";
import {
  IViralLevelState,
  EViralLevelGetters,
} from "@/services/store/viral-level/viral-level-types";
import { TranslateResult } from "vue-i18n";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

export default Vue.extend({
  name: "MobileFooterNav",

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    viralLevelState(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    /**
     * Index of the current selected category.
     */
    selectedCategory(): number {
      return this.viralLevelState.selectedCategory;
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      return this.$store.getters[EViralLevelGetters.SELECTED_STEP_DATA];
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.viralLevelState.levels;
    },

    isLastStep(): boolean {
      return this.selectedCategory === this.levels.length - 1;
    },

    /**
     * Get the text for the next button.
     */
    nextButtonText(): TranslateResult {
      if (!this.selectedStepData) {
        return this.$t("selfAssessment.assessment.nextButton.skip");
      }

      if (this.isLastStep) {
        return this.selectedStepData.level === 0
          ? this.$t("selfAssessment.assessment.nextButton.skipFinal")
          : this.$t("selfAssessment.assessment.nextButton.nextFinalMobile");
      }

      return this.selectedStepData.level === 0
        ? this.$t("selfAssessment.assessment.nextButton.skip")
        : this.$t("selfAssessment.assessment.nextButton.next");
    },

    /**
     * Returns what type of button must be used for the next button.
     */
    nextButtonType(): string {
      return !this.selectedStepData || this.selectedStepData.level === 0
        ? "default"
        : "green";
    },

    nextButtonClass(): string {
      const iconClass =
        !this.selectedStepData || this.selectedStepData.level === 0
          ? "icon-arrow--right-grey"
          : "icon-arrow--right-white";
      return `${iconClass} icon--right`;
    },

    showNextButtonIcon(): boolean {
      return !this.isLastStep;
    },
  },
});
