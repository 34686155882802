


























































import Vue from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { copyToClipboard } from "@/utils/clipboard";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { PROFILE_AFFILIATE_LINK_QUERY } from "@/modules/profile/constants";
import { EProfileSupporterActions } from "@/modules/profile/services/store/profile/profile-types";

export default Vue.extend({
  name: "AffiliateLinkEditModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      affiliateLink: "",
      innerVisibility: false,
      copiedVisibility: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    companyNameLink(): string {
      return this.company ? this.company.name : "";
    },

    affiliateLinkText(): string {
      return `${location.host}/${PROFILE_AFFILIATE_LINK_QUERY}`;
    },

    profileId(): number {
      return this.$store.state.auth.profileId;
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },

    affiliateLink: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }

        // Regular expression to match characters that are not letters, numbers, underscores, or hyphens
        // eslint-disable-next-line no-useless-escape
        const regex = /[^A-Za-z0-9_\-]/g;
        this.affiliateLink = this.affiliateLink.replace(regex, "");
      },
    },
  },

  created() {
    this.affiliateLink = this.companyNameLink;
  },

  methods: {
    onModalClose() {
      window.scrollTo(0, 0);
      this.copiedVisibility = false;
      this.innerVisibility = false;
      this.affiliateLink = this.companyNameLink;
    },

    async onClickGenerate() {
      const affiliate = await this.$store.dispatch(
        EAuthActions.CREATE_AFFILIATE_LINK,
        {
          slug: this.affiliateLink,
        },
      );

      if (!affiliate) {
        this.$message({
          message: this.$t(
            "profile.affiliateLink.modal.errorMessage",
          ) as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else {
        // Save data here and remove temporary affiliate link prop
        this.copiedVisibility = true;

        // fetch updated supporter data
        await this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
          user_profile: this.profileId,
        });
        this.$store.dispatch(EProfileSupporterActions.FETCH, {
          user_profile: this.profileId,
        });

        copyToClipboard(
          `${location.origin}/${PROFILE_AFFILIATE_LINK_QUERY}${this.affiliateLink}`,
        );

        this.onModalClose();

        this.$message({
          message: this.$t(
            "profile.affiliateLink.modal.successMessage",
          ) as string,
          type: "success",
          duration: 10000,
          customClass: "is-full",
        });
      }
    },
  },
});
