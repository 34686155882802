








import Vue from "vue";

export default Vue.extend({
  name: "MatchingCardBackground",

  props: {
    companyName: {
      type: String,
      default: "",
    },

    source: {
      type: String,
      default: null,
    },
  },

  static: {
    defaultBackgroundSource: "/img/shapes/matching-card/abaca-cover-cards.svg",
  },

  computed: {
    backgroundSource(): string {
      return this.source || this.$options.static.defaultBackgroundSource;
    },
  },
});
