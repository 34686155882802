var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"px-navbar",class:[
      { 'px-navbar--affiliate': _vm.logo },
      ("px-navbar--" + _vm.theme),
      { 'px-navbar--partner': _vm.isPartner } ]},[_c('div',{staticClass:"px-navbar__wrapper"},[(!_vm.loading)?[_c('div',{staticClass:"px-navbar__left-side"},[(_vm.$user.isLogged())?_c('RouterLink',{staticClass:"px-navbar__logo-link",attrs:{"to":_vm.logoLink}},[_c('img',{staticClass:"px-navbar__logo",attrs:{"src":_vm.mainLogo,"data-testid":"px-navbar__logo","width":"92","height":"32","alt":"VIRAL Logo"}})]):_c('a',{staticClass:"px-navbar__logo-link",class:{
              'px-navbar__logo-link-disabled':
                _vm.$user.isPendingUser() || _vm.$user.isPendingSupporter(),
            },attrs:{"href":_vm.logoLink}},[_c('img',{staticClass:"px-navbar__logo",attrs:{"src":_vm.mainLogo,"data-testid":"px-navbar__logo","width":"92","height":"32","alt":"VIRAL Logo"}})]),(_vm.showLogo)?_c('div',{staticClass:"px-navbar__logo-affiliate-wrapper"},[_c('img',{staticClass:"px-navbar__logo-affiliate",attrs:{"src":_vm.logo,"data-testid":"px-navbar__logo-affiliate","alt":"Affiliate Logo"},on:{"click":_vm.externalLinkClickHandler}})]):_vm._e(),(_vm.isPartner)?_c('div',{staticClass:"px-navbar--partner__description-wrapper"},[_c('p',{staticClass:"px-navbar--partner__description-text"},[_vm._v(" "+_vm._s(_vm.getPartnerName)+" ")])]):_vm._e(),(_vm.isVisitorNavigation && !_vm.showLogo && !_vm.isPartner)?_c('div',{staticClass:"px-navbar__menu"},[_c('a',{staticClass:"px-navbar__link",attrs:{"href":_vm.howItWorksLink.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.howItWorksLink.title)+" ")])]):_vm._e(),(_vm.showHeadline && _vm.headline && _vm.isThemeWizard)?_c('h2',{staticClass:"px-navbar__headline"},[_vm._v(" "+_vm._s(_vm.$t(_vm.headline))+" ")]):_vm._e(),(_vm.items.length)?_c('PxMenu',{attrs:{"items":_vm.items}}):_vm._e()],1),_c('div',{staticClass:"px-navbar__center",class:{
            'px-navbar__center--empty':
              !_vm.hasNavbarCenterTitle && !_vm.hasNavbarCenterSlot,
          }},[(_vm.headline && !_vm.isThemeWizard)?_c('h2',{staticClass:"px-navbar__headline"},[_vm._v(" "+_vm._s(_vm.$t(_vm.headline))+" ")]):_vm._e(),_vm._t("navbar-center")],2),(_vm.showLinks)?_c('div',{staticClass:"px-navbar__right-side"},[_vm._t("navbar-right",function(){return [(_vm.isVisitorNavigation)?[(_vm.showLogo && !_vm.isHowItWorksLinkDisabled)?_c('div',{staticClass:"px-navbar__menu"},[_c('a',{staticClass:"px-navbar__link",attrs:{"href":_vm.howItWorksLink.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.howItWorksLink.title)+" ")])]):_vm._e(),(_vm.showLoginButton)?_vm._t("login-button",function(){return [_c('a',{staticClass:"px-navbar__link",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.onClickLogin}},[_vm._v(" "+_vm._s(_vm.$t("common.navBar.login"))+" ")])]}):_vm._e()]:_vm._e(),(_vm.showAccountDropdown)?_c('AccountDropdown',{attrs:{"slot":"navbar-right"},on:{"open-modal":function($event){_vm.setAccountModalVisible = true}},slot:"navbar-right"}):_vm._e()]})],2):_vm._e()]:_vm._e()],2)]),(_vm.$user.isLogged())?_c('AccountSettingsModal',{attrs:{"visibility":_vm.setAccountModalVisible},on:{"update:visibility":function($event){_vm.setAccountModalVisible=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }