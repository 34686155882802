































import Vue from "vue";

export default Vue.extend({
  name: "LevelCard",

  props: {
    /**
     * CategoryLevel details
     */
    details: {
      type: Object as () => any,
      required: true,
    },

    /**
     * Category color to be used on the checkbox.
     */
    categoryColor: {
      type: String,
      required: true,
    },

    /**
     * Checkbox value
     */
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: false,
    };
  },

  watch: {
    /**
     * Every time that the value changes the innerValue
     * must be updated as well.
     */
    value: {
      immediate: true,
      handler(newVal: boolean) {
        this.innerValue = newVal;
      },
    },
  },

  methods: {
    onCheckboxClick() {
      this.$emit("change", !this.value);
    },
  },
});
