










import Vue from "vue";
import ErrorPage from "@/modules/common/components/error-page/error-page.vue";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

export default Vue.extend({
  name: "ErrorPageView",

  components: {
    ErrorPage,
  },

  computed: {
    shouldShowSupportModal(): boolean {
      return this.errorCode === ERROR_TYPES.FORBIDDEN;
    },

    errorCode(): string | null {
      return this.$route.query.code as string | null;
    },
  },
});
