



















































import ElDialogMixin from "@/mixins/el-dialog.mixin";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";

export default ElDialogMixin.extend({
  name: "PxMatchingInterestDirectoryMemberModal",

  props: {
    hasServerError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

    companyUid: {
      type: String,
      required: true,
    },
  },

  static: {
    interestButton: {
      icon: "connect",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GREEN,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
    iconSize: 24,
  },

  computed: {
    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList") as string,
      };
    },

    interestButtonProps(): any {
      return {
        ...this.$options.static.interestButton,
        label: this.$t(
          `matching.matchingInterest.directoryMemberModal.submitButton`,
        ) as string,
      };
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    onClickCancelButton() {
      this.updateClosedState();
    },
    onClickAddInterestButton() {
      this.$emit("connect");
    },
    onShowListManagementModal() {
      // Select self
      this.$store.dispatch(EListManagementActions.ADD_COMPANY, this.companyUid);

      // Emit root action, should be available on context
      this.$root.$emit("show-list-management-modal");
      this.updateClosedState();
    },
  },
});
