




















































import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "InitiativeDetails",

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.EXTRA_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data.previousRoute = from.path;
    });
  },

  data() {
    return {
      previousRoute: "",
    };
  },

  computed: {
    initiativeContentCopy(): any {
      return this.$t("teamManagement.initiativePage.content") as any;
    },

    showAddTeamMembersCta(): boolean {
      return this.previousRoute.includes("program");
    },
  },

  methods: {
    goToPreviousPage() {
      return this.$router.back();
    },
  },
});
