























































































































































































































import Vue from "vue";

import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { passwordChangeProvider } from "@/modules/authentication/services/data/password-change/password-change.provider";
import { emailChangeProvider } from "@/modules/authentication/services/data/email-change/email-change.provider";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";
import { isDevelopment } from "@/services/utils/utils";
import { ElForm } from "element-ui/types/form";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

const EMAIL_ORIGINAL_DATA = {
  email: "",
  password: "",
};

const PASSWORD_ORIGINAL_DATA = {
  old_password: "",
  new_password1: "",
  new_password2: "",
};

export default Vue.extend({
  name: "AccountSettingsModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    openView: {
      type: String,
      default: "email",
    },
  },

  data() {
    return {
      isLoading: false,
      hasServerError: false,
      isPasswordSuccessVisible: false,
      isEmailSuccessVisible: false,
      submittedEmailChange: false,
      resentEmailConfirmation: false,

      selectedView: this.openView,

      innerVisibility: false,
      isSubmissionDisabled: true,

      passwordFormEl: null as null | ElForm,

      forms: {
        email: "changeEmailForm",
        password: "changePasswordForm",
      },

      fields: {
        old_password: "",
        new_password1: "",
        new_password2: "",
      },

      newEmail: "",

      emailFields: {
        email: this.$store.get("auth/user.email"),
        password: "",
      },

      rules: {
        old_password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.oldPassword",
        ),

        new_password1: generatePasswordValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.newPassword",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),

        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),

        email: generateEmailValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.newEmail",
          true,
        ),

        password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.password",
        ),
      },
    };
  },

  computed: {
    dialogConfirmationOptions(): any {
      return [
        {
          eventName: "confirm-cancel-edit",
          showCondition: this.isFormChanged,
          message: this.$t("common.dialogConfirmation.discardMessage"),
          confirmFn: this.onConfirmCancel,
        },
      ];
    },

    /**
     * Object with the text that the update button can have.
     */
    submitButtons(): { [key: string]: any } {
      return {
        email: this.$t("profile.accountSettingsModal.changeEmail.submitButton"),
        password: this.$t(
          "profile.accountSettingsModal.changePassword.submitButton",
        ),
      };
    },

    /**
     * Object with the update methods for each tab.
     */
    updateMethods(): { [key: string]: Function } {
      return {
        email: this.updateEmail,
        password: this.updatePassword,
      };
    },

    currentForm(): any {
      const currentForm = get(this.forms, this.selectedView, "email") as string;
      return this.$refs[currentForm];
    },

    emailHasChanged(): boolean {
      return this.emailFields.email !== EMAIL_ORIGINAL_DATA.email;
    },

    passwordFormFields(): IFormField[] | null {
      return this.passwordFormEl
        ? ((this.passwordFormEl as any).fields as IFormField[])
        : null;
    },

    passwordIsInvalid(): boolean {
      if (this.passwordFormFields) {
        const formFields = this.passwordFormFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  watch: {
    openView(newVal) {
      this.selectedView = newVal;
    },

    selectedView(newVal) {
      if (newVal === "password") {
        (this as any).passwordFormEl = this.$refs.changePasswordForm;
      }
    },

    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal) {
      this.$emit("update:visibility", newVal);
    },
  },

  created() {
    EMAIL_ORIGINAL_DATA.email = this.$store.get("auth/user.email");
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },

    /**
     * Handler when the user click on the update button.
     */
    async onClickUpdate() {
      this.isLoading = true;
      this.hasServerError = false;

      const updateMethod = this.updateMethods[this.selectedView];

      try {
        await updateMethod();
      } catch (error) {
        this.hasServerError = true;
        if (isDevelopment) {
          throw error;
        }
        return;
      } finally {
        this.isLoading = false;
      }

      this.updateSubmitState();
    },

    /**
     * Request an email update.
     */
    async updateEmail() {
      await emailChangeProvider.create(this.emailFields);

      this.submittedEmailChange = true;
      this.isEmailSuccessVisible = true;
      this.newEmail = this.emailFields.email;
      const form = this.$refs.changeEmailForm as ElForm;
      form.resetFields();
    },

    /**
     * Request an password update.
     */
    async updatePassword() {
      await passwordChangeProvider.create(this.fields);

      this.isPasswordSuccessVisible = true;
      this.passwordFormEl?.resetFields();
    },

    isFormChanged(): boolean {
      return (
        !isEqual(this.fields, PASSWORD_ORIGINAL_DATA) ||
        !isEqual(this.emailFields, EMAIL_ORIGINAL_DATA)
      );
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    onConfirmCancel() {
      this.innerVisibility = false;

      const formElement = this.currentForm as ElForm;
      formElement.resetFields();
    },

    tabClickHandler() {
      this.updateSubmitState();
    },

    async resendEmailClickHandler() {
      await resendEmailProvider.create({
        email: this.newEmail,
      });

      this.submittedEmailChange = true;
      this.resentEmailConfirmation = true;
      this.isEmailSuccessVisible = true;
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.passwordFormEl?.validateField(
          "new_password2",
          this.updateSubmitState,
        );
      }
    },
  },
});
