










import Vue from "vue";
import MilestoneDetails, {
  IMilestoneItem,
} from "@/modules/milestone-planner/components/milestone-details/milestone-details.vue";
import PxSidebarDivisor from "@/components/px-sidebar-divisor/px-sidebar-divisor.vue";

export default Vue.extend({
  name: "MilestonesSection",

  components: { PxSidebarDivisor, MilestoneDetails },

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    milestones: {
      type: Array as () => IMilestoneItem[],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
