



































import Vue from "vue";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { ICategory } from "@/services/data/category/category.interface";

export default Vue.extend({
  name: "AssessmentChanges",

  props: {
    prevAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    onlyPrevAssessment: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.get("categories.data");
    },

    prevAssessmentData(): any {
      return this.prevAssessment
        .map((entry: IAssessmentLevel, index: number) => {
          const curEntry = this.currentAssessment[index] as IAssessmentLevel;

          if (curEntry.level === entry.level) {
            return null;
          }

          return {
            ...entry,
            level: entry.level,
          };
        })
        .filter((entry: any) => entry !== null)
        .map((entry: any) => {
          const category = this.categories.find(
            (category: any) => category.id === entry.category,
          );

          return {
            ...entry,
            category,
          };
        });
    },

    isDataMissing(): boolean {
      return (
        this.prevAssessment.length === 0 ||
        this.currentAssessment.length === 0 ||
        this.categories.length === 0
      );
    },

    changes(): any {
      return this.prevAssessment
        .map((entry: IAssessmentLevel, index: number) => {
          const curEntry = this.currentAssessment[index] as IAssessmentLevel;

          if (curEntry.level === entry.level) {
            return null;
          } else if ((curEntry as any).level > (entry as any).level) {
            return {
              ...curEntry,
              raised: true,
            };
          }

          return {
            ...curEntry,
            level: curEntry.level !== null ? curEntry.level : 1,
            raised: false,
          };
        })
        .filter((entry: any) => {
          return entry !== null;
        })
        .map((entry: any) => {
          const category = this.categories.find(
            (category: any) => category.id === entry.category,
          );

          return {
            ...entry,
            category,
          };
        });
    },
  },
});
