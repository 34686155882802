



























import Vue from "vue";

import OfferingsForm from "@/modules/profile/components/offerings-form/offerings-form.vue";

export default Vue.extend({
  name: "OfferingsEditModal",

  components: {
    OfferingsForm,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      formChanges: 0,
      innerVisibility: false,
      hasServerErrors: false,
    };
  },

  computed: {
    hasFormChanged(): boolean {
      return !!this.formChanges;
    },

    dialogConfirmationOptions(): any {
      return [
        {
          eventName: "confirm-cancel-edit",
          showCondition: () => this.hasFormChanged,
          message: this.$t("common.dialogConfirmation.discardMessage"),
          confirmFn: this.onConfirmCancel,
        },
      ];
    },
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onConfirmCancel() {
      this.innerVisibility = false;
      this.formChanges = 0;
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },
  },
});
