
import Vue from "vue";
import { EProfileLatestAssessmentActions } from "@/modules/profile/services/store/profile/submodules/profile-latest-assessment/profile-latest-assessment-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { ILevel } from "@/services/data/level/level.interface";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";
import {
  ENTREPRENEUR_USER_GROUP_ID,
  SUPPORTER_USER_GROUP_ID,
} from "@/modules/common/constants";

export default Vue.extend({
  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    latestAssessment(): IAssessment | null {
      return this.$store.get("profile/latestAssessment/data");
    },

    latestAssessmentResults(): Array<IAssessmentLevel> {
      return this.latestAssessment ? this.latestAssessment.data : [];
    },

    levels(): Array<ILevel> {
      return this.$store.get("levels/data");
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment ? this.latestAssessment.level : null;
    },

    latestAssessmentLevelValue(): number {
      return this.latestAssessmentLevel ? this.latestAssessmentLevel.value : 1;
    },

    isLoading(): boolean {
      return (
        this.$store.get("profile/loading") ||
        this.$store.get("profile/latestAssessment/loading") ||
        this.$store.get("profile/company/loading")
      );
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.latestAssessment
      );
    },

    allowLevelUpdate(): boolean {
      return this.$user.isOwner();
    },

    userGroupId(): number {
      return this.$profile.isEntrepreneur()
        ? ENTREPRENEUR_USER_GROUP_ID
        : SUPPORTER_USER_GROUP_ID;
    },
  },

  watch: {
    company: {
      deep: true,
      immediate: true,
      handler(company) {
        if (!company) return;

        // Fetch categories and levels
        this.$store.dispatch(ECategoryActions.FETCH, {
          group: this.userGroupId,
        });
        this.$store.dispatch(ELevelActions.FETCH, {
          group: this.userGroupId,
        });

        // Fetch latest assessment
        if (this.companyId) {
          this.$store.dispatch(
            EProfileLatestAssessmentActions.FETCH,
            this.companyId,
          );
        }
      },
    },
  },

  methods: {
    makeNewAssessment() {
      this.$store.dispatch(EViralLevelActions.RESET);
      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },
  },

  render(): any {
    if (this.$scopedSlots.default === undefined) {
      return;
    }

    return this.$scopedSlots.default({
      // Computed props
      latestAssessment: this.latestAssessment,
      latestAssessmentResults: this.latestAssessmentResults,
      isLoading: this.isLoading,
      levels: this.levels,
      latestAssessmentLevel: this.latestAssessmentLevel,
      latestAssessmentLevelValue: this.latestAssessmentLevelValue,
      isInactive: this.isInactive,
      allowLevelUpdate: this.allowLevelUpdate,
      companyName: this.companyName,
      // Methods
      makeNewAssessment: this.makeNewAssessment,
    });
  },
});
