









import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MilestonePlanState",

  props: {
    future: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    planned: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: true,
    },
  },

  computed: {
    textStyles(): { [key: string]: string } {
      return {
        color: this.future ? "#8b8fa1" : this.color,
      };
    },

    stateCopy(): TranslateResult {
      return this.completed
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.completed")
        : this.inProgress
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.inProgress")
        : this.planned
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.planned")
        : this.future
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.future")
        : this.$t("milestonePlanner.milestoneGrid.stageStatus.toBePlanned");
    },

    cellStyles(): { [key: string]: string | undefined } {
      const backgroundColor = this.color;
      const backgroundImage = this.backgroundImage;

      return {
        ...(this.hasBorder && this.border),
        ...(this.completed && { backgroundColor }),
        ...(this.inProgress && { backgroundImage }),
      };
    },

    hasBorder(): boolean {
      return (
        !this.completed &&
        (this.planned || this.inProgress || (!this.planned && !this.future))
      );
    },

    border(): {
      borderColor?: string;
      borderStyle: string;
    } {
      const isBorderDashed = !this.planned && !this.future && !this.inProgress;
      const borderStyle = isBorderDashed ? "dashed" : "solid";

      const isBorderColored = this.planned || this.inProgress || !this.future;
      const borderColor = !this.future ? this.color : "#8b8fa1";

      return {
        borderStyle,
        ...(isBorderColored && { borderColor }),
      };
    },

    backgroundImage(): string {
      const inactiveColor = `rgba(${this.hexToRGB("#8b8fa1")}, 0.3)`;
      const color = `rgba(${this.hexToRGB(this.color)}, 0.3)`;

      return !this.future
        ? `linear-gradient(110deg, ${color} 50%, #f4f4f5 50%)`
        : `linear-gradient(110deg, ${inactiveColor} 50%, rgba(39, 39, 64, 0.05) 50%)`;
    },
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },
  },
});
