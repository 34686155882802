







import Vue from "vue";
import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "MilestoneNavigationTab",

  components: {
    PxNavigationTab,
  },

  props: {
    planned: {
      type: Boolean,
      default: false,
    },
    milestoneCompleted: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: String as () => TMilestoneDetailsTab,
      required: false,
      default: MILESTONE_DETAILS_TABS.PLAN,
    },
  },

  data() {
    return {
      innerActiveTab: this.activeTab,
    };
  },

  computed: {
    navigationTabs() {
      return [
        {
          id: MILESTONE_DETAILS_TABS.PLAN,
          title: this.$t("milestonePlanner.milestonePlan.navigationTabs.plan"),
          icon: this.planned ? "plan--planned" : "plan",
        },
        {
          id: MILESTONE_DETAILS_TABS.COMPLETE,
          title: this.$t(
            "milestonePlanner.milestonePlan.navigationTabs.complete",
          ),
          icon: this.milestoneCompleted
            ? "completed-milestone--grey"
            : "flag--grey",
        },
      ];
    },
  },

  watch: {
    innerActiveTab: function (tab: TMilestoneDetailsTab) {
      this.$emit("update:activeTab", tab);
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      this.innerActiveTab = tab;
    },
  },
});
