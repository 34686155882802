















































import Vue from "vue";

import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";

import PxCornerRibbon from "@/components/px-corner-ribbon/px-corner-ribbon.vue";

export default Vue.extend({
  name: "InvestorsPanel",

  components: {
    PxPanelSection,
    PxCornerRibbon,
  },

  data() {
    return {
      selectedTab: "next",
      tabsMobileItems: [
        {
          value: "next",
          label: this.$t("profile.investors.nextMilestones"),
        },
      ],
    };
  },

  computed: {
    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    isInvestorsPanelEnabled(): boolean {
      return (
        process.env.VUE_APP_PROFILE_INVESTORS_PANEL_ENABLED !== "false" || false
      );
    },
  },
});
