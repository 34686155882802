








































import Vue from "vue";

import ShareModalSettingsLink from "@/components/share-modal/share-modal-settings-link.vue";
import ShareModalSettingsPasscode from "@/components/share-modal/share-modal-settings-passcode.vue";

export default Vue.extend({
  name: "ShareModalSettings",

  components: {
    ShareModalSettingsLink,
    ShareModalSettingsPasscode,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    passcodeCopy: {
      type: String,
      default: "milestonePlanner.shareModal.passcode",
    },

    linkCopy: {
      type: String,
      default: "common.shareModal.settings.link",
    },
  },

  data() {
    return {
      currentPasscode: null as null | string,
      canSaveChanges: false,
      hasPasscodeRestriction: false,
      hasChangedPasscodeSetting: false,
    };
  },

  watch: {
    passcode(newValue: string, previousValue: string) {
      if (newValue !== this.currentPasscode || !previousValue.length) {
        this.currentPasscode = newValue;
        this.canSaveChanges = false;
      }
    },

    hasChangedPasscodeSetting(hasChanged: boolean) {
      if (!hasChanged) {
        this.canSaveChanges = hasChanged;
      }
    },
  },

  created() {
    this.currentPasscode = this.passcode;
  },

  methods: {
    onClickBackHandler() {
      this.$root.$emit("toggle-settings", false);
      this.currentPasscode = this.passcode;
      this.hasChangedPasscodeSetting = this.canSaveChanges;
    },

    onClickSaveHandler() {
      const newPasscode = this.hasPasscodeRestriction
        ? this.currentPasscode
        : null;
      this.$emit("save", newPasscode);
      this.canSaveChanges = false;
    },

    changeRestrictionHandler(isRestricted: boolean, newPasscode: string) {
      this.hasPasscodeRestriction = isRestricted;
      this.canSaveChanges =
        (isRestricted && newPasscode !== this.passcode) ||
        (!isRestricted && !!this.passcode);
    },
  },
});
