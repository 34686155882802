










































































































































































































import Vue from "vue";
import ActionModal from "@/modules/supporters/components/action-modal/action-modal.vue";
import CriteriaQuestionPanel from "@/modules/supporters/components/criteria-question-panel/criteria-question-panel.vue";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { supportProvider } from "@/services/data/support/support.provider";
import { SUPPORT_SUBJECT_CRITERIA_SUGGESTION } from "@/services/data/support/support.interface";

export default Vue.extend({
  name: "CriteriaMultiSelectorOptionPanel",

  components: { ActionModal, CriteriaQuestionPanel },

  props: {
    items: {
      type: Array as () => Array<IMultiSelectorItem>,
      required: true,
    },
    selectedItems: {
      type: Array as () => Array<IMatchingResponse>,
      required: true,
    },
    highlightText: {
      type: String,
      required: true,
    },
    highlightItem: {
      type: Object as () => IMultiSelectorItem,
      default: null,
    },
  },

  data() {
    return {
      // Used to force hide a single option component, which is required to the Element UI select component
      // to work as it is expected
      forceElementSelectBehaviour: true,

      isCriteriaQuestionVisible: false,
      focusedCriteriaCategory: null as IMultiSelectorItem | null,
      focusedCriteria: null as IMultiSelectorItem | null,

      // Response fields
      currentResponse: {} as Partial<IMatchingResponse>,
      isSubmitButtonDisabled: true,
      innerSelectedItems: [] as Array<IMultiSelectorItem>,

      // Empty state suggestion modal
      showCriteriaSuggestionModal: false,
      suggestionValue: "",
      maxSuggestionSize: 47,
    };
  },

  computed: {
    visibleChildren(): Array<IMultiSelectorItem> {
      return this.focusedCriteriaCategory?.children || [];
    },

    currentQuestion(): IMatchingQuestion | null {
      return this.focusedCriteria?.meta || null;
    },

    hasCurrentQuestion(): boolean {
      return !!this.currentQuestion;
    },

    flattenItems(): Array<IMultiSelectorItem> {
      return this.items.reduce(
        (accumulator: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => {
          const flatItemChildren =
            item.children?.map((child: IMultiSelectorItem) => ({
              ...child,
              meta: {
                ...child.meta,
                flatLabel: `${item.label} ${child.label}`.toLowerCase(),
              },
            })) || [];

          return [...accumulator, ...flatItemChildren];
        },
        [],
      );
    },

    isHighlightTextActive(): boolean {
      return this.highlightText.length >= 2;
    },

    highlightedTextItems(): Array<IMultiSelectorItem> {
      return this.isHighlightTextActive
        ? this.flattenItems.filter((item: IMultiSelectorItem) =>
            item.meta.flatLabel
              .toString()
              .includes(this.highlightText.toLowerCase()),
          )
        : [];
    },

    hasHighlightedTextItems(): boolean {
      return !!this.highlightedTextItems.length;
    },

    highlightedCountCopy(): string {
      const unityCopy =
        this.highlightedTextItems.length === 1
          ? this.$t("supporters.component.criteriaSelector.result")
          : this.$t("supporters.component.criteriaSelector.results");

      return `${this.highlightedTextItems.length} ${unityCopy}`;
    },

    hasSuggestionValue(): boolean {
      return (
        this.suggestionValue !== "" &&
        this.suggestionValue.length <= this.maxSuggestionSize
      );
    },
  },

  watch: {
    items: {
      immediate: true,
      handler(values: Array<IMultiSelectorItem>) {
        // Select first category is none is selected
        this.focusedCriteriaCategory = !this.focusedCriteriaCategory?.value
          ? values[0] || null
          : this.focusedCriteriaCategory;
      },
    },

    highlightItem: {
      immediate: true,
      deep: true,
      handler(item: IMultiSelectorItem) {
        if (item?.value) {
          this.onCriteriaSelect(item);
        } else {
          this.focusedCriteria = null;
          this.isCriteriaQuestionVisible = false;
        }
      },
    },

    highlightedTextItems: {
      handler(values: Array<IMultiSelectorItem>) {
        if (values.length) {
          this.focusedCriteria = null;
          this.isCriteriaQuestionVisible = false;
        }
      },
    },
  },

  methods: {
    /**
     * Method to show a list of criteria based on the clicked category.
     */
    onCriteriaCategoryClick(category: IMultiSelectorItem) {
      this.focusedCriteriaCategory = category;
    },

    /**
     * Method to show a specific criteria question visible.
     */
    onCriteriaSelect(criteria: IMultiSelectorItem) {
      this.focusedCriteria = criteria;
      const selectedItem = this.selectedItems.find(
        (item: IMatchingResponse) =>
          item.question === this.focusedCriteria?.value,
      );

      if (selectedItem) {
        // Prefill current response from a previously selected item
        this.currentResponse = selectedItem;
      } else if (this.currentResponse) {
        // If the chosen criteria hasn't been selected before
        // and the current response has been previously prefilled
        // we need to make sure to clear it!!
        this.onQuestionClear();
      }

      this.$emit("update:highlightItem", criteria);
      this.isCriteriaQuestionVisible = true;
    },

    /**
     * Set question visibility to false.
     */
    onQuestionClose() {
      this.isCriteriaQuestionVisible = false;
      this.$emit("update:highlightItem", {});
    },

    /**
     * Clear current response.
     */
    onQuestionClear() {
      this.currentResponse = {};
    },

    /**
     * Emit response to saved responses.
     */
    onQuestionSubmit() {
      // In this component we work with three hierarchy degrees (criteria category, question, answer)
      // Only question (child) and answers (grandchildren) are emitted, we don't need to store selected parents as they are not required
      this.$emit("change", { ...this.currentResponse });

      // Trigger clear and return
      this.onQuestionClear();
      this.onQuestionClose();
    },

    /**
     * Runs on response change, fetches submit state.
     */
    validateQuestionSubmitState(isValid: boolean) {
      this.isSubmitButtonDisabled = !isValid;
    },

    /**
     * Highlight option text.
     *
     * @param label
     */
    highlightLabelByQuery(label: string): string {
      if (!this.isHighlightTextActive) {
        return label;
      }

      return label.replace(
        new RegExp(this.highlightText, "gi"),
        `<div class="criteria-multi-selector__highlighted-text">$&</div>`,
      );
    },

    onModalVisibilityToggle() {
      this.showCriteriaSuggestionModal = !this.showCriteriaSuggestionModal;
    },

    async onSuggestionSubmit() {
      await supportProvider.postSuggestion({
        subject: SUPPORT_SUBJECT_CRITERIA_SUGGESTION,
        message: this.suggestionValue,
      });

      // TODO: Maybe add a success message here? Design not specified

      // Toggle modal visibility
      this.onModalVisibilityToggle();
    },
  },
});
