





























































































import Vue from "vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";

export default Vue.extend({
  name: "LevelUpdateModalNote",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inProgressIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.inProgress"),
        icon: EMilestoneStatusType.IN_PROGRESS,
      };
    },
  },
});
