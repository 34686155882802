






import Vue from "vue";

/**
 * Label used to display desired text
 */
export default Vue.extend({
  name: "PxLabel",

  props: {
    color: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
});
