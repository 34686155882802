



































































import Vue from "vue";
import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import PxTableHeader from "@/components/px-table-header/px-table-header.vue";
import PxEmptyMessage from "@/components/px-empty-message/px-empty-message.vue";
import SubmissionsTableRow from "@/modules/submissions/components/submissions-table/submissions-table-row.vue";
import SubmissionsTableRowPlaceholder from "@/modules/submissions/components/submissions-table/submissions-table-row-placeholder.vue";

import { TranslateResult } from "vue-i18n";
import { EAffiliateSubmissionType } from "@/modules/submissions/services/data/submissions/submissions.interface";
import { VILCAP_PROGRAMS_PAGE } from "@/modules/submissions/constants";
import { IAffiliateSubmission } from "@/modules/submissions/services/data/submissions/submissions.interface";

export default Vue.extend({
  name: "SubmissionsPanel",

  components: {
    PxListHeader,
    PxTableHeader,
    PxEmptyMessage,
    SubmissionsTableRow,
    SubmissionsTableRowPlaceholder,
  },

  props: {
    type: {
      type: String,
      default: EAffiliateSubmissionType.DRAFT,
      validator(value: EAffiliateSubmissionType) {
        return Object.values(EAffiliateSubmissionType).includes(value);
      },
    },

    submissionsData: {
      type: Array as () => Array<IAffiliateSubmission>,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isUserSubmissionsView: {
      type: Boolean,
      default: false,
    },

    showEmptyResultsCopy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      numberOfLoadingPlaceholders: 4,
      visibleRows: 4,
      tableScrollAtEnd: false,
      table: (null as any) || null,
    };
  },

  computed: {
    tableTitle(): TranslateResult {
      if (this.type === EAffiliateSubmissionType.DRAFT) {
        return this.isUserSubmissionsView
          ? this.$t(`userSubmissions.table.types[${0}]`)
          : this.$t(`ownedAffiliatesSubmissions.table.types[${0}]`);
      } else {
        return this.isUserSubmissionsView
          ? this.$t(`userSubmissions.table.types[${1}]`)
          : this.$t(`ownedAffiliatesSubmissions.table.types[${1}]`);
      }
    },

    emptyCopy(): TranslateResult {
      if (this.showEmptyResultsCopy) {
        return this.$t(`ownedAffiliatesSubmissions.table.emptyResults`);
      }

      if (this.type === EAffiliateSubmissionType.DRAFT) {
        return this.isUserSubmissionsView
          ? this.$t(
              `userSubmissions.table.emptyState.${EAffiliateSubmissionType.DRAFT}`,
            )
          : this.$t(
              `ownedAffiliatesSubmissions.table.emptyState.${EAffiliateSubmissionType.DRAFT}`,
            );
      } else {
        return this.isUserSubmissionsView
          ? this.$t(
              `userSubmissions.table.emptyState.${EAffiliateSubmissionType.SUBMITTED}`,
            )
          : this.$t(
              `ownedAffiliatesSubmissions.table.emptyState.${EAffiliateSubmissionType.SUBMITTED}`,
            );
      }
    },

    tableHeaders(): TranslateResult {
      return this.isUserSubmissionsView
        ? this.$t("userSubmissions.table.headers")
        : this.$t("ownedAffiliatesSubmissions.table.headers");
    },

    showTableHeader(): boolean {
      return this.isLoading || (!this.isLoading && this.hasSubmissions);
    },

    hasSubmissions(): boolean {
      return !!this.submissionsData.length;
    },

    numberOfSubmissions(): number | null {
      return this.hasSubmissions && !this.isLoading
        ? this.submissionsData.length
        : null;
    },

    isGradientVisible(): boolean {
      return (
        this.submissionsData.length > this.visibleRows && !this.tableScrollAtEnd
      );
    },
  },

  methods: {
    goToProgramsPage() {
      window.open(VILCAP_PROGRAMS_PAGE, "_blank");
    },

    checkTableScroll() {
      this.table = this.$refs.table as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop >=
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
