






























import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "CompleteMilestoneInfoIndicator",

  props: {
    hasEvidence: { type: Boolean, default: false },
    hasAction: { type: Boolean, default: false },
    isVisitor: { type: Boolean, default: false },
  },

  computed: {
    label(): TranslateResult {
      return this.hasEvidence
        ? this.$t("milestonePlanner.milestone.evidenceProvided")
        : this.$t("milestonePlanner.milestone.selfAssessed");
    },

    actionLabel(): TranslateResult {
      return this.isVisitor
        ? this.$t("milestonePlanner.milestone.actions.view")
        : this.hasEvidence
        ? this.$t("milestonePlanner.milestone.actions.review")
        : this.$t("milestonePlanner.milestone.actions.addEvidence");
    },

    iconName(): string {
      return this.hasEvidence
        ? EMilestoneStatusType.EVIDENCE_PROVIDED
        : EMilestoneStatusType.SELF_ASSESSED;
    },
  },
});
