import { render, staticRenderFns } from "./signup-step-company.vue?vue&type=template&id=13cf1220&"
import script from "./signup-step-company.vue?vue&type=script&lang=ts&"
export * from "./signup-step-company.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports