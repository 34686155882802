





























import Vue from "vue";
import { LocaleMessages, TranslateResult } from "vue-i18n";
import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import ListShareUsersSearch from "@/modules/company-lists/components/list-share/list-share-users-search.vue";
import ListShareUsersIndex from "@/modules/company-lists/components/list-share/list-share-users-index.vue";
import ListShareActions from "@/modules/company-lists/components/list-share/list-share-actions.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "ListShareUsers",

  components: {
    ListShareUsersSearch,
    ListShareUsersIndex,
    ListShareActions,
  },

  props: {
    uid: {
      type: String,
      required: true,
    },

    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      usersInvited: [] as Array<ICompanyListInvitedUser["user_profile"]>,
      listHasBeenShared: false,
    };
  },

  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    listLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    listErrors(): AxiosError {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    newInvitedUser(): string | undefined {
      return this.users.find((user) =>
        this.usersInvited.includes(user.user_profile),
      )?.name;
    },

    successShareMessage(): TranslateResult {
      return this.usersInvited.length > 1
        ? this.$t(
            `companyLists.list.shareModal.messages.listSharedMultipleUsers`,
          )
        : this.$t(`companyLists.list.shareModal.messages.listShared`, {
            users: this.newInvitedUser,
          });
    },
  },

  methods: {
    sendEmailHandler(
      invitedUsers: Array<ICompanyListInvitedUser["user_profile"]>,
    ) {
      this.usersInvited = invitedUsers;

      const usersToBeInvited = [
        ...this.usersInvited,
        ...this.users
          .filter((user) => !!user?.user_profile)
          .map((user) => user.user_profile),
      ];

      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          payload: {
            invited_users: usersToBeInvited,
          },
          setLoading: false,
        })
        .then(() => {
          if (!this.listErrors) {
            this.listHasBeenShared = true;
            return;
          }

          // Show generic error message
          this.$message({
            message: this.$t("common.errors.global.alertTitle") as string,
            type: "error",
            customClass: "is-full",
          });
        });
    },
  },
});
