



























































import * as particleRender from "@/utils/particle.render";
import elDialogMixin from "@/mixins/el-dialog.mixin";
import PxLevel from "@/components/px-level/px-level.vue";

export default elDialogMixin.extend({
  components: {
    PxLevel,
  },

  name: "MilestonePlanLevelUpDialog",

  props: {
    categoryName: {
      type: String,
      required: true,
    },
    categoryColor: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  watch: {
    async innerVisibility(newVal) {
      if (!newVal) {
        return;
      }

      await this.$nextTick();
      this.playAnimation();
    },
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },

    playAnimation() {
      const canvasElement = this.$el.querySelector(
        ".milestone-plan-level-up-dialog__animation",
      ) as HTMLCanvasElement;
      const bodyElement = this.$el.querySelector(
        ".el-dialog__body",
      ) as HTMLElement;
      const elementWidth = bodyElement.clientWidth;
      const elementHeight = bodyElement.clientHeight;
      const context = particleRender.createContext(
        canvasElement,
        elementWidth,
        elementHeight,
      );
      const rgbColor = this.hexToRGB(`#${this.categoryColor}`);
      const categoryColors = [
        `rgba(${rgbColor}, 0.6)`,
        `rgba(${rgbColor}, 0.2)`,
        `rgba(${rgbColor}, 0.4)`,
        `rgba(${rgbColor}, 0.603)`,
        `rgba(${rgbColor}, 0.3)`,
      ];
      const centerPoint = particleRender.getCanvasCenterPoint(context);
      particleRender.addConfettiParticles(
        context,
        30,
        centerPoint.x - 10,
        centerPoint.y,
        categoryColors,
      );
      particleRender.render(context);
    },
  },
});
