

































import Vue from "vue";
import { INavbarStep } from "@/components/px-navbar-wizard/px-navbar-wizard.interface";

export default Vue.extend({
  name: "PxNavbarWizard",

  props: {
    steps: {
      type: Array as () => INavbarStep[],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: null,
    },
  },

  computed: {
    currentStep(): INavbarStep | undefined {
      return this.steps.find((step: INavbarStep) =>
        step.routes.some((route) => route === this.$route.name),
      );
    },

    isFinalStep(): boolean {
      const lastStep = [...this.steps].pop() as INavbarStep;

      if (!lastStep) {
        return false;
      }

      return (
        !!this.currentStep && lastStep.labelKey === this.currentStep.labelKey
      );
    },
  },

  methods: {
    getStepIndex(step: INavbarStep): number {
      return this.steps.findIndex(
        (innerStep: INavbarStep) => innerStep.labelKey === step.labelKey,
      );
    },

    isStepDisabled(step: INavbarStep): boolean {
      const stepIndex = this.getStepIndex(step);
      const isPreviousStepCompleted =
        stepIndex > 0 && this.isStepCompleted(this.steps[stepIndex - 1]);

      return (
        !!this.currentStep &&
        !isPreviousStepCompleted &&
        this.getStepIndex(step) > this.getStepIndex(this.currentStep)
      );
    },

    isStepActive(step: INavbarStep): boolean {
      return !!this.currentStep && step.labelKey === this.currentStep.labelKey;
    },

    isStepCompleted(step: INavbarStep): boolean {
      return !!step.isCompleted;
    },

    /**
     * On click go to first route of step
     * @param step
     */
    onStepClick(step: INavbarStep) {
      if (!this.isStepDisabled(step)) {
        this.$router.push({
          name: step.routes[0],
        });
      }
    },
  },
});
