









































































import Vue from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { INetwork } from "@/services/data/network/network.interface";

import NetworksList from "@/modules/profile/components/networks-list/networks-list.vue";
import NetworksEditModal from "@/modules/profile/components/networks-edit-modal/networks-edit-modal.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";

export default Vue.extend({
  name: "NetworksPanel",

  components: {
    NetworksList,
    NetworksEditModal,
    PanelEmptyState,
  },

  data() {
    return {
      isNetworksEditModalVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    isLoading(): boolean {
      return this.$store.get("profile/company.loading");
    },

    networks(): Array<INetwork> {
      return this.company && this.company.networks ? this.company.networks : [];
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },

  methods: {
    onClickEditNetworks() {
      this.isNetworksEditModalVisible = true;
    },

    async fetchCompanyData() {
      if (this.companyId) {
        await this.$store.dispatch(
          EProfileCompanyActions.FETCH,
          this.companyId,
        );
      }
    },
  },
});
