








































































import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { TranslateResult } from "vue-i18n";

export default ElDialogMixin.extend({
  name: "VerifyAccountModal",

  computed: {
    modalImage(): TranslateResult {
      return this.$t("authentication.login.modal.image");
    },

    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },

    accountModalTip(): TranslateResult {
      return this.hasJustResentConfirmation
        ? this.$t("profile.account.verification.linkWait")
        : this.$t("profile.account.verification.link");
    },
  },

  data() {
    return {
      // Time in seconds that a user needs to wait before
      // asking for another email confirmation.
      resendWaitPeriod: 20000,
      hasJustResentConfirmation: false,

      emailClients: [
        {
          name: "Gmail",
          icon: "gmail",
          host: "https://mail.google.com",
        },
        {
          name: "Outlook",
          icon: "outlook",
          host: "https://outlook.live.com",
        },
        {
          name: "Yahoo",
          icon: "yahoo",
          host: "https://mail.yahoo.com",
        },
      ],
    };
  },

  methods: {
    onClickEmailClient(emailClientDomain: string) {
      window.open(emailClientDomain);
    },

    async onClickResendConfirmation() {
      await resendEmailProvider.create({
        email: this.authUserEmail,
      });

      // TODO: Use PxModalMessage instead of Element Message
      // Shows message under Navbar, due to message global context,
      // which will be shown behind modal overlay background
      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-navbar",
      });

      // Renable email resend after the wait period has finished.
      this.hasJustResentConfirmation = true;
      setTimeout(() => {
        this.hasJustResentConfirmation = false;
      }, this.resendWaitPeriod);
    },
  },
});
