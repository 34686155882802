import { RouteConfig } from "vue-router";

import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "./routes-names";
import InitiativeDetails from "@/modules/team-management/views/initiative-details.view.vue";

export const routes: Array<RouteConfig> = [
  {
    path: "/support-equality-initiative",
    name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
    component: InitiativeDetails,
  },
];
