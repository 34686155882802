









































import Vue from "vue";
import anime from "animejs";

import { getElementAbsoluteHeight } from "@/services/utils/html";

import LevelCard from "@/modules/self-assessment/components/level-card/level-card.vue";
import CriteriaModal from "@/modules/self-assessment/components/criteria-modal/criteria-modal.vue";
import {
  ICategoryDetail,
  ICategory,
} from "@/services/data/category/category.interface";
import { EViralLevelGetters } from "@/services/store/viral-level/viral-level-types";

const CARD_SELECTOR = ".mobile-level-select__card";
const CONTAINER_SELECTOR = ".mobile-level-select__container";
const VISIBLE_CARDS_SELECTOR = `${CARD_SELECTOR}.is-visible`;

const ADDITIONAL_TOP_GAP = 20;
const ANIMATION_SCROLL_TIME = 230;

export default Vue.extend({
  name: "MobileLevelSelect",

  components: {
    LevelCard,
    CriteriaModal,
  },

  props: {
    /**
     * Current category details.
     */
    categoryDetails: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * Current selected level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      totalHeight: 0,
      maxHeight: 0,

      criteriaModalVisibility: false,
      selectedCategoryLevel: {},

      categoryChanged: false,
    };
  },

  computed: {
    /**
     * Current selected category.
     */
    currentCategory(): ICategory {
      return this.$store.getters[EViralLevelGetters.CURRENT_CATEGORY];
    },

    /**
     * Order the category details by level, in a descendent way.
     */
    sortedDetails(): Array<ICategoryDetail> {
      const rawDetails = [...(this.categoryDetails as Array<ICategoryDetail>)];
      return rawDetails.sort((a, b) => b.level.value - a.level.value);
    },

    elementStyles(): object {
      return {
        height: `${this.totalHeight}px`,
        maxHeight: `${this.maxHeight}px`,
      };
    },
  },

  watch: {
    /**
     * When the category changes animate the view
     * to positioning the cards correctly.
     */
    async categoryDetails() {
      this.categoryChanged = true;
      this.animateView();
    },

    /**
     * When the level changes animate the view
     * to positioning the cards correctly.
     */
    async currentLevel(newVal, oldVal) {
      // In situations when the category changes but the level keeps
      // the same, when the level changes the animation won't play,
      // so it's required to cancel the `categoryChanged` flag first.
      if (newVal !== oldVal) {
        this.categoryChanged = false;
      }
      // When the category changes the animation is canceled to
      // prevent it to run twice.
      else if (this.categoryChanged) {
        this.categoryChanged = false;
        return;
      }

      this.animateView();
    },
  },

  /**
   * Compute the total height of the view and register
   * a resize event to adjust the view height.
   */
  mounted() {
    this.computeTotalHeight();
    this.computeMaxHeight();

    window.addEventListener("resize", this.animateView);
  },

  /**
   * Before destroy remove the event listener on the
   * resize event.
   */
  beforeDestroy() {
    window.removeEventListener("resize", this.animateView);
  },

  methods: {
    /**
     * Handle the card select change and set the
     * current level
     */
    onCardSelectChange(level: number, checked: boolean) {
      const newLevel = checked ? level : level - 1;
      this.$emit("change", newLevel);
    },

    /**
     * Compute the max height required in order to all
     * visible cards have space on screen.
     */
    computeMaxHeight() {
      if (!this.$el) {
        return;
      }

      const allVisibleCards = [
        ...this.$el.querySelectorAll(VISIBLE_CARDS_SELECTOR),
      ] as Array<HTMLElement>;

      const totalCardsHeight = allVisibleCards.reduce(
        (prevVal, cardsEl) => prevVal + getElementAbsoluteHeight(cardsEl),
        0,
      );

      this.maxHeight = totalCardsHeight + ADDITIONAL_TOP_GAP;
    },

    /**
     * Animate the scroll to go top.
     */
    animateScroll() {
      const scrollCoords = {
        y: window.pageYOffset,
      };

      anime({
        targets: scrollCoords,
        y: 0,
        easing: "linear",
        duration: ANIMATION_SCROLL_TIME,
        update: () => window.scroll(0, scrollCoords.y),
      });
    },

    /**
     * Compute the total element height.
     */
    computeTotalHeight() {
      const containerEl = this.$el.querySelector(
        CONTAINER_SELECTOR,
      ) as HTMLElement;
      this.totalHeight = containerEl.offsetHeight;
    },

    onSeeCriteria(details: ICategoryDetail) {
      this.selectedCategoryLevel = details;
      this.criteriaModalVisibility = true;
    },

    async animateView() {
      await this.$nextTick();

      this.computeMaxHeight();
      this.animateScroll();
    },
  },
});
