var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milestone-form-plan"},[_c('ElForm',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('div',{staticClass:"milestone-form-plan__description"},[_vm._v(" "+_vm._s(_vm.$t("milestonePlanner.milestonePlan.planForm.description.owner"))+" ")]),_c('div',{staticClass:"milestone-form-plan__fields"},[(!_vm.isFutureMilestone)?_c('ElFormItem',{attrs:{"required":false,"prop":"critical"}},[_c('ElCheckbox',{staticClass:"milestone-form-plan__checkbox",model:{value:(_vm.form.critical),callback:function ($$v) {_vm.$set(_vm.form, "critical", $$v)},expression:"form.critical"}},[_c('i18n',{attrs:{"tag":"p","path":"milestonePlanner.milestonePlan.planForm.fields.critical"}},[_c('PxIcon',{staticClass:"milestone-form-plan__critical",attrs:{"slot":"criticalIcon","size":22,"name":"critical-milestone"},slot:"criticalIcon"}),_c('i18n',{attrs:{"slot":"text","tag":"span","path":"milestonePlanner.milestonePlan.planForm.fields.criticalMilestone"},slot:"text"})],1)],1),_c('ElTooltip',{attrs:{"popper-class":"milestone-form-plan__tooltip","placement":"top","content":_vm.criticalTooltip}},[_c('PxIcon',{attrs:{"size":14,"name":"question"}})],1)],1):_vm._e(),_c('ElFormItem',{staticClass:"milestone-form-plan__textarea milestone-form-plan__strategy",attrs:{"label":_vm.$t('milestonePlanner.milestonePlan.planForm.fields.strategy'),"prop":"strategy"}},[_c('PxTextarea',{ref:"strategy",attrs:{"placeholder":_vm.$t(
              'milestonePlanner.milestonePlan.planForm.placeholders.strategy',
              { categoryName: _vm.categoryName }
            ),"max-chars":_vm.rules.strategy.max,"show-counter":""},model:{value:(_vm.form.strategy),callback:function ($$v) {_vm.$set(_vm.form, "strategy", $$v)},expression:"form.strategy"}})],1),_c('ElFormItem',{staticClass:"milestone-form-plan__textarea milestone-form-plan__outcomes",attrs:{"prop":"outcomes"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("milestonePlanner.milestonePlan.planForm.fields.outcomes"))+" "),_c('ElTooltip',{attrs:{"popper-class":"milestone-form-plan__tooltip","placement":"top","content":_vm.outcomesTooltip}},[_c('PxIcon',{attrs:{"size":14,"name":"question"}})],1)],1),_c('PxTextarea',{ref:"outcomes",attrs:{"placeholder":_vm.$t(
              'milestonePlanner.milestonePlan.planForm.placeholders.outcomes',
              { question: _vm.outcomesQuestion }
            ),"max-chars":_vm.rules.outcomes.max,"show-counter":""},model:{value:(_vm.form.outcomes),callback:function ($$v) {_vm.$set(_vm.form, "outcomes", $$v)},expression:"form.outcomes"}})],2),_c('ElFormItem',{staticClass:"milestone-form-plan__textarea milestone-form-plan__resources",attrs:{"label":_vm.$t('milestonePlanner.milestonePlan.planForm.fields.resources'),"prop":"resources"}},[_c('PxTextarea',{ref:"resources",attrs:{"placeholder":_vm.$t(
              'milestonePlanner.milestonePlan.planForm.placeholders.resources',
              { categoryName: _vm.categoryName }
            ),"max-chars":_vm.rules.resources.max,"show-counter":""},model:{value:(_vm.form.resources),callback:function ($$v) {_vm.$set(_vm.form, "resources", $$v)},expression:"form.resources"}})],1),_c('div',{staticClass:"milestone-form-plan__inputs"},[_c('ElFormItem',{staticClass:"milestone-form-plan__numeric",attrs:{"label":_vm.$t(
              'milestonePlanner.milestonePlan.planForm.fields.financesNeeded'
            ),"prop":"financesNeeded"}},[_c('PxMoney',{ref:"financesNeeded",model:{value:(_vm.form.financesNeeded),callback:function ($$v) {_vm.$set(_vm.form, "financesNeeded", $$v)},expression:"form.financesNeeded"}})],1),_c('ElFormItem',{attrs:{"prop":"targetDate"}},[_c('PxDatePicker',{staticClass:"milestone-form-plan__date",attrs:{"value":_vm.form.targetDate,"tooltip":_vm.targetDateTooltip,"label":_vm.$t('milestonePlanner.milestonePlan.planForm.fields.targetDate')},on:{"update:value":function($event){return _vm.$set(_vm.form, "targetDate", $event)}}})],1)],1)],1)]),_c('MilestonePlanConfirmationDialog',{ref:"discardChangesDialog",attrs:{"visibility":_vm.isDiscardingChanges},on:{"update:visibility":function($event){_vm.isDiscardingChanges=$event},"confirmed":_vm.resetForm}}),(_vm.milestone)?_c('MilestonePlanConfirmationDialog',{ref:"deletePlanDialog",attrs:{"visibility":_vm.isDeleting,"is-plan-published":_vm.milestone.plan_published,"planned":true,"is-form-invalid":_vm.formIsInvalid},on:{"update:visibility":function($event){_vm.isDeleting=$event},"confirmed":_vm.deletePlan,"save-as-draft":function($event){return _vm.submitPlan({ toPublish: false })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }