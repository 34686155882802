













































import Vue from "vue";
import set from "lodash/set";

import VisitorJoinBar from "@/modules/profile/components/visitor-join-bar/visitor-join-bar.vue";
import VisitorJoinBarMobile from "@/modules/profile/components/visitor-join-bar-mobile/visitor-join-bar-mobile.vue";
import ProfileDesktop from "@/modules/profile/components/profile-desktop/profile-desktop.vue";
import ProfileMobile from "@/modules/profile/components/profile-mobile/profile-mobile.vue";
import UpgradeModal from "@/modules/common/components/upgrade-modal/upgrade-modal.vue";
import LevelUpdateModal from "@/modules/common/components/level-update-modal/level-update-modal.vue";
import VerifyAccountModal from "@/modules/profile/components/verify-account-modal/verify-account-modal.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";
import SubscriptionModal from "@/modules/profile/components/subscription-modal/subscription-modal.vue";
import VerifyAccountBanner from "@/modules/profile/components/verify-account-banner/verify-account-banner.vue";

import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import {
  PROFILE_META_TAB_MENU_ITEMS,
  PROFILE_META_SUBSCRIPTION_MODAL,
} from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { ElMessageComponent } from "element-ui/types/message";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import {
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_UPDATE_META_UPGRADE,
  QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
} from "@/modules/common/constants";
import { activeModules } from "@/services/utils/utils";
import {
  EUserMetadataGetters,
  EUserMetadataActions,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";

export default Vue.extend({
  name: "ProfileView",

  components: {
    VisitorJoinBar,
    VisitorJoinBarMobile,
    ProfileDesktop,
    ProfileMobile,
    UpgradeModal,
    LevelUpdateModal,
    VerifyAccountModal,
    ListManagementBar,
    SubscriptionModal,
    VerifyAccountBanner,
  },

  data() {
    return {
      modalsVisible: {},
      isUpgradeModalVisibility: false,
      isNewLevelModalVisible: false,
      infoMessage: null as null | ElMessageComponent,
      subscriptionModalVisibility: false,
      verifyAccountBannerVisibility: false,
    };
  },

  computed: {
    idFromParams(): string {
      return this.$route.params.id;
    },

    companyId(): number {
      const company = this.$store.get("auth/company.data");
      return this.idFromParams
        ? this.idFromParams
        : company
        ? company.id
        : null;
    },

    company(): ICompany | null {
      return this.$store.get("profile/company/data");
    },

    isAdminAsUser(): boolean {
      return this.$store.state.auth.isAdmin;
    },

    canMatch(): boolean {
      return (
        this.$features.isEnabled("matchingInterest") &&
        this.$user.isLogged() &&
        !this.$user.isOwner() &&
        this.$profile.type() !== this.$user.getUserAccountType()
      );
    },

    needsToVerifyAccount(): boolean {
      return this.$user.isOwner() && !this.$user.hasVerifiedAccount();
    },

    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },

    isAuthUser(): boolean {
      return this.$user.isLogged();
    },

    showPublicView(): boolean {
      return !this.isAuthUser;
    },

    hasRegistrationListener(): boolean {
      return !!this.$listeners?.register;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    showJoinUsBar(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    viralLevelNotChanged(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_SAME_LEVEL);
    },

    isLevelUp(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_UPGRADE);
    },

    showLevelUpdateModal(): boolean {
      return (
        this.isNewLevelModalVisible &&
        this.$user.isOwner() &&
        this.$user.isEntrepreneur()
      );
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    hasSeenSubscriptionModal(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        PROFILE_META_SUBSCRIPTION_MODAL,
      );
    },
  },

  watch: {
    canMatch() {
      if (this.isMatchingEnabled) {
        this.setUserCanMatchWithProfile();
      }
    },

    company() {
      this.setTabMenuItems();
    },

    needsToVerifyAccount: {
      immediate: true,
      handler(stillPending) {
        if (stillPending) {
          if (this.$user.isEntrepreneur()) {
            this.setVerifyAccountMessage();
          } else {
            this.verifyAccountBannerVisibility = true;
          }
        }
      },
    },
  },

  mounted() {
    setTimeout(() => {
      if (
        this.$features.isEnabled("settingsPage") &&
        this.$user.isLogged() &&
        this.$user.isOwner() &&
        this.$user.isSupporter() &&
        !this.hasSeenSubscriptionModal
      ) {
        this.subscriptionModalVisibility = true;
        this.markAsSeenSubscriptionModal();
      }
    }, 2000);
  },

  beforeDestroy() {
    if (this.infoMessage) {
      this.infoMessage.close();
    }
    this.$root.$off("save-new-assessment", this.onAfterSaveNewLevel);
  },

  created() {
    this.shouldShowNewLevelModal();
    this.setTabMenuItems();
    this.setAdminAsUserMessage();
    if (this.isMatchingEnabled) {
      this.setUserCanMatchWithProfile();
    }
    this.checkQuickLevelUpdate();
  },

  methods: {
    checkQuickLevelUpdate() {
      this.$root.$on("save-new-assessment", this.onAfterSaveNewLevel);
    },

    /**
     * Define the items for the mobile tabs menu.
     */
    setTabMenuItems() {
      const items = this.$profile.getByType(
        "tabMenuItems",
        this.$profile.type(),
      );

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_TAB_MENU_ITEMS,
        value: items,
      });
    },

    /**
     * Set the modal visibility.
     */
    setModalVisible(modal: string) {
      set(this.modalsVisible, modal, true);
    },

    /**
     * Check if the the new level modal or the upgrade modal
     * should be shown based on the quick level update.
     */
    shouldShowNewLevelModal() {
      this.isUpgradeModalVisibility =
        this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT) ||
        false;
      this.isNewLevelModalVisible =
        (this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_ON_UPDATE) &&
          !this.isUpgradeModalVisibility) ||
        false;
    },

    /**
     * If the current level and the previous level are equal
     * should show only a toast message instead of a modal
     */
    shouldShowSameLevelMessage() {
      this.cleanQuickLevelMeta();
      return this.$message({
        message: this.$t(
          "common.components.levelUpdateModal.sameLevelSuccessMessage",
        ) as string,
        type: "success",
        duration: 10000,
        customClass: "is-full",
      });
    },

    /**
     * Handle the user level change.
     *
     * This will request a profile refresh to refresh the page
     * with the new user data.
     */
    onAfterSaveNewLevel() {
      this.$store.dispatch(EProfileCompanyActions.FETCH, this.companyId);
      this.viralLevelNotChanged
        ? this.shouldShowSameLevelMessage()
        : (this.isUpgradeModalVisibility = true);
    },

    /**
     * Cleanup the sore after perform a upgrade operation.
     */
    cleanQuickLevelMeta() {
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_UPGRADE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_ON_UPDATE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
      );
    },

    /**
     * Warn admin that is logged as a user.
     */
    setAdminAsUserMessage() {
      if (this.isAdminAsUser) {
        this.$message({
          message: this.$t(
            "authentication.login.messages.adminAsUser",
          ) as string,
          type: "info",
          customClass: "is-navbar",
          duration: 0,
        });
      }
    },

    setUserCanMatchWithProfile() {
      this.$store.dispatch(
        EMatchingInterestActions.SET_CAN_MATCH,
        this.canMatch,
      );
    },

    setVerifyAccountMessage() {
      if (!this.needsToVerifyAccount || !!this.infoMessage) {
        return;
      }

      const message = this.$t(
        "authentication.login.errors.emailNotValidated",
      ) as string;
      const resendLinkText = this.$t(
        "authentication.login.resendLink",
      ) as string;

      const h = this.$createElement;
      const v = (this as any)._v;
      const vNode = h("p", { staticClass: "el-message__content" }, [
        v(`${message} `),
        h(
          "el-button",
          {
            props: {
              type: "link",
            },
            on: {
              click: this.onClickResendEmail,
            },
            staticClass: "el-button--link-white",
          },
          resendLinkText,
        ),
      ]);

      this.infoMessage = this.$message({
        message: vNode,
        type: "info",
        duration: 0,
        customClass: "is-navbar",
      });
    },

    async onClickResendEmail() {
      if (this.infoMessage !== null) {
        this.infoMessage.close();
      }

      await resendEmailProvider.create({
        email: this.authUserEmail,
      });

      this.$message({
        message: this.$t(
          "authentication.login.messages.confirmationEmail",
        ) as string,
        type: "success",
        customClass: "is-navbar",
      });
    },

    onRegistration() {
      this.$emit("register");
    },

    onShowListManagementModal() {
      if (
        !this.company ||
        (this.showPublicView && this.hasRegistrationListener)
      ) {
        this.onRegistration();
        return;
      }

      // Select self
      this.$store.dispatch(
        EListManagementActions.ADD_COMPANY,
        this.company.uid,
      );

      // Emit root action, should be available on context
      this.$root.$emit("show-list-management-modal");
    },

    markAsSeenSubscriptionModal() {
      this.$store.dispatch(EUserMetadataActions.SET, {
        key: PROFILE_META_SUBSCRIPTION_MODAL,
        value: "true",
      });
    },
  },
});
