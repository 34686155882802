















import Vue from "vue";

import CategoryLevelsAssessmentMobilePlaceholder from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment-mobile-placeholder.vue";
import MobileLevelSelect from "@/modules/affiliates/components/mobile-level-select/mobile-level-select.vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

export default Vue.extend({
  name: "CategoryLevelsAssessmentMobile",

  components: {
    MobileLevelSelect,
    CategoryLevelsAssessmentMobilePlaceholder,
  },

  props: {
    /**
     * Current selected category.
     */
    currentCategory: {
      type: Object as () => ICategory,
      default: () => null,
    },

    /**
     * Current selected category level.
     */
    selectedStep: {
      type: Object as () => ICategoryLevelSelection,
      default: () => null,
    },

    /**
     * Handler to be executed when the category
     * level changes.
     */
    onLevelChange: {
      type: Function as () => any,
      required: true,
    },
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.affiliates.loading;
    },
  },
});
