

































import Vue from "vue";

import { HOME } from "@/services/router/router-names";
import { isProduction } from "@/services/utils/utils";

export default Vue.extend({
  name: "PxNavBarMinimal",

  computed: {
    mainLogo(): string {
      return "/img/logos/abaca-logo--white.svg";
    },

    logoLink(): any {
      return !this.$user.isLogged() && isProduction
        ? "https://abaca.app"
        : HOME;
    },
  },
});
