import { IMatchingFilters } from "@/mixins/matching-list.mixin";

/**
 * A util function to extract the value from a matching
 * filter either with a single value or multiple values.
 *
 * Filters with object(s) as value(s) can be extracted
 * by using a filter key.
 *
 * @param filterInstance IMatchingFilters[0]
 */
export const extractValuesFromFilter = (
  filterInstance: IMatchingFilters[0],
): Array<string> => {
  const filterKey = filterInstance?.key || "";
  const hasKey = !!filterKey.length;
  const hasMultiValues = !!filterInstance?.values?.length;
  const hasSingleValue = !!filterInstance?.value?.length;

  return hasKey && hasMultiValues
    ? // Grab all values from list of objects using the filter key
      filterInstance?.values?.map((v: any) => v[filterKey] || "") || []
    : hasSingleValue
    ? // Access directly the filter value
      [filterInstance.value]
    : [];
};
