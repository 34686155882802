









































































import Vue from "vue";
import { LocaleMessages, LocaleMessageArray } from "vue-i18n";

import {
  COMPANY_LIST_TYPES,
  COMPANY_LIST_FILTERS,
} from "@/modules/company-lists/constants";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import CompanyLists from "@/modules/company-lists/components/company-lists/company-lists.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";
import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";
import { CompanySharedListState } from "@/modules/company-lists/services/store/company-shared-list/company-shared-list.module";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { EMetaActions, EMetaGetters } from "@/services/store/meta/meta-types";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";

export interface ICompanyListsFilter {
  [key: string]: {
    // Object key to extract filter value(s)
    key?: string;
    // Single value filter
    value?: string;
  };
}

export default Vue.extend({
  name: "ListsDirectory",

  components: {
    CompanyLists,
    ListManagementBar,
    MatchingConfirmModal,
  },

  data() {
    return {
      // Freeze property to avoid uneccessary reactivity.
      listTypes: Object.freeze(COMPANY_LIST_TYPES),
      selectedTab: COMPANY_LIST_TYPES.OWNED,
      latestAssessment: null as IAssessment | null,
      hasLocation: false,
      hasSectors: false,
      isMatchingConfirmModalVisible: false,
      filters: {
        list: {
          value: "",
        },
      } as ICompanyListsFilter,
    };
  },

  computed: {
    tabsStyle(): { [key: string]: boolean } {
      return {
        "el-tabs--dark-line": this.$screen.smDown,
        "el-tabs--blue-line": this.$screen.mdUp,
      };
    },

    directoryCopy(): LocaleMessages {
      return this.$t("companyLists.directory") as LocaleMessages;
    },

    /**
     * True if we are loading data from the API.
     */
    isLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    /**
     * True if we are loading data from the API.
     */
    isSharedLoading(): boolean {
      return this.$store.get(CompanySharedListState.Getter.IS_LOADING);
    },

    /**
     * List of company lists owned by the user.
     */
    ownedCompanyLists(): Array<ICompanyList> {
      return this.$store.get(CompanyListState.Getter.VALUES) || [];
    },

    /**
     * List of company lists shared with the user.
     */
    sharedLists(): Array<ICompanyList> {
      return this.$store.get(CompanySharedListState.Getter.VALUES) || [];
    },

    /**
     * List of company lists owned by the user and filtered by the name search
     */
    ownedFilteredLists(): Array<ICompanyList> {
      return this.ownedCompanyLists.filter((list) =>
        list.title.toLowerCase().includes(this.listNameToSearch.toLowerCase()),
      );
    },

    /**
     * List of company lists shared with the user and filtered by the name search
     */
    sharedFilteredLists(): Array<ICompanyList> {
      return this.sharedLists.filter((list) =>
        list.title.toLowerCase().includes(this.listNameToSearch.toLowerCase()),
      );
    },

    ownedListsToDisplay(): Array<ICompanyList> {
      return this.hasStoredSelectedFilters
        ? this.ownedFilteredLists
        : this.ownedCompanyLists;
    },

    sharedListsToDisplay(): Array<ICompanyList> {
      return this.hasStoredSelectedFilters
        ? this.sharedFilteredLists
        : this.sharedLists;
    },

    listNameToSearch(): string {
      return this.storedSelectedFilters?.list.value || "";
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment?.level || null;
    },

    hasAssessmentLevel(): boolean {
      return this.latestAssessmentLevel !== null;
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasAssessmentLevel && !!this.hasLocation && !!this.hasSectors
      );
    },

    onlySmartListsCreated(): boolean {
      return (
        !!this.ownedCompanyLists.length &&
        !this.ownedCompanyLists.some(
          (list: ICompanyList) => list.is_smart_list !== true,
        )
      );
    },

    noListsToDisplay(): boolean {
      return (
        !this.hasStoredSelectedFilters &&
        !this.ownedListsToDisplay.length &&
        !this.sharedListsToDisplay.length
      );
    },

    sharedEmptyListsTitle(): LocaleMessageArray {
      return this.hasStoredSelectedFilters &&
        !this.sharedFilteredLists.length &&
        this.sharedLists.length
        ? this.directoryCopy.empty.filteringTitle
        : "";
    },

    ownedEmptyListsTitle(): LocaleMessageArray {
      return this.hasStoredSelectedFilters &&
        !this.ownedFilteredLists.length &&
        this.ownedCompanyLists.length
        ? this.directoryCopy.empty.filteringTitle
        : "";
    },

    sharedEmptyListsSubtitle(): LocaleMessageArray {
      return this.hasStoredSelectedFilters &&
        !this.sharedFilteredLists.length &&
        this.sharedLists.length
        ? this.directoryCopy.empty.filteringSubtitle
        : this.directoryCopy.empty.sharedWithMe;
    },

    ownedEmptyListsSubtitle(): LocaleMessageArray {
      return this.hasStoredSelectedFilters &&
        !this.ownedFilteredLists.length &&
        this.ownedCompanyLists.length
        ? this.directoryCopy.empty.filteringSubtitle
        : this.onlySmartListsCreated
        ? this.directoryCopy.empty.smartList
        : this.directoryCopy.empty.myLists;
    },

    storedSelectedFilters(): ICompanyListsFilter | null {
      return (
        this.$store.getters[EMetaGetters.GET](COMPANY_LIST_FILTERS) || null
      );
    },

    hasStoredSelectedFilters(): boolean {
      return (
        !!this.storedSelectedFilters &&
        Object.keys(this.storedSelectedFilters)
          .map(
            (k: any) => (this.storedSelectedFilters as ICompanyListsFilter)[k],
          )
          .some((k: any) => !!k?.value?.length)
      );
    },
  },

  watch: {
    filters: {
      deep: true,
      handler: debounce(function (this: any, newFilters: ICompanyListsFilter) {
        if (!newFilters) return;

        const newFiltersSelection = newFilters ? cloneDeep(newFilters) : null;
        this.onFiltersChangeHandler(newFiltersSelection);
      }, 600),
    },

    ownedCompanyLists: {
      deep: true,
      handler(ownedLists: Array<ICompanyList>) {
        if (!ownedLists.length && !this.sharedLists.length) {
          this.filters.list.value = "";
        }
      },
    },

    sharedLists: {
      deep: true,
      handler(sharedLists: Array<ICompanyList>) {
        if (!sharedLists.length && !this.ownedCompanyLists.length) {
          this.filters.list.value = "";
        }
      },
    },
  },

  async mounted() {
    await this.fetchLists();
  },

  created() {
    this.latestAssessment = this.$store.get("auth/latestAssessment/data");

    this.prefillAuthData();

    this.prefillFilters();

    // Matching modal should be visible when the user is an entrepreneur and has not level OR location OR sectors defined
    this.isMatchingConfirmModalVisible =
      !this.hasMatchingMandatoryData && this.$user.isEntrepreneur();
  },

  beforeDestroy() {
    this.latestAssessment = null;
    this.$store.dispatch(EMetaActions.REMOVE, COMPANY_LIST_FILTERS);
  },

  methods: {
    onFiltersChangeHandler(newFilter: ICompanyListsFilter) {
      this.$store.dispatch(EMetaActions.SET, {
        key: COMPANY_LIST_FILTERS,
        value: newFilter,
      });
    },

    prefillFilters() {
      if (!this.storedSelectedFilters) return;

      this.filters = { ...this.filters, ...this.storedSelectedFilters };
    },

    prefillAuthData() {
      this.hasLocation =
        !!this.$store.get("auth/company.data").locations.length;
      this.hasSectors = !!this.$store.get("auth/company.data").sectors.length;
    },

    async fetchLists() {
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
      await this.$store.dispatch(CompanySharedListState.Action.GET_VALUES);
    },

    newListClickHandler() {
      // Open New List Modal
      this.$root.$emit("show-list-management-modal", {
        tab: EListManagementModalTab.CREATE,
      });
    },

    removeInitialWhiteSpaces() {
      if (
        this.filters.list.value &&
        this.filters.list.value.length &&
        !this.filters.list.value.trim()
      ) {
        this.filters.list.value = this.filters.list.value.trim();
      }
    },
  },
});
