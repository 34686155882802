























































import Vue from "vue";
import MilestoneFormPlan from "@/modules/milestone-planner/components/milestone-form/milestone-form-plan.vue";
import MilestoneNavigationTab from "@/modules/milestone-planner/components/milestone-navigation-tab/milestone-navigation-tab.vue";
import MilestoneFormComplete from "@/modules/milestone-planner/components/milestone-form/milestone-form-complete.vue";
import MilestonePlanStateBadge from "@/modules/milestone-planner/components/milestone-plan-state-badge/milestone-plan-state-badge.vue";
import MilestoneFormGuestView from "@/modules/milestone-planner/components/milestone-form/milestone-form-guest-view.vue";

import { TranslateResult } from "vue-i18n";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import { IMilestone } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import {
  IMatchingQuestion,
  FREE_RESPONSE,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export default Vue.extend({
  name: "MilestoneForm",

  components: {
    MilestoneFormPlan,
    MilestoneNavigationTab,
    MilestoneFormComplete,
    MilestonePlanStateBadge,
    MilestoneFormGuestView,
  },

  props: {
    milestone: {
      type: Object as () => IMilestone | null,
      default: null,
    },
    categoryLevel: {
      type: Object as () => ICategoryDetail | null,
      default: null,
      required: true,
    },
    questionBundles: {
      type: Array as () => Array<IQuestionBundle>,
      default: null,
      required: true,
    },
    category: {
      type: Object as () => IGridCategory,
      default: null,
      required: true,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    completeFormHasChanges: {
      type: Boolean,
      default: false,
    },
    planLastUpdate: {
      type: String,
      default: "",
    },
    completeLastUpdate: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String as () => TMilestoneDetailsTab,
      default: MILESTONE_DETAILS_TABS.PLAN,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerActiveTab: this.activeTab,
    };
  },

  computed: {
    stateLabel(): TranslateResult {
      return this.isPlanTabActive
        ? this.planStateLabel
        : this.completeStateLabel;
    },

    planStateLabel(): TranslateResult {
      return this.isPlanPublished
        ? this.$t("milestonePlanner.milestoneGrid.stageStatus.planned")
        : this.isMilestonePlanned
        ? this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
        : this.hasCompletionInfo
        ? this.$t("milestonePlanner.milestonePlan.planForm.badges.notPlanned")
        : "";
    },

    completeStateLabel(): TranslateResult {
      return this.isMilestoneComplete
        ? this.$t("milestonePlanner.milestonePlan.planForm.badges.completed")
        : this.hasCompletionInfo
        ? this.$t("milestonePlanner.milestonePlan.planForm.badges.draft")
        : "";
    },

    lastUpdate(): string {
      return this.isPlanTabActive
        ? this.planLastUpdate
        : this.completeLastUpdate;
    },

    isPlanTabActive() {
      return this.activeTab === MILESTONE_DETAILS_TABS.PLAN;
    },

    milestoneQuestions(): Array<IMatchingQuestion> {
      return this.questionBundles.reduce(
        (bundledQuestions: Array<IMatchingQuestion>, currentBundle) => [
          ...bundledQuestions,
          ...currentBundle.questions.filter(
            // Temporarily allow only questions of type free response:
            (question) => question.question_type.type === FREE_RESPONSE,
          ),
        ],
        [],
      );
    },

    evidenceQuestion(): IMatchingQuestion | null {
      return this.milestoneQuestions.length ? this.milestoneQuestions[0] : null;
    },
  },

  watch: {
    innerActiveTab: function (tab: TMilestoneDetailsTab) {
      this.$emit("update:activeTab", tab);
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      this.innerActiveTab = tab;
      this.checkPlanChanges(false);
      this.checkCompleteFormChanges(false);
    },
  },

  methods: {
    checkPlanIsComplete(isComplete: boolean) {
      this.$emit("update:planFormIsComplete", isComplete);
    },

    checkPlanChanges(hasChanges: boolean) {
      this.$emit("update:planFormHasChanges", hasChanges);
    },

    checkPlanIsInvalid(isInvalid: boolean) {
      this.$emit("update:planFormIsInvalid", isInvalid);
    },

    checkCompleteFormChanges(hasChanges: boolean) {
      this.$emit("update:completeFormHasChanges", hasChanges);
    },

    checkCompleteIsInvalid(isInvalid: boolean) {
      this.$emit("update:completeFormIsInvalid", isInvalid);
    },
  },
});
