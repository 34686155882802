





















import Vue from "vue";

import ChartsTable from "@/modules/charts-dashboard/components/common/charts-table/charts-table.vue";
import ChartsComparisonTable, {
  IChartsComparisonTableHeader,
} from "@/modules/charts-dashboard/components/common/charts-comparison-table/charts-comparison-table.vue";

import { ChartData } from "chart.js";
import { IProcessReportsChartData } from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";

export default Vue.extend({
  name: "ChartsTableWrapper",

  components: {
    ChartsTable,
    ChartsComparisonTable,
  },

  props: {
    labels: {
      type: Array as () => string[],
      required: true,
    },

    colors: {
      type: Array as () => string[],
      required: true,
    },

    theme: {
      type: String,
      required: true,
    },

    data: {
      type: Array as () => IProcessReportsChartData[],
      default: () => [] as IProcessReportsChartData[],
    },

    dataToCompare: {
      type: Object as () => Partial<ChartData>,
      default: null,
    },
  },

  computed: {
    tableValues(): Array<any> {
      return this.labels.map((label) => {
        const labelData = this.data.map((listData) => {
          const response = listData.responses.find(
            (response: any) => response.value === label,
          );
          return {
            count: response?.count || 0,
            percentage: response ? Number(response.percentage.toFixed(2)) : 0,
          };
        });

        const count = labelData.map((entry) => entry.count);
        const percentage = labelData.map((entry) => entry.percentage);

        return { label, count, percentage };
      });
    },

    tableTotal(): Array<number> {
      return this.tableValues.map((entry: any) => {
        return entry.count.reduce((acc: number, value: number) => {
          return acc + value;
        }, 0);
      });
    },

    tablePercentages(): Array<number> {
      const total = this.tableTotal.reduce((acc: number, value: number) => {
        return acc + value;
      }, 0);

      return this.tableTotal.map((value: number) => {
        return Math.round((value / total) * 1000) / 10;
      });
    },

    enabledSteps(): number {
      return this.data.filter((data) => !data.is_locked).length;
    },

    tableAverage(): Array<number> | null {
      return this.enabledSteps > 1
        ? this.tableValues.map((entry) =>
            parseFloat(
              (
                (entry.percentage.reduce(
                  (acc: number, value: number) => acc + value,
                  0,
                ) /
                  this.enabledSteps) *
                100
              ).toFixed(1),
            ),
          )
        : null;
    },

    comparisonTableHeaders(): IChartsComparisonTableHeader[] {
      const labels = this.dataToCompare?.labels as string[];

      if (!labels) return [];

      const headers = [] as IChartsComparisonTableHeader[];

      headers.push({
        title: this.theme,
      });
      headers.push({ title: this.getHeaderTitle(0, labels?.[0]) });
      headers.push({ title: this.getHeaderTitle(1, labels?.[0]) });

      const secondLabel = labels?.[1];
      if (secondLabel) {
        headers.push({ title: this.getHeaderTitle(0, secondLabel) });
        headers.push({ title: this.getHeaderTitle(1, secondLabel) });
      } else {
        headers.push({
          title: this.$t(
            "chartsDashboard.comparisonTable.secondStage.title",
          ) as string,
          tooltip: this.$t(
            "chartsDashboard.comparisonTable.secondStage.tooltip",
          ) as string,
        });
        headers.push({
          title: "-",
        });
      }

      headers.push({
        title: this.$t("chartsDashboard.comparisonTable.headers[2]") as string,
        tooltip:
          labels.length === 2
            ? (this.$t("chartsDashboard.comparisonTable.changeTooltip", {
                step1: secondLabel,
                step2: labels?.[0],
              }) as string)
            : (this.$t(
                "chartsDashboard.comparisonTable.secondStage.tooltip",
              ) as string),
      });

      return headers;
    },
  },

  methods: {
    getHeaderTitle(index: number, label: string): string {
      return this.$t(`chartsDashboard.comparisonTable.headers[${index}]`, {
        step: label,
      }) as string;
    },
  },
});
