





































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "ListManagmentConfirmationDialog",

  static: {
    cancelButton: {
      type: EPxButtonType.LINK,
    },

    removeButton: {
      type: EPxButtonType.RED,
    },

    ctaSize: EPxButtonSize.MEDIUM,

    cancelCtaType: EPxButtonType.LINK,
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },

    selectedCompanyName: {
      type: String,
      default: "",
    },

    selectedCompanies: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    dialogTitle(): TranslateResult {
      return this.selectedCompanies.length > 1
        ? (this.$t("matching.matchingList.areYouSureMembers") as string)
        : (this.$t("matching.matchingList.removalConfirmationText", {
            companyName: this.selectedCompanyName,
          }) as string);
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel") as string,
      };
    },

    removeButtonProps(): any {
      return {
        ...this.$options.static.removeButton,
        label: this.$t("common.components.default.cta.removeMembers") as string,
      };
    },

    removeConfirmationButtonCopy(): string {
      return this.$t("common.components.default.cta.yesRemove") as string;
    },
  },

  watch: {
    visibility(value) {
      this.innerVisibility = value;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-options");
      this.$emit("update:visibility", false);
    },

    async onRemoveConfirmationClick() {
      // Hide confirmation and footer
      this.closeModal();

      // Emit remove companies from list
      this.$emit("remove-companies");
    },
  },
});
