





































import Vue from "vue";

export default Vue.extend({
  name: "MatchingBadgeModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    items: {
      type: Array as () => any[],
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  watch: {
    visibility: {
      immediate: true,
      async handler(newVal: boolean) {
        this.innerVisibility = newVal;

        if (!newVal) {
          return;
        }
      },
    },

    innerVisibility(newVal: boolean, oldVal: boolean) {
      if (newVal === oldVal) {
        return;
      }

      if (!newVal) {
        this.$emit("update:visibility", newVal);
      }
    },
  },

  methods: {
    onLinkClick(link: any) {
      this.$router.push({ name: link.name, params: link.params });
    },
  },
});
