


















































import Vue from "vue";

import { IStepItem } from "@/modules/self-assessment/types/step-item.interface";
import { colorShade } from "@/utils/color-shade";

const COLOR_VARIATION_AMOUNT = -20;
const DESIGN_LEFT_SPACE = 3;

export default Vue.extend({
  name: "CategoryWizard",

  props: {
    steps: {
      type: Array as () => any[],
      required: true,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    active: {
      type: Number,
      default: null,
    },
    checked: {
      type: Array as () => any[],
      default: () => [],
    },
    /**
     * Disable the user interaction with the wizard.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxIndexRetched: 0,
    };
  },

  computed: {
    /**
     * Computes the higher checked index.
     */
    higherCheckedIndex() {
      const checkedSteps: Array<number> = this.checked;
      if (checkedSteps.length === 0) {
        return -1;
      }

      let higherIndex = -1;
      checkedSteps.forEach((item: number) => {
        const index = this.steps.findIndex(
          (step: IStepItem) => step.value === item,
        );
        higherIndex = Math.max(index, higherIndex);
      });

      return higherIndex;
    },

    /**
     * Return the progress bar width based on the higher checked step.
     */
    progressStyle() {
      const perStep = 100 / this.steps.length + 1;
      const target = Math.max(this.higherCheckedIndex, this.currentStep);
      const currentProgress = perStep * target + DESIGN_LEFT_SPACE;

      return {
        width: `${currentProgress}%`,
      };
    },
  },

  watch: {
    /**
     * When the current step changes for a higher value the
     * maxIndexRetched is updated.
     */
    currentStep(newVal, oldVal) {
      const curMaxIndexRetched = this.maxIndexRetched;
      const possibleNewValue = Math.max(newVal, oldVal);

      this.maxIndexRetched = Math.max(curMaxIndexRetched, possibleNewValue);
    },

    /**
     * Set the maxIndexRetched when the steps changes.
     */
    steps: {
      immediate: true,
      handler() {
        this.maxIndexRetched = Math.max(
          this.higherCheckedIndex,
          this.currentStep,
        );
      },
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: IStepItem): boolean {
      return this.checked.includes(step.value);
    },

    /**
     * Generates the circle colors for the given step.
     */
    stepCircleStyle(step: IStepItem): { [key: string]: string } {
      const stepColor = `#${step.color}`;
      const newBorderColor = colorShade(stepColor, COLOR_VARIATION_AMOUNT);

      return {
        "--lightColor": stepColor,
        "--darkColor": newBorderColor,
      };
    },

    /**
     * Handles the step clicks.
     *
     * The clicks are ignored when the user clicks on a step
     * that surpasses the maximum reached index plus the
     * next one.
     */
    stepClickHandler(index: number) {
      if (index > this.maxIndexRetched + 1) {
        return false;
      }

      this.$emit("update:currentStep", index);
      this.$emit("changed", index);
    },
  },
});
