import Vue from "vue";

import {
  EAffiliatesActions,
  EAffiliatesMutations,
  IAffiliatesState,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { ROUTE_USER_SUBMISSIONS } from "@/modules/profile/services/router/routes-names";
import { defaultAffiliateId } from "@/services/configs";
import { SELF_ASSESSMENT_SKIP_LANDING_PAGE } from "@/modules/self-assessment/constants";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { AFFILIATE_TARGET_ENTREPRENEUR } from "@/services/data/affiliate/affiliate.interface";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EAuthLatestAssessmentActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { EAffiliateSteps } from "@/modules/affiliates/views/affiliate.types";
import moment from "moment";

export default Vue.extend({
  name: "AffiliateTypesMixin",

  data() {
    return {
      resultsPageVisible: false,
      submitDisabled: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyId(): string | null {
      return this.company ? this.company.id.toString() : null;
    },

    isDefaultAffiliate(): boolean {
      return (
        !!defaultAffiliateId() &&
        this.affiliatesData.affiliate?.id === defaultAffiliateId()
      );
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    error(): any {
      return this.affiliatesData.error;
    },

    affiliateDataLoading(): boolean {
      return this.affiliatesData.loading;
    },

    affiliateWebsite(): string | null {
      return this.affiliatesData.affiliate?.website || null;
    },

    affiliateLogo(): string | null {
      return !this.isDefaultAffiliate && this.affiliatesData.affiliate?.logo
        ? this.affiliatesData.affiliate.logo
        : null;
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.affiliatesData.draftSubmission?.data.assessment || [];
    },

    isAssessmentFirstCategory(): boolean {
      return this.selectedAssessmentCategory === 0;
    },

    affiliateFlowTarget(): number {
      return (
        this.affiliatesData.affiliate?.flow_target ||
        AFFILIATE_TARGET_ENTREPRENEUR
      );
    },

    isEntrepreneurAffiliate(): boolean {
      return this.affiliateFlowTarget === AFFILIATE_TARGET_ENTREPRENEUR;
    },

    isAssessmentLastCategory(): boolean {
      return this.isEntrepreneurAffiliate
        ? this.selectedAssessmentCategory === this.assessmentLevels.length - 1
        : this.selectedAssessmentCategory === 1;
    },

    selectedAssessmentCategory(): number {
      return this.affiliatesData.selectedAssessmentCategory;
    },

    shouldSkipStartingPage(): boolean {
      return (
        this.$route.query &&
        this.$route.query.l === SELF_ASSESSMENT_SKIP_LANDING_PAGE
      );
    },

    submissionInProgress(): boolean {
      return !!this.affiliatesData.draftSubmission?.updated_at;
    },

    updatedDate(): string {
      const updated_at = this.$store.get(
        "affiliates/draftSubmission",
      )?.updated_at;

      return updated_at ? moment(updated_at).format("MMM D, YYYY hh:mm") : "";
    },

    saving(): boolean {
      return this.affiliatesData.saving;
    },

    currentStep: {
      get() {
        return this.$store.state.affiliates.currentStep;
      },
      set(value) {
        this.$store.commit(
          `affiliates/${EAffiliatesMutations.SET_CURRENT_STEP}`,
          value,
        );
      },
    },

    selectedQuestion(): number {
      return this.affiliatesData.selectedQuestion;
    },

    isPendingUser(): boolean {
      return !!this.affiliatesData.pendingUser;
    },
  },

  watch: {
    selectedAssessmentCategory() {
      this.saveDraft();
    },

    currentStep() {
      this.saveDraft();
    },

    selectedQuestion() {
      this.saveDraft();
    },

    isPendingUser() {
      this.saveDraft();
    },
  },

  methods: {
    async submitAffiliate() {
      await this.$store.dispatch(EAffiliatesActions.SAVE_DRAFT);
      await this.$store.dispatch(EAffiliatesActions.SUBMIT);

      if (!this.error) {
        this.resultsPageVisible = true;

        if (this.companyId) {
          this.$store.dispatch(
            EAuthLatestAssessmentActions.FETCH,
            this.companyId,
          );
        }

        this.$store.dispatch(
          EViralLevelActions.SET_LEVELS,
          this.assessmentLevels,
        );
        this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
          skipLoading: true,
        });
      } else {
        this.showErrorMessage();
      }
    },

    async saveDraft() {
      await this.$store.dispatch(EAffiliatesActions.DEBOUNCE_SAVE_DRAFT);
    },

    showSavedDraftMessage() {
      if (
        this.currentStep == EAffiliateSteps.STARTING_PAGE ||
        this.resultsPageVisible
      ) {
        return;
      }

      const message = this.$t("affiliates.drafts.saving.text") as string;
      const action = this.$t("affiliates.drafts.saving.action") as string;

      const h = this.$createElement;
      const v = (this as any)._v;
      const vNode = h("p", { staticClass: "el-message__content" }, [
        v(`${message} `),
        h(
          "el-button",
          {
            props: {
              type: "link",
            },
            on: {
              click: this.goToSubmissionsPage,
            },
            staticClass: "el-button--link-white",
          },
          action,
        ),
      ]);

      this.$message({
        message: vNode,
        type: "success",
        duration: 1500,
        customClass: "is-full",
      });
    },

    goToSubmissionsPage() {
      return this.$router.push({ name: ROUTE_USER_SUBMISSIONS });
    },

    showErrorMessage() {
      this.$message({
        message: this.$t("affiliates.errors.missingData") as string,
        type: "error",
        duration: 2500,
        customClass: "is-full",
      });
    },
  },
});
