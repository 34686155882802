import Vue, { CreateElement } from "vue";

Vue.config.productionTip = false;

/*
 * Loads global application style.
 * --------------------------------------------------- */
import "@/styles/main.scss";

/*
 * Enable Bugsnag on the project.
 * --------------------------------------------------- */
import { bugsnagStart } from "@/plugins/bugsnag.plugin";

/*
 * Load Vue Content Placeholders lib.
 * --------------------------------------------------- */
import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders);

/*
 * Load Element UI components.
 * --------------------------------------------------- */
import "@/plugins/element-ui.plugin";

/*
 * Register global components.
 * --------------------------------------------------- */
import "@/components/components.init";

/*
 * Register global directives..
 * --------------------------------------------------- */
import "@/directives/directives.init";

/*
 * Register global filters.
 * --------------------------------------------------- */
import "@/filters/filters.init";

/*
 * Load Vue MutationObserver plugin.
 * --------------------------------------------------- */
import "@/plugins/mutation-observer.plugin";

/*
 * Load Prevent Scale plugin.
 * --------------------------------------------------- */
import "@/plugins/prevent-scale.plugin";

/*
 * Load Moment plugin.
 * --------------------------------------------------- */
import "@/plugins/moment.plugin";

/*
 * Load Currency plugin and register global filter.
 * --------------------------------------------------- */
import "@/plugins/currency.plugin";

/*
 * Load Touch Events plugin.
 * --------------------------------------------------- */
import "@/plugins/vue2-touch-events.plugin";

/*
 * Load Observe Visibility plugin.
 * --------------------------------------------------- */
import "@/plugins/vue-observe-visibility.plugin";

/*
 * Initialize project services.
 * --------------------------------------------------- */
import "@/services/services.init";
import { servicesToRegister } from "@/services/services.init";

/*
 * Initialize active modules.
 * --------------------------------------------------- */
import { initModules } from "@/modules/modules.init";
import App from "@/views/App.vue";

const loadSequentially = async () => {
  // Initialize all modules before continue, otherwise
  // it can result in weird behavior
  await initModules();
};

loadSequentially().then(() => {
  new Vue({
    ...servicesToRegister,
    el: "#app",
    render: (h: CreateElement) => h(App),
  });

  // Start vue instance dependent plugins
  bugsnagStart();
});

/*
 * Prevent Abaca pages from being served from bfcache
 * --------------------------------------------------- */
window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    window.location.reload();
  }
});
