





































































import Vue from "vue";

import MatchingListCards from "@/modules/matching/components/matching-list-cards/matching-list-cards.vue";
import MatchingListInterests from "@/modules/matching/components/matching-list-interests/matching-list-interests.vue";
import MatchingListInterestMutual from "@/modules/matching/components/matching-list-interests/matching-list-interests-mutual.vue";
import MatchingSidebar from "@/modules/matching/components/matching-sidebar/matching-sidebar.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { MATCHING_SELECTED_TAB } from "@/modules/matching/constants";
import { IMatchingScore } from "@/modules/matching/services/data/matching-score/matching-score.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { Dictionary } from "vue-router/types/router";
import {
  EMatchingListTabs,
  EMatchingListPendingTabs,
} from "@/modules/matching/components/matching-list-desktop/matching-tabs.types";

export default Vue.extend({
  name: "MatchingListDesktop",

  components: {
    MatchingListCards,
    MatchingListInterests,
    MatchingListInterestMutual,
    MatchingSidebar,
    ListManagementBar,
  },

  static: {
    editCriteriaButton: {
      icon: "icon-gear--grey",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_GREY,
    },
  },

  data() {
    return {
      EMatchingListTabs,
      activeTab: EMatchingListTabs.DISCOVER as string,
      childSelectedTab: EMatchingListPendingTabs.INTERESTED_IN_ME as string,
      isListManagementFooterVisible: false,
      routeParams: this.$route.params as Dictionary<string> | null,
      matches: null as Array<IMatchingScore> | null,
    };
  },

  computed: {
    selectedTab(): string {
      return this.$store.get("meta/get", MATCHING_SELECTED_TAB);
    },

    matchingTabs(): any {
      return {
        [EMatchingListTabs.DISCOVER]: {
          label: this.$t(`matching.matchingList.tabs.discover`),
          icon: "icon-discover",
        },

        [EMatchingListTabs.PENDING]: {
          label: this.$t(`matching.matchingList.tabs.pending`),
          icon: "icon-pending",
        },

        [EMatchingListTabs.CONNECTED]: {
          label: this.$t(`matching.matchingList.tabs.connected`),
          icon: "icon-connected",
        },
      };
    },

    // Restrict lists to desktop
    showListFeature(): boolean {
      return this.$screen.lgUp;
    },

    isSelectModeActive(): boolean {
      return this.showListFeature;
    },

    showSidebar(): boolean {
      // Show sidebar only on desktop view, if select mode is de-activated
      return this.$screen.lgUp && !this.isListManagementFooterVisible;
    },

    /**
     * Get companies uids that are already in the selected list
     */
    listsCompaniesUid(): Array<string> {
      return JSON.parse(this.routeParams?.companiesUids || "[]");
    },

    listManagementItems(): Array<string> {
      return this.filterListManagementItems();
    },
  },

  watch: {
    selectedTab: {
      immediate: true,
      handler(currentTab) {
        if (currentTab !== this.activeTab) {
          this.activeTab = currentTab;
        }
      },
    },
  },

  destroyed() {
    this.routeParams = null;
    this.matches = null;
  },

  methods: {
    matchScores() {
      switch (this.activeTab) {
        case `${EMatchingListTabs.DISCOVER}`: {
          this.matches = this.$store.state?.matching?.data || [];
          break;
        }

        case `${EMatchingListTabs.PENDING}`: {
          // Based on pending select sub tab
          this.matches =
            (this.childSelectedTab === EMatchingListPendingTabs.INTERESTED_IN_ME
              ? this.$store.state?.matchingInterest?.targetMatchScores
              : this.$store.state?.matchingInterest?.userMatchScores) || [];
          break;
        }

        case `${EMatchingListTabs.CONNECTED}`: {
          this.matches =
            this.$store.state?.matchingInterest?.mutualMatchScores || [];
          break;
        }

        default: {
          this.matches = [];
          break;
        }
      }

      return this.matches;
    },

    clickTabHandler() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_SELECTED_TAB,
        value: this.activeTab,
      });
    },

    mapListManagementItems(): Array<string> {
      const matchingLoadedData = this.matchScores() || [];

      // Match matching score to company ids
      return matchingLoadedData.reduce(
        (accumulator: Array<string>, score: IMatchingScore) => {
          const company = (
            score.supporter
              ? score.supporter.user_profile.company
              : score.company
          ) as ICompany;

          if (!company?.uid) {
            return accumulator;
          }

          return [...accumulator, company?.uid];
        },
        [],
      );
    },

    /**
     * Filter companies that are already in the selected list
     */
    filterListManagementItems(): Array<string> {
      return this.mapListManagementItems().filter(
        (item: string) => !this.listsCompaniesUid.includes(item),
      );
    },

    onFooterVisibilityChanged(state: boolean) {
      this.isListManagementFooterVisible = state;
    },

    updateChildSelectedTab(tab: string) {
      this.childSelectedTab = tab;
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
