

























import Vue from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "MilestonePlanStageStatus",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    selectedStatus: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      stageStatus: [
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.toBePlanned",
          ),
          icon: EMilestoneStatusType.TO_BE_PLANNED,
        },
        {
          title: this.$t("milestonePlanner.milestoneGrid.stageStatus.planned"),
          icon: EMilestoneStatusType.PLANNED,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.inProgress",
          ),
          icon: EMilestoneStatusType.IN_PROGRESS,
        },
        {
          title: this.$t(
            "milestonePlanner.milestoneGrid.stageStatus.completed",
          ),
          icon: EMilestoneStatusType.COMPLETED,
        },
      ],
      activeState: this.selectedStatus,
    };
  },

  watch: {
    selectedStatus(newValue) {
      this.activeState = newValue;
    },
  },
});
