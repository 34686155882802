


















import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import GetQuestionAnswerMixin from "@/mixins/get-question-answer.mixin";

export default GetQuestionAnswerMixin.extend({
  props: {
    criteria: {
      type: Array as () => Array<IMatchingCriteria>,
      required: true,
    },
  },

  methods: {
    getQuestion(criteria: IMatchingCriteria): string {
      return (criteria.question as IMatchingQuestion).resource_question;
    },
  },
});
