













































import Vue from "vue";
import MatchDetails from "@/components/match-details-modal/match-details.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MatchDetailsModalHeader",

  components: {
    MatchDetails,
  },

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    matchPercentage: {
      type: Number,
      required: true,
    },

    hasMatch: {
      type: Boolean,
      default: false,
    },

    userType: {
      type: Number,
      default: 0,
    },
  },

  static: {
    improveMatchesButton: {
      icon: "icon-matching",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.DEFAULT,
    },
  },

  data() {
    return {
      expandedCollapsedItem: null as null | string,
    };
  },

  computed: {
    improveMatchesButtonProps(): any {
      return {
        ...this.$options.static.improveMatchesButton,
        label: this.$t(
          "matching.matchDetails.buttons.improveMatches",
        ) as string,
      };
    },

    improveTips(): TranslateResult {
      return this.$t(
        `matching.matchDetails.improveMatches[${this.userType}].tips`,
      );
    },

    improveTipsNote(): TranslateResult {
      return this.$t(
        `matching.matchDetails.improveMatches[${this.userType}].note`,
      );
    },

    formattedMatchPercentage(): string {
      return `${Math.trunc(this.matchPercentage)}%`;
    },
  },

  methods: {
    toogleTipsCollapseItem(): string | null {
      return (this.expandedCollapsedItem =
        this.expandedCollapsedItem == null ? "tips" : null);
    },
  },
});
