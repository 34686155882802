






















import Vue from "vue";

import ListReportsChart from "@/modules/charts-dashboard/components/list-reports/list-reports-chart.vue";
import ProcessReportsChart from "@/modules/charts-dashboard/components/process-reports/process-reports-chart.vue";

import { TranslateResult } from "vue-i18n";

import {
  IListReportsDemographicData,
  IDemographicDataResponse,
} from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import { IChartLockedContent } from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";
import {
  EDashboardPages,
  EDemographicThemes,
} from "@/modules/charts-dashboard/views/charts-dashboard.interface";
import {
  IProcessReportsDemographicData,
  IProcessReportsChartData,
} from "@/modules/charts-dashboard/services/data/process-reports-data/process-reports-data.interface";
import {
  formatCompaniesGenderData,
  formatProcessReportsGenderData,
} from "@/modules/charts-dashboard/utils/format-gender-responses-data";
import { IReportsViewThemes } from "@/modules/charts-dashboard/views/charts-dashboard.view.vue";
import {
  formatCompaniesEthnicityData,
  formatProcessReportsEthnicityData,
} from "@/modules/charts-dashboard/utils/format-ethnicity-responses-data";
import {
  formatCompaniesSexualOrientationData,
  formatProcessReportsSexualOrientationData,
} from "@/modules/charts-dashboard/utils/format-sexual-orientation-responses-data";

export default Vue.extend({
  name: "CompaniesDataChart",

  components: {
    ListReportsChart,
    ProcessReportsChart,
  },

  props: {
    data: {
      type: Object as () => IListReportsDemographicData,
      default: () => ({} as IListReportsDemographicData),
    },

    processReportsData: {
      type: Array as () => IProcessReportsDemographicData[],
      default: () => [] as IProcessReportsDemographicData[],
    },

    view: {
      type: Number,
      default: EDashboardPages.LIST_REPORTS,
      validator: (view: EDashboardPages) =>
        Object.values(EDashboardPages).includes(view),
    },

    theme: {
      type: Object as () => IReportsViewThemes,
      required: true,
    },
  },

  data() {
    return {
      aggregatedData: {
        responses: [] as IDemographicDataResponse[],
      },
      processReportsAggregatedData: [] as IProcessReportsChartData[],
    };
  },

  computed: {
    chartsTitle(): TranslateResult {
      return this.$t("chartsDashboard.charts.aggregation.title");
    },

    chartsDescription(): TranslateResult {
      const themeCopy =
        this.theme.title === EDemographicThemes.ETHNICITY
          ? this.$t("chartsDashboard.charts.aggregation.ethnicities")
          : (this.theme.title as string).toLowerCase();

      return this.$t("chartsDashboard.charts.aggregation.description", {
        theme: themeCopy,
      });
    },

    lockedStateCopy(): IChartLockedContent {
      return {
        title: "chartsDashboard.lockedState.title[0]",
        description: this.isListsReportsView
          ? "chartsDashboard.lockedState.description[0]"
          : "chartsDashboard.lockedState.description[4]",
        note: "chartsDashboard.lockedState.note",
        image: this.isListsReportsView
          ? `/img/illustrations/charts-dashboard/list-reports/${this.theme.image_path}_companies.png`
          : `/img/illustrations/charts-dashboard/process-reports/${this.theme.image_path}.png`,
      };
    },

    showLockedState(): boolean {
      return this.isListsReportsView && this.data.companies_with_responses < 5;
    },

    isListsReportsView(): boolean {
      return this.view === EDashboardPages.LIST_REPORTS;
    },
  },

  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData: IListReportsDemographicData) {
        if (!newData || !this.isListsReportsView) {
          return;
        }

        const data = newData as IListReportsDemographicData;

        this.aggregatedData.responses =
          this.theme.title === EDemographicThemes.GENDER
            ? formatCompaniesGenderData(data)
            : this.theme.title === EDemographicThemes.ETHNICITY
            ? formatCompaniesEthnicityData(data)
            : formatCompaniesSexualOrientationData(data);
      },
    },

    processReportsData: {
      immediate: true,
      deep: true,
      handler(newData: IProcessReportsDemographicData[]) {
        if (!newData || this.isListsReportsView) {
          return;
        }

        const data = newData as IProcessReportsDemographicData[];

        this.processReportsAggregatedData =
          this.theme.title === EDemographicThemes.GENDER
            ? formatProcessReportsGenderData(data)
            : this.theme.title === EDemographicThemes.ETHNICITY
            ? formatProcessReportsEthnicityData(data)
            : formatProcessReportsSexualOrientationData(data);
      },
    },
  },
});
