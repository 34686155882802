
















































































import SignInModalMixin from "@/mixins/sign-in-modal.mixin";
import {
  EAuthActions,
  IAuthState,
} from "@/modules/authentication/services/store/auth/auth-types";
import { ElMessageComponent } from "element-ui/types/message";

export default SignInModalMixin.extend({
  name: "LoginModal",

  props: {
    showClose: {
      type: Boolean,
      default: false,
    },

    visibility: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      infoMessage: null as null | ElMessageComponent,
    };
  },

  computed: {
    authState(): IAuthState {
      return this.$store.state.auth as IAuthState;
    },
  },

  methods: {
    async onClickLogin() {
      // remove the information message
      this.infoMessage?.close();

      await this.$store.dispatch(EAuthActions.LOGIN, {
        ...this.data,
        redirectUser: false,
      });

      await this.$nextTick();
      if (!this.authState.error) {
        this.$emit("after-login");
      }
    },
  },
});
