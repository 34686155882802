













import Vue from "vue";
import { setTimeout } from "timers";

export default Vue.extend({
  name: "PxModalMessage",

  props: {
    /**
     * Text to be shown.
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * Set the message visibility.
     */
    visible: {
      type: Boolean,
      default: true,
    },

    /**
     * Accepted types by the modal message.
     */
    type: {
      type: String,
      default: "error",
      validator(val: string) {
        return ["error", "success", "info"].includes(val);
      },
    },

    /**
     * Alignment of text and icon.
     */
    center: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    /**
     * Timeout for the alert be automatically hide.
     *
     * This value is defined in milliseconds.
     */
    timeout: {
      type: Number,
      default: null,
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if (!this.timeout) {
          return;
        }

        setTimeout(() => this.$emit("update:visible", false), this.timeout);
      },
    },
  },
});
