




























































































































import cloneDeep from "lodash/cloneDeep";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import { EAuthCompanyActions } from "@/modules/authentication/services/store/auth/auth-types";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";

import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";
import CompanyEditModalMixin from "@/mixins/company-edit-modal.mixin";

import {
  generateEmailValidator,
  generateRequiredSetValidator,
  generateRequiredValidator,
  generateURLValidator,
} from "@/services/errors/validator-generators";
export default CompanyEditModalMixin.extend({
  name: "CompanyEditModalEntrepreneur",

  components: {
    SectorsSelector,
  },

  data() {
    return {
      form: {
        data: {
          name: "",
          website: "",
          email: "",
          about: "",
          founded_date: "",
          logo: "",
          locations: [],
          sectors: [],
        } as Partial<ICompany>,
        file: null as File | null,
      },

      tempSectors: [] as Array<number>,

      rules: {
        name: {
          required: true,
          message: this.$t("common.errors.required", {
            fieldName: this.$t(
              `profile.companyEditModal.fields.name[${this.$profile.type()}]`,
            ),
          }),
        },
        website: generateURLValidator(
          this,
          "profile.companyEditModal.fields.website",
        ),
        email: generateEmailValidator(
          this,
          "profile.companyEditModal.fields.email",
        ),
        "locations[0]": generateRequiredValidator(
          this,
          "profile.companyEditModal.fields.location",
        ),
        sectors: generateRequiredSetValidator(
          this,
          "profile.companyEditModal.fields.sector",
        ),
        about: {
          max: 200,
        },
      },
    };
  },

  computed: {
    companyId(): number {
      const company = this.$store.get("auth/company.data");
      return company ? company.id : null;
    },
  },

  methods: {
    /**
     * Handle the click on the save button.
     */
    async submitForm() {
      this.$emit("update:loadingSubmission", true);
      this.$emit("update:hasServerErrors", false);

      if (this.form.file) {
        await companyProvider.updateLogo(this.companyId, this.form.file);
      }

      try {
        // TODO: when implementing the multiple location feature these next lines
        // must be removed
        const newData = {
          ...this.form.data,
          location: this.form.data.locations
            ? this.form.data.locations[0]
            : undefined,
        };

        /**
         * Guarantee that the user has defined a location, otherwise an error arises
         */
        if (!newData.location) {
          this.$emit("update:hasServerErrors", true);
          return;
        }

        await this.$store.dispatch(EAuthCompanyActions.PATCH, newData);
      } catch (error) {
        this.$emit("update:hasServerErrors", true);
        return;
      } finally {
        this.$emit("update:loadingSubmission", false);
      }

      this.$message({
        message: this.$t("profile.companyEditModal.successMessage") as string,
        type: "success",
        customClass: "is-full",
      });

      this.$emit("modal-visibility", false);
      await this.fetchCompany();
      this.resetFormData().then(() => null);
    },

    async resetFormData() {
      const company = cloneDeep(this.company) as ICompany;
      const formFields = Object.keys(this.form.data);

      // Get company values for form fields
      this.form.data = Object.keys(company)
        .filter((companyField: any) => formFields.includes(companyField))
        .reduce((obj: any, key: string) => {
          obj[key] = company[key];
          return obj;
        }, {});

      // Clean selected file
      this.form.file = null;

      // Set the sectors
      await this.$nextTick();
      const sectorSelector = this.$refs.sectorSelector as any;
      if (sectorSelector && this.form.data.sectors !== undefined) {
        sectorSelector.setSectors(this.form.data.sectors);
      }

      // Trigger validations
      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);

      try {
        await this.formRef.validate();
      } catch (_) {
        return;
      }
    },

    async fetchCompany() {
      await this.$store.dispatch(EAuthCompanyActions.FETCH, this.companyId);
      await this.$store.dispatch(EProfileCompanyActions.FETCH, this.companyId);
    },
  },
});
