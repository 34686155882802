
























































import Vue from "vue";
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },

    isSaveButtonDisabled: {
      type: Boolean,
      default: false,
    },

    isRedirecting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditing: false,
      isLoading: false,
    };
  },

  methods: {
    onClickStartEdit() {
      this.isEditing = true;
      this.$emit("edit");
    },

    afterSave(success = true) {
      this.isLoading = false;

      if (success) {
        this.isEditing = false;
      }
    },

    onClickCancel() {
      this.isEditing = false;
      this.$emit("cancel");
    },

    onClickSave() {
      this.isLoading = true;
      this.$emit("submit", this.afterSave);
    },
  },
});
