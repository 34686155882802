






































import Vue from "vue";

import PxNavigationTab, {
  INavigationTab,
} from "@/components/px-navigation-tab/px-navigation-tab.vue";
import AffiliateReviewPageSelfAssessment from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-self-assessment.vue";
import AffiliateReviewPageQuestions from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-questions.vue";
import AffiliateReviewPageTeamMembers from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-team-members.vue";

import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { AFFILIATE_TYPE_PROGRAM } from "@/services/data/affiliate/affiliate.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { MATCHING_LOWEST_LEVEL } from "@/modules/matching/constants";

export default Vue.extend({
  name: "AffiliateReviewPage",

  components: {
    PxNavigationTab,
    AffiliateReviewPageSelfAssessment,
    AffiliateReviewPageQuestions,
    AffiliateReviewPageTeamMembers,
  },

  data() {
    return {
      innerActiveTab: 1,
    };
  },

  computed: {
    levels(): ILevel[] {
      return this.$store.state.levels.data;
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    responses(): Array<IMatchingResponse> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.responses
        : [];
    },

    isMissingResponses(): boolean {
      return !!this.responses.filter((response) => !response.isValid).length;
    },

    teamMembersData(): Array<ITeamMember> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.teamMembers
        : [];
    },

    isMissingTeamMembersRequiredInformation(): boolean {
      return this.teamMembersData.some(
        (member) =>
          !member.first_name ||
          !member.last_name ||
          !member.email ||
          (member.email && !this.checkValidEmail(member.email)) ||
          !member.position,
      );
    },

    activeTab(): any {
      return (
        this.visibleNavigationTabs.find(
          (tab: INavigationTab) => tab.id === this.innerActiveTab,
        ) || this.visibleNavigationTabs[0]
      );
    },

    navigationTabs(): any {
      return [
        {
          id: 1,
          title: this.$t(
            "affiliateProgram.components.flowNavbar.assessmentTabTitle",
          ),
          icon: this.isMissingAssessment ? "warning--blue" : "success--green",
          iconPosition: "right",
          visible: true,
        },
        {
          id: 2,
          title: this.$t("affiliates.reviewPage.navigation.questionsTab"),
          icon: this.isMissingResponses ? "warning--blue" : "success--green",
          iconPosition: "right",
          visible: this.showQuestionsTab,
        },
        {
          id: 3,
          title: this.$t(
            "affiliateProgram.components.flowNavbar.teamManagementTabTitle",
          ),
          icon: this.isMissingTeamMembersRequiredInformation
            ? "warning--blue"
            : "success--green",
          iconPosition: "right",
          visible: this.showTeamMembersTab,
        },
      ];
    },

    visibleNavigationTabs(): INavigationTab[] {
      return this.navigationTabs.filter((tab: any) => tab.visible);
    },

    showQuestionsTab(): boolean {
      return (
        this.affiliatesData.affiliate?.flow_type === AFFILIATE_TYPE_PROGRAM
      );
    },

    showTeamMembersTab(): boolean {
      return (
        (this.affiliatesData.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.affiliatesData.draftSubmission?.data.assessment || [];
    },

    viralLevel(): number {
      const assessments = this.assessmentLevels;
      const currentAssessment = assessments.reduce(
        (current, assessment) =>
          assessment.level < current.level ? assessment : current,
        assessments[0],
      );

      return currentAssessment.level
        ? (currentAssessment.level as number)
        : MATCHING_LOWEST_LEVEL;
    },

    isMissingAssessment(): boolean {
      return this.assessmentLevels.every(
        (assessment) => assessment.level === 0,
      );
    },

    levelDescription(): ILevel | null {
      return (
        this.levels.find((level: ILevel) => level.value === this.viralLevel) ||
        null
      );
    },
  },

  watch: {
    affiliatesData: {
      immediate: true,
      deep: true,
      handler() {
        this.checkMissingData();
      },
    },
  },

  methods: {
    goToQuestion(id: number) {
      this.$emit("go-to-page", 2, id);
    },

    checkValidEmail(email: string) {
      const emailRegex =
        // eslint-disable-next-line no-control-regex
        /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
      return emailRegex.test(email);
    },

    checkMissingData() {
      const isMissingData =
        (this.showQuestionsTab && this.isMissingResponses) ||
        (this.showTeamMembersTab &&
          this.isMissingTeamMembersRequiredInformation);

      this.$emit("missing-data", isMissingData);
    },
  },
});
