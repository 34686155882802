





































































import Vue from "vue";
import MilestonePlanState from "@/modules/milestone-planner/components/milestone-plan-state/milestone-plan-state.vue";
import CompleteMilestoneInfoIndicator from "@/modules/milestone-planner/components/complete-milestone-info-indicator/complete-milestone-info-indicator.vue";
import CriticalMilestoneIndicator from "@/modules/milestone-planner/components/critical-milestone-indicator/critical-milestone-indicator.vue";
import SharePlanner from "@/modules/milestone-planner/components/share-planner/share-planner.vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import moment from "moment";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";

export default Vue.extend({
  name: "MilestonePlanHeader",

  components: {
    MilestonePlanState,
    CompleteMilestoneInfoIndicator,
    CriticalMilestoneIndicator,
    SharePlanner,
  },

  props: {
    categoryColor: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    categoryDescription: {
      type: String,
      required: true,
    },
    hasEvidence: {
      type: Boolean,
      default: false,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    isMilestoneComplete: {
      type: Boolean,
      default: false,
    },
    isMilestoneCritical: {
      type: Boolean,
      default: false,
    },
    isMilestoneInProgress: {
      type: Boolean,
      default: false,
    },
    isMilestonePlanned: {
      type: Boolean,
      default: false,
    },
    levelNumber: {
      type: Number,
      required: true,
    },
    targetDate: {
      type: String,
      required: false,
      default: null,
    },
    isVisitor: {
      type: Boolean,
      default: false,
    },
    milestonePlanner: {
      type: Object as () => IMilestonePlanner,
      default: () => null,
    },
  },

  static: {
    shareButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  computed: {
    shareButtonProps(): any {
      return {
        ...this.$options.static.shareButton,
        label: this.$t("milestonePlanner.share") as string,
      };
    },

    formattedTargetDate(): string {
      return moment(this.targetDate).format("DD MMMM, YYYY");
    },
  },
});
