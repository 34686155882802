














































import Vue from "vue";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";

import ResultsBoardJoin from "@/modules/supporters/components/results-board/results-board-join.vue";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import { ILevel } from "@/services/data/level/level.interface";

export default Vue.extend({
  name: "ResultsBoard",

  components: {
    ResultsBoardJoin,
  },

  computed: {
    /**
     * When present that means that registration feature is allowed.
     */
    allowsRegistration(): boolean {
      return this.$features.isEnabled("assessmentResultsPageAllowRegistration");
    },

    flowData(): ISupporterFlow {
      return this.$store.getters[SupporterFlowState.Getter.VALUE];
    },

    hasError(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.HAS_ERROR];
    },

    levels(): Array<ILevel> {
      return this.$store.get("levels/data");
    },

    investingLevel(): ISupporter["investing_level_range"] {
      return this.flowData.supporter.investing_level_range;
    },

    investingLevelMinimum(): number {
      return this.investingLevel.length ? this.investingLevel[0] : 1;
    },

    investingLevelMaximum(): number | null {
      return this.investingLevel.length > 1 ? this.investingLevel[1] : null;
    },

    investingLevelsSelection(): Array<ILevel> {
      if (!this.investingLevelMaximum) {
        return this.levels.filter(
          (level) => level.value === this.investingLevelMinimum,
        );
      }

      const uniqAscendingLevels = sortBy(uniqBy(this.levels, "value"), "value");
      return uniqAscendingLevels.filter(
        (level) =>
          level.value >= this.investingLevelMinimum &&
          !!this.investingLevelMaximum &&
          level.value <= this.investingLevelMaximum,
      );
    },

    investingLevelRangeInterval(): string {
      if (this.investingLevelMaximum) {
        return this.investingLevelMinimum === this.investingLevelMaximum
          ? this.investingLevelMinimum.toString()
          : this.investingLevel.join("-");
      }

      return this.investingLevelMinimum.toString();
    },

    investingLevelRangeTitle(): string {
      const minimumLevel = this.levels.find(
        (level) => level.value === this.investingLevelMinimum,
      );
      let rangeTitle = minimumLevel
        ? `${minimumLevel.title} <span>(${this.$t(
            "supporters.results.levelPanel.label",
          )} ${minimumLevel.value})</span>`
        : "";

      if (this.investingLevelMaximum !== this.investingLevelMinimum) {
        const maximumLevel = this.levels.find(
          (level) => level.value === this.investingLevelMaximum,
        );
        rangeTitle += maximumLevel
          ? `<br><span>to</span> ${maximumLevel.title} <span>(${this.$t(
              "supporters.results.levelPanel.label",
            )} ${maximumLevel.value})</span>`
          : "";
      }

      return rangeTitle;
    },
  },

  created() {
    if (!this.levels.length) {
      // Fetch levels if user lands on the results page directly.
      this.fetchLevels();
    }
  },

  methods: {
    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },
  },
});
