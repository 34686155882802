








































































































































































import QuestionPanel from "@/modules/affiliates/mixins/question-panel.mixin";

export default QuestionPanel.extend({
  name: "QuestionPanel",
});
