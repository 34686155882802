































import Vue from "vue";

export default Vue.extend({
  name: "MatchingCardThumbnail",

  props: {
    thumbnail: {
      type: String,
      default: "",
    },

    level: {
      type: Array as () => Array<number>,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      default: 53,
    },
  },

  static: {
    levelSize: 30,
    padding: 3,
  },

  computed: {
    hasSingleLevel(): boolean {
      return this.level?.length === 1;
    },

    levelIcon(): string | null {
      if (!this.hasSingleLevel) {
        return null;
      }

      return this.level[0]
        ? `level-graph/${this.level[0]}`
        : `empty-state/level`;
    },

    containerSizeStyle(): string {
      return `width: ${this.size}px; height: ${this.size}px;`;
    },

    avatarSizeStyle(): string {
      return `width: ${this.avatarSize}px; height: ${this.avatarSize}px;`;
    },

    /*
     * This allow us to get the avatar real size, by excluding the white space
     * paddings around the avatar image. The avatar is rectangular so the size
     * here is transversal (width and height are equal).
     *
     * (padding | real size | padding) = size
     */
    avatarSize(): number {
      return this.size - this.$options.static.padding * 2;
    },
  },
});
