
































import ElDialogMixin from "@/mixins/el-dialog.mixin";
import PxStepByStepDialogNavigation from "./px-step-by-step-dialog-navigation.vue";

export default ElDialogMixin.extend({
  name: "PxStepByStepDialog",

  components: {
    PxStepByStepDialogNavigation,
  },

  props: {
    pageCount: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },

  methods: {
    onPageChange(page: number) {
      this.$emit("update:currentPage", page);
    },

    onModalClose() {
      this.$emit("close-dialog");
      this.$emit("update:currentPage", 1);
    },
  },
});
