














































































import elDialogMixin from "@/mixins/el-dialog.mixin";
import {
  generateRequiredValidator,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { ElForm } from "element-ui/types/form";

export default elDialogMixin.extend({
  name: "GuestAccessModal",

  data() {
    return {
      guestForm: {
        data: {
          name: "",
          email: "",
        },
      },

      rules: {
        name: generateRequiredValidator(
          this,
          "companyLists.accessModal.form.fields.name",
        ),
        email: generateEmailValidator(
          this,
          "companyLists.accessModal.form.fields.email",
          true,
        ),
      },
    };
  },

  props: {
    title: {
      type: String,
      default: "companyLists.accessModal.title.noPasscode",
    },

    ctaText: {
      type: String,
      default: "companyLists.accessModal.buttons.enter",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToLogin() {
      this.$emit("go-to-login");
    },

    async makeRequest() {
      // validate form data
      const form = this.$refs.guestForm as ElForm;
      try {
        await form.validate();
      } catch (_) {
        return;
      }

      this.$emit("make-request", this.guestForm.data);
    },
  },
});
