











































import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

export default FormatMatchingResponseValuesMixin.extend({
  name: "SubmissionTabTeamMembers",

  props: {
    teamMembers: {
      type: Array as () => Array<ITeamMember>,
      required: true,
    },
  },

  methods: {
    formattedResponse(response: IMatchingResponse) {
      let formattedValue = "";

      if (response.value?.value) {
        formattedValue = this.formatNumericValue(response);
      } else if (
        (response.value?.min || response.value?.min === 0) &&
        response.value?.max
      ) {
        formattedValue = this.formatRangeValue(response);
      } else if (response.value?.text) {
        formattedValue = this.formatFreeResponseValue(response);
      } else if (response.value?.date) {
        formattedValue = this.formatDateValue(response);
      } else if (response.answers.length) {
        formattedValue = response.answers.length
          ? this.formatMultipleAnswersValue(
              response.question as IMatchingQuestion,
              response,
            )
          : "";
      }
      return formattedValue || `<i>${this.$t("common.noAnswer")}</i>`;
    },
  },
});
