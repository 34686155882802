


























import Vue from "vue";

import { IOffering } from "@/services/data/offering/offering.interface";

export default Vue.extend({
  name: "SupporterOfferings",

  props: {
    offerings: {
      type: Array as () => IOffering[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },
});
