import Vue from "vue";

/**
 * This filter validates if a url has
 * scheme and if not adds "//" to the
 * beginning to avoid relative paths
 */
Vue.filter("absoluteUrl", (url: string) => {
  const pattern =
    /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/;

  return pattern.test(url) ? url : `//${url}`;
});
