















































































































































































import Vue from "vue";
import { ErrorsManager } from "@/services/errors-manager";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generateRequiredValidator,
  generateSupporterTypesValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { TranslateResult } from "vue-i18n";
import { ElForm } from "element-ui/types/form";
import { ILocation } from "@/services/data/location/location.interface";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import {
  SUPPORTERS_TERMS_OF_USE_URL,
  SUPPORTERS_PRIVACY_POLICY_URL,
} from "@/modules/supporters/constants";

export default Vue.extend({
  name: "UserDetailsModal",

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },

  static: {
    termsOfUseUrl: SUPPORTERS_TERMS_OF_USE_URL,
    privacyPolicyUrl: SUPPORTERS_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      currentForm: null as null | ElForm,
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      formWrapperScrollAtEnd: false,
      isSubmissionClose: false,

      selectedSupporterTypes: [] as Array<number>,
      hasOtherSupporterType: false,
      otherType: "",

      fields: {
        email: "",

        company: {
          name: "",
          location: {} as Partial<ILocation>,
          networks: [] as Array<number>,
        },
        supporter: {
          types: [] as Array<number>,
        },
        terms: false,
      } as Partial<ISupporterFlow>,

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),

        company: {
          name: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.supporterName",
          ),

          location: generateRequiredValidator(
            this,
            "supporters.signup.form.fields.location",
          ),
        },

        supporter: {
          types: generateSupporterTypesValidator(
            this,
            "fields.supporter.other_type",
          ),
        },
        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
    };
  },

  computed: {
    componentCopy(): TranslateResult {
      return this.$t("supporters.component.userDetailsModal");
    },

    formCopy(): TranslateResult {
      return this.$t("supporters.form");
    },

    ctaCopy(): TranslateResult {
      return this.$t("supporters.cta");
    },

    showGoogleSignUp(): boolean {
      return this.$features.isEnabled("signUpGoogleSSO");
    },

    availableSupporterTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    async visibility() {
      await this.$nextTick();
      this.setupFormRef();
    },

    selectedSupporterTypes(currentSelectedTypes: Array<number>) {
      // Filter out invalid values
      (this.fields.supporter as any).types = currentSelectedTypes.filter(
        (type: number) => !!type,
      );
    },

    hasOtherSupporterType(state: boolean) {
      if (!state) {
        delete (this.fields.supporter as any).other_type;
      }
    },

    otherType(value: string) {
      if (this.hasOtherSupporterType) {
        (this.fields.supporter as any).other_type = value;
      }
    },
  },

  async created() {
    await this.$nextTick();
    this.setupFormRef();
    this.checkFormWrapperScroll();
  },

  methods: {
    setupFormRef() {
      if (!this.currentForm) {
        this.currentForm = this.$refs.formWrapper as ElForm;
      }
    },

    /**
     * Allows to add custom other supporter types
     */
    otherTypeChangeHandler(checked: boolean) {
      this.hasOtherSupporterType = checked;
    },

    /**
     * Handle the Google sign up button click.
     */
    onGoogleSignUpButtonClick() {
      // TODO: sign up with Google logic
    },

    onSignUpClick() {
      // Flag to prevent side-effect onClose action
      this.isSubmissionClose = true;

      // Spread this fields to avoid store injection via memory reference
      const submissionData = this.fields ? { ...this.fields } : undefined;
      this.$emit("submit", submissionData, this.errors);
    },

    beforeCloseHandler() {
      (this.$refs.modal as any).$emit("confirm-cancel-edit");
    },

    gotoSignInModal() {
      this.$emit("sign-in", true);
    },

    onModalClose() {
      if (this.isSubmissionClose) {
        return;
      }

      this.$router.back();
    },

    checkFormWrapperScroll() {
      // Required unknown first to overlap typings
      const wrapper = this.currentForm as unknown as HTMLElement;

      if (!wrapper) {
        return;
      }

      this.formWrapperScrollAtEnd =
        wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight;
    },

    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.currentForm,
        this.rules,
      );
    },
  },
});
