var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElDialog',{ref:"modal",staticClass:"milestone-plan-confirmation-dialog",attrs:{"visible":_vm.innerVisibility,"width":"500px","append-to-body":true,"close-on-click-modal":false,"close-on-press-escape":false},on:{"close":_vm.closeModal}},[_c('template',{slot:"title"},[_c('h3',{staticClass:"milestone-plan-confirmation-dialog__title"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('p',{staticClass:"milestone-plan-confirmation-dialog__description",class:{
        'milestone-plan-confirmation-dialog__description--draft':
          (_vm.planned && !_vm.isPlanPublished) ||
          (_vm.completed && !_vm.isCompletePublished),
        'milestone-plan-confirmation-dialog__description--complete':
          _vm.isCompletePublished,
      }},[_vm._v(" "+_vm._s(_vm.dialogDescription)+" ")])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"milestone-plan-confirmation-dialog__footer",class:{
        'milestone-plan-confirmation-dialog__footer--grid':
          !_vm.isPlanPublished && !_vm.isCompletePublished,
      }},[_c('PxButton',_vm._b({attrs:{"data-testid":"cancel-button"},on:{"click":_vm.closeModal}},'PxButton',_vm.cancelButtonProps,false)),(_vm.showSaveAsDraftButton)?_c('PxButton',_vm._b({attrs:{"data-testid":"save-as-draft-button"},on:{"click":_vm.onClickSaveAsDraft}},'PxButton',_vm.saveAsDraftButtonProps,false)):_vm._e(),_c('PxButton',_vm._b({attrs:{"data-testid":"delete-button"},on:{"click":_vm.onClickDelete}},'PxButton',_vm.deleteButtonProps,false))],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }