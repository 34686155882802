










































import Vue from "vue";

export default Vue.extend({
  name: "ValidatingPanelPlaceholder",

  computed: {
    isMobile(): boolean {
      return this.$screen.mdUp;
    },
  },
});
