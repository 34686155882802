




















































import elDialogMixin from "@/mixins/el-dialog.mixin";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import {
  generateCheckboxValidator,
  allFormFieldsValid,
} from "@/services/errors/validator-generators";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { LocaleMessageObject } from "vue-i18n";

export default elDialogMixin.extend({
  name: "OnboardingModal",

  data() {
    return {
      copy: (this.$user.isSupporter()
        ? this.$t("capitalExplorer.supportersOnboardingModal")
        : this.$t("capitalExplorer.onboardingModal")) as LocaleMessageObject,
      form: {
        terms: false,
      },
      rules: {
        terms: generateCheckboxValidator(),
      },
      continueToFeature: false,
    };
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$user.isSupporter()
          ? this.copy.action
          : this.$t("common.continue"),
      };
    },

    termsForm(): HTMLElement {
      return this.$refs.form as HTMLElement;
    },
  },

  methods: {
    updateContinueState() {
      this.continueToFeature = allFormFieldsValid(this.termsForm, this.rules);
    },

    submit() {
      this.$store.dispatch(ECapitalExplorerActions.GRANT_CONSENT, true);
    },
  },
});
