


































































import elDialogMixin from "@/mixins/el-dialog.mixin";
import { ErrorsManager } from "@/services/errors-manager";
import { generateRequiredValidator } from "@/services/errors/validator-generators";
import { TranslateResult } from "vue-i18n";
import { ElForm } from "element-ui/types/form";

export default elDialogMixin.extend({
  name: "PasscodeAccessModal",

  props: {
    requestedDataUid: {
      type: String,
      required: true,
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },

    needsIdentification: {
      type: Boolean,
      default: false,
    },

    noPasscodeMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      passcodeForm: {
        data: {
          passcode: "",
        },
      },

      errors: new ErrorsManager({
        passcode: {
          invalid_passcode: this.$t(
            "common.components.passcodeModal.errors.invalidPasscode",
          ),
        },
      }),

      rules: {
        passcode: generateRequiredValidator(
          this,
          "common.components.passcodeModal.form.field",
        ),
      },
    };
  },

  watch: {
    requestedDataUid() {
      // reset all form errors
      this.errors.clear();
    },

    hasErrors: {
      handler(hasError: boolean) {
        if (hasError) {
          this.displayErrorMessage();
        }
      },
    },
  },

  computed: {
    modalTitle(): TranslateResult {
      return this.$t("common.components.passcodeModal.title");
    },

    modalSubmitButtonText(): TranslateResult {
      return this.needsIdentification
        ? this.$t("common.next")
        : this.$t("companyLists.accessModal.buttons.enter");
    },
  },

  methods: {
    async makeRequest() {
      // validate form data
      const form = this.$refs.passcodeForm as ElForm;
      try {
        await form.validate();
      } catch (_) {
        return;
      }

      // Clear errors
      this.errors.clear();
      this.$emit("update:hasErrors", false);

      // Emit passcode to make request
      this.$emit("set-passcode", this.passcodeForm.data.passcode);
    },

    displayErrorMessage() {
      this.errors.record({
        passcode: [{ code: "invalid_passcode", message: "" }],
      });
    },
  },
});
