





























import Vue from "vue";

import CompanyPanel from "@/modules/profile/components/company-panel/company-panel.vue";
import AssessmentPanelMobile from "@/modules/profile/components/assessment-panel-mobile/assessment-panel-mobile.vue";
import InvestorsPanel from "@/modules/profile/components/investors-panel/investors-panel.vue";
import MatchingRibbon from "@/modules/profile/components/matching-ribbon/matching-ribbon.vue";
import InvestmentLevelPanel from "@/modules/profile/components/investment-level-panel/investment-level-panel.vue";
import NetworksPanel from "@/modules/profile/components/networks-panel/networks-panel.vue";
import SupporterCriteriaPanel from "@/modules/profile/components/supporter-criteria-panel/supporter-criteria-panel.vue";
import SupporterInfoPanel from "@/modules/profile/components/supporter-info-panel/supporter-info-panel.vue";
import AffiliateLinkPanel from "@/modules/profile/components/affiliate-link-panel/affiliate-link-panel.vue";

import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default Vue.extend({
  components: {
    CompanyPanel,
    AssessmentPanelMobile,
    InvestorsPanel,
    MatchingRibbon,
    InvestmentLevelPanel,
    NetworksPanel,
    SupporterCriteriaPanel,
    SupporterInfoPanel,
    AffiliateLinkPanel,
  },

  data() {
    return {
      selectedView: "about",
    };
  },

  computed: {
    storeActiveView(): string {
      const storeValue = this.$store.get("meta/get", PROFILE_META_ACTIVE_VIEW);
      return storeValue ? storeValue : "about";
    },
    hasMatching(): boolean {
      return this.$features.isEnabled("matchingInterest");
    },
    hasAffiliateLink(): boolean {
      return (
        this.$user.isOwner() &&
        this.$user.isSupporter() &&
        this.$features.isEnabled("commercializationAffiliateLink")
      );
    },
  },

  watch: {
    selectedView(newVal, oldVal) {
      if (newVal === oldVal || this.storeActiveView === newVal) {
        return;
      }

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_ACTIVE_VIEW,
        value: newVal,
      });
    },

    storeActiveView(newVal) {
      this.selectedView = newVal;
    },
  },
});
