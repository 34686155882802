





















import Vue from "vue";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { gaTrackEvent } from "@/services/utils/ga.utils";

export default Vue.extend({
  name: "FeaturesShowcaseCTA",

  methods: {
    onClickSignUp() {
      gaTrackEvent("click", "Join Us CTA");

      this.$router.push({
        name: ROUTE_AUTHENTICATION_SIGNUP,
        query: {
          fromAssessment: "true",
        },
      });
    },
  },
});
