import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { ICompanySearch } from "@/services/data/company-search/company-search.interface";
import {
  EProviderFeatures,
  GenericProvider,
  IOptionsType,
  IPaginationResult,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

@Provider(`${baseAPIUrl}/companies/search`, [EProviderFeatures.GET])
class CompanySearchProvider extends GenericProvider<ICompanySearch> {
  /**
   * Search by company name or email.
   *
   * @param filter filter to be applied on the query.
   * @param options addition query params to be passed to the GET request
   * @returns Search result
   */
  public async search(
    filter: string,
    company_type: number,
    options: IOptionsType = {},
  ): Promise<IPaginationResult<ICompanySearch>> {
    const url = this.buildEndPointUrl({ ...options, filter, company_type });
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    return data;
  }
}

/**
 * Provider to query companies based on their name or email.
 */
export const companySearchProvider = new CompanySearchProvider();
