






































import Vue from "vue";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default Vue.extend({
  name: "LevelSlider",

  props: {
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: "2873cb",
    },
    /**
     * Component height.
     */
    height: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 100 / this.levels;

      const result = [0];
      for (let i = 1; i <= stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue(newVal) {
      this.$emit("input", newVal);
      this.$emit("changed", newVal);
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },

    /**
     * Update innerValue when the prop value changes.
     */
    value(newValue: number) {
      this.innerValue = newValue;
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
  },

  methods: {
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as Vue;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElement = sliderElement.querySelector(
        SLIDER_BUTTON_SELECTOR,
      ) as HTMLElement;

      [progressBar, buttonElement].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },
  },
});
