/**
 * This this responsible for register all global components.
 */

import Vue from "vue";

import PxIcon from "@/components/px-icon/px-icon.vue";
import PxResponsiveBitmap from "@/components/px-responsive-bitmap/px-responsive-bitmap.vue";
import PxPanel from "@/components/px-panel/px-panel.vue";
import PxBadge from "@/components/px-badge/px-badge.vue";
import PxGlobalError from "@/components/px-global-error/px-global-error.vue";
import PxModalMessage from "@/components/px-modal-message/px-modal-message.vue";
import PxSteps from "@/components/px-steps/px-steps.vue";
import PxAvatar from "@/components/px-avatar/px-avatar.vue";
import PxAvatarGrid from "@/components/px-avatar/px-avatar-grid.vue";
import PxAvatarSet from "@/components/px-avatar/px-avatar-set.vue";
import PxInputPassword from "@/components/px-input-password/px-input-password.vue";
import PxInputPlaces from "@/components/px-input-places/px-input-places.vue";
import PxCounter from "@/components/px-counter/px-counter.vue";
import PxTextarea from "@/components/px-textarea/px-textarea.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import PxBottomBar from "@/components/px-bottom-bar/px-bottom-bar.vue";
import PxFooter from "@/components/px-footer/px-footer.vue";
import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxNavbarMinimal from "@/components/px-navbar-minimal/px-navbar-minimal.vue";
import PxNavbarWizard from "@/components/px-navbar-wizard/px-navbar-wizard.vue";
import PxTabsMobile from "@/components/px-tabs-mobile/px-tabs-mobile.vue";
import PxExpand from "@/components/px-expand/px-expand.vue";
import PxNotice from "@/components/px-notice/px-notice.vue";
import PxNoticeBar from "@/components/px-notice/px-notice-bar.vue";
import PxQuestionary from "@/components/px-questionary/px-questionary.vue";
import PxQuestionarySelect from "@/components/px-questionary/px-questionary-select.vue";
import PxMoney from "@/components/px-money/px-money.vue";
import PxNumeric from "@/components/px-numeric-input/px-numeric-input.vue";
import PxMatchingInterest from "@/components/px-matching-interest/px-matching-interest.vue";
import PxMatchingInterestCompact from "@/components/px-matching-interest/px-matching-interest-compact.vue";
import PxMoreList from "@/components/px-more-list/px-more-list.vue";
import PxListModal from "@/components/px-list-modal/px-list-modal.vue";
import PxFormModal from "@/components/px-form-modal/px-form-modal.vue";
import PxDateSelect from "@/components/px-date-select/px-date-select.vue";
import PxSliderCard from "@/components/px-slider-card/px-slider-card.vue";
import PxSwitch from "@/components/px-switch/px-switch.vue";
import PxButton from "@/components/px-button/px-button.vue";
import PxStepByStepDialog from "@/components/px-step-by-step-dialog/px-step-by-step-dialog.vue";
import PxStepByStepDialogNavigation from "@/components/px-step-by-step-dialog/px-step-by-step-dialog-navigation.vue";
import PxRadioGroup from "@/components/px-radio-group/px-radio-group.vue";

const globalComponents: { [key: string]: any } = {
  PxTabsMobile,
  PxNavbar,
  PxNavbarMinimal,
  PxNavbarWizard,
  PxFooter,
  PxTabBar,
  PxBottomBar,
  PxIcon,
  PxResponsiveBitmap,
  PxPanel,
  PxBadge,
  PxGlobalError,
  PxModalMessage,
  PxSteps,
  PxAvatar,
  PxAvatarGrid,
  PxAvatarSet,
  PxInputPassword,
  PxInputPlaces,
  PxCounter,
  PxTextarea,
  PxExpand,
  PxNotice,
  PxNoticeBar,
  PxQuestionary,
  PxQuestionarySelect,
  PxMoney,
  PxNumeric,
  PxMatchingInterest,
  PxMatchingInterestCompact,
  PxMoreList,
  PxListModal,
  PxFormModal,
  PxDateSelect,
  PxSliderCard,
  PxSwitch,
  PxButton,
  PxStepByStepDialog,
  PxStepByStepDialogNavigation,
  PxRadioGroup,
};

for (const name in globalComponents) {
  if (!Object.prototype.hasOwnProperty.call(globalComponents, name)) {
    continue;
  }

  Vue.component(name, globalComponents[name]);
}
