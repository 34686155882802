import { RouteConfig } from "vue-router";
import multiguard from "vue-router-multiguard";

import { removeTabsItems } from "@/services/router/mobile-tab-menu";

import {
  ROUTE_MATCHING_ENTRY_POINT,
  ROUTE_MATCHING_LIST,
} from "./routes-names";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import MatchingStartView from "@/modules/matching/views/matching-start.view.vue";
import MatchingListView from "@/modules/matching/views/matching-list.view.vue";
import { preventMatchingAccessGuard } from "@/modules/matching/services/router/guards/prevent-matching-access-guard/prevent-matching-access.guard";

export const routes: Array<RouteConfig> = [
  {
    path: "/matching",
    component: AuthenticatedWithNavigationView,
    meta: {
      auth: true,
    },
    beforeEnter: multiguard([removeTabsItems, preventMatchingAccessGuard]),
    children: [
      {
        path: "",
        name: ROUTE_MATCHING_LIST,
        component: MatchingListView,
      },
      {
        path: "on-boarding",
        name: ROUTE_MATCHING_ENTRY_POINT,
        component: MatchingStartView,
      },
    ],
  },
];
