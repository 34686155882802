






































































import Vue from "vue";

import PxFooter from "@/components/px-footer/px-footer.vue";
import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";

export default Vue.extend({
  name: "CookiePolicy",

  components: {
    PxNavbar,
    PxTabBar,
    PxFooter,
  },

  data() {
    return {
      footerItems: [],
    };
  },
});
