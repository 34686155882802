












































































































import Vue from "vue";

import get from "lodash/get";

import { ErrorsManager } from "@/services/errors-manager";
import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { pendingUserPasswordProvider } from "@/modules/authentication/services/data/pending-user-password/pending-user-password.provider";

import {
  AUTHENTICATION_TERMS_OF_USE_URL,
  AUTHENTICATION_PRIVACY_POLICY_URL,
} from "@/modules/authentication/constants";

import SignUpModalSuccess from "@/modules/authentication/components/signup-modal/signup-modal-success.vue";
import { IPendingUser } from "@/services/store/viral-level/types/pending-user.interface";
import { ElForm } from "element-ui/types/form";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "SignupModal",

  components: {
    SignUpModalSuccess,
  },

  static: {
    termsOfUseUrl: AUTHENTICATION_TERMS_OF_USE_URL,
    privacyPolicyUrl: AUTHENTICATION_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      innerVisibility: false,
      hasServerErrors: false,
      isLoadingSubmission: false,
      isSubmissionDisabled: true,
      submittedSuccessfully: false,
      formEl: null as null | ElForm,

      fields: {
        email: "",
        new_password1: "",
        new_password2: "",
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        new_password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),
        terms: generateCheckboxValidator(),
      },

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),
    };
  },

  computed: {
    pendingUser(): IPendingUser {
      return this.$store.state.affiliates.pendingUser;
    },

    pendingUserEmail(): string {
      return this.pendingUser ? this.pendingUser.email : "";
    },

    isPendingUserJoining(): boolean {
      return get(this.$route.query, "join", false);
    },

    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  watch: {
    innerVisibility(visible: boolean) {
      if (visible && this.fields.email) {
        this.$nextTick(() => {
          (this as any).formEl = this.$refs.signUpForm;
          this.formEl?.validateField("email", this.updateSubmitState);
        });

        return;
      }

      // Reset submission state
      this.submittedSuccessfully = false;
      this.errors.clear();
    },

    pendingUserEmail(currentEmail: string) {
      this.fields.email = currentEmail;
    },
  },

  created() {
    this.$root.$on("open-signup-modal", () => {
      if (this.$screen.mdDown) window.scrollTo(0, 0);
      this.innerVisibility = true;
    });

    // Set pending user email
    this.fields.email = this.pendingUserEmail;

    // Toggle visibility if requested join via query param
    this.innerVisibility = this.isPendingUserJoining;
  },

  methods: {
    updateSubmitState() {
      if (!this.innerVisibility || this.submittedSuccessfully) return;

      this.isSubmissionDisabled = !allFormFieldsValid(this.formEl, this.rules);
    },

    async submitForm() {
      this.isLoadingSubmission = true;
      this.hasServerErrors = false;

      try {
        const submitData = { ...this.fields, new_email: "" };

        if (this.fields.email !== this.pendingUserEmail) {
          submitData.email = this.pendingUserEmail;
          submitData.new_email = this.fields.email;
        }

        await pendingUserPasswordProvider.create(submitData);
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        } else {
          this.hasServerErrors = true;
        }
        return;
      } finally {
        this.isLoadingSubmission = false;
      }

      this.submittedSuccessfully = true;
      this.formEl?.resetFields();
      // Reset Pending User
      this.$store.dispatch(EAffiliatesActions.SET_PENDING_USER, null);
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("new_password2", this.updateSubmitState);
      }
    },
  },
});
