















































































import Vue from "vue";

import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";
import ListManagementModal from "@/modules/company-lists/components/list-management/list-management-modal.vue";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";
import {
  ICompanyList,
  ISimpleCompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import {
  ROUTE_ENTREPRENEUR_SUBMISSION,
  ROUTE_SUPPORTER_SUBMISSION,
} from "@/modules/program/services/router/routes-names";
import ListManagementConfirmationDialog from "@/modules/company-lists/components/list-management/list-management-confirmation-dialog.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import {
  EListManagementContext,
  EListManagementModalTab,
} from "./list-management.types";

export default Vue.extend({
  name: "ListManagementBar",

  components: {
    NavigationFooter,
    ListManagementModal,
    ListManagementConfirmationDialog,
  },

  props: {
    availableItems: {
      type: Array as () => Array<string>,
      default: () => [],
    },

    editedList: {
      type: Object as () => ICompanyList,
      default: () => null,
    },
  },

  static: {
    selectAllButton: {
      icon: "plus-white",
      type: EPxButtonType.DEFAULT_BLUE,
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },

    deselectAllButton: {
      icon: "line--white-14",
      type: EPxButtonType.BLUE_LIGHT,
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },

    cancelButton: {
      type: EPxButtonType.LINK,
    },

    removeButton: {
      type: EPxButtonType.RED,
    },

    addToListButton: {
      type: EPxButtonType.GREEN,
    },
  },

  data() {
    return {
      EListManagementContext,
      context: EListManagementContext.MATCHING,
      currentTab: EListManagementModalTab.SELECT as EListManagementModalTab,
      innerEditedList: null as ICompanyList | null,
      showAddToListModal: false,
      showNavigationFooter: false,
      showRemoveConfirmation: false,
    };
  },

  computed: {
    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    isSmartList(): boolean {
      return this.innerEditedList ? this.innerEditedList.is_smart_list : false;
    },

    hasSelectedLists(): boolean {
      return this.selectedLists.length > 0;
    },

    hasSelectedCompanies(): boolean {
      return this.selectionCompanyList.length > 0;
    },

    isAllSelected(): boolean {
      return (
        this.hasSelectedCompanies &&
        this.selectionCompanyList.length === this.availableItems.length
      );
    },

    selectedMembersCopy(): string {
      const selectedListTitle = this.$route.params?.listTitle || null;

      if (
        !this.innerEditedList?.companies.length &&
        this.isCurrentContext(EListManagementContext.LIST_DETAIL)
      ) {
        return this.$t("matching.matchingList.emptyList", {
          listTitle: this.innerEditedList?.title,
        }) as string;
      }

      if (!this.availableItems.length) {
        return this.$t("matching.matchingList.allMembersAlreadyOnList", {
          listTitle: selectedListTitle,
        }) as string;
      }

      if (!!selectedListTitle && !this.hasSelectedCompanies) {
        return this.$t("matching.matchingList.selectedList", {
          listTitle: selectedListTitle,
        }) as string;
      }

      if (!this.hasSelectedCompanies && !selectedListTitle) {
        return this.context === EListManagementContext.LIST_DETAIL &&
          !this.isSmartList
          ? (this.$t("matching.matchingList.noMembersSelectedLists") as string)
          : (this.$t(
              "matching.matchingList.noMembersSelectedMatching",
            ) as string);
      }

      return this.$tc(
        "matching.matchingList.memberSelected",
        this.selectionCompanyList.length,
        { number: this.selectionCompanyList.length },
      ) as string;
    },

    selectedCompanyName(): string | null {
      // If there's no selected companies or more than one company selected
      // we don't need the company name
      if (!this.hasSelectedCompanies || this.selectionCompanyList.length > 1) {
        return null;
      }

      const companyData = this.innerEditedList?.allCompaniesCompacted
        ? this.innerEditedList?.allCompaniesCompacted.filter(
            (company) => company.uid === this.selectionCompanyList[0],
          )[0].name
        : null;

      return companyData;
    },

    selectAllButtonProps(): any {
      return this.isAllSelected
        ? this.$options.static.deselectAllButton
        : this.$options.static.selectAllButton;
    },

    selectAllButtonTooltipText(): string {
      return this.isAllSelected
        ? (this.$t("matching.matchingList.deselectAll") as string)
        : (this.$t("matching.matchingList.selectAll") as string);
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel") as string,
      };
    },

    removeButtonProps(): any {
      return {
        ...this.$options.static.removeButton,
        label: this.$t("common.components.default.cta.removeMembers") as string,
      };
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label:
          this.isCurrentContext(EListManagementContext.LIST_DETAIL) &&
          !this.isSmartList
            ? (this.$t(
                "common.components.default.cta.addOrMoveToList",
              ) as string)
            : (this.$t("common.components.default.cta.addToList") as string),
      };
    },

    currentRouteName(): string {
      return this.$route?.name || "";
    },
  },

  watch: {
    selectionCompanyList(items: Array<number>) {
      // Remove navigation bar if route is profile or submission page
      if (
        this.context === EListManagementContext.PROFILE ||
        this.context === EListManagementContext.SUBMISSION
      ) {
        this.showNavigationFooter = false;
        return;
      }
      if (items.length > 0) {
        this.showNavigationFooter = true;
      }
    },

    currentRouteName: {
      immediate: true,
      handler(name: string) {
        switch (name) {
          case ROUTE_MATCHING_LIST:
            this.context = EListManagementContext.MATCHING;
            break;
          case ROUTE_COMPANY_LISTS_DIRECTORY:
            this.context = EListManagementContext.LIST_DIRECTORY;
            break;
          case ROUTE_COMPANY_LISTS_DETAIL:
            this.context = EListManagementContext.LIST_DETAIL;
            break;
          case ROUTE_PROFILE:
            this.context = EListManagementContext.PROFILE;
            break;
          case ROUTE_ENTREPRENEUR_SUBMISSION:
            this.context = EListManagementContext.SUBMISSION;
            break;
          case ROUTE_SUPPORTER_SUBMISSION:
            this.context = EListManagementContext.SUBMISSION;
            break;
          default:
            this.context = EListManagementContext.MATCHING;
            break;
        }
      },
    },

    showAddToListModal(state: boolean) {
      // When modal closes, default to select tab
      if (!state) {
        this.currentTab = EListManagementModalTab.SELECT;
      }
    },

    showNavigationFooter(state: boolean) {
      this.$emit("footer-visibility-changed", state);
    },

    editedList: {
      immediate: true,
      deep: true,
      handler(newValue: ICompanyList) {
        this.innerEditedList = newValue;
      },
    },
  },

  created() {
    // If matching context and contains selected lists, show bar
    if (
      this.hasSelectedLists &&
      this.isCurrentContext(EListManagementContext.MATCHING)
    ) {
      this.showNavigationFooter = true;
      return;
    }

    // If reaches any page or matching and doesn't have selected lists, clear selected companies
    this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);
  },

  mounted() {
    // Mount add to list event listener
    this.$root.$on(
      "show-list-management-modal",
      this.onShowListManagementModal,
    );

    this.$root.$on(
      "show-list-management-footer",
      this.onShowListManagementFooter,
    );
  },

  destroyed() {
    // Collect garbage
    this.$root.$off("show-list-management-modal");
    this.$root.$off("show-list-management-footer");
  },

  methods: {
    isCurrentContext(context: string): boolean {
      return this.context === context;
    },

    onSelectAllButtonClick() {
      if (this.isAllSelected) {
        this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);
        return;
      }

      // Dispatch all selection
      this.$store.dispatch(
        EListManagementActions.SET_COMPANIES,
        this.availableItems,
      );
    },

    onCancelButtonClick() {
      // Clear selected companies and lists
      this.$store.dispatch(EListManagementActions.CLEAR_LISTS);
      this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);

      // Reset the list companies and title params
      if (this.$route.params.companiesUids && this.$route.params.listTitle) {
        this.$route.params.companiesUids = "";
        this.$route.params.listTitle = "";
      }

      this.showNavigationFooter = false;
    },

    async onRemoveConfirmationClick() {
      const updatedCompanies = this.innerEditedList?.allCompaniesCompacted
        ? this.innerEditedList.allCompaniesCompacted
            .map(
              (companyOfList: ISimpleCompanyOfList) => companyOfList.uid || "",
            )
            .filter(
              (innerUid: string) =>
                !this.selectionCompanyList.some(
                  (selectionUid: string) => innerUid === selectionUid,
                ),
            )
        : [];

      await this.$store.dispatch(CompanyListState.Action.PATCH_VALUE, {
        uid: this.innerEditedList?.uid,
        shouldReload: true,
        payload: {
          companies: [...updatedCompanies],
        },
      });

      // Success toast message
      const quantity = this.selectionCompanyList.length;
      const message = this.$tc(
        "matching.matchingList.successToast.removedFromList",
        quantity,
        {
          company: this.$tc(
            "matching.matchingList.successToast.members",
            quantity,
            {
              quantity,
            },
          ),
        },
      );

      // Launch success navbar
      this.$message({
        message,
        type: "success",
        customClass: "is-navbar",
      });

      // Clear selected companies
      this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);

      this.$root.$emit("reset-virtual-grid-scroll-position");

      this.showNavigationFooter = false;
    },

    onShowListManagementFooter() {
      this.showNavigationFooter = true;
    },

    onShowListManagementModal(options: {
      tab: EListManagementModalTab;
      list: ICompanyList;
    }) {
      this.currentTab = options?.tab || EListManagementModalTab.SELECT;

      if (this.currentTab === EListManagementModalTab.EDIT) {
        this.innerEditedList = options?.list || null;
      }

      // Open wizard to add selected cards to a list
      this.showAddToListModal = true;
    },

    onRemoveButtonClick() {
      this.showRemoveConfirmation = !this.showRemoveConfirmation;
    },
  },
});
