






































































































































import Vue from "vue";
import { activeModules } from "@/services/utils/utils";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { locationFormatter } from "@/utils/location-formatter";

import { EMatchingRibbonType } from "@/modules/profile/services/data/matching-ribbon/matching-ribbon.types";
import MatchingRibbon from "@/modules/profile/components/matching-ribbon/matching-ribbon.vue";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

import PxMatchingInterestTip from "@/components/px-matching-interest/px-matching-interest-tip.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "SubmissionCompanyPanel",

  components: {
    MatchingRibbon,
    PxMatchingInterestTip,
  },

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
    userIsConnected: {
      type: Boolean,
      default: false,
    },
    userIsInterested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList") as string,
      };
    },

    isPublicView(): boolean {
      return !this.$user.isLogged();
    },

    formattedLocation(): string {
      const locationComponent =
        this.company && this.company.locations && this.company.locations[0]
          ? this.company.locations[0]
          : null;

      return locationFormatter(locationComponent);
    },

    hasMatching(): boolean {
      return this.$features.isEnabled("matchingInterest");
    },

    showAddToListButton(): boolean {
      return (
        this.$screen.lgUp &&
        activeModules().includes("company-lists") &&
        this.$features.isEnabled("companyLists")
      );
    },
  },

  static: {
    matchingRibbonType: EMatchingRibbonType.SLIM,
    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },

  methods: {
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: this.company.id,
        },
      });
    },

    showListModal() {
      this.$emit("click");
    },
  },
});
