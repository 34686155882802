



































import Vue from "vue";
import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";

export interface IPricingCardFeature {
  label: string;
  icon: string;
  iconSize: number;
}

export default Vue.extend({
  name: "PricingCard",

  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    priceNote: {
      type: String,
      default: null,
    },
    features: {
      type: Array as () => IPricingCardFeature[],
      required: true,
    },
    featuresTitle: {
      type: String,
      required: true,
    },
    ctaLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonProps(): any {
      return {
        type: EPxButtonType.BLUE,
        size: EPxButtonSize.LARGE,
      };
    },
  },
});
