import { IBreakpointEntry } from "./types/breakpoint-entry.interface";
import { IMediaQueriesList } from "./types/media-queries-list.interface";

const capitalize = (v: string) => v.charAt(0).toUpperCase() + v.substr(1);

/**
 * List of breakpoints.
 */
const defaultBreakpoints: Array<IBreakpointEntry> = [
  { name: "xs", width: 0 },
  { name: "sm", width: 576 },
  { name: "md", width: 768 },
  { name: "lg", width: 992 },
  { name: "xl", width: 1200 },
];

export class ScreenService {
  /**
   * List of breakpoints.
   */
  private readonly breakpoints: Array<IBreakpointEntry> = [];

  public matchState: { [key: string]: boolean } = {};

  /**
   * Create a new ScreenService instance.
   *
   * @param breakpoints Optional breakpoints to set.
   */
  constructor(breakpoints: Array<IBreakpointEntry> = defaultBreakpoints) {
    this.breakpoints = breakpoints;

    this.initBreakpoints();
    this.registerListeners();
  }

  /**
   * Initialize breakpoints.
   *
   * Insert a canary breakpoint at the end to make the calculations
   * easier, also sort the breakpoints.
   */
  private initBreakpoints() {
    this.breakpoints.push({
      name: "canary",
      width: Number.POSITIVE_INFINITY,
    });

    this.breakpoints.sort((a, b) => a.width - b.width);
  }

  /**
   * Generate a list of breakpoints.
   *
   * @param index Index of the breakpoint being generated.
   * @param breakpoint Breakpoint to generate the media queries.
   */
  private generateBreakpointMediaQueries(
    index: number,
    breakpoint: IBreakpointEntry,
  ): IMediaQueriesList {
    const nextBreakpoint = this.breakpoints[index + 1];

    let downQuery;
    const upQuery = `(min-width: ${breakpoint.width}px)`;
    let onlyQuery;

    if (nextBreakpoint.width !== Number.POSITIVE_INFINITY) {
      downQuery = `(max-width: ${nextBreakpoint.width - 1}px)`;
    }

    if (nextBreakpoint.width === Number.POSITIVE_INFINITY) {
      onlyQuery = `(min-width: ${breakpoint.width}px)`;
    } else {
      onlyQuery = `(min-width: ${breakpoint.width}px) and (max-width: ${
        nextBreakpoint.width - 1
      }px)`;
    }

    return {
      downQuery,
      onlyQuery,
      upQuery,
    };
  }

  private createMediaQueryListeners(
    breakpoint: IBreakpointEntry,
    queries: IMediaQueriesList,
  ) {
    ["down", "only", "up"].forEach((breakP: string) => {
      const query = queries[`${breakP}Query`];
      const breakCapitalized = capitalize(breakP);
      const matchSlug = `${breakpoint.name}${breakCapitalized}`;

      if (!query) {
        this.matchState[matchSlug] = true;
        return;
      }

      const mql = window.matchMedia(query);
      // TODO: save unsubscribe listener for later
      mql.addListener((e: MediaQueryListEvent) => {
        this.matchState[matchSlug] = e.matches;
      });

      // Initialize
      this.matchState[matchSlug] = mql.matches;
    });
  }

  private registerListeners() {
    this.breakpoints.forEach((breakpoint, index) => {
      if (breakpoint.width === Number.POSITIVE_INFINITY) {
        return;
      }

      if (!breakpoint.name) {
        throw new Error("The breakpoint must have an identifier");
      }

      const mediaQueries = this.generateBreakpointMediaQueries(
        index,
        breakpoint,
      );
      this.createMediaQueryListeners(breakpoint, mediaQueries);
    });
  }

  /**
   * Get a breakpoint entry by its name.
   *
   * @param name Breakpoint name.
   */
  private getBreakpoint(name: string): IBreakpointEntry {
    const entry = this.breakpoints.find(
      (e: IBreakpointEntry) => e.name === name,
    );

    if (!entry) {
      throw new Error("Invalid breakpoint given");
    }

    return entry;
  }

  /**
   * Get a width interval for the given breakpoint.
   *
   * @param name breakpoint to get the interval for.
   */
  private getBreakpointInterval(name: string): Array<number> {
    const breakpoint = this.getBreakpoint(name);
    const index = this.breakpoints.findIndex(
      (e: IBreakpointEntry) => e.name === name,
    );
    return [breakpoint.width, this.breakpoints[index + 1].width];
  }

  /**
   * Get the current screen width;
   */
  public width(): number {
    return window.innerWidth;
  }

  /**
   * Get the current screen height.
   */
  public height(): number {
    return window.innerHeight;
  }

  /**
   * Convert a breakpoint into pixies.
   *
   * @param name Breakpoint to get the correspondent number of pixies.
   */
  public breakpointToPixies(name: string): number {
    return this.getBreakpoint(name).width;
  }

  /**
   * Check if the given breakpoint match with the current
   * screen size.
   *
   * @param breakpoint Breakpoint to be tested.
   */
  public is(breakpoint: string): boolean {
    const interval = this.getBreakpointInterval(breakpoint);
    const screenSize = this.width();

    return screenSize >= interval[0] && screenSize < interval[1];
  }

  /**
   * Check if the screen resolution match with the given
   * breakpoint of above.
   *
   * @param breakpoint Breakpoint to test.
   */
  public isUp(breakpoint: string): boolean {
    const interval = this.getBreakpointInterval(breakpoint);
    return this.width() > interval[0];
  }

  /**
   * Check if the screen resolution match with the given
   * breakpoint of below.
   *
   * @param breakpoint Breakpoint to test.
   */
  public isDown(breakpoint: string): boolean {
    const interval = this.getBreakpointInterval(breakpoint);
    return this.width() < interval[1];
  }
}
