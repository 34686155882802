












































































































































import QuestionPanelMixin from "@/mixins/question-panel.mixin";

export default QuestionPanelMixin.extend({
  name: "QuestionPanel",
});
