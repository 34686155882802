











import Vue from "vue";
import PxStageStatusItem from "@/components/px-stage-status-item/px-stage-status-item.vue";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "MilestoneStageStatusItem",

  components: {
    PxStageStatusItem,
  },

  props: {
    icon: {
      required: true,
      type: String,
      validator: (value: EMilestoneStatusType) =>
        Object.values(EMilestoneStatusType).includes(value),
    },
    title: {
      required: true,
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 26,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
});
