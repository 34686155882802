import { render, staticRenderFns } from "./submission-panel-supporter-info.vue?vue&type=template&id=b25b0f02&scoped=true&"
import script from "./submission-panel-supporter-info.vue?vue&type=script&lang=ts&"
export * from "./submission-panel-supporter-info.vue?vue&type=script&lang=ts&"
import style0 from "./submission-panel-supporter-info.vue?vue&type=style&index=0&id=b25b0f02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b25b0f02",
  null
  
)

export default component.exports