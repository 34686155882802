import { RouteConfig } from "vue-router";

// Interface
interface IRenderlessRoute {
  options: {
    suffixPath: string;
    meta: Record<string, any>;
  };
  children: Array<RouteConfig>;
}

/**
 * Allows to create nested routes which parent doesn't have a component.
 * Links to vue-router issue https://github.com/vuejs/vue-router/issues/2105.
 *
 * @param route
 */
export const nestedRoutesWithRenderlessParent = (
  route: IRenderlessRoute,
): Array<RouteConfig> => {
  return route.children.map((nestedRoute: RouteConfig) => ({
    ...nestedRoute,
    path: `${route.options.suffixPath}/${nestedRoute.path}`,
    meta: {
      ...nestedRoute.meta,
      ...(route.options.meta || {}),
    },
  }));
};
