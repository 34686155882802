

















































































import Vue from "vue";
import { LocaleMessages, TranslateResult } from "vue-i18n";
import { IMilestonePlannerInvitedUser } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

export default Vue.extend({
  name: "SharePlannerUsersIndex",

  props: {
    users: {
      type: Array as () => IMilestonePlannerInvitedUser[],
      required: true,
    },
  },

  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("milestonePlanner.shareModal") as LocaleMessages;
    },
  },

  methods: {
    tagCopy(user: IMilestonePlannerInvitedUser): TranslateResult {
      return !user.user_profile
        ? this.$t("common.guest")
        : this.$t("common.formOwner");
    },

    tooltipCopy(user: IMilestonePlannerInvitedUser): TranslateResult {
      return user.is_form_owner
        ? this.$t("milestonePlanner.shareModal.users.formOwner")
        : this.$t("milestonePlanner.shareModal.users.tip");
    },

    unshareClickHandler(user: IMilestonePlannerInvitedUser) {
      this.$root.$emit("share-planner-modal-confirmation", {
        event: SHARE_PLANNER_EVENTS.UNSHARE_FROM_USER,
        payload: { user },
      });
    },
  },
});
