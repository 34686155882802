































































































import Vue from "vue";

import CapitalTile from "@/modules/capital-explorer/components/capital-tile/capital-tile.vue";
import PxEmptyMessage from "@/components/px-empty-message/px-empty-message.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { TranslateResult } from "vue-i18n";
import {
  ICapitalTileInfo,
  ICapitalMatchingBadge,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { GLOSSARY_URL } from "@/modules/capital-explorer/constants";
import { CAPITAL_EXPLORER_COMPARISON_TABLE } from "@/modules/capital-explorer/services/router/routes-names";
import {
  FAIR_MATCH,
  POOR_MATCH,
  EXCELLENT_MATCH,
} from "@/modules/capital-explorer/capital-structures-matching.types";

export default Vue.extend({
  name: "ResultsPanel",

  components: {
    CapitalTile,
    PxEmptyMessage,
    PxStatusBadge,
  },

  static: {
    glossaryButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
    },
  },

  props: {
    results: {
      type: Array as () => ICapitalTileInfo[],
      required: true,
    },

    noMatchingData: {
      type: Boolean,
      default: false,
    },

    showMatchingBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollAtTop: true,
      panel: (null as any) || null,
      matchingBadges: [EXCELLENT_MATCH, FAIR_MATCH, POOR_MATCH].map(
        ({ matchingBadge }) => matchingBadge as ICapitalMatchingBadge,
      ),
    };
  },

  computed: {
    emptyTitle(): TranslateResult {
      return this.$t("capitalExplorer.resultsPanel.noMatchingData.title");
    },

    emptySubtitle(): TranslateResult {
      return this.$t("capitalExplorer.resultsPanel.noMatchingData.subtitle");
    },

    floatingTagProps(): any {
      return {
        label: this.$t("common.codeveloped") as string,
        labelSize: 14,
        labelColor: "#272740",
        backgroundColor: "#fafafa",
        weight: 500,
        borderRadius: 8,
        boxShadow: "0 0 4px 0 rgba(39, 39, 64, 0.16)",
        uppercase: false,
      };
    },

    glossaryButtonProps(): any {
      return {
        ...this.$options.static.glossaryButton,
        label: this.$t("common.glossary") as string,
        icon: "icon-book-open",
        iconPosition: "left",
      };
    },

    compareButtonProps(): any {
      return {
        size: EPxButtonSize.MEDIUM,
        type: EPxButtonType.PRIMARY,
        label: this.$t("common.compare") as string,
        icon: "icon-matching",
        iconPosition: "left",
      };
    },
  },

  beforeDestroy() {
    this.panel = null;
  },

  methods: {
    checkScroll() {
      this.panel = this.$refs.panel as HTMLElement;

      if (!this.panel) return;

      this.scrollAtTop = this.panel.scrollTop === 0;
    },

    onGlossaryClick() {
      window.open(GLOSSARY_URL);
    },

    onCompareButtonClick() {
      this.$router.push({ name: CAPITAL_EXPLORER_COMPARISON_TABLE });
    },
  },
});
