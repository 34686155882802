
























































































































import Vue from "vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IMoreListItem } from "@/components/px-more-list/px-more-list.vue";

export default Vue.extend({
  props: {
    sectors: {
      type: Array as () => ISupporter["sectors"],
      default: () => [],
    },
    groupedSectors: {
      type: Array as () => ISupporter["grouped_sectors"],
      default: () => [],
    },
    locations: {
      type: Array as () => ISupporter["locations"],
      default: () => [],
    },
    groupedLocations: {
      type: Array as () => ISupporter["grouped_locations"],
      default: () => [],
    },
  },

  data() {
    return {
      tagsLimit: 3,
      tooltipLimit: 8,
    };
  },

  computed: {
    supporterSectorsTitle(): string {
      return this.$t("profile.company.sectorsOfInterest") as string;
    },

    /**
     * Map supporter sectors and grouped sectors
     * for company panel tags and px-more-list.
     */
    supporterSectorsOfInterest(): Array<IMoreListItem> {
      const tags = [];

      if (this.sectors.length) {
        const sectorTags = this.sectors.map((sector) => ({
          name: sector.name,
        }));
        tags.push(...sectorTags);
      }

      if (this.groupedSectors) {
        let sectorGroupedTags: Array<IMoreListItem> = [];

        // 1 - Check if all sub-sectors combined total, is greater than the tooltip limit
        const needsToGroupSectors =
          this.groupedSectors.reduce(
            (totalSectors, grouped_sector) =>
              totalSectors + grouped_sector.sectors.length,
            0,
          ) > this.tooltipLimit;

        // 1.1 - If not, sub-sectors will appear as independent sectors
        if (!needsToGroupSectors) {
          this.groupedSectors.forEach((grouped_sector) => {
            if (grouped_sector.sectors.length) {
              const sectorTags = grouped_sector.sectors.map((sector) => ({
                name: sector.name,
              }));
              sectorGroupedTags.push(...sectorTags);
            }
          });
        } else {
          // 1.2 - Otherwise, sub-sectors will appear grouped on each sector group
          sectorGroupedTags = this.groupedSectors.map((grouped_sector) => {
            if (grouped_sector.sectors.length > 1) {
              return {
                name: grouped_sector.group.name,
                items: grouped_sector.sectors.map((sector) => sector.name),
              };
            } else {
              return { name: grouped_sector.sectors[0].name };
            }
          });
        }

        tags.push(...sectorGroupedTags);
      }

      return tags;
    },

    supporterLocationsTitle(): string {
      return this.$t("profile.company.locationsOfInterest") as string;
    },

    /**
     * Map supporter locations and grouped locations
     * for company panel tags and px-more-list.
     */
    supporterLocationsOfInterest(): Array<IMoreListItem> {
      const tags = [];

      if (this.locations) {
        const locationTags = this.locations.map((location) => ({
          name: location.formatted_address,
        }));
        tags.push(...locationTags);
      }

      if (this.groupedLocations) {
        let locationGroupedTags: Array<IMoreListItem> = [];

        // 1 - Check if all sub-locations combined total, is greater than the tooltip limit
        const needsToGroupLocations =
          this.groupedLocations.reduce(
            (totalLoctions, grouped_location) =>
              totalLoctions + grouped_location.locations.length,
            0,
          ) > this.tooltipLimit;

        // 1.1 - If not, sub-locations will appear as independent locations
        if (!needsToGroupLocations) {
          this.groupedLocations.forEach((grouped_location) => {
            if (grouped_location.locations.length) {
              const locationTags = grouped_location.locations.map(
                (location) => ({
                  name: location.formatted_address,
                }),
              );
              locationGroupedTags.push(...locationTags);
            }
          });
        } else {
          // 1.2 - Otherwise, sub-location will appear grouped on each location group
          locationGroupedTags = this.groupedLocations.map(
            (grouped_location) => {
              if (grouped_location.locations.length > 1) {
                return {
                  name: grouped_location.group.name,
                  items: grouped_location.locations.map(
                    (location) => location.formatted_address,
                  ),
                };
              } else {
                return {
                  name: grouped_location.locations[0].formatted_address,
                };
              }
            },
          );
        }

        tags.push(...locationGroupedTags);
      }

      return tags;
    },

    selectedPrintableValue(): Array<IMoreListItem> {
      return this.supporterSectorsOfInterest.slice(this.tagsLimit);
    },
  },

  methods: {
    limitVisible(data: Array<any>) {
      return data.slice(0, this.tagsLimit);
    },

    getRemaining(data: Array<any>, limit: number) {
      return data.length - limit;
    },

    /**
     * Sum all items with sub-items (if they exist).
     */
    getTotal(items: Array<IMoreListItem>): number {
      return items.reduce((count, item) => {
        // Count own item
        count++;

        // Count group children if they exist
        if ("items" in item && !!item.items && item.items.length) {
          count += item.items.length;
        }

        return count;
      }, 0);
    },

    /**
     * Check if there are more items (including sub-items)
     * than the visible tags limit.
     */
    hasMore(items: Array<IMoreListItem>, limit?: number): boolean {
      limit = limit || this.tagsLimit;

      return (
        items.length > limit ||
        // Check if there are grouped items
        items.some((item) => {
          return "items" in item && !!item.items && item.items.length;
        })
      );
    },

    /**
     * Map list of item instances to
     * simple string list with names
     * for the px-more-list values.
     */
    getListOfNamesWithCount(items: Array<IMoreListItem>): Array<string> {
      return items.map((item) => {
        if ("items" in item && !!item.items && item.items.length) {
          return `${item.name} (${item.items.length})`;
        }

        return item.name;
      });
    },
  },
});
