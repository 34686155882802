





































import Vue from "vue";

import PxListHeader from "@/components/px-list-header/px-list-header.vue";
import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ROUTE_USER_ACCOUNT } from "@/modules/profile/services/router/routes-names";

export enum EAccountSettingsNavigationTabs {
  INFO = "info",
  SUBSCRIPTION = "subscription",
}

export default Vue.extend({
  name: "AccountSettingsNavigation",

  components: {
    PxListHeader,
    PxTabBar,
  },

  props: {
    selectedTab: {
      type: String,
      default: EAccountSettingsNavigationTabs.INFO,
      validator: (value: EAccountSettingsNavigationTabs) => {
        return Object.values(EAccountSettingsNavigationTabs).includes(value);
      },
    },
  },
  data() {
    return {
      innerSelectedTab: this.selectedTab,
      tabs: [
        {
          key: EAccountSettingsNavigationTabs.INFO,
          label: this.$t("profile.accountSettingsPage.navigation.tabs[0]"),
        },
        {
          key: EAccountSettingsNavigationTabs.SUBSCRIPTION,
          label: this.$t("profile.accountSettingsPage.navigation.tabs[1]"),
        },
      ],
    };
  },

  computed: {
    backButtonProps(): any {
      return {
        icon: "arrow--left-dark",
        size: EPxButtonSize.XX_SMALL,
        variant: EPxButtonVariant.COMPACT,
      };
    },
  },

  watch: {
    innerSelectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.$emit("update:selected-tab", newTab);
      },
    },

    selectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.innerSelectedTab = newTab;
      },
    },
  },

  methods: {
    updateSelectedTab(tabKey: string) {
      this.innerSelectedTab = tabKey;
    },

    onBackButtonClick() {
      this.$router.push({
        name: ROUTE_USER_ACCOUNT,
      });
    },
  },
});
