





















import Vue from "vue";

export default Vue.extend({
  name: "MatchDetails",

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    percentage: {
      type: String,
      required: true,
    },

    hasMatch: {
      type: Boolean,
      default: false,
    },
  },
});
