











































import Vue from "vue";
import { ILevel } from "@/services/data/level/level.interface";
import { TranslateResult } from "vue-i18n";
import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import LevelUpdateModalFirstTab from "@/modules/common/components/level-update-modal/level-update-modal-first-tab.vue";

export default Vue.extend({
  name: "LevelUpdateModalLevelsExample",

  components: {
    PxNavigationTab,
    LevelUpdateModalFirstTab,
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  data() {
    return {
      navigationTabs: [
        {
          id: 1,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.1",
          ),
        },
        {
          id: 2,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.2",
          ),
        },
        {
          id: 3,
          title: this.$t(
            "common.components.levelUpdateModal.examplePage.navigationTabs.3",
          ),
        },
      ],
      innerActiveTab: 1,
    };
  },

  computed: {
    isSameLevel(): boolean {
      return this.currentLevel.value === this.newLevel.value;
    },

    isLevelUp(): boolean {
      return this.currentLevel.value < this.newLevel.value;
    },

    modalTitle(): TranslateResult {
      return this.isSameLevel
        ? this.$t(
            "common.components.levelUpdateModal.examplePage.sameLevelTitle",
          )
        : this.isLevelUp
        ? this.$t("common.components.levelUpdateModal.examplePage.levelUpTitle")
        : this.$t(
            "common.components.levelUpdateModal.examplePage.levelDownTitle",
          );
    },

    levelUpdateType(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.sameLevel")
        : this.isLevelUp
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.levelUp")
        : this.$t("common.components.levelUpdateModal.typeOfUpdate.levelDown");
    },

    tabImage(): string {
      return `/img/illustrations/milestone-planner/${this.levelUpdateType}-${this.innerActiveTab}.svg`;
    },
  },
});
