












































































































import Vue from "vue";

import ValidatingPanel from "@/components/validating-panel/validating-panel.vue";
import ValidatingPanelPlaceholder from "@/components/validating-panel-placeholder/validating-panel-placeholder.vue";
import DetailsPanel from "@/modules/affiliates/components/details-panel/details-panel.vue";
import DetailsPanelPlaceholder from "@/modules/affiliates/components/details-panel-placeholder/details-panel-placeholder.vue";
import ResultsBoard from "@/modules/affiliates/components/results-board/results-board.vue";
import FeaturesShowcase from "@/modules/affiliates/components/features-showcase/features-showcase.vue";
import SignUpModal from "@/modules/authentication/components/signup-modal/signup-modal.vue";

import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { AFFILIATE_PROGRAM_FINISHED } from "@/modules/affiliates/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import AffiliateNavigationBar from "@/modules/affiliates/components/affiliate-navigation-bar/affiliate-navigation-bar.vue";

export default Vue.extend({
  name: "AffiliateEntrepreneurResults",

  components: {
    DetailsPanel,
    ValidatingPanel,
    ValidatingPanelPlaceholder,
    DetailsPanelPlaceholder,
    ResultsBoard,
    FeaturesShowcase,
    SignUpModal,
    AffiliateNavigationBar,
  },

  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },

    showNavbar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    viralLevelStore(): IViralLevelState {
      return this.$store.state.viralLevel as IViralLevelState;
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliatesData.affiliate;
    },

    isLoading(): boolean {
      return this.viralLevelStore.loading || this.affiliatesData.loading;
    },

    hasError(): boolean {
      return !!this.viralLevelStore.error || !!this.affiliatesData.error;
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    isFeaturesShowcaseDisabled(): boolean {
      return this.$features.isEnabled("disabledFeaturesShowcase");
    },
  },

  destroyed() {
    this.$store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_PROGRAM_FINISHED,
      value: false,
    });
  },

  mounted() {
    this.$store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_PROGRAM_FINISHED,
      value: true,
    });
  },

  methods: {
    goToMilestonePlanner() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },
  },
});
