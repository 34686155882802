




























































import Vue from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";

import { EPxButtonType } from "@/components/px-button/px-button.types";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "UserResponsesTable",

  components: {
    MatchingCardTagList,
  },

  props: {
    tableData: {
      type: Array as () => any,
      required: true,
    },

    allQuestionsAnswered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      learnMoreModalVisible: false,
      learnMoreModalTitle: "",
      learnMoreModalText: "",
    };
  },

  computed: {
    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore") as string,
      };
    },

    answerHeaderCopy(): TranslateResult {
      const isVisitorsView =
        this.$route.name === CAPITAL_EXPLORER_VISITORS_VIEW;

      return isVisitorsView
        ? this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${1}]`)
        : this.$t(`capitalExplorer.detailsModal.tableHeader[1].title[${0}]`);
    },
  },

  methods: {
    showLearnMoreModal(question: string, instructions: string) {
      this.learnMoreModalTitle = question;
      this.learnMoreModalText = instructions;
      this.learnMoreModalVisible = true;
    },
  },
});
