
























import PxNavigationTabItem from "@/components/px-navigation-tab/px-navigation-tab-item.vue";
import { TMilestoneDetailsTab } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import Vue from "vue";

export interface INavigationTab {
  id: TMilestoneDetailsTab | number;
  title: string;
  icon?: string;
  visible?: boolean;
  iconPosition?: string;
}

export default Vue.extend({
  name: "PxNavigationTab",

  components: {
    PxNavigationTabItem,
  },

  props: {
    navigationTabs: {
      type: Array as () => Array<INavigationTab>,
      required: true,
    },
    selectedTab: {
      type: [Number, String as () => TMilestoneDetailsTab],
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 17,
    },
    listFormat: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Handle navigation between tabs
     */
    clickNavigationTab(tab: TMilestoneDetailsTab) {
      this.$emit("update:selectedTab", tab);
    },
  },
});
