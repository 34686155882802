















import Vue from "vue";

export default Vue.extend({
  name: "LevelBar",

  props: {
    /**
     * Text to be used for the label
     */
    label: {
      type: String,
      default() {
        return this.$t("selfAssessment.components.levelBar.label");
      },
    },

    /**
     * Contains the user level value and description
     */
    level: {
      type: Object as () => any,
      required: true,
    },
  },
});
