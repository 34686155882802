var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assessment",class:{
    'is-navigation-bar': _vm.$screen.mdOnly,
    'has-fixed-navigation': !_vm.$screen.mdOnly && _vm.showIntegratedFooter,
  }},[_c('div',{staticClass:"assessment__container"},[_c('div',{staticClass:"assessment__header"},[(_vm.hasPreviousPage)?_c('PxButton',_vm._b({staticClass:"assessment__back-button",on:{"click":_vm.goToPreviousPage}},'PxButton',_vm.$options.static.backButton,false)):_vm._e(),_c('div',{staticClass:"assessment__header-tip"},[_vm._v(" "+_vm._s(_vm.$t("supporters.cta.ventureInvestmentLevel"))+" "),_c('ElTooltip',{attrs:{"placement":"top","popper-class":"assessment__header-tooltip-wrapper"}},[_c('div',{staticClass:"assessment__header-tooltip-container",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.$t("supporters.view.criteriaAssessment.tip")))]),_c('p',[_vm._v(_vm._s(_vm.$t("supporters.view.criteriaAssessment.tip2")))])]),_c('PxIcon',{staticClass:"assessment__header-tip-icon",attrs:{"size":16,"name":"question"}})],1)],1)],1),(_vm.currentCategory && _vm.selectedStep)?_c('div',{staticClass:"assessment__main"},[_c('div',{staticClass:"assessment__level-slider-column"},[_c('LevelRangeSlider',{attrs:{"color":_vm.currentCategory.color,"levels":_vm.currentCategory.categoryDetails.length,"range-restriction":_vm.rangeRestriction,"value":_vm.selectedStepLevel,"height":"372px"},on:{"input":_vm.onLevelChange,"range-limit-overreached":_vm.rangeLimitOverreachedHandler}}),(_vm.levelAtStart && _vm.isFirstInteraction)?_c('div',{staticClass:"assessment-level-slider-tip"},[_c('img',{staticClass:"assessment-level-slider-tip__icon",attrs:{"src":_vm.sliderTipIcon,"alt":"Drag Tip Icon"}}),_c('p',{staticClass:"assessment-level-slider-tip__text",domProps:{"innerHTML":_vm._s(
              _vm.$t("supporters.assessment.dragTip", { range: _vm.rangeRestriction })
            )}})]):_vm._e()],1),_c('LevelsDescription',{staticClass:"assessment__levels-description",attrs:{"category-details":_vm.currentCategory.categoryDetails,"current-level":_vm.currentSelectedStepLevel,"range-first-level":_vm.firstSelectedRangeLevel,"height":"356px"}}),_c('div',{staticClass:"assessment__requirements-column"},[_c('CompanyRequirements',{staticClass:"assessment__category-requirements",attrs:{"category":_vm.currentCategory,"level":_vm.currentSelectedStepLevel}}),_c('div',{ref:"rangeLimitTip",staticClass:"assessment-range-tip"},[_c('PxIcon',{attrs:{"size":16,"name":"information"}}),_c('p',{staticClass:"assessment-range-tip__text",domProps:{"innerHTML":_vm._s(
              _vm.$t("supporters.assessment.rangeLimitNotice.title", {
                range: _vm.rangeRestriction,
              })
            )}}),_c('p',{staticClass:"assessment-range-tip__teaser"},[_vm._v(" "+_vm._s(_vm.$t("supporters.assessment.rangeLimitNotice.content", { range: _vm.rangeRestriction, }))+" "),_c('ElButton',{attrs:{"type":"link"},on:{"click":_vm.rangeTipButtonClickHandler}},[_vm._v(" "+_vm._s(_vm.$t("supporters.assessment.rangeLimitNotice.cta"))+" ")])],1)],1)],1)],1):_vm._e()]),(!_vm.$screen.mdOnly && _vm.showIntegratedFooter)?_c('div',{staticClass:"assessment__footer"},[_c('div',{staticClass:"assessment__container"},[_c('div',{staticClass:"assessment__footer-left"},[_c('p',{staticClass:"assessment__footer-copyright"},[_vm._v(" "+_vm._s(_vm.$t("common.footer.copyright", { year: _vm.currentYear, }))+" ")])]),_c('div',{staticClass:"assessment__footer-right"},[_c('ElButton',{staticClass:"assessment__next-btn",attrs:{"size":"medium","type":"green"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("supporters.assessment.saveButton"))+" ")])],1)])]):_vm._e(),_c('RangeLevelsModal',{attrs:{"visibility":_vm.rangeLevelsModalVisible},on:{"update:visibility":function($event){_vm.rangeLevelsModalVisible=$event}}}),(_vm.$screen.mdOnly && _vm.showIntegratedFooter)?_c('MobileFooterNav',{attrs:{"loading":false},on:{"on-save":_vm.onSave}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }