




























































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import QuestionPanel from "@/modules/supporters/components/question-panel/question-panel.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { TranslateResult } from "vue-i18n";
import { EAffiliateGetter } from "@/modules/supporters/services/store/affiliate/affiliate.interface";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";

export default SupportersFlowMixin.extend({
  name: "CriteriaQuestions",

  components: {
    QuestionPanel,
    NavigationFooter,
  },

  data() {
    return {
      fields: {
        criteria: [],
      } as Partial<ISupporterFlow>,

      currentYear: new Date().getFullYear(),
      step: 0,
      hasResponse: false,
      currentResponse: {} as null | IMatchingResponse,
      textAreaLimit: 200,
    };
  },

  computed: {
    viewCopy(): TranslateResult {
      return this.$t("supporters.view.criteriaAssessment");
    },

    hasCurrentQuestion(): boolean {
      return !!this.currentQuestion;
    },

    isLastQuestion(): boolean {
      return this.hasCurrentQuestion && this.questions.length === this.step + 1;
    },

    currentQuestion(): IMatchingQuestion | null {
      return this.questions[this.step] || null;
    },

    nextQuestionButtonSettings(): any {
      const nextButton = {
        copy: (this.ctaCopy as any).continue,
        type: "green",
        icon: "arrow--right-white",
        iconSize: 14,
        disabled: false,
      };

      // If skip is not enabled, use next only
      if (!this.skipEnabled) {
        return this.hasResponse
          ? nextButton
          : {
              ...nextButton,
              disabled: true,
            };
      }

      return this.hasResponse
        ? nextButton
        : {
            copy: (this.ctaCopy as any).skipQuestion,
            type: "",
            icon: "arrow--right-grey",
            iconSize: 14,
            disabled: false,
          };
    },

    lastQuestionButtonSettings(): any {
      const nextButton = {
        copy: (this.ctaCopy as any).next,
        type: "primary",
        icon: "",
        iconSize: 14,
        disabled: false,
      };

      // If skip is not enabled, use next only
      if (!this.skipEnabled) {
        return this.hasResponse
          ? nextButton
          : {
              ...nextButton,
              disabled: true,
            };
      }

      return this.hasResponse
        ? nextButton
        : {
            copy: (this.ctaCopy as any).skipAndNext,
            type: "",
            icon: "arrow--right-grey",
            iconSize: 14,
            disabled: false,
          };
    },

    mainCtaSettings(): any {
      return this.isLastQuestion
        ? this.lastQuestionButtonSettings
        : this.nextQuestionButtonSettings;
    },

    questions(): Array<IMatchingQuestion> {
      return this.$store.getters[EAffiliateGetter.QUESTION_BUNDLE].filter(
        (question: IMatchingQuestion) => !question.is_team_member_question,
      );
    },
  },

  created() {
    if (this.flowData) {
      this.fields.criteria = [...this.flowData.criteria];

      if (this.$route.params?.step) {
        this.step = +this.$route.params?.step;
      }

      this.prefillCurrentResponse();
    }
  },

  methods: {
    /**
     * Method to convert response to field value.
     */
    storeResponseField() {
      if (!this.hasResponse || !this.currentResponse) {
        return;
      }

      // Update response field
      const filteredResponses = this.fields.criteria.filter(
        (response: IMatchingResponse) =>
          this.currentResponse &&
          response.question !== this.currentResponse.question,
      ) as Array<IMatchingResponse>;

      // Format value
      const formattedResponse = (
        !this.currentResponse.answers.length
          ? {
              question: this.currentResponse.question,
              value: this.currentResponse.value,
            }
          : {
              question: this.currentResponse.question,
              answers: this.currentResponse.answers,
            }
      ) as IMatchingResponse;

      this.fields.criteria = [...filteredResponses, formattedResponse];
    },

    /**
     * Handle next question button click.
     */
    onNextQuestionButtonClick() {
      // Advance to next question
      this.step += 1;
      this.prefillCurrentResponse();
    },

    /**
     * Handle previous question button click.
     */
    onPreviousQuestionButtonClick() {
      // If first question, return to previous page
      if (!this.step) {
        this.onReturnButtonClick();
        return;
      }

      this.step -= 1;
      this.prefillCurrentResponse();
    },

    /**
     * Handle main cta button click, which action differs if is last question
     */
    onMainCtaButtonClick() {
      // Add response to fields
      this.storeResponseField();

      if (this.isLastQuestion) {
        this.onNextButtonClick();
        return;
      }

      // Continue to next question
      this.onNextQuestionButtonClick();
    },

    /**
     * Update response check state based state received.
     * @param isValid
     */
    validateResponseState(isValid: boolean) {
      this.hasResponse = isValid;
    },

    /**
     * Prefill current response value, based on current step
     */
    prefillCurrentResponse() {
      this.currentResponse =
        this.fields.criteria.find(
          (response: IMatchingResponse) =>
            response.question === this.currentQuestion?.id,
        ) || null;
    },
  },
});
