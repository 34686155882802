import { render, staticRenderFns } from "./matching-card-tag-list.vue?vue&type=template&id=220b82e1&scoped=true&"
import script from "./matching-card-tag-list.vue?vue&type=script&lang=ts&"
export * from "./matching-card-tag-list.vue?vue&type=script&lang=ts&"
import style0 from "./matching-card-tag-list.vue?vue&type=style&index=0&id=220b82e1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220b82e1",
  null
  
)

export default component.exports