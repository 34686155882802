






























































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export interface ICompareDropdownOption {
  label: string;
  index: number;
  disabled: boolean;
}

export default Vue.extend({
  name: "CompareDropdowns",

  props: {
    options: {
      type: Array as () => ICompareDropdownOption[],
      required: true,
    },
  },
  data() {
    return {
      firstStage: null as null | number,
      secondStage: null as null | number,
    };
  },

  computed: {
    tooltipText(): TranslateResult {
      return this.$t("chartsDashboard.compare.tooltip");
    },

    isSelectDisabled(): boolean {
      return this.options.filter((option) => !option.disabled).length === 1;
    },
  },

  methods: {
    change() {
      this.$emit("change", [this.firstStage, this.secondStage]);
    },

    clear() {
      this.firstStage = null;
      this.secondStage = null;

      this.change();
    },
  },
});
