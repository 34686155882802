





















import Vue from "vue";
import { gaTrackEvent } from "@/services/utils/ga.utils";

export default Vue.extend({
  name: "SignUpModalSuccess",

  props: {
    registrationEmail: {
      type: String,
      required: true,
    },
  },

  mounted() {
    // Informs GA the user has registered
    gaTrackEvent("registered", "Self-Assessment");
  },
});
