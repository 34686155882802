

























import Vue from "vue";

export default Vue.extend({
  name: "StatsCard",

  props: {
    title: {
      type: String,
      default: null,
    },

    statValue: {
      type: Number,
      default: 0,
    },

    statTotal: {
      type: Number,
      default: 0,
    },

    description: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    iconSize: {
      type: Number,
      default: 40,
    },

    iconBackgroundColor: {
      type: String,
      default: "rgba(168, 111, 245, 0.8)",
    },
  },
});
