






























import Vue from "vue";

const PX_SCROLL_SELECTOR = ".px-panel__container";

export default Vue.extend({
  name: "PxPanel",
  props: {
    /**
     * Panel title.
     *
     * When the title is present the header sections
     * becomes visible.
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Allows to use an icon on the header.
     *
     * The icon is aligned to the left followed by the title,
     * aligned in the same way.
     */
    icon: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: "default",
    },

    /**
     * Remove panel borders.
     */
    noBorders: {
      type: Boolean,
      default: false,
    },

    /**
     * Make the panel having the same height and the
     * content becomes scrollable.
     */
    scrollable: {
      type: Boolean,
      default: false,
    },

    /**
     * Max height for the scrollable area.
     */
    maxHeight: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showShadow: false,
    };
  },

  computed: {
    showHeader(): boolean {
      return !!this.title || !!this.$slots.header;
    },

    customBodyStyle(): { [key: string]: string } {
      if (!this.maxHeight) {
        return {};
      }

      return { maxHeight: `${this.maxHeight}px` };
    },
  },

  watch: {
    async maxHeight() {
      await this.$nextTick();
      this.onScrollHandler();
    },
  },

  mounted() {
    if (this.scrollable) {
      this.trackScroll();
    }
  },

  methods: {
    trackScroll() {
      const target = this.$el.querySelector(PX_SCROLL_SELECTOR) as HTMLElement;
      target.addEventListener("scroll", this.onScrollHandler, {
        passive: true,
        capture: false,
      });
      window.addEventListener("resize", this.onScrollHandler, {
        passive: true,
        capture: false,
      });

      this.onScrollHandler();
    },

    /**
     * This is the handler that will be used for the scroll event.
     *
     * Here is made an evaluation if the bottom shadow is needed,
     * for that be true the scroll area less the element height
     * must be bigger than the current scroll made from top.
     */
    onScrollHandler() {
      const target = this.$el.querySelector(PX_SCROLL_SELECTOR) as HTMLElement;
      const maxScroll = target.scrollHeight - target.clientHeight;
      this.showShadow = target.scrollTop !== maxScroll;
    },
  },
});
