































































































































































import Vue from "vue";
import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "CompanyPanelEntrepreneur",

  props: {
    company: {
      type: Object as () => any,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
    userIsConnected: {
      type: Boolean,
      default: false,
    },
    userIsInterested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    canShowEmail(): boolean {
      const hasEmail = this.company.email || null;
      const hasPermission =
        this.$user.isOwner() ||
        (this.$user.isSupporter() && this.userIsConnected);

      return hasEmail && hasPermission;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },

  methods: {
    onClickFieldHandler(field?: string) {
      if (field) {
        this.$emit("focus-modal-field", field);
      } else {
        this.$emit("open-company-modal");
      }
    },
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },
  },
});
