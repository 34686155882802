import { Route } from "vue-router";
import store from "@/services/store";
import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { EAuthLatestAssessmentActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

/**
 * Fetch milestones when entering milestone planner route.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export const milestonesResolver = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  await store.dispatch(ECategoryActions.FETCH, {
    group: ENTREPRENEUR_USER_GROUP_ID,
  });

  const company: ICompany = store.get("auth/company.data");

  store.dispatch(EAuthMilestonesActions.FETCH_PARSED_DATA);
  store.dispatch(EAuthLatestAssessmentActions.FETCH, company.id);

  next();
};
