

























































































































































import chunk from "lodash/chunk";
import VirtualGrid, {
  IStatusChanger,
} from "@/modules/matching/components/virtual-grid/virtual-grid.vue";

import MatchingCard from "@/components/matching-card/matching-card.vue";
import MatchingCardSimplified from "@/components/matching-card/matching-card--simplified.vue";
import MatchingCardCompactListHeader from "@/components/matching-card/matching-card-compact-list-header.vue";
import MatchingCardCompact from "@/components/matching-card/matching-card-compact.vue";
import MatchingCardCompactPlaceholder from "@/components/matching-card/matching-card-compact-placeholder.vue";
import MatchingCardPlaceholder from "@/components/matching-card/matching-card-placeholder.vue";

import MatchingEmptyResults from "@/modules/matching/components/matching-empty-results/matching-empty-results.vue";
import MatchingFilters from "@/modules/matching/components/matching-filters/matching-filters.vue";
import MatchingResultsLoading from "@/modules/matching/components/matching-results-loading/matching-results-loading.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EMatchingActions } from "@/modules/matching/services/store/matching/matching.types";
import { EUserMetadataGetters } from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import { EProfileLatestAssessmentActions } from "@/modules/profile/services/store/profile/submodules/profile-latest-assessment/profile-latest-assessment-types";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { MATCHING_BADGE_DIRECTORY_LISTING } from "@/modules/matching/constants";
import MatchingListMixin from "@/mixins/matching-list.mixin";

import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_USER_TYPES,
} from "@/modules/matching/constants";
import {
  ENTREPRENEUR_USER_TYPE,
  SUPPORTER_USER_TYPE,
} from "@/modules/authentication/constants";

export default MatchingListMixin.extend({
  name: "MatchingListCards",

  components: {
    MatchingCard,
    MatchingCardSimplified,
    MatchingCardCompactListHeader,
    MatchingCardCompact,
    MatchingCardCompactPlaceholder,
    MatchingEmptyResults,
    MatchingFilters,
    VirtualGrid,
    MatchingCardPlaceholder,
    MatchingResultsLoading,
  },

  data() {
    return {
      minCompactedMatchesQuantity: 6,
      infiniteLoadingPlaceholderQuantity: 3,
      routeParams: this.$route.params,
      hasFilters: false,
      loadingMessage: null as any,
      calculationsPolling: null as NodeJS.Timeout | null,
    };
  },

  computed: {
    isLoading(): boolean {
      return (
        this.isDiscoverLoading &&
        (!this.hasDiscoverCards || this.hasChangedDiscoverFilters)
      );
    },

    isLoadingNewMatches(): boolean {
      return (
        (this.isLoading && (!this.expandedMatches.length || this.hasFilters)) ||
        // Force loading state when the user clears all filters to avoid showing a premature empty state:
        (this.hasNoCurrentMatches &&
          !this.hasFilters &&
          this.hasAlreadyRetrievedInitialResults)
      );
    },

    isLoadingInitialMatches(): boolean {
      return this.hasOnGoingCalculations && !this.hasDiscoverCards;
    },

    isCalculatingInitialResults(): boolean {
      return (
        this.isLoadingInitialMatches &&
        !this.isLoadingNewMatches &&
        !this.hasAlreadyRetrievedInitialResults
      );
    },

    /**
     * Verify the existence of ongoing matching calculations.
     *
     * @return {boolean}
     */
    hasOnGoingCalculations(): boolean {
      return this.$store.get("matching/recalculating") || false;
    },

    hasNoCurrentMatches(): boolean {
      return (
        !this.isLoading &&
        !this.hasDiscoverCards &&
        (!this.hasOnGoingCalculations || this.hasFilters)
      );
    },

    company(): ICompany | null {
      return this.$store.get("auth/company.data");
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    /**
     * Get companies uids that are already in the selected list
     */
    listsCompaniesUid(): Array<string> {
      return JSON.parse(this.routeParams?.companiesUids || "[]");
    },

    /**
     * List of expanded matches.
     */
    expandedMatches(): Array<IMatchingCard | null> {
      const paginatedMatches = this.matchCards.slice(
        0,
        this.expandedMatchesQuantity,
      );

      return this.hasDiscoverCards ? this.mapMatches(paginatedMatches) : [];
    },

    /**
     * List of compact version matches.
     *
     * This is only used when there is more than 6 matches.
     */
    compactMatches(): Array<IMatchingCard | null> {
      const paginatedMatches = this.matchCards.slice(
        this.expandedMatchesQuantity,
        this.matchCards.length,
      );

      return this.hasDiscoverCards ? this.mapMatches(paginatedMatches) : [];
    },

    hasCompactMatches(): boolean {
      return this.compactMatches.length > 0;
    },

    /**
     * Get the latest loaded page.
     */
    currentPage(): number {
      return this.$store.get("matching.page");
    },

    hasOnBoarded(): boolean {
      return (
        // Skip matching onboarding for Supporters as requested here:
        // https://pixelmatters.atlassian.net/browse/VIR-1198
        this.$user.isSupporter() ||
        // Else, keep onboarding for Entrepreneurs:
        !!this.$store.get(
          EUserMetadataGetters.GET,
          MATCHING_ONBOARDING_COMPLETION,
        )
      );
    },

    expandedMatchesQuantity(): number {
      if (this.$screen.xlUp) {
        return 8;
      }

      if (this.$screen.lgUp) {
        return 6;
      }

      return 4;
    },

    virtualListRowValue(): IMatchingCard[][] {
      return this.split(this.compactMatches, 2);
    },

    virtualGridGap(): number {
      return this.$screen.lgUp ? 16 : 40;
    },

    virtualRowHeight(): number {
      return this.$screen.lgUp ? 104 : 276;
    },

    virtualGridColumns(): number {
      // Matching Card and Compact view
      if (this.$screen.lgUp) {
        return 1;
      }

      // Matching Card and Card Simplified view (Tablet view and lower)
      if (this.$screen.mdUp) {
        return 2;
      }

      return 1;
    },

    matchUserType(): number {
      return this.$user.isEntrepreneur()
        ? SUPPORTER_USER_TYPE
        : ENTREPRENEUR_USER_TYPE;
    },

    emptyTitle(): string {
      return this.hasFilters
        ? "matching.matchingList.emptyState.filteringTitle"
        : "matching.matchingList.emptyState.title";
    },

    emptySubtitle(): string {
      return this.hasFilters
        ? "matching.matchingList.emptyState.filteringSubtitle"
        : "matching.matchingList.emptyState.subtitle";
    },

    emptyNoticeTitle(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.title"
        : "matching.matchingList.emptyState.noticeSupporter.title";
    },

    emptyNoticeSubtitle(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.subtitle"
        : "matching.matchingList.emptyState.noticeSupporter.subtitle";
    },

    emptyNoticeButton(): string {
      return this.$user.isEntrepreneur()
        ? "matching.matchingList.emptyState.notice.button"
        : "matching.matchingList.emptyState.noticeSupporter.button";
    },
  },

  watch: {
    hasOnGoingCalculations: {
      immediate: true,
      handler(isCalculating) {
        if (isCalculating) {
          this.longPollCalculations();

          this.$nextTick(() => {
            if (this.hasAlreadyRetrievedInitialResults || this.hasFilters) {
              this.showRecalculationInfo();
            }
          });
        } else if (this.calculationsPolling) {
          this.stopPollingCalculations();

          if (this.hasAlreadyRetrievedInitialResults || this.hasFilters) {
            // If this is a recalculation, then will deal with the toast messages:
            this.hideRecalculationInfo();
            this.displaySuccessfulRecalculationMessage();
          } else {
            // Fetch initial results:
            this.$store.dispatch(EMatchingActions.FETCH, this.discoverFilters);
          }
        }
      },
    },
  },

  created() {
    if (this.$user.isEntrepreneur() && this.companyId) {
      this.$store.dispatch(
        EProfileLatestAssessmentActions.FETCH,
        this.companyId,
      );
    }
  },

  methods: {
    /**
     * Fetch the next page of the matching score list.
     */
    async fetchMoreMatches($status: IStatusChanger) {
      const response = await this.$store.dispatch(EMatchingActions.FETCH, {
        ...this.discoverFilters,
        page: this.currentPage + 1,
      });

      response && !response.length ? $status.complete() : $status.ready();
    },

    /**
     * Split list into requested size
     * https://lodash.com/docs/4.17.15#chunk
     */
    split(list: Array<any>, size: number): any[][] {
      return chunk(list, size);
    },

    /**
     * Map matches:
     * - With their current disabled state
     */
    mapMatches(
      matches: Array<IMatchingCard | null>,
    ): Array<IMatchingCard | null> {
      return matches.map((matchingCard) => {
        if (matchingCard) {
          matchingCard.disabled = this.listsCompaniesUid.includes(
            matchingCard?.uid || "",
          );

          if (matchingCard.badges) {
            matchingCard.isDirectoryMember = matchingCard.badges.some(
              (badge) => badge.name === MATCHING_BADGE_DIRECTORY_LISTING,
            );
          }
        }

        return matchingCard;
      });
    },

    longPollCalculations() {
      this.calculationsPolling = setInterval(() => {
        this.$store.dispatch(EMatchingActions.FETCH_RECALCULATIONS_STATE);
      }, 6000);
    },

    stopPollingCalculations() {
      if (this.calculationsPolling) {
        clearInterval(this.calculationsPolling);
      }
    },

    /**
     * Display ongoing recalculation toast message.
     * Create poll for tracking recalculation completion.
     * Display successful recalculation toast on completion.
     */
    showRecalculationInfo() {
      this.loadingMessage = this.displayLoadingCalculationMessage();
    },

    hideRecalculationInfo() {
      this.loadingMessage.close();
    },

    /**
     * Display loading recalculation toast message.
     *
     * @return {*}
     */
    displayLoadingCalculationMessage(): any {
      return this.$message({
        message: this.$t(
          "matching.matchingList.recalculateCriteriaState.loading",
        ),
        customClass: "is-navbar is-loading",
        duration: 0,
      });
    },

    /**
     * Display successful recalculation toast message.
     */
    displaySuccessfulRecalculationMessage() {
      this.$message({
        message: this.getSuccessfulRecalculationMessageContent(),
        type: "success",
        customClass: "is-navbar",
        duration: 0,
      });
    },
  },

  beforeDestroy() {
    this.$message.closeAll();
  },

  destroyed() {
    this.stopPollingCalculations();
  },
});
