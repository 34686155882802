

























import Vue from "vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { MATCHING_FROM_ONBOARDING } from "@/modules/matching/constants";

export default Vue.extend({
  name: "MatchingStart",

  methods: {
    /**
     * Add meta to know that the user already visited this page and
     * redirect them to the matching view.
     */
    onClickGoToMatchingList() {
      this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_FROM_ONBOARDING,
        value: true,
      });
      this.$router.push({
        name: ROUTE_MATCHING_LIST,
        query: this.$route.query,
      });
    },
  },
});
