



































































































import Vue from "vue";

import cloneDeep from "lodash/cloneDeep";

import NetworksSelector from "@/components/networks-selector/networks-selector.vue";

import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import {
  formatMultiSectorsSelection,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";

import { networkProvider } from "@/services/data/network/network.provider";
import { INetwork } from "@/services/data/network/network.interface";
import { ESupporterInvestingLevelGetters } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import {
  allFormFieldsValid,
  generateSupporterLocationsValidator,
} from "@/services/errors/validator-generators";

export default Vue.extend({
  name: "SignUpStepSupporterInterests",

  components: {
    NetworksSelector,
    MultiSelector,
  },

  props: {
    supporter: {
      type: Object as () => any,
      required: true,
    },
  },

  data() {
    return {
      stepDisabled: false,
      hasSpecificLocations: false,

      data: {
        locations: [] as Array<IMultiSelection>,
        sectors: [] as Array<IMultiSelection>,
        networks: [] as Array<number>,
      },
      rules: {
        locations: generateSupporterLocationsValidator(
          this,
          "hasSpecificLocations",
        ),
      },
    };
  },

  computed: {
    signupFormRef(): any {
      return this.$refs.signupForm;
    },

    networkMetadata(): string | null {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.SINGLE_METADATA
      ]("network");
    },

    supporterInterests(): any {
      return {
        networks: this.data.networks,
        // Divide location's selection into grouped locations and google places
        ...formatMultiLocationsSelection(this.data.locations),
        // Divide sector's selection into sectors and grouped_sectors
        ...formatMultiSectorsSelection(this.data.sectors),
      };
    },
  },

  watch: {
    hasSpecificLocations(enabled: boolean) {
      const hasSelectedLocations = !enabled && this.data.locations.length;

      if (hasSelectedLocations) {
        this.data.locations = [];
      }
    },

    "supporterInterests.sectors"() {
      this.updateSubmitState();
    },

    "supporterInterests.networks"() {
      this.updateSubmitState();
    },
  },

  async created() {
    // Add network pre-fill using metadata
    if (this.networkMetadata) {
      const networks = (await networkProvider.list({
        search: this.networkMetadata,
      })) as Array<INetwork>;

      await this.$nextTick();
      const networkSelector = this.$refs.networkSelector as any;
      if (networkSelector && networks) {
        networkSelector.setActiveNetworks(cloneDeep(networks));
      }
    }
  },

  mounted() {
    this.signupFormRef.validateField("locations");
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    updateSubmitState() {
      this.stepDisabled = !allFormFieldsValid(this.signupFormRef, this.rules);

      if (!this.stepDisabled) {
        this.$emit("update:supporter", this.supporterInterests);
      }
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
