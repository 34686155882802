

























import Vue from "vue";

export default Vue.extend({
  name: "FeaturesShowcasePendingUserCTA",

  methods: {
    onClickHandler() {
      this.$root.$emit("open-signup-modal");
    },
  },
});
