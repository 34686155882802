import { IBaseState } from "@/services/store/store-types";

export type IMatchScoreDetails = {
  score: number;
  details: Array<IMatchDetails>;
};

export type IMatchDetails = {
  criteria: string;
  myAnswer: Array<string> | Array<number> | number | string;
  matchedAnswer: Array<string> | Array<number> | number | string;
  match: boolean;
  impact: number;
};

/**
 * Represents the state structure for the Match Score Details
 */
export interface IMatchScoreDetailsState extends IBaseState {
  /**
   * Array that stores all match score data.
   */
  data: Array<IMatchScoreDetails>;
}

export enum EMatchScoreDetailsState {
  NAMESPACE = "matchScoreDetails",
}

export enum EMatchScoreDetailsActions {
  FETCH = "matchScoreDetails/fetch",
}

export enum EMatchScoreDetailsMutations {
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_DATA = "SET_DATA",
}
