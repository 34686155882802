


































import Vue from "vue";

import FeatureCard from "@/modules/self-assessment/components/feature-card/feature-card.vue";
import { SUPPORTERS_ASSESSMENT } from "@/modules/supporters/services/router/routes-names";
import { ICategory } from "@/services/data/category/category.interface";
import { ESupporterInvestingLevelActions } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { defaultAffiliateId } from "@/services/configs";

import { ElMessageComponent } from "element-ui/types/message";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

let errorMessage: ElMessageComponent | null = null;

export default Vue.extend({
  name: "StartingPoint",

  components: {
    FeatureCard,
    LangSwitcher,
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Check if the categories are being loaded.
     */
    isLoading(): boolean {
      return this.$store.state.supporterInvestingLevel.loading;
    },

    hasError(): boolean {
      return (
        !!this.$store.state.supporterInvestingLevel.error ||
        !!this.$store.state.categories.error
      );
    },

    startButtonType(): string {
      return this.hasError ? "default" : "green";
    },

    startButtonText(): string {
      return this.hasError
        ? (this.$t("supporters.startingPoint.panel.button.error") as string)
        : (this.$t("supporters.startingPoint.panel.button.normal") as string);
    },

    startButtonIcon(): string | null {
      return this.hasError ? "icon-refresh" : null;
    },

    networkSlug(): string | null {
      return this.$route.query.network as string | null;
    },
  },

  watch: {
    hasError(value, oldVal) {
      if (value === oldVal) {
        return;
      }

      if (value === true) {
        this.showErrorMessage();
      } else if (errorMessage !== null) {
        errorMessage.close();
      }
    },
  },

  created() {
    // Clear affiliate navbar as supporter won't have an affiliate association
    this.resetAffiliate();

    // Clear any metadata present for the footer
    this.$store.dispatch(
      ESupporterInvestingLevelActions.REMOVE_METADATA,
      "footerClass",
    );

    // Add network slug metadata, if exists
    if (this.networkSlug) {
      this.$store.dispatch(ESupporterInvestingLevelActions.SET_METADATA, {
        key: "network",
        value: this.networkSlug,
      });
    } else {
      // Clear any network slug present in store
      this.$store.dispatch(
        ESupporterInvestingLevelActions.REMOVE_METADATA,
        "network",
      );
    }
  },

  methods: {
    /**
     * This will clear the affiliate navbar
     */
    resetAffiliate() {
      this.$store.dispatch(
        EViralLevelActions.FETCH_AFFILIATE,
        defaultAffiliateId(),
      );
    },

    showErrorMessage() {
      errorMessage = this.$message({
        message: this.$t("common.errors.alertTitle") as string,
        type: "error",
        duration: 0,
        customClass: "is-full",
      });
    },

    /**
     * Handle the click on the self assessment button.
     */
    onClickTakeAssessmentButton() {
      if (this.hasError) {
        this.$store.dispatch(ECategoryActions.FETCH, {
          group: SUPPORTER_USER_GROUP_ID,
        });
        return;
      }

      // This will reset the state of the module in order to
      // have a clean state before the user perform a new
      // self-assessment
      this.$store.dispatch(ESupporterInvestingLevelActions.RESET);
      this.$router.push({ name: SUPPORTERS_ASSESSMENT });
    },
  },
});
