


































import Vue from "vue";

import RenderlessEntrepreneurAssessment from "@/modules/common/components/renderless-entrepreneur-assessment/renderless-entrepreneur-assessment.vue";
import AssessmentDesktop from "@/modules/self-assessment/components/assessment-desktop/assessment-desktop.vue";
import AssessmentMobile from "@/modules/self-assessment/components/assessment-mobile/assessment-mobile.vue";
import { SELF_ASSESSMENT_RESULTS } from "@/modules/self-assessment/services/router/routes-names";

export default Vue.extend({
  name: "Assessment",
  components: {
    RenderlessEntrepreneurAssessment,
    AssessmentDesktop,
    AssessmentMobile,
  },

  props: {
    /**
     * This prop allows to the the end action for when the
     * user riches the end of the assessment.
     */
    endAction: {
      type: Function as () => any,
      default() {
        this.$router.push({ name: SELF_ASSESSMENT_RESULTS });
      },
    },

    needsSubmissionConfirmation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },
  },
});
