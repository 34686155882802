























import Vue from "vue";

export interface IInsightData {
  title: string;
  description: string;
  emoji: string;
}

export default Vue.extend({
  name: "InsightsCard",

  props: {
    title: {
      type: String,
      default: "chartsDashboard.insightsCard.title",
    },

    insights: {
      type: Array as () => IInsightData[],
      required: true,
    },
  },
});
