




































import Vue from "vue";

import SignInModal from "@/components/sign-in-modal/sign-in-modal.vue";
import EntrepreneurSignUpModal from "@/modules/affiliates/components/sign-up-modal/entrepreneur-sign-up-modal.vue";
import SupporterSignUpModal from "@/modules/affiliates/components/sign-up-modal/supporter-sign-up-modal.vue";
import AffiliateAssessmentEntrepreneur from "@/modules/affiliates/views/affiliate-assessment/affiliate-assessment-entrepreneur.vue";

import { IUser } from "@/services/data/user/user.interface";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { AFFILIATE_TARGET_ENTREPRENEUR } from "@/services/data/affiliate/affiliate.interface";

export default Vue.extend({
  name: "AffiliateAssessment",

  components: {
    SignInModal,
    EntrepreneurSignUpModal,
    SupporterSignUpModal,
    AffiliateAssessmentEntrepreneur,
  },

  props: {
    isSelfAssessment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showRegistrationForm: false,
      isInvalidAccount: false,
    };
  },

  computed: {
    authUser(): IUser | null {
      return this.$store.get("auth/user");
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliatesDataLoading(): IAffiliatesState {
      return this.$store.state.affiliates.loading;
    },

    showModals(): boolean {
      return (
        (!!this.authUser &&
          (this.isSupporterInWrongFlow || this.isEntrepreneurInWrongFlow)) ||
        this.$store.state.auth.loading ||
        (!this.authUser && !this.isPendingUser)
      );
    },

    isPendingUser(): boolean {
      return this.$user.isPendingUser() || this.$user.isPendingSupporter();
    },

    isSupporter(): boolean {
      return !!this.$store.get("auth/supporter.data");
    },

    hasCompanyData(): boolean {
      return !!this.$store.get("auth/company.data");
    },

    flowData(): ISupporterFlow {
      return this.$store.getters[SupporterFlowState.Getter.VALUE];
    },

    isEntrepreneurAffiliate(): boolean {
      return (
        this.affiliatesData.affiliate?.flow_target ===
        AFFILIATE_TARGET_ENTREPRENEUR
      );
    },

    isSupporterInWrongFlow(): boolean {
      return this.$user.isSupporter() && this.isEntrepreneurAffiliate;
    },

    isEntrepreneurInWrongFlow(): boolean {
      return this.$user.isEntrepreneur() && !this.isEntrepreneurAffiliate;
    },

    hasLoginQueryParam(): boolean {
      return !!this.$route.query.login;
    },

    error(): boolean {
      return this.$store.get("affiliates/error");
    },
  },

  watch: {
    /**
     * Allows showing login modal directly
     */
    hasLoginQueryParam: {
      immediate: true,
      handler(showLoginModal: boolean) {
        this.showRegistrationForm = showLoginModal;
      },
    },

    showModals: {
      immediate: true,
      async handler(newValue, prevValue) {
        if (!newValue && prevValue) {
          await this.loadRequiredData();
        }
      },
    },
  },

  methods: {
    updateSignInModalVisibility() {
      this.showRegistrationForm = !this.showRegistrationForm;
    },

    async loadRequiredData() {
      await this.$store.dispatch(EAffiliatesActions.LOAD_SUBMISSION);

      if (this.error) this.goToErrorPage();
    },

    goToErrorPage() {
      this.$router.replace({
        name: ERROR_ROUTE,
        query: {
          code: ERROR_TYPES.NOT_FOUND,
        },
      });
    },

    checkInvalidAccount() {
      if (this.isEntrepreneurAffiliate) {
        this.isInvalidAccount = !!this.isSupporter;
        return;
      }

      if (!this.isSupporter || !this.hasCompanyData || !!this.flowData?.token) {
        this.isInvalidAccount = true;
        return;
      }
    },
  },
});
