

























import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

export default Vue.extend({
  name: "ListShareClipboardButton",

  props: {
    clipboard: {
      type: [String, Number],
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isTooltipVisible: false,
    };
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },
  },

  methods: {
    flashTooltip() {
      this.isTooltipVisible = true;
      setTimeout(() => {
        this.isTooltipVisible = false;
      }, 1000);
    },

    copyToClipboard() {
      const clipboardElement = this.$refs.clipboard as HTMLInputElement;
      clipboardElement.setAttribute("type", "text");
      clipboardElement.select();
      document.execCommand("copy");
      clipboardElement.setAttribute("type", "hidden");
      (window as any).getSelection().removeAllRanges();
    },

    onClickButtonHandler() {
      // Show tooltip momentarily
      this.flashTooltip();

      // Copy to clipboard:
      // As seen at: https://codepen.io/PJCHENder/pen/jamJpj
      this.copyToClipboard();
    },
  },
});
