


















import Vue from "vue";

// TODO: Move this to generic components and merge it with px-sticky-bar component. Rename it to StickyFooter.
export default Vue.extend({
  name: "NavigationFooter",
});
