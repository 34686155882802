




import Vue from "vue";

import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { NotFoundProviderException } from "@/services/data/exceptions/not-found-provider.exception";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";

export default Vue.extend({
  name: "AuthVerifyAccount",

  created() {
    this.verifyAccount();
  },

  methods: {
    /**
     * Make an API request to validate the user account with the token
     * sent by email.
     *
     * If the token is correct just redirect to the profile
     * with a session set.
     */
    async verifyAccount() {
      const validationHash = this.$route.params.hash as string;

      try {
        await this.$store.dispatch(EAuthActions.VALID_EMAIL, validationHash);

        this.$router.replace({ name: ROUTE_PROFILE });
        this.$message({
          message: this.$t("authentication.verifyAccount.message") as string,
          type: "info",
          customClass: "is-full",
        });
      } catch (error) {
        if (error instanceof NotFoundProviderException) {
          this.$router.replace({ name: ROUTE_AUTHENTICATION_LOGIN });
          return;
        }

        throw error;
      }
    },
  },
});
