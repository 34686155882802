import { render, staticRenderFns } from "./submissions-info-badges.vue?vue&type=template&id=65f95a90&scoped=true&"
import script from "./submissions-info-badges.vue?vue&type=script&lang=ts&"
export * from "./submissions-info-badges.vue?vue&type=script&lang=ts&"
import style0 from "./submissions-info-badges.vue?vue&type=style&index=0&id=65f95a90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f95a90",
  null
  
)

export default component.exports