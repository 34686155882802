








































import Vue from "vue";

import ClipboardButton from "@/components/clipboard-button/clipboard-button.vue";

import { LocaleMessages } from "vue-i18n";

export default Vue.extend({
  name: "ShareModalActions",

  components: {
    ClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },

    actionsLabel: {
      type: Array as () => string[],
      default: () => ["Copy Passcode", "Copy Link"],
    },

    settingsLinkCopy: {
      type: String,
      default: "common.linkSettings",
    },

    copyLinkButtonCopy: {
      type: String,
      default: "common.copyLink",
    },

    passcodeButtonCopy: {
      type: String,
      default: "common.copyPasscode",
    },

    copy: {
      type: String,
      default: "milestonePlanner.shareModal.footer",
    },
  },

  computed: {
    isRestrictedByPasscode(): number {
      // Convert passcode length to a numeric boolean (empty = 0 / not-empty = 1)
      return +!!this.passcode.length;
    },

    actionsCopy(): LocaleMessages {
      return this.$t(this.copy) as LocaleMessages;
    },
  },

  methods: {
    onClickSettingHandler() {
      this.$root.$emit("toggle-settings", true);
    },
  },
});
