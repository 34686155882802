



























import Vue from "vue";

export default Vue.extend({
  name: "PxExpand",

  props: {
    /**
     * Set the expand trigger as a toggler
     * or hide it upon the expand event
     */
    toggle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    triggerVisible(): boolean {
      return !(!this.toggle && this.expanded);
    },
  },
});
