import { Route } from "vue-router";
import store from "@/services/store";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { PROFILE_META_TAB_MENU_ITEMS } from "@/modules/profile/constants";

/**
 * Remove items from the mobile tabs menu.
 *
 * @param to Route where the user is navigating to
 * @param from Route where the user is navigating from
 * @param next Next function
 */
export const removeTabsItems = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  await store.dispatch(EMetaActions.REMOVE, PROFILE_META_TAB_MENU_ITEMS);
  next();
};
