

















































































































import Vue from "vue";
import {
  SELF_ASSESSMENT_ASSESSMENT,
  SELF_ASSESSMENT_RESULTS,
} from "@/modules/self-assessment/services/router/routes-names";

import { SUPPORTERS_ASSESSMENT } from "@/modules/supporters/services/router/routes-names";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { getAppPartner, isPartner } from "@/services/utils/utils";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

// Distance that the user needs to scroll to collapse the header
const COLLAPSE_DISTANCE = 47;

// Distance in milliseconds that a scroll event must occur
const DEBOUNCE_TIME = 300;

// Store the last computed scroll position. This will be used to
// decide if the menu should be collapsed of fully presented.
let scrollState = 0;
let scrollDelta = 0;
let isGoingDown = true;

// Informs it's currently waiting for a scroll event to be called
let isWaitingCall = false;

const TRIGGER_EVENT = "ontouchstart" in window ? "touchmove" : "wheel";

const FIXED_CONTAINER_SELECTOR = ".mobile-top-navbar__wrapper";
const LOGO_SELECTOR = ".mobile-top-navbar__logo";
const CONTENT_SELECTOR = ".mobile-top-navbar__content";

export default Vue.extend({
  name: "MobileTopNavbar",

  props: {
    /**
     * Affiliate logo.
     */
    affiliateLogo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      // Height that the root element must fill
      rootHeight: 0,
      // This informs if the navbar is collapsed
      isSmall: false,
      // his informs if the mobile menu is open
      isMobileMenuOpen: false,
    };
  },

  computed: {
    /**
     * Informs if the mobile menu should collapse on scroll.
     */
    isToCollapse(): boolean {
      // TODO: check the router where we are in to decide how to behave
      return false;
    },

    /**
     * Check if the navbar subtitle must be visible on this page.
     */
    isToShowSubTitle(): boolean {
      return [
        SELF_ASSESSMENT_ASSESSMENT,
        SUPPORTERS_ASSESSMENT,
        SELF_ASSESSMENT_RESULTS,
      ].includes(this.$route.name as string);
    },

    /**
     * Check we are in the assessment page.
     */
    isAssessment(): boolean {
      return (
        this.$route.name === SELF_ASSESSMENT_ASSESSMENT ||
        this.$route.name === SUPPORTERS_ASSESSMENT
      );
    },

    /**
     * Gets the viralLevelStore
     */
    viralLevelStore(): IViralLevelState {
      return this.$store.state.viralLevel as IViralLevelState;
    },

    /**
     * Informs if the affiliate logo must be visible.
     */
    showAffiliateLogo(): boolean {
      return !!this.affiliateLogo && !this.viralLevelStore.loading;
    },

    /**
     * Gives the main logo that must be used.
     */
    mainLogo(): string {
      return this.showAffiliateLogo
        ? "/img/logos/abaca-logo--dark.svg"
        : "/img/logos/abaca-logo--white.svg";
    },

    howItWorksLink(): any {
      return {
        url: "https://abaca.app/how-it-works",
        title: this.$t("common.navBar.howItWorks"),
      };
    },

    isPartner(): boolean {
      return isPartner;
    },

    getPartnerName(): string {
      return getAppPartner();
    },

    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    isVisitorNavigation(): boolean {
      return !this.company || !this.$user.isLogged();
    },
  },

  /**
   * Add some DOM listeners to control the collapse and height
   * of the navbar.
   */
  async mounted() {
    // Add a listener to compute the navbar height after
    // the logo loading.
    const logoElement = this.$el.querySelector<HTMLImageElement>(LOGO_SELECTOR);

    if (logoElement) {
      logoElement.addEventListener("load", this.updateNavHeight);
    }

    // Add a listener to the wheel in order to we compute the scroll
    // distances and see if the navbar state must be toggled.
    // https://github.com/vuejs/vue/pull/6856
    document.addEventListener(TRIGGER_EVENT, this.onScroll.bind(this), {
      capture: true,
      passive: true,
    });
  },

  /**
   * Remove all DOM listeners before destroying the component.
   */
  beforeDestroy() {
    document.removeEventListener(TRIGGER_EVENT, this.onScroll.bind(this));
  },

  methods: {
    /**
     * Update the navbar root height.
     *
     * With this technic we avoid to need to setup a manual padding
     * in each page that this component is in. The base component
     * will always fill out the space occupied by the fixed element.
     */
    updateNavHeight() {
      const containerElement = this.$el.querySelector<HTMLElement>(
        FIXED_CONTAINER_SELECTOR,
      );

      if (!containerElement) {
        return;
      }

      const contentElement =
        containerElement.querySelector<HTMLElement>(CONTENT_SELECTOR);

      if (!contentElement) {
        return;
      }

      this.rootHeight =
        containerElement.offsetHeight - contentElement.offsetHeight;
    },

    /**
     * Determines if the tolerance has been exceeded.
     */
    toleranceExceeded(): boolean {
      return Math.abs(scrollDelta) >= COLLAPSE_DISTANCE;
    },

    /**
     * Determines if is appropriate to expand the tab bar.
     */
    shouldExpand(isToleranceExceeded: boolean): boolean {
      return isGoingDown && isToleranceExceeded;
    },

    /**
     * Determines if is appropriate to retract the tab bar.
     */
    shouldRetract(isToleranceExceeded: boolean): boolean {
      return !isGoingDown && isToleranceExceeded;
    },

    scrollTrigger() {
      const currentScroll = window.pageYOffset;

      // If the scroll is near the top the tab bar must expand
      if (currentScroll <= COLLAPSE_DISTANCE) {
        this.isSmall = false;
        scrollDelta = 0;
      }

      const isToleranceExceeded = this.toleranceExceeded();
      const immediateDelta = scrollState - currentScroll;
      isGoingDown = immediateDelta > 0;

      // Reset scroll delta when the user changes the scroll direction
      if (
        (isGoingDown && scrollDelta < 0) ||
        (!isGoingDown && scrollDelta > 0)
      ) {
        scrollDelta = 0;
      } else {
        scrollDelta += immediateDelta;
      }

      if (this.shouldRetract(isToleranceExceeded)) {
        this.isSmall = true;
      } else if (this.shouldExpand(isToleranceExceeded)) {
        this.isSmall = false;
      }

      // Save current scroll position and the scroll direction
      scrollState = currentScroll;
    },

    /**
     * Handle browser scroll event call.
     *
     * Use a throttle strategy to minimize the performance costs.
     */
    onScroll(event: TouchEvent | MouseEvent) {
      const isScrollingMobileMenu = event.target === this.$refs.mobileMenu;
      if (isWaitingCall || isScrollingMobileMenu) {
        return;
      }

      isWaitingCall = true;
      setTimeout(() => {
        this.scrollTrigger();
        isWaitingCall = false;
        this.isMobileMenuOpen = false;
      }, DEBOUNCE_TIME);
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    toggleMenu(event?: TouchEvent) {
      // Prevent Chrome Desktop from firing a follow-up mouse event
      if (event) {
        event.preventDefault();
      }

      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },

    touchMobileMenuHandler(event: TouchEvent) {
      event.stopImmediatePropagation();
      return false;
    },
  },
});
