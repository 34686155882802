


























































import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import GridLegend from "@/modules/milestone-planner/components/grid-legend/grid-legend.vue";
import LastAssessmentDate from "@/modules/milestone-planner/components/last-assessment-date/last-assessment-date.vue";
import MilestonesFullGrid from "@/modules/milestone-planner/components/milestones-full-grid/milestones-full-grid.vue";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import moment from "moment";
import { IGridCategory } from "../components/milestones-grid/milestones-grid.interface";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "../services/router/routes-names";
import { ILevel } from "@/services/data/level/level.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

export default Vue.extend({
  name: "MilestonePlannerFullGrid",

  components: {
    GridLegend,
    LastAssessmentDate,
    MilestonesFullGrid,
  },

  static: {
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
    collapseButton: {
      icon: "minimize",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  computed: {
    company(): ICompany {
      return this.$store.get("auth/company.data") as ICompany;
    },

    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("auth/milestones.parsedData");
    },

    authLastAssessment(): IAssessment {
      return this.$store.get("auth/latestAssessment.data");
    },

    lastAssessmentDate(): string {
      return moment(this.authLastAssessment?.updated_at).format(
        "DD MMMM YYYY, HH:MMA",
      );
    },
  },

  methods: {
    collapseFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },

    printGrid() {
      window.print();
    },
  },
});
