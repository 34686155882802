

























import Vue from "vue";

export default Vue.extend({
  name: "MatchingCardWrapper",

  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },

    isFaded: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
