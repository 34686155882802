






























































import { ILevel } from "@/services/data/level/level.interface";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import * as particleRender from "@/utils/particle.render";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { TranslateResult } from "vue-i18n";
import {
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
  QUICK_LEVEL_FROM,
} from "@/modules/common/constants";

export default ElDialogMixin.extend({
  name: "UpgradeModal",

  props: {
    isLevelUp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    finalLevel(): ILevel {
      return this.$store.get("viralLevel.finalLevel");
    },

    isFirstAssessment(): boolean {
      return this.$store.get(
        "meta/get",
        QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
      );
    },

    congratsMsg(): TranslateResult {
      return this.isFirstAssessment
        ? this.$t("common.components.updateModal.congrats.firstAssessment")
        : this.isLevelUp
        ? this.$t("common.components.updateModal.congrats.levelUp")
        : this.$t("common.components.updateModal.congrats.levelDown");
    },

    /**
     * Check if the user is coming from the matching.
     */
    isBackToMatching(): boolean {
      const toRoute = this.$store.get("meta/get", QUICK_LEVEL_FROM);
      return toRoute === ROUTE_MATCHING_LIST;
    },
  },

  watch: {
    async innerVisibility(newVal) {
      if (!newVal) {
        return;
      }
      await this.$nextTick();
      if (this.isLevelUp) {
        this.playAnimation();
      }
    },
  },

  methods: {
    onClickBackToMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },

    onBeforeCloseModal(close: any) {
      this.$emit("close");
      close();
    },

    playAnimation() {
      const canvasElement = this.$el.querySelector(
        ".upgrade-modal__animation",
      ) as HTMLCanvasElement;
      const bodyElement = this.$el.querySelector(
        ".el-dialog__body",
      ) as HTMLElement;
      const elementWidth = bodyElement.clientWidth;
      const elementHeight = bodyElement.clientHeight;

      const context = particleRender.createContext(
        canvasElement,
        elementWidth,
        elementHeight,
      );
      const centerPoint = particleRender.getCanvasCenterPoint(context);
      particleRender.addConfettiParticles(
        context,
        30,
        centerPoint.x - 10,
        centerPoint.y,
      );
      particleRender.render(context);
    },
  },
});
