




























import Vue from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default Vue.extend({
  name: "MilestoneCategoryNavigation",

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },
    currentCategory: {
      type: Object as () => IGridCategory,
      required: true,
    },
  },

  data() {
    return {
      currentCategoryIndex: this.currentCategory.order,
      selectedCategory: this.currentCategory.name,
    };
  },

  watch: {
    currentCategory: function (category: IGridCategory) {
      this.selectedCategory = category.name;
    },
  },

  methods: {
    changeCurrentCategoryIndexBy(direction: number) {
      this.currentCategoryIndex += direction;

      // Get the new category
      const newCategory = this.categories.filter(
        (category) => category.order === this.currentCategoryIndex,
      );

      if (newCategory) {
        this.selectedCategory = newCategory[0].name;
        this.$emit("change", newCategory[0]);
      }
    },
  },
});
