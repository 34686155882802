








import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Object as () => any,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: {
        value: "",
      },
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal: any) {
        if (!newVal) {
          this.values.value = "";
        } else {
          const currentValue = parseFloat(this.values.value.replace(/,/g, ""));

          if (newVal.value !== currentValue) {
            this.values = this.value;
          }
        }
      },
    },

    values: {
      deep: true,
      handler(newVal: any) {
        if (newVal.value === "") {
          return;
        }

        const newValues = {
          value: parseFloat(newVal.value.replace(/,/g, "")),
        };

        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("input", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid = (values.value !== null && !!values.value) as boolean;

      this.$emit("update:isValid", isValid);
    },
  },
});
