









































import Vue from "vue";
import MilestoneLevelCell from "@/modules/milestone-planner/components/milestone-level-cell/milestone-level-cell.vue";
import MilestoneTooltip from "@/modules/milestone-planner/components/milestone-tooltip/milestone-tooltip.vue";
import { IGridMilestone } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default Vue.extend({
  name: "MilestoneLevelNavigation",

  components: {
    MilestoneLevelCell,
    MilestoneTooltip,
  },

  props: {
    categoryColor: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    activeLevel: {
      type: Number,
      required: true,
    },
    milestones: {
      type: Array as () => IGridMilestone[],
      required: true,
    },
  },

  data() {
    return {
      selectedLevel: this.activeLevel,
    };
  },

  computed: {
    milestonesSortedByLevel(): Array<any> {
      return [...this.milestones].sort((a, b) => b.level - a.level);
    },
  },

  watch: {
    activeLevel: function (level) {
      this.selectedLevel = level;
    },
  },

  methods: {
    changeLevel(level: number) {
      if (level !== this.selectedLevel) {
        this.selectedLevel = level;
        this.$emit("change", level);
      }
    },
  },
});
