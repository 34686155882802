var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milestone-carousel"},[_c('div',{staticClass:"milestone-carousel__grid"},[_c('ElCarousel',{ref:"carousel",attrs:{"height":"310px","autoplay":false,"arrow":"always","indicator-position":"none","type":"card","initial-index":_vm.currentPosition - 1,"data-testid":"milestone-carousel"},on:{"change":_vm.changeCarouselPosition}},[_c('GridLevels',{attrs:{"levels-count":_vm.levelsCount,"current-level":_vm.currentLevel,"data-testid":"grid-levels"}}),_vm._l((_vm.categories),function(category,index){return _c('ElCarouselItem',{key:category.name,ref:"carousel-items",refInFor:true,staticClass:"milestone-carousel__category",class:[
          {
            'milestone-carousel__category--right':
              _vm.changeRightItemTransformStyles(category.order),
            'milestone-carousel__category--left':
              _vm.changeLeftItemTransformStyles(category.order),
          } ],attrs:{"name":category.name}},[_c('div',{staticClass:"milestone-carousel__category-name",on:{"click":function($event){return _vm.$emit('hide-carousel')}}},[_c('p',[_vm._v(_vm._s(category.name))]),_c('img',{attrs:{"src":"/img/icons/minimize--black.svg","alt":"minimize"}})]),_c('div',{staticClass:"milestone-carousel__wrapper"},[_c('div',{staticClass:"milestone-carousel__milestone"},_vm._l((category.milestones),function(milestone){return _c('ElPopover',{key:milestone.level,attrs:{"data-testid":("milestone-" + index + "-" + (milestone.level) + "-popover"),"popper-class":"px-popover--light milestone-cell-popover","placement":"top","close-delay":0},on:{"show":function($event){return _vm.onTooltipShow(milestone)},"hide":_vm.onTooltipHide}},[_c('MilestoneTooltip',{attrs:{"category-name":category.name,"category-color":category.color,"level-number":milestone.level,"target-date":milestone.targetDate,"milestone-description":milestone.description,"is-milestone-critical":milestone.critical,"is-future-milestone":milestone.future,"is-milestone-in-progress":milestone.inProgress,"is-milestone-planned":milestone.planned,"is-milestone-complete":milestone.completed,"has-evidence":milestone.evidenceProvided,"is-visitor":_vm.isVisitor,"data-testid":("milestone-" + index + "-" + (milestone.level) + "-tooltip")},on:{"complete":function($event){return _vm.onMilestoneCompleteClick(category.id, milestone.level)},"plan":function($event){return _vm.onMilestonePlanClick(category.id, milestone.level)}}}),_c('MilestoneCell',{key:milestone.level,class:{
                  'milestone-carousel__milestone--blurred':
                    _vm.isMilestoneBlurred(milestone),
                },attrs:{"slot":"reference","data-testid":("milestone-" + index + "-" + (milestone.level) + "-cell"),"color":category.color,"completed":milestone.completed,"critical":milestone.critical,"evidence-provided":milestone.evidenceProvided,"future":milestone.future,"in-progress":milestone.inProgress,"planned":milestone.planned},slot:"reference"})],1)}),1),_c('MilestoneCarouselDescription',{attrs:{"data-testid":"milestone-carousel-description","category":category}})],1)])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }