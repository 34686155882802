

























import Vue from "vue";

export default Vue.extend({
  name: "AssessmentPanelLevelInactive",

  props: {
    allowLevelUpdate: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClickUpdateLevelBtn() {
      this.$emit("request-new-assessment");
    },
  },
});
