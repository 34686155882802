































































import Vue from "vue";

export interface IChartsComparisonTableHeader {
  title: string;
  tooltip?: string;
}

export default Vue.extend({
  name: "ChartsComparisonTable",

  props: {
    headers: {
      type: Array as () => IChartsComparisonTableHeader[],
      required: true,
    },

    labels: {
      type: Array as () => string[],
      required: true,
    },

    colors: {
      type: Array as () => string[],
      required: true,
    },

    data: {
      type: Array as () => any[],
      required: true,
    },
  },

  data() {
    return {
      tableScrollAtEnd: false,
      table: (null as any) || null,
      visibleElements: 5,
    };
  },

  computed: {
    tooltipText(): string {
      return this.$t("chartsDashboard.table.averageTooltip") as string;
    },

    isGradientVisible(): boolean {
      return this.data.length > this.visibleElements && !this.tableScrollAtEnd;
    },
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.chartsTable as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },

    getDifference(firstValue: number, secondValue: number): string {
      if (firstValue === secondValue) return "0%";

      const classToAdd = firstValue > secondValue ? "positive" : "negative";

      return `<span class="${classToAdd}">${Math.abs(
        firstValue - secondValue,
      ).toFixed(1)}%</span>`;
    },
  },
});
