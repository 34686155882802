




















import OverallLevel from "@/modules/milestone-planner/components/overall-level/overall-level.vue";
import MilestonesSection from "@/modules/milestone-planner/components/milestones-section/milestones-section.vue";
import { ILevel } from "@/services/data/level/level.interface";
import MilestonesMixin from "@/mixins/milestones.mixin";

export default MilestonesMixin.extend({
  name: "OverviewSidebar",

  components: {
    OverallLevel,
    MilestonesSection,
  },

  props: {
    latestAssessmentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },
});
