
import Vue from "vue";

import { ICategory } from "@/services/data/category/category.interface";
import {
  ESupporterInvestingLevelActions,
  ESupporterInvestingLevelGetters,
  ISupporterInvestingLevelState,
} from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

import {
  SUPPORTERS_INVESTING_RANGE_LIMIT,
  SUPPORTERS_INVESTING_RANGE_META,
} from "@/modules/supporters/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";

export default Vue.extend({
  name: "RenderlessSupporterAssessment",

  props: {
    data: {
      type: Array as () => Array<number>,
      default: () => [],
    },

    endAction: {
      type: Function as () => any,
      default: () => null,
    },
  },

  computed: {
    supporterInvestingLevelState(): ISupporterInvestingLevelState {
      return this.$store.state.supporterInvestingLevel;
    },

    /**
     * Index of the current selected category.
     */
    selectedCategory(): number {
      return this.supporterInvestingLevelState.selectedCategory;
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.supporterInvestingLevelState.levels;
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.SELECTED_STEP_DATA
      ];
    },

    /**
     * Get the category for the current step.
     */
    currentCategory(): ICategory | null {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.CURRENT_CATEGORY
      ];
    },

    isFirstInteraction(): boolean {
      return this.$store.state.supporterInvestingLevel.firstInteraction;
    },
  },

  watch: {
    data(value: Array<number>) {
      if (!value || value.length === 0 || !this.currentCategory) {
        return;
      }

      // Prefill investing level range
      this.handleLevelSliderChange(value);
    },
  },

  async created() {
    // Fetch VIRAL categories and levels
    await this.fetchCategories();
    await this.fetchLevels();

    // Reset VIRAL data for the Supporter state store
    this.$store.dispatch(ESupporterInvestingLevelActions.RESET);

    // Prefill investing level range
    await this.setInvestingRangeLevel();
  },

  methods: {
    async fetchCategories() {
      await this.$store.dispatch(ECategoryActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },

    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH);
    },

    /**
     * Handle the click on the save range button.
     */
    onSaveHandler() {
      this.$store.dispatch(EMetaActions.SET, {
        key: SUPPORTERS_INVESTING_RANGE_META,
        value: this.selectedStepData.level,
        group: SUPPORTER_USER_GROUP_ID,
      });

      this.endAction();
    },

    handleLevelSliderChange(newValue: Array<number>) {
      if (!this.currentCategory) {
        throw new Error("Invalid state");
      }

      this.$store.dispatch(ESupporterInvestingLevelActions.SET_LEVEL, {
        category: this.currentCategory.id,
        level: newValue,
      });

      this.$store.dispatch(ESupporterInvestingLevelActions.INTERACTION);
    },

    async setInvestingRangeLevel() {
      if (!this.currentCategory) {
        throw new Error("Invalid state");
      }

      // Allow setting directly value from prop and prefill
      if (Array.isArray(this.data) && this.data.length > 0) {
        await this.$store.dispatch(ESupporterInvestingLevelActions.SET_LEVEL, {
          category: this.currentCategory.id,
          level: [...this.data],
        });
      } else if (this.$user.isOwner()) {
        // Set current investing range for the auth user
        const currentUserInvestingRangeLevel = this.$store.get(
          "profile/supporter.data.investing_level_range",
        );

        if (currentUserInvestingRangeLevel) {
          await this.$store.dispatch(
            ESupporterInvestingLevelActions.SET_LEVEL,
            {
              category: this.currentCategory.id,
              level: currentUserInvestingRangeLevel,
            },
          );
        }
      }
    },
  },

  render(): any {
    if (this.$scopedSlots.default === undefined) {
      return;
    }

    return this.$scopedSlots.default({
      currentCategory: this.currentCategory,
      selectedStep: this.selectedStepData,
      onLevelChange: this.handleLevelSliderChange,
      selectedCategory: this.selectedCategory,
      levels: this.levels,
      onSaveHandler: this.onSaveHandler,
      isFirstInteraction: this.isFirstInteraction,
      rangeRestriction: SUPPORTERS_INVESTING_RANGE_LIMIT,
    });
  },
});
