




































import Vue from "vue";
import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import SubmissionsPanel from "@/modules/submissions/components/submissions-panel/submissions-panel.vue";

import { TranslateResult } from "vue-i18n";
import { VILCAP_PROGRAMS_PAGE } from "@/modules/submissions/constants";
import {
  IAffiliateSubmissions,
  IAffiliateSubmission,
} from "@/modules/submissions/services/data/submissions/submissions.interface";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "UserSubmissionsView",

  components: {
    PxPageHeader,
    SubmissionsPanel,
  },

  computed: {
    headerCopy(): TranslateResult {
      return this.$t("userSubmissions.header");
    },

    userSubmissions(): IAffiliateSubmissions | null {
      return this.$store.state.affiliates.userSubmissions || null;
    },

    draftSubmissions(): Array<IAffiliateSubmission> {
      return this.userSubmissions?.drafts || [];
    },

    submittedSubmissions(): Array<IAffiliateSubmission> {
      return this.userSubmissions?.submitted || [];
    },

    dataLoading(): boolean {
      return this.$store.get("affiliates/loading");
    },
  },

  async created() {
    await this.$store.dispatch(
      EAffiliatesActions.FETCH_USER_SUBMISSIONS,
      null,
      {
        root: true,
      },
    );
  },

  methods: {
    goToProgramsPage() {
      window.open(VILCAP_PROGRAMS_PAGE, "_blank");
    },

    goToProfilePage() {
      return this.$router.push({ name: ROUTE_PROFILE });
    },
  },
});
