
































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import { generateRandomString } from "@/utils/generate-random-string";

import ListShareClipboardButton from "@/modules/company-lists/components/list-share/list-share-clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { SHARE_PLANNER_EVENTS } from "@/modules/milestone-planner/components/share-planner/share-planner-modal.types";

export default Vue.extend({
  name: "SharePlannerSettingsPasscode",

  components: {
    ListShareClipboardButton,
  },

  props: {
    passcode: {
      type: String,
      required: true,
    },

    needsReset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerPasscode: "",
      isRestrictedByPasscode: false,
    };
  },

  static: {
    resetButton: {
      icon: "password-lock--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    passcodeButtonCopy(): LocaleMessages {
      return this.$t("milestonePlanner.copyPasscode") as LocaleMessages;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t("milestonePlanner.shareModal.passcode") as LocaleMessages;
    },

    hasPasscode(): boolean {
      return !!this.passcode.length;
    },
  },

  watch: {
    passcode(newPasscode: string) {
      if (this.innerPasscode !== newPasscode) {
        this.innerPasscode = newPasscode;
      }
    },

    innerPasscode(newPasscode: string) {
      if (this.passcode !== newPasscode) {
        this.$emit("update:passcode", newPasscode);
      }
    },

    needsReset(newValue: boolean) {
      if (newValue) {
        this.isRestrictedByPasscode = this.hasPasscode;
        this.$emit("update:needsReset", !newValue);
      }
    },
  },

  created() {
    this.isRestrictedByPasscode = this.hasPasscode;

    if (this.hasPasscode) {
      this.innerPasscode = this.passcode;
    }
  },

  methods: {
    changeRestrictionHandler(isRestricted: boolean) {
      // If non-existing passcode, generate a new one:
      if (isRestricted && !this.hasPasscode && !this.innerPasscode) {
        this.innerPasscode = generateRandomString();
      }

      this.$emit("change", isRestricted, this.innerPasscode);
    },

    clickResetPasscodeHandler() {
      this.$root.$emit("share-planner-modal-confirmation", {
        event: SHARE_PLANNER_EVENTS.RESET_PASSCODE,
      });
    },
  },
});
