




















import Vue from "vue";

export interface IAvatarSetItem {
  name: string;
  logo: string;
  id?: number;
}

export default Vue.extend({
  props: {
    // By default, having zero will show all items.
    visibleLimit: {
      type: Number,
      default: 0,
    },

    items: {
      type: Array as () => Array<IAvatarSetItem>,
      required: true,
    },

    size: {
      type: [Number, String],
      required: true,
    },

    round: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    itemDimensions(): { [key: string]: string } {
      return { width: `${this.size}px`, height: `${this.size}px` };
    },

    itemsLimit(): number {
      return this.visibleLimit || this.items.length;
    },

    hasItemsOverLimit(): boolean {
      return this.items.length > this.itemsLimit;
    },

    amountOfAvatarsToShow(): number {
      // If limit is reached, we need to leave space (- 1) to show the remaining items count.
      return this.hasItemsOverLimit ? this.itemsLimit - 1 : this.itemsLimit;
    },

    visibleItems(): Array<IAvatarSetItem> {
      return this.items.slice(0, this.amountOfAvatarsToShow);
    },

    remainingItemsCount(): number {
      return this.items.length - this.visibleItems.length;
    },
  },
});
