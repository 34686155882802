





















import Vue from "vue";

import CategoryWizard from "@/modules/affiliates/components/category-wizard/category-wizard.vue";
import CategoryWizardMobile from "@/modules/affiliates/components/category-wizard-mobile/category-wizard-mobile.vue";
import CategoryLevelsAssessment from "@/modules/affiliates/components/category-levels-assessment/category-levels-assessment.vue";

import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";
import {
  EAffiliatesGetters,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "AffiliateAssessmentEntrepreneur",

  components: {
    CategoryWizard,
    CategoryWizardMobile,
    CategoryLevelsAssessment,
  },

  props: {
    isSelfAssessment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentSelectedCategory: 0,
    };
  },

  computed: {
    defaultAssessmentLevels(): Array<ICategoryLevelSelection> {
      return this.categories.map((item: ICategory) => ({
        category: item.id,
        level: 0,
      }));
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.$store.state.affiliates.draftSubmission?.data.assessment
        .length
        ? this.$store.state.affiliates.draftSubmission?.data.assessment
        : this.defaultAssessmentLevels;
    },

    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    formattedCategories(): Array<ICategoryStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      }));
    },

    checkedSteps(): Array<number> {
      return this.assessmentLevels
        .filter((record: ICategoryLevelSelection) => record.level > 0)
        .map((record: ICategoryLevelSelection) => record.category);
    },

    selectedCategory(): number {
      return this.$store.state.affiliates.selectedAssessmentCategory;
    },

    selectedStepData(): ICategoryLevelSelection | null {
      return (
        this.$store.getters[EAffiliatesGetters.SELECTED_STEP_DATA] || {
          category: this.categories[0].id,
          level: 0,
        }
      );
    },

    currentCategory(): ICategory | null {
      return this.$store.getters[EAffiliatesGetters.CURRENT_CATEGORY];
    },

    showCategoryWizard(): boolean {
      return !this.$screen.mdDown;
    },
  },

  watch: {
    selectedCategory: {
      immediate: true,
      handler(newCategory: number) {
        if (newCategory === this.currentSelectedCategory) {
          return;
        }

        this.currentSelectedCategory = newCategory || 0;
      },
    },
  },

  mounted() {
    window.addEventListener("keyup", this.handleKeyupEvent);
  },

  destroyed() {
    window.removeEventListener("keyup", this.handleKeyupEvent);
  },

  methods: {
    onCategoryChange(newCategory: number) {
      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
        newCategory,
      );
    },

    handleKeyupEvent(event: KeyboardEvent) {
      if (
        event.key === "ArrowRight" &&
        this.currentSelectedCategory < this.assessmentLevels.length - 1
      ) {
        this.$store.dispatch(
          EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
          this.currentSelectedCategory + 1,
        );
      }
      if (event.key === "ArrowLeft" && this.currentSelectedCategory > 0) {
        this.$store.dispatch(
          EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
          this.currentSelectedCategory - 1,
        );
      }
    },
  },
});
