

































import Vue from "vue";
import { i18n } from "@/services/i18n/i18n";

const DIALOG_ELEMENT_SELECTOR = `.el-dialog__header, .el-dialog__body, .el-dialog__footer`;
const DIALOG_OVERLAY_MOD = "el-dialog--overlay";

export default Vue.extend({
  name: "OverlayConfirm",

  props: {
    /**
     * Dialog reference.
     */
    dialog: {
      type: Object as () => any,
      required: true,
    },

    /**
     * Message for the confirm overlay.
     */
    message: {
      type: String,
      default: () => "",
    },

    /**
     * Optional tip for the confirm overlay.
     */
    tip: {
      type: String,
      default: () => "",
    },

    /**
     * Type of the cancel button.
     */
    cancelButtonType: {
      type: String,
      default: () => "link",
    },

    /**
     * Cancel text.
     */
    cancelButtonText: {
      type: String,
      default: () => "",
    },

    /**
     * Additional classes for the cancel button.
     */
    cancelButtonClass: {
      type: Object as () => any,
      default: () => ({ "el-button--link": true }),
    },

    /**
     * Type of the confirm button.
     */
    confirmButtonType: {
      type: String,
      default: () => "red",
    },

    /**
     * Confirm text.
     */
    confirmButtonText: {
      type: String,
      default: () => "",
    },

    /**
     * Additional classes for the confirm button.
     */
    confirmButtonClass: {
      type: Object as () => any,
      default: () => ({ "text-uppercase": true }),
    },

    /**
     * Confirm function.
     */
    confirmFn: {
      type: Function as () => any,
      default: () => null,
    },

    /**
     * Cancel function.
     */
    cancelFn: {
      type: Function as () => any,
      default: () => null,
    },
  },

  data() {
    return {
      isLoading: false,
      visible: false,
      isButtonsDisabled: false,
      fixedOnScroll: false,
    };
  },

  static: {
    payload: null,
  },

  computed: {
    dialogElements(): any {
      const dialogElement = (this.dialog as any).$el as HTMLElement;
      return dialogElement.querySelectorAll(DIALOG_ELEMENT_SELECTOR);
    },

    dialogCancelButtonText(): string {
      return this.cancelButtonText === ""
        ? (i18n.t("common.dialogConfirmation.cancel") as string)
        : this.cancelButtonText;
    },

    dialogConfirmButtonText(): string {
      return this.confirmButtonText === ""
        ? (i18n.t("common.dialogConfirmation.confirm") as string)
        : this.confirmButtonText;
    },
  },

  watch: {
    /**
     * When the visible change check if the overlay must be fixed.
     */
    async visible(isVisible: boolean) {
      await this.$nextTick();

      if (
        (this.$el as any).clientHeight + 120 >= window.innerHeight &&
        isVisible
      ) {
        this.fixedOnScroll = true;
      }
    },
  },

  methods: {
    /**
     * Make the overlay visible
     */
    show() {
      this.visible = true;

      const dialogElement = this.dialog.$el as HTMLElement;
      if (dialogElement && dialogElement.firstElementChild) {
        dialogElement.firstElementChild.scrollTop = 0;
        (dialogElement.firstElementChild as HTMLElement).style.overflowY =
          "hidden";
      }

      [...this.dialogElements].forEach((element: any) =>
        element.classList.add(DIALOG_OVERLAY_MOD),
      );
    },

    /**
     * Hide dialog overlay.
     */
    hide() {
      this.visible = false;

      const dialogElement = this.dialog.$el as HTMLElement;
      if (dialogElement && dialogElement.firstElementChild) {
        (dialogElement.firstElementChild as HTMLElement).style.overflowY =
          "auto";
      }

      [...this.dialogElements].forEach((element: any) =>
        element.classList.remove(DIALOG_OVERLAY_MOD),
      );
    },

    /**
     * Execute the cancel routine.
     */
    onClickCancel() {
      this.cancelFn();
      this.hide();
    },

    /**
     * Execute the confirm routine.
     */
    async onClickConfirm() {
      this.isLoading = true;
      this.isButtonsDisabled = true;
      await this.confirmFn(this.$options.static.payload);
      this.isButtonsDisabled = false;
      this.isLoading = false;
      this.hide();
    },
  },
});
