

























import Vue from "vue";

/**
 * Components to be used for all global errors.
 *
 * This adds a error image and also allows auto retries and
 * with a countdown and also adds a button for manually
 * retry the task that leaded to an error.
 */
export default Vue.extend({
  name: "PxGlobalError",

  props: {
    /**
     * Loading state.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows customize the error text.
     */
    text: {
      type: String,
      default: null,
    },
    /**
     * When true show a button.
     */
    useAction: {
      type: Boolean,
      default: false,
    },
    /**
     * When true the `doAction` event is dispatched each 10 seconds.
     */
    autoRefresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timerIntervalIdentifier: null as any,
      remainingTime: 10,
    };
  },

  mounted() {
    if (this.autoRefresh) {
      this.initTimerInterval();
    }
  },

  methods: {
    dispatchActionEvent() {
      this.$emit("do-action");
    },

    initTimerInterval() {
      this.timerIntervalIdentifier = setInterval(() => {
        this.remainingTime--;

        // When timer gets to zero, throw event and stops the
        // timer interval.
        if (this.remainingTime === 0) {
          this.dispatchActionEvent();
          return clearInterval(this.timerIntervalIdentifier);
        }
      }, 1000);
    },
  },
});
