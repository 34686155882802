














































import Vue from "vue";

import LoginModal from "@/components/login-modal/login-modal.vue";
import GuestAccessModal from "@/components/guest-access-modal/guest-access-modal.vue";

import { IUserGuest } from "@/services/data/user-guest/user-guest.interface";

export default Vue.extend({
  name: "RegistrationModals",

  components: {
    LoginModal,
    GuestAccessModal,
  },

  props: {
    requiresPasscode: {
      type: Boolean,
      default: false,
    },

    isDataLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loginModalVisible: false,
    };
  },

  computed: {
    modalTitle(): string {
      return this.requiresPasscode
        ? (this.$t(
            "milestonePlanner.accessModal.title.afterPasscode",
          ) as string)
        : (this.$t("milestonePlanner.accessModal.title.noPasscode") as string);
    },
  },

  methods: {
    onGoToLoginButtonClick() {
      this.loginModalVisible = true;
    },

    enterAsGuest() {
      this.loginModalVisible = false;
    },

    makeRequestWithLogin() {
      this.$emit("submit-logged-form");
    },

    makeRequestWithGuest(guestData: IUserGuest) {
      this.$emit("submit-guest-form", guestData);
    },
  },
});
