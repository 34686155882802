
























import LevelUpdateModalLevelsInfo from "@/modules/common/components/level-update-modal/level-update-modal-levels-info.vue";
import LevelUpdateModalLevelsExample from "@/modules/common/components/level-update-modal/level-update-modal-levels-example.vue";
import elDialogMixin from "@/mixins/el-dialog.mixin";
import { ILevel } from "@/services/data/level/level.interface";
export default elDialogMixin.extend({
  name: "LevelUpdateModal",

  components: {
    LevelUpdateModalLevelsInfo,
    LevelUpdateModalLevelsExample,
  },

  data() {
    return {
      examplePageVisible: false,
    };
  },

  created() {
    this.$root.$on(
      "change-level-example-modal-visibility",
      this.changeExamplePageVisibility,
    );
  },

  beforeDestroy() {
    this.$root.$off(
      "change-level-example-modal-visibility",
      this.changeExamplePageVisibility,
    );
  },

  computed: {
    currentLevel(): ILevel {
      return this.$store.get("auth/latestAssessment.data.level");
    },

    newLevel(): ILevel {
      return this.$store.get("viralLevel.finalLevel");
    },

    modalWidth(): string {
      return this.examplePageVisible ? "800px" : "740px";
    },
  },

  methods: {
    changeExamplePageVisibility() {
      this.examplePageVisible = !this.examplePageVisible;
    },
  },
});
