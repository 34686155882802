




















































































import Vue from "vue";

import UserResponsesTable from "@/modules/capital-explorer/components/comparison-tables/user-responses-table.vue";
import CompareResponsesTable from "@/modules/capital-explorer/components/comparison-tables/compare-responses-table.vue";
import MatchDetailsTableLegend from "@/components/match-details-table/match-details-table-legend.vue";

import { TranslateResult } from "vue-i18n";

import {
  ICapitalTileInfo,
  ICapitalExplorerData,
  ICapitalExplorerVisitorData,
  ICapitalExplorerVisitorDataCompany,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { calculateResults } from "@/modules/capital-explorer/calculate-results";
import cloneDeep from "lodash/cloneDeep";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "ComparisonTableVisitorsView",

  components: {
    UserResponsesTable,
    CompareResponsesTable,
    MatchDetailsTableLegend,
  },

  static: {
    backButton: {
      icon: "arrow",
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      showLeftButton: false,
      showRightButton: true,
      scrollAmount: 256,
      dataPrefilled: false,
    };
  },

  computed: {
    headerCopy(): TranslateResult {
      return this.$t("capitalExplorer.comparisonTable");
    },

    capitalExplorerData(): ICapitalExplorerData | null {
      return this.$store.get("capitalExplorer/data") || null;
    },

    capitalExplorerVisitorsData(): ICapitalExplorerVisitorData | null {
      return this.$store.get("capitalExplorer/visitorData") || null;
    },

    capitalExplorerTilesResults(): ICapitalTileInfo[] | null {
      return this.capitalExplorerVisitorsData?.results || null;
    },

    capitalExplorerTilesOrdered(): ICapitalTileInfo[] | null {
      const capitalExplorerResults = cloneDeep(
        this.capitalExplorerTilesResults,
      );

      return (
        capitalExplorerResults?.sort(
          (a, b) =>
            (b.matchProperties.score || 0) - (a.matchProperties.score || 0),
        ) || null
      );
    },

    loading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    questions(): IMatchingQuestion[] {
      return this.capitalExplorerData?.questions || [];
    },

    responses(): IMatchingResponse[] {
      return this.capitalExplorerVisitorsData?.responses || [];
    },

    tableHeaders(): Array<string> {
      return (
        this.capitalExplorerTilesOrdered?.map((result: ICapitalTileInfo) => {
          return result.capitalInfo.title;
        }) || []
      );
    },

    userResponsesTableData(): any {
      return this.questions.map((question) => {
        const questionResponse = this.responses.find(
          (response) => response.question === question.id,
        );

        return {
          id: question.id,
          question: question.entrepreneur_question,
          response:
            questionResponse?.answers.map(
              (answer) =>
                question?.answers.find(({ id }) => id === answer)?.value || "",
            ) || [],
          instructions: question.instructions,
        };
      });
    },

    compareResponsesTableData(): Array<any> {
      return this.questions.map((question) => {
        return this.capitalExplorerTilesOrdered?.map((tile) => {
          const tileDetails = tile.matchDetails.find(
            (detail) => detail.question.id === question.id,
          );

          return {
            responses: tileDetails?.desiredAnswers,
            matchIcon: !tileDetails?.userAnswers.length
              ? "unanswered"
              : tileDetails.match
              ? "match--green"
              : "no-match",
          };
        });
      });
    },

    tableLegend(): TranslateResult {
      return this.$t(`capitalExplorer.detailsModal.legendVisitor`);
    },

    allQuestionsAnswered(): boolean {
      return this.responses.length === this.questions.length;
    },

    companyData(): ICapitalExplorerVisitorDataCompany | null {
      return this.capitalExplorerVisitorsData?.company || null;
    },

    subtitleCopy(): TranslateResult {
      return this.$t("capitalExplorer.comparisonTable.visitorSubtitle", {
        company: this.companyData?.name,
      });
    },
  },

  async created() {
    if (!this.capitalExplorerTilesResults?.length) {
      await this.$store.dispatch(ECapitalExplorerActions.FETCH_DATA);

      calculateResults();
    }

    this.dataPrefilled = true;
  },

  methods: {
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer as HTMLElement;

      const maxScroll =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;

      this.showLeftButton = scrollContainer.scrollLeft > 0;
      this.showRightButton = Math.ceil(scrollContainer.scrollLeft) < maxScroll;
    },

    scroll(direction: string) {
      const scrollContainer = this.$refs.scrollContainer as HTMLElement;

      const scrollAmount =
        direction === "left" ? -this.scrollAmount : this.scrollAmount;

      scrollContainer.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },

    goToOwnerProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: { id: String(this.companyData?.id) },
      });
    },
  },
});
