



































import Vue from "vue";

import AssessmentResume from "@/modules/program/components/assessment-resume/assessment-resume.vue";

import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "AffiliateReviewPageSelfAssessment",

  components: {
    AssessmentResume,
  },

  static: {
    updateButton: {
      type: EPxButtonType.DEFAULT,
    },
  },

  props: {
    viralLevel: {
      type: Number,
      required: true,
    },

    levelDescription: {
      type: Object as () => IAssessmentLevel,
      default: null,
    },

    assessmentIncomplete: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    updateButtonProps(): any {
      return {
        ...this.$options.static.updateButton,
        label: this.$t("common.update"),
      };
    },
  },
});
