





































































































import Vue from "vue";
import MilestoneCell from "@/modules/milestone-planner/components/milestone-cell/milestone-cell.vue";
import MilestoneTooltip from "@/modules/milestone-planner/components/milestone-tooltip/milestone-tooltip.vue";
import { IGridCategory, IGridMilestone } from "./milestones-grid.interface";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";
import {
  ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS,
  ROUTE_MILESTONE_PLANNER_DETAIL,
} from "@/modules/milestone-planner/services/router/routes-names";

export default Vue.extend({
  name: "MilestoneGrid",

  components: {
    MilestoneCell,
    MilestoneTooltip,
  },

  props: {
    categories: {
      type: Array as () => IGridCategory[],
      required: true,
    },

    levelsCount: {
      type: Number,
      required: true,
    },

    currentLevel: {
      type: Number,
      required: true,
    },

    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedMilestone: null as IGridMilestone | null,
    };
  },

  computed: {
    currentLevelLinePosition(): number {
      const cellHeightPx = 22;
      const cellUpperMarginPx = 8;
      const totalCellHeight = cellHeightPx + cellUpperMarginPx;

      return -(this.currentLevel * totalCellHeight - cellUpperMarginPx / 2);
    },
  },

  methods: {
    /**
     * Check if provided milestone shall be blurred. It shall be blurred
     * when there's a selected milestone which is not the provided one.
     *
     * @param {IGridMilestone} milestone
     */
    isMilestoneBlurred(milestone: IGridMilestone) {
      return !!this.selectedMilestone && this.selectedMilestone !== milestone;
    },

    /**
     * Mark a milestone as selected when its tooltip gets shown.
     *
     * @param {IGridMilestone} milestone
     */
    onTooltipShow(milestone: IGridMilestone) {
      setTimeout(() => {
        this.selectedMilestone = milestone;
      });
    },

    /**
     * Clear selected milestone when a milestone's tooltip gets hidden.
     */
    onTooltipHide() {
      this.selectedMilestone = null;
    },

    /**
     * Handle milestone plan button clicks,
     * redirecting to plan tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestonePlanClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(categoryId, level, MILESTONE_DETAILS_TABS.PLAN);
    },

    /**
     * Handle milestone complete button clicks,
     * redirecting to complete tab on milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     */
    onMilestoneCompleteClick(categoryId: number, level: number) {
      this.goToMilestoneDetail(
        categoryId,
        level,
        MILESTONE_DETAILS_TABS.COMPLETE,
      );
    },

    /**
     * Navigate to provided tab of milestone detail page.
     *
     * @param {number} categoryId
     * @param {number} level
     * @param {TMilestoneDetailsTab} tab
     */
    goToMilestoneDetail(
      categoryId: number,
      level: number,
      tab: TMilestoneDetailsTab,
    ) {
      this.$router.push({
        name: this.isVisitor
          ? ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS
          : ROUTE_MILESTONE_PLANNER_DETAIL,
        params: {
          categoryId: categoryId.toString(),
          levelValue: level.toString(),
        },
        query: { tab },
      });
    },
  },
});
