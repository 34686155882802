import { ActionTree } from "vuex";

import router from "@/services/router/router";

import {
  EAuthActions,
  EAuthCompanyActions,
  EAuthMutation,
  EAuthSupporterActions,
  IAuthState,
} from "./auth-types";
import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { IAuthLoginPayload } from "./types/auth-login-payload.interface";
import { loginProvider } from "@/modules/authentication/services/data/login/login.provider";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { userManager } from "@/modules/authentication/user-manager";
import { userRecoveryProvider } from "@/modules/authentication/services/data/user-recovery/user-recovery.provider";
import { IAuthRecovery } from "./types/auth-recovery.interface";
import { checkSessionProvider } from "@/modules/authentication/services/data/check-session/check-session.provider";
import { HOME } from "@/services/router/router-names";
import { emailConfirmProvider } from "@/modules/authentication/services/data/email-confirm/email-confirm.provider";
import { profileProvider } from "@/modules/profile/services/data/profile/profile.provider";
import { EUserMetadataActions } from "./sub-modules/user-metadata/user-metadata.types";
import { EAuthLatestAssessmentActions } from "./sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { adminConfirmProvider } from "@/modules/authentication/services/data/admin-confirm/admin-confirm.provider";
import { isProduction } from "@/services/utils/utils";
import { EMatchingResponsesActions } from "@/modules/matching/services/store/matching-responses/matching-responses.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { EProfileLatestAssessmentActions } from "@/modules/profile/services/store/profile/submodules/profile-latest-assessment/profile-latest-assessment-types";
import { heapLogin, heapLogout } from "@/services/utils/heap.utils";
import { i18n } from "@/services/i18n/i18n";
import {
  fullStoryLogin,
  fullStoryLogout,
} from "@/services/utils/fullstory.utils";
import { userGuidingTrack } from "@/services/utils/userguiding.utils";
import { ILocation } from "@/services/data/location/location.interface";

import { featureManager } from "@/services/feature-manager";
import { ILoginResponse } from "@/modules/authentication/services/data/login/login.interface";
import { ESupporterFlowAction } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.interface";
import { EListPasscodeActions } from "@/modules/company-lists/services/store/passcode/passcode.interface";
import { EUserGuestActions } from "@/services/store/user-guest/user-guest.types";
import { EMilestonePlannerPasscodeActions } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/passcode/passcode.interface";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import {
  CAPITAL_EXPLORER_CALLOUT_VISIBLE,
  CAPITAL_EXPLORER_USER_GUIDING_VISIBLE,
} from "@/modules/capital-explorer/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { subscriptionProvider } from "@/modules/authentication/services/data/subscription/subscription.provider";

const loginAction = async (emitter: any, data: any, adminLogin: boolean) => {
  const { commit, dispatch, state } = emitter;

  // Set authentication key on local storage
  userManager.setToken(data.key);

  // Set user and profile id
  commit(EAuthMutation.SET_USER, data.user);
  commit(EAuthMutation.SET_PROFILE_ID, data.user_profile_id);
  commit(EAuthMutation.SET_VERIFIED_ACCOUNT, data.verified_account);

  if (adminLogin) {
    commit(EAuthMutation.SET_IS_ADMIN, data.as_admin);
  }

  // Fetch company
  await dispatch(getBaseName(EAuthActions.FETCH_COMPANY));
  const companyId = state.company ? state.company.data.id : null;

  if (userManager.isEntrepreneur() && companyId) {
    dispatch(getBaseName(EAuthActions.FETCH_LATEST_ASSESSMENT), companyId);
    dispatch(EMatchingResponsesActions.FETCH, null, { root: true });
  }

  if (userManager.isSupporter()) {
    await dispatch(ESupporterFlowAction.RESET, null, { root: true });
    await dispatch(getBaseName(EAuthActions.FETCH_SUPPORTER));

    if (process.env.VUE_APP_CHARGEBEE_SITE) {
      await dispatch(getBaseName(EAuthActions.FETCH_SUBSCRIPTION));
    }
  }

  // Fetch user metadata
  await dispatch(EUserMetadataActions.FETCH, null, {
    root: true,
  });
};

/**
 * Executes external services logic after login
 * @param state
 * TODO: Extract this logic to an abstract factory class
 * class LoggedUserTracker extends AfterLoginAction {
 *     Heap and Fullstory logic
 * }
 */
const afterLoginAction = ({ state }: { state: IAuthState }) => {
  const { company, user } = state;

  // TODO: Add ISupporterState and ILatestAssessmentState declaration (supporter.data)
  const { latestAssessment, supporter } = state as any;

  if (company && user && latestAssessment) {
    // User data
    const userEmail = user.email ? user.email : "";
    const userType = userManager.getUserAccountType();
    const type = i18n.t(`common.userTypes[${userType}]`);
    const latestAssessmentLevel =
      latestAssessment.data && latestAssessment.data.level
        ? latestAssessment.data.level.value
        : 0;

    // Company specific data
    const networks = company.data?.networks
      .map((network: any) => network.name.replace(/,/g, ""))
      .toString();

    // User type specific data
    // TODO: Add ICompany type prop
    // TODO: Replace loose ICompany declaration (company.data) for ICompanyState
    const companyData = userManager.isEntrepreneur()
      ? company.data
      : supporter.data;

    const companyName = companyData.name;
    const companyEmail = companyData.email;
    const { city, country, region, continent } =
      !!companyData.locations && companyData.locations.length > 0
        ? companyData.locations[0]
        : { city: "", country: "", region: "", continent: "" };
    const sectors = companyData.sectors
      .map((sector: any) =>
        sector.name
          .replace(/,/g, "")
          .replace(/\b\w/g, (m: string) => m.toUpperCase()),
      )
      .toString();

    // TODO: Replace loose ICompany declaration (company.data) for ICompanyState
    const typeProperties = userManager.isEntrepreneur()
      ? {
          "VIRAL Level": latestAssessmentLevel,
          Sectors: sectors,
        }
      : {
          "VIRAL Range": companyData.investing_level_range.toString(),
          "Sectors Of Interest": sectors,
          "Locations Of Interest": companyData.locations
            .map((location: ILocation) =>
              location.formatted_address.replace(/,/g, ""),
            )
            .toString(),
        };

    const userProperties = {
      userId: state.profileUid,
      "User Email": userEmail,
      "User Type": type,
      "Company Name": companyName,
      "Company Email": companyEmail,
      "Member Networks": networks,
      ...typeProperties,
      "HQ City": city,
      "HQ Country": country,
      "HQ Region": region,
      "HQ Continent": continent,
    };

    heapLogin(userProperties);
    fullStoryLogin({ displayName: companyName, ...userProperties });
    userGuidingTrack("segment", {
      user_type: type,
    });
  }
};

export const actions: ActionTree<IAuthState, RootState> = {
  /**
   * Try to perform a login
   */
  async [getBaseName(EAuthActions.LOGIN)](emitter, payload: IAuthLoginPayload) {
    const { commit, dispatch } = emitter;
    commit(EAuthMutation.SET_LOADING, true);
    commit(EAuthMutation.SET_ERROR, null);

    try {
      // Perform a login request and save the authentication token
      const data = await loginProvider.create(payload);
      await loginAction(emitter, data, false);

      // TODO: move this into a external hook system to make this action clean and the code more extensible
      // Clean user guest data when there is a login
      dispatch(EUserGuestActions.RESET, null, {
        root: true,
      });

      // Perform external services login
      if (featureManager.isEnabled("externalIntegrationsIdentify")) {
        afterLoginAction(emitter);
      }
    } catch (error) {
      commit(EAuthMutation.SET_ERROR, error);
      return;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }

    dispatch(
      EMetaActions.SET,
      {
        key: CAPITAL_EXPLORER_CALLOUT_VISIBLE,
        value: true,
      },
      {
        root: true,
      },
    );

    dispatch(
      EMetaActions.SET,
      {
        key: CAPITAL_EXPLORER_USER_GUIDING_VISIBLE,
        value: true,
      },
      {
        root: true,
      },
    );

    // Redirect the user to the homepage
    if (payload.redirectUser) {
      router.push({ name: HOME });
    }
  },

  /**
   * Validate admin login as user.
   *
   * @param emitter store commit method
   * @param hash Admin login hash token
   */
  async [getBaseName(EAuthActions.LOGIN_ADMIN_AS_USER)](emitter, hash: string) {
    const { commit } = emitter;
    commit(EAuthMutation.SET_LOADING, true);
    commit(EAuthMutation.SET_ERROR, null);

    let validationError = null;

    try {
      const response = await adminConfirmProvider.get(hash);
      await loginAction(emitter, response, true);
    } catch (error) {
      commit(EAuthMutation.SET_ERROR, error);
      validationError = error;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }

    if (validationError) {
      throw validationError;
    } else {
      // Redirect the user to the profile view
      router.push({ name: ROUTE_PROFILE });
    }
  },

  /**
   * Try to perform a login without provider.
   */
  async [getBaseName(EAuthActions.LOGIN_WITH_HASH)](
    emitter,
    data: ILoginResponse,
  ) {
    const { commit } = emitter;
    commit(EAuthMutation.SET_LOADING, true);
    commit(EAuthMutation.SET_ERROR, null);

    try {
      // Perform a login request and save the authentication token
      await loginAction(emitter, data, false);

      // Perform external services login
      if (featureManager.isEnabled("externalIntegrationsIdentify")) {
        afterLoginAction(emitter);
      }
    } catch (error) {
      commit(EAuthMutation.SET_ERROR, error);
      return;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }
  },

  /**
   * Fetch logged user company.
   *
   * @param param0 vuex methods
   */
  async [getBaseName(EAuthActions.FETCH_COMPANY)]({ state, commit, dispatch }) {
    const profile = await profileProvider.get(state.profileId);

    commit(EAuthMutation.SET_PROFILE_UID, profile.uid);
    await dispatch(EAuthCompanyActions.FETCH, profile.company, { root: true });
  },

  /**
   * Fetch logged user supporter data.
   *
   * @param param0 vuex methods
   */
  [getBaseName(EAuthActions.FETCH_SUPPORTER)]({ state, dispatch }) {
    return dispatch(
      EAuthSupporterActions.FETCH,
      { user_profile: state.profileId },
      { root: true },
    );
  },

  /**
   * Create a new affiliate with a given slug
   * for an authenticated user as supporter.
   *
   * @param dispatch
   * @param slug
   */
  [getBaseName(EAuthActions.CREATE_AFFILIATE_LINK)]({ dispatch }, { slug }) {
    return dispatch(
      EAuthSupporterActions.CREATE_AFFILIATE_LINK,
      { slug },
      { root: true },
    );
  },

  /**
   * Fetch logged user responses.
   *
   * @param emitter commit vuex methods
   * @param companyId company id
   */
  [getBaseName(EAuthActions.FETCH_LATEST_ASSESSMENT)](
    { dispatch },
    companyId: number,
  ) {
    return dispatch(EAuthLatestAssessmentActions.FETCH, companyId, {
      root: true,
    });
  },

  /**
   * Perform a reset password.
   */
  async [getBaseName(EAuthActions.RECOVER)](
    { commit, dispatch },
    payload: IAuthRecovery,
  ) {
    commit(EAuthMutation.SET_LOADING, true);
    commit(EAuthMutation.SET_ERROR, null);

    let isSuccessRecover = true;

    try {
      const response = await userRecoveryProvider.update(
        payload.recoveryToken,
        payload.newData,
      );
      commit(EAuthMutation.SET_PROFILE_ID, response.user_profile_id);
      commit(EAuthMutation.SET_USER, response.user);
      await dispatch(getBaseName(EAuthActions.FETCH_COMPANY));
    } catch (error) {
      commit(EAuthMutation.SET_ERROR, error);
      isSuccessRecover = false;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }

    return isSuccessRecover;
  },

  /**
   * Remove the user token and set the user data to null.
   */
  [getBaseName(EAuthActions.LOGOUT)](
    { commit, dispatch },
    { willRedirect = true, redirectToHome = false } = {},
  ) {
    commit(EAuthMutation.SET_LOADING, true);
    userManager.removeToken();

    // Call major store value reset
    dispatch(EAuthCompanyActions.RESET, null, {
      root: true,
    });
    dispatch(EAuthSupporterActions.RESET, null, {
      root: true,
    });
    dispatch(EUserMetadataActions.RESET, null, {
      root: true,
    });
    dispatch(EAuthLatestAssessmentActions.RESET, null, {
      root: true,
    });
    dispatch(EProfileLatestAssessmentActions.RESET, null, {
      root: true,
    });
    dispatch(EViralLevelActions.RESET, null, {
      root: true,
    });

    // Clear matching responses data
    dispatch(EMatchingResponsesActions.RESET, null, {
      root: true,
    });

    // remove stored passcodes for lists
    dispatch(EListPasscodeActions.RESET, null, {
      root: true,
    });

    // remove stored passcodes for milestone planners
    dispatch(EMilestonePlannerPasscodeActions.RESET, null, {
      root: true,
    });

    // Basic data
    commit(EAuthMutation.SET_USER, null);
    commit(EAuthMutation.SET_PROFILE_ID, null);
    commit(EAuthMutation.SET_IS_ADMIN, false);

    // Clear viral level data
    dispatch(EViralLevelActions.RESET, null, { root: true });
    commit(EAuthMutation.SET_LOADING, false);

    // Clear any pending supporter submission
    dispatch(ESupporterFlowAction.RESET, null, { root: true });

    // Clear affiliate submission data
    dispatch(EAffiliatesActions.RESET, true, { root: true });

    // Clear local meta data
    dispatch(
      EMetaActions.SET,
      {
        key: CAPITAL_EXPLORER_USER_GUIDING_VISIBLE,
        value: false,
      },
      {
        root: true,
      },
    );

    // Clear subscription data
    commit(EAuthMutation.SET_SUBSCRIPTION, null);

    // External logout
    if (featureManager.isEnabled("externalIntegrationsIdentify")) {
      heapLogout();
      fullStoryLogout();
    }

    if (willRedirect) {
      if (!redirectToHome && isProduction) {
        location.replace("https://abaca.app");
      } else {
        router.replace({ name: HOME });
      }
    }
  },

  /**
   * Check session status.
   */
  async [getBaseName(EAuthActions.CHECK_SESSION)]({ commit, dispatch, state }) {
    if (!userManager.isLogged()) {
      // Recover from any possible loading error
      commit(EAuthMutation.SET_LOADING, false);
      return;
    }
    commit(EAuthMutation.SET_LOADING, true);

    let isLogout = false;

    try {
      const response = await checkSessionProvider.checkSession();
      // Enable warning message for admin
      const isAdmin = response.as_admin || false;
      const hasVerifiedAccount = response.verified_account || false;

      // If the check returns a different user, logout from store account
      if (state.profileId !== response.user_profile_id) {
        isLogout = true;
      } else {
        commit(EAuthMutation.SET_IS_ADMIN, isAdmin);
        commit(EAuthMutation.SET_VERIFIED_ACCOUNT, hasVerifiedAccount);
      }
    } catch {
      isLogout = true;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }

    if (isLogout) {
      dispatch(getBaseName(EAuthActions.LOGOUT), {
        redirectToHome: true,
      });
    }
  },

  /**
   * Validate the user account.
   * @param emitter store commit method
   * @param token Validation token
   */
  async [getBaseName(EAuthActions.VALID_EMAIL)](emitter, token: string) {
    const { commit } = emitter;
    commit(EAuthMutation.SET_LOADING, true);
    commit(EAuthMutation.SET_ERROR, null);

    let validationError = null;

    try {
      const response = await emailConfirmProvider.get(token);
      await loginAction(emitter, response, false);
      return response;
    } catch (error) {
      commit(EAuthMutation.SET_ERROR, error);
      validationError = error;
    } finally {
      commit(EAuthMutation.SET_LOADING, false);
    }

    if (validationError) {
      throw validationError;
    }
  },

  async [getBaseName(EAuthActions.FETCH_SUBSCRIPTION)]({ commit }) {
    try {
      const subscription = await subscriptionProvider.getSubscription();
      commit(EAuthMutation.SET_SUBSCRIPTION, subscription);
    } catch (error) {
      commit(EAuthMutation.SET_SUBSCRIPTION, undefined);
    }
  },
};
