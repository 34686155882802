var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElCollapseItem',{staticClass:"collapsible-question",class:{
    'collapsible-question--error': !_vm.hasValidResponse,
    'collapsible-question--success': _vm.successState,
  },attrs:{"name":_vm.question.id}},[_c('div',{staticClass:"collapsible-question__wrapper",attrs:{"slot":"title"},slot:"title"},[(_vm.successState)?_c('div',{staticClass:"collapsible-question__success-icon"},[_c('PxIcon',{attrs:{"name":"answered-icon","size":24}})],1):_vm._e(),_c('div',{staticClass:"collapsible-question__text"},[_c('h4',[_vm._v(" "+_vm._s(_vm.getQuestionTitle(_vm.question))+" ")]),_c('p',{staticClass:"collapsible-question__answer",class:{
          'collapsible-question__answer--italic': !_vm.responseFormattedValue,
        },attrs:{"data-testid":"collapsible-question-response","title":_vm.responseToDisplay}},[_vm._v(" "+_vm._s(_vm.responseToDisplay)+" ")])]),(_vm.question.instructions)?_c('PxButton',_vm._b({staticClass:"collapsible-question__action",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-instructions-modal', _vm.question)}}},'PxButton',_vm.learnMoreButtonProps,false)):_vm._e()],1),_c('div',{staticClass:"collapsible-question__options",class:{
      'collapsible-question__options--grouped': _vm.groupedOptions,
      'collapsible-question__options--ungrouped': !_vm.groupedOptions,
    }},[(_vm.question)?_c('QuestionPanel',{attrs:{"question":_vm.question,"show-only-question-content":true,"data-testid":("collapsible-question--" + (_vm.question.id)),"fields-are-required":false},on:{"validate":_vm.checkValidState,"response-formatted":_vm.handleFormattedResponse},model:{value:(_vm.currentResponse),callback:function ($$v) {_vm.currentResponse=$$v},expression:"currentResponse"}}):_vm._e()],1),(_vm.isMultiSelectQuestion)?_c('div',{staticClass:"collapsible-question__info",attrs:{"data-testid":"collapsible-question-note"}},[_c('PxIcon',{attrs:{"size":16,"name":"icon-information"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('common.multiSelectInfo'))}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }