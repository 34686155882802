


























import Vue from "vue";

export default Vue.extend({
  name: "AssessmentResume",

  props: {
    currentLevel: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: null,
    },
  },

  computed: {
    levelIconSize(): number {
      return this.iconSize ? this.iconSize : this.$screen.mdDown ? 140 : 160;
    },
  },
});
