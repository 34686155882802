













import Vue from "vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { TranslateResult } from "vue-i18n";
import { USER_SUBMISSION_INFO } from "@/modules/submissions/constants";

export default Vue.extend({
  name: "SubmissionsInfoBadges",

  components: {
    PxStatusBadge,
  },

  static: {
    1: {
      backgroundColor: "#fff3e3",
      labelColor: "#f97316",
    },
    2: {
      backgroundColor: "#e9f2ff",
      labelColor: "#3b82f6",
    },
    3: {
      backgroundColor: "#f3f0ff",
      labelColor: "#8b5cf6",
    },
  },

  props: {
    badges: {
      type: Array as () => Array<number>,
      required: true,
      validator: (value: Array<number>) => {
        return value.every((badge: number) =>
          USER_SUBMISSION_INFO.includes(badge),
        );
      },
    },
  },

  computed: {
    badgeLabel(): TranslateResult {
      return this.$t("userSubmissions.table.badges");
    },
  },
});
