




































import Vue from "vue";

export default Vue.extend({
  name: "PxQuestionarySelect",

  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as () => any[],
      required: true,
    },
    size: {
      type: String,
      default: "medium",
      validator: (val: string) => {
        return ["medium", "small", "mini"].includes(val);
      },
    },
  },

  data() {
    return {
      selectedOptions: [],
    };
  },

  watch: {
    /**
     * Reset component state when changing the options.
     */
    options() {
      const context = this as any;
      context.selectedOptions = [];
      context.$emit("input", []);
    },
  },

  methods: {
    /**
     * Emit changes to the parent component.
     */
    submitAnswer(answer: any) {
      const context = this as any;

      context.$emit("select", answer);
      context.$emit("input", answer);

      context.validateField();
    },

    /**
     * Check if the field is valid and emit the change
     * to the parent.
     */
    validateField() {
      const context = this as any;
      const isValid =
        (context.multiple && context.selectedOptions.length) ||
        context.selectedOptions.length > 0 ||
        Number.isInteger(context.selectedOptions);

      this.$emit("update:isValid", isValid);
    },
  },
});
