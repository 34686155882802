




import Vue from "vue";

export default Vue.extend({
  name: "PxLevel",

  props: {
    level: {
      type: Number,
      required: true,
    },
    categoryColor: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentLevel: this.level,
      currentColor: this.categoryColor,
    };
  },

  computed: {
    //TODO: test this computed in unit tests.
    // There were several difficulties in figuring out how to test asynchronous imports.
    getCurrentLevelImage(): any {
      const level = this.currentLevel;
      return () => import(`@root/public/img/icons/level-graph/${level}.svg`);
    },

    getColorMatrix(): number[] {
      // prettier-ignore
      const colorMatrixValues = [
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0, 0,
        0, 0, 0.8, 0,
      ];

      const hex = this.currentColor;

      // Transform hex value to RGB format
      const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      const rFloatNumber = Number((r / 255).toFixed(2)),
        gFloatNumber = Number((g / 255).toFixed(2)),
        bFloatNumber = Number((b / 255).toFixed(2));

      colorMatrixValues[4] = rFloatNumber;
      colorMatrixValues[9] = gFloatNumber;
      colorMatrixValues[14] = bFloatNumber;

      return colorMatrixValues;
    },
  },

  watch: {
    level(newValue: number) {
      if (newValue !== this.currentLevel) {
        this.currentLevel = newValue;
      }
    },
    categoryColor(newValue: string) {
      if (newValue !== this.currentColor) {
        this.currentColor = newValue;
        this.addImageColor(document.querySelector(".px-level"));
      }
    },
  },

  mounted() {
    this.addImageColor(document.querySelector(".px-level"));
  },

  updated() {
    this.addImageColor(document.querySelector(".px-level"));
  },

  methods: {
    hexToRGB(hex: string) {
      // Exclude the "#" on position 0
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return `${r}, ${g}, ${b}`;
    },

    addImageColor(levelImageWrapper: HTMLElement | null) {
      if (levelImageWrapper) {
        this.setColorMatrix(levelImageWrapper);
        this.applyStyles(levelImageWrapper);
      }
    },

    setColorMatrix(levelImageWrapper: HTMLElement) {
      const feColorMatrix = levelImageWrapper.querySelectorAll(".color-matrix");

      feColorMatrix.forEach((el) =>
        el.setAttribute("values", this.getColorMatrix.join(" ")),
      );
    },

    applyStyles(levelImageWrapper: HTMLElement) {
      const hexColor = `#${this.currentColor}`;
      const rgbaColor = this.hexToRGB(hexColor);

      const gradientColor1 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color1",
      );
      const gradientColor2 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color2",
      );
      const gradientColor3 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color3",
      );
      const gradientColor4 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color4",
      );
      const gradientColor5 = levelImageWrapper.querySelectorAll(
        ".linear-gradient-color5",
      );

      gradientColor1.forEach((el) =>
        el.setAttribute("stop-color", `rgba(${rgbaColor}, 0.8)`),
      );
      gradientColor2.forEach((el) => el.setAttribute("stop-color", hexColor));
      gradientColor3.forEach((el) =>
        el.setAttribute("stop-color", `rgba(${rgbaColor}, 0.9)`),
      );
      gradientColor4.forEach((el) =>
        el.setAttribute("fill", `rgba(${rgbaColor}, 0.5)`),
      );
      gradientColor5.forEach((el) =>
        el.setAttribute("stroke", `rgba(${rgbaColor}, 0.5)`),
      );
    },
  },
});
