import Vue from "vue";

import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

import moment from "moment";

const LOCAL_CURRENCY_SETTINGS = {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 0,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
};

export default Vue.extend({
  name: "FormatMatchingResponseValuesMixin",

  methods: {
    parseFloatAsString(value: number | string): string {
      return parseFloat(value.toString().replace(/,/g, "")).toLocaleString();
    },

    formatFreeResponseValue(response: IMatchingResponse): string {
      return response?.value?.text !== undefined ? response.value.text : "";
    },

    formatDateValue(response: IMatchingResponse): string {
      return response?.value?.date !== undefined
        ? moment(response.value.date, "YYYY-MM-DD").format("Do MMMM, YYYY")
        : "";
    },

    formatNumericValue(response: IMatchingResponse): string {
      return response.value && response?.value.value
        ? this.parseFloatAsString(response.value.value)
        : "";
    },

    formatMoneyValue(value: string | number) {
      return this.$options.filters?.currency(value, LOCAL_CURRENCY_SETTINGS);
    },

    formatRangeValue(response: IMatchingResponse): string {
      const minValue =
        response?.value?.min === 0 || !!response?.value?.min
          ? response?.value?.min
          : undefined;

      const maxValue =
        response?.value?.max === 0 || !!response?.value?.max
          ? response?.value?.max
          : undefined;

      return minValue !== undefined && maxValue !== undefined
        ? `From ${this.formatMoneyValue(minValue)} to ${this.formatMoneyValue(
            maxValue,
          )}`
        : response?.value?.value
        ? `${this.formatMoneyValue(response?.value?.value)}`
        : "";
    },

    formatMultipleAnswersValue(
      question: IMatchingQuestion,
      response: IMatchingResponse,
    ): string {
      const answersText: Array<string> = [];

      response.answers.forEach((answerId: number) => {
        const answers = question.answers.find(
          (answer: IMatchingAnswer) => answer.id === answerId,
        );
        answersText.push(answers?.value || "");
      });

      return answersText.join(", ");
    },
  },
});
