

















import Vue from "vue";

import MobileFooterNav from "@/modules/self-assessment/components/mobile-footer-nav/mobile-footer-nav.vue";
import MobileLevelSelect from "@/modules/self-assessment/components/mobile-level-select/mobile-level-select.vue";
import AssessmentMobilePlaceholder from "@/modules/self-assessment/components/assessment-mobile-placeholder/assessment-mobile-placeholder.vue";

export default Vue.extend({
  name: "AssessmentMobile",

  components: {
    AssessmentMobilePlaceholder,
    MobileFooterNav,
    MobileLevelSelect,
  },

  props: {
    /**
     * Handler to be executed when the user clicks
     * on the next button.
     */
    onGoNext: {
      type: Function as () => any,
      required: true,
    },

    /**
     * Handler to be executed when the user clicks
     * on the previous button
     */
    onGoPrev: {
      type: Function as () => any,
      required: true,
    },

    /**
     * Current selected category.
     */
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    /**
     * Current selected category level.
     */
    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    /**
     * Handler to be executed when the category
     * level changes.
     */
    onLevelChange: {
      type: Function as () => any,
      required: true,
    },
  },

  data() {
    return {
      criteriaModalVisibility: false,
    };
  },

  computed: {
    isLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },
  },
});
