

























import Vue from "vue";

import { INetwork } from "@/services/data/network/network.interface";

export default Vue.extend({
  name: "NetworksList",

  props: {
    items: {
      type: Array as () => Array<INetwork>,
      required: true,
    },
  },

  data() {
    return {
      visibleNetworks: 3,
      networksScrollAtEnd: false,
    };
  },

  computed: {
    isGradientVisible(): boolean {
      return (
        this.items.length > this.visibleNetworks && !this.networksScrollAtEnd
      );
    },
  },

  methods: {
    checkNetworksListScroll($event: any) {
      if (!$event.target) return;

      this.networksScrollAtEnd =
        $event.target.scrollTop ===
        $event.target.scrollHeight - $event.target.offsetHeight;
    },
  },
});
