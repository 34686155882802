











































































import AffiliateTypesMixin from "@/modules/affiliates/mixins/affiliate-types.mixin";

import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";
import AffiliateNavigationBar from "@/modules/affiliates/components/affiliate-navigation-bar/affiliate-navigation-bar.vue";
import QuestionBundleEntrepreneurStartingPage from "@/modules/affiliates/views/affiliate-starting-page/question-bundle-entrepreneur-starting-page.vue";
import AffiliateAssessment from "@/modules/affiliates/views/affiliate-assessment/affiliate-assessment.vue";
import AffiliateQuestions from "@/modules/affiliates/views/affiliate-questions/affiliate-questions.vue";
import TeamManagementView from "@/modules/team-management/views/team-management.view.vue";
import AffiliateEntrepreneurResults from "@/modules/affiliates/views/affiliate-results/affiliate-entrepreneur-results.vue";
import AffiliateFooterBar from "@/modules/affiliates/components/affiliate-footer-bar/affiliate-footer-bar.vue";
import PxMessageBar from "@/components/px-message-bar/px-message-bar.vue";
import AffiliateReviewPage from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page.vue";

import { checkIfFinishedProgram } from "@/modules/affiliates/pending-program";
import { AFFILIATE_PROGRAM_START_TIME } from "@/modules/affiliates/constants";
import {
  SELF_ASSESSMENT_START_TIME,
  SELF_ASSESSMENT_START_CAT_TIME,
} from "@/modules/self-assessment/constants";
import {
  EAffiliateSteps,
  ESupporterAffiliateSteps,
  EEntrepreneurAffiliateSteps,
} from "@/modules/affiliates/views/affiliate.types";

import { userGuidingTrack } from "@/services/utils/userguiding.utils";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { gaTrackEvent, gaTrackTime } from "@/services/utils/ga.utils";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { heapTrack } from "@/services/utils/heap.utils";
import { ICategory } from "@/services/data/category/category.interface";
import {
  EAffiliatesGetters,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export interface IAffiliateProgramNavBarStep {
  id: number;
  visible: boolean;
  title: string;
}

export default AffiliateTypesMixin.extend({
  name: "AffiliateQuestionBundleView",

  components: {
    LangSwitcher,
    AffiliateNavigationBar,
    QuestionBundleEntrepreneurStartingPage,
    AffiliateAssessment,
    TeamManagementView,
    AffiliateQuestions,
    AffiliateEntrepreneurResults,
    AffiliateFooterBar,
    PxMessageBar,
    AffiliateReviewPage,
  },

  computed: {
    affiliateStartTime(): number | null {
      return this.$store.get("meta/get", AFFILIATE_PROGRAM_START_TIME) || null;
    },

    affiliateAssessmentStartTime(): number | null {
      return this.$store.get("meta/get", SELF_ASSESSMENT_START_TIME) || null;
    },

    isStartingPage(): boolean {
      return this.currentStep === EAffiliateSteps.STARTING_PAGE;
    },

    isAssessmentPage(): boolean {
      return this.currentStep === EAffiliateSteps.ASSESSMENT;
    },

    isQuestionsPage(): boolean {
      return this.currentStep === EAffiliateSteps.QUESTIONS;
    },

    isTeamManagementPage(): boolean {
      return this.currentStep === EAffiliateSteps.TEAM_MANAGEMENT;
    },

    isInterestsPage(): boolean {
      return this.$user.isSupporter()
        ? this.currentStep === ESupporterAffiliateSteps.INTERESTS
        : false;
    },

    isReviewPage(): boolean {
      return this.$user.isSupporter()
        ? this.currentStep === ESupporterAffiliateSteps.REVIEW
        : this.currentStep === EEntrepreneurAffiliateSteps.REVIEW;
    },

    isAffiliateLastStep(): boolean {
      return this.isReviewPage;
    },

    navigationSteps(): Array<IAffiliateProgramNavBarStep> | [] {
      return this.isEntrepreneurAffiliate
        ? this.entrepreneurAffiliateSteps
        : this.supporterAffiliateSteps;
    },

    showTeamManagementSection(): boolean {
      return (
        (this.affiliatesData.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    entrepreneurAffiliateSteps(): Array<IAffiliateProgramNavBarStep> {
      return [
        {
          id: 1,
          visible: true,
          title: "affiliateProgram.components.flowNavbar.assessmentTabTitle",
        },
        {
          id: 2,
          visible: true,
          title: "affiliateProgram.components.flowNavbar.questionsTabTitle",
        },
        {
          id: 3,
          visible: this.showTeamManagementSection,
          title:
            "affiliateProgram.components.flowNavbar.teamManagementTabTitle",
        },
        {
          id: 4,
          visible: true,
          title: "affiliateProgram.components.flowNavbar.reviewTabTitle",
        },
      ].filter((step: IAffiliateProgramNavBarStep) => step.visible);
    },

    supporterAffiliateSteps(): Array<IAffiliateProgramNavBarStep> {
      return [
        {
          id: 1,
          title: "supporters.cta.ventureInvestmentLevel",
          visible: true,
        },
        {
          id: 2,
          title: "supporters.cta.questions",
          visible: true,
        },
        {
          id: 3,
          title: "supporters.cta.teamManagement",
          visible: this.showTeamManagementSection,
        },
        {
          id: 4,
          title: "supporters.cta.interests",
          visible: true,
        },
        {
          id: 5,
          visible: true,
          title: "affiliateProgram.components.flowNavbar.reviewTabTitle",
        },
      ].filter((step: IAffiliateProgramNavBarStep) => step.visible);
    },

    userAccountType(): number {
      return this.$user.getUserAccountType();
    },

    selectedQuestion(): number {
      return this.affiliatesData.selectedQuestion;
    },

    isFirstQuestion(): boolean {
      return this.selectedQuestion === 0;
    },

    numberOfQuestions(): number {
      return this.affiliatesData.affiliateQuestionBundle?.length
        ? this.affiliatesData.affiliateQuestionBundle?.length - 1
        : 0;
    },

    isLastQuestion(): boolean {
      return this.selectedQuestion === this.numberOfQuestions;
    },

    currentAssessmentCategory(): ICategory | null {
      return this.$store.getters[EAffiliatesGetters.CURRENT_CATEGORY];
    },

    assessmentSelectedStepData(): ICategoryLevelSelection | null {
      return this.$store.getters[EAffiliatesGetters.SELECTED_STEP_DATA];
    },
  },

  created() {
    if (!this.submissionInProgress) {
      this.currentStep = this.shouldSkipStartingPage
        ? EAffiliateSteps.ASSESSMENT
        : EAffiliateSteps.STARTING_PAGE;
    }

    // Notify 3rd party services
    this.userGuidingTrackStart();

    // Watch for Onetrust Consent changes
    if ((window as any)?.Optanon) {
      (window as any).Optanon.OnConsentChanged(this.userGuidingTrackStart);
    }
  },

  methods: {
    resetStepsData() {
      this.$store.dispatch(EAffiliatesActions.SET_SELECTED_QUESTION, 0);
      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
        0,
      );
    },

    async goToPreviousStep() {
      if (this.isAssessmentPage && !this.isAssessmentFirstCategory) {
        this.handleCategoryNavigation(false);
        return;
      }

      if (this.isQuestionsPage) {
        //If not first question, navigate for previous question
        if (!this.isFirstQuestion) {
          this.handleQuestionNavigation(false);
          return;
        } else {
          // If first question, go to the last category of assessment
          this.$store.dispatch(
            EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
            this.assessmentLevels.length - 1,
          );
        }
      }

      // If team management page or review page without team members, go to last question
      if (
        this.isTeamManagementPage ||
        (this.isReviewPage && !this.showTeamManagementSection)
      ) {
        this.$store.dispatch(
          EAffiliatesActions.SET_SELECTED_QUESTION,
          this.numberOfQuestions,
        );
      }

      // if assessment page and first category go to starting page
      if (this.isAssessmentPage) {
        this.currentStep = EAffiliateSteps.STARTING_PAGE;
        return;
      }

      const currentStep = this.navigationSteps.findIndex(
        (step: IAffiliateProgramNavBarStep) => step.id === this.currentStep,
      );

      this.currentStep = this.navigationSteps[currentStep - 1].id;
    },

    async goToNextStep() {
      if (this.isAssessmentPage && !this.isAssessmentLastCategory) {
        this.handleCategoryNavigation();
        return;
      }

      if (this.isQuestionsPage && !this.isLastQuestion) {
        this.handleQuestionNavigation();
        return;
      }

      if (this.isAffiliateLastStep) {
        this.submitAffiliate();
        return;
      }

      const previousStep = this.navigationSteps.findIndex(
        (step: IAffiliateProgramNavBarStep) => step.id === this.currentStep,
      );

      this.currentStep = this.navigationSteps[previousStep + 1].id;

      if (this.isAssessmentPage) {
        // Set program start time to track pending users
        // that do not finish the registration process
        if (
          this.isEntrepreneurAffiliate &&
          !this.$user.isLogged() &&
          !this.affiliateStartTime
        ) {
          this.setProgramStartTime();
        }

        if (!this.affiliateAssessmentStartTime) this.gaRegisterStart();
      }
    },

    handleCategoryNavigation(shouldNavigateNext = true) {
      this.gaEnd();

      const nextCategoryToShow = shouldNavigateNext
        ? this.selectedAssessmentCategory + 1
        : this.selectedAssessmentCategory - 1;

      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
        nextCategoryToShow,
      );

      this.gaStart();
    },

    handleQuestionNavigation(shouldNavigateNext = true) {
      const nextQuestionToShow = shouldNavigateNext
        ? this.selectedQuestion + 1
        : this.selectedQuestion - 1;

      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_QUESTION,
        nextQuestionToShow,
      );
    },

    /**
     * Start google analytics tracking for assessment page
     */
    gaRegisterStart() {
      // Register the start time of self assessment flow
      localStorage.setItem(
        SELF_ASSESSMENT_START_TIME,
        new Date().toISOString(),
      );

      // Send an event informing that the user started
      // the self-assessment flow
      gaTrackEvent("start", "Self-Assessment");
    },

    gaStart() {
      // Register the start category time and the category
      // that are being filled.
      localStorage.setItem(
        SELF_ASSESSMENT_START_CAT_TIME,
        new Date().toISOString(),
      );
    },

    gaEnd() {
      // Registers the time that user take to fill the
      // category
      const stringTime = localStorage.getItem(
        SELF_ASSESSMENT_START_CAT_TIME,
      ) as string;
      const startTime = new Date(stringTime);
      const endTime = new Date();

      // Elapsed time between the start and end of the category level selection
      let duration = (endTime.getTime() - startTime.getTime()) / 1000;
      duration = Math.round(duration);

      gaTrackTime(
        "duration",
        duration,
        "Self-Assessment Category",
        this.currentAssessmentCategory?.name,
      );
      heapTrack("Self-Assessment Category", {
        name: this.currentAssessmentCategory?.name,
        duration,
        level: this.assessmentSelectedStepData?.level,
      });
      // Informs GA the user filled the current category
      gaTrackEvent(
        "category-filled",
        "Self-Assessment",
        this.currentAssessmentCategory?.name,
      );
    },

    userGuidingTrackStart() {
      if (this.isStartingPage) {
        const userType = this.userAccountType;
        const type = this.$t(`common.userTypes[${userType}]`);
        userGuidingTrack("segment", {
          user_type: type,
          affiliate_slug: this.affiliatesData.affiliate?.slug,
        });
      }
    },

    /**
     * Initialize affiliate program timer
     */
    setProgramStartTime() {
      this.$store
        .dispatch(EMetaActions.SET, {
          key: AFFILIATE_PROGRAM_START_TIME,
          value: Date.now(),
        })
        .then(() => {
          checkIfFinishedProgram();
        });
    },

    async goToPage(page: number, questionId: number | null = null) {
      if (questionId !== null) {
        await this.$store.dispatch(
          EAffiliatesActions.SET_SELECTED_QUESTION,
          questionId,
        );
      }

      this.currentStep = page;
    },

    checkDisableState(isDisabled: boolean) {
      this.submitDisabled = isDisabled;
    },
  },
});
