































import Vue from "vue";

export default Vue.extend({
  name: "PxAvatar",

  props: {
    /**
     * Link to the photo.
     */
    photo: {
      type: String,
      default: null,
    },

    /**
     * Avatar name
     */
    name: {
      type: String,
      default: " ",
    },

    /**
     * Avatar image size
     */
    size: {
      type: Number,
      default: 95,
    },

    /**
     * Loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "default",
      validator: (value: string) => ["default", "round"].includes(value),
    },

    styling: {
      type: String,
      default: "colorful",
      validator: (value: string) => ["colorful", "neutral"].includes(value),
    },
  },

  data() {
    return {
      backgroundColors: ["#7bb7ff", "#72cc72", "#f6a34b"],
    };
  },

  computed: {
    /**
     * First letter of the avatar name.
     */
    firstLetter(): string {
      return this.name.charAt(0).toUpperCase();
    },

    /**
     * Image styles based on the image size.
     */
    imageStyle(): { [key: string]: string } {
      const imgSize = `${this.size}px`;
      return {
        width: imgSize,
        height: imgSize,
      };
    },

    isColorful(): boolean {
      return this.styling === "colorful";
    },

    backgroundGradientColor(): string {
      const backgroundColor =
        this.backgroundColors[
          this.name.charCodeAt(0) % this.backgroundColors.length
        ];

      return `linear-gradient(
          to bottom,
          rgba(39, 39, 64, 0),
          rgba(39, 39, 64, 0.1)
        ),
        linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor})`;
    },

    emptyAvatarStyle(): { [key: string]: string } {
      const fontSize = (this.size * 65) / 95;
      const fallbackBackground = this.isColorful
        ? this.backgroundGradientColor
        : "";

      return {
        ...this.imageStyle,
        lineHeight: `${this.size}px`,
        fontSize: `${fontSize}px`,
        backgroundImage: fallbackBackground,
      };
    },
  },
});
