




















import Vue from "vue";
import MaskedInput from "vue-text-mask";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: {
    MaskedInput,
  },
  props: {
    /**
     * Value for the numeric field.
     */
    value: {
      type: String,
      default: "",
    },

    /**
     * Optional name for the field.
     */
    name: {
      type: String,
      default: "numeric",
    },

    /**
     * Placeholder for the field.
     */
    placeholder: {
      type: String,
      default: "affiliateProgram.questions.numericPlaceholder",
    },

    /**
     * Allows to define a custom mask to the field.
     */
    mask: {
      type: [Array, Function, Boolean, Object],
      default: () =>
        createNumberMask({
          allowDecimal: false,
          prefix: "",
          requirementDecimal: false,
          decimalLimit: 2,
          includeThousandsSeparator: true,
          thousandsSeparatorSymbol: ",",
          decimalSymbol: ".",
        }),
    },
  },

  data() {
    return {
      innerValue: null as null | string,
      errorMessage: null as null | string,
    };
  },

  computed: {
    placeholderText(): TranslateResult {
      return this.$t(this.placeholder, {
        number: "5,000",
      });
    },
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerValue = newVal;
    },

    innerValue(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
        this.$emit("change", newVal);
      }
    },
  },

  created() {
    // Default value at start
    this.innerValue = this.value;
  },
});
