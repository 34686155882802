





















import Vue from "vue";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

/**
 * Possible states for this component.
 */
export enum Status {
  READY,
  LOADING,
  COMPLETE,
  ERROR,
}

export interface IStatusChanger {
  /**
   * Inform the the infinity loading that the data is ready and there is no need to be showing a loading state.
   */
  ready(): void;

  /**
   * Infgorms that there is no more data to load for this list.
   */
  complete(): void;
}

export default Vue.extend({
  name: "VirtualGrid",

  props: {
    columns: {
      type: Number,
      default: 2,
    },

    gridGap: {
      type: Number,
      default: 24,
    },

    items: {
      type: Array as () => Array<any>,
      default: () => [],
    },

    itemHeight: {
      type: Number,
      default: 10,
    },

    remainRow: {
      type: Number,
      default: 6,
    },

    loadOnMount: {
      type: Boolean,
      default: false,
    },

    onModal: {
      type: Boolean,
      default: false,
    },

    // For debug reasons
    context: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      status: Status.READY as Status,
      innerItems: [] as Array<any>,

      windowHeight: 0,
      windowScrollY: 0,
      loadingItemsCount: 8,

      stateChanger: {},

      // listeners
      onWindowScroll: (() => undefined) as () => void,
      onWindowResize: (() => undefined) as () => void,
    };
  },

  computed: {
    gridStyles(): { [key: string]: string } {
      const columns =
        this.columns === 1 ? "1fr" : `repeat(${this.columns}, auto)`;

      return {
        transform: `translateY(${this.offsetY}px)`,
        gridTemplateColumns: columns,
        gridRowGap: `${this.gridGap}px`,
      };
    },

    totalLoadingNodesCount(): number {
      // when there is no more items to load stop adding space for additional loading nodes
      if (this.status === Status.COMPLETE) {
        return 0;
      }

      // Get paired total items, then check needed loading items count
      return (
        this.getNextPair(this.innerItems.length + this.loadingItemsCount) -
        this.innerItems.length
      );
    },

    totalNodeCount(): number {
      return this.innerItems.length + this.totalLoadingNodesCount;
    },

    rowHeight(): number {
      return this.itemHeight + this.gridGap;
    },

    totalRows(): number {
      return Math.ceil(this.totalNodeCount / this.columns);
    },

    totalContentHeight(): number {
      return this.totalRows * this.rowHeight - this.gridGap;
    },

    startRow(): number {
      return Math.max(
        0,
        Math.ceil(this.windowScrollY / this.rowHeight) - this.remainRow,
      );
    },

    startNode(): number {
      return Math.max(0, this.startRow * this.columns);
    },

    /**
     * Vertical offset need to make the list following the scroll while changanging its contents.
     */
    offsetY(): number {
      return this.startRow * this.rowHeight;
    },

    virtualNodesCount(): number {
      const endLimit = this.totalNodeCount - this.startNode;

      // Check total items visible, with one more of margin and the remaining on top and bottom
      // The row value is then multiplied by the column count
      const screenLimit =
        (Math.ceil(this.windowHeight / this.rowHeight) +
          1 +
          this.remainRow * 2) *
        this.columns;

      return this.getNextPair(Math.min(endLimit, screenLimit));
    },

    visibleNodesLimit(): number {
      const limit = this.startNode + this.virtualNodesCount;
      return Math.min(this.innerItems.length, limit);
    },

    visibleNodes(): Array<any> {
      return this.startNode >= this.innerItems.length
        ? []
        : this.innerItems.slice(this.startNode, this.visibleNodesLimit);
    },

    visibleLoadingNodesCount(): number {
      return this.status === Status.COMPLETE
        ? 0
        : Math.max(this.virtualNodesCount - this.visibleNodes.length, 0);
    },
  },

  watch: {
    status() {
      if (this.visibleLoadingNodesCount <= 0) {
        return;
      }

      // Emit infinite loading
      this.emitScrollEvent();
    },

    items(newValues: Array<any>, oldValues: Array<any>) {
      // Update inner items
      this.innerItems = newValues;

      if (newValues?.length === oldValues?.length) {
        // Double check this as complete
        this.status = Status.COMPLETE;
        return;
      }

      // On re-mount, make pagination work again
      if (newValues?.length < oldValues?.length) {
        this.status = Status.READY;
      }

      // Emit infinite loading
      this.emitScrollEvent();
    },
  },

  created() {
    this.$root.$on("reset-virtual-grid-scroll-position", () => {
      this.windowScrollY = 0;
    });
  },

  mounted() {
    // configure object that contains methods to be used by the component responsible to load more items
    this.stateChanger = {
      ready: () => {
        this.status = Status.READY;
      },

      complete: () => {
        this.status = Status.COMPLETE;
      },
    } as IStatusChanger;

    // configure listeners
    this.checkScrollListener();
    this.configureWindowListeners();

    // If a list has real data loaded before mounting (like having a v-loading block)
    // inject items and trigger scroll if any visible loading nodes
    if (this.loadOnMount) {
      this.innerItems = this.items;

      if (this.visibleLoadingNodesCount <= 0) {
        return;
      }

      this.emitScrollEvent();
    }
  },

  beforeDestroy() {
    this.removeEventListeners();

    // Reset inner items
    this.innerItems = [];
  },

  methods: {
    checkScrollListener() {
      this.status =
        this.$listeners && !this.$listeners.scroll
          ? Status.COMPLETE
          : Status.READY;
    },

    configureWindowListeners() {
      // create a throttle event to reduce processing while scrolling
      this.onWindowScroll = throttle(() => this.innerOnWindowScroll(), 100);
      this.onWindowResize = debounce(() => this.innerOnWindowResize(), 100);

      this.innerOnWindowResize();

      // Get ref
      const gridRef = this.$refs.grid as HTMLDivElement;
      const listenerRef = this.onModal
        ? (gridRef.parentElement as HTMLDivElement)
        : window;

      // register events listeners for the scroll and resize events
      listenerRef.addEventListener("scroll", this.onWindowScroll, {
        passive: true,
        capture: false,
      });
      listenerRef.addEventListener("resize", this.onWindowResize, {
        passive: true,
        capture: false,
      });
    },

    removeEventListeners() {
      // Get ref
      const gridRef = this.$refs.grid as HTMLDivElement;
      const listenerRef = this.onModal
        ? (gridRef.parentElement as HTMLDivElement)
        : window;

      listenerRef.removeEventListener("scroll", this.onWindowScroll);
      listenerRef.removeEventListener("resize", this.onWindowResize);
    },

    innerOnWindowScroll() {
      // Update positioning
      const gridRef = this.$refs.grid as HTMLDivElement;

      // Be careful about refs
      if (gridRef?.getBoundingClientRect()?.top) {
        this.windowScrollY = Math.max(0, -gridRef.getBoundingClientRect().top);
      }

      this.emitScrollEvent();
    },

    emitScrollEvent() {
      // When we reach the end of the list there is no need to request for more data, we already know that there is none
      // Also, if we are at the beginning (with zero inner items) we shouldn't fetch more just yet
      if (
        !this.innerItems.length ||
        this.status === Status.COMPLETE ||
        this.status === Status.LOADING
      ) {
        return;
      }

      this.status = Status.LOADING;

      // Emit infinite loading
      this.$emit("scroll", this.stateChanger);
    },

    innerOnWindowResize() {
      const gridRef = this.$refs.grid as HTMLDivElement;

      // Get ref
      this.windowHeight = this.onModal
        ? (gridRef.parentElement as HTMLElement).offsetHeight
        : window.innerHeight;
    },

    getNextPair(value: number): number {
      return this.columns * Math.ceil(value / this.columns);
    },
  },
});
