










import Vue from "vue";

import SubmissionNotification from "@/modules/supporters/components/submission/submission-notification.vue";
import SubmissionPanel from "@/modules/supporters/components/submission/submission-panel.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default Vue.extend({
  components: {
    SubmissionNotification,
    SubmissionPanel,
  },

  props: {
    submission: {
      type: Object as () => null | IAffiliateProgramSupporterSubmission,
      default: null,
    },

    showNotification: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isLoading(): boolean {
      return [!this.profileCompany, !this.profileSupporter].some(
        (stillLoading) => stillLoading,
      );
    },

    submittedAffiliate(): IAffiliate {
      return this.$store.getters[AffiliateState.Getter.VALUE] || null;
    },

    profileSupporter(): ISupporter | null {
      return this.submission?.supporter || null;
    },

    profileCompany(): ICompany | null {
      return this.submission?.supporter.user_profile.company || null;
    },

    profileCompanyId(): number | null {
      return this.profileCompany ? this.profileCompany.id : null;
    },
  },
});
