import { render, staticRenderFns } from "./offerings-edit-modal.vue?vue&type=template&id=670b1d48&scoped=true&"
import script from "./offerings-edit-modal.vue?vue&type=script&lang=ts&"
export * from "./offerings-edit-modal.vue?vue&type=script&lang=ts&"
import style0 from "./offerings-edit-modal.vue?vue&type=style&index=0&id=670b1d48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670b1d48",
  null
  
)

export default component.exports