















/**
 * TODO: Merge this component into the original PxNotice while being carefull about regressions.
 */
import Vue from "vue";

export default Vue.extend({
  name: "PxNoticeBar",

  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "default",
      validator: (val: string) => {
        return ["default", "info"].includes(val);
      },
    },
  },
});
