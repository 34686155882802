

























































































import Vue from "vue";
import moment from "moment";

import MilestonePlanOnboardingDialog from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog.vue";
import MilestonePlanHeader from "@/modules/milestone-planner/components/milestone-plan-header/milestone-plan-header.vue";
import MilestoneForm from "@/modules/milestone-planner/components/milestone-form/milestone-form.vue";
import MilestonePlanNavigationBar from "@/modules/milestone-planner/components/milestone-plan-navigation-bar/milestone-plan-navigation-bar.vue";

import {
  IGridMilestone,
  IGridCategory,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "@/modules/milestone-planner/services/router/routes-names";
import {
  IMilestone,
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import { QuestionBundlesState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.module";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import isFinite from "lodash/isFinite";
import { MILESTONE_PLANNER_VISITOR_PLAN_ONBOARDED } from "@/modules/milestone-planner/constants";

export default Vue.extend({
  name: "VisitorsMilestoneDetailView",

  components: {
    MilestonePlanOnboardingDialog,
    MilestonePlanHeader,
    MilestoneForm,
    MilestonePlanNavigationBar,
  },

  data() {
    return {
      onboardingDialogVisibility: false,
      selectedCategory: null as null | IGridCategory,
      selectedLevel: null as null | ILevel["value"],
      planFormIsComplete: false,
      planFormHasChanges: false,
      planFormIsInvalid: false,
      completeFormIsInvalid: false,
      completeFormHasChanges: false,
      activeTab: MILESTONE_DETAILS_TABS.PLAN as TMilestoneDetailsTab,
    };
  },

  static: {
    linkButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    hasOnboarded(): boolean {
      return this.$store.get("milestonePlanner/onboardings/data")[
        MILESTONE_PLANNER_VISITOR_PLAN_ONBOARDED
      ];
    },

    backToOverviewButton(): any {
      return {
        ...this.$options.static.linkButton,
        label: this.$t(
          "milestonePlanner.milestonePlan.planView.backToOverviewLink",
        ),
      };
    },

    openDialogButtonCopy(): any {
      return {
        ...this.$options.static.linkButton,
        icon: "icon-question",
        label: this.$t("milestonePlanner.milestonePlan.planView.howToUseLink"),
      };
    },

    isLoadingCategories(): boolean {
      return this.$store.get("categories.loading");
    },

    isLoadingLevels(): boolean {
      return this.$store.get("levels.loading");
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("milestonePlanner/loading");
    },

    isLoadingQuestionBundles(): boolean {
      return this.$store.get("milestonePlanner/questionBundles.loading");
    },

    isLoadingView(): boolean {
      return (
        this.isLoadingCategories ||
        this.isLoadingLevels ||
        this.isLoadingMilestones
      );
    },

    isLoadingForm(): boolean {
      return this.isLoadingMilestones || this.isLoadingQuestionBundles;
    },

    categories(): ICategory[] {
      return this.$store.get("categories.data");
    },

    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    milestones(): IMilestone[] {
      return this.$store.get("milestonePlanner/value.milestones");
    },

    categoryMilestone(): IMilestone | null {
      return (
        this.milestones.find(
          (milestone) =>
            milestone.category_level.category.id ===
              this.selectedCategory?.id &&
            milestone.category_level.level === this.selectedLevel,
        ) || null
      );
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("milestonePlanner/parsedValue");
    },

    selectedGridCategory(): IGridCategory | null {
      return (
        this.gridCategories.find(
          (category) => category.id === this.selectedCategory?.id,
        ) || null
      );
    },

    selectedGridMilestone(): IGridMilestone | null {
      const milestones = this.selectedGridCategory?.milestones || [];
      return (
        milestones.find(
          (milestone: IGridMilestone) => milestone.level === this.selectedLevel,
        ) || null
      );
    },

    selectedCategoryLevel(): ICategoryDetail | null {
      const selectedCategory = this.categories.find(
        (category) => category.id === this.selectedCategory?.id,
      );

      if (!selectedCategory) {
        return null;
      }

      return (
        selectedCategory.categoryDetails.find(
          (categoryLevel) => categoryLevel.level.value === this.selectedLevel,
        ) || null
      );
    },

    questionBundles(): Array<IQuestionBundle> {
      return this.$store.get("milestonePlanner/questionBundles.values");
    },

    currentQuestionBundles(): Array<IQuestionBundle> {
      const currentCategory = this.categories.find(
        (category) => category.id === this.selectedCategory?.id,
      );
      const currentCategoryLevel = currentCategory?.categoryDetails.find(
        (categoryDetail) => categoryDetail.level.value === this.selectedLevel,
      );
      const levelId = currentCategoryLevel?.id;

      return this.questionBundles.filter(
        (questionBundle) => questionBundle.category_level === levelId,
      );
    },

    planLastUpdate(): string {
      // TODO: Pending last update from plan on API (for now we'll use milestone global update)
      return this.categoryMilestone && this.milestoneHasPlan
        ? moment(this.categoryMilestone.updated_at).format("MMMM D, YYYY")
        : "";
    },

    completeLastUpdate(): string {
      // TODO: Pending last update from complete on API (for now we'll use milestone global update)
      return this.categoryMilestone
        ? moment(this.categoryMilestone.updated_at).format("MMMM D, YYYY")
        : "";
    },

    milestoneHasPlan(): boolean {
      return (
        !!this.categoryMilestone?.strategy ||
        !!this.categoryMilestone?.outcomes ||
        !!this.categoryMilestone?.resources ||
        !!this.categoryMilestone?.critical ||
        isFinite(this.categoryMilestone?.finances_needed) ||
        !!this.categoryMilestone?.target_date
      );
    },
  },

  watch: {
    "$route.params.categoryId": function (newCategoryId: string) {
      const newCategory = this.gridCategories.find(
        (category) => category.id === Number(newCategoryId),
      );

      if (newCategory) {
        this.selectedCategory = newCategory;
      } else {
        this.$router.replace({
          name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        });
      }
    },

    "$route.params.levelValue": function (newLevelValue: string) {
      const hasValidLevel = this.isLevelValid(newLevelValue);

      if (hasValidLevel) {
        this.selectedLevel = Number(newLevelValue);
      } else {
        this.$router.replace({
          name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        });
      }
    },

    "$route.query.tab": function (newTab: TMilestoneDetailsTab) {
      const hasValidTab = this.isTabValid(newTab);

      this.activeTab = hasValidTab ? newTab : MILESTONE_DETAILS_TABS.PLAN;
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      if (this.$route.query?.tab === tab) {
        return;
      }
      this.$router.push({
        path: this.$route.path,
        query: { tab },
      });
    },

    selectedLevel: function (level: number) {
      if (this.$route.params?.levelValue == level) {
        return;
      }
      this.$router.push({
        params: { levelValue: level.toString() },
        query: this.$route.query,
      });
    },

    selectedCategory: function (category: IGridCategory) {
      if (this.$route.params?.categoryId == category.id) {
        return;
      }
      this.$router.push({
        params: { categoryId: category.id.toString() },
        query: this.$route.query,
      });
    },

    selectedCategoryLevel(currentCategoryLevel: ICategoryDetail) {
      if (
        !!currentCategoryLevel &&
        !this.hasAlreadyLoadedQuestionBundle(currentCategoryLevel.id)
      ) {
        this.$store.dispatch(QuestionBundlesState.Action.GET_VALUES, {
          category_level: currentCategoryLevel.id,
        });
      }
    },
  },

  async created() {
    await this.fetchCategoriesAndLevels();

    // Validation relies on having Grid Categories
    this.validateAndSetContext();
  },

  mounted() {
    setTimeout(() => {
      if (!this.hasOnboarded) {
        this.onboardingDialogVisibility = true;
        this.markAsOnboarded();
      }
    }, 2000);
  },

  methods: {
    async fetchCategoriesAndLevels() {
      if (!this.categories.length) {
        await this.$store.dispatch(ECategoryActions.FETCH, {
          group: ENTREPRENEUR_USER_GROUP_ID,
        });
      }

      if (!this.levels.length) {
        await this.$store.dispatch(ELevelActions.FETCH, {
          group: ENTREPRENEUR_USER_GROUP_ID,
        });
      }
    },

    validateAndSetContext() {
      const categoryIdFromRoute = this.$route.params?.categoryId;
      const levelValueFromRoute = this.$route.params?.levelValue;
      const tabFromRouteQuery = this.$route.query?.tab;

      const hasValidCategory = this.categories.some(
        (category) => category.id === Number(categoryIdFromRoute),
      );
      const hasValidLevel = this.isLevelValid(levelValueFromRoute);
      const hasValidTab =
        !!tabFromRouteQuery && this.isTabValid(tabFromRouteQuery);

      if (!hasValidCategory || !hasValidLevel) {
        this.$router.replace({
          name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        });
      } else {
        this.selectedCategory =
          this.gridCategories.find(
            (category) => category.id === Number(categoryIdFromRoute),
          ) || null;
        this.selectedLevel = Number(levelValueFromRoute);
        this.activeTab = hasValidTab
          ? tabFromRouteQuery
          : MILESTONE_DETAILS_TABS.PLAN;
      }
    },

    openOnboardingDialog() {
      this.onboardingDialogVisibility = true;
    },

    closeOnboardingDialog() {
      this.onboardingDialogVisibility = false;
    },

    goToOverviewPage() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS });
    },

    isTabValid(tab: TMilestoneDetailsTab): boolean {
      return Object.values(MILESTONE_DETAILS_TABS).includes(tab);
    },

    isLevelValid(levelValue: string): boolean {
      return this.levels.some((level) => level.value === Number(levelValue));
    },

    hasAlreadyLoadedQuestionBundle(categoryLevelId: number) {
      return this.questionBundles.some(
        (questionBundle) => questionBundle.category_level === categoryLevelId,
      );
    },

    markAsOnboarded(): void {
      this.$store.set("milestonePlanner/onboardings/data", {
        [MILESTONE_PLANNER_VISITOR_PLAN_ONBOARDED]: true,
      });
    },
  },
});
