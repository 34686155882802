import store from "@/services/store";
import { userManager } from "@/modules/authentication/user-manager";
import { isPageVisible } from "@/utils/page-visibility";

import { pendingProgramQuestionBundlesProvider } from "./services/data/pending-program-question-bundles/pending-program-question-bundles.provider";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  AFFILIATE_PROGRAM_FINISHED,
  AFFILIATE_PROGRAM_START_TIME,
} from "@/modules/affiliates/constants";

const HALF_AN_HOUR_IN_MILLISECONDS = 30 * 60000;

const storedProgramStartTime = () => {
  const programStartTime =
    store.get("meta/get", AFFILIATE_PROGRAM_START_TIME) || null;

  // If time is not set, prevent any leftovers of program finished to remain
  if (programStartTime === null) {
    store.dispatch(EMetaActions.SET, {
      key: AFFILIATE_PROGRAM_FINISHED,
      value: false,
    });
  }

  return programStartTime;
};

/**
 * This will check when a pending user that completed
 * an Affiliate program without registering if:
 * -> 30 minutes have passed
 * -> browser tab not visible
 * If so, it will trigger a request to let Abaca
 * know that it can send notification to the Affiliate
 */
export const checkIfFinishedProgram = () => {
  const storeStartTime = storedProgramStartTime();

  // Validate if there is still time stored
  if (storeStartTime === null) {
    return;
  }

  const currentTimeInMiliseconds = new Date().getTime();
  const startTimeInMiliseconds = new Date(storeStartTime).getTime();

  const minutesPassedInMiliseconds =
    currentTimeInMiliseconds - startTimeInMiliseconds;
  const hasRemainingTime =
    minutesPassedInMiliseconds < HALF_AN_HOUR_IN_MILLISECONDS;
  const isProgramFinished =
    store.get("meta/get", AFFILIATE_PROGRAM_FINISHED) || false;
  const storePendingUser = store.get("affiliates/pendingUser");

  if (
    !hasRemainingTime &&
    !isPageVisible() &&
    !!storePendingUser &&
    isProgramFinished
  ) {
    // Set program as finished
    pendingProgramQuestionBundlesProvider.finished(storePendingUser.email);

    // Reset program start time
    store.dispatch(EMetaActions.REMOVE, AFFILIATE_PROGRAM_START_TIME);
  } else if (hasRemainingTime) {
    // Add timer to verify as soon as the 30 minutes have passed
    const remainingTime =
      HALF_AN_HOUR_IN_MILLISECONDS - minutesPassedInMiliseconds;
    setInterval(checkIfFinishedProgram, remainingTime);
  }
};
// Check everytime a user refreshes the page
if (!userManager.isLogged() && !!storedProgramStartTime()) {
  checkIfFinishedProgram();
}
