






































import Vue from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default Vue.extend({
  name: "MilestoneCarouselDescription",

  props: {
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
  },
});
