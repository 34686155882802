








































import Vue from "vue";
import MatchingBadgeModal from "@/modules/matching/components/matching-badge-modal/matching-badge-modal.vue";
import {
  MATCHING_BADGE_COMPLETED_QUESTION,
  MATCHING_BADGES_LIST,
  MATCHING_BADGE_MILESTONE_PLANNER,
} from "@/modules/matching/constants";
import { IMatchingBadge } from "@/modules/matching/services/data/matching-badge/matching-badge.interface";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS } from "@/modules/milestone-planner/services/router/routes-names";

export default Vue.extend({
  name: "MatchingBadgeList",
  components: { MatchingBadgeModal },
  props: {
    badges: {
      type: Array as () => IMatchingBadge[],
      default: () => [],
      validator: (value: IMatchingBadge[]) => {
        return value.every((badge: IMatchingBadge) =>
          MATCHING_BADGES_LIST.includes(badge.name),
        );
      },
    },
    companyName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "expanded",
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    hasBadges(): boolean {
      return this.badges && !!this.badges.length;
    },

    badgeIconSize(): number {
      return this.$screen.mdUp ? 24 : 28;
    },

    typeClass(): string {
      return this.type === "compact"
        ? "matching-badge-list--compact"
        : "matching-badge-list";
    },

    modalTitle(): string {
      return this.$t("matching.matchingBadges.modalTitle", {
        companyName: this.companyName,
      }) as string;
    },

    formattedBadges(): any[] {
      return this.badges.map((badge: IMatchingBadge) => {
        let affiliatesCount = null;

        // If contains affiliate
        if (badge.name == MATCHING_BADGE_COMPLETED_QUESTION) {
          affiliatesCount = badge.meta.affiliatesCount;
        }

        return {
          name: badge.name,
          icon: this.$t(`matching.matchingBadges.${badge.name}.icon`),
          title: this.$t(`matching.matchingBadges.${badge.name}.title`),
          description: this.$t(
            `matching.matchingBadges.${badge.name}.description`,
          ),
          shortDescription: this.$tc(
            `matching.matchingBadges.${badge.name}.shortDescription`,
            affiliatesCount,
            {
              affiliatesCount,
            },
          ),
          meta: badge.meta,
        };
      });
    },
  },

  methods: {
    onBadgeClick(badge: IMatchingBadge) {
      if (badge.name === MATCHING_BADGE_COMPLETED_QUESTION) {
        return this.$emit("click");
      }

      if (badge.name === MATCHING_BADGE_MILESTONE_PLANNER) {
        const route = this.$router.resolve({
          name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
          params: {
            uid: badge.meta.uid,
          },
        });
        return window.open(route.href, "_blank");
      }

      this.isModalVisible = true;
    },
  },
});
