import { RouteConfig } from "vue-router";
import multiguard from "vue-router-multiguard";

import { removeTabsItems } from "@/services/router/mobile-tab-menu";

import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "./routes-names";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import ListsDirectoryView from "@/modules/company-lists/views/lists-directory/lists-directory.view.vue";
import ListsDetailView from "@/modules/company-lists/views/lists-detail/lists-detail.view.vue";
import { preventCompanyListsAccessGuard } from "@/modules/company-lists/services/router/guards/prevent-company-lists-access-guard/prevent-company-lists-access-guard.guard";

export const routes: Array<RouteConfig> = [
  {
    path: "/lists",
    component: AuthenticatedWithNavigationView,
    beforeEnter: multiguard([removeTabsItems, preventCompanyListsAccessGuard]),
    children: [
      {
        path: "",
        name: ROUTE_COMPANY_LISTS_DIRECTORY,
        component: ListsDirectoryView,
        meta: {
          auth: true,
        },
      },
      {
        path: ":uid",
        name: ROUTE_COMPANY_LISTS_DETAIL,
        component: ListsDetailView,
      },
    ],
  },
];
