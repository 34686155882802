

























































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "MilestonePlanConfirmationDialog",

  static: {
    deleteButton: {
      size: EPxButtonSize.LARGE,
      type: EPxButtonType.RED,
      variant: EPxButtonVariant.FLUID,
    },
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
    cancelButton: {
      type: EPxButtonType.LINK,
    },
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    planned: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    isCompletePublished: {
      type: Boolean,
      default: false,
    },
    isFormInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerVisibility: false,
    };
  },

  computed: {
    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel"),
      };
    },

    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label:
          this.completed || this.planned
            ? this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.buttons.delete",
              )
            : this.$t(
                "milestonePlanner.milestonePlan.confirmationDialogs.buttons.discard",
              ),
      };
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveasDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    dialogTitle(): TranslateResult {
      return this.completed && this.isCompletePublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompletePublished.title",
          )
        : this.completed
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompleteDraft.title",
          )
        : this.planned && this.isPlanPublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanPublished.title",
          )
        : this.planned
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanDraft.title",
          )
        : this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.discardChanges.title",
          );
    },

    dialogDescription(): TranslateResult {
      return this.completed && this.isCompletePublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompletePublished.description",
          )
        : this.completed
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deleteCompleteDraft.description",
          )
        : this.planned && this.isPlanPublished
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanPublished.description",
          )
        : this.planned
        ? this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.deletePlanDraft.description",
          )
        : this.$t(
            "milestonePlanner.milestonePlan.confirmationDialogs.discardChanges.description",
          );
    },

    showSaveAsDraftButton(): boolean {
      return (
        !this.isFormInvalid &&
        (this.isPlanPublished || this.isCompletePublished)
      );
    },
  },

  watch: {
    visibility: {
      immediate: true,
      handler(value) {
        this.innerVisibility = value;
      },
    },
  },

  methods: {
    onClickDelete() {
      this.closeModal();
      this.$emit("confirmed");
    },

    onClickSaveAsDraft() {
      this.closeModal();
      this.$emit("save-as-draft");
    },

    closeModal() {
      this.$emit("update:visibility", false);
    },
  },
});
