














































import Vue from "vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "ResultsBoardPendingUser",

  computed: {
    isPendingUser(): boolean {
      return (
        this.$features.isEnabled("assessmentUserInfoBeforeHand") &&
        this.$user.isPendingUser()
      );
    },

    showSubmitMessage(): boolean {
      return !this.$screen.smDown;
    },

    affiliates(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    affiliate(): IAffiliate | null {
      return this.affiliates.affiliate;
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },
  },

  methods: {
    onClickHandler() {
      this.$root.$emit("open-signup-modal");
    },
  },
});
