import { render, staticRenderFns } from "./share-modal-settings.vue?vue&type=template&id=00395565&scoped=true&"
import script from "./share-modal-settings.vue?vue&type=script&lang=ts&"
export * from "./share-modal-settings.vue?vue&type=script&lang=ts&"
import style0 from "./share-modal-settings.vue?vue&type=style&index=0&id=00395565&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00395565",
  null
  
)

export default component.exports