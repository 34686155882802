import { Route, RouteConfig } from "vue-router";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import ChartsDashboardView from "@/modules/charts-dashboard/views/charts-dashboard.view.vue";

import { userManager } from "@/modules/authentication/user-manager";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { defaultScreenService } from "@/modules/common/plugins/screen.plugin";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { featureManager } from "@/services/feature-manager";
import { CHARTS_DASHBOARD } from "@/modules/charts-dashboard/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

export const routes: RouteConfig[] = [
  {
    path: "/charts-dashboard",
    component: AuthenticatedWithNavigationView,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      // Block access to this page:
      // 1. If the feature/module is not enabled (404)
      // 2. If the user is an entrepreneur (403)
      // 3. Device is mobile (mobile)
      let errorCode;

      if (
        !featureManager.isEnabled("chartsDashboard") ||
        !activeModules().includes("charts-dashboard")
      ) {
        errorCode = ERROR_TYPES.NOT_FOUND;
      } else if (userManager.isEntrepreneur()) {
        errorCode = ERROR_TYPES.FORBIDDEN;
      } else if (defaultScreenService.isDown("sm")) {
        errorCode = ERROR_TYPES.MOBILE;
      }

      if (errorCode) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: errorCode,
          },
        });
      } else {
        next();
      }
    },

    children: [
      {
        path: "",
        name: CHARTS_DASHBOARD,
        component: ChartsDashboardView,
        meta: {
          auth: true,
        },
      },
    ],
  },
];
