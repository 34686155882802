



























import Vue from "vue";

import PxGrid from "@/components/px-grid/px-grid.vue";
import ResultsReport from "@/modules/program/components/results-report/results-report.vue";
import AssessmentResume from "@/modules/program/components/assessment-resume/assessment-resume.vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryState } from "@/services/store/category/category-types";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

export default Vue.extend({
  name: "InvestmentLevelPanel",

  components: {
    PxGrid,
    ResultsReport,
    AssessmentResume,
  },

  props: {
    assessment: {
      type: Object as () => IAssessment,
      required: true,
    },
    company: {
      type: Object as () => ICompany,
      required: true,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    numOfLevels(): number {
      return this.$store.get("levels/data").length;
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    level(): ILevel {
      return this.assessment
        ? this.assessment.level
        : { value: 1, title: "", description: "", typical_funding: "" };
    },

    results(): Array<IAssessmentLevel> {
      return this.assessment ? this.assessment.data : [];
    },

    gridSize(): string {
      return this.$screen.smDown
        ? "small"
        : this.$screen.mdDown
        ? "medium"
        : "default";
    },
  },
});
