





























import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import { EListManagementModalTab } from "@/modules/company-lists/components/list-management/list-management.types";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    canCreateLists: {
      type: Boolean,
      default: false,
    },

    customAction: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasPopulatedSlot(): boolean {
      return !!this.$slots.default;
    },

    directoryCopy(): LocaleMessages {
      return this.$t("companyLists.directory") as LocaleMessages;
    },
  },

  methods: {
    newListClickHandler() {
      if (this.customAction) {
        this.$emit("new-list-click");
        return;
      }

      // Open New List Modal
      this.$root.$emit("show-list-management-modal", {
        tab: EListManagementModalTab.CREATE,
      });
    },
  },
});
