


















































import Vue from "vue";

import SubmissionPanelSupporterInfo from "@/modules/supporters/components/submission/submission-panel-supporter-info.vue";
import SubmissionPanelSupporterInterests from "@/modules/supporters/components/submission/submission-panel-supporter-interests.vue";
import SubmissionPanelSupporterContacts from "@/modules/supporters/components/submission/submission-panel-supporter-contacts.vue";
import PxMatchingInterestTip from "@/components/px-matching-interest/px-matching-interest-tip.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  components: {
    SubmissionPanelSupporterInfo,
    SubmissionPanelSupporterInterests,
    SubmissionPanelSupporterContacts,
    PxMatchingInterestTip,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSupporterSubmission,
      required: true,
    },
    company: {
      type: Object as () => ICompany,
      required: true,
    },
    userCanMatch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    supporterSectors(): ISupporter["sectors"] {
      return this.submission?.sectors_of_interest?.sectors || [];
    },

    supporterGroupedSectors(): ISupporter["grouped_sectors"] {
      return this.submission?.sectors_of_interest?.grouped_sectors || [];
    },

    supporterLocations(): ISupporter["locations"] {
      return this.submission?.locations_of_interest?.locations || [];
    },

    supporterGroupedLocations(): ISupporter["grouped_locations"] {
      return this.submission?.locations_of_interest?.grouped_locations || [];
    },

    companyEmail(): string {
      return this.company.email || "";
    },

    companyAddress(): string {
      return this.company && this.company?.locations?.length
        ? this.company.locations[0].formatted_address
        : "";
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList") as string,
      };
    },

    /**
     * Get auth user interests
     */
    matchingInterests(): Array<IMatchingInterest> {
      return this.$store.state.matchingInterest.data;
    },

    /**
     * Check if store value is set and that it has values
     */
    hasMatchingInterests(): boolean {
      return this.matchingInterests && !!this.matchingInterests.length;
    },
  },
  created() {
    // If  store not filled, fetch interests
    if (!this.hasMatchingInterests) {
      this.fetchAuthUserInterests();
    }
  },
  methods: {
    fetchAuthUserInterests() {
      if (this.$user.isLogged()) {
        this.$store.dispatch(EMatchingInterestActions.FETCH);
      }
    },
    showListModal() {
      this.$emit("click");
    },
  },
  static: {
    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },
});
