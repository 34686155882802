





















import Vue from "vue";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";

export interface ILegendItems {
  title: string;
  icon: string;
  iconSize: number;
}

export default Vue.extend({
  name: "GridLegendContainer",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    legend: {
      required: true,
      type: Array as () => Array<ILegendItems>,
    },
    tooltipText: {
      type: String,
      default: "",
    },
  },
});
