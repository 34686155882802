var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NavigationFooter',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNavigationFooter),expression:"showNavigationFooter"}]},[_c('template',{slot:"left"},[_c('ElTooltip',{attrs:{"popper-class":"list-management-bar__cta-tooltip","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.selectAllButtonTooltipText)+" ")]),_c('PxButton',_vm._b({class:{
            'list-management-bar__select-all-cta': !_vm.isAllSelected,
            'list-management-bar__select-all-cta--disabled':
              !_vm.availableItems.length,
          },on:{"click":_vm.onSelectAllButtonClick}},'PxButton',_vm.selectAllButtonProps,false))],1),_c('span',{staticClass:"list-management-bar__selected-text",class:{
          'list-management-bar__selected-text--empty': !_vm.hasSelectedCompanies,
        }},[_vm._v(" "+_vm._s(_vm.selectedMembersCopy)+" ")])],1),_c('template',{slot:"right"},[_c('PxButton',_vm._b({staticClass:"list-management-bar__cancel-cta",on:{"click":_vm.onCancelButtonClick}},'PxButton',_vm.cancelButtonProps,false)),_c('PxButton',_vm._b({staticClass:"list-management-bar__add-move-cta",attrs:{"disabled":!_vm.hasSelectedCompanies,"data-testid":"add-to-list-button"},on:{"click":_vm.onShowListManagementModal}},'PxButton',_vm.addToListButtonProps,false)),(
          _vm.isCurrentContext(_vm.EListManagementContext.LIST_DETAIL) && !_vm.isSmartList
        )?_c('PxButton',_vm._b({staticClass:"list-management-bar__remove-cta",attrs:{"disabled":_vm.showRemoveConfirmation || !_vm.hasSelectedCompanies},on:{"click":_vm.onRemoveButtonClick}},'PxButton',_vm.removeButtonProps,false)):_vm._e()],1)],2),_c('ListManagementConfirmationDialog',{attrs:{"visibility":_vm.showRemoveConfirmation,"selected-company-name":_vm.selectedCompanyName,"selected-companies":_vm.selectionCompanyList,"data-testid":"list-management-bar__management-confirmation-dialog"},on:{"update:visibility":function($event){_vm.showRemoveConfirmation=$event},"remove-companies":_vm.onRemoveConfirmationClick}}),(_vm.showAddToListModal)?_c('ListManagementModal',{attrs:{"visibility":_vm.showAddToListModal,"context":_vm.context,"current-tab":_vm.currentTab,"edited-list":_vm.innerEditedList,"is-smart-list":_vm.isSmartList,"data-testid":"list-management-bar__management-modal"},on:{"update:visibility":function($event){_vm.showAddToListModal=$event},"update:currentTab":function($event){_vm.currentTab=$event},"update:current-tab":function($event){_vm.currentTab=$event},"close-tab":_vm.onCancelButtonClick}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }