







import Vue from "vue";

export default Vue.extend({
  name: "PanelEmptyState",

  props: {
    icon: {
      type: String,
      default: null,
    },
  },
});
