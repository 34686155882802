

















































































import Vue from "vue";
import AffiliateLinkEditModal from "@/modules/profile/components/affiliate-link-edit-modal/affiliate-link-edit-modal.vue";
import { copyToClipboard } from "@/utils/clipboard";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { PROFILE_AFFILIATE_LINK_QUERY } from "@/modules/profile/constants";

export default Vue.extend({
  name: "AffiliateLinkPanel",

  components: {
    AffiliateLinkEditModal,
  },

  data() {
    return {
      isAffiliateLinkEditModalVisible: false,
      copiedVisibility: false,
    };
  },

  computed: {
    // TODO: understand why Supporter type is being created only on matching module
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    affiliate(): IAffiliate | null {
      return this.supporter?.affiliate || null;
    },

    hasAffiliateLink(): boolean {
      return !!this.affiliate;
    },

    affiliateLink(): string {
      if (!this.affiliate) {
        return "";
      }

      const linkSlug = this.affiliate.slug;
      return `${location.origin}/${PROFILE_AFFILIATE_LINK_QUERY}${linkSlug}`;
    },

    isLoading(): boolean {
      return (
        this.$store.get("profile/supporter/loading") ||
        this.$store.get("profile/company/loading")
      );
    },
  },

  methods: {
    onClickGenerate() {
      this.isAffiliateLinkEditModalVisible = true;
    },

    onClickCopyLink() {
      this.copiedVisibility = true;
      copyToClipboard(this.affiliateLink);

      setTimeout(() => (this.copiedVisibility = false), 2000);
    },
  },
});
