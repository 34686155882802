





























import Vue from "vue";

import CategoryColorBar from "@/modules/affiliates/components/category-color-bar/category-color-bar.vue";

import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryStepItem } from "@/modules/affiliates/views/affiliate.types";

export default Vue.extend({
  name: "CategoryWizardMobile",

  components: {
    CategoryColorBar,
  },

  props: {
    steps: {
      type: Array as () => ICategoryStepItem[],
      required: true,
    },

    currentStep: {
      type: Number,
      default: 0,
    },

    active: {
      type: Number,
      default: null,
    },
  },

  computed: {
    assessmentLevels(): Array<ICategoryLevelSelection> {
      return (
        this.$store.state.affiliates.draftSubmission?.data.assessment || []
      );
    },

    /**
     * Get all categories from the store.
     */
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Information of the current selected step.
     */
    currentStepData(): ICategoryStepItem {
      return this.steps[this.currentStep];
    },

    /**
     * Get the category for the current step.
     */
    currentCategory(): ICategory | null {
      if (this.categories.length === 0) {
        return null;
      }

      return this.categories[this.currentStep];
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      if (!this.assessmentLevels || this.assessmentLevels.length === 0) {
        return null;
      }

      const selectedDetail = this.assessmentLevels.find(
        (record: ICategoryLevelSelection): any => {
          return (
            this.currentCategory && record.category === this.currentCategory.id
          );
        },
      );

      return selectedDetail || null;
    },

    /**
     * Informs if the level tag must be shown.
     *
     * The tag only is visible when there is a level selected.
     */
    showLevelTag(): boolean {
      return !!this.selectedStepData && this.selectedStepData.level > 0;
    },

    /**
     * Informs that size to use with the tag.
     */
    tagSize(): string {
      return this.$screen.smDown ? "small" : "medium";
    },
  },
});
