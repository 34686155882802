


































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import { generateRandomString } from "@/utils/generate-random-string";

import ListShareClipboardButton from "@/modules/company-lists/components/list-share/list-share-clipboard-button.vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default Vue.extend({
  name: "ListShareSettingsPasscode",

  components: {
    ListShareClipboardButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    needsReset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPasscode: "",
      isRestrictedByPasscode: false,
    };
  },

  static: {
    resetButton: {
      icon: "password-lock--red",
      size: EPxButtonSize.SMALL,
      type: EPxButtonType.GHOST_RED,
    },
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    hasCurrentlyPasscode(): boolean {
      return !!this.value.length;
    },
  },

  watch: {
    value(newValue: string) {
      if (this.currentPasscode !== newValue) {
        this.currentPasscode = newValue;
      }
    },
    currentPasscode(newPasscode: string) {
      if (this.value !== newPasscode) {
        this.$emit("update:value", newPasscode);
      }
    },
    needsReset(newValue: boolean) {
      if (newValue) {
        this.isRestrictedByPasscode = this.hasCurrentlyPasscode;
        this.$emit("update:needsReset", !newValue);
      }
    },
  },

  created() {
    this.isRestrictedByPasscode = this.hasCurrentlyPasscode;

    if (this.hasCurrentlyPasscode) {
      this.currentPasscode = this.value;
    }
  },

  methods: {
    changeRestrictionHandler(isRestricted: boolean) {
      // If non-existing passcode, generate a new one:
      if (isRestricted && !this.hasCurrentlyPasscode && !this.currentPasscode) {
        this.currentPasscode = generateRandomString();
      }

      this.$emit("change", isRestricted, this.currentPasscode);
    },

    clickResetPasscodeHandler() {
      this.$root.$emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.RESET_PASSWORD,
      });
    },
  },
});
