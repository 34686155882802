















import Vue from "vue";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default Vue.extend({
  name: "ListManagementModalItem",

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },
  },

  data() {
    return {
      innerSelected: false,
    };
  },

  computed: {
    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    isListSelected(): boolean {
      return this.selectedLists.some(
        (item: ICompanyList) => item.uid === this.value.uid,
      );
    },
  },

  watch: {
    isListSelected: {
      immediate: true,
      handler(newValue: boolean) {
        // Prevent hot reloading loop
        if (newValue === this.innerSelected) {
          return;
        }

        this.innerSelected = newValue;
      },
    },

    innerSelected: {
      immediate: true,
      handler(newValue: boolean) {
        // Prevent hot reloading loop
        if (newValue === this.isListSelected) {
          return;
        }

        if (newValue) {
          this.$store.dispatch(EListManagementActions.ADD_LIST, this.value);
          return;
        }

        this.$store.dispatch(EListManagementActions.REMOVE_LIST, this.value);
      },
    },
  },

  methods: {
    // Allow trigger select after clicking anywhere on the item
    onItemClick() {
      this.innerSelected = !this.innerSelected;
    },
  },
});
