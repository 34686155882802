



















































import Vue from "vue";
import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import throttle from "lodash/throttle";

export default Vue.extend({
  name: "MultiSelectorEntry",

  props: {
    item: {
      type: Object as () => IMultiSelectorItem,
      required: true,
    },

    /**
     * Define if will always show parent label.
     */
    showParentLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showAllItems: false,
      childScrollTop: 0,
    };
  },

  computed: {
    formattedItem(): IMultiSelectorItem {
      if (!this.item.children || this.item.children.length === 0) {
        return {
          ...this.item,
          children: [],
        };
      }

      // Filter selected children
      const selectedChildren = this.item.children.filter(
        (child: IMultiSelectorItem) => child.selected,
      );

      // If only on child and show parent label is disabled, them use only child label
      if (selectedChildren.length === 1 && !this.showParentLabel) {
        return {
          ...selectedChildren[0],
          children: [],
        };
      }

      return { ...this.item, children: [...selectedChildren] };
    },

    hasChildItems(): boolean {
      return (
        !!this.formattedItem.children && this.formattedItem.children.length > 1
      );
    },

    formattedChildCopy(): string {
      // If no children, just return empty string
      if (
        !this.formattedItem.children ||
        this.formattedItem.children.length === 0
      ) {
        return "";
      }

      // If only one item, check for show parent label value
      if (this.formattedItem.children.length === 1 && this.showParentLabel) {
        return `(${this.formattedItem.children[0].label})`;
      }

      return `(${this.formattedItem.children.length})`;
    },

    hasChildContainerBeenScrolled(): boolean {
      return this.childScrollTop > 0;
    },
  },

  mounted() {
    const childContainerRef = this.$refs.childContainer as any;

    childContainerRef.addEventListener(
      "scroll",
      throttle(() => this.onChildContainerScroll(childContainerRef), 100),
      {
        passive: true,
        capture: false,
      },
    );
  },

  beforeDestroy() {
    window.removeEventListener(
      "scroll",
      throttle(() => this.onChildContainerScroll, 100),
    );
  },

  methods: {
    onChildContainerScroll(elementRef: any) {
      this.childScrollTop = elementRef.scrollTop;
    },

    onEditItemClick() {
      this.$emit("edit", this.item.value);
    },

    onRemoveItemClick() {
      this.$emit("remove", this.item.value);
    },

    /**
     * Toggle show all items visibility
     */
    onShowAllItemsClick() {
      if (this.hasChildItems) {
        this.showAllItems = !this.showAllItems;
      }
    },
  },
});
