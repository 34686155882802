


















import Vue from "vue";

export default Vue.extend({
  name: "PxSwitch",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    width: {
      type: Number,
      default: 34,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    value(newValue: boolean) {
      if (this.isActive !== newValue) {
        this.isActive = newValue;
      }
    },
    isActive(newValue: boolean) {
      if (this.value !== newValue) {
        this.$emit("update:value", newValue);
      }
    },
  },

  created() {
    this.isActive = this.value;
  },

  methods: {
    labelClickHandler() {
      (this as any).$refs.switch.switchValue();
    },

    switchChangeHandler(newValue: boolean) {
      this.$emit("change", newValue);
      this.$emit("update:value", newValue);
    },
  },
});
