







































































import PxPanelSection from "@/components/px-panel-section/px-panel-section.vue";
import InvestorsPanelAccordion from "@/modules/profile/components/investors-panel/investors-panel-accordion.vue";
import InvestorsPanelInactive from "@/modules/profile/components/investors-panel/investors-panel--inactive.vue";

import MilestonesMixin from "@/mixins/milestones.mixin";

export default MilestonesMixin.extend({
  name: "InvestorsPanel",

  components: {
    PxPanelSection,
    InvestorsPanelAccordion,
    InvestorsPanelInactive,
  },

  data() {
    return {
      selectedTab: "next",
      tabsMobileItems: [
        {
          value: "next",
          label: this.$t("profile.investors.nextMilestones"),
        },
        {
          value: "achieved",
          label: this.$t("profile.investors.achievedMilestones"),
        },
      ],
    };
  },

  computed: {
    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },
  },
});
