































import Vue from "vue";

export default Vue.extend({
  name: "PxNavigationTabItem",

  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 17,
    },
    listFormat: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => {
        return ["left", "right"].includes(value);
      },
    },
  },

  methods: {
    onTabClick() {
      this.$emit("click");
    },
  },
});
