














































































































































import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";

import PxEditPanel from "@/components/px-edit-panel/px-edit-panel.vue";
import LocationsSelector from "@/components/locations-selector/locations-selector.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ILocation } from "@/services/data/location/location.interface";
import { ISector } from "@/services/data/sector/sector.interface";
import { EUserMetadataActions } from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_FROM_ONBOARDING,
} from "@/modules/matching/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ROUTE_UPDATE_RANGE_LEVEL } from "@/modules/profile/services/router/routes-names";
import { PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING } from "@/modules/profile/constants";
import { SUPPORTERS_INVESTING_RANGE_LIMIT } from "@/modules/supporters/constants";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";

import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import {
  EAuthActions,
  EAuthSupporterActions,
} from "@/modules/authentication/services/store/auth/auth-types";

export default ElDialogMixin.extend({
  name: "MatchingSupporterConfirmModal",

  components: {
    PxEditPanel,
    LocationsSelector,
    SectorsSelector,
  },

  data() {
    return {
      hasUpdatedRangeLevel: false,
      hasInvalidData: false,
      hasServerError: false,
      isLoading: false,

      newLocations: [] as Array<ILocation>,
      hasSpecificLocations: false,
      newSectors: [] as Array<number>,
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("auth/supporter.data");
    },

    supporterSectorsTitle(): string {
      return this.$t("profile.company.sectorsOfInterest") as string;
    },

    supporterSectorsOfInterest(): Array<ISector> {
      return this.supporter && this.supporter.sectors
        ? this.supporter.sectors
        : [];
    },

    supporterLocationsTitle(): string {
      return this.$t("profile.company.locationsOfInterest") as string;
    },

    supporterLocationsOfInterest(): Array<ILocation> {
      return this.supporter && this.supporter.locations
        ? this.supporter.locations
        : [];
    },

    supporterInvestingLevelRange(): Array<number> {
      return this.supporter ? uniq(this.supporter.investing_level_range) : [];
    },

    supporterCategories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    supporterCategory(): ICategory | null {
      return this.supporterCategories.length
        ? this.supporterCategories[0]
        : null;
    },

    investingLevels(): Array<number> {
      const levelsRange = this.supporterInvestingLevelRange;
      const startLevel = levelsRange[0];

      if (!levelsRange[1]) {
        return levelsRange;
      }

      // Create number sequence from the levels range
      return Array.from(
        new Array(SUPPORTERS_INVESTING_RANGE_LIMIT),
        (val, index) => index + startLevel,
      ).filter((level: number) => level <= levelsRange[1]);
    },

    investingLevelRangeInterval(): string {
      return this.supporterInvestingLevelRange.join("-");
    },

    investingLevelRangeSize(): number {
      return this.supporterInvestingLevelRange[1] ? 1 : 0;
    },

    investingLevelRangeTitle(): string {
      return this.$t(
        `profile.investors.panel.label[${this.investingLevelRangeSize}]`,
        [...this.supporterInvestingLevelRange],
      ) as string;
    },

    investingLevelRangeDescription(): string {
      let levelsDescription = "";

      this.investingLevels.forEach((level: number) => {
        const category = this.getCategoryDetailsByLevel(level);
        const description = category ? category.achievements : "";

        levelsDescription += `Level ${level} - ${description}<br>`;
      });

      return levelsDescription;
    },

    comingFromLevelRangeUpdate(): boolean {
      return !!this.$route.query.updatedRangeLevel;
    },

    profileId(): number {
      return this.$store.state.auth.profileId;
    },
  },

  created() {
    this.hasUpdatedRangeLevel = this.comingFromLevelRangeUpdate;

    // Fetch categories
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: SUPPORTER_USER_GROUP_ID,
    });

    this.setCurrentLocations();
    this.setCurrentSectors();
  },

  methods: {
    /**
     * Fetch current supporter auth data
     */
    fetchSupporter() {
      this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
        user_profile: this.profileId,
      });
    },

    /**
     * Set active/selected locations on the selector component
     */
    setCurrentLocations() {
      this.newLocations = cloneDeep(this.supporterLocationsOfInterest);
    },

    onCancelLocations() {
      this.hasInvalidData = false;
      this.setCurrentLocations();
    },

    /**
     * Save the changes on the location field.
     */
    onSubmitLocations(success: Function) {
      if (this.newLocations.length || !this.hasSpecificLocations) {
        // If no specific locations, send empty array but avoid removing new location as an UX approach
        const location = this.hasSpecificLocations ? this.newLocations : [];

        this.partialUpdate({ location }, success);
      } else {
        this.notifyInvalidData(success);
      }
    },

    /**
     * Set active/selected sectors on the selector component
     */
    async setCurrentSectors() {
      this.newSectors = this.supporterSectorsOfInterest.map(
        (sector: ISector) => sector.id,
      );

      // Set active sectors
      await this.$nextTick();
      const sectorSelector = this.$refs.sectorSelector as any;
      if (sectorSelector) {
        sectorSelector.setSectors(this.supporterSectorsOfInterest);
      }
    },

    onCancelSectors() {
      this.hasInvalidData = false;
      this.setCurrentSectors();
    },

    /**
     * Save the changes made to the sectors.
     */
    onSubmitSectors(success: Function) {
      if (this.newSectors.length) {
        this.partialUpdate({ sectors: this.newSectors }, success);
      } else {
        this.notifyInvalidData(success);
      }
    },

    /**
     * Request a new Investment Viral Level Range.
     */
    onEditInvestmentRangeLevel() {
      this.$router.push({
        name: ROUTE_UPDATE_RANGE_LEVEL,
      });
      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_UPDATE_FROM_SUPPORTER_MATCHING,
        value: true,
      });
    },

    /**
     * Find category data by level
     */
    getCategoryDetailsByLevel(level: number) {
      if (this.supporterCategory) {
        return this.supporterCategory.categoryDetails.find(
          (category: ICategoryDetail) => category.level.value === level,
        );
      }
    },

    /**
     * Validate supporter locations on input
     */
    insertedLocationsHandler(insertedLocations: Array<ILocation>) {
      if (insertedLocations.length) {
        this.hasSpecificLocations = true;
      }
    },

    /**
     * Show error message when there's invalid data.
     */
    notifyInvalidData(success: Function) {
      this.hasInvalidData = true;

      // set final state for the save operation
      success(false);
    },

    /**
     * Perform a partial supporter data update.
     */
    async partialUpdate(newData: any, success: Function) {
      this.hasInvalidData = false;
      this.hasServerError = false;

      try {
        const supporterId = this.supporter ? this.supporter.id : null;

        if (!supporterId) return;

        await this.$store.dispatch(EAuthSupporterActions.PATCH, {
          ...newData,
          id: supporterId,
          locations: newData.location,
        });

        // This will update the supporter auth state after the patch
        this.fetchSupporter();
      } catch (error) {
        this.hasServerError = true;
      }

      success(!this.hasServerError);
    },

    /**
     * Mark the matching onboarding as finished.
     */
    async onClickProceedButton() {
      this.hasInvalidData = false;
      this.hasServerError = false;
      this.isLoading = true;

      try {
        await this.$store.dispatch(EUserMetadataActions.SET, {
          key: MATCHING_ONBOARDING_COMPLETION,
          value: "true",
        });
        this.$emit("update:visibility", false);
      } catch (_) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      // Cleanup meta module
      this.$store.dispatch(EMetaActions.REMOVE, MATCHING_FROM_ONBOARDING);
    },

    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
