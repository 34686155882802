import {
  DEFAULT_MATCH,
  EXCELLENT_MATCH,
  FAIR_MATCH,
  POOR_MATCH,
} from "@/modules/capital-explorer/capital-structures-matching.types";
import {
  ICapitalExplorerCriteriaWeights,
  ICapitalExplorerData,
  ICapitalExplorerVisitorData,
  ICapitalTileInfo,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { CAPITAL_EXPLORER_VISITORS_VIEW } from "@/modules/capital-explorer/services/router/routes-names";
import { ECapitalExplorerMutations } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import {
  IMatchingQuestion,
  MULTI_SELECT,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import router from "@/services/router/router";
import store from "@/services/store";

import { cloneDeep } from "lodash";

const prefillCapitalTilesInfo = (
  capitalExplorerData: ICapitalExplorerData,
  questions: IMatchingQuestion[],
) => {
  return capitalExplorerData.funding_sources.map((fundingSource) => {
    // remove criteria that are not in the questions list
    fundingSource.funding_criteria = fundingSource.funding_criteria.filter(
      (criteria) =>
        questions.find((question) => question.id === criteria.question),
    );

    return {
      capitalInfo: {
        id: fundingSource.id,
        title: fundingSource.name,
        subtitle: fundingSource.description,
        about: fundingSource.about,
        key_characteristics: fundingSource.key_characteristics,
        key_implications: fundingSource.key_implications,
        funding_types: fundingSource.funding_types,
        funding_stages: fundingSource.funding_stages,
        related_links: fundingSource.related_links || null,
        company_stages: fundingSource.company_stages.map(({ name }) => name),
      },
      matchProperties: DEFAULT_MATCH,
      matchDetails: fundingSource.funding_criteria.map((criteria) => {
        const criteriaQuestion = questions.find(
          (question) => question.id === criteria.question,
        );

        if (criteriaQuestion) {
          const criteriaDesiredAnswers = criteria.answers.map(
            (answer) =>
              criteriaQuestion?.answers.find(({ id }) => id === answer)
                ?.value || "",
          );

          return {
            id: criteria.id,
            question: {
              id: criteriaQuestion.id,
              title: criteriaQuestion.entrepreneur_question,
              instructions: criteriaQuestion.instructions,
            },
            desiredAnswers: criteriaDesiredAnswers,
            userAnswers: [],
            match: false,
            score: 0,
          };
        }
      }),
    };
  }) as ICapitalTileInfo[];
};

export const calculateResults = () => {
  const isVisitorView =
    router.currentRoute.name === CAPITAL_EXPLORER_VISITORS_VIEW;

  const capitalExplorerData = store.get(
    "capitalExplorer/data",
  ) as ICapitalExplorerData;

  const capitalExplorerVisitorData = store.get(
    "capitalExplorer/visitorData",
  ) as ICapitalExplorerVisitorData;

  const questions = capitalExplorerData.questions || [];

  const responses = isVisitorView
    ? capitalExplorerVisitorData.responses
    : capitalExplorerData.submission.responses || [];

  const weights = capitalExplorerData?.weights || {
    matched: [0, 1, 10, 50, 250],
    unmatched: [0, 1, 10, 50, 500],
    unanswered: [0, 1, 10, 50, 350],
  };

  let capitalTilesInfo = prefillCapitalTilesInfo(
    capitalExplorerData,
    questions,
  );

  capitalExplorerData?.funding_sources.forEach((source) => {
    const tile = capitalTilesInfo.find(
      (tile) => tile.capitalInfo.id === source.id,
    );

    if (tile) {
      let availablePoints = 0;
      let earnedPoints = 0;
      const fundingCriteriaPoints: Array<any> = [];

      source.funding_criteria.forEach((criteria) => {
        const response = responses.find(
          (response) => response.question === criteria.question,
        );

        let matchResult: keyof ICapitalExplorerCriteriaWeights | null = null;

        if (
          criteria.question_type === MULTI_SELECT ||
          criteria.question_type === SINGLE_SELECT
        ) {
          if (response) {
            matchResult = criteria.answers.some((answer) =>
              response.answers.includes(answer),
            )
              ? "matched"
              : "unmatched";
          } else {
            matchResult = "unanswered";
          }
        }
        if (matchResult) {
          const criteriaPoints =
            weights[matchResult][criteria.criteria_weight - 1];
          availablePoints += criteriaPoints;
          if (matchResult === "matched") {
            earnedPoints += criteriaPoints;
          }

          const criteriaQuestion = questions.find(
            (question) => question.id === criteria.question,
          );
          const userAnswers =
            response?.answers.map(
              (answer) =>
                criteriaQuestion?.answers.find(({ id }) => id === answer)
                  ?.value || "",
            ) || [];

          tile.matchDetails.map((detail) => {
            if (detail.question.id === criteria.question) {
              detail.userAnswers = userAnswers;
              detail.match = matchResult === "matched";
            }
          });

          fundingCriteriaPoints.push({
            id: criteria["id"],
            points: criteriaPoints,
          });
        }
      });

      const score = availablePoints
        ? Math.round((earnedPoints / availablePoints) * 100)
        : 0;

      if (score > 98) {
        tile.matchProperties = EXCELLENT_MATCH;
      } else if (score > 69) {
        tile.matchProperties = FAIR_MATCH;
      } else {
        tile.matchProperties = POOR_MATCH;
      }

      tile.matchProperties = {
        ...tile.matchProperties,
        score,
      };

      tile.matchDetails.map((detail) => {
        const criteriaPoints = fundingCriteriaPoints.find(
          (criteria) => criteria.id === detail.id,
        ).points;

        detail.score = criteriaPoints
          ? Math.round((criteriaPoints / availablePoints) * 100)
          : 0;
      });
    }
  });

  if (!responses.length) {
    capitalTilesInfo.forEach((tile) => {
      tile.matchProperties = DEFAULT_MATCH;
    });
  }

  capitalTilesInfo = capitalTilesInfo.sort(
    (a, b) => (b.matchProperties.score || 0) - (a.matchProperties.score || 0),
  );

  if (!isVisitorView) {
    store.commit(`capitalExplorer/${ECapitalExplorerMutations.SET_DATA}`, {
      ...capitalExplorerData,
      results: cloneDeep(capitalTilesInfo),
    });
  } else {
    store.commit(
      `capitalExplorer/${ECapitalExplorerMutations.SET_VISITOR_DATA}`,
      {
        ...capitalExplorerVisitorData,
        results: cloneDeep(capitalTilesInfo),
      },
    );
  }
};
