




























import Vue from "vue";
import anime from "animejs";
import ClickOutside from "vue-click-outside";

interface Item {
  value: string;
  label: string;
}

const ELEMENT_HEIGHT = 44;

export default Vue.extend({
  name: "PxTabsMobile",

  directives: {
    ClickOutside,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * Disable the component.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      isReverse: false,
      innerValue: null,
    };
  },

  computed: {
    /**
     * Label of the current selected element.
     */
    label(): string {
      const selectedItem = this.items.find(
        (item: Item) => item.value === this.value,
      );
      return selectedItem.label;
    },

    nonSelectedElements(): any[] {
      return this.items.filter((item: Item) => item.value !== this.value);
    },
  },

  methods: {
    /**
     * Toggle the dropdown state.
     */
    triggerClickHandler() {
      const listElement = this.$refs.list as HTMLElement;
      const containerElement = this.$refs.container as HTMLElement;

      // Toggle dropdown state
      this.isOpen = !this.isOpen;

      let newHeight = 0;
      if (this.isOpen) {
        newHeight = ELEMENT_HEIGHT + listElement.offsetHeight;

        const newBottomLimit =
          containerElement.getBoundingClientRect().bottom +
          listElement.offsetHeight;

        this.isReverse = newBottomLimit > window.innerHeight;
      } else {
        newHeight = ELEMENT_HEIGHT;
      }

      // Animate container element
      return anime({
        targets: containerElement,
        minHeight: `${newHeight}px`,
        easing: "easeInOutQuart",
        duration: 300,
      });
    },

    /**
     * Change the selected item.
     */
    async itemClickHandler(item: Item) {
      await this.triggerClickHandler().finished;

      this.$emit("change", item.value);
      this.$emit("input", item.value);
    },

    /**
     * Handle clicks outside of element.
     *
     * This is used to close the element when the users
     * clicks outside of the element.
     */
    outsideClickHandler() {
      if (!this.isOpen) {
        return;
      }

      this.triggerClickHandler();
    },
  },
});
