














import Vue from "vue";

/**
 * Item to identify the state of the cell on the milestone grid
 */
export default Vue.extend({
  name: "PxStageStatusItem",

  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 26,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
});
