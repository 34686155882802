







































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

import LevelSlider from "@/modules/self-assessment/components/level-slider/level-slider.vue";
import LevelsDescription from "@/modules/self-assessment/components/levels-description/levels-description.vue";
import CategoryRequirements from "@/modules/self-assessment/components/category-requirements/category-requirements.vue";
import MobileFooterNav from "@/modules/self-assessment/components/mobile-footer-nav/mobile-footer-nav.vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "AssessmentDesktop",

  components: {
    LevelSlider,
    LevelsDescription,
    CategoryRequirements,
    MobileFooterNav,
  },

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  props: {
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    selectedCategory: {
      type: Number,
      default: () => null,
    },

    levels: {
      type: Array as () => any[],
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    onGoNext: {
      type: Function as () => any,
      required: true,
    },

    onGoPrev: {
      type: Function as () => any,
      required: true,
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    /**
     * Informs if this is the last step.
     */
    isLastStep(): boolean {
      return this.selectedCategory === this.levels.length - 1;
    },

    hasLatestAssessment(): IAssessment | null {
      return this.$store.get("auth/latestAssessment/data");
    },

    /**
     * Get the text for the next button.
     */
    nextButtonText(): TranslateResult {
      if (!this.selectedStep) {
        return this.$t("selfAssessment.assessment.nextButton.skip");
      }

      if (this.isLastStep) {
        return this.$user.isLogged() &&
          this.hasLatestAssessment &&
          this.selectedStep.level !== 0
          ? this.$t("selfAssessment.assessment.nextButton.nextFinalUpdate")
          : this.selectedStep.level === 0
          ? this.$t("selfAssessment.assessment.nextButton.skipFinal")
          : this.$t("selfAssessment.assessment.nextButton.nextFinal");
      }

      return this.selectedStep.level === 0
        ? this.$t("selfAssessment.assessment.nextButton.skip")
        : this.$t("selfAssessment.assessment.nextButton.next");
    },

    /**
     * Returns what type of button must be used for the next button.
     */
    nextButtonType(): string {
      return !this.selectedStep || this.selectedStep.level === 0
        ? "default"
        : "green";
    },

    nextButtonIconClass(): string {
      const iconClass =
        !this.selectedStep || this.selectedStep.level === 0
          ? "icon-arrow--right-grey"
          : "icon-arrow--right-white";
      return `${iconClass} icon--right`;
    },
  },
});
