/**
 * This plugin is responsible for preventing scale
 * the viewport on iOS devices, using a touch move.
 */

// This disable the pinch zoom
document.addEventListener(
  "touchmove",
  (event: any) => {
    event = event.originalEvent || event;

    if (event.scale !== 1) {
      event.preventDefault();
    }
  },
  false,
);

// This disable the double tap zoom
let lastTouchEnd = 0;
document.addEventListener(
  "touchend",
  (event: TouchEvent) => {
    const now = new Date().getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  },
  false,
);
