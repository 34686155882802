






















import Vue from "vue";

import SubmissionPanelEntrepreneur from "@/modules/program/components/submission-panel/submission-panel.vue";
import SubmissionPanelSupporter from "@/modules/supporters/components/submission/submission-panel.vue";
import ListManagementBar from "@/modules/company-lists/components/list-management/list-management-bar.vue";

import {
  IAffiliateProgramSubmission,
  IAffiliateListProgramSubmission,
} from "@/modules/program/services/data/affiliate-program-submission/affiliate-program-submission";
import {
  affiliateProgramSubmissionListProvider,
  affiliateProgramSubmissionEntryProvider,
} from "@/modules/program/services/data/affiliate-program-submission/affiliate-program-submission.provider";
import { EProfileCompanyActions } from "@/modules/profile/services/store/profile/profile-types";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { EMetaActions } from "@/services/store/meta/meta-types";
import { PROFILE_META_TAB_MENU_ITEMS } from "@/modules/profile/constants";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_ENTREPRENEUR_SUBMISSION } from "@/modules/program/services/router/routes-names";
import { affiliateProgramProgramSubmissionProvider } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.provider";

export default Vue.extend({
  components: {
    SubmissionPanelSupporter,
    SubmissionPanelEntrepreneur,
    ListManagementBar,
  },

  data() {
    return {
      // TODO: validate type
      submission: null as null | any,
      submissionList: [] as IAffiliateListProgramSubmission[],
      submissionLoading: false,
    };
  },

  computed: {
    /**
     * Shows loading if
     */
    isLoading(): boolean {
      return (
        this.submissionLoading ||
        !this.submission ||
        this.$store.get("profile/loading") ||
        this.$store.get("categories/loading") ||
        this.$store.get("levels/loading") ||
        this.$store.state.profile.loading
      );
    },

    canAccessSubmissionList(): boolean {
      return this.$user.isLogged() && this.canMatch;
    },

    isAdminAsUser(): boolean {
      return this.$store.state.auth.isAdmin;
    },

    uidFromParams() {
      return this.$route.params.uid;
    },

    canMatch(): boolean {
      return (
        this.$features.isEnabled("matchingInterest") &&
        this.$user.isLogged() &&
        !this.$user.isOwner() &&
        this.$profile.type() !== this.$user.getUserAccountType()
      );
    },

    isAuthUser(): boolean {
      return this.$user.isLogged();
    },

    showPublicView(): boolean {
      return !this.isAuthUser;
    },

    hasRegistrationListener(): boolean {
      return !!this.$listeners?.register;
    },

    profileCompany(): ICompany | null {
      return this.$store.state.profile.company.data;
    },

    isEntrepreneurSubmission(): boolean {
      return this.$route.name === ROUTE_ENTREPRENEUR_SUBMISSION;
    },
  },

  watch: {
    uidFromParams: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },

    canMatch() {
      this.setUserCanMatchWithProfile();
    },
  },

  created() {
    // Clear tab menu items
    this.$store.dispatch(EMetaActions.SET, {
      key: PROFILE_META_TAB_MENU_ITEMS,
      value: [],
    });
  },

  methods: {
    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    },

    async fetchCategories() {
      await this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    },

    async fetchSubmissionList(
      userProfileUid: string,
    ): Promise<IAffiliateListProgramSubmission[]> {
      let submissionList: IAffiliateListProgramSubmission[] = [];

      try {
        submissionList = await affiliateProgramSubmissionListProvider.get("", {
          user_profile: userProfileUid,
        });
      } catch {
        this.notifyErrorWhileFetchingSubmission();
      }

      return submissionList;
    },

    async fetchSubmissionEntry(
      submissionUid: string,
    ): Promise<IAffiliateProgramSubmission | null> {
      let submission: IAffiliateProgramSubmission | null = null;

      try {
        submission = await affiliateProgramSubmissionEntryProvider.get(
          submissionUid,
        );
      } catch {
        this.notifyErrorWhileFetchingSubmission();
      }

      return submission;
    },

    async fetchProfileCompany(companyId: number) {
      await this.$store.dispatch(EProfileCompanyActions.FETCH, companyId);
    },

    updateSubmissionLink(submissionUid: string) {
      if (this.submission) {
        this.$router.replace({
          params: { uid: submissionUid },
        });
      }
    },

    async fetchData() {
      this.submissionLoading = true;

      await this.fetchCategories();
      await this.fetchLevels();

      let companyId;
      let userProfileUid;

      if (this.isEntrepreneurSubmission) {
        // ROUTE ENTREPRENEUR
        this.submission = await this.fetchSubmissionEntry(this.uidFromParams);
        if (this.submission) {
          companyId = this.submission.user_profile.company;
          userProfileUid = this.submission.user_profile.uid;
        }
      } else {
        // ROUTE SUPPORTER
        this.submission = await affiliateProgramProgramSubmissionProvider.get(
          this.uidFromParams,
        );
        if (this.submission) {
          companyId = this.submission.supporter.user_profile.company.id;
          userProfileUid = this.submission.supporter.user_profile.uid;
        }
      }

      if (this.submission && companyId && userProfileUid) {
        // Fetch company that has submitted an entry for the affiliate program
        await this.fetchProfileCompany(companyId);

        if (this.canAccessSubmissionList && this.isEntrepreneurSubmission) {
          this.submissionList = await this.fetchSubmissionList(userProfileUid);
        }
      }

      this.submissionLoading = false;
    },

    notifyErrorWhileFetchingSubmission() {
      this.$message({
        message: this.$t("program.submission.errors.loading") as string,
        type: "error",
        duration: 10000,
        customClass: "is-full",
      });
    },

    setUserCanMatchWithProfile() {
      this.$store.dispatch(
        EMatchingInterestActions.SET_CAN_MATCH,
        this.canMatch,
      );
    },

    async onSubmissionChange(submissionUid: string) {
      // Force loading screen
      this.submission = null;
      this.submission = await this.fetchSubmissionEntry(submissionUid);

      // If submission exists, update url
      this.updateSubmissionLink(submissionUid);
    },

    onRegistration() {
      this.$emit("register");
    },

    onShowListManagementModal() {
      if (
        !this.profileCompany ||
        (this.showPublicView && this.hasRegistrationListener)
      ) {
        this.onRegistration();
        return;
      }

      // Select self
      this.$store.dispatch(
        EListManagementActions.ADD_COMPANY,
        this.profileCompany.uid,
      );

      // Emit root action, should be available on context
      this.$root.$emit("show-list-management-modal");
    },
  },
});
