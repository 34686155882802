



































import Vue from "vue";

import TeamMemberMandatoryData from "@/modules/team-management/components/team-member-settings/team-member-mandatory-data.vue";
import TeamMemberDemographicData from "@/modules/team-management/components/team-member-settings/team-member-demographic-data.vue";
import TeamMemberSettingsHeader from "@/modules/team-management/components/team-member-settings/team-member-settings-header.vue";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "TeamMemberSettings",

  components: {
    TeamMemberMandatoryData,
    TeamMemberDemographicData,
    TeamMemberSettingsHeader,
  },

  props: {
    memberData: {
      type: Object as () => ITeamMember,
      default: null,
    },

    memberIndex: {
      type: Number,
      default: 0,
    },

    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    hasChanges: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      memberDataOriginal: {} as ITeamMember | null,
      memberDataUpdated: {} as ITeamMember | null,
      shouldFormatResponses: true,
      memberMandatoryData: [] as Partial<ITeamMember> | null,
      mandatoryFormInvalid: false,
    };
  },

  computed: {
    isFromProfile(): boolean {
      return this.$route.path.includes("company/team-management");
    },
  },

  watch: {
    memberData: {
      immediate: true,
      deep: true,
      handler(newMemberData: ITeamMember | null) {
        this.memberDataOriginal = newMemberData
          ? cloneDeep(newMemberData)
          : {
              id: "",
              first_name: "",
              last_name: "",
              email: "",
              position: "",
              responses: [],
            };

        this.memberMandatoryData = pick(this.memberDataOriginal, [
          "first_name",
          "last_name",
          "email",
          "position",
        ]);

        this.memberDataOriginal.responses = this.questions.map(
          (question: IMatchingQuestion) =>
            (
              this.memberDataOriginal?.responses as Array<IMatchingResponse>
            ).find(
              (response: IMatchingResponse) =>
                response.question === question.id,
            ) || {
              question: question.id,
              value: {},
              answers: [],
              id: undefined,
            },
        );

        this.memberDataUpdated = cloneDeep(this.memberDataOriginal);
        this.shouldFormatResponses = true;
      },
    },
  },

  beforeDestroy() {
    this.memberDataUpdated = null;
  },

  methods: {
    updateMemberInfo(newMemberInfo: Partial<ITeamMember>) {
      this.memberDataUpdated = {
        ...this.memberDataUpdated,
        ...newMemberInfo,
      } as ITeamMember;

      this.$emit("update-member", this.memberDataUpdated);
    },

    responseHasValue(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max && !!response?.value?.min) ||
        !!response.value?.text
      );
    },

    updateOriginalResponses(newResponses: Array<IMatchingResponse>) {
      if (
        this.shouldFormatResponses &&
        this.memberDataOriginal &&
        this.memberDataUpdated
      ) {
        this.memberDataOriginal.responses = cloneDeep(newResponses);
        this.memberDataUpdated.responses = cloneDeep(newResponses);
        this.shouldFormatResponses = false;
      }
    },

    updateResponses(newResponses: Array<IMatchingResponse>) {
      if (this.memberDataUpdated) {
        this.memberDataUpdated.responses = cloneDeep(newResponses);

        this.$emit("update-member", this.memberDataUpdated);
      }
    },

    checkDemographicFormIsInvalid(isInvalid: boolean) {
      this.$emit("form-is-invalid", isInvalid);
    },

    checkMandatoryFormIsInvalid(isInvalid: boolean) {
      this.mandatoryFormInvalid = isInvalid;
      this.$emit("form-is-invalid", isInvalid);
    },
  },
});
