import { getAppEnv, isDevelopment } from "./utils/utils";
import Vue, { VueConstructor } from "vue";
import baseConfig from "@/features.json";

/**
 * Interface that represents the list of features.
 */
interface IFeatureList {
  [key: string]: boolean;
}

class FeatureManager {
  /**
   * List of features on this environment.
   */
  private features: IFeatureList = {};

  /**
   * Creates a new feature manager instance.
   *
   * This also loads the configuration.
   */
  constructor() {
    this.loadConfig();
  }

  /**
   * Load the feature configurations.
   *
   * This merges the base configurations with the environment
   * specific ones.
   */
  private loadConfig() {
    let environmentConfig = {};
    let partnerConfig = {};

    try {
      const environment = getAppEnv();
      environmentConfig = require(`@/features.${environment}.json`);
    } catch (_) {
      // Ignore it! This means that there no specific config
      // for this environment.
    }

    // Get specific configurations for the current partner, if
    // one is set.
    if (process.env.VUE_APP_PARTNER) {
      const partner = process.env.VUE_APP_PARTNER;

      try {
        partnerConfig = require(`@/features.${partner}.json`);
      } catch (_) {
        // Do nothing! This means that there is no specific
        // configurations for the current partner.
      }
    }

    this.features = {
      ...baseConfig,
      ...environmentConfig,
      ...partnerConfig,
    };
  }

  /**
   * Checks if the given feature is enabled for the
   * current environment.
   *
   * @param feature Feature to check.
   */
  public isEnabled(feature: string) {
    const featureState = this.features[feature];

    // Using an asterisk on the configurations is possible to
    // enable all features at once.
    if (this.features["*"]) {
      return true;
    }

    // In order to catch error we must throw an exception everytime
    // that a feature isn't present on the configuration
    if (featureState === undefined) {
      if (isDevelopment) {
        console.error(new Error(`Feature(${feature}) not defined.`));
      }
      return false;
    }

    return this.features[feature] as boolean;
  }
}

// Creates a singleton of the Feature Manager
export const featureManager = new FeatureManager();

// Install service on Vue
Vue.use((_Vue: VueConstructor) => {
  Object.defineProperty(_Vue.prototype, "$features", {
    get() {
      return featureManager;
    },
  });
});

// Register the `$features` property as a global of
// a Vue instance.
declare module "vue/types/vue" {
  interface Vue {
    $features: FeatureManager;
  }
}
