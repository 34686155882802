import { render, staticRenderFns } from "./overall-level.vue?vue&type=template&id=993107a2&scoped=true&"
import script from "./overall-level.vue?vue&type=script&lang=ts&"
export * from "./overall-level.vue?vue&type=script&lang=ts&"
import style0 from "./overall-level.vue?vue&type=style&index=0&id=993107a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "993107a2",
  null
  
)

export default component.exports