




















































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { LIST_SHARE_EVENTS } from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default Vue.extend({
  name: "ListShareUsersIndex",

  props: {
    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
      default: () => [],
    },
  },

  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },
  },

  methods: {
    unshareClickHandler(userProfile: number) {
      this.$root.$emit("list-share-modal-confirmation", {
        event: LIST_SHARE_EVENTS.UNSHARE_FROM_USER,
        payload: { user_profile: userProfile },
      });
    },
  },
});
