
import Vue from "vue";

import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ECategoryActions } from "@/services/store/category/category-types";

import { IUser } from "@/services/data/user/user.interface";

import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import {
  EAffiliatesGetters,
  EAffiliatesActions,
  IAffiliatesState,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "RenderlessEntrepreneurAssessment",

  computed: {
    companyToken(): string | null {
      return this.$route.query && (this.$route.query.token as string | null);
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    defaultAssessmentLevels(): Array<ICategoryLevelSelection> {
      return this.categories.map((item: ICategory) => ({
        category: item.id,
        level: 0,
      }));
    },

    assessmentLevels(): Array<ICategoryLevelSelection> {
      return this.$store.state.affiliates.draftSubmission?.data.assessment
        .length
        ? this.$store.state.affiliates.draftSubmission?.data.assessment
        : this.defaultAssessmentLevels;
    },

    selectedStepData(): ICategoryLevelSelection | null {
      return (
        this.$store.getters[EAffiliatesGetters.SELECTED_STEP_DATA] ||
        this.assessmentLevels[0]
      );
    },

    currentCategory(): ICategory | null {
      return this.$store.getters[EAffiliatesGetters.CURRENT_CATEGORY];
    },

    isFirstInteraction(): boolean {
      return this.affiliatesData.firstInteraction;
    },

    authUser(): IUser | null {
      return this.$store.get("auth/user");
    },
  },

  async created() {
    // TODO: validate if token was already used
    if (this.assessmentLevels.length === 0 && this.companyToken) {
      await this.onTokenSelfAssessmentLoad();
    }
  },

  methods: {
    /**
     * Fetch categories when self assessment is loaded directly by token
     */
    async onTokenSelfAssessmentLoad() {
      // Fetch categories asynchronously
      await this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });

      //TODO: check this reset action
      await this.$store.dispatch(EAffiliatesActions.RESET);
    },

    handleLevelSliderChange(newLevel: number) {
      if (!this.currentCategory) {
        throw new Error("Invalid state");
      }

      this.$store.dispatch(
        EAffiliatesActions.SET_SUBMISSION_ASSESSMENT_LEVELS,
        {
          category: this.currentCategory.id,
          level: newLevel,
        },
      );

      this.$store.dispatch(EAffiliatesActions.SET_INTERACTION);
    },
  },

  render(): any {
    if (this.$scopedSlots.default === undefined) {
      return;
    }

    return this.$scopedSlots.default({
      currentCategory: this.currentCategory,
      selectedStep: this.selectedStepData,
      onLevelChange: this.handleLevelSliderChange,
      isFirstInteraction: this.isFirstInteraction,
    });
  },
});
