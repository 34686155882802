







import Vue from "vue";

export default Vue.extend({
  name: "PxComingSoon",

  props: {
    /**
     * Label that will be presented on ribbon.
     */
    label: {
      type: String,
      default: "Coming Soon",
    },

    /**
     * Allow to hide the label, but to keep this component around
     * the child.
     */
    hidden: {
      type: Boolean,
      default: false,
    },
  },
});
