import { Route, RouteRecord } from "vue-router";
import { userManager } from "@/modules/authentication/user-manager";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { ERROR_ROUTE, ROUTE_LOGGED_HOME } from "@/services/router/router-names";
import { AFFILIATES_BASE } from "@/modules/affiliates/services/router/routes-names";
import { SUPPORTERS_STARTING_POINT_PROGRAM } from "@/modules/supporters/services/router/routes-names";
import store from "@/services/store";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { AFFILIATE_TARGET_ENTREPRENEUR } from "@/services/data/affiliate/affiliate.interface";

export const authenticationGuard = async (
  to: Route,
  from: Route,
  next: any,
) => {
  const routeHasAffiliateParameter =
    Object.prototype.hasOwnProperty.call(to.query, "a") ||
    Object.prototype.hasOwnProperty.call(to.query, "affiliate");

  // check if the user needs to be authenticated, if yes, and the users doesn't
  // have a session token redirect to the login page
  if (
    !userManager.isLogged() &&
    (to.matched.some((m: RouteRecord) => m.meta.auth) ||
      (to.name === ROUTE_PROFILE && !to.params.id))
  ) {
    return next({
      name: ROUTE_AUTHENTICATION_LOGIN,
      query: {
        redirectTo: to.fullPath,
      },
    });
  }
  // check if a logged user should see this page
  if (
    userManager.isLogged() &&
    to.matched.some((m: RouteRecord) => m.meta.guest)
  ) {
    return next({
      name: ROUTE_LOGGED_HOME,
    });
  }
  // if user is logged and route has affiliate parameter redirect to correct affiliate flow
  if (
    routeHasAffiliateParameter &&
    to.name !== AFFILIATES_BASE &&
    to.name !== SUPPORTERS_STARTING_POINT_PROGRAM
  ) {
    const affiliate = await store.dispatch(
      EAffiliatesActions.FETCH_AFFILIATE,
      to.query.a || to.query.affiliate,
    );

    if (affiliate) {
      if (affiliate.flow_target === AFFILIATE_TARGET_ENTREPRENEUR) {
        return next({
          name: AFFILIATES_BASE,
          query: to.query,
        });
      }

      return next({
        name: SUPPORTERS_STARTING_POINT_PROGRAM,
        query: to.query,
      });
    } else {
      return next({
        name: ERROR_ROUTE,
      });
    }
  } else {
    // authorize transition
    next();
  }
};
