





















































import Vue from "vue";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";
import { EMatchingRibbonType } from "@/modules/profile/services/data/matching-ribbon/matching-ribbon.types";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";
import {
  EMatchScoreDetailsActions,
  IMatchScoreDetails,
} from "@/modules/matching/services/store/match-score-details/match-score-details.types";

export default Vue.extend({
  name: "MatchingRibbon",

  components: {
    MatchDetailsModal,
  },

  props: {
    type: {
      type: Number as () => EMatchingRibbonType,
      default: EMatchingRibbonType.NORMAL,
    },
  },

  data() {
    return {
      isInterestDetailModalVisible: false,
      matchScoreDetails: null as IMatchScoreDetails | null,
    };
  },

  computed: {
    isLoading(): boolean {
      return this.isProfileLoading || this.isMatchScoreDetailsLoading;
    },

    isProfileLoading(): boolean {
      return (
        this.$store.get("profile/loading") ||
        this.$store.get("profile/company/loading")
      );
    },

    isMatchScoreDetailsLoading(): boolean {
      return this.$store.get("matchScoreDetails/loading");
    },

    hasMatchScoreDetailsError(): boolean {
      return !!this.$store.get("matchScoreDetails/error");
    },

    isRibbonVisible(): boolean {
      return this.isPublicView || this.isMatchable;
    },

    isPublicView(): boolean {
      return !this.$user.isLogged();
    },

    isSlimVersion(): boolean {
      return this.type === EMatchingRibbonType.SLIM;
    },

    isMatchable(): boolean {
      return (
        !this.$user.isOwner() &&
        this.$profile.type() !== this.$user.getUserAccountType()
      );
    },

    profileCompany(): ICompany | null {
      return this.$profile.company();
    },

    profileCompanyUid(): string | null {
      return this.profileCompany?.uid || null;
    },

    profileCompanyName(): string {
      return this.profileCompany?.name || "";
    },

    profileCompanyLogo(): string {
      return this.profileCompany?.logo || "";
    },

    ribbonTitle(): string {
      return (
        this.isPublicView
          ? this.$t("profile.company.publicMatchedWith.title", {
              name: this.profileCompanyName,
            })
          : this.hasMatch
          ? this.$t("profile.company.matchedWith.title", {
              name: this.profileCompanyName,
            })
          : this.$t("profile.company.zeroMatched.title")
      ) as string;
    },

    matchScore(): number | null {
      return this.matchScoreDetails ? this.matchScoreDetails.score : null;
    },

    hasMatch(): boolean {
      return this.matchScore ? this.matchScore > 0 : false;
    },

    ribbonSubtitle(): string {
      return (
        this.isPublicView
          ? this.isSlimVersion
            ? this.$t("profile.company.publicMatchedWith.subtitleSlim")
            : this.$t("profile.company.publicMatchedWith.subtitle")
          : this.$t("profile.company.matchedWith.subtitle")
      ) as string;
    },

    formattedMatchPercentage(): string | null {
      if (this.isPublicView || this.matchScore === null) {
        return "?%";
      }

      if (this.matchScore) {
        return `${Math.trunc(this.matchScore)}%`;
      }

      return "0%";
    },
  },

  watch: {
    isProfileLoading: {
      immediate: true,
      async handler(isProfileLoading: boolean) {
        if (isProfileLoading) return;

        if (!this.isPublicView && this.isMatchable) {
          await this.getMatchScoreDetails();

          this.matchScoreDetails = this.$store.get("matchScoreDetails/data");
        }
      },
    },
  },

  beforeDestroy() {
    this.matchScoreDetails = null;
  },

  methods: {
    async getMatchScoreDetails() {
      await this.$store.dispatch(
        EMatchScoreDetailsActions.FETCH,
        this.profileCompanyUid,
      );
    },

    onRibbonClick(event: MouseEvent) {
      event.preventDefault();

      if (this.isPublicView) {
        // On public view, redirect to sign up
        return this.$router.push({
          name: ROUTE_AUTHENTICATION_SIGNUP,
          query: {
            fromAssessment: "true",
          },
        });
      }

      this.isInterestDetailModalVisible = true;
    },
  },
});
