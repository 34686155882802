














































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import {
  ICompanyList,
  ICompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IMatchingCard } from "@/modules/matching/services/data/matching-card/matching-card.interface";
import { IAvatarSetItem } from "@/components/px-avatar/px-avatar-set.vue";
import ListOptions from "@/modules/company-lists/components/list-options/list-options.vue";
import ListShare from "@/modules/company-lists/components/list-share/list-share.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

export default Vue.extend({
  name: "ListDetailPanelHeader",

  components: {
    ListOptions,
    ListShare,
    PxStatusBadge,
  },

  static: {
    smartListIcon: {
      backgroundColor: "rgba(241, 182, 0, 0.1)",
      labelColor: "#f1b600",
      icon: "stars",
    },
  },

  props: {
    value: {
      type: Object as () => ICompanyList | null,
      default: null,
    },

    members: {
      type: Array as () => Array<IMatchingCard>,
      default: () => [],
    },

    listUserType: {
      type: Number,
      required: true,
    },
  },

  computed: {
    smartListBadge(): any {
      return {
        ...this.$options.static.smartListIcon,
        label: this.$t("companyLists.smartList.label"),
      };
    },

    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    detailCopy(): LocaleMessages {
      return this.$t("companyLists.detail") as LocaleMessages;
    },

    showActions(): boolean {
      return !!this.value && this.$user.isLogged();
    },

    isOwner(): boolean {
      return !!this.value?.is_owner;
    },

    isSmartList(): boolean {
      return this.value ? this.value.is_smart_list : false;
    },

    userAvatars(): Array<IAvatarSetItem> {
      return (this.value?.invited_users || []).map((user) => ({
        name: user.name,
        logo: user.photo || "",
      }));
    },

    title(): string {
      return this.value?.title || "";
    },

    description(): string {
      return this.value?.description || "";
    },

    companies(): Array<ICompanyOfList> {
      return this.value?.companies || [];
    },

    memberQuantity(): number {
      return this.value?.companyCount || this.companies.length;
    },
  },

  methods: {
    joinClickHandler() {
      this.$emit("register");
    },
  },
});
