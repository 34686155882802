import Vue from "vue";

import { ElMessageComponent } from "element-ui/types/message";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

let errorMessage: ElMessageComponent | null = null;

export default Vue.extend({
  computed: {
    hasError(): boolean {
      return (
        !!this.affiliatesData.error || !!this.$store.state.categories.error
      );
    },

    affiliate(): IAffiliate | null {
      return this.$store.get("affiliates/affiliate");
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    submissionInProgress(): boolean {
      return !!this.affiliatesData.draftSubmission?.updated_at;
    },

    startButtonType(): string {
      return this.hasError
        ? "default"
        : this.submissionInProgress
        ? "blue"
        : "green";
    },

    startButtonIcon(): string | null {
      return this.hasError ? "icon-refresh" : null;
    },
  },

  watch: {
    hasError(value, oldVal) {
      if (value === oldVal) {
        return;
      }

      if (value === true) {
        this.showErrorMessage();
      } else if (errorMessage !== null) {
        errorMessage.close();
      }
    },
  },

  methods: {
    showErrorMessage() {
      errorMessage = this.$message({
        message: "Something went wrong. Please refresh the page.",
        type: "error",
        duration: 0,
        customClass: "is-full",
      });
    },
  },
});
