




























import Vue from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

export default Vue.extend({
  name: "LevelCard",

  props: {
    /**
     * Current category.
     */
    category: {
      type: Object as () => any,
      required: true,
    },
    /**
     * Current level.
     */
    level: {
      type: Number,
      required: false,
      default: 0,
    },

    levelDescription: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {
    /**
     * Categories details sorted
     */
    sortedDetails(): Array<ICategoryDetail> {
      const rawDetails = [
        ...(this.category.categoryDetails as Array<ICategoryDetail>),
      ];
      return rawDetails.sort((a, b) => b.level.value - a.level.value);
    },
    /**
     * Current Level Category details
     */
    currentDetails(): ICategoryDetail | undefined {
      return this.sortedDetails.find(
        (item: ICategoryDetail) => item.level.value === this.level,
      );
    },
  },

  watch: {},
});
