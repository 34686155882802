




























import Vue from "vue";
import LevelUpdateCard from "@/modules/common/components/level-update-modal/level-update-card.vue";

import { ILevel } from "@/services/data/level/level.interface";

export default Vue.extend({
  name: "LevelUpdateModalFirstTab",

  components: {
    LevelUpdateCard,
  },

  static: {
    staticSameLevelPrevAssessment: [
      {
        category: 28,
        level: 2,
      },
      {
        category: 30,
        level: 4,
      },
    ],
    staticLevelDownPrevAssessment: [
      {
        category: 26,
        level: 3,
      },
      {
        category: 31,
        level: 3,
      },
    ],
    staticLevelUpPrevAssessment: [
      {
        category: 26,
        level: 2,
      },
    ],
    staticSameLevelCurrentAssessment: [
      {
        category: 28,
        level: 4,
      },
      {
        category: 30,
        level: 2,
      },
    ],
    staticLevelDownCurrentAssessment: [
      {
        category: 26,
        level: 2,
      },
      {
        category: 31,
        level: 4,
      },
    ],
    staticLevelUpCurrentAssessment: [
      {
        category: 26,
        level: 5,
      },
    ],
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel | null,
      default: null,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    sameLevel: {
      type: Boolean,
      default: false,
    },
    levelUp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    staticPrevLevel(): number {
      return this.sameLevel || this.levelUp ? 2 : 3;
    },

    staticNewLevel(): number {
      return this.levelUp ? 3 : 2;
    },

    staticPrevAssessment(): any[] {
      return this.sameLevel
        ? this.$options.static.staticSameLevelPrevAssessment
        : this.levelUp
        ? this.$options.static.staticLevelUpPrevAssessment
        : this.$options.static.staticLevelDownPrevAssessment;
    },

    staticCurrentAssessment(): any[] {
      return this.sameLevel
        ? this.$options.static.staticSameLevelCurrentAssessment
        : this.levelUp
        ? this.$options.static.staticLevelUpCurrentAssessment
        : this.$options.static.staticLevelDownCurrentAssessment;
    },

    typeOfUpdate(): string {
      return this.sameLevel
        ? "same-level"
        : this.levelUp
        ? "level-up"
        : "level-down";
    },
  },

  methods: {
    getLevelDescription(level: string) {
      const currentLevel = this.levels.find((lvl: any) => lvl.value == level);

      return currentLevel?.title;
    },
  },
});
