


























import Vue from "vue";
import MilestoneFormFooterPlan from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-plan.vue";
import MilestoneFormFooterComplete from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer-complete.vue";
import {
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "../../services/data/milestones/milestone.interface";

export default Vue.extend({
  name: "MilestoneFormFooter",

  components: {
    MilestoneFormFooterPlan,
    MilestoneFormFooterComplete,
  },

  props: {
    activeTab: {
      type: String as () => TMilestoneDetailsTab,
      required: true,
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
    hasPlan: {
      type: Boolean,
      default: false,
    },
    isPlanPublished: {
      type: Boolean,
      default: false,
    },
    planFormHasChanges: {
      type: Boolean,
      default: false,
    },
    planFormIsInvalid: {
      type: Boolean,
      default: false,
    },
    planFormIsComplete: {
      type: Boolean,
      default: false,
    },
    userAddsEvidence: {
      type: Boolean,
      default: false,
    },
    hasCompletionInfo: {
      type: Boolean,
      default: false,
    },
    isCompletePublished: {
      type: Boolean,
      default: false,
    },
    completeFormHasChanges: {
      type: Boolean,
      default: false,
    },
    completeFormIsInvalid: {
      type: Boolean,
      default: false,
    },
    belowMilestonesNotCompleted: {
      type: Boolean,
      default: false,
    },
    aboveMilestonesCompleted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isPlanTab(): boolean {
      return this.activeTab === MILESTONE_DETAILS_TABS.PLAN;
    },
  },
});
