
































import Vue from "vue";

import { IMatchingCriteriaWeight } from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";

export default Vue.extend({
  name: "PxQuestionaryCriteria",

  props: {
    value: {
      type: Number,
      default: null,
    },
    weights: {
      type: Array as () => IMatchingCriteriaWeight[],
      required: true,
    },
  },

  data() {
    return {
      selectedWeight: 0,
    };
  },

  computed: {
    weightsCount(): number {
      return this.weights.length;
    },
    weightLabels(): Array<IMatchingCriteriaWeight> {
      // Get only odd indexed items
      return this.weights.filter((weight, index) => !(index % 2));
    },
    currentWeightId(): number | null {
      const selectedWeightIndex = this.selectedWeight - 1;

      return this.weights[selectedWeightIndex]
        ? this.weights[selectedWeightIndex].id
        : null;
    },
  },

  watch: {
    /**
     * Update selected value when the prop value changes.
     */
    value: {
      immediate: true,
      handler(newValue: number) {
        if (newValue) {
          this.selectedWeight = newValue;
        }
      },
    },

    currentWeightId(currentId) {
      if (!!currentId && typeof this.value !== "number") {
        this.$emit("input", this.currentWeightId);
      }
    },
  },

  methods: {
    formatTooltip(weightIndex: number): string | number {
      const selectedWeightIndex = weightIndex - 1;

      return this.weights[selectedWeightIndex]
        ? this.weights[selectedWeightIndex].name
        : weightIndex;
    },
    sliderChangeHandler() {
      this.$emit("input", this.currentWeightId);
      this.$emit("changed", this.currentWeightId);
    },
  },
});
