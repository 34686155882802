





























import Vue from "vue";

export default Vue.extend({
  name: "PxDatePicker",

  props: {
    value: {
      type: Date as new () => Date,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    tooltip: {
      type: String,
      default: null,
    },

    dateFormat: {
      type: String,
      default: "dd MMMM, yyyy",
    },
  },

  data() {
    return {
      innerValue: null as Date | null,
    };
  },

  watch: {
    value(newValue: Date | null) {
      if (this.innerValue !== newValue) {
        this.innerValue = newValue;
      }
    },

    innerValue(newValue: Date | null) {
      if (this.value !== newValue) {
        this.$emit("change", newValue);
        this.$emit("update:value", newValue);
      }
    },
  },

  created() {
    this.innerValue = this.value;
  },

  methods: {
    onDatePickerFocus() {
      (this.$refs.datePicker as HTMLElement).classList.add(
        "px-date-picker--open",
      );
    },

    onDatePickerBlur() {
      (this.$refs.datePicker as HTMLElement).classList.remove(
        "px-date-picker--open",
      );
    },
  },
});
