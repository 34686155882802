































































































import Vue from "vue";
import {
  generateRequiredValidator,
  generatePasswordValidator,
  generatePasswordCheck,
  allFormFieldsValid,
} from "@/services/errors/validator-generators";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";
import { passwordChangeProvider } from "@/modules/authentication/services/data/password-change/password-change.provider";
import { ElForm } from "element-ui/types/form";

export default Vue.extend({
  name: "AccountSettingsPassword",

  data() {
    return {
      passwordFormEl: null as null | ElForm,
      isSubmissionDisabled: true,
      isLoading: false,
      fields: {
        old_password: "",
        new_password1: "",
        new_password2: "",
      },
      rules: {
        old_password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.oldPassword",
        ),

        new_password1: generatePasswordValidator(
          this,
          "profile.accountSettingsModal.changePassword.fields.newPassword",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),

        new_password2: generatePasswordCheck(this, "fields.new_password1", {
          minimum: 8,
        }),
      },
    };
  },

  computed: {
    passwordFormFields(): IFormField[] | null {
      return this.passwordFormEl
        ? ((this.passwordFormEl as any).fields as IFormField[])
        : null;
    },

    newPasswordIsInvalid(): boolean {
      if (this.passwordFormFields) {
        const formFields = this.passwordFormFields;

        const passwordField = formFields.find(
          (field) => field.prop === "new_password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },

    formHasNoChanges(): boolean {
      return (
        this.fields.old_password === "" &&
        this.fields.new_password1 === "" &&
        this.fields.new_password2 === ""
      );
    },
  },

  watch: {
    newPasswordIsInvalid(newVal: boolean, oldVal: boolean) {
      if (newVal === oldVal) return;

      this.passwordFormEl?.validateField(
        "new_password2",
        this.updateSubmitState,
      );
    },
  },

  mounted() {
    (this as any).passwordFormEl = this.$refs.password;
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.$refs.password,
        this.rules,
      );
    },

    cancelPasswordChange() {
      this.passwordFormEl?.resetFields();
      this.updateSubmitState();
    },

    async updatePassword() {
      this.isLoading = true;

      try {
        await passwordChangeProvider.create(this.fields);

        this.$message({
          message: this.$t(
            "profile.accountSettingsModal.changePassword.successMessage",
          ) as string,
          type: "success",
          customClass: "is-full",
        });
      } catch (error) {
        this.$message({
          message: this.$t(
            "profile.accountSettingsModal.changePassword.errorMessage",
          ) as string,
          type: "error",
          customClass: "is-full",
        });
      }
      this.passwordFormEl?.resetFields();
      this.isLoading = false;
      this.updateSubmitState();
    },
  },
});
