



































































import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import { EPxButtonSize } from "@/components/px-button/px-button.types";

export default MatchingCardMixin.extend({
  name: "MatchingCardSimplified",

  components: {
    MatchingCardWrapper,
    MatchDetailsModal,
  },

  static: {
    interestButton: {
      size: EPxButtonSize.EXTRA_SMALL,
    },
  },
});
