import Vue from "vue";
import VueI18n from "vue-i18n";
import mergeWith from "lodash/mergeWith";
import { isArray } from "util";

import { getAppPartner } from "@/services/utils/utils";
import { DEFAULT_LOCALE } from "./locale-utils";
import {
  VUE_APP_PARTNER_ESO,
  VUE_APP_PARTNER_SMALL_FOUNDATION,
} from "@/constants/environments";

import en from "./translations/en.json";
import en_eso from "./translations/en.eso.json";
import en_smallfoundation from "./translations/en.smallfoundation.json";

// Install Vue I18n
Vue.use(VueI18n);

// While internationalization is not 100% ready we need to
// temporarily disable geolocalization language switch

// const initialLocale = getInitialLocale(navigator);

// Conditionally load a partner's specific terms:
const partner = getAppPartner();
let messages;

// When we are dealing with environments translations, and the translation is an array, the specific environment translations should prevail
function customizer(
  objValue: string | Array<string | object> | object,
  srcValue: string | Array<string | object> | object,
) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

switch (partner) {
  case VUE_APP_PARTNER_ESO: {
    messages = { en: mergeWith(en, en_eso, customizer) };
    break;
  }

  case VUE_APP_PARTNER_SMALL_FOUNDATION: {
    messages = {
      en: mergeWith(en, en_smallfoundation, customizer),
    };
    break;
  }

  default: {
    messages = { en };
  }
}

// Create VueI18n instance
export const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE, // fallback locale to be used if an item is not available in the defined locale
  messages,
});
