






























import Vue from "vue";
import { ICategoryDetail } from "@/services/data/category/category.interface";

const MAX_TEXT_LENGTH = 63;

export default Vue.extend({
  name: "LevelsDescription",

  props: {
    /**
     * List of details for the current category.
     */
    categoryDetails: {
      type: Array as () => ICategoryDetail[],
      required: true,
    },

    /**
     * Current active level.
     */
    currentLevel: {
      type: Number,
      required: true,
    },

    /**
     * The component needs the height to distribute the
     * elements;
     */
    height: {
      type: String,
      required: true,
    },

    rangeFirstLevel: {
      type: Number,
      default: null,
    },
  },

  computed: {
    sortedDetails(): ICategoryDetail[] {
      return [...this.categoryDetails].sort(
        (a, b) => b.level.value - a.level.value,
      );
    },
  },

  methods: {
    getItemClasses(item: ICategoryDetail) {
      return {
        "levels-description__item--visible": this.isItemVisible(item),
        "levels-description__item--checked": this.isItemChecked(item),
        "levels-description__item--truncated": this.itemHasLongText(item),
      };
    },

    isItemVisible(item: ICategoryDetail): boolean {
      return item.level.value <= this.currentLevel + 1;
    },

    isItemChecked(item: ICategoryDetail): boolean {
      return (
        item.level.value <= this.currentLevel &&
        item.level.value >= this.rangeFirstLevel
      );
    },

    itemHasLongText(item: ICategoryDetail): boolean {
      return this.getDiffBetweenItemTextAndMaxTextLength(item.achievements) > 0;
    },

    getItemTextContent(rawText: string) {
      const diffBetweenItemTextAndMaxTextLength =
        this.getDiffBetweenItemTextAndMaxTextLength(rawText);

      // If the total text length surpasses the maximum defined length for the
      // item text, cut the difference from the regular text.
      if (diffBetweenItemTextAndMaxTextLength > 0) {
        return `${rawText.slice(0, -diffBetweenItemTextAndMaxTextLength)}…`;
      }

      return rawText;
    },

    getDiffBetweenItemTextAndMaxTextLength(rawText: string) {
      // Create temporary HTML element to be able to extract the text from the
      // raw HTML string.
      const el = document.createElement("div");
      el.innerHTML = rawText;
      const parsedText = el.textContent || "";

      return parsedText.length - MAX_TEXT_LENGTH;
    },
  },
});
