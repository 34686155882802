import { render, staticRenderFns } from "./match-details-table-row.vue?vue&type=template&id=301c5d06&scoped=true&"
import script from "./match-details-table-row.vue?vue&type=script&lang=ts&"
export * from "./match-details-table-row.vue?vue&type=script&lang=ts&"
import style0 from "./match-details-table-row.vue?vue&type=style&index=0&id=301c5d06&lang=scss&scoped=true&"
import style1 from "./match-details-table-row.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301c5d06",
  null
  
)

export default component.exports