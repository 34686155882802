


















































































































































import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardCompactWrapper from "@/components/matching-card/matching-card-compact-wrapper.vue";
import MatchingCardOptions from "@/components/matching-card/matching-card-options.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import ListManagementConfirmationDialog from "@/modules/company-lists/components/list-management/list-management-confirmation-dialog.vue";

export default MatchingCardMixin.extend({
  name: "MatchingCardCompact",

  components: {
    MatchingCardCompactWrapper,
    MatchingCardOptions,
    ListManagementConfirmationDialog,
    MatchDetailsModal,
  },

  static: {
    interestButton: {
      size: EPxButtonSize.MEDIUM,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.MEDIUM,
      type: EPxButtonType.DARK_BLUE,
      variant: EPxButtonVariant.COMPACT,
    },

    publicInterestButton: {
      icon: "connect",
      size: EPxButtonSize.MEDIUM,
      type: EPxButtonType.GREEN,
      variant: EPxButtonVariant.COMPACT,
    },

    cancelButton: {
      type: EPxButtonType.LINK,
    },

    removeButton: {
      type: EPxButtonType.RED,
      size: EPxButtonSize.SMALL,
    },
  },

  computed: {
    // Due to size constraints, screens lower than 1440px must hide description
    showDescription(): boolean {
      return this.$screen.xlUp;
    },

    showMatchingElements(): boolean {
      return (
        this.showPercentage ||
        this.showConnectButton ||
        this.showListButton ||
        this.showPublicView
      );
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    isSelectModeActive(): boolean {
      return this.showListButton && this.selectionCompanyList.length > 0;
    },

    labelText(): string {
      if (!this.label && !this.disabled) {
        return "";
      }

      return (
        this.label || (this.$t("matching.matchingList.alreadyAdded") as string)
      );
    },

    isSelected(): boolean {
      return (
        this.isSelectModeActive &&
        !!this.selectionCompanyList.find(
          (itemUid: string) => itemUid === this.companyUid,
        )
      );
    },

    isFaded(): boolean {
      // If card is not selected, but select mode is active, show faded card
      return this.isSelectModeActive && !this.isSelected;
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel") as string,
      };
    },

    removeButtonProps(): any {
      return {
        ...this.$options.static.removeButton,
        label: this.$t("common.components.default.cta.yesRemove") as string,
      };
    },

    canShowSelectMode(): boolean {
      return !this.isDeletePanelVisible && this.showListButton;
    },

    canShowOptions(): boolean {
      return (
        !this.isDeletePanelVisible &&
        this.showOptions &&
        !(this.isSelected || this.isFaded)
      );
    },

    removalConfirmationCopy(): string {
      return this.$t("common.components.matchingCard.removalConfirmationText", {
        companyName: this.name,
      }) as string;
    },

    checkboxTooltipCopy(): string {
      return this.$t(
        "common.components.matchingCard.checkboxTooltipText",
      ) as string;
    },
  },

  watch: {
    isInnerSelected(newValue: boolean, oldValue: boolean) {
      // Prevent side-effects
      if (newValue === oldValue || newValue === this.isSelected) {
        return;
      }

      // Item was selected, dispatch according action
      if (newValue) {
        this.$store.dispatch(
          EListManagementActions.ADD_COMPANY,
          this.companyUid,
        );
        return;
      }

      this.$store.dispatch(
        EListManagementActions.REMOVE_COMPANY,
        this.companyUid,
      );
    },

    isSelected(newValue: boolean) {
      if (newValue !== this.isInnerSelected) {
        this.isInnerSelected = newValue;
      }
    },
  },

  created() {
    this.isInnerSelected = this.isSelected;
  },

  methods: {
    toogleOptionsOpen(value: boolean) {
      this.toggleOptions = value;
    },

    onCardClick() {
      // Block global component action when select mode is active
      if (this.isSelectModeActive || this.isDeletePanelVisible) {
        return;
      }

      this.$emit("click");
    },

    deleteClickHandler() {
      this.isDeletePanelVisible = true;
    },

    onRemoveConfirmationButtonClick() {
      this.$emit("remove", this.companyUid);
      this.isDeletePanelVisible = false;
    },
  },
});
