import Vue from "vue";
import Loading from "@/components/px-loading/px-loading.vue";

import { addClass, removeClass, getStyle } from "element-ui/src/utils/dom";
import { PopupManager } from "element-ui/src/utils/popup";
import afterLeave from "element-ui/src/utils/after-leave";

const Mask = Vue.extend(Loading);

const loadingDirective: any = {};
loadingDirective.install = (_Vue: any) => {
  if (_Vue.prototype.$isServer) return;

  const insertDom = (parent: HTMLElement, el: any, binding: any) => {
    if (
      !el.domVisible &&
      getStyle(el, "display") !== "none" &&
      getStyle(el, "visibility") !== "hidden"
    ) {
      Object.keys(el.maskStyle).forEach((property: string) => {
        el.mask.style[property] = el.maskStyle[property];
      });

      if (
        el.originalPosition !== "absolute" &&
        el.originalPosition !== "fixed"
      ) {
        addClass(parent, "px-loading-parent--relative");
      }
      if (binding.modifiers.fullscreen && binding.modifiers.lock) {
        addClass(parent, "px-loading-parent--hidden");
      }
      el.domVisible = true;

      parent.appendChild(el.mask);
      Vue.nextTick(() => {
        if (el.instance.hiding) {
          el.instance.$emit("after-leave");
        } else {
          el.instance.visible = true;
        }
      });
      el.domInserted = true;
    }
  };

  const toggleLoading = (el: any, binding: any) => {
    if (binding.value) {
      Vue.nextTick(() => {
        if (binding.modifiers.fullscreen) {
          el.originalPosition = getStyle(document.body, "position");
          el.originalOverflow = getStyle(document.body, "overflow");
          el.maskStyle.zIndex = PopupManager.nextZIndex();

          addClass(el.mask, "is-fullscreen");
          insertDom(document.body, el, binding);
        } else {
          removeClass(el.mask, "is-fullscreen");

          if (binding.modifiers.body) {
            el.originalPosition = getStyle(document.body, "position");

            ["top", "left"].forEach((property: string) => {
              const scroll = property === "top" ? "scrollTop" : "scrollLeft";
              if (document.documentElement === null) {
                return;
              }

              el.maskStyle[property] =
                el.getBoundingClientRect()[property] +
                document.body[scroll] +
                document.documentElement[scroll] -
                parseInt(getStyle(document.body, `margin-${property}`), 10) +
                "px";
            });
            ["height", "width"].forEach((property: string) => {
              el.maskStyle[property] =
                el.getBoundingClientRect()[property] + "px";
            });

            insertDom(document.body, el, binding);
          } else {
            el.originalPosition = getStyle(el, "position");
            insertDom(el, el, binding);
          }
        }
      });
    } else {
      afterLeave(
        el.instance,
        () => {
          el.domVisible = false;
          const target =
            binding.modifiers.fullscreen || binding.modifiers.body
              ? document.body
              : el;
          removeClass(target, "px-loading-parent--relative");
          removeClass(target, "px-loading-parent--hidden");
          el.instance.hiding = false;
        },
        300,
        true,
      );
      el.instance.visible = false;
      el.instance.hiding = true;
    }
  };

  _Vue.directive("loading", {
    bind(el: any, binding: any, vnode: any) {
      const textExr = el.getAttribute("element-loading-text");
      const spinnerExr = el.getAttribute("element-loading-spinner");
      const backgroundExr = el.getAttribute("element-loading-background");
      const customClassExr = el.getAttribute("element-loading-custom-class");
      const vm = vnode.context;
      const mask = new Mask({
        el: document.createElement("div"),
        data: {
          text: (vm && vm[textExr]) || textExr,
          spinner: (vm && vm[spinnerExr]) || spinnerExr,
          background: (vm && vm[backgroundExr]) || backgroundExr,
          customClass: (vm && vm[customClassExr]) || customClassExr,
          fullscreen: !!binding.modifiers.fullscreen,
        },
      });
      el.instance = mask;
      el.mask = mask.$el;
      el.maskStyle = {};

      binding.value && toggleLoading(el, binding);
    },

    update(el: any, binding: any) {
      el.instance.setText(el.getAttribute("element-loading-text"));
      if (binding.oldValue !== binding.value) {
        toggleLoading(el, binding);
      }
    },

    unbind(el: any, binding: any) {
      if (el.domInserted) {
        el.mask &&
          el.mask.parentNode &&
          el.mask.parentNode.removeChild(el.mask);
        toggleLoading(el, { value: false, modifiers: binding.modifiers });
      }
    },
  });
};

export default loadingDirective;
