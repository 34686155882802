

























































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import {
  EMatchingQuestionaryActions,
  IMatchingQuestionaryFetchPayload,
} from "@/modules/matching/services/store/matching-questionary/matching-questionary.types";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

import {
  IMatchingCriteria,
  IMatchingCriteriaWeight,
} from "@/modules/matching/services/data/matching-criteria/matching-criteria.interface";
import { matchingCriteriaProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria.provider";
import { matchingCriteriaWeightProvider } from "@/modules/matching/services/data/matching-criteria/matching-criteria-weight.provider";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default Vue.extend({
  name: "MatchingSidebar",

  data() {
    return {
      title: this.$t("matching.matchingSidebar.questionary.title"),
      menuIcon: "matching--white",
      questionary: {
        final: {
          title: this.$t("matching.matchingSidebar.final.title"),
          message: this.$t("matching.matchingSidebar.final.message"),
        },
      },
      criteria: [] as Array<IMatchingCriteria>,
      criteriaWeights: [] as Array<IMatchingCriteriaWeight>,
      isSidebarOpen: false,

      currentPage: 1,
      resultsPerPage: 5,
      url: AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL,
    };
  },

  computed: {
    showNotice(): boolean {
      return this.currentQuestion === null;
    },
    currentQuestion(): IMatchingQuestion {
      return this.$store.get("matchingQuestionary/question");
    },
    currentQuestionIndex(): number {
      return this.$store.get("matchingQuestionary/questionIndex");
    },
    totalQuestions(): number {
      return this.$store.get("matchingQuestionary/totalQuestions");
    },
    loadingQuestion(): boolean {
      // Showing loading at first page, the remaining are loaded in the background
      return (
        this.$store.get("matchingQuestionary/loading") && this.currentPage === 0
      );
    },
    userId(): number {
      return this.$store.state.auth.profileId;
    },
    userEmail(): string {
      return this.$store.state.auth.user.email;
    },
    showCriteria(): boolean {
      return this.$user.isSupporter();
    },
    currentCriteria(): IMatchingCriteria | null {
      return (
        this.criteria.find(
          (entry: IMatchingCriteria) =>
            this.currentQuestion &&
            entry.question_id === this.currentQuestion.id,
        ) || null
      );
    },
    arrowIcon(): string {
      return this.isSidebarOpen ? "arrow--down-grey" : "arrow--up-grey";
    },
    sidebarCopy(): LocaleMessages {
      return this.$t("matching.matchingSidebar") as LocaleMessages;
    },
  },

  watch: {
    currentQuestionIndex(index: number) {
      if (
        index + this.resultsPerPage >=
        this.currentPage * this.resultsPerPage
      ) {
        // Preload before running out of questions
        this.currentPage += 1;
        this.fetchQuestions();
      }
    },
  },

  created() {
    this.$root.$on("open-questions-sidebar", this.toggleSidebar);
    this.fetchQuestions();

    if (this.showCriteria) {
      this.fetchCriteriaWeight();
      this.fetchCriteria();
    }
  },

  methods: {
    fetchQuestions() {
      this.$store.dispatch(EMatchingQuestionaryActions.FETCH, {
        page: this.currentPage,
        per_page: this.resultsPerPage,
        exclude: ["free-response", "date"],
        only: "criteria",
      } as IMatchingQuestionaryFetchPayload);
    },

    async fetchCriteriaWeight() {
      this.criteriaWeights = await matchingCriteriaWeightProvider.list();
    },

    async fetchCriteria() {
      this.criteria = await matchingCriteriaProvider.list();
    },

    skipQuestion() {
      this.$store.dispatch(EMatchingQuestionaryActions.SKIP_QUESTION);
    },

    submitResponse(response: Array<number>) {
      let dataToSend = {
        user_profile: this.userId,
        question: this.currentQuestion.id,
      } as any;

      dataToSend =
        this.currentQuestion.question_type.type === "range" ||
        this.currentQuestion.question_type.type === "numeric"
          ? { ...dataToSend, value: response }
          : { ...dataToSend, answers: response };

      this.$store.dispatch(
        EMatchingQuestionaryActions.SUBMIT_RESPONSE,
        dataToSend,
      );
    },

    async submitCriteriaSelection(data: {
      criteria: number;
      values: Array<any> | any;
    }) {
      const valuesKey = ["range", "numeric"].includes(
        this.currentQuestion.question_type.type,
      )
        ? "desired"
        : "answers";
      const dataToSend = {
        criteria_weight: data.criteria,
        [valuesKey]: data.values,
        question: this.currentQuestion.id,
      };

      await this.$store.dispatch(
        EMatchingQuestionaryActions.SUBMIT_CRITERIA,
        dataToSend,
      );
    },

    onClickOpenFeedbackPage() {
      window.open(`https://info302232.typeform.com/to/IoMvns`);
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
});
