/**
 * Entry point for the services.
 */

import { i18n } from "@/services/i18n/i18n";

import "@/services/loading/index";

import router from "@/services/router/router";
import store from "@/services/store";

import "@/services/feature-manager";

export const servicesToRegister = {
  i18n,
  router,
  store,
};
