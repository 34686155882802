


































































import Vue from "vue";

import AccountSettingsNavigation, {
  EAccountSettingsNavigationTabs,
} from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import AccountSettingsPassword from "@/modules/profile/components/account-settings/account-settings-password.vue";
import AccountSettingsEmail from "@/modules/profile/components/account-settings/account-settings-email.vue";
import PricingSwitch from "@/modules/profile/components/account-settings/pricing-switch.vue";
import PricingCard, {
  IPricingCardFeature,
} from "@/modules/profile/components/account-settings/pricing-card.vue";

import {
  EPxButtonType,
  EPxButtonSize,
} from "@/components/px-button/px-button.types";
import {
  ESubscriptionPlanID,
  ISubscription,
} from "@/modules/authentication/services/data/subscription/subscription.interface";

import { LocaleMessages } from "vue-i18n";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { subscriptionProvider } from "@/modules/authentication/services/data/subscription/subscription.provider";
import {
  SUBSCRIPTIONS_DATA,
  PRO_SUBSCRIPTION_DATA,
} from "@/modules/profile/pricing-cards-data";

export default Vue.extend({
  name: "AccountSettingsView",

  components: {
    AccountSettingsNavigation,
    AccountSettingsPassword,
    AccountSettingsEmail,
    PricingSwitch,
    PricingCard,
  },

  data() {
    return {
      selectedTab: this.$route.query.tab || EAccountSettingsNavigationTabs.INFO,
      yearly: true,
      plans: {
        FREE: ESubscriptionPlanID.FREE,
        PRO_MONTHLY: ESubscriptionPlanID.PRO_MONTHLY,
        PRO_YEARLY: ESubscriptionPlanID.PRO_YEARLY,
      },
      subscription: undefined as ISubscription | undefined,
    };
  },

  computed: {
    subscriptionCta(): any {
      return {
        label: this.headerCopy.cta,
        type: EPxButtonType.GREEN,
        size: EPxButtonSize.LARGE,
      };
    },

    freePlanCardProps(): any {
      return {
        ...SUBSCRIPTIONS_DATA.free,
        features: SUBSCRIPTIONS_DATA.free.features.map(
          (feature: IPricingCardFeature) => ({
            ...feature,
            icon: "success--blue-dark",
            iconSize: 20,
          }),
        ),
        ctaLabel: this.$t(
          this.subscription?.planId === ESubscriptionPlanID.FREE
            ? "profile.accountSettingsPage.subscriptionPanel.currentPlanCta"
            : "profile.accountSettingsPage.subscriptionPanel.pricingCards.free.cta",
        ) as string,
        disabled:
          this.subscription?.planId === ESubscriptionPlanID.FREE ||
          this.isOnLegacyPlan,
      };
    },

    proPlanCardProps(): any {
      return {
        ...PRO_SUBSCRIPTION_DATA,
        price: this.yearly ? 100 : 120,
        priceNote: this.$t(
          `profile.accountSettingsPage.subscriptionPanel.pricingCards.pro.${
            this.yearly ? "priceNoteYearly" : "priceNoteMonthly"
          }`,
        ),
        ctaLabel: this.$t(
          this.subscription?.planId === ESubscriptionPlanID.PRO_MONTHLY ||
            this.subscription?.planId === ESubscriptionPlanID.PRO_YEARLY
            ? "profile.accountSettingsPage.subscriptionPanel.currentPlanCta"
            : "profile.accountSettingsPage.subscriptionPanel.pricingCards.pro.cta",
        ) as string,
        disabled: this.subscription?.planId !== ESubscriptionPlanID.FREE,
      };
    },

    isOnLegacyPlan(): boolean {
      return (
        this.subscription?.planId !== this.plans.FREE &&
        this.subscription?.planId !== this.plans.PRO_MONTHLY &&
        this.subscription?.planId !== this.plans.PRO_YEARLY
      );
    },

    headerCopy(): LocaleMessages {
      return this.isOnLegacyPlan
        ? (this.$t("profile.accountSettingsPage.legacyPlan") as LocaleMessages)
        : (this.$t(
            "profile.accountSettingsPage.subscriptionPanel",
          ) as LocaleMessages);
    },

    showAccountInfoPanel(): boolean {
      return this.selectedTab === EAccountSettingsNavigationTabs.INFO;
    },
  },

  watch: {
    selectedTab: {
      handler(newTab: string, oldTab: string) {
        if (newTab === oldTab) return;

        this.$router.push({
          query: {
            tab: newTab,
          },
        });
      },
    },
  },

  mounted() {
    Chargebee.getInstance().setPortalSession(
      async () => await subscriptionProvider.getPortalSession(),
    );

    this.fetchSubscription();
  },

  methods: {
    async fetchSubscription() {
      await this.$store.dispatch(EAuthActions.FETCH_SUBSCRIPTION);

      this.subscription = this.$store.get("auth/subscription");
    },

    contactSales() {
      const subject = this.$t(
        "profile.accountSettingsPage.legacyPlan.emailSubject",
      ) as string;

      // Open the email client
      window.location.href = `mailto:sales@abaca.app?subject=${encodeURIComponent(
        subject,
      )}`;
    },

    ctaAction() {
      this.isOnLegacyPlan ? this.contactSales() : this.manageSubscription();
    },

    manageSubscription() {
      Chargebee.getInstance().createChargebeePortal().open({
        subscriptionChanged: this.onSubscriptionChange,
      });
    },

    changeSubscription(plan: ESubscriptionPlanID) {
      Chargebee.getInstance().openCheckout({
        hostedPage: async () => await subscriptionProvider.getCheckout(plan),
        success: this.onSubscriptionChange,
      });
    },

    async onSubscriptionChange() {
      const previousPlan = this.subscription?.planId;

      await this.fetchSubscription();

      // If we are changing between pro plans, we don't want to show the success message
      if (
        previousPlan !== this.plans.FREE &&
        this.subscription?.planId !== this.plans.FREE
      ) {
        return;
      }

      const message =
        this.subscription?.planId === this.plans.FREE
          ? this.$t(
              "profile.accountSettingsPage.subscriptionPanel.successMessage.free",
            )
          : this.$t(
              "profile.accountSettingsPage.subscriptionPanel.successMessage.pro",
            );

      this.$message({
        message: message as string,
        type: "success",
        customClass: "is-navbar account-settings-success-message",
        duration: 10000,
      });
    },
  },
});
