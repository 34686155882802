




























































































import Vue from "vue";

import OfferingTypesSelect from "@/modules/profile/components/offering-types-select/offering-types-select.vue";
import { offeringProvider } from "@/services/data/offering/offering.provider";

import {
  IOffering,
  IOfferingCategory,
  IOfferingType,
} from "@/services/data/offering/offering.interface";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";

export default Vue.extend({
  name: "OfferingsFormItem",

  components: {
    OfferingTypesSelect,
  },

  props: {
    offering: {
      type: Object as () => IOffering,
      required: true,
    },

    category: {
      type: Object as () => IOfferingCategory,
      required: true,
    },
  },

  data() {
    return {
      offer: null as IOffering | null,
      originalOffer: null as IOffering | null,
      offerCategoryTypes: [] as Array<IOfferingType> | null,
      originalOfferCategoryTypes: [] as Array<IOfferingType> | null,
      supporter: null as ISupporter | null,
      isEditing: false,
      isLoading: false,
      isNewOffer: false,
    };
  },

  computed: {
    isExistingOffer(): boolean {
      return !!this.offering.id;
    },

    onCreationMode(): boolean {
      return this.isNewOffer && this.isEditing;
    },

    hasOfferTypesChanged(): boolean | undefined {
      if (
        this.offerCategoryTypes?.length !==
        this.originalOfferCategoryTypes?.length
      ) {
        return true;
      }

      return !this.originalOfferCategoryTypes?.every((element) =>
        this.offerCategoryTypes?.some(
          (offer: IOfferingType) => offer.id === element.id,
        ),
      );
    },

    offerChanged(): boolean {
      return (
        !isEqual(this.offer, this.originalOffer) || !!this.hasOfferTypesChanged
      );
    },
  },

  watch: {
    offering: {
      immediate: true,
      deep: true,
      handler(newOffering: IOffering) {
        if (!newOffering) return;

        this.prefillOfferData(newOffering);
      },
    },

    offerChanged: {
      handler(changed: boolean) {
        this.$emit("offer-changed", changed);
      },
    },
  },

  beforeDestroy() {
    this.supporter = null;
    this.offer = null;
    this.originalOffer = null;
    this.offerCategoryTypes = null;
    this.originalOfferCategoryTypes = null;
  },

  beforeMount() {
    this.supporter = this.$store.get("profile/supporter/data");

    this.isEditing = !this.isExistingOffer;
    this.isNewOffer = !this.isExistingOffer;
  },

  methods: {
    prefillOfferData(newOffering: IOffering) {
      this.offer = cloneDeep(newOffering);
      this.originalOffer = cloneDeep(newOffering);
      this.offerCategoryTypes =
        cloneDeep(newOffering.types) ||
        cloneDeep(newOffering.category.category_types) ||
        [];
      this.originalOfferCategoryTypes =
        cloneDeep(newOffering.types) ||
        cloneDeep(newOffering.category.category_types) ||
        [];
    },

    async setActiveTypes(types: Array<IOfferingType>) {
      await this.$nextTick();
      const typesSelect = this.$refs.typesSelect as any;

      if (typesSelect) {
        typesSelect.setActiveOfferingTypes(cloneDeep(types));
      }
    },

    changedTypesHandler() {
      this.isEditing = true;
      this.$emit("offer-changed", true);
    },

    resetOffer() {
      this.offer = cloneDeep(this.originalOffer);
      this.offerCategoryTypes = cloneDeep(this.originalOfferCategoryTypes);

      // Set the active types
      if (this.offerCategoryTypes) {
        this.setActiveTypes(this.offerCategoryTypes);
      }
    },

    onClickCancel() {
      if (this.isNewOffer) {
        this.$emit("remove-offer");
      } else {
        this.resetOffer();
        this.isEditing = false;
      }
    },

    setOfferNewTypes() {
      if (!this.offer?.types && !this.offer?.category.category_types) return;

      if (this.offer?.types) {
        this.offer.types = this.offerCategoryTypes
          ? this.offerCategoryTypes
          : this.offer.types;
        return;
      }

      if (this.offer?.category.category_types) {
        this.offer.category.category_types = this.offerCategoryTypes
          ? this.offerCategoryTypes
          : this.offer.category.category_types;
      }
    },

    async onClickSave() {
      this.isLoading = true;

      this.$emit("submission-errors", false);

      // Since we are initial decopling the offer types from the offer data we need to update the offer data with the new selected types
      this.setOfferNewTypes();

      try {
        const offerCategoryTypes = this.offerCategoryTypes?.map(
          (type: IOfferingType) => type.id,
        );

        if (this.isNewOffer) {
          await offeringProvider.create({
            supporter: this.supporter?.id,
            description: this.offer?.description,
            category: this.offer?.category.id,
            types: offerCategoryTypes,
          });
        } else {
          await offeringProvider.patch(this.supporter?.id, {
            description: this.offer?.description,
            category: this.offer?.category.id,
            types: offerCategoryTypes,
          });
        }

        this.$emit("update:offering", this.offer);

        // Informs the parent that was an eddition. With this the `formChanges`
        // values must be reseted.
        this.$emit("offer-changed", "edit");
        this.isEditing = false;
        this.isNewOffer = false;
      } catch {
        this.$emit("submission-errors", true);
      } finally {
        this.isLoading = false;
      }
    },

    async onClickEdit() {
      this.isEditing = true;

      if (this.offerCategoryTypes) {
        await this.setActiveTypes(this.offerCategoryTypes);
      }
    },

    onClickRemove() {
      this.$emit("remove-offer");
      this.$emit("offer-changed", "remove");
    },
  },
});
