

















































import Vue from "vue";

import PxSteps from "@/components/px-steps/px-steps.vue";
import SignUpStepCompany from "@/modules/authentication/components/signup-step-company/signup-step-company.vue";
import SignUpStepAccount from "@/modules/authentication/components/signup-step-account/signup-step-account.vue";
import LevelBar from "@/modules/self-assessment/components/level-bar/level-bar.vue";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { ErrorsManager } from "@/services/errors-manager";
import { signUpProvider } from "@/modules/authentication/services/data/signup/signup.provider";
import { ISignup } from "@/modules/authentication/services/data/signup/signup.interface";
import { AUTHENTICATION_META_REGISTRATION_EMAIL } from "@/modules/authentication/constants";
import {
  ROUTE_AUTHENTICATION_SIGNUP_SUCCESS,
  ROUTE_AUTHENTICATION_LOGIN,
} from "@/modules/authentication/services/router/routes-names";
import { ErrorsProviderException } from "@/services/data/exceptions/errors-provider.exception";
import { ILevel } from "@/services/data/level/level.interface";
import {
  IViralLevelState,
  EViralLevelActions,
} from "@/services/store/viral-level/viral-level-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { signUpWithAssessmentProvider } from "@/modules/authentication/services/data/signup-with-assessment/signup-with-assessment.provider";
import { defaultAffiliateId } from "@/services/configs";
import { gaTrackEvent } from "@/services/utils/ga.utils";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";

export default Vue.extend({
  name: "AuthBaseSignup",

  components: {
    PxSteps,
    SignUpStepCompany,
    SignUpStepAccount,
    LevelBar,
  },

  data() {
    return {
      currentStep: 0,
      hasServerError: false,
      loading: false,

      errors: new ErrorsManager({
        email: {
          unique: this.$t("authentication.signup.form.errors.email.unique"),
        },
      }),

      company: {
        name: "",
        location: {
          formatted_address: "",
        },
        sectors: [] as Array<number>,
      },

      formData: {
        email: "",
        password1: "",
        password2: "",
      },

      items: [
        {
          label: this.$t("authentication.signup.steps.company"),
          value: "company",
        },
        {
          label: this.$t("authentication.signup.steps.account"),
          value: "user",
        },
      ],
    };
  },

  computed: {
    /**
     * Informs if the user comes from the assessment.
     */
    isFromAssessment(): boolean {
      return !!this.$route.query.fromAssessment;
    },

    assessmentLevel(): ILevel | undefined {
      return this.$store.state.viralLevel.finalLevel;
    },

    /**
     * Get affiliate id
     */
    affiliateId(): number {
      return this.$store.get("affiliates/affiliate@id") || defaultAffiliateId();
    },
  },

  async created() {
    if (this.isFromAssessment) {
      await this.$store.dispatch(ELevelActions.FETCH);
    }
  },

  methods: {
    nextStep() {
      this.currentStep++;
    },

    previousStep() {
      this.currentStep--;
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    /**
     * Create the user account with an assessment.
     *
     * After creating the account reset the viral level data.
     */
    async createUserAccountWithAssessment(genericData: ISignup) {
      const viralStore = this.$store.state.viralLevel as IViralLevelState;
      const levels = viralStore.levels;
      const affiliate = this.affiliateId;
      const dataToSend = {
        ...genericData,
        affiliate,
        levels,
        group: ENTREPRENEUR_USER_GROUP_ID,
      } as any;

      await signUpWithAssessmentProvider.create(dataToSend);

      // clean up assessment data
      this.$store.dispatch(EViralLevelActions.RESET);
    },

    /**
     * Submit the form data to the API.
     */
    async submitForm() {
      const dataToSend = {
        ...this.formData,
        company: this.company,
        affiliate: this.affiliateId,
      } as ISignup;

      this.errors.clear();
      this.loading = true;

      try {
        if (this.isFromAssessment) {
          await this.createUserAccountWithAssessment(dataToSend);
        } else {
          await signUpProvider.create(dataToSend);
        }
      } catch (error) {
        if (error instanceof ErrorsProviderException) {
          this.errors.record(error.response.data.errors);
        }

        return;
      } finally {
        this.loading = false;
      }

      // Store user email in the metadata module in order
      // to be used on the success screen
      this.$store.dispatch(EMetaActions.SET, {
        key: AUTHENTICATION_META_REGISTRATION_EMAIL,
        value: dataToSend.email,
      });

      this.$router.push({
        name: ROUTE_AUTHENTICATION_SIGNUP_SUCCESS,
      });

      // Informs GA the user has registered
      gaTrackEvent("registered", "Self-Assessment");
    },
  },
});
