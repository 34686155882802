








































import Vue from "vue";

import ChartLocked, {
  IChartLockedContent,
} from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";
import DoughnutChart from "@/components/charts/doughnut-chart/doughnut-chart.vue";
import ChartsTable from "@/modules/charts-dashboard/components/common/charts-table/charts-table.vue";
import ChartsLockedModal from "@/modules/charts-dashboard/components/common/charts-locked-modal/charts-locked-modal.vue";

import { IListReportsDemographicData } from "@/modules/charts-dashboard/services/data/lists-reports-data/lists-reports-data.interface";
import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";
import { CHARTS_BACKGROUND_COLORS } from "@/modules/charts-dashboard/components/charts-background-colors";

export default Vue.extend({
  name: "ListReportsChart",

  components: {
    DoughnutChart,
    ChartsTable,
    ChartLocked,
    ChartsLockedModal,
  },

  props: {
    data: {
      type: Object as () => IListReportsDemographicData,
      required: true,
    },

    chartTitle: {
      type: String,
      default: "",
    },

    chartDescription: {
      type: String,
      default: "",
    },

    theme: {
      type: String,
      required: true,
    },

    chartWidth: {
      type: Number,
      default: 240,
    },

    chartHeight: {
      type: Number,
      default: 240,
    },

    showLockedState: {
      type: Boolean,
      default: false,
    },

    lockedState: {
      type: Object as () => IChartLockedContent,
      default: () => ({
        title: "",
        description: "",
        note: "",
        image: "",
      }),
    },

    note: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      backgroundColors: CHARTS_BACKGROUND_COLORS,
      privacyModalVisible: false,
    };
  },

  computed: {
    allChartLabels(): Array<string> {
      return this.data.responses.map((response: any) => response.value);
    },

    allLabelsValues(): Array<number> {
      return this.allChartLabels.map((label: string) => {
        const response = this.data.responses.find(
          (response: any) => response.value === label,
        );

        return response ? response.count : 0;
      });
    },

    allLabelsPercentage(): Array<number> {
      return this.allChartLabels.map((label: string) => {
        const response = this.data.responses.find(
          (response: any) => response.value === label,
        );

        return response ? Math.round(response.percentage * 1000) / 10 : 0;
      });
    },

    doughnutChartData(): any {
      return {
        labels: this.allChartLabels,
        datasets: [
          {
            data: this.allLabelsValues,
            percentage: this.allLabelsPercentage,
            backgroundColor: this.backgroundColors,
          },
        ],
      };
    },
  },

  methods: {
    showPrivacyModal() {
      this.privacyModalVisible = true;
    },

    goToInitiativePage() {
      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
