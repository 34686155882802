











import Vue from "vue";

export default Vue.extend({
  name: "PxEmptyMessage",

  props: {
    icon: {
      type: String,
      default: null,
    },

    iconSize: {
      type: [Number, String],
      default: 36,
    },

    title: {
      type: String,
      default: null,
    },

    subtitle: {
      type: String,
      default: null,
    },

    titleSize: {
      type: Number,
      default: 15,
    },

    subtitleSize: {
      type: Number,
      default: 14,
    },

    subtitleStyle: {
      type: String,
      default: "italic",
    },
  },

  computed: {
    titleStyles(): { [key: string]: string | number } {
      return {
        fontSize: `${this.titleSize}px`,
      };
    },

    subtitleStyles(): { [key: string]: string | number } {
      return {
        fontSize: `${this.subtitleSize}px`,
        fontStyle: this.subtitleStyle,
      };
    },
  },
});
