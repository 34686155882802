








































import Vue from "vue";

import PxStickyBar from "@/components/px-sticky-bar/px-sticky-bar.vue";

import { ROUTE_AUTHENTICATION_SIGNUP } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "VisitorJoinBar",

  components: {
    PxStickyBar,
  },

  methods: {
    onClickSignUp() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_SIGNUP });
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },
  },
});
