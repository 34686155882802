import {
  EGenericAction,
  EGenericGetter,
  EGenericMutation,
  IGenericAction,
  IGenericGetter,
  IGenericMutation,
  IGenericState,
  IGenericStateConstructor,
} from "@/services/store/generic/generic-state.interface";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/services/store/root-state";
import { getGenericInitialState } from "@/services/store/generic/generic-state";
import { getGenericStateActions } from "@/services/store/generic/generic-state.actions";
import { getGenericStateMutations } from "@/services/store/generic/generic-state.mutations";
import { getGenericStateGetters } from "@/services/store/generic/generic-state.getters";

export abstract class GenericState<S extends IGenericState<T>, T>
  implements Module<S, RootState>
{
  // Methods enumerators
  static Action: IGenericAction = EGenericAction;
  static Getter: IGenericGetter = EGenericGetter;
  static Mutation: IGenericMutation = EGenericMutation;

  // Set default value for namespaced
  namespaced = true;

  // Declare store variables
  state: S;
  actions: ActionTree<S, RootState>;
  getters: GetterTree<S, RootState>;
  mutations: MutationTree<S>;

  /**
   * State module constructor.
   * @param provider
   * @param namespace
   * @param props
   */
  constructor({ provider, props = {} }: IGenericStateConstructor<S, T>) {
    this.namespaced = props.namespaced || this.namespaced;

    // Set state value
    this.state = (props.state as S) || getGenericInitialState<T>();

    // Set actions
    this.actions = props.actions || getGenericStateActions<T>(provider);

    // Set mutations
    this.mutations = props.mutations || getGenericStateMutations<T>();

    // Set getters
    this.getters = props.getters || getGenericStateGetters<T>();
  }

  /**
   * Adds namespace mapping to Enumerable object.
   *
   * @param enumerator
   * @param namespace
   */
  static mapNamespaceEnum<E>(enumerator: E, namespace: string): E {
    // inner copy just to make the typechecker understand that `E` can be delead as an object with keys.
    const innerEnum = enumerator as unknown as { [key: string]: string };

    return Object.keys(enumerator).reduce(
      (a, key: string) => ({ ...a, [key]: `${namespace}/${innerEnum[key]}` }),
      {},
    ) as E;
  }
}
