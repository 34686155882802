






























import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AssessmentChanges from "@/modules/common/components/level-update-modal/assessment-changes.vue";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "LevelUpdateCard",

  components: { AssessmentChanges },

  props: {
    type: {
      type: String,
      default: "grey",
    },
    typeOfUpdate: {
      type: String,
      default: "same-level",
    },
    levelDescription: {
      type: String,
      default: "",
    },
    level: {
      type: Number,
      default: 1,
    },
    prevAssessment: {
      type: Array as () => IAssessmentLevel[] | null,
      required: true,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    onlyPrevAssessment: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title(): TranslateResult {
      return this.type === "grey"
        ? this.$t("profile.levelCard.titleCurrent")
        : this.$t("profile.levelCard.titleNew");
    },
  },
});
