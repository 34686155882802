

































































































































import ActionModal from "@/modules/supporters/components/action-modal/action-modal.vue";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import NetworksList from "@/modules/profile/components/networks-list/networks-list.vue";
import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";

import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";
import { TranslateResult } from "vue-i18n";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { ILocation } from "@/services/data/location/location.interface";
import { INetwork } from "@/services/data/network/network.interface";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";

export default SupportersFlowMixin.extend({
  name: "AccountInfo",

  components: {
    ActionModal,
    FormPresentationLayout,
    NetworksList,
  },

  data() {
    return {
      selectedSupporterTypes: [] as Array<number>,
      hasOtherSupporterType: false,
      otherType: "",
      showNetworkModal: false,

      fields: {
        company: {
          name: "",
          location: {} as Partial<ILocation>,
          networks: [] as Array<number>,
        },
        supporter: {
          types: [] as Array<number>,
        },

        meta: {
          hasShownNetworkModal: false,
        },
      } as Partial<ISupporterFlow>,
    };
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.accountInfo.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.accountInfo");
    },

    availableSupporterTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },

    affiliateNetwork(): Array<INetwork> {
      const affiliate = this.$store.getters[AffiliateState.Getter.VALUE];
      const networkList = [] as INetwork[];

      // Check if affiliate is set and if it contains at least one network
      if (affiliate && affiliate.networks?.length > 0) {
        // Select first network
        networkList.push(affiliate.networks[0]);
      }

      return networkList;
    },

    networkModalTitle(): string {
      const networkName = this.affiliateNetwork[0]?.name || "";

      return this.$t("supporters.component.networkModal.title", {
        network: networkName,
      }) as string;
    },
  },

  watch: {
    selectedSupporterTypes(currentSelectedTypes: Array<number>) {
      // Filter out invalid values
      this.fields.supporter.types = currentSelectedTypes.filter(
        (type: number) => !!type,
      );
    },

    hasOtherSupporterType(state: boolean) {
      if (!state) {
        delete this.fields.supporter.other_type;
      }
    },

    otherType(value: string) {
      if (this.hasOtherSupporterType) {
        this.fields.supporter.other_type = value;
      }
    },

    affiliateNetwork: {
      immediate: true,
      handler(values: Array<INetwork>) {
        // Avoid showing this if already shown
        const hasShownNetworkModal =
          this.flowData.meta?.hasShownNetworkModal || false;

        this.showNetworkModal = values.length > 0 && !hasShownNetworkModal;
      },
    },
  },

  mounted() {
    if (this.flowData) {
      this.fields.company = {
        ...this.fields.company,
        ...this.flowData.company,
      };

      this.fields.supporter = {
        ...this.fields.supporter,
        ...this.flowData.supporter,
      };

      this.fields.meta.hasShownNetworkModal =
        this.flowData.meta?.hasShownNetworkModal ||
        this.fields.meta.hasShownNetworkModal;
    }

    this.prefillInnerFields();
  },

  methods: {
    /**
     * Allows to add custom other supporter types
     */
    otherTypeChangeHandler(checked: boolean) {
      this.hasOtherSupporterType = checked;
    },

    prefillInnerFields() {
      // Prefill supporter types
      if (this.fields.supporter.types?.length) {
        this.selectedSupporterTypes = this.fields.supporter.types;
        this.currentForm.validate();
      }

      if (this.fields.supporter.other_type) {
        this.hasOtherSupporterType = true;
        this.otherType = this.fields.supporter.other_type || "";
        this.currentForm.validate();
      }

      if (
        !!this.fields.company.name ||
        !!this.fields.location?.formatted_address
      ) {
        this.currentForm.validate();
      }
    },

    /**
     * Sends network data as a batch submission.
     * @param submitNetwork
     */
    async submitNetworkModal(submitNetwork: boolean) {
      this.showNetworkModal = false;
      this.fields.meta.hasShownNetworkModal = true;

      if (submitNetwork) {
        this.fields.company.networks = [this.affiliateNetwork[0].id];
      }
    },
  },
});
