















import Vue from "vue";

const ICON_SIZE_XS = 71;
const ICON_SIZE_ABOVE_XS = 78;

export default Vue.extend({
  name: "FeatureCard",
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconSize(): any {
      return this.$screen.xsOnly ? ICON_SIZE_XS : ICON_SIZE_ABOVE_XS;
    },
  },
});
