import { Route } from "vue-router";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { featureManager } from "@/services/feature-manager";
import { userManager } from "@/modules/authentication/user-manager";
import { defaultScreenService } from "@/modules/common/plugins/screen.plugin";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

/**
 * Guard to deny access to company lists.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export const preventCompanyListsAccessGuard = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  const isCompanyListsEnable = featureManager.isEnabled("companyLists");
  const isCompanyListsForEntrepreneursEnable = featureManager.isEnabled(
    "companyListsForEntrepreneurs",
  );

  if (
    !isCompanyListsEnable ||
    (!isCompanyListsForEntrepreneursEnable && userManager.isEntrepreneur())
  ) {
    next({
      name: ERROR_ROUTE,
    });
  } else if (defaultScreenService.isDown("sm")) {
    next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.MOBILE,
      },
    });
  } else {
    next();
  }
};
