
































import Vue from "vue";

import ChartsLockedModal from "@/modules/charts-dashboard/components/common/charts-locked-modal/charts-locked-modal.vue";
import ChartLocked from "@/modules/charts-dashboard/components/common/chart-locked/chart-locked.vue";

import { ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS } from "@/modules/team-management/services/router/routes-names";

export default Vue.extend({
  name: "ProcessReportsLocked",

  components: {
    ChartsLockedModal,
    ChartLocked,
  },

  props: {
    allStepsLocked: {
      type: Boolean,
      required: true,
    },

    copy: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      privacyModalVisible: false,
    };
  },

  methods: {
    goToInitiativePage() {
      return this.$router.push({
        name: ROUTE_TEAM_MANAGEMENT_INITIATIVE_DETAILS,
      });
    },
  },
});
