
















































import Vue from "vue";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { HOME } from "@/services/router/router-names";
import SupportModal from "@/modules/common/components/support-modal/support-modal.vue";
import { TranslateResult } from "vue-i18n";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

export default Vue.extend({
  name: "ErrorPage",

  components: { SupportModal },

  props: {
    errorCode: {
      type: String,
      default: ERROR_TYPES.NOT_FOUND,
    },

    showSupportModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      supportModalVisibility: false,
    };
  },

  computed: {
    actionText(): TranslateResult {
      return this.errorCode === "mobile"
        ? this.$t(`common.errors.actions.returnProfile.text`)
        : this.$t(`common.errors.actions.returnHomepage.text`);
    },

    actionLink(): TranslateResult {
      return this.errorCode === "mobile"
        ? this.$t(`common.errors.actions.returnProfile.action`)
        : this.$t(`common.errors.actions.returnHomepage.action`);
    },

    showDefaultErrorMessage(): boolean {
      return !(
        this.errorCode === ERROR_TYPES.FORBIDDEN &&
        this.supporterForbiddenVariation
      );
    },

    supporterForbiddenVariation(): boolean {
      return this.$route.params.supporterForbiddenVariation === "true";
    },
  },

  methods: {
    returnHomepage() {
      this.$user.isLogged()
        ? this.$router.push({ name: HOME })
        : this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },

    showModal() {
      this.supportModalVisibility = true;
    },
  },
});
