













import Vue from "vue";

import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { featureManager } from "@/services/feature-manager";
import { userManager } from "@/modules/authentication/user-manager";

export default Vue.extend({
  computed: {
    isAuthRoute(): boolean {
      return (
        "auth" in this.$router.currentRoute.meta &&
        !!this.$router.currentRoute.meta.auth
      );
    },
    isLoadingAuthRoute(): boolean {
      return this.$store.state.auth.loading && this.isAuthRoute;
    },
    isLoading(): boolean {
      return this.isLoadingAuthRoute;
    },
  },
  mounted() {
    // TODO: Check if dispatching the Check Session is required
    if (featureManager.isEnabled("authentication") && userManager.isLogged()) {
      this.$store.dispatch(EAuthActions.CHECK_SESSION);

      if (userManager.isEntrepreneur()) return;

      this.$store.dispatch(EAuthActions.FETCH_SUBSCRIPTION);
    }
  },
});
