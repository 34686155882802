

























import Vue from "vue";

export default Vue.extend({
  name: "PxInputPassword",

  props: {
    /**
     * Input password value.
     */
    value: {
      type: String,
      required: true,
    },

    /**
     * Input label.
     */
    label: {
      type: String,
      default: "",
    },

    /**
     * Placeholder text.
     */
    placeholder: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * We should only show success state on signups with two password fields (password and confirmation)
     */
    showSuccessState: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      innerValue: "",
      isTextPasswordVisible: false,
    };
  },

  computed: {
    fieldType(): string {
      return this.isTextPasswordVisible ? "text" : "password";
    },

    showCopy(): string {
      const visibleCopy = this.isTextPasswordVisible
        ? "common.components.default.input.hide"
        : "common.components.default.input.show";
      return this.$t(visibleCopy) as string;
    },

    hasInnerValue(): boolean {
      return !!this.innerValue;
    },
  },

  watch: {
    innerValue: {
      handler(newInnerValue: string) {
        this.$emit("input", newInnerValue);

        // If no value, hide text password
        if (!this.hasInnerValue) {
          this.isTextPasswordVisible = false;
        }
      },
    },

    value: {
      immediate: true,
      handler(newValue: string) {
        if (newValue !== this.innerValue) {
          this.innerValue = newValue;
        }
      },
    },
  },

  methods: {
    /**
     * Toggles input field password visibility.
     */
    onPasswordShowClick() {
      if (this.hasInnerValue) {
        this.isTextPasswordVisible = !this.isTextPasswordVisible;
      }
    },
  },
});
