import Vue from "vue";

import { featureManager } from "@/services/feature-manager";

/**
 * This directive removes a DOM node if the feature
 * isn't enabled.
 */
Vue.directive("feature", {
  inserted(_, binding, vnode) {
    const feature = binding.value;

    if (!featureManager.isEnabled(feature)) {
      if (!vnode.elm || !vnode.elm.parentElement) {
        return;
      }

      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});
