


























































































import Vue from "vue";
import moment from "moment";
import PxGrid from "@/components/px-grid/px-grid.vue";
import { ICategory } from "@/services/data/category/category.interface";
import {
  ICategoryState,
  ECategoryActions,
} from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";
import {
  ROUTE_MILESTONE_PLANNER_OVERVIEW,
  ROUTE_MILESTONE_PLANNER_FULLGRID,
} from "@/modules/milestone-planner/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

export default Vue.extend({
  name: "AssessmentPanelGrid",

  components: {
    PxGrid,
  },

  props: {
    latestAssessment: {
      type: Object as () => IAssessment,
      required: true,
    },
    levels: {
      type: Array as () => ILevel[],
      required: true,
    },
    levelValue: {
      type: Number,
      required: true,
    },
  },

  static: {
    showFullGridButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },

    quickUpdateButton: {
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    results(): Array<IAssessmentLevel> {
      return this.latestAssessment.data;
    },

    /**
     * Get the categories in a ordered way.
     *
     * This will sort the categories by their order, from the
     * lowest to the higher order.
     */
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    /**
     * Return the number of levels.
     */
    numOfLevels(): number {
      return this.levels.length;
    },

    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    companyName(): string {
      return this.company ? this.company.name : "";
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    hasUserAccess(): boolean {
      return this.$user.isLogged() || this.isSpecialGuest;
    },

    quickUpdateButtonProps(): any {
      return {
        ...this.$options.static.quickUpdateButton,
        label: this.$t("milestonePlanner.quickUpdate") as string,
      };
    },

    latestAssessmentDate(): string {
      return moment(this.latestAssessment.created_at).format(
        "DD MMMM YYYY, hh:mmA",
      );
    },

    isMilestonePlannerModuleActive(): boolean {
      return activeModules().includes("milestone-planner");
    },
  },

  created() {
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  },

  methods: {
    /**
     * Get the level for a given category.
     */
    levelForCategory(category: ICategory): number {
      const levelSection = this.results.find(
        (result: IAssessmentLevel) => result.category === category.id,
      );

      return levelSection && levelSection.level ? levelSection.level : 0;
    },

    /**
     * Get the color for the given category.
     */
    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },

    onClickOpenHowItWorksLink() {
      window.open(
        "https://medium.com/village-capital/entrepreneurs-and-vcs-need-to-be-more-precise-in-the-way-they-talk-to-each-other-3e714e7a5245",
      );
    },

    onQuickUpdateClick() {
      this.$store.dispatch(EViralLevelActions.RESET);

      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },

    goToMilestonePlannerPage() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },

    showFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_FULLGRID });
    },
  },
});
