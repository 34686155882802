










































































































import MatchingCardMixin from "@/mixins/matching-card.mixin";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";
import MatchingCardBackground from "@/components/matching-card/matching-card-background.vue";
import MatchDetailsModal from "@/components/match-details-modal/match-details-modal.vue";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";

export default MatchingCardMixin.extend({
  name: "MatchingCard",

  components: {
    MatchingCardWrapper,
    MatchingCardBackground,
    MatchDetailsModal,
  },

  static: {
    interestButton: {
      size: EPxButtonSize.EXTRA_SMALL,
    },

    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },

  computed: {
    checkboxTooltipCopy(): string {
      return this.$t(
        "common.components.matchingCard.checkboxTooltipText",
      ) as string;
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList") as string,
      };
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    isSelectModeActive(): boolean {
      return this.showListButton && this.selectionCompanyList.length > 0;
    },

    labelText(): string {
      if (!this.label && !this.disabled) {
        return "";
      }

      return (
        this.label || (this.$t("matching.matchingList.alreadyAdded") as string)
      );
    },

    isSelected(): boolean {
      return (
        this.isSelectModeActive &&
        !!this.selectionCompanyList.find(
          (itemUid: string) => itemUid === this.companyUid,
        )
      );
    },

    isFaded(): boolean {
      // If card is not selected, but select mode is active, show faded card
      return this.isSelectModeActive && !this.isSelected;
    },
  },

  watch: {
    isInnerSelected(newValue: boolean, oldValue: boolean) {
      // Prevent side-effects
      if (newValue === oldValue || newValue === this.isSelected) {
        return;
      }

      // Item was selected, dispatch according action
      if (newValue) {
        this.$store.dispatch(
          EListManagementActions.ADD_COMPANY,
          this.companyUid,
        );
        return;
      }

      this.$store.dispatch(
        EListManagementActions.REMOVE_COMPANY,
        this.companyUid,
      );
    },

    isSelected(newValue: boolean) {
      if (newValue !== this.isInnerSelected) {
        this.isInnerSelected = newValue;
      }
    },
  },

  created() {
    this.isInnerSelected = this.isSelected;
  },

  methods: {
    onCardClick() {
      // Block global component action when select mode is active
      if (!this.isSelectModeActive) {
        this.$emit("click");
      }
    },
  },
});
