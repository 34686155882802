import { render, staticRenderFns } from "./px-questionary-numeric.vue?vue&type=template&id=abffe8da&scoped=true&"
import script from "./px-questionary-numeric.vue?vue&type=script&lang=ts&"
export * from "./px-questionary-numeric.vue?vue&type=script&lang=ts&"
import style0 from "./px-questionary-numeric.vue?vue&type=style&index=0&id=abffe8da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abffe8da",
  null
  
)

export default component.exports