



















































import Vue from "vue";
import LevelUpdateCard from "@/modules/common/components/level-update-modal/level-update-card.vue";
import LevelUpdateModalNote from "@/modules/common/components/level-update-modal/level-update-modal-note.vue";
import LevelUpdateModalFooter from "@/modules/common/components/level-update-modal/level-update-modal-footer.vue";

import { ILevel } from "@/services/data/level/level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "LevelUpdateModalLevelsInfo",

  components: {
    LevelUpdateCard,
    LevelUpdateModalNote,
    LevelUpdateModalFooter,
  },

  props: {
    currentLevel: {
      type: Object as () => ILevel,
      required: true,
    },
    newLevel: {
      type: Object as () => ILevel,
      required: true,
    },
  },

  data() {
    return {
      hasServerError: false,
      isLoading: false,
    };
  },

  computed: {
    modalTitle(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.sameLevelTitle")
        : this.$t("common.components.levelUpdateModal.newLevelTitle", {
            current: this.currentLevel?.value,
            new: this.newLevel.value,
          });
    },

    prevAssessment(): Array<IAssessmentLevel> | null {
      return this.$store.get("auth/latestAssessment.data.data");
    },

    formattedPrevAssessment(): Array<IAssessmentLevel> | null {
      return this.prevAssessment
        ? this.prevAssessment.map((assessment: IAssessmentLevel) => ({
            ...assessment,
            level: assessment.level || 0,
          }))
        : null;
    },

    currentAssessment(): Array<IAssessmentLevel> {
      return this.$store.get("viralLevel.levels");
    },

    isSameLevel(): boolean {
      return this.currentLevel?.value === this.newLevel.value;
    },

    isLevelUp(): boolean {
      return this.currentLevel.value < this.newLevel.value;
    },

    typeOfUpdate(): TranslateResult {
      return this.isSameLevel
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.sameLevel")
        : this.isLevelUp
        ? this.$t("common.components.levelUpdateModal.typeOfUpdate.levelUp")
        : this.$t("common.components.levelUpdateModal.typeOfUpdate.levelDown");
    },
  },
});
