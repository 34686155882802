


























import Vue from "vue";

import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default Vue.extend({
  props: {
    affiliate: {
      type: Object as () => IAffiliate,
      required: true,
    },
  },

  computed: {
    affiliateShortcode(): string {
      return this.affiliate?.shortcode || "";
    },
  },

  methods: {
    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
      });
    },
  },
});
