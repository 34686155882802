



















import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import {
  ICompanyList,
  ICompanyListInvitedGuest,
  ICompanyListInvitedUser,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import ListShareModal from "@/modules/company-lists/components/list-share/list-share-modal.vue";

export default Vue.extend({
  name: "ListShare",

  components: {
    ListShareModal,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    listPasscode(): string {
      return this.value.passcode || "";
    },

    allUsers(): Array<ICompanyListInvitedUser | ICompanyListInvitedGuest> {
      return [...this.value.invited_users, ...this.value.invited_guests].sort(
        (userA, userB) => userA.name.localeCompare(userB.name),
      );
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },

    onVisibleChange(state: boolean) {
      this.shareModalVisible = state;
    },
  },
});
