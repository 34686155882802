





























































import Vue from "vue";
import {
  generateEmailValidator,
  allFormFieldsValid,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";
import { LocaleMessages } from "vue-i18n";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import cloneDeep from "lodash/cloneDeep";
import {
  SUPPORTER_USER_TYPE,
  ENTREPRENEUR_USER_TYPE,
} from "@/modules/authentication/constants";

export interface IFormField {
  fieldValue: any;
  prop: string;
  validateState: string;
  required: boolean;
}

export default Vue.extend({
  name: "TeamMemberMandatoryData",

  props: {
    memberInfo: {
      type: Object as () => ITeamMember,
      default: null,
    },

    memberIndex: {
      type: Number,
      default: 0,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isFromProfile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {} as ITeamMember,
      entrepreneurAffiliateRules: {
        first_name: [{ required: false }],
        last_name: [{ required: false }],
        email: generateEmailValidator(
          this,
          "teamManagement.settings.mandatoryData.email",
        ),
        position: [{ required: false }],
      },
      profileRules: {
        first_name: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.firstName",
        ),
        last_name: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.lastName",
        ),
        email: generateEmailValidator(
          this,
          "teamManagement.settings.mandatoryData.email",
          true,
        ),
        position: generateRequiredValidator(
          this,
          "teamManagement.settings.mandatoryData.position",
        ),
      },
      submissionDisabled: false,
    };
  },

  computed: {
    fieldsLabel(): LocaleMessages {
      return this.$t("teamManagement.settings.mandatoryData") as LocaleMessages;
    },

    isSupporter(): boolean {
      return this.$user.isSupporter() || this.$user.isPendingSupporter();
    },

    userType(): number {
      return this.isSupporter ? SUPPORTER_USER_TYPE : ENTREPRENEUR_USER_TYPE;
    },

    userTypeCopy(): string {
      return this.$t(
        `teamManagement.settings.mandatoryData.info[${this.userType}]`,
      ) as string;
    },

    infoCopy(): string {
      return this.isFromProfile
        ? (this.$t(`teamManagement.settings.mandatoryData.required`) as string)
        : this.userTypeCopy;
    },

    rules(): any {
      return this.isFromProfile || this.isSupporter
        ? this.profileRules
        : this.entrepreneurAffiliateRules;
    },

    formRef(): any {
      return this.$refs.mandatoryDataForm;
    },

    formFields(): IFormField[] {
      return this.formRef.fields as IFormField[];
    },
  },

  watch: {
    memberInfo: {
      deep: true,
      immediate: true,
      handler(newMemberInfo: ITeamMember) {
        this.formData = cloneDeep(newMemberInfo);
      },
    },

    memberIndex: {
      handler() {
        this.checkFieldsValidState();
      },
    },
  },

  mounted() {
    this.checkFieldsValidState();
  },

  methods: {
    checkFieldsValidState() {
      if (this.formRef) {
        const formFields = this.formFields;

        if (this.isFromProfile || this.isSupporter) {
          // When the fields are preffiled with data that already exists
          // Element UI doesn't consider the success state so we need
          // to check if the fields have value and change the state to success
          formFields.forEach((field: IFormField) => {
            if (!!field.fieldValue && field.prop === "email") {
              const matchResult = field.fieldValue.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              );

              return (field.validateState = matchResult ? "success" : "error");
            }

            if (
              !!field.fieldValue &&
              field.validateState !== "error" &&
              field.prop !== "email"
            ) {
              return (field.validateState = "success");
            }
          });

          this.submissionDisabled = !allFormFieldsValid(
            this.formRef,
            this.rules,
          );

          this.$emit("form-is-invalid", this.submissionDisabled);
        }

        this.$emit("new-member-info", this.formData);
      }
    },
  },
});
