





























import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import {
  IMatchingQuestion,
  IMatchingQuestionCategory,
} from "@/services/data/matching-questionary/matching-question.interface";
import SubmissionTabEmpty from "@/modules/supporters/components/submission/submission-tab-empty.vue";

import GetQuestionAnswerMixin from "@/mixins/get-question-answer.mixin";

export default GetQuestionAnswerMixin.extend({
  components: {
    SubmissionTabEmpty,
  },

  props: {
    additionalCriteria: {
      type: Array as () => Array<IMatchingCriteria>,
      required: true,
    },
  },

  methods: {
    getQuestion(criteria: IMatchingCriteria): string {
      const question: IMatchingQuestion = criteria.question;
      const questionCategory: IMatchingQuestionCategory =
        question.question_category;
      return `${questionCategory.name} > ${
        question.short_name || question.resource_question
      }`;
    },
  },
});
