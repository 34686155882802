











import Vue from "vue";

import PxTabBar from "@/components/px-tab-bar/px-tab-bar.vue";
import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxFooter from "@/components/px-footer/px-footer.vue";

import {
  helpScoutBeaconInit,
  helpScoutBeaconDestroy,
} from "@/services/utils/helpscout";
import { Route } from "vue-router";
import { changeLocale, loadedLocales } from "@/services/i18n/locale-utils";
import { i18n } from "@/services/i18n/i18n";
import store from "@/services/store";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";
import {
  SELF_ASSESSMENT_ASSESSMENT,
  SELF_ASSESSMENT_RESULTS,
} from "@/modules/self-assessment/services/router/routes-names";
import { SUPPORTERS_STARTING_POINT } from "@/modules/supporters/services/router/routes-names";
import { ROUTE_AUTHENTICATION_PASSWORD_RECOVER } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "AuthBase",

  components: {
    PxTabBar,
    PxNavbar,
    PxFooter,
  },

  mounted() {
    // Load HelpScout chat widget
    helpScoutBeaconInit();
  },

  destroyed() {
    // Remove HelpScout chat widget
    helpScoutBeaconDestroy();
  },

  beforeRouteLeave: async (to: Route, from: Route, next: Function) => {
    if (
      to.name === SELF_ASSESSMENT_ASSESSMENT ||
      to.name === SELF_ASSESSMENT_RESULTS ||
      to.name === SUPPORTERS_STARTING_POINT ||
      to.name === ROUTE_AUTHENTICATION_PASSWORD_RECOVER
    ) {
      next();
    } else {
      // We're reverting the locale back to English as we're only supposed
      // to provide translations in assessment flow pages for the time being.
      await changeLocale(i18n, "en", loadedLocales, document);
      // Fetch backend translations
      store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
        skipLoading: true,
      });
      store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      next();
    }
  },
});
