







































import Vue from "vue";
import { get } from "vuex-pathify";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";

export default Vue.extend({
  name: "AccountDropdown",

  data() {
    return {
      isMenuVisible: false,
    };
  },

  computed: {
    company: get("auth/company/data"),

    companyLogo(): string {
      const innerCompany = this.company as ICompany | null;
      return innerCompany ? innerCompany.logo : "";
    },

    companyName(): string {
      const innerCompany = this.company as ICompany | null;
      return innerCompany ? innerCompany.name : "";
    },

    userType(): number {
      return this.$user.getUserAccountType();
    },
  },

  methods: {
    onClickLogout() {
      this.$store.dispatch(EAuthActions.LOGOUT);
    },

    onClickOpenModal(modal: string) {
      this.$emit("open-modal", modal);
    },

    onVisibleChange(state: boolean) {
      this.isMenuVisible = state;
    },

    goToSettings() {
      !this.$features.isEnabled("settingsPage") ||
      this.userType === ENTREPRENEUR_USER_TYPE
        ? this.onClickOpenModal("accountSettings")
        : this.$route.name !== ROUTE_ACCOUNT_SETTINGS &&
          this.$router.push({
            name: ROUTE_ACCOUNT_SETTINGS,
            query: {
              tab: EAccountSettingsNavigationTabs.INFO,
            },
          });
    },
  },
});
