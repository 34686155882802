






















import Vue from "vue";

export default Vue.extend({
  name: "PxStatusBadge",

  props: {
    label: {
      required: true,
      type: String,
    },

    labelSize: {
      type: Number,
      default: 12,
    },

    backgroundColor: {
      type: String,
      default: "#000",
    },

    labelColor: {
      type: String,
      default: "#fff",
    },

    borderColor: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: "",
    },

    iconSize: {
      type: Number,
      default: 20,
    },

    uppercase: {
      type: Boolean,
      default: true,
    },

    weight: {
      type: Number,
      default: 700,
    },

    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => ["left", "right"].includes(value),
    },

    borderRadius: {
      type: Number,
      default: 100,
    },

    boxShadow: {
      type: String,
      default: null,
    },
  },

  computed: {
    badgeStyle(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        border: this.borderColor ? `1px solid ${this.borderColor}` : 0,
        textTransform: this.uppercase ? "uppercase" : "none",
        fontWeight: this.weight,
        borderRadius: `${this.borderRadius}px`,
        boxShadow: this.boxShadow,
      };
    },

    labelStyle(): { [key: string]: string | number } {
      return { color: this.labelColor, fontSize: `${this.labelSize}px` };
    },
  },
});
