








































import Vue from "vue";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";

export default Vue.extend({
  name: "VerifyAccountBanner",

  computed: {
    authUserEmail(): string {
      return this.$store.get("auth/user.email");
    },
  },

  methods: {
    async resendEmail() {
      try {
        await resendEmailProvider.create({
          email: this.authUserEmail,
        });

        this.$message({
          message: this.$t(
            "authentication.login.messages.confirmationEmail",
          ) as string,
          type: "success",
          customClass: "is-navbar",
        });

        this.closeBanner();
      } catch {
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          duration: 3000,
          customClass: "is-navbar",
        });
      }
    },

    closeBanner() {
      this.$emit("close");
    },
  },
});
