




















import Vue from "vue";

import {
  EPxButtonSize,
  EPxButtonType,
  EPxButtonVariant,
} from "./px-button.types";

/**
 *
 * Apart from using the base structure from Element UI Button, it allows
 * to have custom icons and takes advantage of defined styles using enums.
 */
export default Vue.extend({
  name: "PxButton",

  props: {
    size: {
      type: String,
      default: EPxButtonSize.MEDIUM,
      validator(value: EPxButtonSize) {
        return Object.values(EPxButtonSize).includes(value);
      },
    },

    variant: {
      type: String,
      default: EPxButtonVariant.NORMAL,
      validator(value: EPxButtonVariant) {
        return Object.values(EPxButtonVariant).includes(value);
      },
    },

    type: {
      type: String,
      default: EPxButtonType.DEFAULT,
      validator(value: EPxButtonType) {
        return Object.values(EPxButtonType).includes(value);
      },
    },

    icon: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    iconPosition: {
      type: String,
      default: "left",
      validator: (value: string) => {
        return ["left", "right"].includes(value);
      },
    },
  },

  computed: {
    isCompact(): boolean {
      return this.variant === EPxButtonVariant.COMPACT;
    },

    isFluid(): boolean {
      return this.variant === EPxButtonVariant.FLUID;
    },
  },

  methods: {
    onButtonClick(event: any) {
      this.$emit("click", event);
    },
  },
});
