


















import Vue from "vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "PxPageHeader",

  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },
  },

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },
});
