


































import Vue from "vue";

import CollapsibleQuestion from "@/components/collapsible-question/collapsible-question.vue";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { ICapitalExplorerData } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

export default Vue.extend({
  name: "QuestionsPanel",

  components: {
    CollapsibleQuestion,
  },

  props: {
    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      required: true,
    },

    reset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentResponses: [] as Array<IMatchingResponse>,
      validResponses: [] as Array<IMatchingResponse>,
      scrollAtTop: true,
      panel: (null as any) || null,
      showModal: false,
      modalTitle: "",
      modalText: "",
    };
  },

  watch: {
    reset: {
      handler(shouldReset: boolean) {
        if (!shouldReset) return;

        this.currentResponses = this.questions.map((question) => {
          return {
            question: question.id,
            value: {},
            answers: [],
          };
        });

        this.$emit("update:reset", false);
      },
    },
  },

  created() {
    this.currentResponses = this.questions.map((question) => {
      return {
        question: question.id,
        value: {},
        answers: [],
      };
    });

    const capitalExplorerData = this.$store.get(
      "capitalExplorer/data",
    ) as ICapitalExplorerData;

    for (const response of capitalExplorerData.submission.responses) {
      const currentResponse = this.currentResponses.find(
        ({ question }) => question === response.question,
      );
      if (currentResponse) {
        currentResponse.value = response.value;
        currentResponse.answers = response.answers;
      }
    }
  },

  beforeDestroy() {
    this.currentResponses = [];
    this.panel = null;
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max &&
          !!response?.value?.min &&
          response?.value?.max > response?.value?.min) ||
        (!!response.value?.text && response.value?.text.length < 1500)
      );
    },

    updateResponses(isValid: boolean, newResponse: IMatchingResponse) {
      this.currentResponses =
        this.currentResponses?.map((response: IMatchingResponse) => {
          return response.question === newResponse.question
            ? newResponse
            : response;
        }) || [];

      // filter currentResponses to only include valid responses
      this.validResponses = this.currentResponses?.filter(
        (response: IMatchingResponse) => {
          return this.checkValidResponse(response);
        },
      );

      this.$emit("update-responses", this.validResponses);
    },

    checkScroll() {
      this.panel = this.$refs.panel as HTMLElement;

      if (!this.panel) return;

      this.scrollAtTop = this.panel.scrollTop === 0;
    },

    openModal(question: IMatchingQuestion) {
      this.modalTitle = this.$user.isEntrepreneur()
        ? question.entrepreneur_question
        : question.resource_question;
      this.modalText = question.instructions || "";
      this.showModal = true;
    },
  },
});
