













































































































import Vue from "vue";
import { activeModules } from "@/services/utils/utils";

import SubmissionCompanyPanel from "@/modules/program/components/submission-company-panel/submission-company-panel.vue";
import InvestmentLevelPanel from "@/modules/program/components/investment-level-panel/investment-level-panel.vue";
import SubmissionResponsesPanel from "@/modules/program/components/submission-responses-panel/submission-responses-panel.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import {
  MATCHING_CONNECTED_INTEREST,
  MATCHING_HAS_INTEREST,
  MATCHING_INITIAL_INTEREST,
  MATCHING_USER_TYPES,
} from "@/modules/matching/constants";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import {
  IAffiliateProgramSubmission,
  IAffiliateListProgramSubmission,
} from "@/modules/program/services/data/affiliate-program-submission/affiliate-program-submission";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import moment from "moment";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";
import SubmissionTabTeamMembers from "@/modules/supporters/components/submission/submission-tab-team-members.vue";

enum ESubmissionPanelTab {
  QUESTIONS = 1,
  VENTURE_INVESTMENT_LEVEL = 2,
  TEAM_MEMBERS = 3,
}

interface ISubmissionPanelTab {
  key: number;
  label: string;
  visible: boolean;
}

export default Vue.extend({
  components: {
    SubmissionCompanyPanel,
    InvestmentLevelPanel,
    SubmissionResponsesPanel,
    SubmissionTabTeamMembers,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSubmission,
      required: true,
    },
    submissionList: {
      type: Array as () => IAffiliateListProgramSubmission[],
      required: true,
    },
  },

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  data() {
    return {
      selectedTab: null as null | number,
      ESubmissionPanelTab,
      submissionUid: "",
    };
  },

  computed: {
    isLoading(): boolean {
      return (
        !this.profileCompany || this.$store.get("matchingInterest/loading")
      );
    },

    submissionFormattedDate(): string {
      const date = this.submission
        ? moment(this.submission.assessment.created_at)
        : moment();
      return date.format("MMMM D, YYYY - HH:mmA");
    },

    ventureInvestmentLevelLabel(): string {
      return (
        this.$screen.mdUp
          ? this.$t("program.submission.tabs.ventureInvestLevel")
          : this.$t("program.submission.tabs.ventureInvestLevelMobile")
      ) as string;
    },

    affiliate(): IAffiliate {
      return this.submission.affiliate;
    },

    assessment(): IAssessment {
      return this.submission.assessment;
    },

    responses(): Array<IMatchingResponse> {
      return this.submission.responses;
    },

    /**
     * Exclude responses that override profile fields
     * and which may appear duplicated on the submission (about, website, email)
     *
     * Questions excluded are the ones:
     * - that do not explicitly have a `profile_field` associated or;
     * - that do not have a value or;
     * - whose value does not match any of the company properties.
     */
    responsesWithoutProfileFields(): Array<IMatchingResponse> {
      if (!this.profileCompany) {
        return this.responses;
      }

      const profileFieldsValues = [
        this.profileCompany.about,
        this.profileCompany.website,
        this.profileCompany.email,
      ];

      if (this.responses && this.responses.length) {
        return this.responses.filter((response) => {
          const questionOverridesProfileField =
            typeof response.question === "object" &&
            !!response.question?.profile_field;

          const responseValue = response.value
            ? Object.values(response.value)
            : null;

          return (
            !questionOverridesProfileField ||
            !responseValue ||
            !profileFieldsValues.some((fieldValue) =>
              responseValue.includes(fieldValue),
            )
          );
        });
      }

      return [];
    },

    profileCompany(): ICompany | null {
      return this.$store.state.profile.company.data;
    },

    profileCompanyId(): number | null {
      return this.profileCompany ? this.profileCompany.id : null;
    },

    /**
     * Get auth user interests
     */
    authCompany(): ICompany {
      return this.$store.state.auth.company.data;
    },

    authCompanyId(): number | null {
      return this.authCompany ? this.authCompany.id : null;
    },

    /**
     * Get auth user interests
     */
    matchingInterests(): Array<IMatchingInterest> {
      return this.$store.state.matchingInterest.data;
    },

    /**
     * Check if store value is set and that it has values
     */
    hasMatchingInterests(): boolean {
      return this.matchingInterests && !!this.matchingInterests.length;
    },

    /**
     * Get auth user type
     */
    userType(): string {
      return MATCHING_USER_TYPES[this.$user.getUserAccountType()];
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    /**
     * Return current interest state if available
     */
    currentMatchingInterest(): IMatchingInterest | null {
      if (this.hasMatchingInterests) {
        return (
          this.matchingInterests.find(
            (interest: IMatchingInterest) =>
              interest[this.userType] === this.authCompanyId &&
              interest[this.targetType] === this.profileCompanyId,
          ) || null
        );
      }

      return null;
    },

    /**
     * Return final interest between both parties
     */
    interestState(): number {
      return this.currentMatchingInterest
        ? this.currentMatchingInterest.state_of_interest
        : MATCHING_INITIAL_INTEREST;
    },

    isConnected(): boolean {
      return this.interestState === MATCHING_CONNECTED_INTEREST;
    },

    isInterested(): boolean {
      return this.interestState === MATCHING_HAS_INTEREST;
    },

    canMatch(): boolean {
      return (
        (this.$store.state.matchingInterest.canMatch &&
          this.$user.isLogged()) ||
        false
      );
    },

    mappedSubmissionList(): any {
      return this.submissionList.map(
        (submission: IAffiliateListProgramSubmission, index) => {
          const current =
            index === 0
              ? `(${this.$t("profile.assessment.historyPanel.current")}) `
              : "";

          return {
            uid: submission.uid,
            date: `${current} ${this.formattedDate(submission.created_at)}`,
            printableValue: this.$t("program.submission.submittedAt", {
              date: moment(submission.created_at).format(
                "MMMM D, YYYY - HH:mmA",
              ),
            }),
          };
        },
      );
    },

    selectedPrintableValue(): string {
      return (
        this.mappedSubmissionList.find(
          (submission: any) => submission.uid === this.submissionUid,
        ).printableValue || ""
      );
    },

    canShowSubmissionsList(): boolean {
      return this.$user.isLogged() && !!this.submissionList.length;
    },

    panelTabs(): Array<ISubmissionPanelTab> {
      return [
        {
          label: this.$t("program.submission.tabs.questions") as string,
          key: ESubmissionPanelTab.QUESTIONS,
          visible:
            !!this.responsesWithoutProfileFields &&
            !!this.responsesWithoutProfileFields.length,
        },
        {
          label: this.$t(
            "program.submission.tabs.ventureInvestLevel",
          ) as string,
          key: ESubmissionPanelTab.VENTURE_INVESTMENT_LEVEL,
          visible: !!this.submission.assessment,
        },
        {
          label: this.$t("program.submission.tabs.teamMembers") as string,
          key: ESubmissionPanelTab.TEAM_MEMBERS,
          visible:
            !!this.submission.team_members &&
            !!this.submission.team_members.length,
        },
      ];
    },

    visiblePanelTabs(): Array<ISubmissionPanelTab> {
      return this.panelTabs.filter((tab: ISubmissionPanelTab) => tab.visible);
    },

    showMatchingButton(): boolean {
      return (
        this.$user.isLogged() &&
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },
  },

  watch: {
    submissionUid: {
      handler(newSubmission: string) {
        if (!this.submission || newSubmission === this.submission.uid) {
          return;
        }

        this.$emit("on-submission-change", newSubmission);
      },
    },
  },

  created() {
    // If  store not filled, fetch interests
    if (!this.hasMatchingInterests) {
      this.fetchAuthUserInterests();
    }

    if (this.submission) {
      this.submissionUid = this.submission.uid;

      this.selectedTab = this.visiblePanelTabs[0].key;
    }
  },

  methods: {
    goToMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },

    fetchAuthUserInterests() {
      if (this.$user.isLogged()) {
        this.$store.dispatch(EMatchingInterestActions.FETCH);
      }
    },

    externalLinkClickHandler() {
      if (this.affiliate && this.affiliate.website) {
        window.open(this.affiliate.website);
      }
    },

    isActiveTab(tab: ESubmissionPanelTab) {
      return this.selectedTab === tab;
    },

    changeTab(selectedTab: ESubmissionPanelTab) {
      this.selectedTab = selectedTab;
    },

    formattedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    showListModal() {
      this.$emit("click");
    },

    onPrintButtonClick() {
      window.print();
    },
  },
});
