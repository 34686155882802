













import Vue from "vue";

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: 92,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
