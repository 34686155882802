









































import Vue from "vue";
import { IModalListGroup } from "@/components/px-list-modal/px-list-modal.vue";

export interface IMoreListItem {
  name: string;
  items?: Array<string>;
}

export default Vue.extend({
  name: "PxMoreList",

  props: {
    modalTitle: {
      type: String,
      default: "",
    },
    // Optionally specify items
    // used for list modal
    // otherwise fallsback to (values).
    valuesItemList: {
      type: Array as () => IMoreListItem[],
      default: () => [],
      required: false,
    },
    values: {
      type: Array as () => string[],
      default: () => [],
    },
    valuesLimit: {
      type: Number,
      default: 8,
    },
    capitalize: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    fullList(): Array<IModalListGroup | string> {
      return this.valuesItemList.length
        ? this.valuesItemList.map((list) => {
            return !list.items ? list.name : (list as IModalListGroup);
          })
        : this.values.length
        ? this.values
        : [];
    },

    fullListCount(): number {
      return this.fullList.reduce((count, list) => {
        count++;

        if (typeof list !== "string" && !!list.items && list.items.length) {
          count += list.items.length;
        }

        return count;
      }, 0);
    },

    hasValuesOverLimit(): boolean {
      return (
        this.fullList.length < this.fullListCount ||
        this.fullListCount > this.valuesLimit
      );
    },

    valuesOnLimit(): string[] {
      return this.hasValuesOverLimit
        ? this.values.slice(0, this.valuesLimit)
        : this.values;
    },

    valuesOverLimitLength(): number {
      return this.fullListCount - this.valuesOnLimit.length;
    },
  },

  methods: {
    onClickMoreHandler() {
      this.isModalVisible = true;
    },
  },
});
