



















































import Vue from "vue";
import get from "lodash/get";

import PxNavbar from "@/components/px-navbar/px-navbar.vue";
import PxFooter from "@/components/px-footer/px-footer.vue";

import MobileTopNavbar from "@/modules/self-assessment/components/mobile-top-navbar/mobile-top-navbar.vue";
import CategoryWizard from "@/modules/self-assessment/components/category-wizard/category-wizard.vue";
import CategoryWizardMobile from "@/modules/self-assessment/components/category-wizard-mobile/category-wizard-mobile.vue";
import { IStepItem } from "@/modules/self-assessment/types/step-item.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import {
  ESupporterInvestingLevelActions,
  ESupporterInvestingLevelGetters,
  ISupporterInvestingLevelState,
} from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";

import { ELevelActions } from "@/services/store/levels/levels-types";
import { SUPPORTERS_ASSESSMENT } from "@/modules/supporters/services/router/routes-names";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { defaultAffiliateId } from "@/services/configs";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

export default Vue.extend({
  components: {
    PxNavbar,
    MobileTopNavbar,
    PxFooter,
    CategoryWizard,
    CategoryWizardMobile,
  },

  data() {
    return {
      currentStep: 0,
    };
  },

  computed: {
    /**
     * Check if the footer must be shown for the current route.
     */
    showFooter(): boolean {
      const routeMeta = this.$route.meta;
      return routeMeta.showFooter || false;
    },

    /**
     * Check if the wizard must be shown for the current route.
     */
    showWizard(): boolean {
      const routeMeta = this.$route.meta;
      return routeMeta.showWizard || false;
    },

    showDesktopWizard(): boolean {
      const sizeAllowed = this.$screen.mdDown;

      return this.showWizard && !sizeAllowed;
    },

    showMobileWizard(): boolean {
      return this.showWizard && this.$screen.mdDown;
    },

    hasError(): boolean {
      return this.$store.state.categories.error;
    },

    isLoading(): boolean {
      return (
        this.$store.state.categories.loading ||
        this.$store.getters[SupporterTypeState.Getter.IS_LOADING]
      );
    },

    /**
     * Get all categories from the store.
     */
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Prepare the categories to be used on the Wizard.
     */
    steps(): Array<IStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      })) as Array<IStepItem>;
    },

    supporterInvestingLevelState(): ISupporterInvestingLevelState {
      return this.$store.state.supporterInvestingLevel;
    },

    /**
     * Index of the current selected category.
     */
    selectedCategory(): number {
      return this.supporterInvestingLevelState.selectedCategory;
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.supporterInvestingLevelState.levels;
    },

    checkedSteps(): Array<number> {
      return this.levels
        .filter((record: ICategoryLevelSelection) => record.level > 0)
        .map((record: ICategoryLevelSelection) => record.category);
    },

    submitted(): boolean {
      return this.supporterInvestingLevelState.submitted;
    },

    extraFooterClasses(): string {
      return this.$store.getters[
        ESupporterInvestingLevelGetters.SINGLE_METADATA
      ]("footerClass") as string;
    },

    viralLevelState(): ISupporterInvestingLevelState {
      return this.$store.state.viralLevel;
    },

    affiliate(): IAffiliate | null {
      return this.viralLevelState.affiliate;
    },

    affiliateLogo(): string {
      return this.affiliate && this.affiliate.logo ? this.affiliate.logo : "";
    },
  },

  watch: {
    /**
     * Update the wizard current step when the
     * store updates.
     */
    selectedCategory: {
      immediate: true,
      handler(newVal: number) {
        this.currentStep = newVal;
      },
    },
  },

  created() {
    this.fetchCategories();
    this.fetchLevels();
  },

  methods: {
    /**
     * When the user uses the wizard update the store with the
     * new category selected index.
     */
    stepClickHandler(newIndex: number) {
      this.$store.dispatch(
        ESupporterInvestingLevelActions.SET_SELECTED_CATEGORY,
        newIndex,
      );

      // Move the user to the assessment when not on that page
      if (this.$route.name !== SUPPORTERS_ASSESSMENT) {
        this.$router.push({ name: SUPPORTERS_ASSESSMENT });
      }
    },

    fetchCategories() {
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },

    fetchLevels() {
      this.$store.dispatch(ELevelActions.FETCH);
    },

    /**
     * Get the affiliate data when present.
     */
    getAffiliate(data: string): string | null {
      if (!this.affiliate || this.affiliate.id === defaultAffiliateId()) {
        return null;
      }

      return get(this.affiliate, data, null);
    },
  },
});
