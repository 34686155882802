import {
  ICompanyList,
  ICompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import {
  EProviderFeatures,
  GenericProvider,
  IPaginationResult,
  Provider,
} from "@/services/data/generic-provider";
import { baseAPIUrl } from "@/services/utils/utils";

/**
 * Provider for the company list.
 */
@Provider(`${baseAPIUrl}/user/company-lists`, [
  EProviderFeatures.CREATE,
  EProviderFeatures.LIST,
  EProviderFeatures.GET,
  EProviderFeatures.PATCH,
])
class CompanyListProvider extends GenericProvider<ICompanyList> {
  /**
   *
   * @param listUid
   * @param queryParams
   */
  public async getDetailedCompanies(
    listUid: string,
    queryParams: any = {},
  ): Promise<IPaginationResult<ICompanyOfList>> {
    const url = super.buildEndPointUrl(queryParams, `${listUid}/companies`);
    const request = this.httpClient.get(url);
    const { data } = await this.wrapRequest(request);

    const mappedResults = data.results.map((company: ICompanyList) => ({
      ...company,
      type: "detailed",
    }));

    return {
      ...data,
      results: mappedResults,
    } as IPaginationResult<ICompanyOfList>;
  }
}

export const companyListProvider = new CompanyListProvider();
