



































































































import Vue from "vue";

import CompanyEditModal from "@/modules/profile/components/company-edit-modal/company-edit-modal.vue";
import CompanyPanelPlaceholder from "@/modules/profile/components/company-panel/company-panel-placeholder.vue";
import CompanyPanelEntrepreneur from "@/modules/profile/components/company-panel/company-panel--entrepreneur.vue";
import CompanyPanelSupporter from "@/modules/profile/components/company-panel/company-panel--supporter.vue";
import PxMatchingInterestTip from "@/components/px-matching-interest/px-matching-interest-tip.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";

import {
  MATCHING_CONNECTED_INTEREST,
  MATCHING_HAS_INTEREST,
  MATCHING_INITIAL_INTEREST,
  MATCHING_USER_TYPES,
} from "@/modules/matching/constants";

import { locationFormatter } from "@/utils/location-formatter";
import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";

import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { ROUTE_TEAM_MANAGEMENT } from "@/modules/profile/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

const AVATAR_SIZE_MOBILE = 74;
const AVATAR_SIZE_DESKTOP = 95;

export default Vue.extend({
  name: "CompanyPanel",

  components: {
    CompanyEditModal,
    CompanyPanelPlaceholder,
    CompanyPanelEntrepreneur,
    CompanyPanelSupporter,
    PxMatchingInterestTip,
  },

  static: {
    addToListButton: {
      icon: "icon-add-to-list",
      size: EPxButtonSize.EXTRA_SMALL,
      type: EPxButtonType.DARK_BLUE,
    },
  },

  data() {
    return {
      isCompanyEditModalVisible: false,
    };
  },

  computed: {
    showAddToListButton(): boolean {
      if (this.$user.isEntrepreneur()) {
        return (
          this.$screen.lgUp &&
          activeModules().includes("company-lists") &&
          this.$features.isEnabled("companyLists") &&
          this.$features.isEnabled("companyListsForEntrepreneurs")
        );
      } else {
        return (
          this.$screen.lgUp &&
          activeModules().includes("company-lists") &&
          this.$features.isEnabled("companyLists")
        );
      }
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.$t("common.components.default.cta.addToList") as string,
      };
    },
    /**
     * Size of the avatar component.
     */
    avatarSize(): number {
      return this.$screen.smDown ? AVATAR_SIZE_MOBILE : AVATAR_SIZE_DESKTOP;
    },

    company(): ICompany | null {
      return this.$store.state.profile.company.data;
    },

    companyId(): number | null {
      return this.company ? this.company.id : null;
    },

    isSupporterLoading(): boolean {
      return (
        this.$user.isSupporter() &&
        (this.$store.state.profile.supporter.loading ||
          (this.$user.isOwner() && this.$store.state.auth.supporter.loading) ||
          this.$store.getters[SupporterTypeState.Getter.IS_LOADING])
      );
    },

    isLoading(): boolean {
      return (
        this.$store.state.auth.company.loading ||
        this.$store.state.profile.loading ||
        this.$store.state.profile.company.loading ||
        (activeModules().includes("matching") &&
          this.$store.state.matchingInterest.loading) ||
        this.isSupporterLoading
      );
    },

    /**
     * Get auth user interests
     */
    authCompany(): ICompany {
      return this.$store.state.auth.company.data;
    },

    authCompanyId(): number | null {
      return this.authCompany ? this.authCompany.id : null;
    },

    /**
     * Get auth user interests
     */
    matchingInterests(): Array<IMatchingInterest> {
      return this.$store.state.matchingInterest
        ? this.$store.state.matchingInterest.data
        : [];
    },

    /**
     * Check if store value is set and that it has values
     */
    hasMatchingInterests(): boolean {
      return this.matchingInterests && !!this.matchingInterests.length;
    },

    /**
     * Get auth user type
     */
    userType(): string {
      return MATCHING_USER_TYPES[this.$user.getUserAccountType()];
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    /**
     * Return current interest state if available
     */
    currentMatchingInterest(): IMatchingInterest | null {
      if (this.hasMatchingInterests) {
        return (
          this.matchingInterests.find(
            (interest: IMatchingInterest) =>
              interest[this.userType] === this.authCompanyId &&
              interest[this.targetType] === this.companyId,
          ) || null
        );
      }

      return null;
    },

    /**
     * Return final interest between both parties
     */
    interestState(): number {
      return this.currentMatchingInterest
        ? this.currentMatchingInterest.state_of_interest
        : MATCHING_INITIAL_INTEREST;
    },

    isConnected(): boolean {
      return this.interestState === MATCHING_CONNECTED_INTEREST;
    },

    isInterested(): boolean {
      return this.interestState === MATCHING_HAS_INTEREST;
    },

    canMatch(): boolean {
      return this.$store.state.matchingInterest
        ? this.$store.state.matchingInterest.canMatch
        : false;
    },

    formattedLocation(): string {
      const locationComponent =
        this.company && this.company.locations && this.company.locations[0]
          ? this.company.locations[0]
          : null;

      return locationFormatter(locationComponent);
    },

    isMatchingEnabled(): boolean {
      return (
        this.$features.isEnabled("match") &&
        activeModules().includes("matching")
      );
    },

    showMatchingCta(): boolean {
      return this.canMatch && this.isMatchingEnabled;
    },
  },

  async created() {
    // If  store not filled, fetch interests
    if (!this.hasMatchingInterests && this.isMatchingEnabled) {
      this.fetchAuthUserInterests();
    }

    // If supporter profile, loading supporter types
    if (this.$user.isSupporter()) {
      await this.$store.dispatch(SupporterTypeState.Action.GET_VALUES);
    }
  },

  methods: {
    onClickEdit() {
      this.isCompanyEditModalVisible = true;
    },

    onOpenCompanyModal() {
      this.isCompanyEditModalVisible = true;
    },

    async onFocusCompanyModalField(field: string) {
      this.isCompanyEditModalVisible = true;
      await this.$nextTick();
      (this.$refs.companyEditModal as any).focusInputField(field);
    },

    async onShowModalView(view: string) {
      this.isCompanyEditModalVisible = true;
      await this.$nextTick();
      (this.$refs.companyEditModal as any).setCurrentView(view);
    },

    fetchAuthUserInterests() {
      this.$store.dispatch(EMatchingInterestActions.FETCH);
    },

    // TODO: improve the emission of this event to not be repeated in two components
    showListModal() {
      this.$emit("click");
    },

    goToTeamMembersManagementPage() {
      this.$router.push({ name: ROUTE_TEAM_MANAGEMENT });
    },
  },
});
