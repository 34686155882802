
























































import Vue from "vue";

import { EMetaActions } from "@/services/store/meta/meta-types";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { assessmentProvider } from "@/services/data/assessment/assessment.provider";

import {
  ENTREPRENEUR_USER_GROUP_ID,
  SUPPORTER_USER_GROUP_ID,
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_UPDATE_META_UPGRADE,
  QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
} from "@/modules/common/constants";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "LevelUpdateModalFooter",

  props: {
    currentLevel: {
      type: Number,
      required: true,
    },
    newLevel: {
      type: Number,
      required: true,
    },
    sameLevel: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    currentAssessment: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: this.loading,
      isPopoverVisible: false,
      hasServerError: this.hasError,
    };
  },

  computed: {
    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    userGroupId(): number {
      return this.$user.isEntrepreneur()
        ? ENTREPRENEUR_USER_GROUP_ID
        : SUPPORTER_USER_GROUP_ID;
    },

    updateButtonCopy(): TranslateResult {
      return this.sameLevel
        ? this.$t("common.components.levelUpdateModal.buttons.saveChanges")
        : this.$t("common.components.levelUpdateModal.buttons.saveUpdate");
    },
  },

  watch: {
    hasServerError(newValue: boolean) {
      if (this.hasError !== newValue) {
        this.$emit("update:hasError", newValue);
      }
    },

    isLoading(newValue: boolean) {
      if (this.loading !== newValue) {
        this.$emit("update:loading", newValue);
      }
    },
  },

  methods: {
    onClickDiscard() {
      this.cleanAssessmentData();
    },

    /**
     * Hide the modal and remove the after assessment mode.
     */
    cleanAssessmentData() {
      this.$emit("close-modal");
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_ON_UPDATE,
      );
      this.isPopoverVisible = false;
    },

    /**
     * Handle click on the save assessment button.
     *
     * This will save the new assessment on the API and clear
     * the temporary assessment data.
     */
    //TODO: test the assessment provider
    async onClickSaveAssessment() {
      this.isLoading = true;
      this.hasServerError = false;

      if (this.sameLevel) {
        // Create some metadata to help the other components to check if
        // the level was not changed
        this.$store.dispatch(EMetaActions.SET, {
          key: QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
          value: true,
        });
      } else {
        // Create some metadata to help the other components to check if
        // this was an level upgrade or downgrade.
        this.$store.dispatch(EMetaActions.SET, {
          key: QUICK_LEVEL_UPDATE_META_UPGRADE,
          value: this.newLevel > this.currentLevel,
        });
      }

      try {
        await assessmentProvider.register(this.userGroupId, {
          levels: this.currentAssessment.map((e: IAssessmentLevel) => ({
            category: e.category,
            level: e.level === 0 ? null : e.level,
          })),
        });
        // Update assessment on store:
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      } catch (error) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      this.cleanAssessmentData();
      this.$root.$emit("save-new-assessment");
    },
  },
});
