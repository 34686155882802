















import Vue from "vue";
import { IStepItem } from "@/modules/self-assessment/types/step-item.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";

export default Vue.extend({
  name: "CategoryColorBar",

  props: {
    /**
     * Allows to mark a step as active.
     */
    activeStep: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    steps(): Array<IStepItem> {
      return this.categories.map((item: ICategory) => ({
        value: item.id,
        label: item.name.replace(" and ", " & "),
        color: item.color,
      })) as Array<IStepItem>;
    },

    /**
     * Available levels.
     */
    levels(): Array<ICategoryLevelSelection> {
      const viralLevelState = this.$store.state.viralLevel as IViralLevelState;
      return viralLevelState.levels;
    },

    /**
     * Retrieves an array with all checked categories.
     */
    checkedSteps(): Array<number> {
      return this.levels
        .filter((record: ICategoryLevelSelection) => record.level > 0)
        .map((record: ICategoryLevelSelection) => record.category);
    },
  },

  methods: {
    /**
     * Checks if the given step is part of the set of
     * current selected steps.
     */
    isStepChecked(step: IStepItem): boolean {
      return this.checkedSteps.includes(step.value);
    },
  },
});
