












































































import Vue from "vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";

import SupporterCriteriaList from "@/modules/profile/components/supporter-criteria-list/supporter-criteria-list.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";

import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default Vue.extend({
  name: "SupporterCriteriaPanel",

  components: {
    SupporterCriteriaList,
    PanelEmptyState,
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    isSupporterLoading(): boolean {
      return this.$store.get("profile/supporter/loading");
    },

    criteria(): Array<IMatchingCriteria> | null {
      return this.supporter && this.supporter.criteria
        ? this.supporter.criteria
        : [];
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    isMatchingDisabled(): boolean {
      return !this.$features.isEnabled("match");
    },
  },

  methods: {
    onClickEditCriteria() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },

    onClickGetStarted() {
      return this.isMatchingDisabled
        ? this.onClickEditCriteria()
        : this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
