



































































































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";

import {
  formatMultiSectorsSelection,
  IGroupedSectorsPayload,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  IGroupedLocationsPayload,
  IMultiLocationsPayload,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";
import { TranslateResult } from "vue-i18n";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import { ILocationGoogleAutocomplete } from "@/services/data/location/location-search.interface";

export default SupportersFlowMixin.extend({
  name: "CriteriaBasicInterests",

  components: {
    FormPresentationLayout,
    MultiSelector,
    NavigationFooter,
  },

  data() {
    return {
      fields: {
        supporter: {
          sectors: [] as Array<number>,
          grouped_sectors: [] as Array<IGroupedSectorsPayload>,
          grouped_locations: [] as Array<IGroupedLocationsPayload>,
          locations: [] as Array<number>,
          places: [] as Array<ILocationGoogleAutocomplete["place_id"]>,
        },

        meta: {
          locations: [] as Array<IMultiSelection>,
          sectors: [] as Array<IMultiSelection>,
          hasSpecificLocations: false,
        },
      },
    };
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.criteriaBasicInterests.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.criteriaBasicInterests");
    },
  },

  created() {
    if (this.flowData) {
      this.fields.supporter = {
        ...this.fields.supporter,
        ...this.flowData.supporter,
      };

      this.restoreMetadata();
    }
  },

  watch: {
    "fields.meta": {
      deep: true,
      handler() {
        this.updateBasicInterestsSubmitState();
      },
    },

    "fields.meta.hasSpecificLocations": {
      handler(value: boolean) {
        // If no specific location, we should not pass any location in the meta data
        if (!value) {
          this.fields.meta.locations = [];
        }

        // If change specific locations and locations is empty, won't shown validation warning but must persist disabled submission
        if (value && !this.fields.meta.locations.length) {
          (this as any).isSubmissionDisabled = true;
          return;
        }

        // If not specific locations, validate to prevent blocking submission
        this.currentForm.validate();
      },
    },
  },

  mounted() {
    this.currentForm.validate();
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    restoreMetadata() {
      // If there is a meta for locations and/or sectors, restore it
      if (this.flowData.meta?.locations?.length) {
        this.fields.meta.locations = [...this.flowData.meta.locations];
        this.fields.meta.hasSpecificLocations = true;
      } else {
        this.fields.meta.hasSpecificLocations = false;
      }

      if (this.flowData.meta?.sectors) {
        this.fields.meta.sectors = [...this.flowData.meta.sectors];
      }
    },

    onInterestsChange() {
      this.currentForm.validate();
    },

    updateBasicInterestsSubmitState() {
      this.updateSubmitState();

      // Delete previous values
      delete this.fields.supporter.grouped_sectors;
      delete this.fields.supporter.sectors;
      delete this.fields.supporter.grouped_locations;
      delete this.fields.supporter.places;

      // If specific locations, divide location's selection into grouped locations and google places
      const locations = (
        this.fields.meta?.hasSpecificLocations
          ? formatMultiLocationsSelection(this.fields.meta?.locations)
          : {
              places: [],
              locations: [],
            }
      ) as IMultiLocationsPayload;

      // Format data
      this.fields.supporter = {
        ...this.fields.supporter,

        ...locations,

        // Divide sector's selection into sectors and grouped_sectors
        ...formatMultiSectorsSelection(this.fields.meta?.sectors),
      };
    },
  },
});
