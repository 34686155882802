




import Vue from "vue";

import { HOME } from "@/services/router/router-names";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";

export default Vue.extend({
  name: "AuthAdminAsUser",

  computed: {
    isLoggedIn(): boolean {
      return this.$user.isLogged();
    },
  },

  async created() {
    if (this.isLoggedIn) {
      await this.$store.dispatch(EAuthActions.LOGOUT, {
        willRedirect: false,
      });
    }

    this.validateAdmin();
  },

  methods: {
    /**
     * Make an API request to validate admin as user session by company access_hash
     *
     * If the token is correct just redirect to the profile
     * with a session set.
     */
    async validateAdmin() {
      const validationHash = this.$route.params.hash as string;

      try {
        await this.$store.dispatch(
          EAuthActions.LOGIN_ADMIN_AS_USER,
          validationHash,
        );
      } catch (error) {
        this.$router.replace({ name: HOME });
      }
    },
  },
});
