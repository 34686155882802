

























































































import Vue from "vue";
import {
  generateRequiredValidator,
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

import { ElForm } from "element-ui/types/form";
import { resendEmailProvider } from "@/modules/authentication/services/data/resend-email/resend-email.provider";
import { emailChangeProvider } from "@/modules/authentication/services/data/email-change/email-change.provider";

export default Vue.extend({
  name: "AccountSettingsEmail",

  data() {
    return {
      emailFormEl: null as null | ElForm,
      isSubmissionDisabled: true,
      emailChangeRequested: false,
      resentEmailConfirmation: false,
      isLoading: false,
      newEmail: "",
      fields: {
        email: this.$store.get("auth/user.email"),
        password: "",
      },
      rules: {
        email: generateEmailValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.newEmail",
          true,
        ),

        password: generateRequiredValidator(
          this,
          "profile.accountSettingsModal.changeEmail.fields.password",
        ),
      },
    };
  },

  computed: {
    userEmail(): string {
      return this.$store.get("auth/user.email");
    },

    emailFormFields(): IFormField[] | null {
      return this.emailFormEl
        ? ((this.emailFormEl as any).fields as IFormField[])
        : null;
    },

    emailHasChanged(): boolean {
      return this.fields.email !== this.userEmail;
    },
  },

  mounted() {
    (this as any).emailFormEl = this.$refs.email;
  },

  methods: {
    updateSubmitState() {
      this.isSubmissionDisabled = !allFormFieldsValid(
        this.emailFormEl,
        this.rules,
      );
    },

    cancelEmailChange() {
      this.emailFormEl?.resetFields();
      this.updateSubmitState();
    },

    triggerSuccessMessage() {
      this.$message({
        message: this.$t(
          "profile.accountSettingsModal.changeEmail.successMessage",
        ) as string,
        type: "success",
        customClass: "is-full",
      });
    },

    triggerErrorMessage() {
      this.$message({
        message: this.$t(
          "profile.accountSettingsModal.changePassword.errorMessage",
        ) as string,
        type: "error",
        customClass: "is-full",
      });
    },

    async updateEmail() {
      this.isLoading = true;

      try {
        await emailChangeProvider.create(this.fields);

        this.triggerSuccessMessage();
        this.emailChangeRequested = true;
        this.newEmail = this.fields.email;
        this.emailFormEl?.resetFields();
      } catch (error) {
        this.triggerErrorMessage();
      }

      this.isLoading = false;
      this.updateSubmitState();
    },

    async resendEmailClickHandler() {
      this.isLoading = true;

      try {
        await resendEmailProvider.create({
          email: this.newEmail,
        });

        this.triggerSuccessMessage();
        this.resentEmailConfirmation = true;
        this.emailChangeRequested = true;
      } catch (error) {
        this.triggerErrorMessage();
      }

      this.isLoading = false;
    },
  },
});
