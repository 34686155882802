















import Vue from "vue";

export default Vue.extend({
  name: "PxMessageBar",

  props: {
    visible: {
      type: Boolean,
      default: true,
    },

    center: {
      type: Boolean,
      default: true,
    },

    closable: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: String,
      default: "info",
    },

    backgroundColor: {
      type: String,
      default: "#7bb7ff",
    },

    textColor: {
      type: String,
      default: "#fff",
    },
  },

  computed: {
    messageBarStyles(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        justifyContent: this.center ? "center" : "flex-start",
      };
    },
  },

  methods: {
    closeBar() {
      this.$emit("update:visible", false);
    },
  },
});
