








import Vue from "vue";

export default Vue.extend({
  name: "PxQuestionaryNumeric",

  props: {
    value: {
      type: Object as () => any,
      default: () => null,
    },

    isValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: { value: "" },
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const currentValue =
            this.innerValue.value === ""
              ? ""
              : parseFloat(this.innerValue.value.replace(/,/g, ""));

          if (newVal.value !== currentValue) {
            this.innerValue = this.value;
          }
        }
      },
    },

    innerValue: {
      deep: true,
      handler(newVal) {
        const newValues = {
          value:
            newVal.value === ""
              ? ""
              : parseFloat(newVal.value.replace(/,/g, "")),
        };
        this.emitValueChanges(newValues);
        this.validateField(newValues);
      },
    },
  },

  methods: {
    /**
     * Emit value changes to the parent component.
     */
    emitValueChanges(values: any) {
      this.$emit("input", values);
      this.$emit("change", values);
    },

    validateField(values: any) {
      const isValid = values.value !== null && !!values.value;

      this.$emit("update:isValid", isValid);
    },
  },
});
