























import Vue from "vue";
import { IInvestorsPanelAccordionItem } from "@/modules/profile/components/investors-panel/investors-panel-accordion.interface";

export default Vue.extend({
  name: "InvestorsPanelAccordion",

  props: {
    items: {
      type: Array as () => IInvestorsPanelAccordionItem[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },
});
