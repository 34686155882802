


















































































































































import Vue from "vue";

import LevelRangeSlider from "@/modules/supporters/components/level-range-slider/level-range-slider.vue";
import LevelsDescription from "@/modules/supporters/components/levels-description/levels-description.vue";
import CompanyRequirements from "@/modules/supporters/components/company-requirements/company-requirements.vue";
import MobileFooterNav from "@/modules/supporters/components/mobile-footer-nav/mobile-footer-nav.vue";
import RangeLevelsModal from "@/modules/supporters/components/range-levels-modal/range-levels-modal.vue";
import { setTimeout } from "timers";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "AssessmentDesktop",

  static: {
    backButton: {
      icon: "arrow--left-dark",
      size: EPxButtonSize.EXTRA_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  components: {
    LevelRangeSlider,
    LevelsDescription,
    CompanyRequirements,
    MobileFooterNav,
    RangeLevelsModal,
  },

  props: {
    currentCategory: {
      type: Object as () => ICategory,
      default: null,
    },

    rangeRestriction: {
      type: Number,
      default: null,
    },

    selectedStep: {
      type: Object as () => any,
      default: null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    selectedCategory: {
      type: Number,
      default: () => null,
    },

    levels: {
      type: Array as () => any[],
      default: () => [],
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },

    onSave: {
      type: Function as () => any,
      default: () => null,
    },

    showIntegratedFooter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      defaultStartLevel: 1,
      selectedStepLevel: [1, 1],
      currentYear: new Date().getFullYear(),
      rangeLevelsModalVisible: false,
    };
  },

  computed: {
    levelAtStart(): boolean {
      return this.currentSelectedStepLevel === this.defaultStartLevel;
    },

    currentCategoryIsTypeRange(): boolean {
      return (
        (!!this.currentCategory &&
          this.currentCategory.value_type === "RANGE") ||
        false
      );
    },

    currentSelectedStepLevel(): number {
      if (this.currentCategoryIsTypeRange) {
        return this.selectedStep.level[1] || this.defaultStartLevel;
      }

      return this.selectedStep.level !== 0
        ? this.selectedStep.level
        : this.defaultStartLevel;
    },

    firstSelectedRangeLevel(): number {
      return this.currentCategoryIsTypeRange
        ? this.selectedStep.level[0]
        : this.selectedStep.level;
    },

    rangeTipElement(): HTMLElement {
      return this.$refs.rangeLimitTip as HTMLElement;
    },

    sliderTipIcon(): string {
      return "/img/arrow-tip.svg";
    },

    hasPreviousPage(): boolean {
      return !!window.history.length;
    },
  },

  watch: {
    levels: {
      immediate: true,
      handler(values: Array<ICategoryLevelSelection>) {
        const levelRange = values.find(
          (level: ICategoryLevelSelection) =>
            level.category === this.currentCategory?.id,
        )?.level as Array<number>;

        this.selectedStepLevel = levelRange || this.selectedStepLevel;
      },
    },
  },

  methods: {
    goToPreviousPage() {
      const previousPage = this.$route.meta.previousPage;
      const hasPreviousPage = !!previousPage;

      hasPreviousPage
        ? this.$router.push({ name: previousPage })
        : this.$router.back();
    },

    rangeTipToggleShakeAnimation() {
      this.rangeTipElement.classList.toggle("shake-animation");
    },

    rangeLimitOverreachedHandler() {
      this.rangeTipToggleShakeAnimation();

      setTimeout(this.rangeTipToggleShakeAnimation, 1000);
    },

    rangeTipButtonClickHandler() {
      this.rangeLevelsModalVisible = true;
    },
  },
});
