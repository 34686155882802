






























import Vue from "vue";

import PxMenu from "@/components/px-menu/px-menu.vue";

// Distance that the user needs to scroll to collapse the header
const COLLAPSE_DISTANCE = 47;

// Store the last computed scroll position. This will be used to
// decide if the menu should be collapsed of fully presented.
let scrollState = 0;
let scrollDelta = 0;
let isGoingDown = true;

// Distance in milliseconds that a scroll event must occur
const DEBOUNCE_TIME = 300;

// Informs it's currently waiting for a scroll event to be called
let isWaitingCall = false;

export default Vue.extend({
  name: "PxTabBar",

  components: {
    PxMenu,
  },

  props: {
    /**
     * List of items to add to the navbar.
     *
     * Example:
     * ```
     * [
     *  {
     *    key: "about",
     *    label: "About",
     *    to: HOME,
     *  }
     * ]
     * ```
     */
    items: {
      type: Array as () => any[],
      default: () => [],
    },

    /**
     * Current selected option.
     */
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isSmall: false,
      innerValue: "",
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.innerValue = newVal;
      },
    },

    innerValue(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.changeTabSize(false);
      this.$emit("input", newVal);
    },
  },

  mounted() {
    document.addEventListener("scroll", this.onScroll.bind(this), {
      capture: true,
      passive: true,
    });
  },

  updated() {
    // Launch resize to reset tab size
    this.handleTabResize();
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll.bind(this));
  },

  methods: {
    /**
     * Determines if the tolerance has been exceeded.
     */
    toleranceExceeded(): boolean {
      return Math.abs(scrollDelta) >= COLLAPSE_DISTANCE;
    },

    /**
     * Updates scroll delta value based on inverted scroll direction
     */
    updateScrollDelta(immediateDelta: number) {
      // Reset scroll delta when the user changes the scroll direction
      return (isGoingDown && scrollDelta < 0) ||
        (!isGoingDown && scrollDelta > 0)
        ? -scrollDelta
        : immediateDelta;
    },

    changeTabSize(isSmall: boolean) {
      const thisElem = this as any;
      thisElem.isSmall = isSmall;
      this.$root.$emit("tab-bar-small", isSmall);
    },

    handleTabResize() {
      const thisElem = this as any;
      const currentScroll = window.pageYOffset;

      // Calculate scroll direction and scroll delta since last direction change
      const immediateDelta = scrollState - Math.abs(currentScroll);
      isGoingDown = immediateDelta > 0;
      scrollDelta += thisElem.updateScrollDelta(immediateDelta);

      // Save current scroll position and the scroll direction
      scrollState = currentScroll;

      // If the scroll is near the top the tab bar must expand
      if (currentScroll <= COLLAPSE_DISTANCE) {
        thisElem.changeTabSize(false);
        return;
      }

      const isToleranceExceeded = thisElem.toleranceExceeded();
      const isSmall = thisElem.isSmall;

      if (!(isToleranceExceeded && isSmall === isGoingDown)) {
        return;
      }

      thisElem.changeTabSize(!isGoingDown);
    },

    /**
     * Handle browser scroll event call.
     *
     * Use a throttle strategy to minimize the performance costs.
     */
    onScroll() {
      if (isWaitingCall) {
        return;
      }

      isWaitingCall = true;
      setTimeout(() => {
        const thisElem = this as any;
        thisElem.handleTabResize();
        isWaitingCall = false;
      }, DEBOUNCE_TIME);
    },
  },
});
