






























































































import Vue from "vue";
import MilestoneStageStatusSelector from "@/modules/milestone-planner/components/milestone-stage-status-selector/milestone-stage-status-selector.vue";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default Vue.extend({
  name: "MilestoneOnboardingDialog",

  components: {
    MilestoneStageStatusSelector,
  },

  props: {
    visibility: { type: Boolean, required: true },
    isVisitor: { type: Boolean, default: false },
  },

  data() {
    return {
      currentPage: 1,
      pageCount: 6,
      pagesWithoutSelector: 3,
    };
  },

  computed: {
    dialogSubtitleIndex(): number {
      return this.currentPage < this.pagesWithoutSelector
        ? this.currentPage - 1
        : 2;
    },

    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },

  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
  },
});
