















import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL } from "@/modules/affiliates/constants";

export default Vue.extend({
  name: "MatchDetailsModalFooter",

  static: {
    editAnswersButton: {
      size: EPxButtonSize.SMALL,
    },

    backButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    editAnswersButtonProps(): any {
      return {
        ...this.$options.static.editAnswersButton,
        label: this.$t("matching.matchDetails.buttons.editAnswers") as string,
      };
    },

    backButtonProps(): any {
      return {
        ...this.$options.static.backButton,
        label: this.$t("common.back") as string,
      };
    },
  },

  methods: {
    onBackClick() {
      this.$emit("close-modal");
    },

    onEditAnswersClick() {
      window.open(AFFILIATE_SUPPORTER_PROGRAM_UPDATE_URL);
    },
  },
});
