import Vue from "vue";

/**
 * This filter removes from the URL:
 * -> Scheme and www subdomain
 * -> Unnecessary forward slash at the end of a URL
 */
Vue.filter("presentationUrl", (url: string) => {
  const matchSchemeAndSubdomain = /^((ftp|http|https)?:\/\/?)?((www\.)?)/;
  const matchEndingForwardSlash = /\/$/;

  return url
    .replace(matchSchemeAndSubdomain, "")
    .replace(matchEndingForwardSlash, "")
    .trim();
});
