

































import Vue from "vue";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

export default Vue.extend({
  props: {
    affiliate: {
      type: Object as () => IAffiliate,
      required: true,
    },
    submissionDate: {
      type: String,
      required: true,
    },
  },

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  methods: {
    externalLinkClickHandler() {
      if (this.affiliate && this.affiliate.website) {
        window.open(this.affiliate.website);
      }
    },

    onPrintButtonClick() {
      window.print();
    },
  },
});
