
































































import Vue from "vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";

import { passwordRecoverProvider } from "@/modules/authentication/services/data/password-recover/password-recover.provider";
import { IPasswordRecover } from "@/modules/authentication/services/data/password-recover/password-recover.interface";

import { ErrorsManager } from "@/services/errors-manager";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";

export default Vue.extend({
  name: "AuthBasePasswordRecover",

  data() {
    return {
      loading: false,
      hasServerError: false,
      submissionDisabled: true,

      fields: {
        email: "",
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.passwordRecover.errors.emptyEmail",
          true,
        ),
      },

      errors: new ErrorsManager(),
    };
  },

  computed: {
    recoverPasswordFormRef(): HTMLElement {
      return this.$refs.recoverPasswordForm as HTMLElement;
    },
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(
        this.recoverPasswordFormRef,
        this.rules,
      );
    },

    async submitForm() {
      const dataToSend = {
        email: this.fields.email,
      } as IPasswordRecover;

      this.hasServerError = false;
      this.loading = true;

      try {
        await passwordRecoverProvider.create(dataToSend);
      } catch (error) {
        this.hasServerError = true;
      } finally {
        this.loading = false;
      }

      this.$message({
        message: this.$t("authentication.passwordRecover.successMessage", {
          email: this.fields.email,
        }) as string,
        type: "success",
        customClass: "is-full",
      });

      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
