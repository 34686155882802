










import Vue from "vue";

export default Vue.extend({
  name: "LastAssessmentDate",

  props: {
    date: {
      type: String,
      required: true,
    },
  },
});
