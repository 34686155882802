var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-wizard",class:{ 'is-disabled': _vm.disabled }},[_c('div',{staticClass:"category-wizard__container"},[_c('div',{staticClass:"category-wizard__steps"},[_c('div',{staticClass:"category-wizard__steps-container"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:step.value,staticClass:"category-wizard-step is-clickable",class:{
            'is-active': _vm.currentStep === index,
            'is-checked': _vm.isStepChecked(step),
          },on:{"click":function($event){return _vm.stepClickHandler(index)}}},[_c('p',{staticClass:"category-wizard-step__label"},[_vm._v(_vm._s(step.label))]),_c('div',{staticClass:"category-wizard-step__circles"},[_c('div',{staticClass:"category-wizard-step__circle category-wizard-step__circle--point"}),_c('div',{staticClass:"category-wizard-step__circle category-wizard-step__circle--bigger is-clickable",class:{
                'is-visible':
                  index <= _vm.currentStep || index <= _vm.higherCheckedIndex,
                'is-checked': _vm.isStepChecked(step),
                'is-active': _vm.currentStep === index,
              },style:(_vm.stepCircleStyle(step))})])])}),0),_c('div',{staticClass:"category-wizard__progress",style:(_vm.progressStyle)}),_c('div',{staticClass:"category-wizard__final-step"},[_vm._v(" "+_vm._s(_vm.$t("selfAssessment.assessment.wizard.finalStep"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }