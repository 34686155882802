

















































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

import { TranslateResult } from "vue-i18n";

export default SupportersFlowMixin.extend({
  name: "CriteriaStartPage",

  components: {
    FormPresentationLayout,
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.criteriaStartPage.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.criteriaStartPage");
    },
  },
});
