





























import Vue from "vue";
import PxLevel from "@/components/px-level/px-level.vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";

export default Vue.extend({
  name: "MilestoneLevel",

  components: {
    PxLevel,
  },

  props: {
    level: {
      type: Number,
      required: true,
    },
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    categoryLevelDescription(): string {
      const selectedCategoryLevel = this.category.milestones.find(
        (milestone) => milestone.level === this.level,
      );
      return selectedCategoryLevel ? selectedCategoryLevel.description : "";
    },
  },
});
