
import Vue from "vue";
import { RadioGroup } from "element-ui";

export default Vue.extend({
  name: "PxRadioGroup",
  extends: RadioGroup,
  mounted() {
    this.$el.addEventListener("click", (e: Event) => {
      if ((e.target as HTMLElement).nodeName == "INPUT") {
        if ((e.target as HTMLInputElement).value == this.value) {
          this.$emit("input");
        }
      }
    });
  },
});
