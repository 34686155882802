import { RouteConfig } from "vue-router";
import multiguard from "vue-router-multiguard";

import { removeTabsItems } from "@/services/router/mobile-tab-menu";

import {
  ROUTE_MILESTONE_PLANNER_OVERVIEW,
  ROUTE_MILESTONE_PLANNER_DETAIL,
  ROUTE_MILESTONE_PLANNER_FULLGRID,
  ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
  ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS,
  ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS,
} from "./routes-names";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import MilestonePlannerOverview from "@/modules/milestone-planner/views/milestone-overview.view.vue";
import MilestonePlannerDetail from "@/modules/milestone-planner/views/milestone-detail.view.vue";
import MilestonePlannerFullGrid from "@/modules/milestone-planner/views/milestone-full-grid.view.vue";
import MilestonePlannerForVisitorsView from "@/modules/milestone-planner/views/milestone-planner-for-visitors.view.vue";
import VisitorsMilestonePlannerOverview from "@/modules/milestone-planner/views/visitors-milestone-overview.view.vue";
import VisitorsMilestonePlannerDetail from "@/modules/milestone-planner/views/visitors-milestone-detail.view.vue";
import VisitorsMilestonePlannerFullGrid from "@/modules/milestone-planner/views/visitors-milestone-full-grid.view.vue";

import { milestonesResolver } from "./guards/milestones-resolver/milestones.resolver";
import { fullGridResolver } from "./guards/milestones-resolver/full-grid.resolver";
import { visitorMilestonePlannerGuard } from "./guards/visitor-milestone-planner-guard/visitor-milestone-planner.guard";
import { milestonePlannerOwnerGuard } from "./guards/milestone-planner-owner-guard/milestone-planner-owner.guard";

export const routes: Array<RouteConfig> = [
  {
    path: "/milestone-planner",
    component: AuthenticatedWithNavigationView,
    beforeEnter: multiguard([
      milestonePlannerOwnerGuard,
      milestonesResolver,
      removeTabsItems,
    ]),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_MILESTONE_PLANNER_OVERVIEW,
        component: MilestonePlannerOverview,
      },
      {
        path: "category/:categoryId/level/:levelValue",
        name: ROUTE_MILESTONE_PLANNER_DETAIL,
        component: MilestonePlannerDetail,
        meta: {
          shouldBeRerendered: false,
        },
      },
      {
        path: "full-grid",
        name: ROUTE_MILESTONE_PLANNER_FULLGRID,
        component: MilestonePlannerFullGrid,
        beforeEnter: fullGridResolver,
      },
    ],
  },
  {
    path: "/milestone-planner/:uid",
    component: MilestonePlannerForVisitorsView,
    beforeEnter: visitorMilestonePlannerGuard,
    children: [
      {
        path: "",
        name: ROUTE_MILESTONE_PLANNER_OVERVIEW_VISITORS,
        component: VisitorsMilestonePlannerOverview,
      },
      {
        path: "category/:categoryId/level/:levelValue",
        name: ROUTE_MILESTONE_PLANNER_DETAIL_VISITORS,
        component: VisitorsMilestonePlannerDetail,
        meta: {
          shouldBeRerendered: false,
        },
      },
      {
        path: "full-grid",
        component: VisitorsMilestonePlannerFullGrid,
        name: ROUTE_MILESTONE_PLANNER_FULLGRID_VISITORS,
        beforeEnter: fullGridResolver,
      },
    ],
  },
];
