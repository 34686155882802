var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-panel",class:[
    ("px-panel--" + _vm.theme),
    {
      'px-panel--with-icon': !!_vm.icon,
      'px-panel--no-borders': _vm.noBorders,
      'px-panel--scrollable': _vm.scrollable,
      'px-panel--has-shadow': _vm.showShadow,
    } ]},[_c('div',{staticClass:"px-panel__container"},[(_vm.showHeader)?_c('div',{staticClass:"px-panel__header"},[_vm._t("header",function(){return [(_vm.icon)?_c('PxIcon',{attrs:{"name":_vm.icon}}):_vm._e(),_c('h1',{staticClass:"px-panel__title"},[_vm._v(_vm._s(_vm.title))])]})],2):_vm._e(),_c('div',{staticClass:"px-panel__body",style:(_vm.customBodyStyle)},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{staticClass:"px-panel__footer"},[_vm._t("footer")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }