























































import Vue, { VueConstructor } from "vue";
import emitter from "element-ui/src/mixins/emitter";

import { sectorProvider } from "@/services/data/sector/sector.provider";
import { ISector } from "@/services/data/sector/sector.interface";

let requestTimer: any = null;

interface ISectorOption {
  value: number;
  label: string;
}

interface ISectorSelectorTypes {
  listOfSectors: null | Array<ISectorOption>;
  tempSector: null | ISectorOption;
  sectorSelectLoading: boolean;
  selectedSectors: Array<ISectorOption>;
  searchSectorsRequest: (query: string) => void;
}

export default (Vue as VueConstructor<Vue & ISectorSelectorTypes>).extend({
  name: "SectorsSelector",

  mixins: [emitter],

  props: {
    value: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    labelCopy: {
      type: String,
      default: "selfAssessment.companyInfoModal.fields.sector",
    },
  },

  data() {
    return {
      listOfSectors: null as null | Array<ISectorOption>,
      tempSector: null as null | ISectorOption,
      sectorSelectLoading: false,

      // Will stores the list of selected sectors
      selectedSectors: [] as Array<ISectorOption>,
    };
  },

  watch: {
    selectedSectors(newVal: Array<ISectorOption>) {
      const ids = newVal.map((entry: ISectorOption) => entry.value);
      this.$emit("input", ids);

      (this as any).dispatch("ElFormItem", "el.form.change", [ids]);
    },
  },

  methods: {
    /**
     * Allow to set the active sectors.
     */
    setSectors(sectors: Array<ISector>) {
      this.selectedSectors = sectors.map((entry: ISector) => ({
        label: entry.name,
        value: entry.id,
      }));
    },

    async searchSectorsRequest(query: string) {
      // Never make an API request when the query is null
      // or a string with less than 2 characters.
      if (!query || query.length < 2) {
        this.listOfSectors = [];
        return;
      }

      this.sectorSelectLoading = true;

      const data = (await sectorProvider.list({
        filter: query,
      })) as Array<ISector>;

      // ElAutocomplete component needs a value fields,
      // that will be used as the selected value.
      const allSectors = data.map(
        (sector: ISector) =>
          ({
            value: sector.id,
            label: sector.name,
          } as ISectorOption),
      );
      // Get current selected sectors ids
      const selectedIds = this.selectedSectors.map(
        (item: ISectorOption) => item.value,
      );
      // Remove selected sectors from all sectors
      this.listOfSectors = allSectors.filter(
        (entry: ISectorOption) => !selectedIds.includes(entry.value),
      );

      this.sectorSelectLoading = false;
    },

    /**
     * Search for sectors on the API.
     */
    async searchSectors(query: string) {
      if (requestTimer) {
        clearTimeout(requestTimer);
      }

      requestTimer = setTimeout(() => this.searchSectorsRequest(query), 400);
    },

    /**
     * When a new sector is selected it is pushed into the
     * array of selected sectors and the autocomplete fill
     * cleared.
     */
    onSectorSelect(selectedValue: ISectorOption) {
      this.selectedSectors.push(selectedValue);
      this.tempSector = null;
      this.listOfSectors = [];

      this.$emit("change", selectedValue);
    },

    /**
     * Remove the given sector from the list of selected sectors.
     */
    onClickRemoveSector(sectorId: number) {
      const indexToRemove = this.selectedSectors.findIndex(
        (sector: ISectorOption) => sector.value === sectorId,
      );
      this.selectedSectors.splice(indexToRemove, 1);
    },

    blurHandler(event: any) {
      this.$emit("blur", event);
      (this as any).dispatch("ElFormItem", "el.form.change", [
        this.selectedSectors,
      ]);
    },

    handleVisibleChange() {
      const elSelect = this.$refs.select as any;

      // TEMPORARY FIX: Show select menu while input is focused
      if (!elSelect.visible && elSelect.$refs.reference.focused) {
        elSelect.toggleMenu();
      }
    },
  },
});
