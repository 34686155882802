













































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";

import { TranslateResult } from "vue-i18n";
import { AffiliateState } from "@/modules/supporters/services/store/affiliate/affiliate.module";

export default SupportersFlowMixin.extend({
  name: "StartingPointAffiliate",

  components: {
    FormPresentationLayout,
    LangSwitcher,
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.criteriaStartPage.imageLink");
    },

    affiliateName(): string {
      return this.$store.getters[AffiliateState.Getter.VALUE]?.name || "";
    },

    isLogged(): boolean {
      return !!this.$store.get("auth/user");
    },

    withTeamSection(): boolean {
      return (
        (this.$store.getters[AffiliateState.Getter.VALUE]?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.startPageAffiliate");
    },

    viewCopySteps(): Array<string> {
      return [...(this.$t("supporters.view.startPageAffiliate.steps") as any)];
    },

    viewCopyStepsWithoutTeamSection(): Array<string> {
      return this.viewCopySteps.filter((step, index) => index !== 2);
    },

    stepsToDisplay(): Array<string> {
      return !this.withTeamSection
        ? this.viewCopyStepsWithoutTeamSection
        : this.viewCopySteps;
    },

    viewContentDescription(): string {
      return this.$t("supporters.view.startPageAffiliate.contentDescription", {
        affiliate: this.affiliateName,
      }) as string;
    },
  },
});
