
















































































































import SignInModalMixin from "@/mixins/sign-in-modal.mixin";

export default SignInModalMixin.extend({
  name: "SignInModal",
});
