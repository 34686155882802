







































import Vue from "vue";
import { EMetaGetters } from "@/services/store/meta/meta-types";
import { AUTHENTICATION_META_REGISTRATION_EMAIL } from "@/modules/authentication/constants";

export default Vue.extend({
  name: "AuthBaseSignUpSuccess",

  data() {
    return {
      currentStep: 2,

      items: [
        {
          label: this.$t("authentication.signup.steps.company"),
          value: "company",
        },
        {
          label: this.$t("authentication.signup.steps.account"),
          value: "user",
        },
      ],
    };
  },

  computed: {
    registrationEmail(): string {
      return this.$store.getters[EMetaGetters.GET](
        AUTHENTICATION_META_REGISTRATION_EMAIL,
      );
    },
  },
});
