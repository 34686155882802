import { render, staticRenderFns } from "./company-requirements.vue?vue&type=template&id=5b247d83&scoped=true&"
import script from "./company-requirements.vue?vue&type=script&lang=ts&"
export * from "./company-requirements.vue?vue&type=script&lang=ts&"
import style0 from "./company-requirements.vue?vue&type=style&index=0&id=5b247d83&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b247d83",
  null
  
)

export default component.exports