























import Vue from "vue";

import Submission from "@/modules/supporters/components/submission/submission.vue";
import ResultsBoard from "@/modules/supporters/components/results-board/results-board.vue";
import FeaturesShowcase from "@/modules/supporters/components/features-showcase/features-showcase.vue";

import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";
import { affiliateProgramProgramSubmissionProvider } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.provider";
import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";

export default Vue.extend({
  name: "SupporterResults",
  components: {
    Submission,
    ResultsBoard,
    FeaturesShowcase,
  },

  data() {
    return {
      submission: null as null | IAffiliateProgramSupporterSubmission,
      resultsVariation: null,
      modalVisibility: false,
    };
  },

  computed: {
    uidFromRoute(): string | null {
      return this.$route.params?.uid;
    },

    uidFromSubmission(): string | null {
      return this.$store.getters[SupporterFlowState.Getter.VALUE].options
        .submissionUid;
    },

    affiliate(): IAffiliate {
      return this.$store.state.affiliate.value as IAffiliate;
    },

    isLoading(): boolean {
      return (
        this.$store.getters[SupporterFlowState.Getter.IS_LOADING] ||
        (this.showSubmissionView && !this.submission)
      );
    },

    hasError(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.HAS_ERROR];
    },

    /**
     * Check if the user is assessing by an Affiliate.
     */
    isDefaultAffiliate(): boolean {
      return !this.affiliate || this.affiliate.default_flow;
    },

    /**
     * Define a theme variation for this view.
     */
    resultsVariationTheme(): { [key: string]: boolean } {
      return {
        "supporter-results--affiliate": !this.isDefaultAffiliate,
      };
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    isLoggedSubmissionFlow(): boolean {
      return this.isUserLogged && !!this.uidFromSubmission;
    },

    showSubmissionView(): boolean {
      return this.isLoggedSubmissionFlow || !!this.uidFromRoute;
    },
  },

  async created() {
    // Basic results without submission page, carry on
    if (!this.showSubmissionView) {
      return;
    }

    // For Supporters Affiliate flow, where user is logged
    if (this.isLoggedSubmissionFlow) {
      // Applied "" for linting reasons
      await this.fetchSubmission(this.uidFromSubmission || "");
      return;
    }

    // For outside flow, both on logged or public view
    // Applied "" for linting reasons
    await this.fetchSubmission(this.uidFromRoute || "");
  },

  methods: {
    async fetchSubmission(submissionUid: string) {
      try {
        this.submission = await affiliateProgramProgramSubmissionProvider.get(
          submissionUid,
        );
      } catch {
        this.notifyErrorWhileFetchingSubmission();
      }
    },

    notifyErrorWhileFetchingSubmission() {
      this.$message({
        message: this.$t("program.submission.errors.loading") as string,
        type: "error",
        duration: 10000,
        customClass: "is-full",
      });
    },
  },
});
