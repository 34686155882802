









































import Vue from "vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import {
  EAffiliatesActions,
  EAffiliatesMutations,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  name: "AffiliateFooterBar",

  static: {
    saveAsDraftButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
    },

    previousButton: {
      type: EPxButtonType.LINK,
    },

    nextButton: {
      size: EPxButtonSize.MEDIUM,
    },
  },

  props: {
    isLastPage: {
      type: Boolean,
      default: false,
    },

    date: {
      type: String,
      default: "",
    },

    isSaving: {
      type: Boolean,
      default: false,
    },

    submitDisabled: {
      type: Boolean,
      default: false,
    },

    questionBundleAffiliate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("common.saveAsDraft") as string,
      };
    },

    previousButtonProps(): any {
      return {
        ...this.$options.static.previousButton,
        label: this.$t("common.previous") as string,
      };
    },

    nextButtonProps(): any {
      return {
        ...this.$options.static.nextButton,
        type:
          this.isLastPage && this.questionBundleAffiliate
            ? EPxButtonType.GREEN
            : EPxButtonType.BLUE,
        label: this.isLastPage
          ? this.$t("common.submit")
          : (this.$t("common.next") as string),
      };
    },

    shouldShowElements(): boolean {
      return (
        (this.questionBundleAffiliate && !this.isLastPage) ||
        !this.questionBundleAffiliate
      );
    },
  },

  methods: {
    async saveDraft() {
      this.$store.commit(`affiliates/${EAffiliatesMutations.SET_SAVING}`, true);
      await this.$store.dispatch(EAffiliatesActions.DEBOUNCE_SAVE_DRAFT);
      this.$emit("show-saved-draft-message");
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    goToNextStep() {
      this.$emit("next-step");
    },
  },
});
