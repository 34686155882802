












































import Vue from "vue";

import FeaturesShowcaseCTA from "@/modules/affiliates/components/features-showcase-cta/features-showcase-cta.vue";
import FeaturesShowcasePendingUserCTA from "@/modules/affiliates/components/features-showcase-cta/features-showcase-pending-user-cta.vue";

export default Vue.extend({
  name: "FeaturesShowcase",

  components: {
    FeaturesShowcaseCTA,
    FeaturesShowcasePendingUserCTA,
  },

  computed: {
    featuresList(): any {
      return this.$t("selfAssessment.results.featuresShowcase.list");
    },

    pendingAssessmentActive(): boolean {
      return (
        this.$features.isEnabled("assessmentUserInfoBeforeHand") &&
        this.$user.isPendingUser()
      );
    },
  },
});
