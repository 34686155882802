








































































































































import Vue from "vue";

import moment from "moment";

import AssessmentFluctuation from "@/modules/profile/components/assessment-fluctuation/assessment-fluctuation.vue";
import AssessmentDetails from "@/modules/profile/components/assessment-details/assessment-details.vue";

import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { EAssessmentHistoryActions } from "@/modules/profile/services/store/profile/submodules/assessment-history/assessment-history.types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { PROFILE_META_OPEN_ASSESSMENT_MODAL } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

export default Vue.extend({
  name: "AssessmentPanelHistory",

  components: {
    AssessmentFluctuation,
    AssessmentDetails,
  },

  props: {
    allowLevelUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentAssessment: 0,
      isAssessmentDetailsVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company.data");
    },

    assessments(): Array<IAssessment> {
      return this.$store.get("profile/assessmentHistory.data");
    },

    visibleResults(): Array<IAssessment> {
      return this.assessments.slice(0, 4);
    },

    hiddenResults(): Array<IAssessment> {
      return this.assessments.slice(4, this.assessments.length);
    },

    hasOneAssessment(): boolean {
      return this.visibleResults.length === 1;
    },

    noAssessment(): boolean {
      return this.visibleResults.length === 0;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    /**
     * Return if the current user has permissions to see the assessment results.
     */
    userIsVisitor(): boolean {
      return (
        (!this.$user.isLogged() && !this.isSpecialGuest) ||
        !this.$user.isOwner()
      );
    },
  },

  watch: {
    /**
     * Every time that the company changes reload the
     * list of assessments.
     */
    company: {
      immediate: true,
      handler(newVal: ICompany | null) {
        if (newVal === null) {
          return;
        }

        this.fetchData();
      },
    },
  },

  mounted() {
    this.isAssessmentDetailsVisible = this.$store.get(
      "meta/get",
      PROFILE_META_OPEN_ASSESSMENT_MODAL,
    );

    this.$store.dispatch(
      EMetaActions.REMOVE,
      PROFILE_META_OPEN_ASSESSMENT_MODAL,
    );
  },

  methods: {
    formatedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    assessmentTitle(assessment: IAssessment) {
      const formatedDate = this.formatedDate(assessment.created_at);
      const levelText = this.$t("profile.assessment.historyPanel.level");
      return `${levelText} ${assessment.level.value} - ${formatedDate}`;
    },

    previousAssessmentData(key: number): Array<IAssessmentLevel> {
      const prevKey = key + 1;
      const prevAssessment = this.assessments[prevKey];
      return prevAssessment ? prevAssessment.data : [];
    },

    currentAssessmentData(assessment: IAssessment): Array<IAssessmentLevel> {
      return assessment.data || [];
    },

    /**
     * Fetch the assessment history data.
     */
    fetchData() {
      if (this.company === null) {
        return;
      }

      this.$store.dispatch(EAssessmentHistoryActions.FETCH, this.company.id);
    },

    onClickUpdateLevel() {
      this.$emit("request-new-assessment");
    },

    onClickOpenAssessmentDetails(assessmentIndex: number) {
      this.currentAssessment = assessmentIndex;
      this.isAssessmentDetailsVisible = true;
    },

    isAssessmentFluctuationVisible(
      key: number,
      assessment: IAssessment,
    ): boolean {
      return (
        !!this.previousAssessmentData(key).length &&
        !!this.currentAssessmentData(assessment).length
      );
    },
  },
});
