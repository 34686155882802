





























import Vue from "vue";

import { SUPPORTERS_INVESTING_RANGE_META } from "@/modules/supporters/constants";
import { EMetaGetters, EMetaActions } from "@/services/store/meta/meta-types";
import MobileFooterNav from "@/modules/supporters/components/mobile-footer-nav/mobile-footer-nav.vue";
import MobileLevelRangeSlider from "@/modules/supporters/components/mobile-level-range-slider/mobile-level-range-slider.vue";
import LevelCard from "@/modules/supporters/components/level-card/level-card.vue";
import RangeLevelsModal from "@/modules/supporters/components/range-levels-modal/range-levels-modal.vue";
import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";

export default Vue.extend({
  name: "AssessmentMobile",

  components: {
    LevelCard,
    MobileFooterNav,
    MobileLevelRangeSlider,
    RangeLevelsModal,
  },

  props: {
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    rangeRestriction: {
      type: Number,
      default: null,
    },

    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    selectedCategory: {
      type: Number,
      default: () => null,
    },

    levels: {
      type: Array as () => any[],
      default: () => [],
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },

    onSave: {
      type: Function as () => any,
      required: true,
    },
  },

  data() {
    return {
      defaultStartLevel: 1,
      selectedStepLevel: [1, 1],
      currentYear: new Date().getFullYear(),
      rangeLevelsModalVisible: false,
      currentCategoryIsTypeRange: true,
    };
  },

  computed: {
    currentRange(): Array<number> {
      return (
        this.$store.getters[EMetaGetters.GET](
          SUPPORTERS_INVESTING_RANGE_META,
        ) || this.selectedStepLevel
      );
    },

    levelDescription(): string {
      const description = this.currentCategory.categoryDetails.find(
        (item: any) => item.level.value === this.currentSelectedStepLevel,
      );

      const params = {
        description: description ? description.requirements : "",
        level: this.currentSelectedStepLevel,
        category: this.currentCategory.name,
      };

      return this.$t(
        "supporters.assessment.rangeDescriptionTemplate",
        params,
      ) as string;
    },

    levelAtStart(): boolean {
      return this.currentSelectedStepLevel === this.defaultStartLevel;
    },

    currentSelectedStepLevel(): number {
      if (this.currentCategoryIsTypeRange) {
        return this.selectedStep.level[1] || this.defaultStartLevel;
      }

      return this.selectedStep.level !== 0
        ? this.selectedStep.level
        : this.defaultStartLevel;
    },

    firstSelectedRangeLevel(): number {
      return this.currentCategoryIsTypeRange
        ? this.selectedStep.level[0]
        : this.selectedStep.level;
    },

    isLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },
  },

  watch: {
    levels: {
      immediate: true,
      handler(categoryLevelSelection: Array<ICategoryLevelSelection>) {
        const levelRange = categoryLevelSelection.find(
          (level: ICategoryLevelSelection) =>
            level.category === this.currentCategory?.id,
        )?.level as Array<number>;

        this.selectedStepLevel = levelRange || this.selectedStepLevel;
      },
    },
  },

  mounted() {
    this.selectedStepLevel = this.currentRange;

    this.$store.dispatch(EMetaActions.SET, {
      key: PROFILE_META_ACTIVE_VIEW,
      value: "criteria",
    });
  },

  methods: {
    inputChanged(val: any) {
      this.onLevelChange(val);
    },

    rangeTipButtonClickHandler() {
      this.rangeLevelsModalVisible = true;
    },
  },
});
