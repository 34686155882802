import Vue from "vue";

/**
 * This filter wraps text in strong tags
 * that match a specified query.
 */
Vue.filter("highlightWordMatch", (text: string, query: string) => {
  const check = new RegExp(query, "ig");

  // Skip letters.
  if (query.length < 2) return text;

  return text.toString().replace(check, (matchedText) => {
    return "<strong>" + matchedText + "</strong>";
  });
});
