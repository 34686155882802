var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-navigation-tab-item",class:{
    'px-navigation-tab-item--list-format': _vm.listFormat,
  }},[_c('div',{staticClass:"px-navigation-tab-item__container",class:{
      'px-navigation-tab-item--active': _vm.active,
      'px-navigation-tab-item--icon-right': _vm.iconPosition === 'right',
    },on:{"click":_vm.onTabClick}},[(_vm.icon)?_c('PxIcon',{attrs:{"size":_vm.iconSize,"name":_vm.icon,"data-testid":"icon"}}):_vm._e(),_c('h3',{staticClass:"px-navigation-tab-item__title"},[(_vm.showNumber)?_c('span',[_vm._v(_vm._s(_vm.number)+".")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])],1),(_vm.active)?_c('div',{staticClass:"px-navigation-tab-item__border",class:{
      'px-navigation-tab-item__border-left': _vm.listFormat,
    }}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }