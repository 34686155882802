
























































































import Vue from "vue";

import uniq from "lodash/uniq";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";

import { SUPPORTERS_INVESTING_RANGE_LIMIT } from "@/modules/supporters/constants";

import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";

import { ROUTE_UPDATE_RANGE_LEVEL } from "@/modules/profile/services/router/routes-names";

import InvestmentLevelPanelPlaceholder from "@/modules/profile/components/investment-level-panel/investment-level-panel-placeholder.vue";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";

export default Vue.extend({
  name: "InvestmentLevelPanel",

  components: {
    InvestmentLevelPanelPlaceholder,
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    supporterCategory(): ICategory | null {
      return this.categories.length ? this.categories[0] : null;
    },

    isLoading(): boolean {
      return (
        this.$store.get("profile/company/loading") ||
        this.$store.get("profile/supporter/loading")
      );
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    isInactive(): boolean {
      return !this.$user.isLogged() && !this.isSpecialGuest;
    },

    allowLevelUpdate(): boolean {
      return this.$user.isOwner();
    },

    investingLevelRangeValue(): Array<number> {
      if (!this.supporter) {
        return [];
      }

      const levelRange = uniq(this.supporter.investing_level_range);

      return levelRange.map((entry: number) => {
        if (entry < 1) {
          return 1;
        }

        if (entry > 9) {
          return 9;
        }

        return entry;
      });
    },

    investingLevels(): Array<number> {
      if (!this.investingLevelRangeValue) {
        return [];
      }

      const levelsRange = this.investingLevelRangeValue;
      const startLevel = levelsRange[0];

      if (!levelsRange[1]) {
        return levelsRange;
      }

      // Create number sequence from the levels range
      return Array.from(
        new Array(SUPPORTERS_INVESTING_RANGE_LIMIT),
        (val, index) => index + startLevel,
      ).filter((level: number) => level <= levelsRange[1]);
    },

    investingLevelRangeTitle(): string {
      return this.$t(
        `profile.investors.panel.label[${this.investingLevelRangeSize}]`,
        [...this.investingLevelRangeValue],
      ) as string;
    },

    investingLevelRangeDescription(): string {
      let levelsDescription = "";

      this.investingLevels.forEach((level: number) => {
        const category = this.getCategoryDetailsByLevel(level);
        const description = category ? category.achievements : "";

        levelsDescription += `Level ${level} - ${description}<br>`;
      });

      return levelsDescription;
    },

    investingLevelRangeInterval(): string {
      return this.investingLevelRangeValue.join("-");
    },

    investingLevelRangeSize(): number {
      return this.investingLevelRangeValue[1] ? 1 : 0;
    },
  },

  created() {
    // Fetch categories
    this.$store.dispatch(ECategoryActions.FETCH, {
      group: SUPPORTER_USER_GROUP_ID,
    });
  },

  methods: {
    getCategoryDetailsByLevel(level: number) {
      if (this.supporterCategory) {
        return this.supporterCategory.categoryDetails.find(
          (category: ICategoryDetail) => category.level.value === level,
        );
      }
    },

    onClickUpdateLevel() {
      this.$store.dispatch(EViralLevelActions.RESET);
      this.$router.push({
        name: ROUTE_UPDATE_RANGE_LEVEL,
      });
    },

    onClickMatching() {
      this.$router.push({ name: ROUTE_MATCHING_LIST });
    },
  },
});
