






















import Vue from "vue";
import moment from "moment";

import SubmissionPanelAffiliate from "@/modules/supporters/components/submission/submission-panel-affiliate.vue";
import SubmissionPanelSupporter from "@/modules/supporters/components/submission/submission-panel-supporter.vue";
import SubmissionPanelTabs from "@/modules/supporters/components/submission/submission-panel-tabs.vue";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { IAffiliate } from "@/services/data/affiliate/affiliate.interface";

import { IAffiliateProgramSupporterSubmission } from "@/modules/supporters/services/data/affiliate-program-supporter-submission/affiliate-program-supporter-submission.interface";
import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";

export default Vue.extend({
  components: {
    SubmissionPanelAffiliate,
    SubmissionPanelSupporter,
    SubmissionPanelTabs,
  },

  props: {
    submission: {
      type: Object as () => IAffiliateProgramSupporterSubmission,
      required: true,
    },
  },

  data() {
    return {
      submissionUid: "",
    };
  },

  computed: {
    supporter(): ISupporter {
      return this.submission?.supporter;
    },

    company(): ICompany {
      return this.submission?.supporter?.user_profile?.company;
    },

    affiliate(): IAffiliate {
      return this.submission?.affiliate;
    },

    questions(): Array<IMatchingCriteria> {
      return this.submission?.criteria;
    },

    interests(): Array<IMatchingCriteria> {
      return this.submission?.additional_criteria;
    },

    profileCompany(): ICompany | null {
      return this.$store.state.profile.company.data;
    },

    profileCompanyId(): number | null {
      return this.profileCompany ? this.profileCompany.id : null;
    },

    submissionFormattedDate(): string {
      const date = this.submission
        ? moment(this.submission.created_at)
        : moment();
      return date.format("MMMM D, YYYY - HH:mmA");
    },
    canMatch(): boolean {
      return (
        (this.$store.state.matchingInterest.canMatch &&
          this.$user.isLogged()) ||
        false
      );
    },
  },
  methods: {
    showListModal() {
      this.$emit("click");
    },
  },
});
