
















































import ElDialogMixin from "@/mixins/el-dialog.mixin";

import { LocaleMessages } from "vue-i18n";
import { ROUTE_COMPANY_LISTS_DETAIL } from "@/modules/company-lists/services/router/routes-names";
import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";

import ListShareUsers from "@/modules/company-lists/components/list-share/list-share-users.vue";
import ListShareSettings from "@/modules/company-lists/components/list-share/list-share-settings.vue";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { AxiosError } from "axios";

export const LIST_SHARE_EVENTS = {
  RESET_SHAREABLE_LINK: "reset-shareable-link",
  RESET_PASSWORD: "reset-password",
  UNSHARE_FROM_USER: "unshare-from-user",
};

export default ElDialogMixin.extend({
  name: "ListShareModal",

  components: {
    ListShareUsers,
    ListShareSettings,
  },

  props: {
    /**
     * Represents the list unique identifiable
     * slug used for the shareable link.
     */
    uid: {
      type: String,
      required: true,
    },
    /**
     * Optional password where empty means
     * having no protection.
     */
    passcode: {
      type: String,
      default: "",
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
      default: [],
    },
  },

  data() {
    return {
      hasServerError: false,
      currentProfileUid: null as null | string,
      settingsVisibility: false,
      usernameOfRemoved: "",
      userHasBeenRemoved: false,
      passcodeWasChanged: false,
    };
  },

  watch: {
    innerVisibility(isVisible) {
      // Workaround since mounted and destroyed
      // hooks are not called when modals get toggled.
      if (isVisible) {
        this.listenForGlobalSettingsEvent();
        this.listenForGlobalConfirmationEvents();
      } else {
        this.disableGlobalSettingsEvent();
        this.disableGlobalConfirmationEvents();
      }
    },
  },

  computed: {
    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    shareableLink(): string {
      const linkPath = this.$router.resolve({
        name: ROUTE_COMPANY_LISTS_DETAIL,
        params: { uid: this.uid },
      }).href;

      return location.origin + linkPath;
    },

    isRestrictedByPasscode(): boolean {
      return !!this.passcode && !!this.passcode.length;
    },

    currentProfile(): ICompanyListInvitedUser | null {
      return this.currentProfileUid
        ? this.users.find(
            (user) => user.user_profile === this.currentProfileUid,
          ) || null
        : null;
    },

    dialogConfirmationOptions(): any {
      const confirmationDialogs = [
        {
          event: LIST_SHARE_EVENTS.UNSHARE_FROM_USER,
          key: "unshareFromUser",
          callback: this.onConfirmUnshare,
        },
        {
          event: LIST_SHARE_EVENTS.RESET_SHAREABLE_LINK,
          key: "resetLink",
          callback: this.onConfirmResetLink,
        },
        {
          event: LIST_SHARE_EVENTS.RESET_PASSWORD,
          key: "resetPassword",
          callback: this.onConfirmResetPassword,
        },
      ];

      return confirmationDialogs.map((confirmation) => ({
        eventName: confirmation.event,
        message: this.getTranslationWithUsername(
          `companyLists.list.confirmationDialogs.${confirmation.key}.title`,
        ),
        tip: this.getTranslationWithUsername(
          `companyLists.list.confirmationDialogs.${confirmation.key}.subtitle`,
        ),
        confirmButtonText: this.$t(
          `companyLists.list.confirmationDialogs.${confirmation.key}.cta`,
        ),
        cancelButtonText: this.$t("common.cancel"),
        confirmFn: confirmation.callback,
      }));
    },

    listErrors(): AxiosError {
      return this.$store.get(CompanyListState.Getter.ERROR);
    },

    successUserRemovalMessage(): string {
      return this.$t(`companyLists.list.shareModal.messages.accessRemoved`, {
        user: this.usernameOfRemoved,
      }) as string;
    },
  },

  methods: {
    updateClosedState() {
      this.$emit("update:visibility", false);
    },
    beforeCloseHandler() {
      this.updateClosedState();
    },
    listenForGlobalSettingsEvent() {
      this.$root.$on("list-settings", (visibility: boolean) => {
        this.settingsVisibility = visibility;
      });
    },
    disableGlobalSettingsEvent() {
      this.$root.$off("list-settings");
    },
    listenForGlobalConfirmationEvents() {
      // Workaround for the dialog confirmation
      // directive to work with global events:
      this.$root.$on(
        "list-share-modal-confirmation",
        ({
          event,
          payload,
        }: {
          event: string;
          payload: { [key: string]: any };
        }) => {
          this.currentProfileUid = payload?.user_profile || null;
          (this as any).$refs.modal.$emit(event, payload);
        },
      );
    },
    getTranslationWithUsername(path: string): string {
      return this.currentProfile
        ? (this.$t(path, {
            username: this.currentProfile.name,
          }) as string)
        : (this.$t(path) as string);
    },
    disableGlobalConfirmationEvents() {
      this.$root.$off("list-share-modal-confirmation");
    },
    onConfirmUnshare({ user_profile }: { user_profile: string }) {
      this.usernameOfRemoved = this.currentProfile?.name || "";
      const usersStillInvited = this.users
        .filter(
          (user) => !!user?.user_profile && user.user_profile !== user_profile,
        )
        .map((user) => user.user_profile);

      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          payload: {
            invited_users: usersStillInvited,
          },
          setLoading: false,
        })
        .then(() => {
          if (!this.listErrors) {
            this.userHasBeenRemoved = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
    onConfirmResetLink() {
      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          queryParams: {
            reset: "link",
          },
          setLoading: false,
        })
        .then(async (response) => {
          if (!this.listErrors) {
            // Update current route if at lists' detail page upon list link reset:
            if (this.$route.name === ROUTE_COMPANY_LISTS_DETAIL) {
              this.$router.replace({
                name: ROUTE_COMPANY_LISTS_DETAIL,
                params: { uid: response.uid },
              });
            }

            this.$message({
              message: this.$root.$t(
                "companyLists.list.shareModal.messages.linkChanged",
              ) as string,
              type: "success",
              duration: 10000,
              customClass: "is-full",
            });
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
    onConfirmResetPassword() {
      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          queryParams: {
            reset: "passcode",
          },
          setLoading: false,
        })
        .then(async () => {
          if (!this.listErrors) {
            await this.$store.dispatch(
              CompanyListState.Action.GET_VALUES,
              false,
            );
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
    saveSettingsHandler(newPasscode: string | null) {
      // Disable passcode restriction
      this.$store
        .dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: this.uid,
          shouldReload: true,
          payload: {
            passcode: newPasscode,
          },
        })
        .then(async () => {
          if (!this.listErrors) {
            await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
            this.passcodeWasChanged = true;
            return;
          }
          // Show generic error message
          this.hasServerError = true;
        });
    },
  },
});
