


















import Vue from "vue";

export default Vue.extend({
  name: "PxSidebarDivisor",

  props: {
    title: {
      required: true,
      type: String,
    },
    optionalText: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
});
