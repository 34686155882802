







































































































































import VueI18n, { LocaleMessages, TranslateResult } from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

import { EPxButtonType } from "@/components/px-button/px-button.types";
import ElDialogMixin from "@/mixins/el-dialog.mixin";
import ListManagementModalItem from "@/modules/company-lists/components/list-management/list-management-modal-item.vue";
import PanelEmptyState from "@/modules/profile/components/panel-empty-state/panel-empty-state.vue";

import { COMPANY_LIST_TYPES } from "@/modules/company-lists/constants";
import {
  ICompanyList,
  ICompanyOfList,
  ICreateCompanyPayload,
  ISimpleCompanyOfList,
} from "@/modules/company-lists/services/data/company-list/company-list.interface";
import {
  allFormFieldsValid,
  generateMaxSizeValidator,
  generateRequiredValidator,
} from "@/services/errors/validator-generators";
import {
  ROUTE_COMPANY_LISTS_DETAIL,
  ROUTE_COMPANY_LISTS_DIRECTORY,
} from "@/modules/company-lists/services/router/routes-names";

import { EListManagementActions } from "@/modules/company-lists/services/store/list-management/list-management.types";
import {
  EListManagementContext,
  EListManagementModalTab,
} from "@/modules/company-lists/components/list-management/list-management.types";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ElForm } from "element-ui/types/form";
import isEqual from "lodash/isEqual";

const TITLE_FIELD_MAX_SIZE = 128;
const DESCRIPTION_FIELD_MAX_SIZE = 150;
const LIST_HEIGHT = 50;

export interface IFormField {
  fieldValue: any;
  prop: string;
  validateState: string;
  required: boolean;
}

// TODO: Extract each tab context to different components
export default ElDialogMixin.extend({
  name: "ListManagementModal",

  components: {
    ListManagementModalItem,
    PanelEmptyState,
  },

  props: {
    context: {
      type: String,
      required: true,
      validator: (value: EListManagementContext) =>
        Object.values(EListManagementContext).includes(value),
    },

    currentTab: {
      type: String,
      required: true,
      validator: (value: EListManagementModalTab) =>
        Object.values(EListManagementModalTab).includes(value),
    },

    editedList: {
      type: Object as () => ICompanyList,
      default: () => null,
    },

    isSmartList: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    newListButton: {
      icon: "plus-white",
      type: EPxButtonType.GREEN,
    },

    createListButton: {
      icon: "plus-dark",
      type: EPxButtonType.LINK_DARK,
    },

    cancelButton: {
      type: EPxButtonType.LINK,
    },

    addToListButton: {
      type: EPxButtonType.GREEN,
    },

    descriptionFieldMaxSize: DESCRIPTION_FIELD_MAX_SIZE,

    LIST_HEIGHT,
  },

  data() {
    return {
      EListManagementContext,
      innerCurrentTab:
        EListManagementModalTab.SELECT as EListManagementModalTab,
      EListManagementModalTab,
      listTypes: Object.freeze(COMPANY_LIST_TYPES),

      // For toast messages
      filteredSelectedLists: [] as Array<ICompanyList>,

      isFormSubmissionDisabled: true,
      titleFieldMaxSize: TITLE_FIELD_MAX_SIZE,
      listFormData: {
        title: "",
        description: "",
      },
      listFormRules: {
        title: generateRequiredValidator(
          this,
          "matching.matchingList.createListForm.title",
        ),
        description: generateMaxSizeValidator(DESCRIPTION_FIELD_MAX_SIZE),
      },
      innerEditedList: null as ICompanyList | null,
      isMoveAction: false,
      currentForm: null as null | ElForm,
    };
  },

  computed: {
    formCopy(): LocaleMessages {
      return this.$t("matching.matchingList.createListForm") as LocaleMessages;
    },

    ctaCopy(): LocaleMessages {
      return this.$t("common.components.default.cta") as LocaleMessages;
    },

    /**
     * True if we are loading data from the API.
     */
    isLoading(): boolean {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    modalTitle(): LocaleMessage {
      return (
        this.innerCurrentTab === EListManagementModalTab.SELECT &&
        this.isCurrentContext(EListManagementContext.LIST_DETAIL) &&
        !this.isSmartList
          ? this.$t("matching.matchingList.addOrMove")
          : this.innerCurrentTab === EListManagementModalTab.SELECT
          ? this.ctaCopy.addToList
          : this.innerCurrentTab === EListManagementModalTab.CREATE
          ? this.ctaCopy.newList
          : this.ctaCopy.settings
      ) as LocaleMessage;
    },

    newListButtonProps(): any {
      return {
        ...this.$options.static.newListButton,
        label: this.ctaCopy.newList,
        loading: this.isLoading,
      };
    },

    createNewButtonProps(): any {
      return {
        ...this.$options.static.createListButton,
        label: this.ctaCopy.createNew,
        disabled: this.isLoading,
      };
    },

    cancelButtonProps(): any {
      return {
        ...this.$options.static.cancelButton,
        label: this.$t("common.cancel") as string,
        disabled: this.isLoading,
      };
    },

    addToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.ctaCopy.addToList,
        disabled: !this.hasSelectedCompanies,
        loading: this.isLoading,
      };
    },

    moveToListButtonProps(): any {
      return {
        ...this.$options.static.addToListButton,
        label: this.ctaCopy.moveToList,
        disabled: !this.hasSelectedCompanies,
        loading: this.isLoading,
      };
    },

    submissionButtonProps(): any {
      const ctaCopy =
        this.innerCurrentTab === EListManagementModalTab.CREATE
          ? this.ctaCopy.createList
          : this.ctaCopy.save;

      // Has same style as Add to List but with different label
      return {
        ...this.$options.static.addToListButton,
        label: ctaCopy,
        disabled: this.isFormSubmissionDisabled,
        loading: this.isLoading,
      };
    },

    listsEmptyStateCopy(): string {
      return this.isCurrentContext(EListManagementContext.LIST_DETAIL) &&
        !this.availableLists.length &&
        this.userCreatedLists.length
        ? (this.$t("matching.matchingList.emptyState.listsContext") as string)
        : (this.$t("matching.matchingList.emptyState.lists") as string);
    },

    existingLists(): Array<ICompanyList> {
      return this.$store.get(CompanyListState.Getter.VALUES) || [];
    },

    hasExistingLists(): boolean {
      return this.availableLists.length > 0;
    },

    hasSelectedCompanies(): boolean {
      return this.selectedLists.length > 0;
    },

    showCreateTabButton(): boolean {
      return (
        this.hasExistingLists &&
        this.isCurrentTab(EListManagementModalTab.SELECT)
      );
    },

    showFormTab(): boolean {
      return (
        this.isCurrentTab(EListManagementModalTab.CREATE) ||
        this.isCurrentTab(EListManagementModalTab.EDIT)
      );
    },

    showMoveButton(): boolean {
      return (
        this.isCurrentTab(EListManagementModalTab.SELECT) &&
        this.isCurrentContext(EListManagementContext.LIST_DETAIL) &&
        !this.isSmartList
      );
    },

    showTip(): boolean {
      return (
        this.isCurrentContext(EListManagementContext.LIST_DETAIL) &&
        this.hasExistingLists
      );
    },

    tipCopy(): TranslateResult {
      return !this.isSmartList
        ? this.$t("matching.matchingList.addOrMoveTip")
        : this.$t("matching.matchingList.addTip");
    },

    selectionCompanyList(): Array<string> {
      return this.$store.get("listManagement.data.companies") || [];
    },

    selectedLists(): Array<ICompanyList> {
      return this.$store.get("listManagement.data.lists") || [];
    },

    shouldFetchList(): boolean {
      return (
        this.innerVisibility &&
        this.isCurrentTab(EListManagementModalTab.SELECT)
      );
    },

    isFooterShadowless(): boolean {
      return (
        !this.isCurrentTab(EListManagementModalTab.SELECT) ||
        !this.hasExistingLists
      );
    },

    userCreatedLists(): Array<ICompanyList> {
      return this.existingLists.filter((list) => list.is_smart_list !== true);
    },

    availableLists(): Array<ICompanyList> {
      // If we are in the list details context
      // remove the current selected list from the available lists
      return this.isCurrentContext(EListManagementContext.LIST_DETAIL)
        ? this.userCreatedLists.filter(
            (list) => list.uid !== this.innerEditedList?.uid,
          )
        : this.userCreatedLists;
    },

    formFields(): IFormField[] {
      return (!!this.currentForm as any)
        ? ((this.currentForm as any).fields as IFormField[])
        : [];
    },

    formHasChanges(): boolean {
      return (
        !isEqual(
          this.listFormData.description,
          this.innerEditedList?.description,
        ) || !isEqual(this.listFormData.title, this.innerEditedList?.title)
      );
    },
  },

  watch: {
    innerCurrentTab: {
      handler(tab: EListManagementModalTab) {
        if (tab !== this.currentTab) {
          this.$emit("update:currentTab", tab);
          this.prefillFormData();
        }
      },
    },

    currentTab: {
      immediate: true,
      handler(tab: EListManagementModalTab) {
        if (tab !== this.innerCurrentTab) {
          this.innerCurrentTab = tab;
          this.prefillFormData();
        }
      },
    },

    shouldFetchList: {
      immediate: true,
      async handler(state: boolean) {
        if (!state) {
          return;
        }

        await this.fetchLists();
      },
    },

    editedList: {
      immediate: true,
      deep: true,
      handler(newValue: ICompanyList) {
        this.innerEditedList = newValue;
      },
    },

    async visibility() {
      await this.$nextTick();
      this.setupFormRef();
    },
  },

  async created() {
    await this.$nextTick();
    this.setupFormRef();
  },

  methods: {
    // TODO: refactor and simplify the process of keeping the component ref up-to-date
    setupFormRef() {
      if (!this.formFields.length) {
        this.currentForm = this.$refs.listForm as ElForm;
      }
    },

    async fetchLists() {
      await this.$store.dispatch(CompanyListState.Action.GET_VALUES);
    },

    onModalClose() {
      // Cleanup the form to be reused only when the form is visible
      if (this.showFormTab && this.formFields.length) {
        this.currentForm?.resetFields();
      }
    },

    async prefillFormData() {
      await this.$nextTick();
      this.setupFormRef();
      if (
        this.innerCurrentTab === EListManagementModalTab.EDIT &&
        !!this.editedList
      ) {
        const { title, description } = this.editedList as ICompanyList;
        this.listFormData.title = title;
        this.listFormData.description = description;
      }
    },

    onSuccessListAddition(): any {
      const company = this.$tc(
        "matching.matchingList.successToast.company",
        this.selectionCompanyList.length,
        { quantity: this.selectionCompanyList.length },
      );

      const selectedList = this.filteredSelectedLists[0];
      const listTitle = selectedList.title;
      const action = this.isMoveAction
        ? this.$t("matching.matchingList.successToast.availableActions.move")
        : this.$t("matching.matchingList.successToast.availableActions.add");
      const addedToListProps =
        this.filteredSelectedLists.length > 1
          ? {
              company,
              action,
              listTitle,
              remainingQuantity: this.filteredSelectedLists.length - 1,
            }
          : {
              company,
              action,
              listTitle,
            };

      const message = this.$tc(
        "matching.matchingList.successToast.actionsOnList",
        this.filteredSelectedLists.length,
        addedToListProps,
      );

      const goToListText = this.$tc(
        "matching.matchingList.successToast.goToList",
        this.filteredSelectedLists.length,
      );

      const h = this.$createElement;
      const v = (this as any)._v;
      return h(
        "p",
        {
          staticClass:
            "el-message__content list-management-modal__success-toast",
        },
        [
          v(`${message} `),
          h(
            "el-button",
            {
              props: {
                type: "link",
              },
              on: {
                click: () => this.goToListClick(),
              },
              staticClass: "el-button--link-white",
            },
            goToListText,
          ),
        ],
      );
    },

    afterSubmissionAction() {
      // Emit close tab
      this.$emit("close-tab");
      // Reset move action variable
      this.isMoveAction = false;
      // Close modal
      this.innerVisibility = false;
    },

    onListUpdateSubmission() {
      // If no companies selected, this must be create without company list items
      const message =
        this.selectionCompanyList.length === 0
          ? this.$t("matching.matchingList.successToast.createdList", {
              listTitle: this.filteredSelectedLists[0].title,
            })
          : this.onSuccessListAddition();

      // Launch success navbar
      this.$message({
        message,
        type: "success",
        customClass: "is-navbar",
      });

      // Clear selected companies
      this.$store.dispatch(EListManagementActions.CLEAR_LISTS);
      this.$store.dispatch(EListManagementActions.CLEAR_COMPANIES);

      this.afterSubmissionAction();
    },

    isCurrentTab(tab: string): boolean {
      return this.innerCurrentTab === tab;
    },

    isCurrentContext(context: string): boolean {
      return this.context === context;
    },

    onOpenCreateListTabButtonClick() {
      this.innerCurrentTab = EListManagementModalTab.CREATE;
    },

    onEditSubmission() {
      // TODO: Add error message here
      if (!this.editedList) {
        return;
      }

      const { uid } = this.editedList as ICompanyList;

      // Should only reload store value if currently is on list detail
      const shouldReload = this.context === EListManagementContext.LIST_DETAIL;

      this.$store.dispatch(CompanyListState.Action.PATCH_VALUE, {
        uid,
        shouldReload,
        payload: this.listFormData,
      });

      // Success toast
      this.$message({
        message: this.$t(
          "matching.matchingList.successToast.updatedSuccessfully",
        ) as string,
        type: "success",
        customClass: "is-navbar",
      });

      this.afterSubmissionAction();
    },

    onCancelButtonClick() {
      // TODO: Cancel bug, should return to select
      // For the create tab, the cancel acts as a return button
      // This happens only on the Matching context/page
      if (
        this.isCurrentTab(EListManagementModalTab.CREATE) &&
        this.isCurrentContext(EListManagementContext.MATCHING)
      ) {
        this.innerCurrentTab = EListManagementModalTab.SELECT;
        return;
      }

      // Close modal
      this.innerVisibility = false;
    },

    async onCreateSubmission() {
      // Create new company list to add selected companies
      const createdCompanyList = await this.$store.dispatch(
        CompanyListState.Action.CREATE_VALUE,
        {
          ...this.listFormData,
          companies: [...this.selectionCompanyList],
        } as ICreateCompanyPayload,
      );

      this.filteredSelectedLists = [
        {
          ...createdCompanyList,
        },
      ];

      // Cleanup the form to be reused
      const form = this.$refs.listForm as ElForm;
      form.resetFields();

      // Show list update message
      this.onListUpdateSubmission();
    },

    onMoveAction() {
      this.isMoveAction = true;
      // Remove companies from source list
      this.removeCompanyFromList();
      // Add companies to destination lists
      this.onListSelect();
    },

    onListSelect() {
      this.filteredSelectedLists = this.selectedLists.map(
        (item: ICompanyList) => {
          return this.existingLists.find(
            (existingItem: ICompanyList) => item.uid === existingItem.uid,
          );
        },
      ) as Array<ICompanyList>;

      // Patch each company list to add selected companies
      this.filteredSelectedLists.forEach((list: ICompanyList) => {
        this.$store.dispatch(CompanyListState.Action.PATCH_VALUE, {
          uid: list.uid,
          payload: {
            companies: [
              ...list.companies.map((company: ICompanyOfList) => company.uid),
              ...this.selectionCompanyList,
            ],
          },
        });
      });

      // Show list update message
      this.onListUpdateSubmission();
    },

    async removeCompanyFromList() {
      // We should check the companies of the current list
      // and if the selected companies are on these list
      // we should remove them
      const updatedCompanies = this.innerEditedList?.allCompaniesCompacted
        ? this.innerEditedList.allCompaniesCompacted
            .map(
              (companyOfList: ISimpleCompanyOfList) => companyOfList.uid || "",
            )
            .filter(
              (innerUid: string) =>
                !this.selectionCompanyList.some(
                  (selectionUid: string) => innerUid === selectionUid,
                ),
            )
        : [];

      await this.$store.dispatch(CompanyListState.Action.PATCH_VALUE, {
        uid: this.innerEditedList?.uid,
        shouldReload: true,
        payload: {
          companies: [...updatedCompanies],
        },
      });
    },

    updateSubmitState() {
      this.isFormSubmissionDisabled =
        !allFormFieldsValid(this.currentForm, this.listFormRules) ||
        !this.formHasChanges;
    },

    goToListClick() {
      // When multiple lists selected, go to directory
      if (this.filteredSelectedLists.length > 1) {
        this.$router.push({
          name: ROUTE_COMPANY_LISTS_DIRECTORY,
        });
        return;
      }

      // Go to list detail
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DETAIL,
        params: { uid: this.filteredSelectedLists[0].uid },
      });
    },
  },
});
