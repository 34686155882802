import Vue from "vue";
import Vuex, { createLogger, StoreOptions } from "vuex";
import VuexPersistence from "vuex-persist";
import pathify from "vuex-pathify";
import get from "lodash/get";
import set from "lodash/set";

import { RootState } from "@/services/store/root-state";
import { getEnv, activeModules } from "@/services/utils/utils";

import { categoriesModule } from "./category/category-module";
import { levelsModule } from "./levels/levels-module";
import { metaModule } from "./meta/meta-module";
import { userGuestModule } from "./user-guest/user-guest.module";
import { viralLevelModule } from "@/services/store/viral-level/viral-level-module";

// If the app isn't in production, enable the debug.
//
// Disable this also on development, as we have access to Vuex
// using Vue Dev Tools. This only creates extra console logs.
const debug = getEnv() !== "production" && getEnv() !== "development";

// Install Vuex on Vue global
Vue.use(Vuex);

const listOfActiveModules = activeModules();

let moduleToRegister = {
  categories: categoriesModule,
  levels: levelsModule,
  meta: metaModule,
  viralLevel: viralLevelModule,
  userGuest: userGuestModule,
};

// Store modules/sub-modules to persist by path: (e.g. ["auth", "profile.company"])
let modulesToPersist = ["meta", "viralLevel", "userGuest"];

// Iterate all modules and try to load a store definition
// for those how have one.
listOfActiveModules.forEach((moduleName: string) => {
  try {
    const {
      modules,
      toPersist,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
    } = require(`@/modules/${moduleName}/services/store/index`);

    // Register new modules
    if (modules) {
      moduleToRegister = {
        ...moduleToRegister,
        ...modules,
      };
    }

    // Register modules to persist
    if (toPersist) {
      modulesToPersist = [...modulesToPersist, ...toPersist];
    }
  } catch (_) {
    // Do nothing! This means that files doesn't exists.
  }
});

// Build Vuex Persist instance
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) =>
    modulesToPersist.reduce(
      (object, property) => ({
        ...object,
        ...set(object, property, get(state, property)),
      }),
      {},
    ),
});

// Build list of Vuex plugins
const plugins = [vuexLocal.plugin, pathify.plugin];

if (debug) {
  plugins.unshift(createLogger({}));
}

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
  },
  modules: moduleToRegister,

  strict: debug,
  plugins,
};

export default new Vuex.Store<RootState>(store);
