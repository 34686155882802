














import Vue from "vue";

import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import VisitorsGuard from "@/modules/capital-explorer/components/visitors-guard/visitors-guard.vue";

import { ICapitalExplorerVisitorData } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "CapitalExplorerForVisitorsView",

  components: {
    AuthenticatedWithNavigationView,
    VisitorsGuard,
  },

  computed: {
    hasRetrievedData(): boolean {
      return !!this.capitalExplorerData;
    },

    capitalExplorerData(): ICapitalExplorerVisitorData | null {
      return this.$store.get("capitalExplorer/visitorData") || null;
    },

    capitalExplorerErrors(): null | AxiosError {
      return this.$store.get("capitalExplorer.error");
    },

    capitalExplorerErrorCode(): undefined | number {
      return this.capitalExplorerErrors?.response?.status;
    },

    requiresPasscode(): boolean {
      return (
        this.capitalExplorerErrorCode === 403 &&
        this.capitalExplorerErrors?.response?.data.errors.code.includes(
          "passcode",
        )
      );
    },
  },
});
