






































import Vue from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import {
  IComponentLabel,
  IComponentCta,
  EComponentCtaActionType,
} from "@/modules/affiliates/components/common.interface";

export default Vue.extend({
  name: "PxCallout",

  components: {
    PxStatusBadge,
  },

  props: {
    label: {
      type: Object as () => IComponentLabel,
      default: null,
    },

    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    ctas: {
      type: Array as () => IComponentCta[],
      default: () => [],
      validator: (ctas: IComponentCta[]) => {
        return ctas.every((cta) => {
          if (cta.action) {
            return Object.values(EComponentCtaActionType).includes(cta.action);
          } else {
            return true;
          }
        });
      },
    },

    backgroundColor: {
      type: String,
      default: "#27273e",
    },
  },
});
