














































































































import Vue from "vue";

import LevelBar from "@/modules/self-assessment/components/level-bar/level-bar.vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
} from "@/services/errors/validator-generators";
import { ErrorsManager } from "@/services/errors-manager";
import {
  EViralLevelActions,
  IViralLevelState,
} from "@/services/store/viral-level/viral-level-types";
import { ILevel } from "@/services/data/level/level.interface";

import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";

import { userByTokenProvider } from "@/modules/authentication/services/data/user-by-token/user-by-token.provider";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";
import { EAuthActions } from "@/modules/authentication/services/store/auth/auth-types";
import { IAuthRecovery } from "@/modules/authentication/services/store/auth/types/auth-recovery.interface";
import { ElForm } from "element-ui/types/form";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default Vue.extend({
  name: "AuthBaseEnlist",

  components: {
    LevelBar,
  },

  data() {
    return {
      isLoading: false,
      isSubmitEnabled: false,
      formEl: null as null | ElForm,

      userName: "",

      form: {
        data: {
          email: "",
          password1: "",
          password2: "",
        },
        rules: {
          email: generateEmailValidator(
            this,
            "authentication.enlist.form.fields.email",
            true,
          ),
          password1: generatePasswordValidator(
            this,
            "authentication.enlist.form.fields.password",
            {
              minimum: 8,
              enforceCharRequirements: true,
            },
          ),
          password2: generatePasswordCheck(this, "form.data.password1", {
            minimum: 8,
          }),
        },
        errors: new ErrorsManager({
          email: {
            unique: this.$t("authentication.enlist.form.errors.email.unique"),
          },
        }),
      },
    };
  },

  computed: {
    isSubmitLoading(): boolean {
      return this.$store.get("auth/loading");
    },

    hasServerError(): boolean {
      return !!this.$store.get("auth/error");
    },

    /**
     * Get the user assessment level.
     */
    assessmentLevel(): ILevel | undefined {
      const viralStore = this.$store.state.viralLevel as IViralLevelState;
      return viralStore.finalLevel;
    },

    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  created() {
    this.fetchUserByToken();
  },

  mounted() {
    (this as any).formEl = this.$refs.enlistForm;
  },

  methods: {
    updateSubmitState() {
      this.isSubmitEnabled = allFormFieldsValid(this.formEl, this.form.rules);
    },

    /**
     * Fetch the user data based on the passed token on the URL.
     */
    async fetchUserByToken() {
      this.isLoading = true;

      try {
        const token = this.$route.params.token;
        const data = await userByTokenProvider.get(token);

        const user = data.user;
        this.userName = user.name;
        this.form.data.email = user.email;

        this.$store.dispatch(EViralLevelActions.SET_FINAL_LEVEL, data.level);
      } catch (_) {
        this.$message({
          message: this.$t(
            "authentication.enlist.errors.invalidToken",
          ) as string,
          type: "error",
          customClass: "is-full",
        });
        this.$router.replace({ name: ROUTE_AUTHENTICATION_LOGIN });
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Handler for when the user clicks on the submit button.
     */
    async onClickSubmit() {
      const payload: IAuthRecovery = {
        recoveryToken: this.$route.params.token,
        newData: {
          email: this.form.data.email,
          password: this.form.data.password1,
        },
      };

      const success = await this.$store.dispatch(EAuthActions.RECOVER, payload);
      if (!success) {
        return;
      }

      this.$message({
        message: this.$t("authentication.enlist.successMessage") as string,
        type: "success",
        customClass: "is-full",
      });

      this.$router.replace({ name: ROUTE_PROFILE });
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
