






















import Vue from "vue";

export default Vue.extend({
  name: "MilestoneTooltipProgressIndicator",

  props: {
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    isMilestoneComplete: { type: Boolean, default: false },
    categoryColor: { type: String, required: true },
  },

  computed: {
    indicatorColor(): string | null {
      return !this.isFutureMilestone ? this.categoryColor : null;
    },

    foregroundType(): string {
      return !this.isFutureMilestone &&
        !this.isMilestoneInProgress &&
        !this.isMilestonePlanned &&
        !this.isMilestoneComplete
        ? "dashed"
        : "solid";
    },

    foregroundWidth(): string {
      return this.isMilestoneInProgress ? "50%" : "100%";
    },
  },
});
