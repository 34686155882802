























import Vue from "vue";
import MatchingCardCompactWrapper from "@/components/matching-card/matching-card-compact-wrapper.vue";

export default Vue.extend({
  name: "MatchingCardCompactPlaceholder",

  components: {
    MatchingCardCompactWrapper,
  },

  props: {
    showPercentagePill: {
      type: Boolean,
      default: true,
    },
  },
});
