





























import Vue from "vue";

import { IStepItem } from "@/modules/self-assessment/types/step-item.interface";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";

import CategoryColorBar from "@/modules/self-assessment/components/category-color-bar/category-color-bar.vue";

export default Vue.extend({
  name: "CategoryWizardMobile",

  components: {
    CategoryColorBar,
  },

  props: {
    steps: {
      type: Array as () => any[],
      required: true,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    active: {
      type: Number,
      default: null,
    },
  },

  computed: {
    /**
     * Viral level state.
     */
    viralLevelState(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.viralLevelState.levels;
    },

    /**
     * Get all categories from the store.
     */
    categories(): Array<ICategory> {
      return this.$store.state.categories.data;
    },

    /**
     * Information of the current selected step.
     */
    curStepData(): IStepItem {
      return this.steps[this.currentStep];
    },

    /**
     * Get the category for the current step.
     */
    currentCategory(): ICategory | null {
      if (this.categories.length === 0) {
        return null;
      }

      return this.categories[this.currentStep];
    },

    /**
     * Get the data for the selected step.
     */
    selectedStepData(): ICategoryLevelSelection | null {
      if (!this.levels || this.levels.length === 0) {
        return null;
      }

      const selectedDetail = this.levels.find(
        (record: ICategoryLevelSelection): any => {
          return (
            this.currentCategory && record.category === this.currentCategory.id
          );
        },
      );

      return selectedDetail || null;
    },

    /**
     * Informs if the level tag must be shown.
     *
     * The tag only is visible when there is a level selected.
     */
    showLevelTag(): boolean {
      return !!this.selectedStepData && this.selectedStepData.level > 0;
    },

    /**
     * Informs that size to use with the tag.
     */
    tagSize(): string {
      return this.$screen.smDown ? "small" : "medium";
    },
  },
});
