







































































































































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
import MilestoneStageStatusItem from "@/modules/milestone-planner/components/milestone-stage-status-item/milestone-stage-status-item.vue";
import {
  EPxButtonType,
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { EMilestoneStatusType } from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { MATCHING_USER_TYPES } from "@/modules/matching/constants";

export default Vue.extend({
  name: "MilestonePlanOnboardingDialogContentSecondPage",

  components: {
    MilestoneStageStatusItem,
  },

  props: {
    isVisitor: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
    completeMilestoneButton: {
      type: EPxButtonType.BLUE,
      size: EPxButtonSize.XXX_SMALL,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    onboardingCopy(): LocaleMessages {
      return this.$t(
        `milestonePlanner.milestonePlan.onboardingModal.${this.userType}.content`,
      ) as LocaleMessages;
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },

    completeMilestoneButtonProps(): any {
      return {
        ...this.$options.static.completeMilestoneButton,
        label: this.$t(
          "milestonePlanner.milestonePlan.buttons.completeMilestone",
        ),
      };
    },

    inProgressIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.inProgress"),
        icon: EMilestoneStatusType.IN_PROGRESS,
      };
    },

    completedIconProps(): any {
      return {
        title: this.$t("milestonePlanner.milestoneGrid.stageStatus.completed"),
        icon: EMilestoneStatusType.COMPLETED,
      };
    },

    userType(): string {
      return this.isVisitor ? "visitor" : MATCHING_USER_TYPES[0];
    },
  },
});
