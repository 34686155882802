

























import Vue from "vue";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "ResultsReport",

  props: {
    levels: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    categories: {
      type: Array as () => ICategory[],
      required: true,
    },
  },

  methods: {
    /**
     * Get the description for the category on the selected level.
     */
    selectedLevelDescription(category: ICategory): string {
      const categoryLevel = this.levelForCategory(category);
      const details = category.categoryDetails.find(
        (entry: ICategoryDetail) => entry.level.value === categoryLevel,
      ) as ICategoryDetail;

      if (!details) {
        return this.$t(
          "selfAssessment.results.detailsPanel.placeholderNotAchieved",
        ) as string;
      }

      return details.description;
    },

    /**
     * Get the level for a given category.
     */
    levelForCategory(category: ICategory): number {
      const levelSection = this.levels.find(
        (record: IAssessmentLevel) => record.category === category.id,
      ) as ICategoryLevelSelection;
      return (
        levelSection ? (levelSection.level ? levelSection.level : 0) : 0
      ) as number;
    },

    /**
     * Get the color for the given category.
     */
    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },
  },
});
