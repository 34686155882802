

























import Vue from "vue";
import PxLabel from "@/components/px-label/px-label.vue";

export interface IMilestoneItem {
  title: string;
  description: string;
  level: number;
  category: string;
  color: string;
}

export default Vue.extend({
  name: "MilestoneDetails",

  components: {
    PxLabel,
  },

  props: {
    milestones: {
      type: Array as () => IMilestoneItem[],
      required: true,
    },
  },
});
