




















































import Vue from "vue";

export default Vue.extend({
  name: "ChartsTable",

  props: {
    labels: {
      type: Array as () => string[],
      required: true,
    },

    data: {
      type: Array as () => number[],
      required: true,
    },

    percentages: {
      type: Array as () => number[],
      required: true,
    },

    colors: {
      type: Array as () => string[],
      required: true,
    },

    firstColumnHeader: {
      type: String,
      required: true,
    },

    average: {
      type: Array as () => number[],
      default: null,
    },
  },

  data() {
    return {
      tableScrollAtEnd: false,
      table: (null as any) || null,
      visibleElements: 5,
    };
  },

  computed: {
    tooltipText(): string {
      return this.$t("chartsDashboard.table.averageTooltip") as string;
    },

    isGradientVisible(): boolean {
      return (
        this.average &&
        this.data.length > this.visibleElements &&
        !this.tableScrollAtEnd
      );
    },
  },

  methods: {
    checkTableScroll() {
      this.table = this.$refs.chartsTable as HTMLElement;

      if (!this.table) return;

      this.tableScrollAtEnd =
        this.table.scrollTop ===
        this.table.scrollHeight - this.table.offsetHeight;
    },
  },
});
