



































































































































import PxEditPanel from "@/components/px-edit-panel/px-edit-panel.vue";
import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";

import ElDialogMixin from "@/mixins/el-dialog.mixin";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ILocation } from "@/services/data/location/location.interface";
import { ILevel } from "@/services/data/level/level.interface";
import {
  EAuthActions,
  EAuthCompanyActions,
} from "@/modules/authentication/services/store/auth/auth-types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ROUTE_UPDATE_LEVEL } from "@/modules/profile/services/router/routes-names";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  EUserMetadataActions,
  EUserMetadataGetters,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import {
  MATCHING_ONBOARDING_COMPLETION,
  MATCHING_FROM_ONBOARDING,
} from "@/modules/matching/constants";
import { ISector } from "@/services/data/sector/sector.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { TranslateResult } from "vue-i18n";

export default ElDialogMixin.extend({
  name: "MatchingConfirmModal",

  components: {
    PxEditPanel,
    SectorsSelector,
  },

  data() {
    return {
      hasServerError: false,
      isLoading: false,

      newLocation: {} as any,
      newSectors: [],
    };
  },

  computed: {
    isMilestonePlannerRoute(): boolean {
      return this.$route.path.includes("milestone-planner");
    },

    isListsRoute(): boolean {
      return this.$route.path.includes("lists");
    },

    currentPageIndex(): number {
      return this.isListsRoute ? 1 : this.isMilestonePlannerRoute ? 2 : 0;
    },

    nextButtonLabel(): TranslateResult {
      return this.$t("matching.confirmModal.buttons.proceed", {
        page: this.$t(`common.pages[${this.currentPageIndex}]`),
      });
    },

    company(): ICompany {
      return this.$store.get("auth/company.data");
    },

    hasSector(): boolean {
      return !!this.sectors.length;
    },

    hasLocation(): boolean {
      return this.location !== null;
    },

    errorMessage(): TranslateResult {
      return !this.hasLocation
        ? this.$t("matching.confirmModal.errors.location")
        : !this.hasSector
        ? this.$t("matching.confirmModal.errors.sector")
        : this.$t("matching.confirmModal.errors.level");
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasLocation && !!this.hasSector && !!this.hasAssessmentLevel
      );
    },

    location(): ILocation | null {
      if (
        !this.company ||
        !this.company.locations ||
        !this.company.locations[0]
      ) {
        return null;
      }

      return this.company.locations[0];
    },

    sectors(): Array<ISector> {
      return this.company && this.company.sectors ? this.company.sectors : [];
    },

    latestAssessment(): IAssessment | null {
      return this.$store.get("auth/latestAssessment/data");
    },

    latestAssessmentLevel(): ILevel | null {
      return this.latestAssessment ? this.latestAssessment.level : null;
    },

    hasAssessmentLevel(): boolean {
      return this.latestAssessmentLevel !== null;
    },

    isViralLevelLoading(): boolean {
      return this.$store.state.viralLevel.loading;
    },

    hasAlreadyOnBoarded(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        MATCHING_ONBOARDING_COMPLETION,
      );
    },
  },

  created() {
    this.fetchLevel();

    // Init company location edit data
    this.newLocation = this.location;
  },

  methods: {
    resetLocationValues() {
      this.newLocation = this.location || {};
    },

    /**
     * Mark the matching onboarding as finished.
     */
    async onClickProceedButton() {
      this.hasServerError = false;
      this.isLoading = true;

      try {
        if (!this.hasAlreadyOnBoarded) {
          await this.$store.dispatch(EUserMetadataActions.SET, {
            key: MATCHING_ONBOARDING_COMPLETION,
            value: "true",
          });
        }
        this.$emit("update:visibility", false);
      } catch (_) {
        this.hasServerError = true;
        return;
      } finally {
        this.isLoading = false;
      }

      // Cleanup meta module
      await this.$store.dispatch(EMetaActions.REMOVE, MATCHING_FROM_ONBOARDING);
    },

    /**
     * Initialize edit sectors component
     */
    onEditSectors() {
      const sectorSelector = this.$refs.sectorSelector as any;
      sectorSelector.setSectors(this.sectors);
    },

    /**
     * Request a new Viral Level.
     *
     * - Reset the assessment flow data;
     * - Redirect to the assessment flow page;
     * - Set a new flag in order to the profile know that on the end
     * of the assessment a link must be provided to the user return
     * to matching page.
     */
    async onEditViralLevel() {
      await this.$store.dispatch(EViralLevelActions.RESET);
      this.$router.push({
        name: ROUTE_UPDATE_LEVEL,
      });
    },

    /**
     * Fetch the company level for the user company.
     */
    async fetchLevel() {
      if (this.company) {
        await this.$store.dispatch(
          EAuthActions.FETCH_LATEST_ASSESSMENT,
          this.company.id,
        );
      }
    },

    /**
     * Save the changes on the location field.
     */
    onSubmitLocation(success: Function) {
      this.partialUpdate({ location: this.newLocation }, success);
    },

    /**
     * Save the changes made to the sectors.
     */
    onSubmitSector(success: Function) {
      this.partialUpdate({ sectors: this.newSectors }, success);
    },

    async partialUpdate(newData: any, success: Function) {
      this.hasServerError = false;

      try {
        await this.$store.dispatch(EAuthCompanyActions.PATCH, {
          id: this.company.id,
          ...newData,
        });
      } catch (error) {
        this.hasServerError = true;
      }

      success(!this.hasServerError);

      await this.fetchCompany();
    },

    async fetchCompany() {
      await this.$store.dispatch(EAuthCompanyActions.FETCH, this.company.id);
    },
  },
});
