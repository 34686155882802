





































import FeatureCard from "@/modules/affiliates/components/feature-card/feature-card.vue";

import startingPagesMixin from "@/modules/affiliates/mixins/starting-pages.mixin";

import { LocaleMessageArray } from "vue-i18n";
import { getAppPartner, isPartner } from "@/services/utils/utils";
import { VUE_APP_PARTNER_ESO } from "@/constants/environments";

export default startingPagesMixin.extend({
  name: "SelfAssessmentStartingPage",

  components: {
    FeatureCard,
  },

  computed: {
    startButtonText(): string {
      return this.hasError
        ? (this.$t("selfAssessment.startingPoint.panel.button.error") as string)
        : this.submissionInProgress
        ? (this.$t(
            "selfAssessment.startingPoint.panel.button.resume",
          ) as string)
        : (this.$t(
            "selfAssessment.startingPoint.panel.button.normal",
          ) as string);
    },

    startingPageKeyPoints(): LocaleMessageArray {
      return this.isEsoPartner
        ? this.$t("selfAssessment.startingPoint.esoPartnerKeyPoints")
        : this.$t("selfAssessment.startingPoint.keyPoints");
    },

    isEsoPartner(): boolean {
      return isPartner && getAppPartner() === VUE_APP_PARTNER_ESO;
    },

    startingPageTitle(): string {
      return (
        this.isEsoPartner
          ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.title")
          : this.$t("selfAssessment.startingPoint.panel.title")
      ) as string;
    },

    startingPageContent(): string {
      return (
        this.isEsoPartner
          ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.content")
          : this.$t("selfAssessment.startingPoint.panel.content")
      ) as string;
    },

    startingPageContentTwo(): string {
      return (
        this.isEsoPartner
          ? this.$t("selfAssessment.startingPoint.esoPartnerPanel.contentTwo")
          : ""
      ) as string;
    },
  },

  methods: {
    onSelfAssessmentButtonClick() {
      this.$emit("next-step");
    },
  },
});
