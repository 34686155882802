




















import Vue from "vue";

import ShareResultsModal from "@/modules/capital-explorer/components/share-results/share-results-modal.vue";

import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { ICapitalExplorerInvitedUser } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";

export default Vue.extend({
  name: "ShareResults",

  components: {
    ShareResultsModal,
  },

  data() {
    return {
      shareModalVisible: false,
    };
  },

  computed: {
    shareButtonProps(): any {
      return {
        label: this.$t("common.shareResults") as string,
        icon: "share",
        iconPosition: "right",
        size: EPxButtonSize.SMALL,
        variant: EPxButtonVariant.FLUID,
      };
    },

    capitalExplorerSubmission(): any {
      return this.$store.get("capitalExplorer/data").submission;
    },

    capitalExplorerSubmissionPasscode(): string {
      return this.capitalExplorerSubmission.passcode || "";
    },

    allInvitedUsers(): Array<ICapitalExplorerInvitedUser> {
      return [
        ...(this.capitalExplorerSubmission?.invited_users || []),
        ...(this.capitalExplorerSubmission?.invited_guests || []),
      ].sort((userA, userB) => userA.name.localeCompare(userB.name));
    },
  },

  methods: {
    openShareModalClickHandler() {
      this.shareModalVisible = true;
    },
  },
});
