














































































import Vue from "vue";

import MatchingCardTagList from "@/components/matching-card/matching-card-tag-list.vue";
import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import DetailsModal from "@/modules/capital-explorer/components/details-modal/details-modal.vue";

import { ICapitalTileInfo } from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "CompareResponsesTable",

  components: {
    MatchingCardTagList,
    PxStatusBadge,
    DetailsModal,
  },

  props: {
    answersData: {
      type: Array as () => any,
      required: true,
    },

    tableHeaders: {
      type: Array as () => string[],
      required: true,
    },

    matchDetailsModalData: {
      type: Array as () => ICapitalTileInfo[],
      required: true,
    },

    allQuestionsAnswered: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      detailsModalVisible: false,
      detailsModalInfo: {} as ICapitalTileInfo,
    };
  },

  computed: {
    labelsStyles() {
      return this.matchDetailsModalData.map((item) => {
        return item.matchProperties.matchingBadge;
      });
    },

    headerButtonProps(): object {
      return {
        type: EPxButtonType.LINK_DARK,
      };
    },
  },

  methods: {
    showDetailsModal(index: number) {
      this.detailsModalInfo = this.matchDetailsModalData[index];
      this.detailsModalVisible = true;
    },
  },
});
