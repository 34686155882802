















































import Vue from "vue";

import { TranslateResult } from "vue-i18n";
import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";

export default Vue.extend({
  name: "TeamMembersListMemberEntry",

  props: {
    member: {
      type: Object as () => ITeamMember,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    showRemoveButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    removeCopy(): TranslateResult {
      return this.$t("common.components.default.cta.remove");
    },

    memberName(): string {
      // If is empty member
      if (!this.member.first_name && !this.member.last_name) {
        return this.$t("teamManagement.newMember") as string;
      }

      //If we have both names
      if (this.member.first_name && this.member.last_name) {
        return `${this.member.first_name} ${this.member.last_name}`;
      }

      return this.member.first_name
        ? this.member.first_name
        : this.member.last_name;
    },
  },

  methods: {
    removeClickHandler() {
      this.$emit("remove-member");
    },
  },
});
