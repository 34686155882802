
















































































































































import Vue from "vue";

import PxMenu from "@/components/px-menu/px-menu.vue";
import AccountDropdown from "@/modules/profile/components/account-dropdown/account-dropdown.vue";
import AccountSettingsModal from "@/modules/profile/components/account-settings-modal/account-settings-modal.vue";
import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_AUTHENTICATION_LOGIN } from "@/modules/authentication/services/router/routes-names";
import { getAppPartner, isPartner, isProduction } from "@/services/utils/utils";
import { RouteRecord } from "vue-router";

export default Vue.extend({
  name: "PxNavBar",

  components: {
    PxMenu,
    AccountDropdown,
    AccountSettingsModal,
  },

  props: {
    items: {
      type: Array as () => Array<any>,
      default: () => [],
    },

    logo: {
      type: String,
      default: null,
    },

    externalLink: {
      type: String,
      default: null,
    },

    theme: {
      type: String,
      default: "default",
      validator(value: string) {
        return ["default", "centered", "wizard"].includes(value);
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showLinks: {
      type: Boolean,
      default: true,
    },

    showLoginButton: {
      type: Boolean,
      default: true,
    },

    showHeadline: {
      type: Boolean,
      default: true,
    },

    showAccountDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      setAccountModalVisible: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    mainLogo(): string {
      return this.logo
        ? "/img/logos/abaca-logo--dark.svg"
        : "/img/logos/abaca-logo--white.svg";
    },

    showLogo(): boolean {
      const viralLevelStore = this.$store.state.viralLevel as IViralLevelState;
      return !!this.logo && !viralLevelStore.loading;
    },

    isSpecialGuest(): boolean {
      return this.$route.meta?.specialGuest;
    },

    logoLink(): any {
      return this.$user.isPendingSupporter() || this.$user.isPendingUser()
        ? null
        : !this.$user.isLogged() && !this.isSpecialGuest && isProduction
        ? "https://abaca.app"
        : "/";
    },

    howItWorksLink(): any {
      return {
        url: "https://abaca.app/how-it-works",
        title: this.$t("common.navBar.howItWorks"),
      };
    },

    headline(): string | null {
      const headlineRoute = this.$route.matched.find(
        (route: RouteRecord) => !!route.meta.navbarHeadline,
      );
      return !headlineRoute ? null : headlineRoute.meta.navbarHeadline || null;
    },

    isThemeDefault(): boolean {
      return this.theme === "default";
    },

    isThemeWizard(): boolean {
      return this.theme === "wizard";
    },

    isVisitorNavigation(): boolean {
      return (!this.company || !this.$user.isLogged()) && this.isThemeDefault;
    },

    isPartner(): boolean {
      return isPartner;
    },

    getPartnerName(): string {
      return getAppPartner();
    },

    hasNavbarCenterTitle(): boolean {
      return !!this.headline && !this.isThemeWizard;
    },

    hasNavbarCenterSlot(): boolean {
      return !!this.$slots["navbar-center"];
    },

    isHowItWorksLinkDisabled(): boolean {
      return this.$features.isEnabled("disabledHowItWorksLink");
    },
  },

  methods: {
    externalLinkClickHandler() {
      if (this.externalLink) {
        window.open(this.externalLink);
      }
    },

    onClickLogin() {
      this.$router.push({ name: ROUTE_AUTHENTICATION_LOGIN });
    },
  },
});
