




















import Vue from "vue";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MilestoneFormFooterDeleteButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
  },

  static: {
    deleteButton: {
      icon: "trash--red",
      type: EPxButtonType.LINK_DANGER,
    },
  },

  computed: {
    deletePlanTooltipCopy(): TranslateResult {
      return this.$t("milestonePlanner.milestonePlan.tooltips.deleteMilestone");
    },

    deleteButtonProps(): any {
      return {
        ...this.$options.static.deleteButton,
        label: this.buttonLabel,
      };
    },
  },
});
