













































import Vue from "vue";

import { Progress as ElProgress } from "element-ui";
import { LocaleMessageArray } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "QuestionsPanelHeader",

  components: {
    ElProgress,
  },

  props: {
    questionsAnswered: {
      type: Number,
      required: true,
      validator(value: number) {
        return value >= 0;
      },
    },

    questionsTotal: {
      type: Number,
      required: true,
      validator(value: number) {
        return value >= 0;
      },
    },
  },

  computed: {
    answeredPercentage(): number {
      return (
        Math.round((this.questionsAnswered / this.questionsTotal) * 100) || 0
      );
    },

    questionsTitle(): LocaleMessageArray {
      return this.answeredPercentage === 0
        ? this.$t("capitalExplorer.questionsPanel.questionsTitle.0")
        : this.answeredPercentage === 100
        ? this.$t("capitalExplorer.questionsPanel.questionsTitle.2")
        : this.$t("capitalExplorer.questionsPanel.questionsTitle.1", {
            answered: this.questionsAnswered,
            total: this.questionsTotal,
          });
    },

    questionsDescription(): LocaleMessageArray {
      return this.answeredPercentage === 100
        ? this.$t("capitalExplorer.questionsPanel.questionsDescription.1")
        : this.$t("capitalExplorer.questionsPanel.questionsDescription.0");
    },

    clearButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.clearAnswers") as string,
      };
    },
  },
});
