






















































































import FeatureCard from "@/modules/affiliates/components/feature-card/feature-card.vue";
import startingPagesMixin from "@/modules/affiliates/mixins/starting-pages.mixin";

export default startingPagesMixin.extend({
  name: "QuestionBundleEntrepreneurStartingPage",

  components: {
    FeatureCard,
  },

  computed: {
    affiliateFlowKeyPoint(): any {
      return {
        value: this.$t("affiliateProgram.startingPoint.keyPoint.value"),
        title:
          this.affiliate?.disclaimer_heading ||
          this.$t("affiliateProgram.startingPoint.keyPoint.title"),
        description:
          this.affiliate?.disclaimer_body ||
          this.$t("affiliateProgram.startingPoint.keyPoint.description"),
      };
    },

    affiliateName(): string {
      return this.affiliate ? this.affiliate.name : "";
    },

    withTeamSection(): boolean {
      return (
        (this.affiliate?.show_team_section &&
          this.$features.isEnabled("teamMembers")) ||
        false
      );
    },

    selfAssessmentStepCopy(): any {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[0].title`,
        ),
        description: this.affiliate?.self_assessment_step_description
          ? this.affiliate.self_assessment_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[0].description`,
            ),
        note: this.affiliate?.self_assessment_step_note
          ? this.affiliate.self_assessment_step_note
          : this.$te(
              `affiliateProgram.results.affiliateFlowDescription[0].note`,
            )
          ? this.$t(`affiliateProgram.results.affiliateFlowDescription[0].note`)
          : null,
        thumbnail: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[0].thumbnail`,
        ),
      };
    },

    questionsStepCopy(): any {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[1].title`,
        ),
        description: this.affiliate?.questions_step_description
          ? this.affiliate.questions_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[1].description`,
            ),
        note: this.affiliate?.questions_step_note
          ? this.affiliate.questions_step_note
          : this.$te(
              `affiliateProgram.results.affiliateFlowDescription[1].note`,
            )
          ? this.$t(`affiliateProgram.results.affiliateFlowDescription[1].note`)
          : null,
        thumbnail: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[1].thumbnail`,
        ),
      };
    },

    teamMembersStepCopy(): any {
      return {
        title: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[2].title`,
        ),
        description: this.affiliate?.team_members_step_description
          ? this.affiliate.team_members_step_description
          : this.$t(
              `affiliateProgram.results.affiliateFlowDescription[2].description`,
            ),
        note: this.affiliate?.team_members_step_note
          ? this.affiliate.team_members_step_note
          : this.$te(
              `affiliateProgram.results.affiliateFlowDescription[2].note`,
            )
          ? this.$t(`affiliateProgram.results.affiliateFlowDescription[2].note`)
          : null,
        thumbnail: this.$t(
          `affiliateProgram.results.affiliateFlowDescription[2].thumbnail`,
        ),
      };
    },

    descriptionToDisplay(): Array<string> {
      return this.withTeamSection
        ? [
            this.selfAssessmentStepCopy,
            this.questionsStepCopy,
            this.teamMembersStepCopy,
          ]
        : [this.selfAssessmentStepCopy, this.questionsStepCopy];
    },

    startButtonText(): string {
      return this.hasError
        ? (this.$t(
            "affiliateProgram.startingPoint.panel.button.error",
          ) as string)
        : this.submissionInProgress
        ? (this.$t(
            "affiliateProgram.startingPoint.panel.button.resume",
          ) as string)
        : (this.$t(
            "affiliateProgram.startingPoint.panel.button.normal",
          ) as string);
    },

    contentAffiliate(): string {
      return this.withTeamSection
        ? (this.$t(
            "affiliateProgram.startingPoint.panel.contentAffiliateTeamMembers",
            {
              affiliateName: this.affiliateName,
            },
          ) as string)
        : (this.$t("affiliateProgram.startingPoint.panel.contentAffiliate", {
            affiliateName: this.affiliateName,
          }) as string);
    },

    contentBonus(): string {
      return this.$t(
        "affiliateProgram.startingPoint.panel.contentBonus",
      ) as string;
    },
  },

  methods: {
    onSelfAssessmentButtonClick() {
      this.$emit("next-step");
    },
  },
});
