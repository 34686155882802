


























import Vue from "vue";
import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

/**
 * Abaca's footer component.
 */
export default Vue.extend({
  name: "PxBottomBar",

  props: {
    /**
     * List of items to be displayed.
     *
     * Example:
     * ```js
     * {
     *   value: "my_company",
     *   label: "My Company",
     *   icon: "company",
     *   to: COMPONENTS,
     * }
     * ```
     *
     */
    items: {
      type: Array as () => any[],
      required: true,
    },
  },

  watch: {
    $route(to, from) {
      // On new route, reset scroll
      window.scrollTo(0, 0);

      if (to.name === "profile" && to.name === from.name && from.params.id) {
        this.$store.dispatch(EMetaActions.SET, {
          key: PROFILE_META_ACTIVE_VIEW,
          value: "",
        });
      }
    },
  },

  methods: {
    iconModifier(routeItem: any): string | null {
      const isActiveRoute = this.$route.name === routeItem.to;
      const isMainPage = Object.keys(this.$route.params).length === 0;

      return isActiveRoute && isMainPage ? "active" : null;
    },
  },
});
