






















import Vue from "vue";

export default Vue.extend({
  props: {
    email: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasEmail(): boolean {
      return !!this.email;
    },
  },
});
