




import Vue from "vue";

import ProfileView from "@/modules/profile/views/profile.view.vue";

import {
  EProfileCompanyActions,
  EProfileSupporterActions,
} from "@/modules/profile/services/store/profile/profile-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default Vue.extend({
  components: {
    ProfileView,
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("auth/company/data");
    },

    companyId(): string | null {
      return this.company ? this.company.id.toString() : null;
    },

    idFromParams(): string {
      return this.$route.params.id;
    },

    idFromHash(): string {
      return this.$route.params.hash;
    },

    currentCompanyId(): string | null {
      return this.idFromParams || this.idFromHash || this.companyId;
    },
  },

  watch: {
    idFromParams: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  created() {
    // Reset Pending User
    this.$store.dispatch(EAffiliatesActions.SET_PENDING_USER, null);
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch(
        EProfileCompanyActions.FETCH,
        this.currentCompanyId,
      );

      if (this.$profile.isSupporter()) {
        await this.$store.dispatch(EProfileSupporterActions.FETCH, {
          company: this.currentCompanyId,
        });
      }

      if (this.$user.isOwner() && this.$user.isEntrepreneur()) {
        this.$store.dispatch(EAffiliatesActions.FETCH_USER_SUBMISSIONS, null, {
          root: true,
        });
      }
    },
  },
});
