















































import Vue from "vue";
import xor from "lodash/xor";

const SLIDER_BAR_SELECTOR = ".el-slider__bar";
const SLIDER_BUTTON_SELECTOR = ".el-slider__button";

export default Vue.extend({
  name: "LevelRangeSlider",

  props: {
    rangeRestriction: {
      type: Number,
      default: null,
    },
    /**
     * Number of available levels.
     */
    levels: {
      type: Number,
      required: true,
    },
    /**
     * Current selected level.
     */
    value: {
      type: Array as () => any[],
      default: () => [1, 1],
    },
    color: {
      type: String,
      default: "ff8e54",
    },
    /**
     * Component height.
     */
    height: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      innerValue: [1, 1],
      rangeHasOverreachedLimit: false,
      levelsBeingSelected: this.value,
    };
  },

  computed: {
    stops(): Array<number> {
      const stopCount = this.levels;
      const stepWidth = 105 / this.levels;

      const result = [0];
      for (let i = 0; i < stopCount; i++) {
        result.push(i * stepWidth);
      }

      return result;
    },

    firstStop(): number {
      return this.innerValue[0];
    },

    lastStop(): number {
      return this.innerValue[1];
    },

    firstBeingSelectedLevel(): number {
      return this.levelsBeingSelected[0];
    },

    lastBeingSelectedLevel(): number {
      return this.levelsBeingSelected[1];
    },
  },

  watch: {
    /**
     * Inform the outside would that the value changed.
     */
    innerValue(newVal: Array<number>, oldVal: Array<number>) {
      const newRangeRestricted = this.applyRangeRestrictions(newVal, oldVal);

      const newRange = this.rangeRestriction ? newRangeRestricted : newVal;
      const hasNewValue = !!xor(newVal, this.value).length;

      if (hasNewValue) {
        this.$emit("input", newRange);
        this.$emit("changed", newRange);
      }

      if (this.rangeHasOverreachedLimit) {
        this.$emit("range-limit-overreached");
        this.rangeHasOverreachedLimit = false;
      }
    },

    value: {
      immediate: true,
      handler(newVal: Array<number>) {
        if (xor(newVal, this.innerValue).length) {
          this.innerValue = newVal;
        }
      },
    },

    /**
     * Set the slider color when the prop `color` changes.
     */
    color() {
      this.setSliderColor();
    },
  },

  mounted() {
    // When the component is rendered for the first time
    // the slider color must be set with the color
    // presented on the prop `color`
    this.setSliderColor();
  },

  methods: {
    handleRangeSliderInputChange(newVal: Array<number>) {
      this.levelsBeingSelected = newVal;
    },
    /**
     * Sets the slider color based on the color present on the
     * `color` prop.
     */
    setSliderColor() {
      const sliderComponent = this.$refs.slider as Vue;
      const sliderElement = sliderComponent.$el;
      const progressBar = sliderElement.querySelector(
        SLIDER_BAR_SELECTOR,
      ) as HTMLElement;
      const buttonElements = sliderElement.querySelectorAll(
        SLIDER_BUTTON_SELECTOR,
      ) as NodeListOf<HTMLElement>;

      [progressBar, ...buttonElements].forEach((element: HTMLElement) => {
        element.style.backgroundColor = `#${this.color}`;
      });
    },

    applyRangeRestrictions(
      newRangeValue: Array<number>,
      oldRangeValue: Array<number>,
    ) {
      if (!oldRangeValue) return newRangeValue;

      const updatedRangeValue: Array<number> = [...newRangeValue];

      const rangeDifference = this.rangeRestriction - 1;
      const rangeGotHigher = newRangeValue[1] > oldRangeValue[1];
      const rangeGotLower = newRangeValue[0] < oldRangeValue[0];

      const rangeHasGrown =
        newRangeValue[0] + rangeDifference < newRangeValue[1];
      const rangeHasShrink =
        newRangeValue[1] - newRangeValue[0] <
        oldRangeValue[1] - oldRangeValue[0];
      const rangeReachedLimit = rangeHasGrown || rangeHasShrink;

      if (rangeReachedLimit) {
        this.rangeHasOverreachedLimit = rangeHasGrown;

        if (rangeGotHigher) {
          // Pull first range selector up
          updatedRangeValue[0] = newRangeValue[1] - rangeDifference;
        } else if (rangeGotLower) {
          // Pull last range selector down
          updatedRangeValue[1] = newRangeValue[0] + rangeDifference;
        }
      }

      return updatedRangeValue;
    },

    isLevelSelected(index: number): boolean {
      return index >= this.firstStop && index <= this.lastStop;
    },

    isLevelBeingSelected(index: number): boolean {
      return (
        index >= this.firstBeingSelectedLevel &&
        index <= this.lastBeingSelectedLevel
      );
    },

    isLevelSelectable(index: number): boolean {
      const rangeDifference = this.rangeRestriction - 1;
      const selectedRangeDifference = this.lastStop - this.firstStop;
      const rangeAvailable = rangeDifference - selectedRangeDifference;

      const canSelectLowerLevel =
        index < this.firstStop && index >= this.firstStop - rangeAvailable;
      const canSelectHigherLevel =
        index > this.lastStop && index <= this.lastStop + rangeAvailable;

      return canSelectLowerLevel || canSelectHigherLevel;
    },

    currentLevelState(index: number) {
      return {
        "is-selected": this.isLevelSelected(index),
        "is-selectable": this.isLevelSelectable(index),
      };
    },
  },
});
