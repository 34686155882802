




















import Vue from "vue";
import { IBitmapLink } from "@/components/px-responsive-bitmap/px-responsive-bitmap.interface";

export default Vue.extend({
  name: "PxResponsiveBitmap",

  props: {
    /**
     * Icon name.
     */
    link: {
      type: Object as () => IBitmapLink,
      required: true,
    },

    alt: {
      type: String,
      required: true,
    },
  },
});
