










































import Vue from "vue";

import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { offeringProvider } from "@/services/data/offering/offering.provider";

import {
  IOffering,
  IOfferingCategory,
  IOfferingType,
} from "@/services/data/offering/offering.interface";

import { EProfileSupporterActions } from "@/modules/profile/services/store/profile/profile-types";

import OfferingsFormItem from "@/modules/profile/components/offerings-form/offerings-form--item.vue";

import { offeringCategoryProvider } from "@/services/data/offering/offering-category.provider";

import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
import differenceWith from "lodash/differenceWith";
import {
  PROFILE_SUPPORTER_OFFER_NOT_CHANGED,
  PROFILE_SUPPORTER_OFFER_HAS_CHANGED,
} from "@/modules/profile/constants";

export default Vue.extend({
  name: "OfferingsForm",

  components: {
    OfferingsFormItem,
  },

  props: {
    formChanges: {
      type: Number,
      default: 0,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedOfferingCategories: "",
      offeringCategories: [] as Array<IOfferingCategory>,
      currentOfferingCategories: [] as Array<IOfferingCategory>,

      form: {
        offerings: [] as Array<IOffering>,
      },
    };
  },

  computed: {
    supporter(): ISupporter {
      return this.$store.get("profile/supporter/data");
    },

    supporterOfferings(): Array<IOffering> {
      return this.supporter ? this.supporter.offerings : [];
    },

    availableOfferingCategories(): Array<IOfferingCategory> {
      return this.offeringCategories.filter(
        (item: IOfferingCategory) =>
          !this.currentOfferingCategories.some(
            (category: IOfferingCategory) => category.id === item.id,
          ),
      );
    },

    profileId(): number {
      // TODO: Set on the store the profile id for the current profile being shown
      return (
        this.$store.state.profile.profileId || this.$store.state.auth.profileId
      );
    },
  },

  watch: {
    offeringCategories() {
      this.updateCurrentOfferingCategories();
    },
  },

  created() {
    this.setActiveOffers();
    this.fetchOfferingCategories();
  },

  methods: {
    setActiveOffers() {
      if (this.supporterOfferings) {
        this.form.offerings = [...this.supporterOfferings];

        this.updateCurrentOfferingCategories();
      }
    },

    updateCurrentOfferingCategories() {
      this.currentOfferingCategories = this.form.offerings.map(
        (offer: IOffering) => {
          let selectableTypes: Array<IOfferingType> = [];
          const currentCategory = this.offeringCategories.find(
            (category: IOfferingCategory) => category.id === offer.category.id,
          );

          if (offer.types) {
            selectableTypes =
              currentCategory && currentCategory.category_types
                ? differenceWith(
                    currentCategory.category_types,
                    offer.types,
                    isEqual,
                  )
                : offer.types;
          }

          return {
            ...offer.category,
            category_types: selectableTypes,
          };
        },
      );
    },

    onOfferingCategorySelect(category: IOfferingCategory) {
      this.selectedOfferingCategories = "";
      this.currentOfferingCategories.push(category);

      this.addNewOffer();
    },

    addNewOffer() {
      this.currentOfferingCategories.forEach(
        (currentCategory: IOfferingCategory) => {
          const categoryDoesNotExist = this.form.offerings.every(
            (offer: IOffering) => offer.category.id !== currentCategory.id,
          );
          if (categoryDoesNotExist) {
            this.form.offerings.push({
              description: "",
              category: {
                ...currentCategory,
                category_types: [],
              },
            });
          }
        },
      );
    },

    async onRemoveOfferHandler(categoryId: number) {
      await offeringProvider.destroy(this.supporter.id, {
        category: categoryId,
      });

      this.currentOfferingCategories = this.currentOfferingCategories.filter(
        (category: IOfferingCategory) => category.id !== categoryId,
      );

      remove(this.form.offerings, (item: IOffering) => {
        return item.category.id === categoryId;
      });

      this.fetchSupporter();
    },

    async fetchSupporter() {
      await this.$store.dispatch(EProfileSupporterActions.FETCH, {
        user_profile: this.profileId,
      });
    },

    async offerChangedHandler(change: boolean | string) {
      if (change === "edit" || change === "remove") {
        this.$emit("update:formChanges", 0);
        await this.fetchSupporter();
        return;
      }

      const hasChanged = change
        ? PROFILE_SUPPORTER_OFFER_HAS_CHANGED
        : PROFILE_SUPPORTER_OFFER_NOT_CHANGED;

      this.$emit("update:formChanges", hasChanged);
    },

    async fetchOfferingCategories() {
      this.offeringCategories = await offeringCategoryProvider.list();
    },

    offerSubmissionHandler(hasErrors: boolean) {
      this.$emit("update:hasErrors", hasErrors);
    },
  },
});
