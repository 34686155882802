
























import Vue from "vue";
import PxIcon from "@/components/px-icon/px-icon.vue";

export default Vue.extend({
  name: "PxCard",

  components: {
    PxIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: "submission",
    },

    iconSize: {
      type: Number,
      default: 36,
    },

    action: {
      type: Function,
      default: null,
    },

    actionIcon: {
      type: String,
      default: "arrow--right-blue",
    },

    backgroundColor: {
      type: String,
      default: "#fff",
    },

    titleColor: {
      type: String,
      default: "#2972ca",
    },

    subtitleColor: {
      type: String,
      default: "#272740",
    },

    borderColor: {
      type: String,
      default: "#e4e6ea",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cardStyles(): { [key: string]: string | number } {
      return {
        backgroundColor: this.backgroundColor,
        border: `1px solid ${this.borderColor}`,
      };
    },

    cardAction(): Function | object {
      return this.action ? { click: this.action } : {};
    },
  },
});
