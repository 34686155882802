/**
 * Load all global directives.
 */

import Vue, { VueConstructor } from "vue";

import "@/directives/markdown.directive";
import "@/directives/feature.directive";
import "@/directives/dialog-confirmation/dialog-confirmation.directive";

import PxLoadingDirective from "@/directives/px-loading.directive";

Vue.use((_Vue: VueConstructor) => {
  _Vue.use(PxLoadingDirective);
});
