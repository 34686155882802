

































































































































import Vue from "vue";
import moment from "moment";
import {
  EUserMetadataActions,
  EUserMetadataGetters,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import { MILESTONE_PLANNER_ONBOARDED } from "@/modules/milestone-planner/constants";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";
import { IGridCategory } from "../components/milestones-grid/milestones-grid.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import {
  QUICK_LEVEL_UPDATE_META_UPGRADE,
  QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
  QUICK_LEVEL_UPDATE_META_ON_UPDATE,
  QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
} from "@/modules/common/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import UpgradeModal from "@/modules/common/components/upgrade-modal/upgrade-modal.vue";
import LevelUpdateModal from "@/modules/common/components/level-update-modal/level-update-modal.vue";
import MilestoneOnboardingDialog from "@/modules/milestone-planner/components/milestone-onboarding-dialog/milestone-onboarding-dialog.vue";
import GridLegend from "@/modules/milestone-planner/components/grid-legend/grid-legend.vue";
import OverviewSidebar from "@/modules/milestone-planner/components/overview-sidebar/overview-sidebar.vue";
import MilestoneCarousel from "@/modules/milestone-planner/components/milestone-carousel/milestone-carousel.vue";
import MilestonesGrid from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.vue";
import MilestoneSidebar from "@/modules/milestone-planner/components/milestone-sidebar/milestone-sidebar.vue";
import LastAssessmentDate from "@/modules/milestone-planner/components/last-assessment-date/last-assessment-date.vue";
import SharePlanner from "@/modules/milestone-planner/components/share-planner/share-planner.vue";
import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";

import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import { EAuthMilestonePlannersActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestone-planners/auth-milestone-planners.types";
import { EAuthLatestAssessmentActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import { ROUTE_MILESTONE_PLANNER_FULLGRID } from "../services/router/routes-names";
import { ILevel } from "@/services/data/level/level.interface";

export default Vue.extend({
  name: "MilestonePlannerOverview",

  components: {
    OverviewSidebar,
    MilestoneOnboardingDialog,
    GridLegend,
    MilestoneCarousel,
    MilestonesGrid,
    MilestoneSidebar,
    UpgradeModal,
    LevelUpdateModal,
    LastAssessmentDate,
    SharePlanner,
    MatchingConfirmModal,
  },

  static: {
    printButton: {
      icon: "print",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
    maximizeButton: {
      icon: "maximize",
      size: EPxButtonSize.SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      onboardingDialogVisibility: false,
      carouselVisible: false,
      carouselSelectedCategory: {},
      isUpgradeModalVisibility: false,
      isNewLevelModalVisible: false,
      hasLocation: false,
      hasSectors: false,
      isMatchingConfirmModalVisible: false,
    };
  },

  computed: {
    companyId(): number | null {
      const company = this.$store.get("auth/company.data");
      return company ? company.id : null;
    },

    hasOnboarded(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        MILESTONE_PLANNER_ONBOARDED,
      );
    },

    isLoading(): boolean {
      return [
        this.$store.get("auth/latestAssessment.loading"),
        this.$store.get("auth/milestones.loading"),
      ].some((stillLoading) => stillLoading);
    },

    categories(): ICategory[] {
      return this.$store.get("categories.data");
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("auth/milestones.parsedData");
    },

    /**
     * Default Milestone Planner owned by the user.
     */
    defaultMilestonePlanner(): IMilestonePlanner {
      return this.$store.get("auth/milestonePlanners.defaultPlanner");
    },

    authLastAssessment(): IAssessment {
      return this.$store.get("auth/latestAssessment.data");
    },

    lastAssessmentDate(): string {
      return moment(this.authLastAssessment?.updated_at).format(
        "DD MMMM YYYY, HH:MMA",
      );
    },

    shouldRenderGrid(): boolean {
      return (
        !!this.categories.length &&
        !!this.gridCategories.length &&
        !!this.authLastAssessment
      );
    },

    isLevelUp(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_UPGRADE);
    },

    viralLevelNotChanged(): boolean {
      return this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_SAME_LEVEL);
    },

    authLatestAssessmentLevel(): ILevel | null {
      return this.authLastAssessment?.level || null;
    },

    hasAssessmentLevel(): boolean {
      return this.authLatestAssessmentLevel !== null;
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasAssessmentLevel && !!this.hasLocation && !!this.hasSectors
      );
    },
  },

  beforeDestroy() {
    this.$root.$off("save-new-assessment", this.onAfterSaveNewLevel);
  },

  async created() {
    this.prefillAuthData();

    // Matching modal should be visible when the user has not level OR location OR sectors defined
    this.isMatchingConfirmModalVisible = !this.hasMatchingMandatoryData;
    if (this.isMatchingConfirmModalVisible) return;

    if (!this.hasOnboarded) {
      // Force re-fetching user metadata to ensure user has not onboarded
      // in the meantime.
      this.$store.dispatch(EUserMetadataActions.FETCH, null, {
        root: true,
      });
    }

    this.manageAssessmentModalsVisibility();
    this.listenToQuickLevelUpdates();

    await this.fetchDefaultMilestonePlanner();
  },

  mounted() {
    setTimeout(() => {
      if (
        !this.hasOnboarded &&
        !this.isMatchingConfirmModalVisible &&
        !this.isUpgradeModalVisibility &&
        !this.isNewLevelModalVisible
      ) {
        this.onboardingDialogVisibility = true;
        this.markAsOnboarded();
      }
    }, 2000);
  },

  methods: {
    prefillAuthData() {
      this.hasLocation =
        !!this.$store.get("auth/company.data").locations.length;
      this.hasSectors = !!this.$store.get("auth/company.data").sectors.length;
    },

    openOnboardingDialog() {
      this.onboardingDialogVisibility = true;
    },

    closeOnboardingDialog() {
      this.onboardingDialogVisibility = false;
    },

    hideCarousel() {
      this.carouselVisible = false;
    },

    showCarousel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
      this.carouselVisible = true;
    },

    changeCarouselCategoryLevel(category: IGridCategory) {
      this.carouselSelectedCategory = category;
    },

    markAsOnboarded(): void {
      this.$store.dispatch(EUserMetadataActions.SET, {
        key: MILESTONE_PLANNER_ONBOARDED,
        value: "true",
      });
    },

    listenToQuickLevelUpdates() {
      this.$root.$on("save-new-assessment", this.onAfterSaveNewLevel);
    },

    /**
     * Handle the user level change.
     *
     * This will request a milestone planner refresh to refresh the page
     * with the new user data.
     */
    onAfterSaveNewLevel() {
      this.$store.dispatch(EAuthMilestonesActions.FETCH_PARSED_DATA);
      this.$store.dispatch(EAuthLatestAssessmentActions.FETCH, this.companyId);
      this.viralLevelNotChanged
        ? this.showSameLevelMessage()
        : (this.isUpgradeModalVisibility = true);
    },

    /**
     *  Clean quick level metadata from the store
     *  and show a success toast message
     */
    showSameLevelMessage() {
      this.cleanQuickLevelMeta();
      return this.$message({
        message: this.$t(
          "common.components.levelUpdateModal.sameLevelSuccessMessage",
        ) as string,
        type: "success",
        duration: 10000,
        customClass: "is-full",
      });
    },

    /**
     * Check if the the new level modal or the upgrade modal
     * should be shown based on the quick level update.
     */
    manageAssessmentModalsVisibility() {
      this.isUpgradeModalVisibility =
        this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT) ||
        false;
      this.isNewLevelModalVisible =
        (this.$store.get("meta/get", QUICK_LEVEL_UPDATE_META_ON_UPDATE) &&
          !this.isUpgradeModalVisibility) ||
        false;
    },

    /**
     * Cleanup the store after perform a upgrade operation.
     */
    cleanQuickLevelMeta() {
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_SAME_LEVEL,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_UPGRADE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_ON_UPDATE,
      );
      this.$store.dispatch(
        EMetaActions.REMOVE,
        QUICK_LEVEL_UPDATE_META_FIRST_ASSESSMENT,
      );
    },

    showFullGrid() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_FULLGRID });
    },

    async fetchDefaultMilestonePlanner() {
      await this.$store.dispatch(
        EAuthMilestonePlannersActions.FETCH_DEFAULT_PLANNER,
      );
    },
  },
});
