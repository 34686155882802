




















import Vue from "vue";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "MilestoneFormFooterSaveAsDraftButton",

  props: {
    showTooltip: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  static: {
    saveAsDraftButton: {
      type: EPxButtonType.DEFAULT,
      size: EPxButtonSize.LARGE,
      variant: EPxButtonVariant.FLUID,
    },
  },

  computed: {
    saveAsDraftTooltipCopy(): TranslateResult {
      return this.$t("milestonePlanner.milestonePlan.tooltips.saveAsDraft");
    },

    saveAsDraftButtonProps(): any {
      return {
        ...this.$options.static.saveAsDraftButton,
        label: this.$t("milestonePlanner.milestonePlan.buttons.saveAsDraft"),
      };
    },
  },
});
