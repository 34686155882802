













import Vue from "vue";
import MilestonePlanOnboardingDialogContentFirstPage from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content--first-page.vue";
import MilestonePlanOnboardingDialogContentSecondPage from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog-content--second-page.vue";

export default Vue.extend({
  name: "MilestonePlanOnboardingDialogContent",

  components: {
    MilestonePlanOnboardingDialogContentFirstPage,
    MilestonePlanOnboardingDialogContentSecondPage,
  },

  props: {
    activePage: {
      type: Number,
      required: true,
    },

    isVisitor: {
      type: Boolean,
      default: false,
    },
  },
});
