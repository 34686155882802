













































import AffiliateTypesMixin from "@/modules/affiliates/mixins/affiliate-types.mixin";

import MobileTopNavbar from "@/modules/affiliates/components/mobile-top-navbar/mobile-top-navbar.vue";
import LangSwitcher from "@/modules/common/components/lang-switcher/lang-switcher.vue";
import SelfAssessmentStartingPage from "@/modules/affiliates/views/affiliate-starting-page/self-assessment-starting-page.vue";
import AffiliateAssessment from "@/modules/affiliates/views/affiliate-assessment/affiliate-assessment.vue";
import AffiliateEntrepreneurResults from "@/modules/affiliates/views/affiliate-results/affiliate-entrepreneur-results.vue";
import AffiliateFooterBar from "@/modules/affiliates/components/affiliate-footer-bar/affiliate-footer-bar.vue";

import { EAffiliateSteps } from "@/modules/affiliates/views/affiliate.types";
import { EAffiliatesActions } from "@/modules/affiliates/services/store/affiliates/affiliates.types";

export default AffiliateTypesMixin.extend({
  name: "AffiliateSelfAssessmentView",

  components: {
    LangSwitcher,
    SelfAssessmentStartingPage,
    AffiliateEntrepreneurResults,
    AffiliateAssessment,
    AffiliateFooterBar,
    MobileTopNavbar,
  },

  computed: {
    isStartingPage(): boolean {
      return this.currentStep === EAffiliateSteps.STARTING_PAGE;
    },

    isAssessmentPage(): boolean {
      return (
        this.currentStep === EAffiliateSteps.ASSESSMENT &&
        !this.resultsPageVisible
      );
    },
  },

  created() {
    if (!this.submissionInProgress) {
      this.currentStep = this.shouldSkipStartingPage
        ? EAffiliateSteps.ASSESSMENT
        : EAffiliateSteps.STARTING_PAGE;
    }
  },

  methods: {
    async goToPreviousStep() {
      if (this.isAssessmentPage && !this.isAssessmentFirstCategory) {
        this.handleCategoryNavigation(false);
      } else {
        this.currentStep--;
      }
    },

    async goToNextStep() {
      if (this.isAssessmentPage) {
        !this.isAssessmentLastCategory
          ? this.handleCategoryNavigation()
          : this.submitAffiliate();
        return;
      }

      this.currentStep++;
    },

    handleCategoryNavigation(shouldNavigateNext = true) {
      const nextCategoryToShow = shouldNavigateNext
        ? this.selectedAssessmentCategory + 1
        : this.selectedAssessmentCategory - 1;

      this.$store.dispatch(
        EAffiliatesActions.SET_SELECTED_ASSESSMENT_CATEGORY,
        nextCategoryToShow,
      );
    },
  },
});
