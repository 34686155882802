
































































































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import { ICompanyList } from "@/modules/company-lists/services/data/company-list/company-list.interface";
import { IAvatarGridItem } from "@/components/px-avatar/px-avatar-grid.vue";
import { IAvatarSetItem } from "@/components/px-avatar/px-avatar-set.vue";
import { ROUTE_COMPANY_LISTS_DETAIL } from "@/modules/company-lists/services/router/routes-names";
import ListOptions from "@/modules/company-lists/components/list-options/list-options.vue";
import ListShare from "@/modules/company-lists/components/list-share/list-share.vue";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";

export default Vue.extend({
  name: "CompanyListsItem",

  components: {
    ListOptions,
    ListShare,
  },

  props: {
    value: {
      type: Object as () => ICompanyList,
      required: true,
    },

    showPinToTop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listIsPinned: false,
    };
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    smartListTooltipCopy(): LocaleMessages {
      return this.$t("companyLists.smartList.tooltip") as LocaleMessages;
    },

    isOwner(): boolean {
      return this.value.is_owner;
    },

    isSmarList(): boolean {
      return this.value.is_smart_list;
    },

    companyAvatars(): Array<IAvatarGridItem> {
      return this.value.companies.map((member) => ({
        name: member.name,
        logo: member.logo || "",
      }));
    },

    userAvatars(): Array<IAvatarSetItem> {
      return this.value.invited_users.map((user) => ({
        name: user.name,
        logo: user.photo || "",
      }));
    },

    listUserType(): number {
      return this.value?.type || ENTREPRENEUR_USER_TYPE;
    },
  },

  watch: {
    "value.pinned": {
      immediate: true,
      handler(isPinned: boolean) {
        this.listIsPinned = isPinned;
      },
    },
  },

  methods: {
    listClickHandler() {
      this.$router.push({
        name: ROUTE_COMPANY_LISTS_DETAIL,
        params: { uid: this.value.uid },
      });
    },

    pinToTopClickHandler() {
      // Needed callbacks to fix an issue with popper not updating its size & positioning:
      (this as any).$refs.pinTooltip.handleFocus();
      (this as any).$refs.pinTooltip.updatePopper();

      // Toggle pinned list:
      this.listIsPinned = !this.listIsPinned;

      // Emit pinned list to reorder list items
      this.$emit("pinned-item", this.value);
    },
  },
});
