
























































import Vue from "vue";
import { LocaleMessages } from "vue-i18n";

import ListShareClipboardButton from "@/modules/company-lists/components/list-share/list-share-clipboard-button.vue";

export default Vue.extend({
  name: "ListShareActions",

  components: {
    ListShareClipboardButton,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  computed: {
    listCopy(): LocaleMessages {
      return this.$t("companyLists.list") as LocaleMessages;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    isRestrictedByPasscode(): number {
      // Convert passcode length to a numeric boolean (empty = 0 / not-empty = 1)
      return +!!this.passcode.length;
    },
  },

  methods: {
    onClickSettingHandler() {
      this.$root.$emit("list-settings", true);
    },
  },
});
