














































import difference from "lodash/difference";

import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import RenderlessSupporterAssessment from "@/modules/common/components/renderless-supporter-assessment/renderless-supporter-assessment.vue";
import AssessmentDesktop from "@/modules/supporters/components/assessment-desktop/assessment-desktop.vue";
import NavigationFooter from "@/modules/supporters/components/navigation-footer/navigation-footer.vue";

import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { ISupporterInvestingLevelState } from "@/modules/supporters/services/store/supporter-investing-level/supporter-investing-level-types";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import { ICategory } from "@/services/data/category/category.interface";
import { SupporterFlowState } from "@/modules/supporters/services/store/supporter-flow/supporter-flow.module";

export default SupportersFlowMixin.extend({
  name: "CriteriaAssessment",
  components: {
    RenderlessSupporterAssessment,
    AssessmentDesktop,
    NavigationFooter,
  },

  data() {
    return {
      fields: {
        supporter: {
          investing_level_range: [] as Array<number>,
        },
      } as Partial<ISupporterFlow>,
    };
  },

  computed: {
    supporterInvestingLevelState(): ISupporterInvestingLevelState {
      return this.$store.state.supporterInvestingLevel;
    },

    levels(): Array<ICategoryLevelSelection> {
      return this.supporterInvestingLevelState.levels;
    },

    categories(): Array<ICategory> {
      return this.$store.state?.categories?.data || [];
    },

    isFlowDataLoading(): boolean {
      return this.$store.getters[SupporterFlowState.Getter.IS_LOADING];
    },
  },

  watch: {
    levels(value: Array<ICategoryLevelSelection>) {
      const filteredLevels = value.filter(
        (selection: ICategoryLevelSelection) =>
          this.categories.some(
            (category: ICategory) => category.id === selection.category,
          ),
      );

      if (filteredLevels.length > 0 && this.fields.supporter) {
        this.fields.supporter.investing_level_range = filteredLevels[0]
          .level as any;
      }
    },

    "flowData.supporter.investing_level_range": {
      handler(value: Array<number>) {
        const hasEmptyValue = !value || value.length === 0;
        const valueDidNotChange =
          difference(value, this.fields.supporter.investing_level_range)
            .length === 0;

        if (hasEmptyValue || valueDidNotChange) {
          return;
        }

        this.fields.supporter.investing_level_range = [...value];
      },
    },

    isFlowDataLoading: {
      immediate: true,
      handler(isLoading: boolean) {
        if (isLoading) return;

        if (this.flowData) {
          this.fields.supporter = {
            ...this.fields.supporter,
            ...this.flowData.supporter,
          };
        }
      },
    },
  },
});
