


















































import Vue from "vue";
import moment from "moment";
import { generateYearsRange } from "@/services/utils/date";

interface IFoundedDate {
  day: number;
  month: number;
  year: number;
}

export default Vue.extend({
  name: "PxDateSelect",
  props: {
    /**
     * Number to render inside the select.
     */
    value: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      innerValue: {
        day: 0,
        month: 2,
        year: 0,
      } as IFoundedDate,

      months: moment.months(),
      years: generateYearsRange(1930),
    };
  },

  computed: {
    /**
     * Number of days in the selected month.
     */
    dayLimit(): number {
      const currentDate = moment();

      if (
        currentDate.month() === this.innerValue.month &&
        currentDate.year() === this.innerValue.year
      ) {
        return currentDate.date();
      }

      return moment(
        `${this.innerValue.year}-${this.innerValue.month + 1}`,
        "YYYY-MM",
      ).daysInMonth();
    },

    /**
     * Number of days in the selected month.
     */
    monthLimit(): string[] {
      const currentDate = moment();

      if (currentDate.year() === this.innerValue.year) {
        return this.months.filter((item, index) => {
          return currentDate.month() >= index;
        });
      }

      return this.months;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal: string, oldVal: string) {
        if (newVal === oldVal) {
          return;
        }

        this.setDate();
      },
    },

    innerValue: {
      deep: true,
      handler(newVal: IFoundedDate) {
        const selectedMonth = newVal.month + 1;
        const lastMonth = this.monthLimit.length - 1;
        let selectedDay = newVal.day;

        if (selectedMonth > lastMonth) {
          newVal.month = lastMonth;
        }

        if (selectedDay > this.dayLimit) {
          selectedDay = this.dayLimit;
          newVal.day = selectedDay;
        }

        const date = moment(
          `${newVal.year}-${selectedMonth}-${selectedDay}`,
          "YYYY-MM-DD",
        ).format("YYYY-MM-DD");

        if (date !== this.value) {
          this.$emit("input", date);
          this.$emit("change", date);

          this.innerValue = { ...newVal };
        }
      },
    },
  },

  methods: {
    /**
     * Set the founded date to be edited by the form.
     */
    setDate() {
      const parsedDate = this.value
        ? moment(this.value, "YYYY-MM-DD")
        : moment();

      this.innerValue = {
        day: parsedDate.date(),
        month: parsedDate.month(),
        year: parsedDate.year(),
      };
    },
  },
});
