import { Route } from "vue-router";
import store from "@/services/store";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ILevel } from "@/services/data/level/level.interface";
import { ELevelActions } from "@/services/store/levels/levels-types";

/**
 * Fetch required data when entering milestone planner full page grid route.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export const fullGridResolver = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  const levels: ILevel[] = store.get("levels.data");

  if (!levels.length) {
    await store.dispatch(ELevelActions.FETCH, {
      group: ENTREPRENEUR_USER_GROUP_ID,
    });
  }

  next();
};
