




































































import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import { cloneDeep } from "lodash";

import QuestionPanel from "@/modules/affiliates/components/question-panel/question-panel.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export default FormatMatchingResponseValuesMixin.extend({
  name: "CollapsibleQuestion",

  components: {
    QuestionPanel,
  },

  props: {
    response: {
      type: Object as () => IMatchingResponse,
      default: null,
    },

    question: {
      type: Object as () => IMatchingQuestion,
      required: true,
    },

    showSuccessState: {
      type: Boolean,
      default: false,
    },

    // To display the radio and checkbox options in a single column, set groupedOptions to false.
    groupedOptions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
      currentResponse: {} as IMatchingResponse | null,
      hasValidResponse: true,
    };
  },

  computed: {
    isMultiSelectQuestion(): boolean {
      return (
        this.question.question_type.type === this.responseTypes.multiSelect
      );
    },

    responseFormattedValue(): string {
      let formattedValue = "";

      if (
        (this.getQuestionType(this.responseTypes.numeric) &&
          this.$user.isEntrepreneur()) ||
        (this.getQuestionType(this.responseTypes.range) &&
          this.$user.isSupporter())
      ) {
        formattedValue = this.formatNumericValue(this.response);
      } else if (
        (this.getQuestionType(this.responseTypes.numeric) &&
          this.$user.isSupporter()) ||
        (this.getQuestionType(this.responseTypes.range) &&
          this.$user.isEntrepreneur())
      ) {
        formattedValue = this.formatRangeValue(this.response);
      } else if (this.getQuestionType(this.responseTypes.freeResponse)) {
        formattedValue = this.formatFreeResponseValue(this.response);
      } else if (this.getQuestionType(this.responseTypes.dateResponse)) {
        formattedValue = this.formatDateValue(this.response);
      } else if (
        this.getQuestionType(this.responseTypes.singleSelect) ||
        this.getQuestionType(this.responseTypes.multiSelect)
      ) {
        formattedValue =
          this.currentResponse?.answers && this.currentResponse?.answers.length
            ? this.formatMultipleAnswersValue(this.question, this.response)
            : "";
      }

      return formattedValue;
    },

    responseToDisplay(): string {
      return this.responseFormattedValue
        ? this.responseFormattedValue
        : (this.$t("common.notAnswered") as string);
    },

    successState(): boolean {
      return (
        this.showSuccessState &&
        this.hasValidResponse &&
        !!this.responseFormattedValue.length
      );
    },

    learnMoreButtonProps(): object {
      return {
        type: EPxButtonType.LINK,
        label: this.$t("common.learnMore") as string,
      };
    },
  },

  watch: {
    response: {
      immediate: true,
      deep: true,
      handler() {
        this.currentResponse = cloneDeep(this.response);
      },
    },
  },

  beforeDestroy() {
    this.responseTypes = null;
    this.currentResponse = null;
  },

  methods: {
    getQuestionTitle(question: IMatchingQuestion): string {
      return this.$user.isEntrepreneur()
        ? question.entrepreneur_question
        : question.resource_question;
    },

    getQuestionType(type: string): boolean {
      return (
        this.question.question_type && this.question.question_type.type === type
      );
    },

    handleFormattedResponse(questionId: number) {
      this.$emit("response-formatted", questionId);
    },

    checkValidState(isValid: boolean) {
      this.hasValidResponse = isValid;

      this.$emit(
        "handle-new-response",
        this.hasValidResponse,
        this.currentResponse,
      );
    },
  },
});
