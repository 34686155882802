







































































































































import Vue from "vue";
import moment from "moment";
import PxGrid from "@/components/px-grid/px-grid.vue";
import {
  IAssessment,
  IAssessmentLevel,
} from "@/services/data/assessment/assessment.interface";
import { ICategoryLevelSelection } from "@/services/data/viral-level/viral-level.interface";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ICategoryState } from "@/services/store/category/category-types";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { activeModules } from "@/services/utils/utils";

export default Vue.extend({
  name: "AssessmentDetails",

  components: {
    PxGrid,
  },

  props: {
    visibility: {
      type: Boolean,
      required: false,
    },

    assessmentList: {
      type: Array as () => IAssessment[],
      required: true,
    },

    selectedAssessment: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      innerVisibility: false,
      currentSelectAssessment: 0,
    };
  },

  computed: {
    currentAssessment(): IAssessment {
      return this.assessmentList[this.currentSelectAssessment];
    },
    currentAssessmentLevelValue(): number {
      return this.currentAssessment && this.currentAssessment.level
        ? this.currentAssessment.level.value
        : 1;
    },
    currentAssessmentDate(): string | null {
      return this.currentAssessment ? this.currentAssessment.created_at : null;
    },
    numOfLevels(): number {
      return this.$store.get("levels/data").length;
    },
    assessmentResults(): Array<IAssessmentLevel> {
      return this.currentAssessment ? this.currentAssessment.data : [];
    },
    onLatestAssessment(): boolean {
      return this.currentSelectAssessment === 0;
    },
    onOldestAssessment(): boolean {
      return this.currentSelectAssessment + 1 === this.assessmentList.length;
    },
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },
    gridSize(): string {
      return this.$screen.smDown ? "small" : "default";
    },
    company(): ICompany {
      return this.$store.get("auth/company.data");
    },
    companyName(): string {
      return this.company ? this.company.name : "";
    },
    hasMultipleAssessments(): boolean {
      return this.assessmentList.length > 1;
    },
    isMilestonePlannerModuleActive(): boolean {
      return activeModules().includes("milestone-planner");
    },
    showMilestonePlannerCta(): boolean {
      return (
        this.$user.isEntrepreneur() &&
        this.$user.isOwner() &&
        this.isMilestonePlannerModuleActive
      );
    },
  },

  watch: {
    visibility(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.innerVisibility = newVal;
    },

    innerVisibility(newVal: boolean) {
      this.$emit("update:visibility", newVal);
    },

    selectedAssessment(newAssessment: number) {
      this.currentSelectAssessment = newAssessment;
    },
  },

  methods: {
    formattedDate(date: string) {
      return moment(date).format("MMMM D, YYYY - HH:mmA");
    },

    assessmentTitle(assessment: IAssessment, index: number) {
      const current =
        index === 0
          ? `(${this.$t("profile.assessment.historyPanel.current")}) `
          : "";
      return `${current}${this.$t("profile.assessmentDetails.level")} ${
        assessment.level.value
      } - ${this.formattedDate(assessment.created_at)}`;
    },

    prevAssessment() {
      this.currentSelectAssessment++;
    },

    nextAssessment() {
      this.currentSelectAssessment--;
    },

    levelForCategory(category: ICategory): number {
      const levelSection = this.assessmentResults.find(
        (record: IAssessmentLevel) => record.category === category.id,
      ) as ICategoryLevelSelection;
      return (
        levelSection ? (levelSection.level ? levelSection.level : 0) : 0
      ) as number;
    },

    getCategoryColor(category: ICategory) {
      const level = this.levelForCategory(category);
      const color: string = level !== 0 ? category.color : "f0f1f5";
      return `#${color}`;
    },

    selectedLevelDescription(category: ICategory): string {
      const categoryLevel = this.levelForCategory(category);
      const details = category.categoryDetails.find(
        (entry: ICategoryDetail) => entry.level.value === categoryLevel,
      ) as ICategoryDetail;

      if (!details) {
        return this.$t(
          "selfAssessment.results.detailsPanel.placeholderNotAchieved",
        ) as string;
      }

      return details.description;
    },

    onClickOpenHowItWorksLink() {
      window.open("https://abaca.app/how-it-works/");
    },

    goToMilestonePlannerPage() {
      this.$router.push({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
    },
  },
});
