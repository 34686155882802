














import Vue from "vue";
import { loadedLocales, changeLocale } from "@/services/i18n/locale-utils";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import { EViralLevelActions } from "@/services/store/viral-level/viral-level-types";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import { ELevelActions } from "@/services/store/levels/levels-types";

export default Vue.extend({
  name: "LangSwitcher",

  static: {
    switchLanguageButton: {
      type: EPxButtonType.LINK_WHITE,
    },
  },

  computed: {
    switchLanguageButtonProps(): any {
      return {
        ...this.$options.static.switchLanguageButton,
        label: this.$t("common.languageSwitcher.switchLanguage"),
      };
    },
  },

  methods: {
    async localeChange(): Promise<void> {
      const newLocale = this.$i18n.locale === "en" ? "es" : "en";

      await changeLocale(this.$i18n, newLocale, loadedLocales, document);

      this.$root.$emit("locale-changed");

      // Fetch backend translations
      this.$store.dispatch(EViralLevelActions.COMPUTE_LEVEL, {
        skipLoading: true,
      });
      this.$store.dispatch(ECategoryActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
      this.$store.dispatch(ELevelActions.FETCH, {
        group: ENTREPRENEUR_USER_GROUP_ID,
      });
    },
  },
});
