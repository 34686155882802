




















































import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "WeightsPanel",
  data() {
    return {
      weights: {
        matched: [0, 0, 0, 0, 0],
        unmatched: [0, 0, 0, 0, 0],
        unanswered: [0, 0, 0, 0, 0],
      },
      weightNames: [
        "Irrelevant",
        "A Little Important",
        "Somewhat Important",
        "Very Important",
        "Extremely Important",
      ],
    };
  },
  computed: {
    capitalExplorerData() {
      return this.$store.get("capitalExplorer/data");
    },
  },
  watch: {
    weights: {
      handler() {
        this.$store.set(
          "capitalExplorer/data@weights",
          cloneDeep(this.weights),
        );
      },
      deep: true,
    },
  },
  mounted() {
    this.weights = cloneDeep(this.capitalExplorerData.weights);
  },
});
