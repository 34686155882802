import Vue from "vue";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import includes from "lodash/includes";
import cloneDeep from "lodash/cloneDeep";
import { ICategoryState } from "@/services/store/category/category-types";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { IInvestorsPanelAccordionItem } from "@/modules/profile/components/investors-panel/investors-panel-accordion.interface";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "MilestonesMixin",

  data() {
    return {
      itemsPerAccordion: 3,
    };
  },

  computed: {
    categories(): Array<ICategory> {
      const categoriesStore = this.$store.state.categories as ICategoryState;
      return [...categoriesStore.data].sort((a, b) => a.order - b.order);
    },

    isLoading(): boolean {
      return (
        this.$store.get("categories/isLoading") &&
        this.$store.get("auth/latestAssessment/loading")
      );
    },

    latestAssessment(): IAssessment | null {
      return this.$user.isOwner()
        ? this.$store.get("auth/latestAssessment/data")
        : this.$store.get("profile/latestAssessment/data");
    },

    latestAssessmentLevels(): Array<IAssessmentLevel> | null {
      return this.latestAssessment && this.latestAssessment.data
        ? this.latestAssessment.data.map((assessment: IAssessmentLevel) => ({
            ...assessment,
            level: assessment.level || 0,
          }))
        : null;
    },

    nextMilestones(): Array<IInvestorsPanelAccordionItem> {
      const lowestLevels: Array<IAssessmentLevel> = [];

      // 1. Get lowest levels
      if (this.latestAssessmentLevels) {
        for (
          let levelsSelected = 0;
          lowestLevels.length < this.itemsPerAccordion;
          ++levelsSelected
        ) {
          // Exclude already pushed category levels from all assessment levels
          const selectedCategoryLevels = differenceWith(
            this.latestAssessmentLevels,
            lowestLevels,
            isEqual,
          ) as Array<IAssessmentLevel>;

          // Get category by lowest level
          const lowestLevel = minBy(
            selectedCategoryLevels,
            (categoryLevel: IAssessmentLevel) => categoryLevel.level,
          );

          if (lowestLevel) {
            lowestLevels.push(lowestLevel);
          } else if (++levelsSelected === this.latestAssessmentLevels.length) {
            break;
          }
        }
      }

      // 2. Get categories from lowest levels
      const lowestLevelCategories = this.categories.filter(
        (category: ICategory) =>
          lowestLevels.some(
            (lowerLevel: IAssessmentLevel) =>
              lowerLevel.category === category.id,
          ),
      );

      // 3. Map next level categories content
      return lowestLevelCategories
        .map((category: ICategory) => {
          // Filter category details by a level higher that the current one
          const filteredCategoryDetails = category.categoryDetails.find(
            (categoryLevel: ICategoryDetail) =>
              lowestLevels.some(
                (lowerLevel: IAssessmentLevel) =>
                  lowerLevel.category === categoryLevel.category &&
                  lowerLevel.level !== null &&
                  lowerLevel.level + 1 === categoryLevel.level.value,
              ),
          );

          if (filteredCategoryDetails) {
            return {
              title: filteredCategoryDetails.next_milestones_title,
              description: filteredCategoryDetails.next_milestones_description,
              level: filteredCategoryDetails.level.value,
              category: category.name,
              color: category.color,
            };
          }
        })
        .filter(
          (categoryLevel: any) => !!categoryLevel,
        ) as Array<IInvestorsPanelAccordionItem>;
    },

    achievedMilestones(): Array<IInvestorsPanelAccordionItem> {
      let latestAssessmentLevels = cloneDeep(this.latestAssessmentLevels);
      const highestLevels: Array<IAssessmentLevel> = [];

      // 1. Get highest levels
      if (latestAssessmentLevels) {
        for (
          let levelsSelected = 0;
          this.latestAssessmentLevels &&
          levelsSelected < this.latestAssessmentLevels.length &&
          highestLevels.length < 3;
          ++levelsSelected
        ) {
          const highestLevel = maxBy(
            latestAssessmentLevels,
            (categoryLevel: IAssessmentLevel) => categoryLevel.level,
          ) as IAssessmentLevel;

          if (!includes(highestLevels, highestLevel)) {
            highestLevels.push(highestLevel);
            latestAssessmentLevels = latestAssessmentLevels.filter(
              (categoryLevel: IAssessmentLevel) =>
                categoryLevel.category !== highestLevel.category,
            );
          }
        }
      }

      // 2. Get categories from highest levels
      const highestLevelCategories = this.categories.filter(
        (category: ICategory) =>
          highestLevels.some(
            (higherLevel: IAssessmentLevel) =>
              higherLevel.category === category.id,
          ),
      );

      // 3. Map highest level categories content
      return (
        highestLevelCategories
          .map((category: ICategory) => {
            // Filter category details by level
            const filteredCategoryDetails = category.categoryDetails.find(
              (categoryLevel: ICategoryDetail) =>
                highestLevels.some(
                  (higherLevel: IAssessmentLevel) =>
                    higherLevel.category === categoryLevel.category &&
                    !!higherLevel.level &&
                    higherLevel.level === categoryLevel.level.value,
                ),
            );

            if (filteredCategoryDetails) {
              return {
                title: filteredCategoryDetails.achieved_milestones_title,
                description:
                  filteredCategoryDetails.achieved_milestones_description,
                level: filteredCategoryDetails.level.value,
                category: category.name,
                color: category.color,
              };
            }
          })
          // Filter out empty/invalid categories
          .filter(
            (category: any) => !!category,
          ) as Array<IInvestorsPanelAccordionItem>
      );
    },
  },
});
