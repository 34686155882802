






























import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import { IMatchingCriteria } from "@/modules/matching/services/data/matching-score/matching-criteria.interface";
import { IMatchingQuestionCategory } from "@/services/data/matching-questionary/matching-question.interface";
import GetQuestionAnswerMixin from "@/mixins/get-question-answer.mixin";

export default GetQuestionAnswerMixin.extend({
  name: "SupporterCriteriaList",

  props: {
    criteria: {
      type: Array as () => IMatchingCriteria[],
      required: true,
    },
  },

  data() {
    return {
      activeDrawer: [0],
    };
  },

  computed: {
    supporterCriteriaByQuestionCategories(): Array<any> {
      // Remove questions with no answers
      const filteredCriteria = this.criteria.filter(
        (criteria: IMatchingCriteria) =>
          !!criteria.answers.length || !!criteria.desired,
      );

      // Remove duplicates and Order by id
      const questionCategories = sortBy(
        uniqWith(
          filteredCriteria.map(
            (criteria: IMatchingCriteria) =>
              criteria.question.question_category,
          ),
          isEqual,
        ),
        ["id"],
      );

      return questionCategories.map((category: IMatchingQuestionCategory) => {
        const criteriaByCategory = filteredCriteria
          .filter(
            (criteria: IMatchingCriteria) =>
              criteria.question.question_category.id === category.id,
          )
          .map((criteria) => ({
            ...criteria,
            // Custom property with a formatted answer
            formattedValue: this.getAnswer(criteria),
          }));

        return { ...category, criteria: criteriaByCategory };
      });
    },
  },
});
