































































































import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IComponentLabel } from "@/modules/affiliates/components/common.interface";
import { TranslateResult } from "vue-i18n";

import {
  IMatchingQuestion,
  FREE_RESPONSE,
  SINGLE_SELECT,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  DATE,
} from "@/services/data/matching-questionary/matching-question.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

export default FormatMatchingResponseValuesMixin.extend({
  name: "AffiliateReviewPageTeamMembersDetails",

  components: {
    PxStatusBadge,
  },

  props: {
    teamMember: {
      type: Object as () => ITeamMember,
      required: true,
    },

    label: {
      type: Object as () => IComponentLabel,
      default: null,
    },
  },

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
    };
  },

  computed: {
    firstName(): TranslateResult {
      return (
        this.teamMember.first_name ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    lastName(): TranslateResult {
      return (
        this.teamMember.last_name ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    email(): TranslateResult {
      return (
        this.teamMember.email ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    position(): TranslateResult {
      return (
        this.teamMember.position ||
        this.$t("affiliates.reviewPage.content.notAnswered")
      );
    },

    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    questions(): Array<IMatchingQuestion> {
      return this.affiliatesData.teamMembersQuestionBundle || [];
    },
  },

  methods: {
    formattedResponse(question: IMatchingQuestion): string {
      let formattedValue = "";

      const response = (this.teamMember.responses as IMatchingResponse[]).find(
        (response) => response.question === question.id,
      );

      if (response) {
        if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isEntrepreneur()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isSupporter())
        ) {
          formattedValue = this.formatNumericValue(response);
        } else if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isSupporter()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isEntrepreneur())
        ) {
          formattedValue = this.formatRangeValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.freeResponse)
        ) {
          formattedValue = this.formatFreeResponseValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.dateResponse)
        ) {
          formattedValue = this.formatDateValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.singleSelect) ||
          this.getQuestionType(question, this.responseTypes.multiSelect)
        ) {
          formattedValue =
            response?.answers && response?.answers.length
              ? this.formatMultipleAnswersValue(question, response)
              : "";
        }
      }

      return (
        formattedValue ||
        `<i>${this.$t("affiliates.reviewPage.content.notAnswered")}</i>`
      );
    },

    getQuestionType(question: IMatchingQuestion, type: string): boolean {
      return question.question_type && question.question_type.type === type;
    },
  },
});
