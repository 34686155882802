import Vue, { VueConstructor } from "vue";
import { ScreenService } from "@/modules/common/services/screen/screen.service";

/**
 * Create an export a default screen service.
 */
export const defaultScreenService = new ScreenService();

// Match the changes on the service reactive
const media = new Vue({ data: defaultScreenService });

// Install the default screen service on the
// Vue instance
Vue.use((_Vue: VueConstructor) =>
  Object.defineProperty(_Vue.prototype, "$screenService", {
    get() {
      return defaultScreenService;
    },
  }),
);

// Install the list of breakpoint states
Vue.use((_Vue: VueConstructor) =>
  Object.defineProperty(_Vue.prototype, "$screen", {
    get() {
      return media.$data.matchState;
    },
  }),
);

// Enable autocomplete for the previous added $screen property
declare module "vue/types/vue" {
  interface Vue {
    $screenService: ScreenService;
    $screen: { [key: string]: boolean };
  }
}
