















































import Vue from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";
import PxButton from "@/components/px-button/px-button.vue";
import {
  IComponentLabel,
  IComponentCta,
} from "@/modules/affiliates/components/common.interface";

export default Vue.extend({
  name: "PxInformationCard",

  components: {
    PxStatusBadge,
    PxButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    descriptionStyles: {
      type: String,
      default: "default",
      validator: (value: string) => {
        return ["default", "italic"].includes(value);
      },
    },

    border: {
      type: String,
      default: "1px solid rgba(123, 183, 255, 0.8)",
    },

    backgroundColor: {
      type: String,
      default: "rgba(123, 183, 255, 0.05)",
    },

    label: {
      type: Object as () => IComponentLabel,
      default: null,
    },

    cta: {
      type: Object as () => IComponentCta,
      default: null,
    },
  },
});
