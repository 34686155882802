import { render, staticRenderFns } from "./verify-account-modal.vue?vue&type=template&id=6f49c3b0&scoped=true&"
import script from "./verify-account-modal.vue?vue&type=script&lang=ts&"
export * from "./verify-account-modal.vue?vue&type=script&lang=ts&"
import style0 from "./verify-account-modal.vue?vue&type=style&index=0&id=6f49c3b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f49c3b0",
  null
  
)

export default component.exports