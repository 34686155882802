import {
  EProviderFeatures,
  GenericProvider,
} from "@/services/data/generic-provider";
import { IPendingProgramQuestionBundles } from "@/modules/affiliates/services/data/pending-program-question-bundles/pending-program-question-bundles.interface";

class PendingProgramQuestionBundlesProvider extends GenericProvider<IPendingProgramQuestionBundles> {
  constructor() {
    super(
      `${process.env.VUE_APP_API_URL}/pending-user/program/question-bundles`,
      [EProviderFeatures.CREATE],
    );
  }

  public async finished(email: string) {
    const url = `${process.env.VUE_APP_API_URL}/pending-user/program/finished`;
    const request = this.httpClient.get(url, { params: { email } });
    await this.wrapRequest(request);
  }
}

export const pendingProgramQuestionBundlesProvider =
  new PendingProgramQuestionBundlesProvider();
