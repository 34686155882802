






































import Vue from "vue";

import SharePlannerSettingsLink from "@/modules/milestone-planner/components/share-planner/share-planner-settings-link.vue";
import SharePlannerSettingsPasscode from "@/modules/milestone-planner/components/share-planner/share-planner-settings-passcode.vue";

export default Vue.extend({
  name: "SharePlannerSettings",

  components: {
    SharePlannerSettingsLink,
    SharePlannerSettingsPasscode,
  },

  props: {
    link: {
      type: String,
      required: true,
    },

    passcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentPasscode: null as null | string,
      canSaveChanges: false,
      hasPasscodeRestriction: false,
      hasChangedPasscodeSetting: false,
    };
  },

  computed: {
    isSavingChanges(): boolean {
      return this.$store.get("auth/milestonePlanners.loading");
    },
  },

  watch: {
    passcode(newValue: string, previousValue: string) {
      if (newValue !== this.currentPasscode || !previousValue.length) {
        this.currentPasscode = newValue;
        this.canSaveChanges = false;
      }
    },

    hasChangedPasscodeSetting(hasChanged: boolean) {
      if (!hasChanged) {
        this.canSaveChanges = hasChanged;
      }
    },
  },

  created() {
    this.currentPasscode = this.passcode;
  },

  methods: {
    onClickCancelHandler() {
      this.$root.$emit("planner-settings", false);
      this.currentPasscode = this.passcode;
      this.hasChangedPasscodeSetting = this.canSaveChanges;
    },

    onClickSaveHandler() {
      const newPasscode = this.hasPasscodeRestriction
        ? this.currentPasscode
        : null;
      this.$emit("save", newPasscode);
      this.canSaveChanges = false;
    },

    changeRestrictionHandler(isRestricted: boolean, newPasscode: string) {
      this.hasPasscodeRestriction = isRestricted;
      this.canSaveChanges =
        (isRestricted && newPasscode !== this.passcode) ||
        (!isRestricted && !!this.passcode);
    },
  },
});
