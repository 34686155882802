









import Vue from "vue";

export default Vue.extend({
  name: "PxListHeader",

  props: {
    title: {
      type: String,
      required: true,
    },

    badgeNumber: {
      type: Number,
      default: null,
    },

    backgroundColor: {
      type: String,
      default: "#fafafa",
    },

    textColor: {
      type: String,
      default: "#272740",
    },
  },
});
