interface IExtendedDocument extends Document {
  msHidden: any;
  webkitHidden: any;
}

/**
 * Helper function to check if the current
 * page is visible to the user
 */
export const isPageVisible = () => {
  const _document = document as IExtendedDocument;

  if (typeof _document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return _document.hidden;
  } else if (typeof _document.msHidden !== "undefined") {
    return _document.msHidden;
  } else if (typeof _document.webkitHidden !== "undefined") {
    return _document.webkitHidden;
  }

  return null;
};
