










































































































































































































































import cloneDeep from "lodash/cloneDeep";

import SectorsSelector from "@/components/sectors-selector/sectors-selector.vue";
import LocationsSelector from "@/components/locations-selector/locations-selector.vue";

import MultiSelector from "@/components/multi-selector/multi-selector.vue";
import { IMultiSelection } from "@/components/multi-selector/multi-selector.interface";
import {
  formatMultiSectorsSelection,
  formatSupporterSectors,
  multiSelectorSectorsProvider,
} from "@/components/multi-selector/multi-selector-sectors.provider";
import {
  formatMultiLocationsSelection,
  formatSupporterLocations,
  multiSelectorLocationsProvider,
} from "@/components/multi-selector/multi-selector-locations.providers";

import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import CompanyEditModalMixin from "@/mixins/company-edit-modal.mixin";
import { ISupporter } from "@/modules/matching/services/data/matching-score/supporter.interface";
import { ISupporterType } from "@/modules/supporters/services/data/supporter-type/supporter-type.interface";

import {
  generateEmailValidator,
  generateRequiredValidator,
  generateSupporterLocationsValidator,
  generateSupporterTypesValidator,
  generateURLValidator,
  generateRequiredListsValidator,
} from "@/services/errors/validator-generators";
import {
  EProfileSupporterActions,
  EProfileCompanyActions,
} from "@/modules/profile/services/store/profile/profile-types";

import { SupporterTypeState } from "@/modules/supporters/services/store/supporter-type/supporter-type.module";
import {
  EAuthCompanyActions,
  EAuthActions,
} from "@/modules/authentication/services/store/auth/auth-types";

export default CompanyEditModalMixin.extend({
  name: "CompanyEditModalSupporter",

  components: {
    SectorsSelector,
    LocationsSelector,
    MultiSelector,
  },

  props: {
    selectedView: {
      type: String,
      default: "general",
      validator(value: string) {
        return !value || ["general", "interests"].includes(value);
      },
    },
  },

  data() {
    return {
      currentView: "",

      form: {
        data: {
          company: {
            name: "",
            website: "",
            email: "",
            locations: [],
            about: "",
            logo: "",
          } as ICompany,
          supporter: {
            sectors: [] as Partial<IMultiSelection>[],
            locations: [] as Partial<IMultiSelection>[],
            types: [] as Array<number>,
            otherType: null,
          },
        },
        file: null as File | null,
      },

      hasOtherType: false,
      selectedTypes: [] as Array<number>,
      hasSpecificLocations: false,

      rules: {
        company: {
          name: generateRequiredValidator(
            this,
            `profile.companyEditModal.fields.name[${this.$profile.type()}]`,
          ),
          website: generateURLValidator(
            this,
            "profile.companyEditModal.fields.website",
          ),
          email: generateEmailValidator(
            this,
            "profile.companyEditModal.fields.email",
          ),
          locations: generateRequiredListsValidator(
            this,
            "profile.companyEditModal.fields.location",
            { index: 0 },
          ),
          about: {
            max: 200,
          },
        },
        supporter: {
          types: generateSupporterTypesValidator(
            this,
            "form.data.supporter.otherType",
          ),

          locations: generateSupporterLocationsValidator(
            this,
            "hasSpecificLocations",
          ),
        },
      },
    };
  },

  computed: {
    supporter(): ISupporter | null {
      return this.$store.get("profile/supporter/data");
    },

    supporterTypes(): Array<ISupporterType> {
      return this.supporter ? this.supporter.types : [];
    },

    supporterTypesErrorMessage(): string {
      return this.$t("common.errors.requiredSet", {
        fieldName: this.$t("supporters.signup.form.fields.supporterType"),
      }) as string;
    },

    locationsOfInterestErrorMessage(): string {
      return this.$t("common.errors.requiredSet", {
        fieldName: this.$t("supporters.signup.form.errors.locations"),
      }) as string;
    },

    profileId(): number {
      return this.$store.state.auth.profileId;
    },

    availableTypes(): Array<ISupporterType> {
      return this.$store.getters[SupporterTypeState.Getter.VALUES];
    },
  },

  watch: {
    selectedTypes: {
      immediate: true,
      handler(currentSelectedTypes: Array<number>) {
        // Filter out invalid values
        if (this.form) {
          this.form.data.supporter.types = currentSelectedTypes.filter(
            (type: number) => !!type,
          );
        }
      },
    },

    hasSpecificLocations() {
      this.formValidateLocations();
    },

    selectedView: {
      immediate: true,
      handler(newVal: string) {
        if (newVal) {
          this.currentView = newVal;
        }
      },
    },
  },

  methods: {
    // Reuse provider for this component
    searchSectors: multiSelectorSectorsProvider,

    searchLocations: multiSelectorLocationsProvider,

    /**
     * Handle the click on the save button.
     */
    async submitForm() {
      this.$emit("update:loadingSubmission", true);
      this.$emit("update:hasServerErrors", false);

      if (this.form.file) {
        await companyProvider.updateLogo(this.company.id, this.form.file);
      }

      if (!this.hasSpecificLocations) {
        // Set all locations value to an empty array
        this.form.data.supporter.locations = [];
      }

      try {
        const companyData = cloneDeep(this.form.data.company);
        const supporterData = cloneDeep(this.form.data.supporter);

        const supporterPayload = {
          types: supporterData.types,
          otherType: supporterData.otherType || null,
          // Divide location's selection into grouped locations and google places
          ...formatMultiLocationsSelection(supporterData.locations),
          // Divide sectors selection into sectors and grouped_sectors
          ...formatMultiSectorsSelection(supporterData.sectors),
        };

        const companyPayload = {
          name: companyData.name,
          email: companyData.email,
          about: companyData.about,
          website: companyData.website,
          location: companyData.locations
            ? companyData.locations[0]
            : undefined,
        };

        await this.$store.dispatch(EAuthCompanyActions.PATCH, companyPayload);
        await this.$store.dispatch(
          EProfileSupporterActions.PATCH,
          supporterPayload,
        );
      } catch (error) {
        this.$emit("update:hasServerErrors", true);
        return;
      } finally {
        this.$emit("update:loadingSubmission", false);
      }

      this.$message({
        message: this.$t("profile.companyEditModal.successMessage") as string,
        type: "success",
        customClass: "is-full",
      });

      this.$emit("modal-visibility", false);
      await this.fetchSupporterData();
      this.resetFormData().then(() => null);
    },

    /**
     * Reset form data to the current profile data
     */
    async resetFormData() {
      const company = cloneDeep(this.company) as ICompany;
      const supporter = cloneDeep(this.supporter) as ISupporter;

      // Update company form data
      this.form.data.company = Object.keys(this.form.data.company).reduce(
        (obj: any, key: string) => {
          obj[key] = company[key];
          return obj;
        },
        this.form.data.company,
      );

      // Merge locations and grouped locations for multi-selector
      this.form.data.supporter.locations = formatSupporterLocations(
        supporter as any,
      );

      // Merge sectors and grouped sectors for multi-selector
      this.form.data.supporter.sectors = formatSupporterSectors(
        supporter as any,
      );

      // Initialize location selector state
      this.hasSpecificLocations = this.form.data.supporter.locations.length > 0;
      await this.$nextTick();

      // Reset selected types
      this.selectedTypes = this.supporterTypes.map(
        (type: ISupporterType) => type.id,
      );
      this.form.data.supporter.otherType = null;
      this.hasOtherType = false;

      // Clean selected file
      this.form.file = null;

      // Store original data
      await this.$nextTick();
      this.originalData = cloneDeep(this.form.data);
      this.formRef.validate();
    },

    async fetchSupporterData() {
      //TODO: we should refactor the usage of two separate store modules (auth and profile) into just one to avoid unnecessary amount of identical requests.
      await Promise.all([
        this.$store.dispatch(EProfileSupporterActions.FETCH, {
          user_profile: this.profileId,
        }),
        this.$store.dispatch(EProfileCompanyActions.FETCH, this.company.id),
        this.$store.dispatch(EAuthCompanyActions.FETCH, this.company.id),
        this.$store.dispatch(EAuthActions.FETCH_SUPPORTER, {
          user_profile: this.profileId,
        }),
        this.$store.dispatch(SupporterTypeState.Action.GET_VALUES),
      ]);
    },

    /**
     * Handle the "other" type checkbox change event
     */
    otherTypeChangeHandler(checked: boolean) {
      this.hasOtherType = checked;

      if (!checked) {
        this.form.data.supporter.otherType = null;
      }
    },

    /**
     * Validate locations and then check if form can be submitted
     */
    async formValidateLocations() {
      await this.$nextTick();
      this.formRef.validateField("supporter.locations");
    },

    /**
     * Validate supporter locations on input
     */
    insertedLocationsHandler() {
      this.formRef.validate();
    },
  },
});
