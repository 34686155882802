



























































import Vue from "vue";
import ElCollapseTransition from "element-ui/lib/transitions/collapse-transition";

import { IMultiSelectorItem } from "@/components/multi-selector/multi-selector.interface";
import throttle from "lodash/throttle";

export default Vue.extend({
  name: "MultiSelectorOption",

  components: { ElCollapseTransition },

  props: {
    item: {
      type: Object as () => IMultiSelectorItem,
      required: true,
    },
    selectedItems: {
      type: Array as () => Array<IMultiSelectorItem>,
      required: true,
    },
    highlightText: {
      type: String,
      required: true,
    },
    subtextLabelList: {
      type: Array as () => Array<string>,
      default: () => [
        "common.components.default.input.subLabel",
        "common.components.default.input.subLabelPlural",
      ],
    },
    startsCollapsed: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      innerItem: {} as IMultiSelectorItem,
      externalSelectedItems: [] as Array<IMultiSelectorItem>,
      isSelected: false,
      isCollapsed: true,

      visibleChildLimit: 3,
      showAllItems: false,
      showStickyShadow: false,
      optionElementHeight: 0,
    };
  },

  computed: {
    hasChildren(): boolean {
      return !!this.innerItem.children && !!this.innerItem.children.length;
    },

    hasSelectedChildren(): boolean {
      return (
        !!this.innerItem.children &&
        this.innerItem.children.some(
          (item: IMultiSelectorItem) => item.selected,
        )
      );
    },

    isIndeterminate(): boolean {
      return !this.isSelected && this.hasSelectedChildren;
    },

    remainSelectedItems(): Array<IMultiSelectorItem> {
      return this.selectedItems.filter(
        (selItem: IMultiSelectorItem) => selItem.value !== this.item.value,
      );
    },

    visibleChildren(): Array<IMultiSelectorItem> {
      return this.innerItem.children
        ? this.showAllItems
          ? this.innerItem.children
          : this.innerItem.children.slice(0, this.visibleChildLimit)
        : [];
    },

    visibleShowAll(): boolean {
      return (
        !!this.innerItem.children &&
        this.innerItem.children.length > this.visibleChildLimit &&
        !this.showAllItems
      );
    },
  },

  watch: {
    item: {
      deep: true,
      handler() {
        // Update inner item with current selection
        this.syncSelectedItems(this.selectedItems);
      },
    },
    selectedItems(newSelection: Array<IMultiSelectorItem>) {
      // Update inner item when selected items change
      this.syncSelectedItems(newSelection);
    },
  },

  created() {
    // Set collapsed state
    this.isCollapsed = this.startsCollapsed;

    // Update inner item with current selection
    this.syncSelectedItems(this.selectedItems);
  },

  mounted() {
    const selectScrollerElement: HTMLElement | null = document.querySelector(
      ".el-scrollbar__wrap",
    );
    const optionContainerElement = this.$refs.option as any;
    const stickyElement = this.$refs.sticky as any;

    if (
      !!selectScrollerElement &&
      !!optionContainerElement &&
      !!stickyElement
    ) {
      selectScrollerElement.addEventListener(
        "scroll",
        throttle(() => {
          this.onSelectScroll(selectScrollerElement);
          this.onOptionScroll(optionContainerElement.$el, stickyElement.$el);
        }, 100),
        {
          passive: true,
          capture: false,
        },
      );
    }
  },

  beforeDestroy() {
    const selectScrollerElement = document.querySelector(".el-scrollbar__wrap");

    if (selectScrollerElement) {
      window.removeEventListener(
        "scroll",
        throttle(() => this.onOptionScroll, 100),
      );
    }
  },

  methods: {
    async onSelectScroll(selectEl: HTMLElement) {
      // margin to trigger right before scrolling the last two options
      const triggerMargin = this.optionElementHeight * 2;
      const scrollAtBottom =
        selectEl.scrollTop >
        selectEl.scrollHeight - selectEl.offsetHeight - triggerMargin;

      if (scrollAtBottom) {
        this.$emit("scrolled-bottom");
      }
    },

    onOptionScroll(container: any, stickyElement: any) {
      this.showStickyShadow =
        container.getBoundingClientRect().top <
        stickyElement.getBoundingClientRect().top;

      if (!this.optionElementHeight) {
        this.optionElementHeight = container.offsetHeight;
      }
    },

    /**
     * Updates inner item value and current selected state
     */
    updateInnerItem() {
      // Get item external selected state for pre-fill
      const initSelectState = this.item.children
        ? false
        : this.getExternalSelectedState(this.item);

      // Map children items, with external selected state pre-fill
      const initChildren = this.item.children
        ? this.item.children.map((child: IMultiSelectorItem) => ({
            ...child,
            selected: this.getExternalSelectedState(child),
          }))
        : [];

      // Check if all children items are selected
      const allChildrenSelected = this.item.children
        ? !initChildren.some((child: IMultiSelectorItem) => !child.selected)
        : false;

      this.innerItem = {
        ...this.item,
        children: [...initChildren],
        selected: allChildrenSelected || initSelectState || false,
      };
      this.isSelected = this.innerItem.selected;
    },

    /**
     * Synchronizes selected items with current item and children
     *
     * @param selectedItems
     */
    syncSelectedItems(selectedItems: Array<IMultiSelectorItem>) {
      this.externalSelectedItems = selectedItems.reduce(
        (items: Array<IMultiSelectorItem>, item: IMultiSelectorItem) => {
          return [
            ...items,
            ...(item.selected ? [item] : []),
            ...(item.children
              ? item.children.filter(
                  (children: IMultiSelectorItem) => children.selected,
                )
              : []),
          ];
        },
        [],
      );

      this.updateInnerItem();
    },

    /**
     * Fetches parent component current selected state, allowing to
     * pre-fill the selections
     */
    getExternalSelectedState(item: IMultiSelectorItem) {
      return this.externalSelectedItems.some(
        (externalItem: IMultiSelectorItem) =>
          externalItem.label === item.label && externalItem.selected,
      );
    },

    /**
     * When a new item is selected it is pushed into the
     * array of selected items and the autocomplete fill
     * cleared.
     *
     * @param clickedItem
     */
    onItemClick(clickedItem: IMultiSelectorItem) {
      // If the selected item is the main one, invert selected state
      if (
        this.innerItem.value === clickedItem.value &&
        this.innerItem.label === clickedItem.label
      ) {
        this.isSelected = !this.isSelected;
        this.innerItem.selected = this.isSelected;

        // Update child items selected state
        if (this.innerItem.children) {
          this.innerItem.children = [
            ...this.innerItem.children.map((child: IMultiSelectorItem) => ({
              ...child,
              selected: this.isSelected,
            })),
          ];
        }
      } else if (this.innerItem.children) {
        this.innerItem.children = [
          ...this.innerItem.children.map((child: IMultiSelectorItem) =>
            child.value === clickedItem.value
              ? {
                  ...child,
                  selected: !child.selected,
                }
              : child,
          ),
        ];

        // If one child is not selected, then unselect item
        this.isSelected = !this.innerItem.children.some(
          (child: IMultiSelectorItem) => !child.selected,
        );
      }

      const remainingItems = this.remainSelectedItems.map((item) => {
        if (!item.children) return item;
        const uniqueChildren = item.children.filter(
          (child) =>
            child.value !== this.innerItem.value ||
            (this.innerItem.children &&
              this.innerItem.children.every(
                (innerChild) => innerChild.value !== child.value,
              )),
        );
        return { ...item, children: uniqueChildren };
      });

      if (this.isSelected || this.hasSelectedChildren) {
        this.$emit("change", [...remainingItems, this.innerItem]);
      } else {
        this.$emit("change", remainingItems);
      }
    },

    /**
     * Flag if the user wants to see all items
     */
    onShowAllItemsClick() {
      this.showAllItems = true;
    },

    labelClickHandler(event: any) {
      if (
        event.srcElement &&
        event.srcElement.className.includes("multi-selector__collapse")
      ) {
        // Prevent side effects like selecting options
        event.preventDefault();
        event.stopImmediatePropagation();

        // Toggle collapse
        this.isCollapsed = !this.isCollapsed;
      }
    },

    childrenCountClickHandler() {
      // Toggle full list of children either when collapsed or not.
      if (this.isCollapsed) {
        this.isCollapsed = !this.isCollapsed;
        this.showAllItems = true;
      } else {
        this.showAllItems = !this.showAllItems;
      }
    },

    /**
     * Wrap the last word of a label with the
     * collapse icon in a span to avoid
     * line-breaks containing only the icon.
     *
     * @param label
     */
    wrapLabelWithCollapseIcon(label: string): string {
      const words = label.split(" ");
      const lastWord = words.pop();
      return `${words.join(" ")} <span class="multi-selector__label-suffix">
          ${lastWord} <img alt="option-collapse" src="/img/icons/caret--gray.svg" class="multi-selector__collapse"/>
        </span>`;
    },

    /**
     * Highlight option text.
     *
     * @param label
     * @param hasChildren
     */
    highlightLabelByQuery(label: string, hasChildren = false): string {
      if (hasChildren) {
        label = this.wrapLabelWithCollapseIcon(label);
      }

      if (this.highlightText.length < 2) {
        return label;
      }

      return label.replace(
        new RegExp(this.highlightText, "gi"),
        `<span class="multi-selector__highlight-text">$&</span>`,
      );
    },

    /**
     * Fetches a compose copy with the current children count, if applicable
     * @param children
     */
    childrenCountCopy(children: Array<IMultiSelectorItem> | undefined) {
      if (children === undefined) {
        return "";
      }

      return `${children.length} ${
        children.length > 1
          ? this.$t(this.subtextLabelList[1])
          : this.$t(this.subtextLabelList[0])
      }`;
    },
  },
});
