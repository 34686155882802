

































































import Vue from "vue";

import LevelSlider from "@/modules/affiliates/components/level-slider/level-slider.vue";
import LevelsDescription from "@/modules/affiliates/components/levels-description/levels-description.vue";
import CategoryRequirements from "@/modules/affiliates/components/category-requirements/category-requirements.vue";

export default Vue.extend({
  name: "CategoryLevelsAssessmentDesktop",

  components: {
    LevelSlider,
    LevelsDescription,
    CategoryRequirements,
  },

  props: {
    currentCategory: {
      type: Object as () => any,
      default: () => null,
    },

    selectedStep: {
      type: Object as () => any,
      default: () => null,
    },

    onLevelChange: {
      type: Function as () => any,
      required: true,
    },

    isFirstInteraction: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
});
