import { Route, RouteConfig } from "vue-router";

import ProfileController from "@/modules/profile/views/profile-controller.view.vue";
import AccountView from "@/modules/profile/views/account.view.vue";
import UpdateLevelView from "@/modules/profile/views/update-level.view.vue";
import UpdateRangeLevelView from "@/modules/profile/views/update-range-level.view.vue";
import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";
import TeamManagementView from "@/modules/team-management/views/team-management.view.vue";
import UserSubmissionsView from "@/modules/submissions/views/user-submissions.view.vue";
import OwnedAffiliatesSubmissionsView from "@/modules/submissions/views/owned-affiliates-submissions.view.vue";
import AccountSettingsView from "@/modules/profile/views/account-settings.view.vue";

import {
  ROUTE_PROFILE,
  ROUTE_USER_ACCOUNT,
  ROUTE_UPDATE_LEVEL,
  ROUTE_GUEST_VIEW,
  ROUTE_UPDATE_RANGE_LEVEL,
  ROUTE_TEAM_MANAGEMENT,
  ROUTE_USER_SUBMISSIONS,
  ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
  ROUTE_ACCOUNT_SETTINGS,
} from "./routes-names";
import { QUICK_LEVEL_FROM } from "@/modules/common/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";
import store from "@/services/store";
import { userManager } from "@/modules/authentication/user-manager";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";
import { featureManager } from "@/services/feature-manager";

export const routes: RouteConfig[] = [
  {
    path: "/profile/:id?",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: "",
        name: ROUTE_PROFILE,
        component: ProfileController,
      },
    ],
  },
  {
    path: "/profile/update",
    name: ROUTE_UPDATE_LEVEL,
    component: UpdateLevelView,
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      await store.dispatch(EMetaActions.SET, {
        key: QUICK_LEVEL_FROM,
        value: from.name,
      });
      next();
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/update",
    name: ROUTE_UPDATE_RANGE_LEVEL,
    component: UpdateRangeLevelView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/account",
    name: ROUTE_USER_ACCOUNT,
    component: AccountView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/v/:hash",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: "",
        name: ROUTE_GUEST_VIEW,
        component: ProfileController,
        meta: {
          specialGuest: true,
        },
      },
    ],
  },
  {
    path: "/company/team-management",
    component: AuthenticatedWithNavigationView,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_TEAM_MANAGEMENT,
        component: TeamManagementView,
      },
    ],
  },
  {
    path: "/company/my-submissions",
    component: AuthenticatedWithNavigationView,
    //TODO: remove this when the supporter logic is implemented
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      if (userManager.isSupporter()) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.FORBIDDEN,
          },
        });
      }

      next();
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_USER_SUBMISSIONS,
        component: UserSubmissionsView,
      },
    ],
  },
  {
    path: "/track-submissions",
    component: AuthenticatedWithNavigationView,
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      if (userManager.isEntrepreneur()) {
        next({
          name: ERROR_ROUTE,
          query: {
            code: ERROR_TYPES.FORBIDDEN,
          },
        });
      }

      next();
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_OWNED_AFFILIATES_SUBMISSIONS,
        component: OwnedAffiliatesSubmissionsView,
        props: true,
      },
    ],
  },
  {
    path: "/account-settings",
    component: AuthenticatedWithNavigationView,
    beforeEnter: async (to: Route, from: Route, next: Function) => {
      if (
        !featureManager.isEnabled("settingsPage") ||
        userManager.isEntrepreneur()
      ) {
        next({
          name: ERROR_ROUTE,
        });
      }

      next();
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: ROUTE_ACCOUNT_SETTINGS,
        component: AccountSettingsView,
      },
    ],
  },
];
