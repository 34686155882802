







import Vue from "vue";

/**
 * Component that show the remaining chars.
 */
export default Vue.extend({
  name: "PxCounter",

  props: {
    /**
     * Remaining chars.
     */
    remainingChars: {
      type: Number,
      required: true,
    },

    /**
     * When set to `true` only the number
     * is shown.
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    remainingCharsCopy(): string {
      return ` ${this.$t("common.remainingChars")}`;
    },
  },
});
