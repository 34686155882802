



















































































import ElDialogMixin from "@/mixins/el-dialog.mixin";
import SupportModalSuccess from "@/modules/common/components/support-modal/support-modal-success.vue";

import {
  allFormFieldsValid,
  generateEmailValidator,
} from "@/services/errors/validator-generators";
import { supportProvider } from "@/services/data/support/support.provider";
import { SUPPORT_SUBJECT_ACCESS_DENIED } from "@/services/data/support/support.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ElForm } from "element-ui/types/form";

export default ElDialogMixin.extend({
  name: "SupportModal",

  components: {
    SupportModalSuccess,
  },

  data() {
    return {
      form: {
        detailedReport: "",
        email: "",
      },
      rules: {
        detailedReport: {
          max: 500,
          message: this.$t("common.errors.textarea.max", {
            fieldLabel: this.$t(
              "common.components.supportModal.fields.detailedReport.label",
            ),
            maxValue: 500,
          }),
        },
        email: generateEmailValidator(
          this,
          "supporters.assessment.rangeLevelsModal.fields.email",
          true,
        ),
      },
      formInvalid: true,
      hasError: false,
      reportSent: false,
    };
  },

  computed: {
    company(): ICompany | null {
      return this.$store.get("profile/company/data") || null;
    },

    canSendReport(): boolean {
      return !!this.form.detailedReport.length;
    },

    formIsInvalid(): boolean {
      return !allFormFieldsValid(this.$refs.form, this.rules);
    },

    isUserLogged(): boolean {
      return this.$user.isLogged();
    },

    userEmail(): string | undefined {
      return this.isUserLogged ? this.company?.email : this.form.email;
    },
  },

  methods: {
    onClickCloseModal() {
      this.innerVisibility = false;
      (this.$refs.form as ElForm).resetFields();
      (this.$refs.form as ElForm).clearValidate();
    },

    async onClickSendHandler() {
      try {
        await supportProvider.postSuggestion({
          subject: SUPPORT_SUBJECT_ACCESS_DENIED,
          message: this.form.detailedReport,
          author: this.userEmail,
        });
        this.reportSent = true;
      } catch (error) {
        this.hasError = true;
        return;
      }
    },
  },
});
