













































import elDialogMixin from "@/mixins/el-dialog.mixin";
import MatchDetailsModalHeader from "@/components/match-details-modal/match-details-modal-header.vue";
import MatchDetailsModalTable from "@/components/match-details-modal/match-details-modal-table.vue";
import MatchDetailsTableLegend from "@/components/match-details-table/match-details-table-legend.vue";
import MatchDetailsModalFooter from "@/components/match-details-modal/match-details-modal-footer.vue";
import { IMatchScoreDetails } from "@/modules/matching/services/store/match-score-details/match-score-details.types";
import { TranslateResult } from "vue-i18n";

export default elDialogMixin.extend({
  name: "MatchDetailsModal",

  components: {
    MatchDetailsModalHeader,
    MatchDetailsModalTable,
    MatchDetailsTableLegend,
    MatchDetailsModalFooter,
  },

  props: {
    companyName: {
      type: String,
      required: true,
    },

    companyLogo: {
      type: String,
      default: "",
    },

    matchDetails: {
      type: Object as () => IMatchScoreDetails,
      required: true,
    },
  },

  computed: {
    userType(): number {
      return this.$user.getUserAccountType();
    },

    matchPercentage(): number {
      return Math.trunc(this.matchDetails.score);
    },

    hasMatch(): boolean {
      return this.matchPercentage > 0;
    },

    tableLegend(): TranslateResult {
      return this.$t(
        `matching.matchDetails.table.legend.content[${this.userType}].cards`,
      );
    },
  },
});
