










































































import Vue from "vue";

import PxInformationCardCollapsible from "@/components/px-information-card/px-information-card-collapsible.vue";

import { ITeamMember } from "@/modules/team-management/services/data/team-members/team-members.interface";
import { IAffiliatesState } from "@/modules/affiliates/services/store/affiliates/affiliates.types";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import AffiliateReviewPageTeamMembersDetails from "@/modules/affiliates/views/affiliate-review-page/affiliate-review-page-team-members-details.vue";

export default Vue.extend({
  name: "AffiliateReviewPageTeamMembers",

  components: {
    PxInformationCardCollapsible,
    AffiliateReviewPageTeamMembersDetails,
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    emptyTeamMembersData(): Array<ITeamMember> {
      return [
        {
          id: "",
          first_name: "",
          last_name: "",
          email: "",
          position: "",
          responses: [],
        },
      ];
    },

    teamMembersData(): Array<ITeamMember> {
      return this.affiliatesData.draftSubmission &&
        this.affiliatesData.draftSubmission.data.teamMembers.length
        ? this.affiliatesData.draftSubmission.data.teamMembers
        : this.emptyTeamMembersData;
    },

    validTeamMembers(): Array<ITeamMember> {
      if (!this.affiliateWithTeamMemberQuestions) {
        return this.teamMembersData.filter(
          (teamMember) =>
            teamMember.first_name &&
            teamMember.last_name &&
            teamMember.email &&
            this.checkValidEmail(teamMember.email) &&
            teamMember.position,
        );
      } else {
        return this.teamMembersData.filter(
          (teamMember) =>
            teamMember.first_name &&
            teamMember.last_name &&
            teamMember.email &&
            this.checkValidEmail(teamMember.email) &&
            teamMember.position &&
            teamMember.responses.length &&
            teamMember.responses?.every((response: any) => {
              return this.checkValidResponse(response);
            }),
        );
      }
    },

    teamMembersWithMissingMandatoryData(): Array<ITeamMember> {
      return this.teamMembersData.filter((teamMember) => {
        return (
          !teamMember.first_name ||
          !teamMember.last_name ||
          !teamMember.email ||
          !this.checkValidEmail(teamMember.email) ||
          !teamMember.position
        );
      });
    },

    affiliateWithTeamMemberQuestions(): boolean {
      return this.affiliatesData.teamMembersQuestionBundle
        ? !!this.affiliatesData.teamMembersQuestionBundle?.length
        : false;
    },

    teamMembersWithMissingData(): Array<ITeamMember> {
      return this.teamMembersData.filter(
        (teamMember) => !this.validTeamMembers.includes(teamMember),
      );
    },

    missingTeamMembersInfoCopy(): any {
      return this.$tc(
        "affiliates.reviewPage.content.missingTeamMembers",
        this.teamMembersWithMissingMandatoryData.length,
      );
    },

    notAnsweredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "warning--blue",
          iconSize: 16,
          textColor: "#7bb7ff",
          backgroundColor: "rgba(123, 183, 255, 0.16)",
        },
        cta: {
          label: this.$t("common.answer"),
          type: "default",
          size: "small",
        },
        backgroundColor: "rgba(123, 183, 255, 0.05)",
        border: "1px solid rgba(123,183, 255, 0.8)",
      };
    },

    answeredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "milestone-check",
          iconSize: 16,
          textColor: "#53af72",
          backgroundColor: "rgba(83, 175, 114, 0.12)",
        },
        cta: {
          label: this.$t("common.update"),
          type: "default",
          size: "small",
        },
        backgroundColor: "#fafafa",
        border: "1px solid rgba(220, 221, 226, 0.8)",
      };
    },
  },

  methods: {
    checkValidResponse(response: IMatchingResponse) {
      return (
        !!response.answers?.length ||
        !!response.value?.value ||
        !!response.value?.date ||
        (!!response?.value?.max &&
          !!response?.value?.min &&
          response?.value?.max > response?.value?.min) ||
        (!!response.value?.text && response.value?.text.length < 1500)
      );
    },

    checkValidEmail(email: string) {
      const emailRegex =
        // eslint-disable-next-line no-control-regex
        /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
      return emailRegex.test(email);
    },

    isMissingRequiredInformation(teamMember: ITeamMember): boolean {
      return (
        !teamMember.first_name ||
        !teamMember.last_name ||
        !teamMember.email ||
        (teamMember.email && !this.checkValidEmail(teamMember.email)) ||
        !teamMember.position
      );
    },

    getTeamMemberName(teamMember: ITeamMember) {
      if (teamMember.first_name || teamMember.last_name) {
        return `${teamMember.first_name} ${teamMember.last_name}`;
      } else {
        return this.$t("teamManagement.newMember");
      }
    },

    cardTitleStyles(teamMember: ITeamMember): any {
      if (!teamMember.first_name && !teamMember.last_name) {
        return "blue";
      }
    },

    cardDescription(teamMember: ITeamMember): any {
      if (this.isMissingRequiredInformation(teamMember)) {
        return this.$t("affiliates.reviewPage.content.missingUserDetails");
      }
    },

    missingDataCardStyles(teamMember: ITeamMember) {
      if (!this.isMissingRequiredInformation(teamMember)) {
        return {
          ...this.answeredCardStyles,
          cta: {
            ...this.answeredCardStyles.cta,
            label: this.$t("common.answer"),
          },
        };
      }

      return this.notAnsweredCardStyles;
    },

    navigateToTeamMembersPage() {
      this.$emit("go-to-team-members");
    },
  },
});
