







































import PxSidebarDivisor from "@/components/px-sidebar-divisor/px-sidebar-divisor.vue";
import MilestoneLevel from "@/modules/milestone-planner/components/milestone-level/milestone-level.vue";
import { IMatchingAnswer } from "@/services/data/matching-questionary/matching-answer.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import Vue from "vue";
import { IGridCategory } from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { IAssessmentLevel } from "@/services/data/assessment/assessment.interface";
import { ICategory } from "@/services/data/category/category.interface";

export interface IMilestoneResponse extends Omit<IMatchingResponse, "answers"> {
  answers: Array<IMatchingAnswer>;
}

export default Vue.extend({
  name: "MilestoneSidebar",
  components: {
    MilestoneLevel,
    PxSidebarDivisor,
  },

  props: {
    category: {
      type: Object as () => IGridCategory,
      required: true,
    },
    lastAssessmentData: {
      type: Array as () => IAssessmentLevel[],
      required: true,
    },
    categories: {
      type: Array as () => ICategory[],
      required: true,
    },
  },

  data() {
    return {
      numberOfQuestions: 3,
    };
  },

  computed: {
    // questions(): Array<IMilestoneResponse> {
    //   // TODO: return matching responses/questions from the store
    //   return questions.slice(0, this.numberOfQuestions);
    // },

    categoryDescription(): string {
      const selectedCategory = this.categories.find(
        (category) => category.name === this.category.name,
      );
      return selectedCategory ? selectedCategory.description : "";
    },

    categoryLevel(): number | null {
      const categoryLevelData = this.lastAssessmentData.find(
        (data) => data.category === this.category?.id,
      );

      return categoryLevelData?.level ? categoryLevelData.level : 1;
    },
  },
});
