












import Vue from "vue";

import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  methods: {
    goToProfile() {
      this.$router.push({
        name: ROUTE_PROFILE,
        params: {
          id: this.id.toString(),
        },
      });
    },
  },
});
