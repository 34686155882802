




































































import Vue from "vue";

import PxPageHeader from "@/components/px-page-header/px-page-header.vue";
import ShareResults from "@/modules/capital-explorer/components/share-results/share-results.vue";
import UserResponsesTable from "@/modules/capital-explorer/components/comparison-tables/user-responses-table.vue";
import CompareResponsesTable from "@/modules/capital-explorer/components/comparison-tables/compare-responses-table.vue";
import MatchDetailsTableLegend from "@/components/match-details-table/match-details-table-legend.vue";
import RolePlayingBanner from "@/modules/capital-explorer/components/role-playing-banner/role-playing-banner.vue";

import { TranslateResult } from "vue-i18n";
import { CAPITAL_EXPLORER } from "@/modules/capital-explorer/services/router/routes-names";
import {
  ICapitalTileInfo,
  ICapitalExplorerData,
  ICapitalExplorerSubmission,
} from "@/modules/capital-explorer/services/data/capital-explorer/capital-explorer.interface";
import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { ECapitalExplorerActions } from "@/modules/capital-explorer/services/store/capital-explorer/capital-explorer.types";
import { calculateResults } from "@/modules/capital-explorer/calculate-results";
import cloneDeep from "lodash/cloneDeep";
import {
  EPxButtonSize,
  EPxButtonVariant,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "ComparisonTableView",

  components: {
    PxPageHeader,
    UserResponsesTable,
    CompareResponsesTable,
    MatchDetailsTableLegend,
    ShareResults,
    RolePlayingBanner,
  },

  static: {
    backButton: {
      icon: "arrow--right-manatee",
      size: EPxButtonSize.XX_SMALL,
      variant: EPxButtonVariant.COMPACT,
    },
  },

  data() {
    return {
      showLeftButton: false,
      showRightButton: true,
      scrollAmount: 992,
      dataPrefilled: false,
      originX: 0,
      originLeft: 0,
      scrollContainer: null as null | HTMLElement,
    };
  },

  computed: {
    headerCopy(): TranslateResult {
      return this.$t("capitalExplorer.comparisonTable");
    },

    capitalExplorerData(): ICapitalExplorerData | null {
      return this.$store.get("capitalExplorer/data") || null;
    },

    capitalExplorerTilesResults(): ICapitalTileInfo[] | null {
      return this.capitalExplorerData?.results || null;
    },

    capitalExplorerTilesOrdered(): ICapitalTileInfo[] | null {
      const capitalExplorerResults = cloneDeep(
        this.capitalExplorerTilesResults,
      );

      return (
        capitalExplorerResults?.sort(
          (a, b) =>
            (b.matchProperties.score || 0) - (a.matchProperties.score || 0),
        ) || null
      );
    },

    loading(): boolean {
      return this.$store.get("capitalExplorer/loading");
    },

    questions(): IMatchingQuestion[] {
      return this.capitalExplorerData?.questions || [];
    },

    submission(): ICapitalExplorerSubmission {
      return this.capitalExplorerData?.submission as ICapitalExplorerSubmission;
    },

    responses(): IMatchingResponse[] {
      return this.submission?.responses || [];
    },

    tableHeaders(): Array<string> {
      return (
        this.capitalExplorerTilesOrdered?.map((result: ICapitalTileInfo) => {
          return result.capitalInfo.title;
        }) || []
      );
    },

    userResponsesTableData(): any {
      return this.questions.map((question) => {
        const questionResponse = this.responses.find(
          (response) => response.question === question.id,
        );

        return {
          id: question.id,
          question: question.entrepreneur_question,
          response:
            questionResponse?.answers.map(
              (answer) =>
                question?.answers.find(({ id }) => id === answer)?.value || "",
            ) || [],
          instructions: question.instructions,
        };
      });
    },

    compareResponsesTableData(): Array<any> {
      return this.questions.map((question) => {
        return this.capitalExplorerTilesOrdered?.map((tile) => {
          const tileDetails = tile.matchDetails.find(
            (detail) => detail.question.id === question.id,
          );

          return {
            responses: tileDetails?.desiredAnswers,
            matchIcon: !tileDetails?.userAnswers.length
              ? "unanswered"
              : tileDetails.match
              ? "match--green"
              : "no-match",
          };
        });
      });
    },

    tableLegend(): TranslateResult {
      return this.$t(`capitalExplorer.detailsModal.legend`);
    },

    allQuestionsAnswered(): boolean {
      return this.responses.length === this.questions.length;
    },
  },

  async created() {
    if (!this.capitalExplorerTilesResults?.length) {
      await this.$store.dispatch(ECapitalExplorerActions.FETCH_DATA);
      await this.$store.dispatch(
        ECapitalExplorerActions.FETCH_AUTH_USER_SUBMISSION,
      );

      calculateResults();
    }

    this.dataPrefilled = true;
  },

  mounted() {
    this.scrollContainer = this.$refs.scrollContainer as HTMLElement;
  },

  methods: {
    goToCapitalExplorer() {
      return this.$router.push({ name: CAPITAL_EXPLORER });
    },

    handleScroll() {
      if (!this.scrollContainer) return;

      const maxScroll =
        this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;

      this.showLeftButton = this.scrollContainer.scrollLeft > 0;
      this.showRightButton =
        Math.ceil(this.scrollContainer.scrollLeft) < maxScroll;
    },

    scroll(direction: string) {
      const scrollAmount =
        direction === "left" ? -this.scrollAmount : this.scrollAmount;

      this.scrollContainer?.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },

    handleMouseDown(event: MouseEvent) {
      // Add active class to change cursor style
      this.scrollContainer?.classList.add("active");

      this.originX = event.pageX;
      this.originLeft = this.scrollContainer?.scrollLeft || 0;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },

    onMouseUp() {
      // Remove previously added active class
      this.scrollContainer?.classList.remove("active");

      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },

    onMouseMove(event: MouseEvent) {
      if (!this.scrollContainer) return;

      event.preventDefault();

      const offset = event.pageX - this.originX;
      const scrollSpeed = 1.5;

      this.scrollContainer.scrollLeft = this.originLeft - offset * scrollSpeed;
    },
  },
});
