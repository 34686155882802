


























































































































import Vue from "vue";

import {
  AUTHENTICATION_TERMS_OF_USE_URL,
  AUTHENTICATION_PRIVACY_POLICY_URL,
} from "@/modules/authentication/constants";

import {
  allFormFieldsValid,
  generateEmailValidator,
  generatePasswordCheck,
  generatePasswordValidator,
  generateCheckboxValidator,
} from "@/services/errors/validator-generators";
import { ElForm } from "element-ui/types/form";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default Vue.extend({
  name: "SignUpStepAccount",

  props: {
    account: {
      type: Object as () => any,
      required: true,
    },

    errors: {
      type: Object as () => any,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  static: {
    termsOfUseUrl: AUTHENTICATION_TERMS_OF_USE_URL,
    privacyPolicyUrl: AUTHENTICATION_PRIVACY_POLICY_URL,
  },

  data() {
    return {
      submissionDisabled: true,
      formEl: null as null | ElForm,
      innerAccount: {
        email: "",
        password1: "",
        password2: "",
        terms: false,
      },

      rules: {
        email: generateEmailValidator(
          this,
          "authentication.signup.form.fields.email",
          true,
        ),
        password1: generatePasswordValidator(
          this,
          "authentication.signup.form.fields.password",
          {
            minimum: 8,
            enforceCharRequirements: true,
          },
        ),
        password2: generatePasswordCheck(this, "innerAccount.password1", {
          minimum: 8,
        }),
        terms: generateCheckboxValidator(),
      },
    };
  },

  computed: {
    formFields(): IFormField[] | null {
      return this.formEl ? ((this.formEl as any).fields as IFormField[]) : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.signupForm;
  },

  methods: {
    updateSubmitState() {
      this.submissionDisabled = !allFormFieldsValid(this.formEl, this.rules);

      if (!this.submissionDisabled) {
        this.$emit("update:account", this.innerAccount);
      }
    },

    goToPreviousStep() {
      this.$emit("previous-step");
    },

    submitForm() {
      this.$emit("submit-form");
    },

    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.formEl?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
