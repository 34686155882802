































import Vue from "vue";

export default Vue.extend({
  name: "MatchingCardOptions",

  data() {
    return {
      optionsVisible: false,
    };
  },

  computed: {
    ctaCopy(): string {
      return this.$t("common.components.default.cta.removeFromList") as string;
    },
  },

  methods: {
    onVisibleChange(state: boolean) {
      this.optionsVisible = state;
      this.$emit("is-options-open", this.optionsVisible);
    },

    deleteClickHandler() {
      this.$emit("delete");
    },
  },
});
