

























import Vue from "vue";
import MatchingCardWrapper from "@/components/matching-card/matching-card-wrapper.vue";

export default Vue.extend({
  name: "MatchingCardPlaceholder",

  components: {
    MatchingCardWrapper,
  },
});
