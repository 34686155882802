import Vue, { VNode } from "vue";
import DialogConfirmation from "./dialog-confirmation.vue";

const APPEND_ELEMENT_SELECTOR = ".el-dialog";

Vue.directive("dialog-confirmation", {
  bind(el: HTMLElement, binding: any, vnode: VNode) {
    const propsDefaults = {
      dialog: vnode.componentInstance,
      message: "",
      tip: "",
      cancelButtonType: "link",
      cancelButtonText: undefined,
      cancelButtonClass: undefined,
      confirmButtonType: "red",
      confirmButtonText: undefined,
      confirmButtonClass: undefined,
      confirmFn: undefined,
      cancelFn: undefined,
    };

    // Events that causes a confirmation overlay to display
    const events = binding.value;

    if (!Array.isArray(events)) {
      return;
    }

    const overlayInstances: Array<any> = [];

    for (const i in events) {
      if (!Object.prototype.hasOwnProperty.call(events, i)) {
        continue;
      }

      // Create OverlayConfirm component for each event
      const overlayConfirmInstance = new DialogConfirmation({
        el: document.createElement("div"),
        propsData: { ...propsDefaults, ...events[i] },
      }).$mount();

      overlayInstances.push(overlayConfirmInstance);

      // insert confirmation overlay element into DOM
      const dialogElement = el.querySelector(APPEND_ELEMENT_SELECTOR);
      if (!dialogElement) {
        return;
      }

      dialogElement.appendChild(overlayConfirmInstance.$el);

      vnode.componentInstance?.$on(events[i].eventName, (payload: any) => {
        if (
          events[i].showCondition === undefined ||
          events[i].showCondition()
        ) {
          // Prevent multiple overlays from appearing at the same time on the same dialog
          overlayInstances.map((overlay: any) => overlay.hide());
          (overlayConfirmInstance as any).show();
          // Include payload for confirm callback:
          overlayConfirmInstance.$options.static.payload = payload;
        } else {
          // execute confirm function
          events[i].confirmFn();
        }
      });
    }

    // inject overlay instances on target component instance
    (vnode.context as any).overlayInstances = overlayInstances;

    return events;
  },

  update(_, binding, vnode: VNode) {
    const overlayInstances: Array<Vue> = (vnode.context as any)
      .overlayInstances;

    // Iterate through all the overlay instances and update the props. We don't have a way to know wish overlay
    // instances changed, so we must update all of them.
    overlayInstances.map((overlayEl: any, index) => {
      const newPropsData = binding.value[index];
      overlayEl._props = { ...overlayEl.$props, ...newPropsData };
    });
  },
});
