import Vue from "vue";
import {
  MATCHING_INITIAL_INTEREST,
  MATCHING_HAS_INTEREST,
  MATCHING_CONNECTED_INTEREST,
  MATCHING_USER_TYPES,
  MATCHING_SELECTED_TAB,
  MATCHING_PENDING_SELECTED_TAB,
} from "@/modules/matching/constants";

import { EMatchingInterestActions } from "@/modules/matching/services/store/matching-interest/matching-interest.types";
import { IMatchingInterest } from "@/modules/matching/services/data/matching-interest/matching-interest.interface";
import { ICompany } from "@/modules/profile/services/data/company/company.types";

import PxMatchingInterestRemovalModal from "@/components/px-matching-interest/px-matching-interest-removal-modal.vue";
import PxMatchingInterestDirectoryMemberModal from "@/components/px-matching-interest/px-matching-interest-directory-member-modal.vue";
import { ROUTE_MATCHING_LIST } from "@/modules/matching/services/router/routes-names";
import {
  EPxButtonSize,
  EPxButtonType,
} from "@/components/px-button/px-button.types";
import { EMetaActions } from "@/services/store/meta/meta-types";
import {
  EMatchingListPendingTabs,
  EMatchingListTabs,
} from "@/modules/matching/components/matching-list-desktop/matching-tabs.types";

export default Vue.extend({
  name: "PxMatchingInterestMixin",

  components: {
    PxMatchingInterestRemovalModal,
    PxMatchingInterestDirectoryMemberModal,
  },

  props: {
    /**
     * Company Id
     */
    id: {
      type: Number,
      required: true,
    },

    /**
     * Company Uid
     */
    uid: {
      type: String,
      required: false,
    },

    /**
     * Company Name
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * CTA size
     */
    size: {
      type: String,
      default: EPxButtonSize.MEDIUM,
    },

    /**
     * Is Directory member
     */
    isDirectoryMember: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      interestCTAVisibility: true,
      isRemoveInterestModalVisible: false,
      isDirectoryMemberModalVisible: false,
      isCtaHover: false,
      cta: {
        types: [
          EPxButtonType.GREEN,
          EPxButtonType.GHOST_GREEN,
          EPxButtonType.GHOST_YELLOW,
          EPxButtonType.GREEN,
          EPxButtonType.GHOST,
        ],
        icons: [
          "connect",
          "check--success",
          "connected",
          "check--white-14",
          "cancel--gray",
        ],
        iconSizes: {
          default: 16,
          small: 10,
        },
      },
    };
  },
  computed: {
    /**
     * Get auth user company
     */
    authCompany(): ICompany {
      return this.$store.state.auth.company.data;
    },

    /**
     * Get all matching interests
     */
    matchingInterests(): IMatchingInterest[] {
      return this.$store.state.matchingInterest.data;
    },

    hasServerError(): boolean {
      return !!this.$store.state.matchingInterest.error;
    },

    // TODO: Add loading in mixin implementations
    isLoading(): boolean {
      return (
        this.$store.state.matchingInterest.loading ||
        this.$store.state.auth.company.loading
      );
    },

    /**
     * Get auth user type
     */
    userType(): string {
      return MATCHING_USER_TYPES[this.$user.getUserAccountType()];
    },

    /**
     * Get target interest user type
     */
    targetType(): string {
      const oppositeUserType = +!this.$user.getUserAccountType();
      return MATCHING_USER_TYPES[oppositeUserType];
    },

    /**
     * Return current interest state if available
     */
    currentMatchingInterest(): IMatchingInterest | null {
      if (
        this.matchingInterests &&
        this.matchingInterests.length &&
        !!this.authCompany
      ) {
        return (
          this.matchingInterests.find(
            (interest: IMatchingInterest) =>
              interest[this.userType] === this.authCompany.id &&
              interest[this.targetType] === this.id,
          ) || null
        );
      }

      return null;
    },

    /**
     * Return auth user interest
     */
    userInterest(): number {
      return this.currentMatchingInterest
        ? this.currentMatchingInterest[`${this.userType}_is_interested`]
        : MATCHING_INITIAL_INTEREST;
    },

    /**
     * Return final interest between both parties
     */
    interestState(): number {
      return this.currentMatchingInterest
        ? this.currentMatchingInterest.state_of_interest
        : MATCHING_INITIAL_INTEREST;
    },

    isConnected(): boolean {
      return this.interestState === MATCHING_CONNECTED_INTEREST;
    },

    /**
     * If already connected, return final interest
     * between both parties
     *
     * Else, return target interest
     */
    targetInterest(): number {
      if (this.isConnected) {
        return this.interestState;
      }

      return this.currentMatchingInterest
        ? this.currentMatchingInterest[`${this.targetType}_is_interested`]
        : MATCHING_INITIAL_INTEREST;
    },

    /**
     * Map interest state with CTA types
     * Returns current interest state, if initial or connected
     * OR
     * Returns a specific has interest state, either of user or target interest
     */
    currentCTAState(): number {
      return [
        this.interestState === MATCHING_INITIAL_INTEREST,
        this.userInterest > this.targetInterest && !this.isCtaHover,
        this.interestState === MATCHING_CONNECTED_INTEREST,
        this.userInterest < this.targetInterest,
        this.userInterest > this.targetInterest && this.isCtaHover,
      ].findIndex((state: boolean) => state);
    },

    ctaType(): string {
      return this.currentCTAState !== null
        ? this.cta.types[this.currentCTAState]
        : "";
    },

    ctaText(): string {
      // If the cta is hovered & the cta has hover text
      if (
        this.isCtaHover &&
        this.$te(
          `matching.matchingInterest.cta[${this.currentCTAState}].hoverText`,
        )
      ) {
        // If cta size is medium
        if (this.size === "medium") {
          return this.$tc(
            `matching.matchingInterest.cta[${this.currentCTAState}].hoverText`,
            2,
          ) as string;
        }
        // If cta size is small
        else {
          return this.$tc(
            `matching.matchingInterest.cta[${this.currentCTAState}].hoverText`,
            1,
          ) as string;
        }
      } else {
        return this.size === "medium"
          ? (this.$tc(
              `matching.matchingInterest.cta[${this.currentCTAState}].text`,
              2,
            ) as string)
          : (this.$tc(
              `matching.matchingInterest.cta[${this.currentCTAState}].text`,
              1,
            ) as string);
      }
    },

    ctaTip(): string {
      return this.$t(
        `matching.matchingInterest.tip[${this.currentCTAState}]`,
      ) as string;
    },

    ctaIcon(): string {
      return this.currentCTAState !== null
        ? this.cta.icons[this.currentCTAState]
        : "";
    },

    showDirectoryMemberModal(): boolean {
      // If is a directory member, show a information modal
      return (
        this.isDirectoryMember &&
        !this.isDirectoryMemberModalVisible &&
        this.userInterest === MATCHING_INITIAL_INTEREST
      );
    },
  },

  methods: {
    // When user clicks on page link, go to matching page
    async goToMatchingPage(page: EMatchingListTabs) {
      await this.$store.dispatch(EMetaActions.SET, {
        key: MATCHING_SELECTED_TAB,
        value: page,
      });

      if (page === EMatchingListTabs.PENDING) {
        await this.$store.dispatch(EMetaActions.SET, {
          key: MATCHING_PENDING_SELECTED_TAB,
          value: EMatchingListPendingTabs.IM_INTERESTED_IN,
        });
      }

      this.$router.push({
        name: ROUTE_MATCHING_LIST,
      });
    },

    displayMessage(
      message: string,
      page: string,
      matchingPage = EMatchingListTabs.DISCOVER,
    ) {
      // If page string exists, it need to be bold and underline
      if (page) {
        const h = this.$createElement;
        const v = (this as any)._v;
        const vNode = h("p", { staticClass: "el-message__content" }, [
          v(`${message} `),
          h(
            "el-button",
            {
              props: {
                type: "link",
              },
              on: {
                click: () => this.goToMatchingPage(matchingPage),
              },
              staticClass: "el-button--link-white",
            },
            page,
          ),
        ]);

        this.$message({
          message: vNode,
          type: "success",
          customClass: "is-full",
        });
      } else {
        this.$message({
          message,
          type: "success",
          customClass: "is-full",
        });
      }
    },
    emitInterest() {
      let message;
      let page;
      // From matching-list-cards
      if (
        this.userInterest === MATCHING_INITIAL_INTEREST &&
        this.interestState === MATCHING_INITIAL_INTEREST
      ) {
        message = this.$t(
          "matching.matchingInterest.matchingList.messages.movedToInterest",
          { targetName: this.name },
        ) as string;

        page = this.$t(
          "matching.matchingInterest.matchingList.messagesPage.movedToInterest",
        ) as string;

        return this.displayMessage(message, page, EMatchingListTabs.PENDING);
      }
      // From matching-list-interests-targets
      if (
        this.userInterest === MATCHING_INITIAL_INTEREST &&
        this.interestState === MATCHING_HAS_INTEREST
      ) {
        message = this.$t(
          "matching.matchingInterest.matchingList.messages.movedToMutual",
          { targetName: this.name },
        ) as string;

        page = this.$t(
          "matching.matchingInterest.matchingList.messagesPage.movedToMutual",
        ) as string;

        return this.displayMessage(message, page, EMatchingListTabs.CONNECTED);
      }

      // From matching-list-interests-mutual
      if (this.userInterest !== this.interestState) {
        message = this.$t(
          "matching.matchingInterest.matchingList.messages.removedOfConnection",
          { targetName: this.name },
        ) as string;

        return this.displayMessage(message, "");
      }

      // From matching-list-interests-user
      if (this.userInterest === MATCHING_HAS_INTEREST) {
        message = this.$t(
          "matching.matchingInterest.matchingList.messages.removedOfInterest",
          { targetName: this.name },
        ) as string;

        return this.displayMessage(message, "");
      }
    },
    registerInterest(newState: number) {
      this.emitInterest();
      // Prepared for optimistic ui
      this.$store.dispatch(EMatchingInterestActions.REGISTER, {
        [this.userType]: this.authCompany.id,
        [this.targetType]: this.id,
        [`${this.userType}_is_interested`]: newState,
      });
    },

    async clickInterestCTAHandler(event: Event) {
      if (event) {
        event.stopPropagation();
      }

      if (this.showDirectoryMemberModal) {
        this.isDirectoryMemberModalVisible = true;
        return;
      }

      this.isDirectoryMemberModalVisible = false;

      if (this.userInterest === MATCHING_INITIAL_INTEREST) {
        this.onConfirmedInterest();
      } else if (
        this.userInterest === MATCHING_HAS_INTEREST ||
        this.userInterest === MATCHING_CONNECTED_INTEREST
      ) {
        this.isRemoveInterestModalVisible = true;
      }
    },

    onConfirmedInterest() {
      this.registerInterest(MATCHING_HAS_INTEREST);
    },

    onRemovedInterest() {
      this.registerInterest(MATCHING_INITIAL_INTEREST);
      this.isRemoveInterestModalVisible = false;
    },

    clickInterestCompactHandler(event: Event) {
      event.stopPropagation();

      if (this.userInterest === MATCHING_INITIAL_INTEREST) {
        this.registerInterest(MATCHING_HAS_INTEREST);
      } else if (
        this.userInterest === MATCHING_HAS_INTEREST ||
        this.userInterest === MATCHING_CONNECTED_INTEREST
      ) {
        this.registerInterest(MATCHING_INITIAL_INTEREST);
      }
    },

    interestCTAVisibilityChangeHandler(isVisible: boolean) {
      this.interestCTAVisibility = isVisible;
    },
  },
});
