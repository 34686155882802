import { RouteConfig } from "vue-router";
import {
  ROUTE_ENTREPRENEUR_SUBMISSION,
  ROUTE_SUPPORTER_SUBMISSION,
} from "@/modules/program/services/router/routes-names";
import SubmissionView from "@/modules/program/views/submission.view.vue";
import AuthenticatedWithNavigationView from "@/modules/common/views/authenticated-with-navigation.view.vue";

export const routes: RouteConfig[] = [
  {
    path: "/program/submission/:uid",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: "",
        name: ROUTE_ENTREPRENEUR_SUBMISSION,
        component: SubmissionView,
      },
    ],
  },
  {
    path: "/program/supporter/submission/:uid",
    component: AuthenticatedWithNavigationView,
    children: [
      {
        path: "",
        name: ROUTE_SUPPORTER_SUBMISSION,
        component: SubmissionView,
        meta: {
          auth: true,
        },
      },
    ],
  },
];
