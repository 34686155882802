















































import Vue from "vue";

export interface ISliderCardStop {
  label: string;
  value: number;
}

export default Vue.extend({
  name: "PxSliderCard",

  props: {
    value: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    sliderStops: {
      type: Array as () => ISliderCardStop[],
      required: true,
    },
    // Place the slider button on the middle if stops count is odd.
    startOnMiddle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedStop: 0,
    };
  },

  computed: {
    sliderCount(): number {
      return this.sliderStops.length;
    },
    sliderOddStops(): Array<ISliderCardStop> {
      // Get only odd indexed items
      return this.sliderStops.filter((value, index) => !(index % 2));
    },
    currentStopValue(): number | null {
      const selectedStopIndex = this.selectedStop - 1;

      return this.sliderStops[selectedStopIndex]
        ? this.sliderStops[selectedStopIndex].value
        : null;
    },
  },

  watch: {
    /**
     * Update slider value when the prop value changes.
     */
    currentStopValue(currentValue) {
      if (!!currentValue && typeof this.value !== "number") {
        this.$emit("input", this.currentStopValue);
      }
    },
  },

  mounted() {
    if (this.value) {
      this.selectedStop = this.value;
      return;
    }

    const sliderCountIsOdd = this.sliderStops.length % 2 !== 0;

    if (this.startOnMiddle && sliderCountIsOdd) {
      // Find middle index of slider stops
      this.selectedStop = Math.round(this.sliderStops.length / 2);
    }
  },

  methods: {
    formatTooltip(stopIndex: number): string | number {
      const selectedStopIndex = stopIndex - 1;

      return this.sliderStops[selectedStopIndex]
        ? this.sliderStops[selectedStopIndex].label
        : stopIndex;
    },

    sliderChangeHandler() {
      this.$emit("input", this.currentStopValue);
      this.$emit("changed", this.currentStopValue);
    },
  },
});
