































import Vue from "vue";

import { TranslateResult } from "vue-i18n";
import {
  EPxButtonSize,
  EPxButtonVariant,
  EPxButtonType,
} from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "TeamMemberSettingsHeader",

  static: {
    saveButton: {
      size: EPxButtonSize.MEDIUM,
      variant: EPxButtonVariant.FLUID,
      type: EPxButtonType.BLUE,
    },
  },

  props: {
    isSaveButtonDisabled: {
      type: Boolean,
      default: false,
    },

    isFromProfile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title(): TranslateResult {
      return this.isFromProfile
        ? this.$t("teamManagement.settings.profileTitle")
        : this.$t("teamManagement.settings.title");
    },

    subtitle(): TranslateResult {
      return this.$t("teamManagement.settings.subtitle");
    },

    saveButtonProps(): any {
      return {
        ...this.$options.static.saveButton,
        label: this.$t("common.saveChanges"),
      };
    },
  },
});
