






















































































import Vue from "vue";

import PxStatusBadge from "@/components/px-status-badge/px-status-badge.vue";

import { INavBarItem } from "@/modules/profile/meta/entrepreneurs";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

export default Vue.extend({
  name: "PxMenu",

  components: {
    PxStatusBadge,
  },

  props: {
    /**
     * Items for the menu
     */
    items: {
      type: Array as () => Array<INavBarItem>,
      required: true,
    },

    /**
     * Modifier for the appearance.
     */
    mode: {
      type: String,
      default: "normal",
      validator(value: string) {
        return ["normal", "even"].includes(value);
      },
    },

    /**
     * When this is defined the component will use a `router-link`
     * to handle the menu elements instead of using the v-router.
     */
    useRouter: {
      type: Boolean,
      default: true,
    },

    /**
     * Current selected item.
     *
     * This is only used when the `useRouter` is set to `false`.
     */
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      innerValue: "",
    };
  },

  static: {
    newFeatureLabel: {
      label: "New",
      icon: "stars--white",
      iconSize: 19,
      textColor: "#fff",
      backgroundColor: "#3b82f6",
      iconPosition: "right",
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.innerValue = newVal;
      },
    },

    /**
     * Emit value updated to the outside world.
     */
    innerValue(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.$emit("input", newVal);
    },
  },

  methods: {
    /**
     * Handles the click on the item.
     */
    onClickItem(key: string) {
      this.innerValue = key;
    },

    checkItemShouldBeActive(item: INavBarItem) {
      const notProfileOwner =
        this.$route.name === ROUTE_PROFILE && !!this.$route.params.id;

      if (!item.includedRoutes?.length || !this.$route.name || notProfileOwner)
        return false;

      return item.includedRoutes.includes(this.$route.name);
    },

    showSubMenuItemsDropdown(item: INavBarItem) {
      return item.subMenuItems && item.subMenuItems.length > 1;
    },

    showSubMenuItem(item: INavBarItem) {
      return item.subMenuItems && item.subMenuItems.length == 1;
    },

    goToPage(to: string) {
      if (to === this.$route.name) return;

      this.$router.push({ name: to });
    },
  },
});
