






























































import SupportersFlowMixin from "@/modules/supporters/mixins/supporters-flow.mixin";
import FormPresentationLayout from "@/modules/supporters/components/form-presentation-layout/form-presentation-layout.vue";

import { TranslateResult } from "vue-i18n";
import { ISupporterFlow } from "@/modules/supporters/services/data/supporter-flow/supporter-flow.interface";
import { IFormField } from "@/modules/company-lists/components/list-management/list-management-modal.vue";

export default SupportersFlowMixin.extend({
  name: "SetupPassword",

  components: {
    FormPresentationLayout,
  },

  data() {
    return {
      fields: {
        password1: "",
        password2: "",
      } as Partial<ISupporterFlow>,
    };
  },

  mounted() {
    if (this.flowData) {
      this.fields.password1 = this.flowData.password1 || this.fields.password1;
      this.fields.password2 = this.flowData.password2 || this.fields.password2;
    }

    // Check if should validate
    if (this.fields.password1 || this.fields.password2) {
      this.currentForm?.validate();
    }
  },

  computed: {
    imageLink(): TranslateResult {
      return this.$t("supporters.view.setupPassword.imageLink");
    },

    viewCopy(): TranslateResult {
      return this.$t("supporters.view.setupPassword");
    },

    formFields(): IFormField[] | null {
      return this.currentForm
        ? ((this.currentForm as any).fields as IFormField[])
        : null;
    },

    passwordIsInvalid(): boolean {
      if (this.formFields) {
        const formFields = this.formFields;

        const passwordField = formFields.find(
          (field) => field.prop === "password1",
        );

        return passwordField?.validateState !== "success";
      }
      return true;
    },
  },

  methods: {
    validateConfirmationField() {
      if (!this.passwordIsInvalid) {
        this.currentForm?.validateField("password2", this.updateSubmitState);
      }
    },
  },
});
