import VueI18n from "vue-i18n";
import moment from "moment";
import merge from "lodash/merge";

import { getAppPartner } from "@/services/utils/utils";
import { VUE_APP_PARTNER_ESO } from "@/constants/environments";

export const SUPPORTED_LOCALES = ["en", "es"];
export const DEFAULT_LOCALE = "en";

export const loadedLocales: string[] = [];

/**
 * Check if provided locale is supported.
 *
 * @export
 * @param {string} locale
 * @return {*}  {boolean}
 */
export function isLocaleSupported(locale: string): boolean {
  return SUPPORTED_LOCALES.includes(locale);
}

/**
 * Get browser locale.
 *
 * Tries to get browser locale from experimental property Navigator.languages;
 * Falls back to Navigator.language if the former is not supported.
 *
 * @param {Navigator} navigator
 * @return {*}  {string}
 */
function getBrowserLocale(navigator: Navigator): string {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  return navigatorLocale?.trim().split(/-|_/)[0];
}

/**
 * Get locale to be used to initialise the application.
 *
 * @export
 * @param {Navigator} navigator
 * @return {*}  {string}
 */
export function getInitialLocale(navigator: Navigator): string {
  const browserLocale = getBrowserLocale(navigator);

  return browserLocale && isLocaleSupported(browserLocale)
    ? browserLocale
    : DEFAULT_LOCALE;
}

/**
 * Update document language with provided locale.
 *
 * @param {Document} document
 * @param {string} locale
 */
function setDocumentLang(document: Document, locale: string) {
  document.documentElement.lang = locale;
}

/**
 * Lazy load messages for provided locale.
 *
 * @param {VueI18n} i18n
 * @param {string} locale
 * @param {string[]} loadedLocales
 */
async function loadLocale(
  i18n: VueI18n,
  locale: string,
  loadedLocales: string[],
) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/services/i18n/translations/${locale}`
  );
  const partnerMessages =
    getAppPartner() === VUE_APP_PARTNER_ESO
      ? await import(
          /* webpackChunkName: "locale-partner-[request]" */ `@/services/i18n/translations/${locale}.eso`
        ).catch((error) => console.warn(error))
      : {};

  i18n.setLocaleMessage(
    locale,
    merge(messages.default, partnerMessages?.default),
  );
  loadedLocales.push(locale);

  if (locale !== DEFAULT_LOCALE) {
    // Globally load moment locale:
    await import(
      /* webpackChunkName: "locale-moment-[request]" */ `moment/locale/${locale}`
    );
  }
}

/**
 * Change locale on i18n.
 * Loads locale messages if not yet loaded.
 *
 * @export
 * @param {VueI18n} i18n
 * @param {string} locale
 * @param {string[]} loadedLocales
 * @param {Document} document
 * @return {*}  {Promise<string>}
 */
export async function changeLocale(
  i18n: VueI18n,
  locale: string,
  loadedLocales: string[],
  document: Document,
): Promise<string> {
  if (!isLocaleSupported(locale)) {
    return Promise.reject(`Provided locale "${locale}" is not supported.`);
  }

  if (loadedLocales.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale);
  }

  if (!loadedLocales.includes(locale)) {
    await loadLocale(i18n, locale, loadedLocales);
  }

  i18n.locale = locale;
  setDocumentLang(document, locale);

  // Temporary locale persistance
  localStorage.setItem("userLocale", locale);

  // Globally set moment locale:
  moment.locale(locale);

  return Promise.resolve(locale);
}
