
























































import FormatMatchingResponseValuesMixin from "@/mixins/format-matching-response-values.mixin";

import PxInformationCard from "@/components/px-information-card/px-information-card.vue";

import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import {
  IAffiliatesState,
  EAffiliatesActions,
} from "@/modules/affiliates/services/store/affiliates/affiliates.types";

import {
  DATE,
  FREE_RESPONSE,
  IMatchingQuestion,
  MULTI_SELECT,
  NUMERIC,
  RANGE,
  SINGLE_SELECT,
} from "@/services/data/matching-questionary/matching-question.interface";

export default FormatMatchingResponseValuesMixin.extend({
  name: "AffiliateReviewPageQuestions",

  components: {
    PxInformationCard,
  },

  data() {
    return {
      responseTypes: {
        freeResponse: FREE_RESPONSE,
        singleSelect: SINGLE_SELECT,
        multiSelect: MULTI_SELECT,
        numeric: NUMERIC,
        range: RANGE,
        dateResponse: DATE,
      } as any | null,
    };
  },

  computed: {
    affiliatesData(): IAffiliatesState {
      return this.$store.state.affiliates;
    },

    responses(): Array<IMatchingResponse> {
      return this.affiliatesData.draftSubmission
        ? this.affiliatesData.draftSubmission.data.responses
        : [];
    },

    questions(): Array<IMatchingQuestion> {
      return this.affiliatesData.affiliateQuestionBundle || [];
    },

    notAnswered(): Partial<IMatchingResponse>[] {
      return this.responses.filter((response) => !response.isValid);
    },

    answered(): Partial<IMatchingResponse>[] {
      return this.responses.filter((response) => response.isValid);
    },

    missingQuestionsCopy(): any {
      return this.$tc(
        "affiliates.reviewPage.content.missingQuestions",
        this.notAnswered.length,
      );
    },

    notAnsweredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "warning--blue",
          iconSize: 16,
          textColor: "#7bb7ff",
          backgroundColor: "rgba(123, 183, 255, 0.16)",
        },
        cta: {
          label: this.$t("common.answer"),
          type: "default",
          size: "small",
        },
        backgroundColor: "rgba(123, 183, 255, 0.05)",
        border: "1px solid rgba(123,183, 255, 0.8)",
        descriptionStyles: "italic",
      };
    },

    answeredCardStyles(): any {
      return {
        label: {
          text: this.$t("common.required"),
          icon: "milestone-check",
          iconSize: 16,
          textColor: "#53af72",
          backgroundColor: "rgba(83, 175, 114, 0.12)",
        },
        cta: {
          label: this.$t("common.update"),
          type: "default",
          size: "small",
        },
        backgroundColor: "#fafafa",
        border: "1px solid rgba(220, 221, 226, 0.8)",
        descriptionStyles: "default",
      };
    },
  },

  methods: {
    getQuestion(questionId: number): IMatchingQuestion | null {
      return (
        this.questions.find((question) => question.id === questionId) || null
      );
    },

    getAnswer(response: IMatchingResponse): string {
      let formattedValue = "";

      const question = this.getQuestion(response.question as number);

      if (question) {
        if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isEntrepreneur()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isSupporter())
        ) {
          formattedValue = this.formatNumericValue(response);
        } else if (
          (this.getQuestionType(question, this.responseTypes.numeric) &&
            this.$user.isSupporter()) ||
          (this.getQuestionType(question, this.responseTypes.range) &&
            this.$user.isEntrepreneur())
        ) {
          formattedValue = this.formatRangeValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.freeResponse)
        ) {
          formattedValue = this.formatFreeResponseValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.dateResponse)
        ) {
          formattedValue = this.formatDateValue(response);
        } else if (
          this.getQuestionType(question, this.responseTypes.singleSelect) ||
          this.getQuestionType(question, this.responseTypes.multiSelect)
        ) {
          formattedValue =
            response?.answers && response?.answers.length
              ? this.formatMultipleAnswersValue(question, response)
              : "";
        }
      }

      return formattedValue;
    },

    getQuestionType(question: IMatchingQuestion, type: string): boolean {
      return question.question_type && question.question_type.type === type;
    },

    navigateToQuestion(id: number) {
      const questionIndex = this.questions.findIndex(
        (question) => question.id === id,
      );

      this.$store.dispatch(
        EAffiliatesActions.SET_USER_NAVIGATING_FROM_REVIEW_PAGE,
        true,
      );

      this.$emit("go-to-question", questionIndex);
    },
  },
});
