











































































































import Vue from "vue";
import moment from "moment";

import MilestonePlanOnboardingDialog from "@/modules/milestone-planner/components/milestone-plan-onboarding-dialog/milestone-plan-onboarding-dialog.vue";
import MilestonePlanHeader from "@/modules/milestone-planner/components/milestone-plan-header/milestone-plan-header.vue";
import MilestoneForm from "@/modules/milestone-planner/components/milestone-form/milestone-form.vue";
import MilestonePlanNavigationBar from "@/modules/milestone-planner/components/milestone-plan-navigation-bar/milestone-plan-navigation-bar.vue";
import MilestoneFormFooter from "@/modules/milestone-planner/components/milestone-form-footer/milestone-form-footer.vue";
import MatchingConfirmModal from "@/modules/matching/components/matching-confirm-modal.vue";

import {
  EUserMetadataActions,
  EUserMetadataGetters,
} from "@/modules/authentication/services/store/auth/sub-modules/user-metadata/user-metadata.types";
import { MILESTONE_PLANNER_PLAN_ONBOARDED } from "@/modules/milestone-planner/constants";
import {
  IGridMilestone,
  IGridCategory,
} from "@/modules/milestone-planner/components/milestones-grid/milestones-grid.interface";
import { EPxButtonType } from "@/components/px-button/px-button.types";
import {
  ICategory,
  ICategoryDetail,
} from "@/services/data/category/category.interface";
import { ILevel } from "@/services/data/level/level.interface";
import { ECategoryActions } from "@/services/store/category/category-types";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import {
  IMilestone,
  MILESTONE_DETAILS_TABS,
  TMilestoneDetailsTab,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { IQuestionBundle } from "@/services/data/question-bundle/question-bundle.interface";
import { QuestionBundlesState } from "@/modules/milestone-planner/services/store/milestone-planner/sub-modules/question-bundles/question-bundles.module";
import { ENTREPRENEUR_USER_GROUP_ID } from "@/modules/common/constants";
import isFinite from "lodash/isFinite";
import { EAuthMilestonePlannersActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestone-planners/auth-milestone-planners.types";
import { IMilestonePlanner } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { IAssessment } from "@/services/data/assessment/assessment.interface";

export default Vue.extend({
  name: "MilestoneDetailView",

  components: {
    MilestonePlanOnboardingDialog,
    MilestonePlanHeader,
    MilestoneForm,
    MilestonePlanNavigationBar,
    MilestoneFormFooter,
    MatchingConfirmModal,
  },

  data() {
    return {
      onboardingDialogVisibility: false,
      selectedCategory: null as null | IGridCategory,
      selectedLevel: null as null | ILevel["value"],
      planFormIsComplete: false,
      planFormHasChanges: false,
      planFormIsInvalid: false,
      completeFormIsInvalid: false,
      completeFormHasChanges: false,
      activeTab: MILESTONE_DETAILS_TABS.PLAN as TMilestoneDetailsTab,
      hasLocation: false,
      hasSectors: false,
      isMatchingConfirmModalVisible: false,
    };
  },

  static: {
    linkButton: {
      type: EPxButtonType.LINK,
    },
  },

  computed: {
    hasOnboarded(): boolean {
      return !!this.$store.get(
        EUserMetadataGetters.GET,
        MILESTONE_PLANNER_PLAN_ONBOARDED,
      );
    },

    backToOverviewButton(): any {
      return {
        ...this.$options.static.linkButton,
        label: this.$t(
          "milestonePlanner.milestonePlan.planView.backToOverviewLink",
        ),
      };
    },

    openDialogButtonCopy(): any {
      return {
        ...this.$options.static.linkButton,
        icon: "icon-question",
        label: this.$t("milestonePlanner.milestonePlan.planView.howToUseLink"),
      };
    },

    isLoadingCategories(): boolean {
      return this.$store.get("categories.loading");
    },

    isLoadingLevels(): boolean {
      return this.$store.get("levels.loading");
    },

    isLoadingMilestones(): boolean {
      return this.$store.get("auth/milestones.loading");
    },

    isLoadingQuestionBundles(): boolean {
      return this.$store.get("milestonePlanner/questionBundles.loading");
    },

    isLoadingView(): boolean {
      return this.isLoadingCategories || this.isLoadingLevels;
    },

    isLoadingForm(): boolean {
      return this.isLoadingMilestones || this.isLoadingQuestionBundles;
    },

    categories(): ICategory[] {
      return this.$store.get("categories.data");
    },

    levels(): ILevel[] {
      return this.$store.get("levels.data");
    },

    authMilestones(): IMilestone[] {
      return this.$store.get("auth/milestones.data");
    },

    currentMilestone(): IMilestone | null {
      return (
        this.authMilestones.find(
          (milestone) =>
            milestone.category_level.category.id ===
              this.selectedCategory?.id &&
            milestone.category_level.level === this.selectedLevel,
        ) || null
      );
    },

    gridCategories(): IGridCategory[] {
      return this.$store.get("auth/milestones.parsedData");
    },

    selectedGridCategory(): IGridCategory | null {
      return (
        this.gridCategories.find(
          (category) => category.id === this.selectedCategory?.id,
        ) || null
      );
    },

    selectedGridMilestone(): IGridMilestone | null {
      const milestones = this.selectedGridCategory?.milestones || [];
      return (
        milestones.find(
          (milestone: IGridMilestone) => milestone.level === this.selectedLevel,
        ) || null
      );
    },

    selectedCategoryLevel(): ICategoryDetail | null {
      const selectedCategory = this.categories.find(
        (category) => category.id === this.selectedCategory?.id,
      );

      if (!selectedCategory) {
        return null;
      }

      return (
        selectedCategory.categoryDetails.find(
          (categoryLevel) => categoryLevel.level.value === this.selectedLevel,
        ) || null
      );
    },

    questionBundles(): Array<IQuestionBundle> {
      return this.$store.get("milestonePlanner/questionBundles.values");
    },

    currentQuestionBundles(): Array<IQuestionBundle> {
      const currentCategory = this.categories.find(
        (category) => category.id === this.selectedCategory?.id,
      );
      const currentCategoryLevel = currentCategory?.categoryDetails.find(
        (categoryDetail) => categoryDetail.level.value === this.selectedLevel,
      );
      const levelId = currentCategoryLevel?.id;

      return this.questionBundles.filter(
        (questionBundle) => questionBundle.category_level === levelId,
      );
    },

    hasMilestonesCompletedAbove(): boolean {
      return this.authMilestones.some(
        (milestone) =>
          !!this.currentMilestone &&
          milestone.completed &&
          milestone.category_level.category.id ===
            this.currentMilestone.category_level.category.id &&
          milestone.category_level.level >
            this.currentMilestone.category_level.level,
      );
    },

    hasMilestonesNotCompletedBelow(): boolean {
      return (
        !!this.selectedGridCategory &&
        this.selectedGridCategory.milestones.some(
          (milestone) =>
            !!this.selectedGridMilestone &&
            !milestone.completed &&
            milestone.level < this.selectedGridMilestone.level,
        )
      );
    },

    planLastUpdate(): string {
      // TODO: Pending last update from plan on API (for now we'll use milestone global update)
      return this.currentMilestone && this.milestoneHasPlan
        ? moment(this.currentMilestone.updated_at).format("MMMM D, YYYY")
        : "";
    },

    completeLastUpdate(): string {
      // TODO: Pending last update from complete on API (for now we'll use milestone global update)
      return this.currentMilestone
        ? moment(this.currentMilestone.updated_at).format("MMMM D, YYYY")
        : "";
    },

    milestoneHasPlan(): boolean {
      return (
        !!this.currentMilestone?.strategy ||
        !!this.currentMilestone?.outcomes ||
        !!this.currentMilestone?.resources ||
        !!this.currentMilestone?.critical ||
        isFinite(this.currentMilestone?.finances_needed) ||
        !!this.currentMilestone?.target_date
      );
    },

    /**
     * Default Milestone Planner owned by the user.
     */
    defaultMilestonePlanner(): IMilestonePlanner {
      return this.$store.get("auth/milestonePlanners.defaultPlanner");
    },

    authLastAssessment(): IAssessment {
      return this.$store.get("auth/latestAssessment.data");
    },

    authLatestAssessmentLevel(): ILevel | null {
      return this.authLastAssessment?.level || null;
    },

    hasAssessmentLevel(): boolean {
      return this.authLatestAssessmentLevel !== null;
    },

    hasMatchingMandatoryData(): boolean {
      return (
        !!this.hasAssessmentLevel && !!this.hasLocation && !!this.hasSectors
      );
    },
  },

  watch: {
    "$route.params.categoryId": function (newCategoryId: string) {
      const newCategory = this.gridCategories.find(
        (category) => category.id === Number(newCategoryId),
      );

      if (newCategory) {
        this.selectedCategory = newCategory;
      } else {
        this.$router.replace({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
      }
    },

    "$route.params.levelValue": function (newLevelValue: string) {
      const hasValidLevel = this.isLevelValid(newLevelValue);

      if (hasValidLevel) {
        this.selectedLevel = Number(newLevelValue);
      } else {
        this.$router.replace({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
      }
    },

    "$route.query.tab": function (newTab: TMilestoneDetailsTab) {
      const hasValidTab = this.isTabValid(newTab);

      this.activeTab = hasValidTab ? newTab : MILESTONE_DETAILS_TABS.PLAN;
    },

    activeTab: function (tab: TMilestoneDetailsTab) {
      if (this.$route.query?.tab === tab) {
        return;
      }
      this.$router.push({
        path: this.$route.path,
        query: { tab },
      });
    },

    selectedLevel: function (level: number) {
      if (this.$route.params?.levelValue == level) {
        return;
      }
      this.$router.push({
        params: { levelValue: level.toString() },
        query: this.$route.query,
      });
    },

    selectedCategory: function (category: IGridCategory) {
      if (this.$route.params?.categoryId == category.id) {
        return;
      }
      this.$router.push({
        params: { categoryId: category.id.toString() },
        query: this.$route.query,
      });
    },

    selectedCategoryLevel(currentCategoryLevel: ICategoryDetail) {
      if (
        !!currentCategoryLevel &&
        !this.hasAlreadyLoadedQuestionBundle(currentCategoryLevel.id)
      ) {
        this.$store.dispatch(QuestionBundlesState.Action.GET_VALUES, {
          category_level: currentCategoryLevel.id,
        });
      }
    },

    isMatchingConfirmModalVisible(isVisible: boolean) {
      if (isVisible) return;

      this.fecthMilestoneDetailsData();
    },
  },

  async created() {
    if (!this.hasOnboarded) {
      // Force re-fetching user metadata to ensure user has not onboarded
      // in the meantime.
      this.$store.dispatch(EUserMetadataActions.FETCH, null, {
        root: true,
      });
    }

    this.prefillAuthData();

    // Matching modal should be visible when the user has not level OR location OR sectors defined
    this.isMatchingConfirmModalVisible = !this.hasMatchingMandatoryData;
    if (this.isMatchingConfirmModalVisible) return;

    await this.fecthMilestoneDetailsData();
  },

  mounted() {
    setTimeout(() => {
      if (!this.hasOnboarded) {
        this.onboardingDialogVisibility = true;
        this.markAsOnboarded();
      }
    }, 2000);
  },

  methods: {
    prefillAuthData() {
      this.hasLocation =
        !!this.$store.get("auth/company.data").locations.length;
      this.hasSectors = !!this.$store.get("auth/company.data").sectors.length;
    },

    async fecthMilestoneDetailsData() {
      await this.fetchCategoriesAndLevels();
      await this.fetchDefaultMilestonePlanner();

      if (!this.authMilestones.length) {
        await this.$store.dispatch(EAuthMilestonesActions.FETCH);
      }

      if (!this.gridCategories.length) {
        await this.$store.dispatch(EAuthMilestonesActions.PARSE_DATA);
      }

      // Validation relies on having Grid Categories
      this.validateAndSetContext();
    },

    async fetchCategoriesAndLevels() {
      if (!this.categories.length) {
        await this.$store.dispatch(ECategoryActions.FETCH, {
          group: ENTREPRENEUR_USER_GROUP_ID,
        });
      }

      if (!this.levels.length) {
        await this.$store.dispatch(ELevelActions.FETCH);
      }
    },

    validateAndSetContext() {
      const categoryIdFromRoute = this.$route.params?.categoryId;
      const levelValueFromRoute = this.$route.params?.levelValue;
      const tabFromRouteQuery = this.$route.query?.tab;

      const hasValidCategory = this.categories.some(
        (category) => category.id === Number(categoryIdFromRoute),
      );
      const hasValidLevel = this.isLevelValid(levelValueFromRoute);
      const hasValidTab =
        !!tabFromRouteQuery && this.isTabValid(tabFromRouteQuery);

      if (!hasValidCategory || !hasValidLevel) {
        this.$router.replace({ name: ROUTE_MILESTONE_PLANNER_OVERVIEW });
      } else {
        this.selectedCategory =
          this.gridCategories.find(
            (category) => category.id === Number(categoryIdFromRoute),
          ) || null;
        this.selectedLevel = Number(levelValueFromRoute);
        this.activeTab = hasValidTab
          ? tabFromRouteQuery
          : MILESTONE_DETAILS_TABS.PLAN;
      }
    },

    openOnboardingDialog() {
      this.onboardingDialogVisibility = true;
    },

    closeOnboardingDialog() {
      this.onboardingDialogVisibility = false;
    },

    markAsOnboarded(): void {
      this.$store.dispatch(EUserMetadataActions.SET, {
        key: MILESTONE_PLANNER_PLAN_ONBOARDED,
        value: "true",
      });
    },

    goToOverviewPage() {
      this.$router.push({ path: "/milestone-planner" });
    },

    isTabValid(tab: TMilestoneDetailsTab): boolean {
      return Object.values(MILESTONE_DETAILS_TABS).includes(tab);
    },

    isLevelValid(levelValue: string): boolean {
      return this.levels.some((level) => level.value === Number(levelValue));
    },

    hasAlreadyLoadedQuestionBundle(categoryLevelId: number) {
      return this.questionBundles.some(
        (questionBundle) => questionBundle.category_level === categoryLevelId,
      );
    },

    async fetchDefaultMilestonePlanner() {
      await this.$store.dispatch(
        EAuthMilestonePlannersActions.FETCH_DEFAULT_PLANNER,
      );
    },
  },
});
