











































































































































import Vue from "vue";
import throttle from "lodash/throttle";
import { LocaleMessages } from "vue-i18n";

import { companySearchProvider } from "@/services/data/company-search/company-search.provider";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import { ICompanySearch } from "@/services/data/company-search/company-search.interface";
import { ICompanyListInvitedUser } from "@/modules/company-lists/services/data/company-list/company-list.interface";

export default Vue.extend({
  name: "ListShareUsersSearch",

  props: {
    hasSavedUsers: {
      type: Boolean,
      default: false,
    },

    users: {
      type: Array as () => Array<ICompanyListInvitedUser>,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isSendingEmail: false,
      onSearchMode: false,
      searchNeedle: "",
      searchResults: [] as any,
      searchRequests: [] as Array<Promise<void>>,
      selectEl: null,
      selectedUsers: [] as Array<string>,
      isUserDisabled: false,
    };
  },

  computed: {
    authCompany(): ICompany {
      return this.$store.state.auth.company;
    },

    authCompanyUid(): string | null {
      return this.authCompany?.data ? this.authCompany.data.uid : null;
    },

    shareModalCopy(): LocaleMessages {
      return this.$t("companyLists.list.shareModal") as LocaleMessages;
    },

    alreadyAddedUsers(): Array<string | null> {
      return this.users.map((user) => user.user_profile);
    },

    hasEmptyInput(): boolean {
      return !this.searchNeedle.length;
    },

    hasSearchResults(): boolean {
      return !!this.searchResults.length;
    },

    isMakingRequests(): boolean {
      return !!this.searchRequests.length;
    },

    hasClearedSearch(): boolean {
      return this.isMakingRequests && !this.searchNeedle;
    },

    hasSelectedUsers(): boolean {
      return !!this.selectedUsers.length;
    },

    listLoading() {
      return this.$store.get(CompanyListState.Getter.IS_LOADING);
    },

    searchUserType(): number {
      return this.$user.getUserAccountType();
    },
  },

  watch: {
    "selectEl.query"(value: string) {
      this.searchNeedle = value;

      if (!value) {
        this.clearSearchResults();
      }
    },

    hasSavedUsers(success: boolean) {
      if (success) {
        this.selectedUsers = [];
        this.clearSearchResults();
        this.onSearchMode = false;
      }
    },
  },

  mounted() {
    (this as any).selectEl = this.$refs.select;
  },

  methods: {
    async doCompanySearch(query: string) {
      try {
        const data = await companySearchProvider.search(
          query,
          this.searchUserType,
        );

        if (!this.hasClearedSearch) {
          // Map backend data and filter out auth company from results
          this.searchResults = data.results
            .map((company) => ({
              uid: company.uid,
              name: company.name,
              email: company.email,
              logo: company.logo,
              user_profile: company.user_profile,
            }))
            .filter((company: ICompanySearch) => {
              return company.uid !== this.authCompanyUid;
            });
        }
      } catch {
        // TODO: Show error message
      } finally {
        this.isLoading = false;
        this.searchRequests.shift();
      }
    },

    shouldUserBeDisabled(user: string) {
      return (
        this.selectedUsers.includes(user) ||
        this.alreadyAddedUsers.includes(user)
      );
    },

    searchForUsers(query: string) {
      if (!query) {
        this.clearSearchResults();
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      throttle(() => {
        this.searchRequests.push(this.doCompanySearch(query));
      }, 1000)();
    },

    clearSearchResults() {
      this.searchResults = [];
      this.isSendingEmail = false;
    },

    focusHandler() {
      this.onSearchMode = true;

      // Reset results:
      if (!this.searchNeedle) {
        this.clearSearchResults();
      }
    },

    clearHandler() {
      // To avoid hiding "search mode":
      (this as any).$refs.select.focus();
    },

    dropdownChangeHandler(isVisible: boolean) {
      // NOTE: Using visible-change event since blur does not work.
      // Hide "search mode" on blur if no users were selected:
      if (!isVisible && !this.hasSelectedUsers) {
        this.onSearchMode = false;
      }
    },

    onClickCancelHandler() {
      this.selectedUsers = [];
      this.clearSearchResults();
      this.onSearchMode = false;
    },

    onClickSendEmailHandler() {
      this.isSendingEmail = true;

      this.$emit("send-email", this.selectedUsers);
    },
  },
});
