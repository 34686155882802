







































import Vue from "vue";
import { ILocation } from "@/services/data/location/location.interface";

export default Vue.extend({
  name: "LocationsSelector",

  props: {
    value: {
      type: Array as () => any[],
      required: true,
    },

    /**
     * When set to `true` the label won't be rendered.
     */
    noLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "supporters.signup.form.fields.locations",
    },

    placeholder: {
      type: String,
      default: "supporters.signup.form.placeholders.locations",
    },

    autocompleteType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tempLocation: null as null | ILocation,

      // Will store the list of selected locations
      selectedLocations: [] as Array<ILocation>,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal: Array<ILocation>) {
        if (newVal) {
          this.selectedLocations = newVal;
        }
      },
    },

    selectedLocations: {
      immediate: true,
      handler(newLocations: Array<ILocation>) {
        this.$emit("input", newLocations);
      },
    },
  },

  methods: {
    /**
     * When a new location is selected it is pushed into the
     * array of selected locations and the autocomplete fill
     * cleared.
     */
    onLocationSelect(selectedLocation: ILocation) {
      const newLocationToInsert =
        selectedLocation &&
        !this.selectedLocations.some(
          (location: ILocation) =>
            location.formatted_address === selectedLocation.formatted_address,
        );

      if (newLocationToInsert) {
        this.tempLocation = null;
        this.selectedLocations.push(selectedLocation);
        this.$emit("change", selectedLocation);
      } else {
        this.tempLocation = null;
      }
    },

    /**
     * Remove the given location from the list of selected locations.
     */
    onClickRemoveLocation(locationIndex: number) {
      this.selectedLocations.splice(locationIndex, 1);
    },
  },
});
