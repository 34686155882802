















































































import Vue from "vue";
import CompleteMilestoneInfoIndicator from "@/modules/milestone-planner/components/complete-milestone-info-indicator/complete-milestone-info-indicator.vue";
import CriticalMilestoneIndicator from "@/modules/milestone-planner/components/critical-milestone-indicator/critical-milestone-indicator.vue";
import MilestoneTooltipButtons from "./milestone-tooltip-buttons.vue";
import MilestoneTooltipProgressIndicator from "./milestone-tooltip-progress-indicator.vue";

/**
 * Milestone Tooltip
 *
 * For the tooltip to adopt the appropriate look and feel, the <ElPopover>
 * element that wrapps it needs to have both a 'px-popover--light' and a
 * 'milestone-cell-popover' class, as in:
 *
 * `<ElPopover popper-class="px-popover--light milestone-cell-popover">`
 */
export default Vue.extend({
  name: "MilestoneTooltip",

  components: {
    CompleteMilestoneInfoIndicator,
    CriticalMilestoneIndicator,
    MilestoneTooltipButtons,
    MilestoneTooltipProgressIndicator,
  },

  props: {
    categoryColor: { type: String, required: true },
    categoryName: { type: String, required: true },
    hasEvidence: { type: Boolean, default: false },
    isFutureMilestone: { type: Boolean, default: false },
    isMilestoneComplete: { type: Boolean, default: false },
    isMilestoneCritical: { type: Boolean, default: false },
    isMilestoneInProgress: { type: Boolean, default: false },
    isMilestonePlanned: { type: Boolean, default: false },
    levelNumber: { type: Number, required: true },
    milestoneDescription: { type: String, required: true },
    targetDate: { type: String, required: false, default: null },
    simplified: { type: Boolean, required: false, default: false },
    isVisitor: { type: Boolean, required: false, default: false },
  },

  computed: {
    showButtons(): boolean {
      return (
        (this.isVisitor &&
          this.isMilestonePlanned &&
          !this.isMilestoneComplete) ||
        (!this.isVisitor && !this.isMilestoneComplete)
      );
    },
  },
});
