


























import Vue from "vue";

import { PROFILE_META_ACTIVE_VIEW } from "@/modules/profile/constants";
import { EMetaActions } from "@/services/store/meta/meta-types";

import MatchingListCards from "@/modules/matching/components/matching-list-cards/matching-list-cards.vue";
import MatchingListInterests from "@/modules/matching/components/matching-list-interests/matching-list-interests.vue";
import MatchingListInterestMutual from "@/modules/matching/components/matching-list-interests/matching-list-interests-mutual.vue";

export default Vue.extend({
  name: "MatchingListMobile",

  components: {
    MatchingListCards,
    MatchingListInterests,
    MatchingListInterestMutual,
  },

  data() {
    return {
      selectedView: "discover",
    };
  },

  computed: {
    storeActiveView(): string {
      const storeValue = this.$store.get("meta/get", PROFILE_META_ACTIVE_VIEW);
      return storeValue ? storeValue : "discover";
    },
  },

  watch: {
    selectedView(newVal, oldVal) {
      if (newVal === oldVal || this.storeActiveView === newVal) {
        return;
      }

      this.$store.dispatch(EMetaActions.SET, {
        key: PROFILE_META_ACTIVE_VIEW,
        value: newVal,
      });
    },

    storeActiveView(newVal) {
      this.selectedView = newVal;
    },
  },
});
