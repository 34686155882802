






























































import Vue from "vue";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

import { ILevel } from "@/services/data/level/level.interface";
import { ELevelActions } from "@/services/store/levels/levels-types";
import { SUPPORTER_USER_GROUP_ID } from "@/modules/common/constants";

export default Vue.extend({
  props: {
    investRange: {
      type: Array as () => Array<number>,
      required: true,
    },
  },

  computed: {
    levels(): Array<ILevel> {
      return this.$store.get("levels/data");
    },

    investingLevelMinimum(): number {
      return this.investRange.length ? this.investRange[0] : 1;
    },

    investingLevelMaximum(): number | null {
      return this.investRange.length > 1 ? this.investRange[1] : null;
    },

    investingLevelsSelection(): Array<ILevel> {
      if (!this.investingLevelMaximum) {
        const uniqLevelValues = uniqBy(this.levels, "value");
        return uniqLevelValues.filter(
          (level) => level.value === this.investingLevelMinimum,
        );
      }

      const uniqAscendingLevels = sortBy(uniqBy(this.levels, "value"), "value");
      return uniqAscendingLevels.filter(
        (level) =>
          level.value >= this.investingLevelMinimum &&
          !!this.investingLevelMaximum &&
          level.value <= this.investingLevelMaximum,
      );
    },

    investingLevelRangeInterval(): string {
      if (this.investingLevelMaximum) {
        return this.investingLevelMinimum === this.investingLevelMaximum
          ? this.investingLevelMinimum.toString()
          : this.investRange.join("-");
      }

      return this.investingLevelMinimum.toString();
    },

    investingLevelRangeTitle(): string {
      const minimumLevel = this.levels.find(
        (level) => level.value === this.investingLevelMinimum,
      );
      let rangeTitle = minimumLevel
        ? `${minimumLevel.title} <span>(${this.$t(
            "supporters.results.levelPanel.label",
          )} ${minimumLevel.value})</span>`
        : "";

      if (this.investingLevelMaximum) {
        const maximumLevel = this.levels.find(
          (level) => level.value === this.investingLevelMaximum,
        );
        rangeTitle += maximumLevel
          ? `<br><span>to</span> ${maximumLevel.title} <span>(${this.$t(
              "supporters.results.levelPanel.label",
            )} ${maximumLevel.value})</span>`
          : "";
      }

      return rangeTitle;
    },
  },

  created() {
    if (!this.levels.length) {
      // Fetch levels if user lands on the results page directly.
      this.fetchLevels();
    }
  },

  methods: {
    async fetchLevels() {
      await this.$store.dispatch(ELevelActions.FETCH, {
        group: SUPPORTER_USER_GROUP_ID,
      });
    },
  },
});
