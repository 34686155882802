import { Route } from "vue-router";
import store from "@/services/store";
import { EMilestonePlannerActions } from "@/modules/milestone-planner/services/store/milestone-planner/milestone-planner.types";
import { ENTREPRENEUR_USER_TYPE } from "@/modules/authentication/constants";
import { ROUTE_MILESTONE_PLANNER_OVERVIEW } from "@/modules/milestone-planner/services/router/routes-names";
import { ERROR_ROUTE } from "@/services/router/router-names";
import { EUserGuestGetters } from "@/services/store/user-guest/user-guest.types";
import { userManager } from "@/modules/authentication/user-manager";
import { defaultScreenService } from "@/modules/common/plugins/screen.plugin";
import { ERROR_TYPES } from "@/modules/common/components/error-page/constants";

/**
 * Guard for Milestone Planner for Visitors route.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export const visitorMilestonePlannerGuard = async (
  to: Route,
  from: Route,
  next: Function,
) => {
  let requestParams: object = {
    id: to.params.uid,
  };

  // Handle request for user guest persisted on the store
  if (!userManager.isLogged()) {
    const userGuest = await store.get(EUserGuestGetters.GET);

    if (userGuest) {
      requestParams = {
        ...requestParams,
        email: userGuest.email,
      };
    }
  }

  await store.dispatch(EMilestonePlannerActions.GET_VALUE, requestParams);

  const error = store.get("milestonePlanner.error");
  const userCompany = store.get("auth/company.data");
  const userIsEntrepreneur = userCompany?.type === ENTREPRENEUR_USER_TYPE;

  /**
   * Error Handling:
   *
   * 403
   * - Either it is an entrepreneur who's accessing another entrepreneur's
   *   milestone planner, and therefore shall be redirected to an error page;
   * - Or it is a visitor/supporter who's accessing a milestone planner that
   *   has a passcode which was not provided and therefore shall proceed to
   *   the milestone planner page where it'll be asked for the passcode.
   *
   * 400
   * - It is a visitor who's accessing a milestone planner and therefore shall
   *   proceed to the milestone planner page where it'll be asked to register.
   */

  if (error && error.response.status === 403 && userIsEntrepreneur) {
    next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.FORBIDDEN,
      },
    });
  } else if (!error && userIsEntrepreneur) {
    /**
     * User is an entrepreneur and therefore shall be redirected to its own
     * milestone planner page.
     */
    return next({
      name: ROUTE_MILESTONE_PLANNER_OVERVIEW,
    });
  } else if (error && error.response.status === 404) {
    next({
      name: ERROR_ROUTE,
    });
  } else if (defaultScreenService.isDown("sm")) {
    next({
      name: ERROR_ROUTE,
      query: {
        code: ERROR_TYPES.MOBILE,
      },
    });
  } else {
    next();
  }
};
