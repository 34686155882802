










































import Vue from "vue";

import { IViralLevelState } from "@/services/store/viral-level/viral-level-types";
import { ILevel } from "@/services/data/level/level.interface";

import { EPxButtonVariant } from "@/components/px-button/px-button.types";

export default Vue.extend({
  name: "ValidatingPanel",

  static: {
    printButton: {
      variant: EPxButtonVariant.COMPACT,
      icon: "print",
    },
  },

  data() {
    return {
      modalVisibility: false,
    };
  },

  computed: {
    viralStore(): IViralLevelState {
      return this.$store.state.viralLevel;
    },

    isLoading(): boolean {
      return this.viralStore.loading;
    },

    hasError(): boolean {
      return !!this.viralStore.error;
    },

    finalLevel(): ILevel {
      return this.viralStore.finalLevel as ILevel;
    },
  },

  methods: {
    onPrintButtonClick() {
      window.print();
    },
  },
});
