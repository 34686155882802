


































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

import moment from "moment";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import pick from "lodash/pick";
import snakeCase from "lodash/snakeCase";

import { IMatchingQuestion } from "@/services/data/matching-questionary/matching-question.interface";

import PxDatePicker from "@/components/px-date-picker/px-date-picker.vue";
// import MilestoneFormCompleteFooter from "@/modules/milestone-planner/components/milestone-form/milestone-form-complete-footer.vue";
import MilestonePlanConfirmationDialog from "@/modules/milestone-planner/components/milestone-plan-confirmation-dialog/milestone-plan-confirmation-dialog.vue";
import MilestonePlanLevelUpDialog from "@/modules/milestone-planner/components/milestone-plan-level-up-dialog/milestone-plan-level-up-dialog.vue";
import {
  IMilestone,
  MILESTONE_STATES,
} from "@/modules/milestone-planner/services/data/milestones/milestone.interface";
import { EAuthMilestonesActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-milestones/auth-milestones.types";
import { ICategoryDetail } from "@/services/data/category/category.interface";
import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";
import { startCase } from "lodash";
import { IGridCategory } from "../milestones-grid/milestones-grid.interface";
import { EAuthLatestAssessmentActions } from "@/modules/authentication/services/store/auth/sub-modules/auth-latest-assessment/auth-latest-assessment.types";
import isFinite from "lodash/isFinite";

export default Vue.extend({
  name: "MilestoneFormComplete",

  components: {
    PxDatePicker,
    // MilestoneFormCompleteFooter,
    MilestonePlanConfirmationDialog,
    MilestonePlanLevelUpDialog,
  },

  props: {
    milestone: {
      type: Object as () => IMilestone,
      default: null,
    },
    categoryLevel: {
      type: Object as () => ICategoryDetail,
      required: true,
    },
    questions: {
      type: Array as () => Array<IMatchingQuestion>,
      default: () => [],
    },
    isFutureMilestone: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {} as any,
      originalData: {} as any,
      // Will be reused to reset form for future milestones:
      evidenceInitialState: Object.freeze({
        // TEMP: Evidence will need to be converted into an array of IMilestoneEvidence
        evidence: "",
        dateOfCompletion: null,
      }),
      rules: {
        evidence: {
          max: 4000,
        },
      },
      isDiscardingChanges: false,
      isDeleting: false,
      hasLeveledUp: false,
      formEl: null as null | Element,
    };
  },

  computed: {
    evidenceTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.completeForm.tooltips.evidence",
      );
    },

    dateOfCompletionTooltip(): TranslateResult {
      return this.$t(
        "milestonePlanner.milestonePlan.completeForm.tooltips.dateOfCompletion",
      );
    },

    /**
     * TEMP: Only show first question, while most common questions is pending specification.
     */
    evidenceQuestions(): Array<IMatchingQuestion> {
      return this.questions.length ? [this.questions[0]] : [];
    },

    milestoneEvidence(): Array<Omit<IMatchingResponse, "answers">> {
      return this.form.evidence.length
        ? this.evidenceQuestions.map((question) => ({
            question: question?.id,
            // TODO: Adapt value type (text, value, min, max) depending on question type:
            value: {
              // TODO: This will need to work with a list of evidence:
              text: this.form.evidence,
            },
          }))
        : [];
    },

    hasChangedEvidence(): boolean {
      return this.form.evidence !== this.originalData.evidence;
    },

    //TODO: we need to add E2E tests to check the invalid state of the form
    formIsInvalid(): boolean {
      if (this.formEl) {
        const formFields = (this as any).formEl.fields as any;

        // Check if some field have a error state
        const fieldsAreInvalid = (field: {
          fieldValue: string;
          prop: string;
          validateState: string;
          required: boolean;
        }) => {
          // Check if the field has only spaces and not content
          if (typeof field.fieldValue === "string" && field.fieldValue.length) {
            return !field.fieldValue.trim();
          }

          return field.validateState === "error";
        };
        return (
          !!formFields?.some(fieldsAreInvalid) ||
          isEqual((this as any).form, this.evidenceInitialState)
        );
      }
      return false;
    },

    hasFormChanged(): boolean {
      return !isEqual((this as any).form, this.originalData);
    },

    isExistingMilestone(): boolean {
      return !!this.milestone;
    },

    hasErrorOnMilestones(): boolean {
      return Boolean(this.$store.get("auth/milestones.error"));
    },

    milestoneHasPlanInfo(): boolean {
      return (
        !!this.milestone &&
        (this.milestone.plan_published ||
          !!this.milestone.strategy ||
          !!this.milestone.outcomes ||
          !!this.milestone.resources ||
          !!this.milestone.critical ||
          isFinite(this.milestone.finances_needed) ||
          !!this.milestone.target_date)
      );
    },

    companyId(): number | null {
      const company = this.$store.get("auth/company.data");
      return company ? company.id : null;
    },
  },

  watch: {
    hasFormChanged(hasChanged: boolean) {
      //TODO: check this emit (is happening twice on initialization because the assignment of the originalData only occurs on mounted)
      this.$emit("complete-form-has-changes", hasChanged);
    },

    formIsInvalid(isInvalid: boolean) {
      this.$emit("complete-form-is-invalid", isInvalid);
    },

    categoryLevel() {
      if (!this.isExistingMilestone) {
        this.resetForm();
      }
    },

    milestone: {
      deep: true,
      immediate: true,
      handler(currentMilestone: IMilestone | null) {
        if (currentMilestone) {
          const milestoneToFormValues =
            this.formatMilestoneValues(currentMilestone);
          const prefillValues = mapKeys(milestoneToFormValues, (v, k) =>
            camelCase(k),
          );
          (this as any).form = pick(
            prefillValues,
            Object.keys(this.evidenceInitialState),
          );
        } else {
          this.form = { ...this.evidenceInitialState };
        }

        this.originalData = cloneDeep(this.form);
      },
    },
  },

  mounted() {
    (this as any).formEl = this.$refs.form;
  },

  created() {
    this.$root.$on(
      "delete-milestone-evidence",
      this.showDeleteCompletionDialog,
    );
    this.$root.$on("discard-milestone-evidence", this.showDiscardChangesDialog);
    this.$root.$on("submit-milestone-evidence", this.submitEvidence);
  },

  beforeDestroy() {
    this.$root.$off(
      "delete-milestone-evidence",
      this.showDeleteCompletionDialog,
    );
    this.$root.$off(
      "discard-milestone-evidence",
      this.showDiscardChangesDialog,
    );
    this.$root.$off("submit-milestone-evidence", this.submitEvidence);
  },

  methods: {
    convertKeysToSnakeCase(fields: {}) {
      // TODO: Move this functionality into the generic provider
      return mapKeys(fields, (v, k) => snakeCase(k));
    },

    formatMilestoneValues(milestone: IMilestone) {
      const evidence = milestone.evidence[0]?.value?.text || "";
      const dateOfCompletion = milestone.date_of_completion
        ? moment(new Date(milestone.date_of_completion)).startOf("day").toDate()
        : null;
      return { ...milestone, evidence, dateOfCompletion };
    },

    resetForm() {
      this.form = { ...this.originalData };
    },

    showDeleteCompletionDialog() {
      this.isDeleting = true;
    },

    showDiscardChangesDialog() {
      this.isDiscardingChanges = true;
    },

    /**
     * Get formatted form data to match the expected API schema.
     */
    getFormattedForm(toPublish: boolean): any {
      const dateOfCompletion = this.form.dateOfCompletion
        ? moment(this.form.dateOfCompletion).format("YYYY-MM-DD")
        : toPublish
        ? moment().format("YYYY-MM-DD")
        : this.form.dateOfCompletion;

      return {
        ...(this.hasChangedEvidence && { evidence: this.milestoneEvidence }),
        dateOfCompletion,
      };
    },

    async submitEvidence({ toPublish }: { [key: string]: boolean }) {
      const fieldsInSnakeCase = this.convertKeysToSnakeCase(
        this.getFormattedForm(toPublish),
      );
      const categoryLevelBeforeUpdate = this.getCurrentCategoryLevel();

      if (!this.isExistingMilestone) {
        await this.$store.dispatch(EAuthMilestonesActions.CREATE, {
          ...fieldsInSnakeCase,
          category_level: this.categoryLevel.id,
          evidence_published: toPublish,
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            ...fieldsInSnakeCase,
            evidence_published: toPublish,
          },
        });
      }

      if (this.hasErrorOnMilestones) {
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else if (categoryLevelBeforeUpdate < this.getCurrentCategoryLevel()) {
        this.hasLeveledUp = true;
        this.$store.dispatch(
          EAuthLatestAssessmentActions.FETCH,
          this.companyId,
        );
      } else {
        this.$store.dispatch(
          EAuthLatestAssessmentActions.FETCH,
          this.companyId,
        );

        const translationKey = toPublish ? "completionUpdate" : "draft";

        this.$message({
          message: this.$t(
            `milestonePlanner.milestonePlan.completeForm.successfullySubmited.${translationKey}`,
          ) as string,
          type: "success",
          duration: 10000,
          customClass: "is-full",
        });
      }
    },

    async deleteMilestoneCompletion() {
      const isEvidencePublished = this.milestone.evidence_published;

      if (this.milestoneHasPlanInfo) {
        await this.$store.dispatch(EAuthMilestonesActions.PATCH, {
          milestoneToUpdate: this.milestone,
          payload: {
            evidence: [],
            date_of_completion: null,
          },
        });
      } else {
        await this.$store.dispatch(EAuthMilestonesActions.DESTROY, {
          milestoneToDelete: this.milestone,
        });
      }

      if (this.hasErrorOnMilestones) {
        this.$message({
          message: this.$t("common.errors.global.alertTitle") as string,
          type: "error",
          duration: 10000,
          customClass: "is-full",
        });
      } else {
        this.$store.dispatch(
          EAuthLatestAssessmentActions.FETCH,
          this.companyId,
        );
        this.showSuccessfulDeleteMessage(isEvidencePublished);
      }
    },

    showSuccessfulDeleteMessage(isEvidencePublished: boolean): void {
      this.$message({
        message: this.successfullyDeletedMessage(
          this.milestone,
          isEvidencePublished,
          this.isFutureMilestone,
        ) as string,
        type: "success",
        duration: 10000,
        customClass: "is-full",
      });
    },

    successfullyDeletedMessage(
      milestone: IMilestone,
      isEvidencePublished: boolean,
      isFutureMilestone: boolean,
    ): TranslateResult {
      const originalCompletionType = isEvidencePublished ? "plan" : "draft";
      let message = this.$t(
        `milestonePlanner.milestonePlan.completeForm.successfullyDeleted.${originalCompletionType}`,
      );

      if (!isFutureMilestone || milestone) {
        const milestoneStatus = milestone
          ? milestone.state
          : MILESTONE_STATES.TO_BE_PLANNED;
        const camelCaseStatus = camelCase(milestoneStatus);
        const statusLabel = this.$t(
          `milestonePlanner.milestoneGrid.stageStatus.${camelCaseStatus}`,
        ) as string;
        const updatedStatusMessage = this.$t(
          "milestonePlanner.milestonePlan.completeForm.successfullyDeleted.milestoneStatus",
          { status: startCase(statusLabel) },
        );
        message += ` ${updatedStatusMessage}`;
      }

      return message;
    },

    getCurrentCategoryLevel(): number {
      const gridCategories = this.$store.get(
        "auth/milestones.parsedData",
      ) as IGridCategory[];
      const currentCategory = gridCategories.find(
        (category) => category.id === this.categoryLevel.category,
      ) as IGridCategory;

      // Sort milestones in descending order of level
      // so that the last complete level shows first.
      const milestones = [...currentCategory?.milestones].sort(
        (a, b) => b.level - a.level,
      );
      const lastCompletedMilestone = milestones.find(
        (milestone) => milestone.completed,
      );

      return lastCompletedMilestone?.level || 0;
    },
  },
});
