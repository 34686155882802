

































import Vue from "vue";

interface IFooterItem {
  label: string;
  value: string;
  class?: string;
  openTab?: string;
}

/**
 * This is the desktop version of the footer.
 */
export default Vue.extend({
  name: "PxFooter",

  props: {
    /**
     * Receives a list of items to append to the menu.
     *
     * Structure:
     * {
     *   label: "Label",
     *   value: "https://example.com",
     *   openTab: false,
     *   class: "additional-class-names"
     * }
     */
    items: {
      type: Array as () => IFooterItem[],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    footerItems(): IFooterItem[] {
      return (
        this.items ||
        ([
          {
            label: this.$t("common.footer.cookieSettings"),
            value: "javascript:void(0)",
            class: "optanon-toggle-display",
          },
          {
            label: this.$t("common.footer.privacyPolicy"),
            value: "https://abaca.app/privacy-policy/",
            openTab: true,
          },
          {
            label: this.$t("common.footer.termsOfUse"),
            value: "https://abaca.app/terms-of-use/",
            openTab: true,
          },
        ] as IFooterItem[])
      );
    },
  },

  methods: {
    itemTarget(item: IFooterItem) {
      return item.openTab ? "_blank" : "_self";
    },
  },
});
